import Button from '../../atoms/Button/Button';
import './UplinkConfigurationView.css';

function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

const UplinkItem = ({ uplink }) => {
  return (
    <div className="uplink-item">
      <div>
        <h3>{`Uplink ${uplink.id}`}</h3>
        <div>{`State: ${uplink.state}`}</div>
        <div>{`Version: ${uplink.version}`}</div>
        <div>{`Last active: ${timeSince(new Date(uplink.last_heartbeat?.timestamp))}`}</div>
      </div>
      <div>
        <Button label="Deactivate" icon="pause" />
        <Button label="Delete" style={{ marginLeft: '1rem' }} icon="trash" />
      </div>
    </div>
  );
};

const UplinkConfigurationView = ({ uplinks, refresh }) => {
  return (
    <div className="uplink-configuration-container">
      <h2>My Uplinks</h2>
      <Button label="Refresh" onClick={refresh} />
      {uplinks
        .sort((a, b) => new Date(b.last_heartbeat?.timestamp) - new Date(a.last_heartbeat?.timestamp))
        .map((uplink) => (
          <UplinkItem key={uplink.id} uplink={uplink} />
        ))}
    </div>
  );
};

export default UplinkConfigurationView;
