import Icon from '../../atoms/Icon/Icon';
import './SlideShareDisplay.css';

const SlideShareDisplay = ({ media, mode }) => {
  const isSelectedForPrint = media?.selected_for_print;
  const isSelectedForShare = media?.shared_with_patient;
  const isSharing = mode === 'share';
  const isPrinting = mode === 'print';
  const isSelected = (isSharing && isSelectedForShare) || (isPrinting && isSelectedForPrint);

  return (
    !!media && (
      <div className="slide-share-display-container">
        <div className={isSelected ? '' : 'unselected'}>
          <Icon name={isSelected ? 'done-circled' : 'circle'} />
        </div>
      </div>
    )
  );
};

export default SlideShareDisplay;
