export function getSuggestedCarryForward(carryForward, placeholderValue) {
  return Object.values(carryForward)
    .filter((item) => !!item?.codes_object && !placeholderValue?.[item?.label])
    .map((item) => ({
      ...item,
      description: item.label,
      value: item.label,
      from: 'carry-forward',
    }));
}

export function shouldSuggestedCarryForwardBeFolded(examData, reportSection, dataField = 'value') {
  // on Finding section, fold by default (whatever the content of the current data)
  if (!!reportSection && reportSection.includes('finding')) return true;
  // if the current section in data is empty or inexistant, unfold suggestions
  if (!examData || !reportSection || !examData[reportSection]) return false;

  // if the current section in data has some entries, fold suggestions
  let entries;
  if (dataField === 'data') {
    // report v2
    entries = examData[reportSection].data.flatMap((v) => Object.values(v?.value?.value || {}));
  } else {
    // report v1
    entries = Object.values(examData[reportSection][dataField] || {});
  }
  return entries.length > 0;
}

export function getDefaultCheckedCodes(carryForwards) {
  return carryForwards
    .filter(
      (cf) =>
        !cf.label.toLowerCase().includes('not found') &&
        !cf.label.toLowerCase().includes('suspected') &&
        !cf.label.toLowerCase().includes('screening')
    )
    .map((cf) => cf.codes);
}
