import { useCallback, useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import { ExaminationContext } from '../../context-providers/Examination';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import useAuth from '../../context-providers/Auth';
import { useReportDialog } from '../../context-providers/ReportDialogProvider';

import { convertTimeZone, formatYYYYMMDDDate, compareDates, checklistItemsIsReady } from '../../utils';

import './ExaminationReport.css';

import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import { ExamStatus } from '../../config';
import ResourceApi from '../../services/resource';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import ReportSidebar from '../../components/ReportSidebar/ReportSidebar';
import EditFlashReportDialog from '../../components/ReportDialog/EditFlashReportDialog/EditFlashReportDialog';
import EditModeDialog from '../../components/ReportDialog/EditModeDialog/EditModeDialog';
import ConfirmFlashReportDialog from '../../components/ReportDialog/ConfirmDialog/ConfirmFlashReportDialog';

import { XMLDocument } from '../../components/XMLDocument';

const ExaminationReportView = ({
  /* TODO remove and use the i18n */
  t: __,
  /* TODO remove and use the context */
  setOpenPopup,
  isFeatureFlagEnabled,
  templateBlueprint,
  examStatus,
  examEvents,
  appContext,
  base64Images,
  setBase64Images,
  reportContentRef,
  frozenReport,
  closePanel,
}) => {
  const examinationContext = useContext(ExaminationContext);
  const { openModal } = useReportDialog();

  const { user, siteFlowsConnectors } = useAuth();

  const {
    reportData,
    reportDataOptions,
    applyAutomationTemplate,
    automationTemplateFieldsVisible,
    setAutomationTemplateFieldsVisible,
    isEditMode,
    setIsEditMode,
    setCurrentTemplate,
    setEditReportBackup,
    placeholders,
  } = useXMLTemplate();

  // TODO require all checklist items from report data or examinationContext ???
  const [requiredAndInvalid, setRequiredAndInvalid] = useState(new Set());
  const [showErrors, setShowErrors] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const placeholdersForCILoaded = checklistItemsIsReady(placeholders, examinationContext.examination.report_version);

  /* TODO move this into the XMLDocument context with BI event hook */
  const onCloseWindow = () => {
    ResourceApi.createExaminationEvent(examinationContext.examination.id, 'report_closed');
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onCloseWindow);
    ResourceApi.createExaminationEvent(examinationContext.examination.id, 'report_opened');
    ResourceApi.createBIEvent({
      event_type: 'report_opened',
      examination_id: examinationContext.examination.id,
      report_id: reportDataOptions.report_id,
    });
    return () => {
      onCloseWindow();
      window.removeEventListener('beforeunload', onCloseWindow);
      ResourceApi.createExaminationEvent(examinationContext.examination.id, 'report_closed');
      ResourceApi.createBIEvent({
        event_type: 'report_closed',
        examination_id: examinationContext.examination.id,
        report_id: reportDataOptions.report_id,
      });
    };
  }, []);

  const examStatusLabel = () => {
    const ee = examEvents.filter((ee) => ee.exam_status === examStatus).sort((a, b) => b.id - a.id)[0];
    const date = ee?.inserted_at + 'Z';

    const tzDate = formatYYYYMMDDDate(
      convertTimeZone(date, examinationContext.examination?.site?.timezone) || '',
      appContext.preferences.date_format
    );
    const tzTime = convertTimeZone(date, examinationContext.examination?.site?.timezone)?.substr(11, 5);

    const readyForReview = examinationContext.examination.status === ExamStatus.READY_FOR_REVIEW;
    const submittedOn = examinationContext.examination.events
      .sort((a, b) => (a.inserted_at < b.inserted_at ? 1 : -1))
      .find(
        (event) => event.event_type === 'status_update' && event.exam_status === ExamStatus.READY_FOR_REVIEW
      )?.inserted_at;
    const lastDicomTime = examinationContext.examination.events
      .sort((a, b) => (a.inserted_at < b.inserted_at ? 1 : -1))
      .find((event) => event.event_type === 'dicom')?.inserted_at;

    return (
      <div className="exam-report-status">
        {__('examinationReview.status.' + examStatus)}.&nbsp;
        {tzDate &&
          tzTime &&
          __('examinationReview.status.' + examStatus + '.meta', {
            date: tzDate,
            time: tzTime,
            name: ee?.entity?.title,
          })}
        {!examinationContext.canEdit && (
          <div>
            <Icon name="lock_closed" /> {__('examinationReview.examIsLocked')}
            {examinationContext.canUnfreeze && (
              <>
                {__(':')}{' '}
                <Button
                  label={__('examinationReview.editExam')}
                  variant="link"
                  size="small"
                  onClick={() => examinationContext.reopenExamination()}
                />
              </>
            )}
          </div>
        )}
        {readyForReview && compareDates(submittedOn, lastDicomTime) === -1 && (
          <div className="exam-completed-status-warning">
            <Icon name="warning" />
            {__('examinationReview.dicomReceivedAfterSubmission')}
          </div>
        )}
      </div>
    );
  };

  // Handle missing required fields
  const scrollToFirstError = () => {
    const errorElemsByScrollTop = Array.prototype.reduce.call(
      reportContentRef.current.getElementsByClassName('required-error'),
      (acc, elem) => ({ ...acc, [elem.getBoundingClientRect().top]: elem }),
      {}
    );
    if (Object.keys(errorElemsByScrollTop).length === 0) {
      return;
    }
    const firstErrorElem = errorElemsByScrollTop[`${Math.min(...Object.keys(errorElemsByScrollTop).map(parseFloat))}`];
    if (firstErrorElem) firstErrorElem.scrollIntoView({ behavior: 'smooth', block: 'nearest' }); // 'nearest' needed because scrolling to the end of the report display the notification panel
  };

  const onClickSubmit = (newStatus) => {
    setShowErrors(false);
    setSubmitClicked(true);
    const isCodingProvided =
      examinationContext.examination?.procedures_codes?.length !== 0 &&
      examinationContext.examination?.diagnoses_codes?.length !== 0;
    const isCodingCorrectlyProvided =
      (examinationContext.examination?.preset?.is_coding_mandatory && isCodingProvided) ||
      !examinationContext.examination?.preset?.is_coding_mandatory ||
      !siteFlowsConnectors?.coding;

    /* Keep this to understand why we can't sign sometimes */
    if (!isCodingCorrectlyProvided) console.warn('Coding is not correctly filled in. Please fill it before submitting');
    if (requiredAndInvalid.size !== 0)
      console.warn('Some mandatory fields are not correctly provided.', requiredAndInvalid);
    if (requiredAndInvalid.size === 0 && isCodingCorrectlyProvided) {
      setOpenPopup((openPopup) => (openPopup?.view === 'submit' ? false : { view: 'submit', status: newStatus }));
      return;
    }
    setShowErrors(true);
  };

  useEffect(() => {
    if (showErrors && submitClicked) {
      setSubmitClicked(false);
      requestAnimationFrame(scrollToFirstError);
    }
  }, [showErrors, submitClicked]);

  const getAutomationTemplateOptions = () => {
    const options =
      reportDataOptions.automation_templates
        ?.filter(({ report_version }) => report_version === examinationContext.examination.report_version)
        ?.map((t) => ({ label: t.name, value: t.slug })) || [];
    const optionsLength = options.length;
    if (isFeatureFlagEnabled('sonio.create_quick_reports')) {
      if (optionsLength > 0) {
        options.push({ type: 'separator' });
        if (examinationContext.examination.report_version === '2.0.0') {
          options.push({
            label: __('reportDialog.edit.addNew'),
            value: 'add-new-flash-report',
            icon: 'add',
          });
        }
        if (optionsLength > 0) {
          options.push({
            label: __('reportDialog.edit.existing'),
            value: 'edit-existing-flash-report',
            icon: 'settings',
          });
        }
        options.push({
          type: 'checkbox',
          label: __('reportDialog.edit.viewFields'),
          value: 'highlight-flash-report-fields',
          icon: automationTemplateFieldsVisible ? 'checkbox-done' : 'checkbox-empty',
          autoClose: false,
        });
      } else {
        if (examinationContext.examination.report_version === '2.0.0') {
          options.push({
            label: __('reportDialog.edit.addNew'),
            value: 'add-new-flash-report',
            icon: 'add',
          });
          options.push({
            type: 'checkbox',
            label: __('reportDialog.edit.viewFields'),
            value: 'highlight-flash-report-fields',
            icon: automationTemplateFieldsVisible ? 'checkbox-done' : 'checkbox-empty',
            autoClose: false,
          });
        }
      }
    }
    return options;
  };

  const handleSelectChange = (slug) => {
    if (slug === 'add-new-flash-report') {
      setEditReportBackup && setEditReportBackup(structuredClone(reportData));
      setAutomationTemplateFieldsVisible(true);
      setCurrentTemplate && setCurrentTemplate({});
      setIsEditMode(true);
    } else if (slug === 'edit-existing-flash-report') {
      openModal(<EditFlashReportDialog />);
    } else if (slug === 'highlight-flash-report-fields') {
      setAutomationTemplateFieldsVisible((visible) => !visible);
    } else {
      if (window.localStorage.getItem(`firstTimeFlashReportApply_${user.id}`) !== null) {
        applyAutomationTemplate(slug);
      } else {
        openModal(
          <ConfirmFlashReportDialog
            onConfirm={() => applyAutomationTemplate(slug)}
            content={__('reportDialog.edit.applyContent')}
            showOnce={`firstTimeFlashReportApply_${user.id}`}
          />
        );
      }
    }
  };

  const openEditor = useCallback(
    (editor) => {
      switch (editor) {
        case 'stakeholders':
          return setOpenPopup({ view: 'submit', submitAllowed: false });
        default:
          console.error('Unknown editor', editor);
          return false;
      }
    },
    [setOpenPopup]
  );

  return (
    <div className={`exam-report-wrapper ${examinationContext.canEdit ? '' : 'frozen'}`}>
      <div ref={reportContentRef} className="exam-report-content">
        {(examinationContext.canEdit || frozenReport.state === 'error') && (
          <>
            <div
              className={`exam-report-inner-content ${frozenReport.state === 'error' ? 'with-pointer' : ''} ${
                isFeatureFlagEnabled('sonio.sidebar') ? 'has-sidebar' : ''
              }`}
              onClick={() =>
                frozenReport.state === 'error' &&
                examinationContext.reopenExamination &&
                examinationContext.reopenExamination()
              }
            >
              {closePanel}
              <XMLDocument
                templateBlueprint={templateBlueprint}
                page="report"
                showErrors={showErrors}
                submitClicked={submitClicked}
                openEditor={openEditor}
                setEditChecklistDialogIsOpen={(open) => {
                  return setOpenPopup(
                    !open
                      ? false
                      : {
                          view: 'checklist',
                          fetus: open?.fetus,
                          slug: open?.slug,
                          componentUID: open?.componentUID,
                          allowedSlugs: open?.allowedSlugs,
                        }
                  );
                }}
                reportMode="edit"
                setManageMeasurementsIsOpen={(open) => setOpenPopup(!open ? false : { ...open, view: 'measurements' })}
                base64Images={base64Images}
                setBase64Images={setBase64Images}
                requiredAndInvalid={requiredAndInvalid}
                setRequiredAndInvalid={setRequiredAndInvalid}
              />
            </div>
            {isFeatureFlagEnabled('sonio.sidebar') && <ReportSidebar />}
          </>
        )}
        {!examinationContext.canEdit && frozenReport.state === 'loaded' && (
          <>
            <div
              className="exam-report-inner-content with-pointer"
              onClick={() => examinationContext.reopenExamination && examinationContext.reopenExamination()}
              dangerouslySetInnerHTML={{ __html: frozenReport.edit }}
            />
            {isFeatureFlagEnabled('sonio.sidebar') && <ReportSidebar />}
          </>
        )}
      </div>
      <div className="exam-report-options">
        <div className="exam-report-options-column">
          <Button
            variant="outline"
            onClick={() => setOpenPopup((openPopup) => (openPopup?.view === 'activity' ? false : { view: 'activity' }))}
            icon="comment"
            label={__('examinationReview.dialog.activity')}
          />
          <Button
            variant="outline"
            onClick={() =>
              setOpenPopup((openPopup) => (openPopup?.view === 'measurements' ? false : { view: 'measurements' }))
            }
            onClickDisabled={examinationContext.reopenExamination}
            icon="settings-alt"
            label={__('examinationReview.dialog.measurements')}
            disabled={!examinationContext.canEdit}
          />
          <Button
            variant="outline"
            onClick={() =>
              setOpenPopup((openPopup) => (openPopup?.view === 'checklist' ? false : { view: 'checklist' }))
            }
            onClickDisabled={() => placeholdersForCILoaded && examinationContext.reopenExamination()}
            icon="done"
            label={__('examinationReview.dialog.checklist')}
            disabled={!examinationContext.canEdit || !placeholdersForCILoaded}
          />
          {isFeatureFlagEnabled('sonio.quick_reports') && (
            <div>
              <SelectInput
                options={getAutomationTemplateOptions()}
                onChange={handleSelectChange}
                placeholder={{
                  icon: 'flash',
                  label: __('examination.report.flashReport'),
                }}
                showDropDown={false}
                variant="outline"
                padding="big"
                disabled={!examinationContext.canEdit || isEditMode}
              />
            </div>
          )}
          {examStatusLabel()}
        </div>
        <div className="exam-report-options-column">
          <Button
            variant="outline"
            onClick={() =>
              setOpenPopup((openPopup) =>
                openPopup?.view === 'printing-preview' ? false : { view: 'printing-preview' }
              )
            }
            icon="download"
            label={__('examinationReview.dialog.previewDownloadPdf')}
          />
          {examinationContext.canSubmit && !examinationContext.canSign && (
            <Button
              onClick={() => onClickSubmit(ExamStatus.READY_FOR_REVIEW)}
              icon="edit"
              label={__('common.submit')}
            />
          )}
          {examinationContext.canSign && (
            <Button
              onClick={() => onClickSubmit(ExamStatus.REPORT_SIGNED)}
              icon="edit"
              label={__('examinationReview.dialog.signReport')}
            />
          )}
        </div>
      </div>
      {isEditMode && <EditModeDialog />}
    </div>
  );
};

export default withTranslation()(ExaminationReportView);
