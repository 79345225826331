import { useContext, useState } from 'react';
import './SubmitExamDialog.css';
import TextArea from '../../atoms/TextArea/TextArea';
import Button from '../../atoms/Button/Button';
import { withTranslation } from 'react-i18next';
import { formatDateWithTZ, formatDateTimeWithTZ } from '../../utils';
import { ExamStatus } from '../../config';
import { AppContext } from '../../context-providers/App';
import ButtonBack from '../../atoms/ButtonBack/ButtonBack';

const SubmitStatus = {
  NOT_SUBMITTED: 1,
  LOADING: 2,
  ERROR: 3,
};

const EventType = {
  STATUS_UPDATE: 'status_update',
  COMMENT: 'comment',
  REPORT_EDITED: 'report_edited',
  DICOM: 'dicom',
  REPORT_OPENED: 'report_opened',
  REPORT_CLOSED: 'report_closed',
  IMAGE_REASSOC: 'image_reassociated',
  PRESET_CHANGED: 'preset_changed',
  EXAMINATION_DATE_CHANGED: 'examination_date_changed',
};

const ViewExamEventsDialog = ({
  t: __,
  setReviewReportDialogIsOpen,
  commentValue,
  setCommentValue,
  submitReport,
  examEvents,
}) => {
  let submitOrSignedFound = false;
  const eventsToDisplay = JSON.parse(JSON.stringify(examEvents)).reduce((acc, event) => {
    if (
      event.event_type === EventType.STATUS_UPDATE &&
      [ExamStatus.READY_FOR_REVIEW, ExamStatus.REPORT_SIGNED].includes(event.exam_status)
    ) {
      submitOrSignedFound = true;
    }

    // Only display these events from after the exam is submitted
    if (
      !submitOrSignedFound &&
      [EventType.IMAGE_REASSOC, EventType.REPORT_EDITED, EventType.DICOM].includes(event.event_type)
    ) {
      return acc;
    }

    // Don't display report open/close events
    if (event.event_type === EventType.REPORT_OPENED || event.event_type === EventType.REPORT_CLOSED) {
      return acc;
    }

    // Don't display 'completed' or draft events
    if (
      event.event_type === EventType.STATUS_UPDATE &&
      [ExamStatus.COMPLETED, ExamStatus.DRAFT].includes(event.exam_status)
    ) {
      return acc;
    }

    // Amalgamate consecutive report edits and DICOM images
    const prevEvent = acc[acc.length - 1];
    if (prevEvent && prevEvent.event_type === event.event_type) {
      if (
        event.event_type === EventType.REPORT_EDITED ||
        (event.event_type === EventType.DICOM &&
          event.dicom_instance.modality === 'US' &&
          prevEvent.dicom_instance.modality === 'US')
      ) {
        prevEvent.count = (prevEvent.count || 1) + 1;
        prevEvent.lastEdit = event.inserted_at;
        return acc;
      }
    }
    acc.push(event);
    return acc;
  }, []);

  const sortedEventsToDisplay = eventsToDisplay.sort((a, b) => (a.inserted_at > b.inserted_at ? 1 : -1));

  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.NOT_SUBMITTED);

  const onClickCancel = () => {
    setCommentValue('');
    setReviewReportDialogIsOpen(false);
  };

  const onClickSubmit = async () => {
    setSubmitStatus(SubmitStatus.LOADING);
    const res = await submitReport(commentValue, EventType.COMMENT);
    setSubmitStatus(res.status === 200 ? SubmitStatus.NOT_SUBMITTED : SubmitStatus.ERROR);
    setCommentValue('');
  };

  return (
    <>
      <div className="modal-background" onClick={onClickCancel} />
      <div className="exam-report-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="exam-report-dialog-header">
          <ButtonBack onClick={onClickCancel} />
          <div className="exam-report-dialog-header_title">
            <h2>{__('examinationReview.dialog.examActivity')}</h2>
          </div>
          <ButtonBack icon="close" onClick={onClickCancel} />
        </div>
        <div className="exam-report-dialog-body column-direction">
          <div>
            {sortedEventsToDisplay.map((examEvent) => (
              <EventItem {...examEvent} key={examEvent.id} />
            ))}
          </div>
          <div className="exam-report-dialog-comment">
            <TextArea
              value={commentValue}
              fullwidth={true}
              onChange={(value) => setCommentValue(value)}
              placeholder={__('examinationReview.dialog.writeComment')}
              disabled={submitStatus === SubmitStatus.LOADING}
            />
            <div>
              <Button
                onClick={onClickSubmit}
                label={
                  submitStatus === SubmitStatus.NOT_SUBMITTED
                    ? __('examinationReview.dialog.addComment')
                    : __('common.loading')
                }
                disabled={submitStatus === SubmitStatus.LOADING}
              />
              <div className="error">
                {submitStatus === SubmitStatus.ERROR && __('examinationReview.dialog.somethingWentWrong')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ViewExamEventsDialog);

const EventItem = withTranslation()(
  ({
    t: __,
    comment,
    entity,
    event_type,
    exam_status,
    inserted_at,
    count,
    dicom_instance,
    preset,
    examination_changes,
  }) => {
    const appContext = useContext(AppContext);
    const eventDate = formatDateWithTZ(inserted_at, appContext.preferences.date_format);
    const eventTime = `${formatDateTimeWithTZ(inserted_at)}`.substr(11);

    const getStatusUpdateMessage = () => {
      const statuses = {
        [ExamStatus.INPROGRESS]: {
          message: __('examinationReview.dialog.startedExam'),
          showUser: false,
        },
        [ExamStatus.COMPLETED]: {
          message: __('examinationReview.dialog.completedExam'),
          showUser: true,
        },
        [ExamStatus.READY_FOR_REVIEW]: {
          message: __('examinationReview.dialog.submittedReport'),
          showUser: true,
        },
        [ExamStatus.REPORT_SIGNED]: {
          message: __('examinationReview.dialog.signedReport'),
          showUser: true,
        },
      };
      return (
        statuses[exam_status] || {
          message: __('examinationReview.dialog.unknownStatus', {
            status: exam_status,
          }),
          showUser: false,
        }
      );
    };

    const getPresetChangedMessage = () => {
      return __('examinationReview.dialog.presetChanged', {
        preset: preset?.name,
      });
    };

    const getExaminationDateChangedMessage = (examination_changes) => {
      const changeDate = formatDateWithTZ(examination_changes.new_examination_date, appContext.preferences.date_format);
      return __('examinationReview.dialog.examinationDateChanged', {
        newDate: changeDate,
      });
    };

    const getReportEditedMessage = () => {
      return count === 1
        ? __('examinationReview.dialog.editedReport')
        : __('examinationReview.dialog.editedReportMultiple', {
            number: count,
          });
    };

    const getDicomMessage = () => {
      if (dicom_instance.modality === 'SR') return __('examinationReview.dialog.newDicomSr');
      return count === 1
        ? __('examinationReview.dialog.newDicomImage')
        : __('examinationReview.dialog.newDicomImages', { number: count });
    };

    let message;
    let showUserName = true;
    switch (event_type) {
      case EventType.COMMENT:
        message = __('examinationReview.dialog.commented');
        break;
      case EventType.STATUS_UPDATE:
        // eslint-disable-next-line no-case-declarations
        const { message: statusMessage, showUser } = getStatusUpdateMessage();
        message = statusMessage;
        showUserName = showUser;
        break;
      case EventType.REPORT_EDITED:
        message = getReportEditedMessage();
        break;
      case EventType.DICOM:
        message = getDicomMessage();
        showUserName = false;
        break;
      case EventType.IMAGE_REASSOC:
        message = __('examinationReview.dialog.imageReassociated');
        break;
      case EventType.PRESET_CHANGED:
        message = getPresetChangedMessage();
        break;
      case EventType.EXAMINATION_DATE_CHANGED:
        message = getExaminationDateChangedMessage(examination_changes);
        showUserName = true;
        break;
      default:
        /* Remove messages that are not known events from the activity panel */
        console.warn('Unknown event type', event_type);
        return null;
    }

    const userName = showUserName && entity && <span className="user-name">{entity.title}</span>;

    return (
      <div className="event-item">
        <div className="event-item-message">
          {userName} {message}{' '}
        </div>
        {comment && <div className="event-item-comment"> {comment} </div>}
        {eventDate && eventTime && (
          <div className="event-item-timestamp">
            {eventDate} {eventTime}
          </div>
        )}
      </div>
    );
  }
);
