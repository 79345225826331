import React, { useContext, useRef, useState } from 'react';
import { convertTimeZone, formatDate, formatYYYYMMDDDate } from '../../../utils';
import Icon from '../../../atoms/Icon/Icon';
import Button from '../../../atoms/Button/Button';
import { SocketContext } from '../../../context-providers/Socket';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../../../context-providers/App';
import useChannel from '../../../hooks/useChannel';

export default withTranslation()(EpisodesDetails);

function EpisodesDetails({
  t: __,
  canEdit,
  examinationId,
  examinationTz,
  patientId,
  refreshEpisodes = async () => [],
}) {
  const appContext = useContext(AppContext);
  const { socket } = useContext(SocketContext);
  const [episodes, setEpisodes] = useState([]);
  const episodesRef = useRef([]);

  episodesRef.current = episodes;

  const updateEpisodesCallback = ({ resource_type, action, data: exam }) => {
    if (resource_type !== 'examination' || !exam) {
      return;
    }

    const existingEpisodes = episodesRef.current;
    const examAlreadyExists = doesExamExist(existingEpisodes, exam);

    const isCreate = action === 'create' && !examAlreadyExists;
    const isUpdate = action === 'update' && exam.state === 'active' && examAlreadyExists;
    const isDelete = action === 'update' && exam.state === 'deleted' && examAlreadyExists;

    if (isCreate) {
      refreshEpisodes(patientId).then(setEpisodes);
    } else if (isUpdate) {
      setEpisodes(updateExam(existingEpisodes, exam));
    } else if (isDelete) {
      setEpisodes(deleteExam(existingEpisodes, exam));
    }
  };

  const channelTopic = `react.EpisodesDetails:${patientId}`;

  useChannel(socket, channelTopic, async (channel) => {
    const initialEpisodes = await refreshEpisodes(patientId);
    setEpisodes(initialEpisodes);

    channel.on('update', updateEpisodesCallback);
    channel.join().receive('error', (res) => {
      console.log(`Could not subscribe to channel ${channelTopic}: ${JSON.stringify(res)}`);
    });
  });

  if (!examinationId || !examinationTz || !patientId) {
    return null;
  }

  return (
    <div className="examination-live-phenotype-panel-episodes" title="episodes-details-panel">
      <h2 className="section-title">{__('episode.episodes')}</h2>
      <table className="simple-table">
        <tbody>
          {episodes.sort(descendingDateSorter('inserted_at')).map((episode) => (
            <React.Fragment key={episode.id}>
              <tr className={'examination-live-phenotype-episode-name'}>
                <th colSpan="4">
                  <span>
                    {episode.name ||
                      __('episode.defaultName', {
                        inserted_at: formatDate(episode.inserted_at, appContext.preferences.date_format),
                      })}
                  </span>
                </th>
              </tr>
              {episode.examinations.sort(descendingDateSorter('examination_date')).map((examination) => (
                <React.Fragment key={`examination-${examination.id}`}>
                  <tr data-is-current={examination.id === examinationId}>
                    <td>
                      {examination.id === examinationId && (
                        <span className="examination-live-phenotype-episode-current-exam">
                          <Icon name="arrow-right" />
                        </span>
                      )}
                    </td>
                    <td>{examination.preset.name}</td>
                    <td>{examination.preset.category === 'other' ? '-' : examination.preset.category}</td>
                    <td>
                      <ExaminationDate
                        date={examination.examination_date}
                        tz={examinationTz}
                        format={appContext.preferences.date_format}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4">
              <Icon name="info" />{' '}
              {__('episode.timezone', {
                timezone: examinationTz,
              })}
            </td>
          </tr>
        </tfoot>
      </table>
      {canEdit && (
        <div className="examination-live-phenotype-panel-cta">
          <Button
            variant="outline"
            onClick={() => {
              window.location.href = `/patient/${patientId}`;
            }}
            icon="edit"
            label={__('episode.editEpisodes')}
          />
        </div>
      )}
    </div>
  );
}

function doesExamExist(existingEpisodes, examToFind) {
  return existingEpisodes.some((episode) => episode.examinations.some((exam) => exam.id === examToFind.id));
}

function updateExam(existingEpisodes, examToUpdate) {
  return existingEpisodes.map((episode) => ({
    ...episode,
    examinations: episode.examinations.map((currentExam) => ({
      ...currentExam,
      examination_date:
        currentExam.id === examToUpdate.id ? examToUpdate.examination_date : currentExam.examination_date,
    })),
  }));
}

function deleteExam(existingEpisodes, examToDelete) {
  return existingEpisodes.map((episode) => ({
    ...episode,
    examinations: episode.examinations.filter((currentExam) => currentExam.id !== examToDelete.id),
  }));
}

function ExaminationDate({ date, tz, format }) {
  const formattedDateTime = convertTimeZone(date, tz);
  const displayDate = formatYYYYMMDDDate(formattedDateTime, format);
  const displayTime = formattedDateTime.substr(11, 5);

  return (
    <time dateTime={`${displayDate} ${displayTime}`} role="time">
      {displayDate} &nbsp;{displayTime}
    </time>
  );
}

function descendingDateSorter(key) {
  return (a, b) => new Date(b[key]) - new Date(a[key]);
}
