import { withTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon/Icon';
import './MedicationLine.css';

const MedicationLine = ({ t: __, medication, onRemove = false, status = false }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  return (
    <div className="medication-line">
      <div className="medication-line-name">
        <span>{medication.name[currentLanguage]}</span>
        {!!medication.risk_factor_ids.length && (
          <span className="medication-is-teratogenic">
            <Icon name="bell" /> {__('riskSearchBar.isTeratogenic')}
          </span>
        )}
      </div>
      <div className="medication-line-details">
        {!!medication.medications[currentLanguage].length && (
          <div className="medication-line-molecules">{medication.medications[currentLanguage].join(', ')}</div>
        )}
        <div className="medication-line-description">{medication.description[currentLanguage]}</div>
      </div>
    </div>
  );
};

export default withTranslation()(MedicationLine);
