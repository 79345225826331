import ReportTemplateHeading_v1 from './Heading/v1.js';
import ReportTemplateHeading_v2 from './Heading/v2.js';

export default function ReportTemplateHeading(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateHeading_v1(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateHeading_v2(props);
  }
  return null;
}
