import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './EditFlashReportNameDialog.css';
import { useReportDialog } from '../../../context-providers/ReportDialogProvider';
import EditFlashReportDialog from '../EditFlashReportDialog/EditFlashReportDialog';
import Button from '../../../atoms/Button/Button';
import TextInput from '../../../atoms/TextInput/TextInput';
import ResourceApi from '../../../services/resource';
import { ExaminationContext } from '../../../context-providers/Examination';
import { NotificationContext } from '../../../context-providers/Notification';
import { useXMLTemplate } from '../../../context-providers/XMLTemplate';
import Icon from '../../../atoms/Icon/Icon';

const EditFlashReportNameDialog = ({ template, onNameUpdate, closeOnUpdate, t: __ }) => {
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);
  const { updateTemplateList } = useXMLTemplate();

  const { closeModal, openModal } = useReportDialog();
  const [currentName, setCurrentName] = useState(template.name);

  const updateName = () => {
    ResourceApi.editAutomationTemplate(examinationContext.examination.preset.id, template.slug, { name: currentName })
      .then((resp) => {
        updateTemplateList(template.slug, resp.data.data);
        onNameUpdate && onNameUpdate(currentName);
        notificationContext.showNotification(
          <>
            <Icon name="done" />
            {__('reportDialog.edit.notification.editName', {
              name: currentName,
            })}
          </>,
          5000
        );
        if (closeOnUpdate) {
          closeModal();
        } else {
          openModal(<EditFlashReportDialog t={__} />);
        }
      })
      .catch((error) => {
        console.error(error);
        notificationContext.showNotification(
          <>
            <Icon name="warning" />
            {__('reportDialog.edit.notification.editNameError')}
          </>,
          5000
        );
      });
  };

  return (
    <div className="edit-flash-report-name-root">
      <div className="edit-flash-report-name-title">{__('reportDialog.editName.title')}</div>
      <TextInput variant="outline" fullwidth value={currentName} maxLength="60" onChange={setCurrentName} />
      <div className="edit-flash-report-name-buttons">
        <Button
          variant="outline"
          onClick={() => (closeOnUpdate ? closeModal() : openModal(<EditFlashReportDialog />))}
          label={__('reportDialog.confirm.cancel')}
        />
        <Button onClick={updateName} label={__('reportDialog.confirm.submit')} />
      </div>
    </div>
  );
};

export default withTranslation()(EditFlashReportNameDialog);
