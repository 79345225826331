export const placeholderToInputFormat = (format) => {
  switch (format) {
    case 'measurement':
    case 'number':
      return 'number';
    case 'date':
      return 'date';
    case 'boolean':
      // TODO display a checkbox instead of a InlineInput
      return 'string';
    default:
      // InlineInput uses string and not text as base format
      return 'string';
  }
};
