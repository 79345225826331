import React, { useState, useEffect } from 'react';
import InlineInput from '../../../../atoms/InlineEditing/InlineInput/InlineInput';
import Icon from '../../../../atoms/Icon/Icon';
import { isNullOrUndefined } from '../../../../utils';
import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';
import { placeholderIdFromProps } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';
import { placeholderToInputFormat } from './helpers';
import IsRequiredMarker from '../IsRequiredMarker';

function ReportTemplateContentBody(fullProps) {
  const [editing, setEditing] = useState('no');
  const [autofocusCounter, setAutofocusCounter] = useState(0);

  const {
    props,
    appPreferences,
    showErrors,
    openEditor = () => {},
    onEndEditing = () => {},
    showOptions: parentShowOptions = null,
    reportMode = 'edit',
    highlight = false,
    BIContext: InitialBIContext,
    placeholders,
    canEdit,
    setRequiredAndInvalid,
    renderMode,
  } = fullProps;

  const { label, width = null, required = null, align = null, decimals = null } = props ?? {};
  const fieldId = placeholderIdFromProps(props);
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const printMode = reportMode === 'print';
  const [displayTemplate, setDisplayTemplate] = useState(
    placeholdersHelper.displayedValue(fieldId, null, null, printMode)
  );
  const [edited, setEdited] = useState(false);

  const selectedDatum = placeholdersHelper.selectedDatum(fieldId);
  const visible = highlight ? true : selectedDatum?.visible ?? true;

  const attribute = placeholdersHelper.attribute(fieldId);
  const format = placeholdersHelper.format(fieldId);
  const defaultEditor = placeholdersHelper.defaultEditor(fieldId);
  const BIContext = { ...InitialBIContext, component: 'content', format };
  const inputFormat = placeholderToInputFormat(format);
  const displayTemplateUid = placeholdersHelper
    .displayedValue(fieldId, null, null, printMode)
    .map((item) => {
      if (typeof item === 'string') return item;
      return `${item.value}`;
    })
    .join(';');

  useEffect(() => {
    if (edited && editing !== 'yes') {
      const timeout = setTimeout(() => {
        const newValue = placeholdersHelper.templateToValue(fieldId, displayTemplate);
        onEndEditing(
          fieldId,
          placeholdersHelper.editSelectedDataValue(fieldId, { [attribute]: newValue }, null, 'user'),
          { BIContext }
        );
        setEdited(false);
        setEditing('no');
      }, 200);
      return () => clearTimeout(timeout);
    } else if (editing === 'transient') {
      return setTimeout(() => setEditing('no'), 200);
    } else if (editing === 'no') {
      /* We didn't edited the value and it was change in props */
      setDisplayTemplate(placeholdersHelper.displayedValue(fieldId, null, null, printMode));
    }
  }, [edited, editing, displayTemplateUid]);

  /* Decimals only applies to the last editable element */
  const lastEditableElementReverseIndex = [...displayTemplate].reverse().findIndex((item) => typeof item !== 'string');
  const lastEditableElementIndex = displayTemplate.length - lastEditableElementReverseIndex - 1;
  const lastEditableElementIntersperseIndex = lastEditableElementIndex * 2;
  const showOptions = parentShowOptions || placeholders[fieldId].showOptions !== false;

  const editable = placeholdersHelper.editable(fieldId) && canEdit && !defaultEditor && renderMode !== 'freeze';
  const value = placeholdersHelper.selectedValue(fieldId)?.[placeholdersHelper.attribute(fieldId)];

  const style = {};

  if (width) {
    style.minWidth = `min(${width}, 100%)`;
    style.maxWidth = `min(${width}, 100%)`;
  }

  if (align) style.textAlign = align;

  const labelStyle = {};
  if (props['label-width']) {
    labelStyle.width = props['label-width'];
    labelStyle.minWidth = props['label-width'];
  }

  const isRequired = required === 'true';

  const invalidValue = () => {
    return !value;
  };

  useEffect(() => {
    if (isRequired && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ? prevState.add(props.data) : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [value, setRequiredAndInvalid]);

  const saveChange = (value, index) => {
    setEditing('transient');
    if (!props.data) return false;
    setDisplayTemplate((displayTemplate) => {
      setEdited((edited) => edited || displayTemplate[index].value !== value);
      const displayTemplateCopy = [...displayTemplate];
      displayTemplateCopy[index] = { value };
      return displayTemplateCopy;
    });
  };

  const onStartEditing = () => {
    return setEditing('yes');
  };

  const focusOnInput = () => {
    setAutofocusCounter((c) => ++c);
  };

  const changeVisibility = (visible) => {
    const { value: _, ...datum } = selectedDatum;
    datum.visible = visible;
    onEndEditing(fieldId, [datum], { BIContext });
  };

  return (
    <div
      placeholder={fieldId}
      className={`
        content-wrapper exam-report-editing-field
        ${!isNullOrUndefined(value) && value !== '' ? (visible ? 'has-printable-value' : '') : 'not-printable'}
        ${required === 'true' && invalidValue() && showErrors ? 'required-error' : ''}
        ${!label || props.inline ? 'is-inline' : 'is-block'}
        ${visible ? 'is-visible' : 'not-visible'}
        ${align ? 'align-' + align : ''}
        ${props?.data ? 'is-editable' : ''}
        ${editing !== 'no' ? 'is-editing' : ''}
      `}
      style={style}
    >
      {!!label && (
        <div className="label" style={labelStyle} onClick={focusOnInput}>
          {label}
          <IsRequiredMarker visible={reportMode === 'edit' && isRequired && canEdit} />
        </div>
      )}
      <span className={`${highlight ? 'highlight-field' : ''}`}>
        {
          /* Here we intersperse template with &nbsp; so we need to create a bigger array */
          [...new Array((displayTemplate.length - 1) * 2 + 1)].map((_, index) => {
            /* if we are on a odd index it means we are on the intersperser */
            if (index % 2 === 1) return <React.Fragment key={index}>&nbsp;&nbsp;&nbsp;</React.Fragment>;

            const item = displayTemplate[index / 2];
            if (typeof item === 'string') {
              return item;
            }
            if (item && Object.prototype.hasOwnProperty.call(item, 'value'))
              return (
                <div key={index} className="content-text exam-report-editing-value" data-editing={editing !== 'no'}>
                  <InlineInput
                    autofocus={autofocusCounter}
                    value={item.value}
                    format={inputFormat}
                    decimals={index === lastEditableElementIntersperseIndex ? decimals : '0'}
                    printable={visible}
                    onStartEditing={onStartEditing}
                    onChange={(newValue) => saveChange(newValue, index / 2)}
                    appPreferences={appPreferences}
                    active={editable}
                    defaultEditor={defaultEditor && renderMode !== 'freeze' ? () => openEditor(defaultEditor) : false}
                  />
                </div>
              );
            console.error('Invalid item in displayTemplate', fieldId, displayTemplate);
            return null;
          })
        }
        {!label && <IsRequiredMarker visible={reportMode === 'edit' && isRequired && canEdit} />}
        {highlight && highlight.icon && (
          <span className={`highlight-field_icon ${highlight.iconClass}` || ''}>
            <Icon name={highlight.icon} />
          </span>
        )}
        {!highlight && editable && visible && reportMode === 'edit' && showOptions && (
          <div className="content-options exam-report-editing-options">
            <div onClick={() => changeVisibility(!visible)}>
              <Icon name={visible ? 'eye' : 'eye-off'} />
            </div>
          </div>
        )}
      </span>
      {!visible && reportMode === 'edit' && <NotVisibleOverlay onClick={() => changeVisibility(true)} />}
    </div>
  );
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateContent({ props, placeholders, ...otherProps }) {
  const fieldId = placeholderIdFromProps(props);
  return (
    <PlaceholderLoader
      Component={ReportTemplateContentBody}
      placeholders={placeholders}
      requiredPlaceholders={[fieldId, 'fetus.number', 'fetus.order']}
      fetusNumberPlaceholder={placeholders['fetus.number']}
      originalPlaceholder={placeholders[fieldId]}
      props={props}
      {...otherProps}
    />
  );
}
