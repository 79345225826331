import './Popup.css';

/**
 * <Popup
 *  onBackgroundClick={function}
 * >{children}
 * </Popup>
 */
const SimplePopup = ({ children, onBackgroundClick = () => undefined }) => {
  return (
    <div className="popup-container">
      <div className="popup">{children}</div>
      <div className="modal-background" onClick={onBackgroundClick} />
    </div>
  );
};

export default SimplePopup;
