import { createContext, useEffect, useState, useContext, useCallback } from 'react';
import { withTranslation } from 'react-i18next';

import { ExaminationContext } from './Examination';
import { NotificationContext } from './Notification';

import ResourceApi from '../services/resource';
import { getPercentileValueString, defaultLabel } from '../services/measurements';

import { MeasurementDefaultUnits } from '../config';
import { batchRequests, nestedObjectStringify } from '../utils';

import Icon from '../atoms/Icon/Icon';

export const MeasurementsContext = createContext({});
const MEASUREMENTS = '_measurements';

export const MeasurementsContextProvider = withTranslation()(
  ({ t: __, children, i18n: { language: currentLanguage } }) => {
    const examinationContext = useContext(ExaminationContext);
    const notificationContext = useContext(NotificationContext);

    const [labels, setLabels] = useState({});
    const [measurementData, setMeasurementData] = useState({});
    const optimisticUpdateEpisode = (attrs) =>
      setMeasurementData({
        ...measurementData,
        episode: { ...measurementData.episode, ...attrs },
      });
    const [grouping, setGrouping] = useState([]);
    const [formattedGrouping, setFormattedGrouping] = useState([]);
    const [groupLabels, setGroupLabels] = useState([]);

    const examId = examinationContext.examination.id;

    const getReportMeasurements = useCallback(
      batchRequests(async (examId) => {
        return await ResourceApi.getReportMeasurements(examId);
      }, 100),
      [examId]
    );

    const loadMeasurementsData = async () => {
      if (!examId) return;
      const response = await getReportMeasurements(examId);
      setMeasurementData(response.data);
    };

    const loadLabels = async () => {
      const response = await ResourceApi.getReportMeasurementLabels(examId);
      setLabels(response.data);
      setGrouping(response.data.groups);
      setFormattedGrouping(getFormattedGrouping(response.data.groups));
      setGroupLabels(response.data.group_labels);
    };

    useEffect(() => {
      const f = async () => {
        if (!examinationContext.examination.id) return;
        loadLabels();
      };
      f();
    }, [
      examinationContext.examination.id,
      examinationContext.examination.site_id,
      examinationContext.examination.preset_id,
    ]);

    /*
     * Implementing the long polling system for measurements
     * The sytem will reload the measurements when the data suscpetible to change
     * the result of measurement calculation are updated
     *
     * TODO: In order to have a more efficient and live system,
     * we should implement a websocket system to notify the client when
     * measurements are updated
     */
    useEffect(() => {
      if (!examinationContext.examination.id) return;
      loadMeasurementsData();
    }, [
      examinationContext.examination.preset_id,
      examinationContext.examination.id,
      examinationContext.dating?.value,
      examinationContext.instances
        .filter((i) => i.modality === 'SR')
        .map(({ id }) => id)
        .sort()
        .join(';'),
      nestedObjectStringify(examinationContext.examination.measurements),
    ]);

    const updateMeasurements = async (changes, opts = {}) => {
      let previousMeasurement = null;

      if (changes.fetus) {
        previousMeasurement =
          measurementData.measurements.fetus?.[changes.fetus]?.[changes.measurement_id]?.selected_value;
      } else {
        previousMeasurement = measurementData.measurements.patient?.[changes.measurement_id]?.selected_value;
      }

      const defaultBIAttributes = {
        examination_id: examId,
        examination_status: examinationContext.examination.status,
        examination_preset_id: examinationContext.examination.preset_id,
        measurement_id: changes.measurement_id,
        previous_derivation: previousMeasurement?.derivation,
        event_type: 'measurement_edited',
        page: 'measurements',
      };

      const optsBI = opts.BIContext ?? {};
      /* Overwrite default BI attributes with the ones passed in the opts. For instance by the XMLTemplate context */
      const BIAttributes = { ...defaultBIAttributes, ...optsBI };
      try {
        ResourceApi.createBIEvent({
          ...BIAttributes,
          changes,
        });
        await ResourceApi.updateMeasurements(examId, changes);
        loadMeasurementsData();
        return true;
      } catch (error) {
        notificationContext.showNotification(
          <>
            <Icon name="warning" /> {__('report.unableToUpdate')}
          </>,
          5000
        );
        return false;
      }
    };

    const removeIdentifierMeasurementRow = async (measurementId) => {
      try {
        await ResourceApi.deleteMeasurement(examId, measurementId, 'identifier');
        loadMeasurementsData();
        return true;
      } catch (error) {
        notificationContext.showNotification(
          <>
            <Icon name="warning" /> {__('report.unableToUpdate')}
          </>,
          5000
        );
        return false;
      }
    };

    const getDefaultLabel = (measurementId, bodyStructureId, lateralityId) =>
      defaultLabel(measurementId, bodyStructureId, lateralityId, labels, currentLanguage);

    // TODO: this is a lighter version of a function by the same in XMLTemplate.
    // Investigate if they can be combined in some way
    const getMeasurementObject = (slug, fetusId) => {
      if (!slug || !fetusId) return {};

      const measurement =
        fetusId === 'patient'
          ? measurementData?.measurements?.patient?.[slug]
          : measurementData?.measurements?.fetus?.[fetusId]?.[slug];
      const [measurementId, bodyStructureId, lateralityId] = slug.split(/[./]/);

      const edits = measurementData?.measurement_edits?.[fetusId]?.[slug];
      const parsedReport = labels?.parsed_template?.[slug];

      const percentiles = Object.fromEntries(
        Object.entries(measurement?.selected_value?.percentiles || {}).filter(
          ([_key, value]) => value.reference_value !== null
        )
      );

      const defaultDisplayUnit = labels?.measurement?.[measurementId]?.units;
      const displayUnit = edits?.units || parsedReport?.units || defaultDisplayUnit;
      const storedUnit = MeasurementDefaultUnits[labels?.measurement?.[measurementId]?.type];
      return {
        editedLabel: edits?.label,
        label: edits?.label || parsedReport?.label || getDefaultLabel(measurementId, bodyStructureId, lateralityId),
        value: measurement?.selected_value?.y,
        xvalue: measurement?.selected_value?.x,
        storedUnit,
        // TODO: Support multi value units
        displayUnit: displayUnit === 'lbs.oz' ? 'g' : displayUnit,
        decimals: parsedReport?.decimals || 1,
        comment: edits?.comment,
        visible: edits?.visible ?? parsedReport?.visible,
        curve_slug: edits?.curve_slug || measurement?.selected_value?.curve,
        percentile: measurement?.selected_value?.sonio_percentile,
        zscore: measurement?.selected_value?.sonio_zscore,
        reference_value: measurement?.selected_value?.reference_value,
        estimation: measurement?.selected_value?.estimation || false,
        derivation: measurement?.selected_value?.derivation || false,
        availableDerivations: (measurement?.all_values || []).map((val) => ({
          value: val.y,
          derivation: val.derivation,
        })),
        percentiles,
        availableCurveSlugs: Object.keys(percentiles || {}),
        estimatedGa: measurementData?.v2_dating_values?.find(
          (d) => d.estimation.startsWith(`${slug}.`) && d.fetus === `${fetusId}`
        ),
      };
    };

    const getIdentifierMeasurementObject = (slug, identifier) => {
      if (!slug || !identifier) return {};

      const [measurementId, bodyStructureId, lateralityId] = slug.split(/[./]/);

      const identifierSlug = `${bodyStructureId ?? ''}/${lateralityId ?? ''}/${identifier}`;

      const measurement = measurementData?.identifier_measurements?.[identifierSlug]?.[measurementId];

      const parsedReport = labels?.parsed_template?.[slug];
      const edits = measurementData?.measurement_edits?.identifier?.[identifierSlug];
      // console.log(identifierSlug)
      // console.log(measurementData?.measurement_edits?.identifier?.[identifierSlug])

      const defaultDisplayUnit = labels?.measurement?.[measurementId]?.units;
      const displayUnit = parsedReport?.units || defaultDisplayUnit;
      const storedUnit = MeasurementDefaultUnits[labels?.measurement?.[measurementId]?.type];

      return {
        value: measurement?.value,
        label: parsedReport?.label || getDefaultLabel(measurementId, bodyStructureId, lateralityId),
        storedUnit,
        displayUnit: displayUnit === 'lbs.oz' ? 'g' : displayUnit,
        decimals: parsedReport?.decimals || 1,
        identifierSlug,
        measurementId,
        visible: edits?.visible,
      };
    };

    const makeDerivationLabel = (derivationSlug, value) => {
      const [derivation, estimation] = derivationSlug.split('.');
      const derivationLabel = labels?.derivation?.[derivation]?.label?.[currentLanguage];
      return derivationLabel + (estimation ? ` (${estimation})` : '') + ` - ${value}`;
    };

    const setDefaultProperty = (obj, prop, defaultValue) => {
      if (!Object.prototype.hasOwnProperty.call(obj, prop)) obj[prop] = defaultValue;
      return obj;
    };

    function insertIntoSortedArray(array, toInsert) {
      let index = 0;
      while (index < array.length && array[index].order < toInsert.order) {
        index++;
      }
      array.splice(index, 0, toInsert);
      return array;
    }

    // Takes array of slugs with their groups and creates nested objects
    // Where the key "_measurements" exists, we have a list of measurements and subsubsection titles

    const getFormattedGrouping = (grouping) => {
      const formattedGrouping = {};
      for (const {
        measurement_slug: measurementSlug,
        section_id: sectionId,
        subsection_id: subsectionId,
        subsubsection_id: subsubsectionId,
        order,
        identifier,
      } of grouping) {
        setDefaultProperty(formattedGrouping, sectionId, {});
        setDefaultProperty(formattedGrouping[sectionId], subsectionId, {});

        if (subsubsectionId === null) {
          setDefaultProperty(formattedGrouping[sectionId][subsectionId], MEASUREMENTS, []);
          insertIntoSortedArray(formattedGrouping[sectionId][subsectionId][MEASUREMENTS], {
            slug: measurementSlug,
            order,
          });
        } else {
          setDefaultProperty(formattedGrouping[sectionId][subsectionId], subsubsectionId, {});
          setDefaultProperty(formattedGrouping[sectionId][subsectionId][subsubsectionId], MEASUREMENTS, []);
          insertIntoSortedArray(formattedGrouping[sectionId][subsectionId][subsubsectionId][MEASUREMENTS], {
            slug: measurementSlug,
            identifier,
            order,
          });
        }
      }
      return formattedGrouping;
    };

    const findMeasurementGroupLabel = (slug) => {
      const groupLabel = groupLabels.find((group) => group.slug === slug);
      if (!groupLabel) return {};
      // For use in Tabs
      const label = groupLabel?.label?.[currentLanguage] || groupLabel.slug;
      return { ...groupLabel, value: groupLabel.slug || 'ERROR', label: label };
    };

    return (
      <MeasurementsContext.Provider
        value={{
          optimisticUpdateEpisode,
          labels,
          measurementData,
          updateMeasurements,
          loadMeasurementsData,
          measurementLabels: labels,
          getMeasurementObject,
          getIdentifierMeasurementObject,
          grouping,
          formattedGrouping,
          makeDerivationLabel,
          groupLabels,
          getDefaultMeasurementLabel: getDefaultLabel,
          findMeasurementGroupLabel,
          isAssignedDatingSet: examinationContext?.examination?.dating?.assigned_dating_id,
          removeIdentifierMeasurementRow,
          getPercentileValueString,
        }}
      >
        {children}
      </MeasurementsContext.Provider>
    );
  }
);
export const useMeasurements = () => useContext(MeasurementsContext);
