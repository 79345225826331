export default function ReportTemplateSpacer({ props }) {
  const { size = 'm', style = 'space' } = props ?? {};

  const sizes = {
    big: 1,
    large: 1,
    xl: 1,
    l: 2,
    medium: 3,
    m: 3,
    small: 4,
    s: 4,
    xs: 5,
  };

  return <div className={`spacer size-${sizes[size] || 2} style-${style}`}></div>;
}
