import { placeholderIdFromProps } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';
import { useEffect } from 'react';
import IsRequiredMarker from '../IsRequiredMarker';

function ReportTemplateValueBody(fullProps) {
  const { props, reportMode = 'edit', setRequiredAndInvalid, showErrors, highlight = false } = fullProps;
  const placeholdersHelper = new PlaceholdersHelper(fullProps);

  const { required = null } = props ?? {};

  const displayedValue = placeholdersHelper.displayedValue(placeholderIdFromProps(props));
  if (!displayedValue) {
    console.error('No displayed value for', placeholderIdFromProps(props), {
      placeholdersHelper,
    });
    return null;
  }

  /* The <value> component should always return a string with no JSX. If you need JSX, please use <content> instead */
  const outputString = displayedValue
    .map((obj) => {
      if (typeof obj === 'string') {
        return obj;
      }
      return obj.value;
    })
    .join(' ');

  const isRequired = required === 'true';

  const invalidValue = () => {
    return !outputString;
  };

  useEffect(() => {
    if (isRequired && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ? prevState.add(props.data) : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [outputString, setRequiredAndInvalid]);

  const className = [];
  if (highlight) {
    className.push('highlight-field');
  }
  if (invalidValue && showErrors && isRequired) {
    className.push('empty-value required-error');
  }
  return (
    <>
      <span className={className}>{outputString}</span>
      <IsRequiredMarker visible={reportMode === 'edit' && isRequired} />
    </>
  );
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateValue({ props, placeholders, ...otherProps }) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateValueBody}
      placeholders={placeholders}
      requiredPlaceholders={[placeholderIdFromProps(props), 'fetus.number', 'fetus.order']}
      props={props}
      {...otherProps}
    />
  );
}
