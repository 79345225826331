import { Liquid, Tag } from 'liquidjs';

const engine = new Liquid();

engine.registerTag('assigns_to_json', {
  render: function* (ctx) {
    // ctx.scopes[0] is where all the assign values are available
    const assigns = yield ctx.scopes[0];
    // Convert the assigns object into a JSON string. This will be output as - is.
    return JSON.stringify(assigns);
  },
});

const getMeasurementValue = (measurementObj, key) => {
  if (key.includes('.')) {
    const keys = key.split('.');

    let value = measurementObj;
    keys.forEach((k) => {
      value = value[k];
    });

    return value;
  }
};

class assignMeasurement extends Tag {
  constructor(tagToken, remainTokens, liquid) {
    super(tagToken, remainTokens, liquid);

    this.name = this.tokenizer.readIdentifier().content;

    this.tokenizer.skipBlank();
    this.tokenizer.advance();

    this.measurementKey = this.tokenizer.readValue().getText();
  }

  *render(ctx) {
    const name = yield this.name;
    const measurementKey = yield this.measurementKey;

    const measurements = ctx.environments.measurements;
    const valueObj = getMeasurementValue(measurements, measurementKey);

    ctx.bottom()[name] = valueObj?.selected_value?.y;
  }
}

class captureRaw extends Tag {
  constructor(tagToken, remainTokens, liquid) {
    super(tagToken, remainTokens, liquid);

    const name = this.tokenizer.readIdentifier().content;

    const rawToken = remainTokens.shift();
    const token = liquid.parser.parseToken(rawToken, remainTokens);
    const content = token.str
      .trim()
      .split('\n')
      .map((c) => c.trim());
    this.rawObject = { name, content };

    remainTokens.shift(); // remove endcapture token
  }

  *render(ctx) {
    const { name, content } = yield this.rawObject;
    const contentObj = content.map((i) => JSON.parse(i));
    ctx.bottom()[name] = contentObj;
  }
}

// If value is a list - then it is for a field that is for a fetus
// If value is not a list - then it is for a maternal field
class assignFetus extends Tag {
  constructor(tagToken, remainTokens, liquid) {
    super(tagToken, remainTokens, liquid);

    this.name = this.tokenizer.readIdentifier().content;

    this.tokenizer.skipBlank();
    this.tokenizer.advance();

    this.value = this.tokenizer.readValue().getText();
  }

  *render(ctx) {
    const name = yield this.name;
    const value = yield this.value;

    // Used for multiple fetus case
    if (isJson(value)) {
      ctx.bottom()[name] = JSON.parse(value);
    } else {
      ctx.bottom()[name] = value.replaceAll('"', '');
    }
  }
}

class captureFetalAnatomy extends Tag {
  constructor(tagToken, remainTokens, liquid) {
    super(tagToken, remainTokens, liquid);

    this.status = this.tokenizer.readIdentifier().content;

    const rawToken = remainTokens.shift();
    const token = liquid.parser.parseToken(rawToken, remainTokens);
    this.checklistItems = token.str
      .replaceAll('\n', '')
      .trim()
      .split(',')
      .map((c) => c.trim());

    remainTokens.shift(); // remove endcapture token
  }

  *render(ctx) {
    const status = yield this.status;
    const checklistItems = yield this.checklistItems;

    if (!ctx.bottom().hasOwnProperty('fetalAnatomy')) {
      ctx.bottom().fetalAnatomy = {};
    }

    ctx.bottom().fetalAnatomy[status] = checklistItems;
  }
}

// assign_measurement
// Example: {% assign_measurement test = patient.afi__amniotic_sac %}
engine.registerTag('assign_measurement', assignMeasurement);

// capture_raw
/**
 * Example to assign examination__indication:
 * {% capture_raw examination__indication %}
 * { "Suspected Fetal Heart Defect, singleton pregnancy": { "codes": "O35.BXX0", "codes_object": [ { "applicable_filters": { "fetus": 1, "max_number_of_fetuses": 1, "min_number_of_fetuses": 1, "trimesters": [ "one", "two", "three" ] }, "code": "O35.BXX0", "description": "Maternal care for other (suspected) fetal abnormality and damage, fetal cardiac anomalies, not applicable or unspecified", "origin_value": "Suspected Fetal Heart Defect, singleton pregnancy" } ], "connector": "Elixir.SonioCore.Integration.Connector.Dictionary", "label": "Suspected Fetal Heart Defect, singleton pregnancy", "order": 0, "private": { "concept_codes": [ "O35.BXX%FETUS%" ] }, "value": true } }
 * { "Activated protein C resistance affecting pregnancy": { "codes": "", "codes_object": [ { "code": "O99.119", "description": "Other diseases of the blood and blood-forming organs and certain disorders involving the immune mechanism complicating pregnancy, unspecified trimester", "origin_value": "Activated protein C resistance affecting pregnancy" }, { "code": "D68.51", "description": "Activated protein C resistance", "origin_value": "Activated protein C resistance affecting pregnancy" } ], "label": "Activated protein C resistance affecting pregnancy", "order": 1, "value": true } }
 * {% endcapture %}
 */
engine.registerTag('capture_raw', captureRaw);

engine.registerTag('assign', assignFetus);

/*
{% capture_fetal_anatomy usual %}
four-chambers,anterior-horns
{% endcapture %}
*/
engine.registerTag('capture_fetal_anatomy', captureFetalAnatomy);

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const evalTemplate = (template, data) => {
  const output = engine.parseAndRenderSync(template, data);

  // For debugging purposes
  if (window.showEvaluatedAutomationTemplate) {
    console.log(output);
  }

  return output;
};

window.evalTemplate = evalTemplate;

export default evalTemplate;
