import { useState, useEffect } from 'react';
/*
 * @desc - Custom hook - return true if the passed uid is not modified during the last stabilityDelay milliseconds
 * If the uid is stable for irremovableDelay milliseconds, the hook will always return true no matter what
 * @param {number} stabilityDelay - The delay in milliseconds to consider the uid stable
 * @param {number} irremovableDelay - The delay in milliseconds to consider the uid irremovable
 * @param {boolean} forceUnstable - If true, the hook will always return false
 * @param {Array} dependencies - Dependencies to trigger the stability check on
 */

const stability = {
  STABLE: 'stable',
  UNSTABLE: 'unstable',
  IRREMOVABLE: 'irremovable',
};

export default function useStability(
  stabilityDelay = 300,
  irremovableDelay = 5_000,
  forceUnstable = false,
  dependecies = []
) {
  const [stable, setStable] = useState(stability.UNSTABLE);
  const fullDeps = [...dependecies, forceUnstable];
  useEffect(() => {
    /* If the hook went into the irremovable stability state, it should not go back to the unstable state */
    if (stable === stability.IRREMOVABLE) return;
    setStable(stability.UNSTABLE);

    /* If the hook is forced to be unstable, it should not go to the stable state */
    if (forceUnstable) return;

    const timeout = setTimeout(() => {
      setStable(stability.STABLE);
    }, stabilityDelay);
    return () => clearTimeout(timeout);
  }, fullDeps);

  useEffect(() => {
    if (stable === stability.IRREMOVABLE) return;
    if (stable === stability.UNSTABLE) return;
    const timeout = setTimeout(() => {
      setStable(stability.IRREMOVABLE);
    }, irremovableDelay);
    return () => clearTimeout(timeout);
  }, [stable]);
  return stable === stability.STABLE || stable === stability.IRREMOVABLE;
}
