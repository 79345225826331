import React, { useContext } from 'react';
import './Alert.css';
import { NotificationContext } from '../../context-providers/Notification';
import Icon from '../../atoms/Icon/Icon';

const Alert = ({ notification }) => {
  const { removeNotification } = useContext(NotificationContext);

  return (
    <div
      className={`alert ${notification.duration ? 'animate' : ''}`}
      style={{ '--duration': notification.duration + 'ms' }}
    >
      <div>{notification.text}</div>
      <div className="close">
        <Icon name="close" onClick={() => removeNotification(notification.key)} />
      </div>
    </div>
  );
};

export default Alert;
