import Icon from '../../atoms/Icon/Icon';
import { formatName } from '../../services/examination';
import './ContactPointCard.css';

const ContactPointCard = ({ contact, icon = 'user', onRemove = false }) => {
  return (
    !!contact && (
      <div className="contact-point-card">
        <div className="contact-point-card_icon">
          <Icon name={icon} />
        </div>
        <div className="contact-point-card_name">
          <div className="contact-point-card_role">{contact.role}</div>
          {formatName(contact.name).fullName}
          {!!contact.external_id && <div className="contact-point-card_external-id">{contact.external_id}</div>}
        </div>
        <div className="contact-point-card_contacts">
          {!!contact.email && (
            <div className="contact-point-card_email">
              <Icon name="mail" /> {contact.email}
            </div>
          )}
          {!!contact.phone && (
            <div className="contact-point-card_phone">
              <Icon name="phone" /> {contact.phone}
            </div>
          )}
          {!!contact.fax && (
            <div className="contact-point-card_fax">
              <Icon name="fax" /> {contact.fax}
            </div>
          )}
        </div>
        {!!onRemove && (
          <div className="contact-point-card_remove" onClick={() => onRemove(contact)}>
            <Icon name="trash" />
          </div>
        )}
      </div>
    )
  );
};

export default ContactPointCard;
