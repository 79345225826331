export const isList = (value) => Array.isArray(value);
export const isEmptyList = (array) => isList(array) && array.length === 0;

export const extractProtoSetting = (userPreselectConfig, presetId) => {
  const printPreselect = userPreselectConfig?.printing_preset;
  return printPreselect && printPreselect[presetId + ''];
};

export const lastPersonalisedProtoSetting = (userPreselectConfig, presetId) => {
  const savedPrintingPreset = userPreselectConfig?.printing_preset_saved;
  return savedPrintingPreset && savedPrintingPreset[presetId + ''];
};
