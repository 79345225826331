import { withTranslation } from 'react-i18next';

/* Utils */
import { MeasurementDefaultUnits } from '../../../../config';
import { convertValueToSelectedUnit } from '../../../../unitConverter';
import { measurementLabel } from '../../../../biometryUtils';

/* Atoms */
import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';
import Icon from '../../../../atoms/Icon/Icon';
import InlineInput from '../../../../atoms/InlineEditing/InlineInput/InlineInput';

/* Placeholders loader */
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';

// TODO this component shouldn't exists and should be a non orderable table with displayed columns label
// To be able to have it we need to better handle table format and convertion of number / measurement passed in it
function IdentifierBiometryBody(fullProps) {
  const {
    t: __,
    i18n: { language: currentLanguage },
    props,
    onEndEditing,
    reportMode,
    reportDataOptions,
    fieldId,
    BIContext,
    canEdit,
  } = fullProps;

  const { site, label } = props;
  const sortBy = props['sort-by'] ?? 'order';
  const laterality = props.laterality;

  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const attribute = placeholdersHelper.attribute(fieldId);
  const slugStart = `${site}/${laterality}`;
  const allValues = placeholdersHelper.selectedValue(fieldId)?.value || {};

  let columns;
  switch (site) {
    case 'ovarian_follicle':
      columns = ['follicle_diameter', 'long_axis', 'radius', 'short_axis', 'volume'];
      break;
    default:
      columns = ['length', 'width', 'height', 'volume'];
      break;
  }

  if (site == null || laterality == null) {
    // TODO put this in the debug panel
    console.error('<identifier-biometry> Please specify site and laterality attributes');
    return null;
  }

  const sortedCollection = Object.entries(allValues)
    .map(([id, value]) => ({ id, ...value }))
    .sort((a, b) => Number(a?.[sortBy]) - Number(b?.[sortBy]));

  const headerVisible = sortedCollection.some(({ visible }) => visible);
  const maxId = Math.max(Math.max(...sortedCollection.map(({ order }) => Number(order))), 0);

  const addRow = () => {
    const nextId = maxId + 1;
    const slug = `${slugStart}/${nextId}`;
    const newValue = { [slug]: { visible: true, order: nextId }, ...allValues };
    saveChange(newValue);
  };

  const onEditRow = (id, field, value) => {
    const newValue = { ...allValues };
    newValue[id][field] = value;
    saveChange(newValue);
  };

  const removeRow = (slug) => {
    const newValue = { ...allValues };
    delete newValue[slug];
    saveChange(newValue);
  };

  const saveChange = (value) => {
    const newValue = value;
    onEndEditing(fieldId, placeholdersHelper.editSelectedDataValue(fieldId, { [attribute]: newValue }, null, 'user'), {
      BIContext,
    });
  };

  return (
    <div className="identifier-biometry-table">
      {label && (
        <span className={`${headerVisible ? 'is-visible is-printable' : 'not-visible not-printable'}`}>{label}</span>
      )}
      <div
        className={
          'column-heading identifier-biometry-id ' +
          `${headerVisible ? 'is-visible is-printable has-printable-value' : 'not-visible not-printable'}`
        }
      >
        {['id', ...columns, 'comment'].map((label, index) => {
          switch (label) {
            case 'id':
              label = __('report.identifierBiometries.id');
              break;
            case 'comment':
              label = __('report.identifierBiometries.comment');
              break;
            default:
              label = measurementLabel(label, reportDataOptions?.labels, currentLanguage);
              break;
          }
          return <div key={index}>{label}</div>;
        })}
      </div>

      {sortedCollection.map(({ id, order, visible, comment, source, ...measurements }) => {
        return (
          <div key={id} className={`identifier-biometry-id ${visible ? 'is-visible' : 'not-visible'}`}>
            <div className="measurement-list">
              <span>{order}</span>
            </div>
            <ItemMeasurements
              currentLanguage={currentLanguage}
              item={measurements}
              columns={columns}
              saveChange={(measurement_id, value) => onEditRow(id, measurement_id, value)}
              reportDataOptions={reportDataOptions}
            />
            <div className="measurement-list">
              <InlineInput
                value={comment}
                format="string"
                printable={true}
                onChange={(comment) => onEditRow(id, 'comment', comment)}
              />
            </div>
            {visible && (
              <div className="exam-report-editing-options">
                <div onClick={() => onEditRow(id, 'visible', !visible)}>
                  <Icon name={visible ? 'eye' : 'eye-off'} />
                </div>
                {source === 'edit' && order === maxId && (
                  <div onClick={() => removeRow(id)}>
                    <Icon name="trash" />
                  </div>
                )}
              </div>
            )}
            {!visible && <NotVisibleOverlay onClick={() => onEditRow(id, 'visible', !visible)} />}
          </div>
        );
      })}
      {reportMode === 'edit' && canEdit && (
        <div className="identifier-biometry add-row" onClick={addRow}>
          <div className="plus-icon">
            <Icon name="add" />
          </div>
        </div>
      )}
    </div>
  );
}

const ItemMeasurements = ({ item, reportDataOptions, columns, saveChange }) => {
  return columns.map((column) => {
    const storedUnit = MeasurementDefaultUnits[reportDataOptions?.labels?.measurement?.[column]?.type];
    const defaultDisplayUnit = reportDataOptions?.labels?.measurement?.[column]?.units;

    const unit = item?.[column]?.unit || defaultDisplayUnit;
    const value = item?.[column]?.value ? convertValueToSelectedUnit(item[column].value, storedUnit, unit) : '';

    const decimals = {
      cyst_diameter: 1,
      volume: 2,
      follicle_diameter: 2,
      long_axis: 2,
      radius: 2,
      short_axis: 2,
      length: 1,
      width: 1,
      height: 1,
    };

    const saveChangeValue = (value) => {
      value = convertValueToSelectedUnit(value, unit, storedUnit);
      saveChange(column, { value, unit });
    };

    return (
      <div className="measurement-list" key={column}>
        <InlineInput
          value={value === '' ? '' : Number(value).toFixed(decimals[column])}
          format="number"
          printable={true}
          onChange={saveChangeValue}
        />
        &nbsp;
        {value === '' ? '' : unit}
      </div>
    );
  });
};

const WithTranslationIdentifierBiometryBody = withTranslation()(IdentifierBiometryBody);

/* This is just a squelton to ensure placeholders are loaded */
export default function IdentifierBiometry({ props, ...otherProps }) {
  const slugStart = `${props.site}/${props.laterality}`;
  const fieldId = `identifier_measurement.${slugStart}`;

  return (
    <PlaceholderLoader
      Component={WithTranslationIdentifierBiometryBody}
      props={props}
      requiredPlaceholders={[fieldId, 'fetus.order']}
      fieldId={fieldId}
      {...otherProps}
    />
  );
}
