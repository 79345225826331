import { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { formatProbability, getSyndromeName } from '../../services/examination';
import { ExaminationContext } from '../../context-providers/Examination';
import './SyndromeLine.css';
import Icon from '../../atoms/Icon/Icon';
import useAuth from '../../context-providers/Auth';

const SyndromeLine = ({
  t: __,
  syndrome,
  malformations = [],
  mode = 'normal',
  onClick = null,
  onHideClick = null,
  onPinClick = null,
  onMalformationClick = null,
  isHidden,
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();
  const fullSyndrome = examinationContext.getSyndromeById(syndrome.id);
  const sName = getSyndromeName(fullSyndrome, examinationContext.MALFORMATIONS, syndrome.malformations, __);

  /**
   * Compact mode
   */
  if (mode === 'compact') {
    return (
      <div
        className={`syndrome-line compact-mode ${onHideClick ? 'is-discarded' : ''} ${onPinClick ? 'is-pinned' : ''} ${
          isHidden ? 'is-hidden' : ''
        }`}
      >
        <div className="syndrome-line-heading" onClick={() => (onClick ? onClick(syndrome.id) : false)}>
          {onHideClick && (
            <span className="syndrome-line-icon">
              <Icon name="eye-off" onClick={() => (onHideClick ? onHideClick(syndrome.id, false) : false)} />
            </span>
          )}
          {onPinClick && (
            <span className="syndrome-line-icon">
              <Icon
                name="pin"
                onClick={(e) => {
                  e.stopPropagation();
                  return onPinClick(syndrome.id, false);
                }}
              />
            </span>
          )}
          <span className="syndrome-line-name">
            {sName}
            {isFeatureFlagEnabled('sonio.dx_syndrome_summary') && (
              <>
                {fullSyndrome?.main_biological_test?.name[currentLanguage] && (
                  <> - {fullSyndrome?.main_biological_test?.name[currentLanguage]}</>
                )}
                {fullSyndrome?.abstract[currentLanguage] && (
                  <>
                    <br />
                    <small>{fullSyndrome?.abstract[currentLanguage]}</small>
                  </>
                )}
              </>
            )}
          </span>
          <span className="syndrome-line-score">
            <Icon name="matching" title={__('examination.matchingScore')} />{' '}
            <span>
              <b>{formatProbability(syndrome.probability || 0)}</b>
              <small> / 10</small>
            </span>
          </span>
          {onClick && (
            <span className="syndrome-line-cta">
              <Icon name="right" />
            </span>
          )}
        </div>
      </div>
    );
  }

  /**
   * Normal mode
   */
  return (
    <div
      className={`syndrome-line normal-mode ${onHideClick ? 'is-discarded' : ''} ${onPinClick ? 'is-pinned' : ''} ${
        isHidden ? 'is-hidden' : ''
      }`}
    >
      <div className="syndrome-line-heading" onClick={() => (onClick ? onClick(syndrome.id) : false)}>
        {onHideClick && (
          <span className="syndrome-line-icon">
            <Icon
              name="eye-off"
              onClick={(e) => {
                e.stopPropagation();
                return onHideClick(syndrome.id, false);
              }}
            />
          </span>
        )}
        {onPinClick && (
          <span className="syndrome-line-icon">
            <Icon
              name="pin"
              onClick={(e) => {
                e.stopPropagation();
                return onPinClick(syndrome.id, false);
              }}
            />
          </span>
        )}
        <span className="syndrome-line-name">
          {sName}
          {isFeatureFlagEnabled('sonio.dx_syndrome_summary') && (
            <>
              {fullSyndrome?.main_biological_test?.name[currentLanguage] && (
                <> - {fullSyndrome?.main_biological_test?.name[currentLanguage]}</>
              )}
              {fullSyndrome?.abstract[currentLanguage] && (
                <>
                  <br />
                  <small>{fullSyndrome?.abstract[currentLanguage]}</small>
                </>
              )}
            </>
          )}
        </span>
        <span className="syndrome-line-score">
          <Icon name="matching" title={__('examination.matchingScore')} />{' '}
          <span>
            <b>{formatProbability(syndrome.probability || 0)}</b>
            <small> / 10</small>
          </span>
        </span>
        {onClick && (
          <span className="syndrome-line-cta">
            <Icon name="right" />
          </span>
        )}
      </div>
      {!!malformations.length && (
        <div className="syndrome-line-details">
          <label>{__('dx.checklist.stillToCheck')}</label>
          <div className="syndrome-line-malformations">
            {malformations.map((malformationId) => (
              <div
                key={malformationId}
                className="syndrome-line-malformation"
                onClick={() => (onMalformationClick ? onMalformationClick(malformationId) : false)}
              >
                {examinationContext.getMalformationById(malformationId).canonical_name[currentLanguage]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(SyndromeLine);
