import { useContext, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../../../context-providers/App';
import { ExaminationContext } from '../../../context-providers/Examination';
import ExamPresetButtons from '../../../components/ExamPresetButtons/ExamPresetButtons';
import ProgressRing from '../../../atoms/ProgressRing/ProgressRing';
import './ExamCompletedBanner.css';
import Button from '../../../atoms/Button/Button';

const ExamCompletedBanner = ({ t: __, medias, unusualMedias, trimester, instanceViews, setShownPlanes }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);

  const allSlides = useMemo(
    () =>
      instanceViews
        .filter((instance) => instance.type === 'picture')
        .reduce((sum, instance) => {
          // multi-instance support
          if (!sum[instance.id]) sum[instance.id] = { ...instance, medias: 0 };
          sum[instance.id].medias += Number(instance.medias[trimester]);
          return sum;
        }, [])
        .flat(),
    [instanceViews, appContext.preferences.default_category]
  );

  const expectedMedias = useMemo(
    () => allSlides.reduce((count, slide) => (count += slide.medias || 0), 0),
    [allSlides]
  );
  const takenMedias = useMemo(() => medias.filter((m) => m.dicom_media_type === 'image').length, [medias.length]);

  return (
    <div className="exam-completed-banner-dt-container">
      <div className="exam-completed-banner-dt">
        <div className="exam-completed-banner-dt-title">
          <h2>{__('examinationReview.examinationFinished')}</h2>
        </div>
        <div className="exam-completed-banner-dt-stats">
          <div
            className={`exam-completed-banner-dt-stat ${unusualMedias.length > 0 ? 'ko' : 'ok'}`}
            onClick={() => setShownPlanes('taken')}
          >
            <div className="exam-completed-banner-dt-stat-graph">
              <ProgressRing
                progress={Math.min(100, (100 / expectedMedias) * takenMedias)}
                stroke="1"
                color={takenMedias < expectedMedias ? 'ocra' : 'blue'}
              />
            </div>
            <label>
              <b>{takenMedias}</b> / {expectedMedias}
              <br />
              {__('examination.stats.takenMediasVsExpected')}
            </label>
          </div>
        </div>
        <div className="exam-completed-banner-dt-editexam">
          <Button label={__('examinationReview.editExam')} onClick={() => examinationContext.reopenExamination()} />
        </div>
      </div>
      <div className="exam-completed-banner-dt-new-exam">
        <div>
          <label>{__('examinationReview.startNewExam')}</label>
          <ExamPresetButtons action="new-exam" label="" size="compact" theme="light" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ExamCompletedBanner);
