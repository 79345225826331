import ReportTemplateIdentifierBiometry_v1_0 from './IdentifierBiometry/v1.0.js';
import ReportTemplateIdentifierBiometry_v2_0 from './IdentifierBiometry/v2.0.js';

export default function ReportTemplateIdentifierBiometry(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateIdentifierBiometry_v1_0(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateIdentifierBiometry_v2_0(props);
  }
  return null;
}
