import { withTranslation } from 'react-i18next';
import { createFullName, formatName } from '../../services/examination';
import { useContext, useEffect, useState } from 'react';
import { ExaminationContext } from '../../context-providers/Examination';
import Icon from '../../atoms/Icon/Icon';
import TextInput from '../../atoms/TextInput/TextInput';
import Button from '../../atoms/Button/Button';
import './PatientNameValidation.css';

export const isPatientNameValid = (patient) => {
  if (patient) {
    const parsed = formatName(patient.name);
    return parsed.lastName.trim().length > 0 && !/\d/.test(parsed.lastName);
  } else {
    return false;
  }
};

const PatientNameValidation = ({ t: __, onClose, onConfirm }) => {
  const [name, setName] = useState({ firstName: '', lastName: '', middleName: '', prefix: '', fullName: '' });
  const [lastNameValid, setLastNameValid] = useState(false);
  const examinationContext = useContext(ExaminationContext);

  useEffect(() => {
    if (examinationContext.patient) {
      const parsed = formatName(examinationContext.patient.name);
      setName(parsed);
      setLastNameValid(parsed.lastName.trim().length > 0 && !/\d/.test(parsed.lastName));
    } else {
      setName({ firstName: '', lastName: '', middleName: '', prefix: '', fullName: '' });
      setLastNameValid(false);
    }
  }, [examinationContext.patient]);

  const updateFirstName = (value) => {
    name.firstName = value;
    setName(name);
  };

  const updateLastName = (value) => {
    name.lastName = value;
    setName(name);
  };

  const updatePatient = async () => {
    if (name.lastName.trim().length > 0) {
      onConfirm();
    }
    examinationContext.updatePatient(examinationContext.patient.id, {
      name: createFullName(name.lastName.trim(), name.middleName.trim(), name.firstName.trim(), name.prefix.trim()),
    });
  };

  const lastNameWithNumber = /\d/.test(name.lastName);

  return (
    <>
      <div className="examination-patient-name-validation-header">
        {!lastNameValid && (
          <h4>
            {lastNameWithNumber
              ? __('examinationReview.patientLastName.withNumber')
              : __('examinationReview.patientLastName.empty')}
          </h4>
        )}
        <div>{__('examinationReview.patientLastName.verifyAndUpdate')}</div>
      </div>
      <div className="w12 examination-patient-name-validation">
        <div className="row">
          <div className="w12">
            <TextInput
              className="ph-no-capture"
              value={name.lastName}
              label={__('patient_details.lastname')}
              onChange={(value) => updateLastName(value)}
              maxLength="30"
              fullwidth
            />
          </div>
        </div>
        <div className="row">
          <div className="w12">
            <TextInput
              className="ph-no-capture"
              value={name.firstName}
              label={__('patient_details.firstname')}
              onChange={(value) => updateFirstName(value)}
              maxLength="30"
              fullwidth
            />
          </div>
        </div>
        <div className="row actions">
          <div className="w12">
            <Button label={__('examinationReview.patientLastName.close')} variant="outline" onClick={onClose} />
            <Button label={__('examinationReview.patientLastName.updateAndShare')} onClick={updatePatient} />
          </div>
        </div>
      </div>

      <div className="share-popup-close" onClick={onClose}>
        <Icon name="close" />
      </div>
    </>
  );
};
export default withTranslation()(PatientNameValidation);
