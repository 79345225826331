import { withTranslation } from 'react-i18next';
import Popup from '../components/Popup/Popup';
import Button from '../atoms/Button/Button';
import './ReassociationDialog.css';
import LoaderInline from '../atoms/LoaderInline/LoaderInline';
import Icon from '../atoms/Icon/Icon';

const ReassociationDialog = ({
  t: __,
  trimester,
  targetTrimester,
  confirmSetPreset,
  setReassociating,
  reassociating,
  reassociatingTimedOut,
  setShowReassociationDialog,
  setChangingTrimester,
}) => {
  return (
    <Popup
      message=""
      cta={
        <>
          <h2>{__('examination.confirmTrimesterChange')}</h2>
          {trimester !== 'ND' && <div>{__('examination.confirmTrimesterChange.hint')}</div>}
          <br />
          <Button
            label={__('examination.confirmTrimesterChangeCancel')}
            variant="outline"
            onClick={() => setShowReassociationDialog(false)}
          />
          &nbsp;
          <Button
            label={__('examination.confirmTrimesterChangeYes')}
            disabled={reassociating}
            onClick={() => {
              setReassociating(true);
              if (!isNaN(targetTrimester)) confirmSetPreset(targetTrimester);
            }}
          />
          {reassociating && (
            <div className="reassociation-dialog-progress">
              <div className="reassociation-dialog-line">
                <LoaderInline />
                {__('examination.confirmTrimesterChangeReassociating')}
              </div>
              {reassociatingTimedOut && (
                <div className="reassociation-dialog-line">
                  <Icon name="warning" />
                  {__('examination.confirmTrimesterChangeReassociating.timedOut')}
                  <Button
                    size="small"
                    variant="outline"
                    label={__('examination.confirmTrimesterChangeReassociating.cancel')}
                    onClick={() => {
                      setShowReassociationDialog(false);
                      setReassociating(false);
                      setChangingTrimester(false);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      }
    />
  );
};

export default withTranslation()(ReassociationDialog);
