import SectionsNavBar from '../SectionsNavBar/SectionsNavBar';
import { withTranslation } from 'react-i18next';

const ExamNavBar = ({
  t: __,
  medicalHistory = {},
  selected = '',
  anomalies = [],
  videos = [],
  refs = {},
  disableOptions = false,
  disableActions = false,
  onOptionClick = () => {},
  onChangeSection = () => {},
}) => {
  const getSections = () => {
    const sections = [];

    sections.push({
      id: 'images',
      icon: 'image',
      title: __('examination.sidebar.images'),
      counter: anomalies.length,
      target: refs.imageChapterRef?.current,
    });

    sections.push({
      id: 'videos',
      icon: 'video',
      title: __('examination.sidebar.videos'),
      disabled: !videos.length,
      target: refs.videoChapterRef?.current,
    });

    return sections;
  };

  return (
    <SectionsNavBar
      onOptionClickCurrentOption={onChangeSection}
      options={getSections()}
      disableOptions={disableOptions}
      disableActions={disableActions}
      actions={[
        {
          icon: 'paste',
          label: __('examination.sidebar.phenotype'),
          selected: selected === 'phenotype',
          counter: medicalHistory.filter((item) => item.is_risky).length,
          onClick: () => onOptionClick('phenotype'),
        },
      ]}
      actionsAfter={[
        {
          icon: 'report',
          label: __('examination.sidebar.stats'),
          selected: selected === 'stats',
          onClick: () => onOptionClick('stats'),
        },
      ]}
    />
  );
};

export default withTranslation()(ExamNavBar);
