import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './EditModeDialog.css';
import { useReportDialog } from '../../../context-providers/ReportDialogProvider';
import EditFlashReportNameDialog from '../EditFlashReportNameDialog/EditFlashReportNameDialog';
import { useXMLTemplate } from '../../../context-providers/XMLTemplate';
import Button from '../../../atoms/Button/Button';
import { ExaminationContext } from '../../../context-providers/Examination';
import { NotificationContext } from '../../../context-providers/Notification';
import ResourceApi from '../../../services/resource';
import Icon from '../../../atoms/Icon/Icon';
import TextInput from '../../../atoms/TextInput/TextInput';
import { generateRandomString } from '../../../utils';

const EditModeDialog = ({ t: __ }) => {
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);

  const { openModal } = useReportDialog();
  const {
    addAutomationTemplate,
    setIsEditMode,
    generateAutomationTemplate,
    currentTemplate,
    setAutomationTemplateFieldsVisible,
    reportDataOptions,
  } = useXMLTemplate();

  const isCreating = !currentTemplate?.name;
  const [currentName, setCurrentName] = useState(currentTemplate?.name || '');

  const onEditName = (name) => {
    setCurrentName(name);
  };

  const onQuit = () => {
    setIsEditMode(false);
    setAutomationTemplateFieldsVisible(false);
  };

  const onRename = () => {
    openModal(<EditFlashReportNameDialog t={__} template={currentTemplate} onNameUpdate={onEditName} closeOnUpdate />);
  };

  const onSave = () => {
    if (reportDataOptions.automation_templates.map((template) => template.name).indexOf(currentName) !== -1) {
      notificationContext.showNotification(
        <>
          <Icon name="warning" />
          {__('reportDialog.edit.notification.alreadyExist')}
        </>,
        5000
      );
    } else if (examinationContext.examination?.preset) {
      generateAutomationTemplate().then((template) => {
        if (isCreating) {
          const newTemplate = {
            slug: `${currentName.toLowerCase().replaceAll(' ', '-')}-${generateRandomString()}`,
            name: currentName,
            template,
            report_version: examinationContext?.examination?.report_version,
            examination_id: examinationContext?.examination?.id,
          };
          ResourceApi.createAutomationTemplate(examinationContext.examination.preset.id, newTemplate)
            .then(() => {
              notificationContext.showNotification(
                <>
                  <Icon name="done" />
                  {__('reportDialog.edit.notification.create', {
                    name: currentName,
                  })}
                </>,
                5000
              );
              addAutomationTemplate(newTemplate);
              setIsEditMode(false);
              setAutomationTemplateFieldsVisible(false);
            })
            .catch((error) => {
              console.error(error);
              notificationContext.showNotification(
                <>
                  <Icon name="warning" />
                  {__('reportDialog.edit.notification.createError')}
                </>,
                5000
              );
            });
        } else {
          ResourceApi.editAutomationTemplate(examinationContext.examination.preset.id, currentTemplate.slug, {
            template,
          })
            .then(() => {
              notificationContext.showNotification(
                <>
                  <Icon name="done" />
                  {__('reportDialog.edit.notification.edit', {
                    name: currentTemplate.name,
                  })}
                </>,
                5000
              );
              setIsEditMode(false);
              setAutomationTemplateFieldsVisible(false);
            })
            .catch((error) => {
              console.error(error);
              notificationContext.showNotification(
                <>
                  <Icon name="warning" />
                  {__('reportDialog.edit.notification.editError')}
                </>,
                5000
              );
            });
        }
      });
    } else {
      console.error('Cannot edit flash report without examination preset.');
      notificationContext.showNotification(
        <>
          <Icon name="warning" />
          {__('reportDialog.edit.notification.editError')}
        </>,
        5000
      );
    }
  };

  return (
    <div className="edit-mode-dialog-root">
      <div className="edit-mode-dialog-title">
        {isCreating ? 'Create new flash report' : `EDIT MODE - ${currentName}`}
      </div>
      <div className="edit-mode-dialog-buttons">
        <Button
          customClass="edit-mode-dialog-button-outline"
          size="small"
          color="none"
          icon="close"
          onClick={onQuit}
          label={__('reportDialog.edit.close')}
        />
        <span className="edit-mode-dialog-buttons-right">
          {isCreating ? (
            <TextInput variant="outline" value={currentName} maxLength="60" onChange={setCurrentName} />
          ) : (
            <Button
              customClass="edit-mode-dialog-button-outline"
              size="small"
              color="dark-blue"
              onClick={onRename}
              label={__('reportDialog.edit.rename')}
              icon="edit"
            />
          )}
          <Button
            disabled={isCreating && !currentName}
            customClass="edit-mode-dialog-button-fill"
            size="small"
            icon="done"
            onClick={onSave}
            label={__('reportDialog.edit.save')}
          />
        </span>
      </div>
    </div>
  );
};

export default withTranslation()(EditModeDialog);
