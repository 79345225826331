export default {
  StudyID: 'GO-003109065',
  Modality: 'SR',
  PatientID: 'Q859753',
  StudyDate: '20240626',
  StudyTime: '092031',
  ValueType: 'CONTAINER',
  PatientAge: '029Y',
  PatientSex: 'F',
  ContentDate: '20240626',
  ContentTime: '094625',
  PatientName: 'PROTSENKO^VALENTYNA',
  SOPClassUID: '1.2.840.10008.5.1.4.1.1.88.33',
  StationName: 'GSHOGEUS3',
  Manufacturer: 'GE Healthcare',
  SeriesNumber: '1',
  OperatorsName: 'SD',
  CompletionFlag: 'PARTIAL',
  InstanceNumber: '1',
  PatientAddress: '8601 LINCOLN BLVD APT 1413, LOS ANGELES, CA, 90045',
  PrivateCreator: 'GE_GROUP',
  SOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719413186.3',
  AccessionNumber: 'GO-003109065',
  ContentSequence: [
    {
      ValueType: 'CONTAINER',
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '121118',
          CodeMeaning: 'Patient Characteristics',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          Date: '20240820',
          ValueType: 'DATE',
          RelationshipType: 'CONTAINS',
          ConceptNameCodeSequence: [
            {
              CodeValue: '11778-8',
              CodeMeaning: 'EDD',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '225',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: 'd',
                  CodeMeaning: 'Day',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-05',
              CodeMeaning: 'Gestational Age by EDD',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              TextValue: 'A',
              ValueType: 'TEXT',
              RelationshipType: 'HAS OBS CONTEXT',
              ConceptNameCodeSequence: [
                {
                  CodeValue: '121030',
                  CodeMeaning: 'Subject ID',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'HAS OBS CONTEXT',
              MeasuredValueSequence: [
                {
                  NumericValue: '2',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '1',
                      CodeMeaning: 'no units',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11878-6',
                  CodeMeaning: 'Number of Fetuses',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/FL-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11920-6',
                      CodeMeaning: 'FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '15',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '205',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/AC-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11892-7',
                      CodeMeaning: 'AC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '15',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '209',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/BPD-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11902-4',
                      CodeMeaning: 'BPD, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '22',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '216',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/HC-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11932-1',
                      CodeMeaning: 'HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '14',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '209',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '210',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11884-4',
                  CodeMeaning: 'Average Ultrasound Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              Date: '20240904',
              ValueType: 'DATE',
              RelationshipType: 'CONTAINS',
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11781-2',
                  CodeMeaning: 'EDD from average ultrasound age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '146.902173913044',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '{H.B.}/min',
                      CodeMeaning: 'bpm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11948-7',
                  CodeMeaning: 'Fetal Heart Rate',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11750-7',
                      CodeMeaning: 'EFW by AC, FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1469.21194299571',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11739-0',
                      CodeMeaning: 'EFW by AC, BPD, Shepard 1982',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1542.94993327898',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11735-8',
                      CodeMeaning: 'EFW by AC, BPD, FL, Hadlock 1985',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1477.13741943057',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11754-9',
                      CodeMeaning: 'EFW by AC, HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1495.31584808315',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11746-5',
                      CodeMeaning: 'EFW by AC, FL, HC, Hadlock 1985',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1437.21291108764',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11732-5',
                      CodeMeaning: 'EFW by AC, BPD, FL, HC, Hadlock 1985',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1449.61281620306',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125008',
              CodeMeaning: 'Fetus Summary',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              TextValue: 'B',
              ValueType: 'TEXT',
              RelationshipType: 'HAS OBS CONTEXT',
              ConceptNameCodeSequence: [
                {
                  CodeValue: '121030',
                  CodeMeaning: 'Subject ID',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'HAS OBS CONTEXT',
              MeasuredValueSequence: [
                {
                  NumericValue: '2',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '1',
                      CodeMeaning: 'no units',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11878-6',
                  CodeMeaning: 'Number of Fetuses',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/BPD-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11902-4',
                      CodeMeaning: 'BPD, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '22',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '215',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/HC-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11932-1',
                      CodeMeaning: 'HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '21',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '222',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/AC-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11892-7',
                      CodeMeaning: 'AC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '21',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '212',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeMeaning: 'OB/Hadlock/FL-GA',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11920-6',
                      CodeMeaning: 'FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '21',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '213',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '216',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11884-4',
                  CodeMeaning: 'Average Ultrasound Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              Date: '20240829',
              ValueType: 'DATE',
              RelationshipType: 'CONTAINS',
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11781-2',
                  CodeMeaning: 'EDD from average ultrasound age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '139.329896907217',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '{H.B.}/min',
                      CodeMeaning: 'bpm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11948-7',
                  CodeMeaning: 'Fetal Heart Rate',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11739-0',
                      CodeMeaning: 'EFW by AC, BPD, Shepard 1982',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1555.10304016561',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11754-9',
                      CodeMeaning: 'EFW by AC, HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1646.98560605661',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11750-7',
                      CodeMeaning: 'EFW by AC, FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1594.61046527656',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11735-8',
                      CodeMeaning: 'EFW by AC, BPD, FL, Hadlock 1985',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1576.84868403941',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11746-5',
                      CodeMeaning: 'EFW by AC, FL, HC, Hadlock 1985',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1593.26376039712',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11732-5',
                      CodeMeaning: 'EFW by AC, BPD, FL, HC, Hadlock 1985',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '1581.56007832366',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'g',
                      CodeMeaning: 'g',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11727-5',
                  CodeMeaning: 'Estimated Weight',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125008',
              CodeMeaning: 'Fetus Summary',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '121111',
          CodeMeaning: 'Summary',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'A',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '3',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-10',
              CodeMeaning: 'EFW(Hadlock)-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '16.2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-11',
              CodeMeaning: 'EFW(Brenner)-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '15.6',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-12',
              CodeMeaning: 'EFW(Williams)-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '14',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-13',
              CodeMeaning: 'EFW(Kramer(f))-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '10',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-14',
              CodeMeaning: 'EFW(Kramer(m))-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '21.5701515740381',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11871-1',
              CodeMeaning: 'FL/AC',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '72.0779220779221',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11872-9',
              CodeMeaning: 'FL/BPD',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '20.2554744525548',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11873-7',
              CodeMeaning: 'FL/HC',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '81.8278427205101',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11823-2',
              CodeMeaning: 'Cephalic Index',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '1.06490478041197',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11947-9',
              CodeMeaning: 'HC/AC',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '125001',
          CodeMeaning: 'Fetal Biometry Ratios',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'B',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '73.9593417231365',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11823-2',
              CodeMeaning: 'Cephalic Index',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '5.6',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-10',
              CodeMeaning: 'EFW(Hadlock)-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '25.7',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-11',
              CodeMeaning: 'EFW(Brenner)-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '22.8',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-12',
              CodeMeaning: 'EFW(Williams)-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '23.5',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-13',
              CodeMeaning: 'EFW(Kramer(f))-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '16.3',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '%',
                  CodeMeaning: '%',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-14',
              CodeMeaning: 'EFW(Kramer(m))-GP',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '1.10425450364124',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11947-9',
              CodeMeaning: 'HC/AC',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '22.3457263319279',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11871-1',
              CodeMeaning: 'FL/AC',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '20.2360291565429',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11873-7',
              CodeMeaning: 'FL/HC',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'CONTAINS',
          MeasuredValueSequence: [
            {
              NumericValue: '76.3089005235602',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11872-9',
              CodeMeaning: 'FL/BPD',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '125001',
          CodeMeaning: 'Fetal Biometry Ratios',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'A',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '26.442734287778',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '25.402553525686',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '25.344414113367',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '25.729900642277',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11892-7',
                      CodeMeaning: 'AC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11892-7',
                      CodeMeaning: 'AC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '15',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '209',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '3.6',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.635374245406',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11820-8',
                  CodeMeaning: 'Biparietal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.759744373767',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11820-8',
                  CodeMeaning: 'Biparietal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.697559309587',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11820-8',
                  CodeMeaning: 'Biparietal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11902-4',
                      CodeMeaning: 'BPD, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11902-4',
                      CodeMeaning: 'BPD, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '22',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '216',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '10.7',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '28.057600450996',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11984-2',
                  CodeMeaning: 'Head Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '28.915486799425',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11984-2',
                  CodeMeaning: 'Head Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '25.241852760592',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11984-2',
                  CodeMeaning: 'Head Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '27.404980003671',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11984-2',
                  CodeMeaning: 'Head Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11932-1',
                      CodeMeaning: 'HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11932-1',
                      CodeMeaning: 'HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '14',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '209',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '3',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: '%',
                          CodeMeaning: '%',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: 'R-00362',
                      CodeMeaning: '- , Range of lower measurement uncertainty',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '3',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '9.803163257158',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11851-3',
                  CodeMeaning: 'Occipital-Frontal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '10.250040769973',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11851-3',
                  CodeMeaning: 'Occipital-Frontal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '8.178630737095',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11851-3',
                  CodeMeaning: 'Occipital-Frontal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '9.410611588076',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11851-3',
                  CodeMeaning: 'Occipital-Frontal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '125002',
          CodeMeaning: 'Fetal Biometry',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'B',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '25.617694627234',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '26.611381435114',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '26.055337629716',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '26.094804564021',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11979-2',
                  CodeMeaning: 'Abdominal Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11892-7',
                      CodeMeaning: 'AC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11892-7',
                      CodeMeaning: 'AC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '21',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '212',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '6.3',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.583715279971',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11820-8',
                  CodeMeaning: 'Biparietal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.696909940929',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11820-8',
                  CodeMeaning: 'Biparietal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.64031261045',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11820-8',
                  CodeMeaning: 'Biparietal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11902-4',
                      CodeMeaning: 'BPD, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11902-4',
                      CodeMeaning: 'BPD, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '22',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '215',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '7.5',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '28.812460275382',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11984-2',
                  CodeMeaning: 'Head Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '28.812460275382',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11984-2',
                  CodeMeaning: 'Head Circumference',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11932-1',
                      CodeMeaning: 'HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11932-1',
                      CodeMeaning: 'HC, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '21',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '222',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '8.2',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '10.328590372861',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11851-3',
                  CodeMeaning: 'Occipital-Frontal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '10.328590372861',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11851-3',
                  CodeMeaning: 'Occipital-Frontal Diameter',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '125002',
          CodeMeaning: 'Fetal Biometry',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'A',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '5.478703101959',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11963-6',
                  CodeMeaning: 'Femur Length',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '5.614985589456',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11963-6',
                  CodeMeaning: 'Femur Length',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '5.546844345707',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11963-6',
                  CodeMeaning: 'Femur Length',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11920-6',
                      CodeMeaning: 'FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11920-6',
                      CodeMeaning: 'FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '15',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '205',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '3',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: '%',
                          CodeMeaning: '%',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: 'R-00362',
                      CodeMeaning: '- , Range of lower measurement uncertainty',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '3',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '125003',
          CodeMeaning: 'Fetal Long Bones',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'B',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          ValueType: 'CONTAINER',
          ContentSequence: [
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '5.83324258822',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11963-6',
                  CodeMeaning: 'Femur Length',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS CONCEPT MOD',
                  ConceptCodeSequence: [
                    {
                      CodeValue: 'R-00317',
                      CodeMeaning: 'Mean',
                      CodingSchemeDesignator: 'SRT',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121401',
                      CodeMeaning: 'Derivation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'HAS PROPERTIES',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '121412',
                      CodeMeaning: 'Mean value chosen',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121404',
                      CodeMeaning: 'Selection Status',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '5.83324258822',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'cm',
                      CodeMeaning: 'cm',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '11963-6',
                  CodeMeaning: 'Femur Length',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              ContentSequence: [
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11920-6',
                      CodeMeaning: 'FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121424',
                      CodeMeaning: 'Table of Values',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'CODE',
                  RelationshipType: 'INFERRED FROM',
                  ConceptCodeSequence: [
                    {
                      CodeValue: '11920-6',
                      CodeMeaning: 'FL, Hadlock 1984',
                      CodingSchemeDesignator: 'LN',
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121420',
                      CodeMeaning: 'Equation',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
                {
                  ValueType: 'NUM',
                  RelationshipType: 'HAS PROPERTIES',
                  MeasuredValueSequence: [
                    {
                      NumericValue: '21',
                      MeasurementUnitsCodeSequence: [
                        {
                          CodeValue: 'd',
                          CodeMeaning: 'Day',
                          CodingSchemeDesignator: 'UCUM',
                        },
                      ],
                    },
                  ],
                  ConceptNameCodeSequence: [
                    {
                      CodeValue: '121414',
                      CodeMeaning: 'Standard deviation of population',
                      CodingSchemeDesignator: 'DCM',
                    },
                  ],
                },
              ],
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '213',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: 'd',
                      CodeMeaning: 'Day',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '18185-9',
                  CodeMeaning: 'Gestational Age',
                  CodingSchemeDesignator: 'LN',
                },
              ],
            },
            {
              ValueType: 'NUM',
              RelationshipType: 'CONTAINS',
              MeasuredValueSequence: [
                {
                  NumericValue: '5.8',
                  MeasurementUnitsCodeSequence: [
                    {
                      CodeValue: '%',
                      CodeMeaning: '%',
                      CodingSchemeDesignator: 'UCUM',
                    },
                  ],
                },
              ],
              ConceptNameCodeSequence: [
                {
                  CodeValue: '125012',
                  CodeMeaning: 'Growth Percentile Rank',
                  CodingSchemeDesignator: 'DCM',
                },
              ],
            },
          ],
          RelationshipType: 'CONTAINS',
          ContinuityOfContent: 'SEPARATE',
          ConceptNameCodeSequence: [
            {
              CodeValue: '125005',
              CodeMeaning: 'Biometry Group',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: '125003',
          CodeMeaning: 'Fetal Long Bones',
          CodingSchemeDesignator: 'DCM',
        },
      ],
    },
    {
      ValueType: 'CONTAINER',
      ContentSequence: [
        {
          TextValue: 'A',
          ValueType: 'TEXT',
          RelationshipType: 'HAS OBS CONTEXT',
          ConceptNameCodeSequence: [
            {
              CodeValue: '121030',
              CodeMeaning: 'Subject ID',
              CodingSchemeDesignator: 'DCM',
            },
          ],
        },
        {
          ValueType: 'NUM',
          RelationshipType: 'HAS OBS CONTEXT',
          MeasuredValueSequence: [
            {
              NumericValue: '2',
              MeasurementUnitsCodeSequence: [
                {
                  CodeValue: '1',
                  CodeMeaning: 'no units',
                  CodingSchemeDesignator: 'UCUM',
                },
              ],
            },
          ],
          ConceptNameCodeSequence: [
            {
              CodeValue: '11878-6',
              CodeMeaning: 'Number of Fetuses',
              CodingSchemeDesignator: 'LN',
            },
          ],
        },
        {
          TextValue: 'BREECH',
          ValueType: 'TEXT',
          RelationshipType: 'CONTAINS',
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-02',
              CodeMeaning: 'Fetus Position',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
        {
          TextValue: 'POST',
          ValueType: 'TEXT',
          RelationshipType: 'CONTAINS',
          ConceptNameCodeSequence: [
            {
              CodeValue: 'GEU-1009-03',
              CodeMeaning: 'Placenta Location',
              CodingSchemeDesignator: '99GEMS',
            },
          ],
        },
      ],
      RelationshipType: 'CONTAINS',
      ContinuityOfContent: 'SEPARATE',
      ConceptNameCodeSequence: [
        {
          CodeValue: 'GEU-1009-01',
          CodeMeaning: 'Fetal Anatomy',
          CodingSchemeDesignator: '99GEMS',
        },
      ],
    },
  ],
  InstitutionName: 'Good Samaritan',
  PatientBirthDate: '19950217',
  PatientBirthTime: '000000',
  SoftwareVersions: 'LOGIQE10:R3.2.1',
  StudyDescription: 'US MFM-OB GROWTH,UA DOPPLER',
  StudyInstanceUID: '1.2.840.113619.2.182.108086182241169.1719385232.1492443',
  VerificationFlag: 'UNVERIFIED',
  IssuerOfPatientID: 'SITE_DEFAULT_LOCAL',
  RequestingService: 'ANTEPARTUM',
  SeriesDescription: '- US MFM-OB GROWTH,UA DOPPLER',
  SeriesInstanceUID: '1.2.840.113619.2.455.140911791118.1719413185.251',
  'Unknown Tag & Data': null,
  ContinuityOfContent: 'SEPARATE',
  SpecificCharacterSet: 'ISO_IR 100',
  ManufacturerModelName: 'LOGIQE10',
  ProcedureCodeSequence: [
    {
      CodeValue: 'USOBTGUAD',
      CodeMeaning: 'US MFM-OB GROWTH,UA DOPPLER',
      CodingSchemeVersion: '0',
      CodingSchemeDesignator: 'GEIIS',
    },
  ],
  AuthorObserverSequence: [
    {
      PersonName: 'SDDefaultNY',
      ObserverType: 'PSN',
      InstitutionName: '',
      InstitutionCodeSequence: [],
      PersonIdentificationCodeSequence: [],
    },
  ],
  ReferringPhysicianName: 'Lee^Jenny',
  ConceptNameCodeSequence: [
    {
      CodeValue: '125000',
      CodeMeaning: 'OB-GYN Ultrasound Procedure Report',
      CodingSchemeDesignator: 'DCM',
    },
  ],
  ContentTemplateSequence: [
    {
      MappingResource: 'DCMR',
      TemplateIdentifier: '5000',
    },
  ],
  ReferencedRequestSequence: [
    {
      AccessionNumber: 'GO-003109065',
      StudyInstanceUID: '1.2.840.113619.2.182.108086182241169.1719385232.1492443',
      RequestedProcedureID: 'GO-003109065',
      ReferencedStudySequence: [],
      RequestedProcedureDescription: '- US MFM-OB GROWTH,UA DOPPLER',
      RequestedProcedureCodeSequence: [],
      FillerOrderNumberImagingServiceRequest: '""',
      PlacerOrderNumberImagingServiceRequest: '""',
    },
  ],
  RequestAttributesSequence: [
    {
      RequestedProcedureID: 'GO-003109065',
    },
  ],
  InstitutionalDepartmentName: 'Ultrasound',
  PerformedProcedureCodeSequence: [],
  FillerOrderNumberImagingServiceRequest: 'GO-003109065',
  ReferencedPerformedProcedureStepSequence: [],
  CurrentRequestedProcedureEvidenceSequence: [
    {
      StudyInstanceUID: '1.2.840.113619.2.182.108086182241169.1719385232.1492443',
      ReferencedSeriesSequence: [
        {
          SeriesInstanceUID: '1.2.840.113619.2.455.140911791118.1719411631.155',
          ReferencedSOPSequence: [
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.7',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411651.156',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.7',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412275.177',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.7',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412896.199',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411704.157',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411711.158',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411746.159',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411755.160',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411759.161',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411770.162',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411778.163',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411783.164',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411787.165',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411791.166',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411845.167',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411913.168',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411927.169',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411961.170',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411980.171',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719411989.172',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412037.173',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412046.174',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412103.175',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412254.176',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412335.178',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412406.179',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412453.180',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412508.181',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412529.182',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412548.183',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412587.184',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412616.185',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412636.186',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412678.187',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412697.188',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412715.189',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412720.190',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412730.191',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412767.192',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412773.193',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412775.194',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412776.195',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412838.196',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412856.197',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719412880.198',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719413084.200',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719413121.201',
            },
            {
              ReferencedSOPClassUID: '1.2.840.10008.5.1.4.1.1.6.1',
              ReferencedSOPInstanceUID: '1.2.840.113619.2.455.140911791118.1719413130.202',
            },
          ],
        },
      ],
    },
  ],
};
