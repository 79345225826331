import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import useAuth from '../../context-providers/Auth';
import TextInput from '../TextInput/TextInput';
import Recents from '../../services/recents';
import Icon from '../Icon/Icon';
import './SelectInput.css';
import { isNullOrUndefined } from '../../utils';

/**
 * <SelectInput
 *  label
 *  options = [ { label, icon, value, type: [ "option" | "heading" | "separator" ] } ]
 *  optionsFontSize = "medium" | "large" | "small"
 *  padding = "no" | "medium" | "big"
 *  value = value
 *  onChange = () => {}
 *  theme = "common-white" | "black" | "grey"
 *  variant = "contained" | "outline"
 *  placeholder = {label, icon, value}
 *  showRecent = unique ID
 *  showMostUsed = unique ID
 *  showSearchBar = boolean
 *  showDropDown = boolean
 * />
 */

const scrollableParents = [];
let closing = false;

const SelectInput = ({
  label = '',
  placeholder = { label: '', value: '', icon: false },
  options,
  value,
  disabled = false,
  variant = 'contained',
  theme = 'default',
  padding = 'medium',
  showSeparators = true,
  showRecent = false,
  showMostUsed = false,
  showSearchBar = false,
  showDropDown = true,
  optionsFontSize = 'small',
  onChange,
  t: __,
}) => {
  const { user } = useAuth();
  const [spacing, setSpacing] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'bottom',
  }); // space over and below the select box at open
  const [open, setOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const container = useRef(null);
  const dropdown = useRef(null);
  const searchField = useRef(null);
  const hasSeparators = useMemo(() => options.some((option) => option.type === 'separator'), [options]);

  const recentCollection = showRecent || showMostUsed;

  useEffect(() => {
    for (let node = container.current.parentNode; node; node = node.parentNode) {
      if (node.scrollHeight > node.clientHeight && !scrollableParents.includes(node)) scrollableParents.push(node);
    }
    return () => {
      dropdown.current?.parentNode.removeChild(dropdown.current);
      for (const forgottenOptions of document.querySelectorAll('.App > div > .select-input-options')) {
        forgottenOptions.parentNode.removeChild(forgottenOptions);
      }
    };
  }, []);

  const onChangeHandler = (value, option) => {
    if (isNullOrUndefined(option.autoClose) || option.autoClose === true) {
      onCloseHandler();
      setOpen(false);
    }
    saveAsRecent(option);
    if (typeof onChange === 'function') onChange(value, option);
  };

  useLayoutEffect(() => {
    if (!open) {
      container.current?.appendChild(dropdown.current);
      window.removeEventListener('click', onCloseHandlerByWindowEvent, true);
      window.removeEventListener('resize', refreshDropdownPosition, true);
      for (const node of scrollableParents) {
        node.removeEventListener('scroll', refreshDropdownPosition);
      }
      closing = true;
      setTimeout(() => (closing = false), 100);
      return;
    }

    for (const forgottenOptions of document.querySelectorAll('.App > div > .select-input-options')) {
      forgottenOptions.parentNode.removeChild(forgottenOptions);
    }
    document.querySelector('.App > div').appendChild(dropdown.current);

    refreshDropdownPosition();

    window.addEventListener('click', onCloseHandlerByWindowEvent, true);
    window.addEventListener('resize', refreshDropdownPosition, true);
    for (const node of scrollableParents) {
      node.addEventListener('scroll', refreshDropdownPosition);
    }
  }, [open]);

  const isClickingOutsideOptions = (e) => {
    for (let parent = e.target; parent; parent = parent.parentNode) {
      if (parent !== document && parent.classList.contains('select-input-options')) return false;
    }
    return true;
  };

  const getRecent = () => {
    if (!showRecent) return [];
    return Recents.getRecents(recentCollection, user.id, 3)
      .map(({ item }) => ({
        ...(options.find((option) => `${option.value ?? option.label}` === `${item.id}`) || {}),
        from: 'recent',
      }))
      .filter((option) => option.value || option.label);
  };

  const getMostUsed = () => {
    if (!showMostUsed) return [];
    const recents = getRecent();
    return Recents.getMostUsed(recentCollection, user.id, 3, (item) => !recents.some((recent) => recent.id === item.id))
      .map(({ item }) => ({
        ...(options.find((option) => `${option.value ?? option.label}` === `${item.id}`) || {}),
        from: 'most-used',
      }))
      .filter((option) => option.value || option.label);
  };

  const saveAsRecent = (item) => {
    if (!showRecent) return;
    Recents.addToRecent(recentCollection, user.id, item.value, item);
  };

  const highlight = (string, searchKey) => {
    if (!searchKey) return string;
    const regExp = new RegExp(`(${searchKey})`, 'ig');
    const html = string.replace(regExp, `<b>$1</b>`);
    return <span dangerouslySetInnerHTML={{ __html: html }}></span>;
  };

  const refreshDropdownPosition = () => {
    // calculate the space below and hover the button until the window border, in pixels, and pass it to the callback
    const position = container.current?.querySelector('.select-input-current')?.getBoundingClientRect();
    if (!position) {
      setOpen(false);
      return false;
    }

    const spacing = {
      top: parseInt(position.bottom),
      left: parseInt(position.left),
      position: 'top',
      minWidth: container.current?.offsetWidth,
    };

    const maxScrollHeight = Math.ceil(
      Math.min(Math.max(window.innerHeight - position.bottom - 30, position.top - 30), dropdown.current.scrollHeight)
    );
    spacing.position = window.innerHeight - position.bottom < maxScrollHeight ? 'top' : 'bottom';
    spacing.maxHeight = maxScrollHeight;
    if (spacing.position === 'top') {
      spacing.top = parseInt(position.top - maxScrollHeight);
    }
    if (window.innerWidth < spacing.left + dropdown.current.offsetWidth) {
      spacing.left = position.right - dropdown.current.offsetWidth;
    }

    setSpacing(spacing);
  };

  const onCloseHandler = () => {
    if (searchField?.current === document.activeElement) return;
    setSearchKey('');
    setOpen(false);
  };

  const onCloseHandlerByWindowEvent = (e) => isClickingOutsideOptions(e) && onCloseHandler();

  const filteredOptions = useMemo(() => {
    return (
      options?.filter((option) => `${option.label || option.value}`.toLowerCase().includes(searchKey.toLowerCase())) ||
      []
    );
  }, [options, searchKey]);

  const selectedOption =
    options.find((option) => value !== undefined && value?.toString() === option?.value?.toString()) || placeholder;

  return (
    <div
      className={`select-input theme-${theme} padding-${padding} ${open ? 'open' : ''} ${
        showDropDown ? '' : 'no-drop-down-icon'
      }`}
      ref={container}
      data-disabled={disabled}
      data-variant={variant}
    >
      {!!label && <label>{label}</label>}
      <div
        className="select-input-current"
        onClick={() => {
          if (!open && !closing && !disabled) setOpen(true);
        }}
      >
        {selectedOption.icon && <Icon name={selectedOption.icon} />}
        {showDropDown && <> </>}
        {selectedOption.label || (showDropDown ? <> </> : '')}
        {showDropDown && <Icon name="down" />}
      </div>
      <div
        ref={dropdown}
        className={`select-input-options theme-${theme} font-size-${optionsFontSize} ${open ? 'open' : ''} ${
          hasSeparators || !showSeparators ? 'has-separators' : ''
        }`}
        style={{
          top: spacing.top,
          left: spacing.left,
          minWidth: spacing.minWidth,
          maxHeight: spacing.maxHeight,
        }}
      >
        {!disabled && (
          <>
            {showSearchBar && (
              <div className="option searchbar">
                <TextInput
                  icon="search"
                  forwardRef={searchField}
                  value={searchKey}
                  onChange={(value) => setSearchKey(value)}
                />
              </div>
            )}
            {!searchKey && showRecent && getRecent().length > 0 && (
              <div className="option-recents">
                <div className="option heading" key="recent-heading">
                  {__('suggestion.recent')}
                </div>
                {getRecent().map((option, index) => (
                  <div
                    className={
                      'option recent ' +
                      (option.disabled ? 'disabled ' : '') +
                      (value !== undefined && value?.toString() === option.value?.toString() ? 'selected' : '')
                    }
                    key={'recent' + index}
                  >
                    <label onClick={() => !option.disabled && onChangeHandler(option?.value, option)}>
                      {option.icon && <Icon name={option.icon} />}
                      <small>
                        <Icon name="clock" />
                      </small>
                      {option.label || <>&nbsp;</>}
                    </label>
                  </div>
                ))}
              </div>
            )}
            {!searchKey && showMostUsed && getMostUsed().length > 0 && (
              <div className="option-most-used">
                <div className="option heading" key="recent-heading">
                  {__('suggestion.mostUsed')}
                </div>
                {getMostUsed().map((option, index) => (
                  <div
                    className={
                      'option most-used ' +
                      (option.disabled ? 'disabled ' : '') +
                      (value !== undefined && value?.toString() === option.value?.toString() ? 'selected' : '')
                    }
                    key={'most-used' + index}
                  >
                    <label onClick={() => !option.disabled && onChangeHandler(option?.value, option)}>
                      {option.icon && <Icon name={option.icon} />}
                      <small>
                        <Icon name="reload" />
                      </small>
                      {option.label || <>&nbsp;</>}
                    </label>
                  </div>
                ))}
              </div>
            )}
            {filteredOptions.map((option, index) =>
              option.type === 'separator' ? (
                <div className="option separator" key={index}></div>
              ) : option.type === 'heading' ? (
                <div className="option heading" key={index}>
                  {option.label}
                </div>
              ) : (
                <div
                  data-test-id={option.value?.toString()?.replace(/ /g, '_')}
                  className={
                    'option ' +
                    (option.disabled ? 'disabled ' : '') +
                    ((value !== undefined && value?.toString() === option.value?.toString()) || option.selected
                      ? 'selected'
                      : '')
                  }
                  key={index}
                >
                  <label onClick={() => !option.disabled && onChangeHandler(option?.value, option)}>
                    {option.icon && <Icon name={option.icon} />}
                    {highlight(option.label, searchKey) || <>&nbsp;</>}
                  </label>
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SelectInput);
