import React, { useState, useEffect } from 'react';

import { propsToTag } from './utils';

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function PlaceholderLoader({
  requirePlaceholders,
  requiredPlaceholders,
  placeholders,
  Component,
  props,
  reportMode,
  ...otherProps
}) {
  if (!Array.isArray(requiredPlaceholders))
    throw new Error(`requiredPlaceholders must be an array of placeholders ID.\nError in ${propsToTag(props)}`);
  const [requiringPlacehodlers, setRequiringPlaceholders] = useState(false);

  requiredPlaceholders = (requiredPlaceholders || []).filter(onlyUnique).sort();

  const placeholdersLoaded = requiredPlaceholders.every((placeholder) => !!placeholders[placeholder]);
  const toLoadPlaceholders = requiredPlaceholders.join('|');

  // TODO we can hide that behind a custom hook
  useEffect(() => {
    /* useEffect is not called in printing mode. This is added just to be explicit */
    if (reportMode === 'print') return;
    /* Placeholder is not loaded yet */
    if (!placeholdersLoaded && requiringPlacehodlers !== toLoadPlaceholders) {
      setRequiringPlaceholders(toLoadPlaceholders);
      requirePlaceholders(props, requiredPlaceholders);
      return;
    }
    /* Placeholder is now loaded */
    if (placeholdersLoaded && !!requiringPlacehodlers) {
      setRequiringPlaceholders(false);
    }
  }, [toLoadPlaceholders, placeholders]);
  /* If we are in print mode, useEffect is not called, we need to call this our self */
  if (reportMode === 'print' && !placeholdersLoaded) {
    requirePlaceholders(props, requiredPlaceholders);
  }

  // TODO - Add a loading spinner
  if (!placeholdersLoaded) return null;

  return (
    <Component
      {...otherProps}
      reportMode={reportMode}
      requirePlaceholders={requirePlaceholders}
      placeholders={placeholders}
      props={props}
    />
  );
}
