import { useState, useContext, useEffect } from 'react';
import './RiskFactorsSidebar.css';
import { withTranslation } from 'react-i18next';
import { ExaminationContext } from '../../context-providers/Examination';
import { getTeratogenicRisks } from '../../services/examination';
import LookupApi from '../../services/lookup';
import MedicalHistoryInput from '../MedicalHistoryInput/MedicalHistoryInput';
import MedicalHistoryTextualInput from '../MedicalHistoryInput/MedicalHistoryTextualInput';
import FetusSexHandler from '../../components/MedicalHistoryInput/FetusSexHandler';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import Icon from '../../atoms/Icon/Icon';

/**
 * <RiskFactorsSidebar
 *  examinationId=(integer)
 *  medicalHistory={} // the medical history object from the examination context
 *  examinationContext.medicalHistoryItems={} // all the medical history items from the api
 *  medications={} // all the medications
 * >
 */
const RiskFactorsSidebar = ({
  t: __,
  examinationId,
  medicalHistory,
  medications,
  recommendedMedicalHistoryItemIds = [],
  recommendedTeratogenIds = [],
}) => {
  const examinationContext = useContext(ExaminationContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const [teratogenicRisks, setTeratogenicRisks] = useState([]);
  const [recommendedTeratogenicRisks, setRecommendedTeratogenicRisks] = useState([]);
  const [recommendedMedications, setRecommendedMedications] = useState([]);
  const [riskFactorsAll, setRiskFactorsAll] = useState([]);
  const [recommendedMedicalHistoryItems, setRecommendedMedicalHistoryItems] = useState([]);

  useEffect(() => {
    if (recommendedTeratogenicRisks.length)
      LookupApi.getMedicationsByRiskFactorIds(recommendedTeratogenicRisks).then((results) =>
        setRecommendedMedications(
          results.data.filter((item) => {
            return !medicalHistory['teratogenicrisks.medications']?.risk_factors.some((rfid) =>
              item.risk_factor_ids.includes(rfid)
            );
          })
        )
      );
    else setRecommendedMedications([]);
  }, [recommendedTeratogenicRisks, medications]);

  useEffect(() => {
    setTeratogenicRisks(getTeratogenicRisks(medicalHistory, medications));
  }, [JSON.stringify(medicalHistory), JSON.stringify(medications)]);

  useEffect(() => {
    let newRiskFactorsAll = Object.values(medicalHistory || {}).filter(
      (item) => !Array.isArray(item.raw_value) && !item.text_id.includes('medicalexam.fetus')
    );
    // always display CGH
    if (!medicalHistory?.['genetichistory.cgh'])
      newRiskFactorsAll.unshift(examinationContext.medicalHistoryItems['genetichistory.cgh']);

    if (
      medicalHistory?.['genetichistory.cgh']?.value !== 'yes_and_positive' &&
      medicalHistory?.['genetichistory.karyotype']?.value !== 'yes_and_positive' &&
      medicalHistory?.['genetichistory.dpni']?.value !== 'yes_and_positive'
    ) {
      newRiskFactorsAll = newRiskFactorsAll.filter((risk) => risk.text_id !== 'genetichistory.geneticist_result');
    } else if (!medicalHistory.hasOwnProperty('genetichistory.geneticist_result')) {
      newRiskFactorsAll.unshift(examinationContext.medicalHistoryItems['genetichistory.geneticist_result']);
    }

    setRiskFactorsAll(newRiskFactorsAll);
  }, [JSON.stringify(medicalHistory)]);

  useEffect(() => {
    const newRecommendedMedicalHistoryItems = recommendedMedicalHistoryItemIds
      .map((medicalHistoryItemId) =>
        Object.values(examinationContext.medicalHistoryItems).find((item) => item.id === medicalHistoryItemId)
      )
      .filter((medicalHistoryItem) => !!medicalHistoryItem);
    setRecommendedMedicalHistoryItems(newRecommendedMedicalHistoryItems);
  }, [recommendedMedicalHistoryItemIds]);

  useEffect(() => {
    const newRecommendedMedicalHistoryItems = recommendedTeratogenIds.map(
      (riskFactorId) =>
        Object.values(examinationContext.medicalHistoryItems).find((item) =>
          item.options?.some((option) => option.risk_factor_id === riskFactorId)
        ) || riskFactorId
    );
    setRecommendedTeratogenicRisks(newRecommendedMedicalHistoryItems.filter((item) => !isNaN(item)));
  }, [recommendedTeratogenIds]);

  if (!Object.keys(examinationContext.medicalHistoryItems).length) return false;

  return (
    <div className="risk-factors-sidebar-container">
      {!!riskFactorsAll.length && (
        <ul className="general-risk-factors">
          {riskFactorsAll.map((field) => (
            <li key={field.text_id}>
              <MedicalHistoryInput
                field={{ ...examinationContext.medicalHistoryItems[field.text_id], ...field }}
                disableOptionalFields={true}
              />
            </li>
          ))}
        </ul>
      )}
      {teratogenicRisks.length > 0 && (
        <ul className="teratogenic-risks medication">
          {teratogenicRisks.map((risk, idx) => (
            <li key={idx}>
              <span className="teratogenic-risks medication-label">{risk.label}</span>
              <div className="dismiss">
                <Icon name="close" onClick={() => examinationContext.removeMedicationById(risk.id)} />
              </div>
            </li>
          ))}
        </ul>
      )}
      {!!recommendedMedicalHistoryItems.length && (
        <ul className="recommended-medical-history-items">
          {recommendedMedicalHistoryItems.map((field) =>
            field.text_id === 'medicalexam.fetus.sex' ? (
              <FetusSexHandler
                key={field.text_id}
                wrapper={({ children }) => <li className="recommended">{children}</li>}
              >
                <MedicalHistoryTextualInput field={field} />
              </FetusSexHandler>
            ) : (
              <li key={field.text_id} className="recommended">
                <MedicalHistoryTextualInput field={field} />
              </li>
            )
          )}
        </ul>
      )}
      {!!recommendedMedications.length && (
        <ul className="recommended-medication medication">
          {recommendedMedications.map((medication) => (
            <li key={medication.id} className="recommended">
              <span className="recommended-medication medication-label">{medication.name[currentLanguage]}</span>
              <ButtonGroup
                options={[
                  { label: __('dx.checklist.yes'), value: true },
                  { label: __('dx.checklist.no'), value: true },
                ]}
                value=""
                onChange={(value) => {
                  if (value === true) examinationContext.addMedicationById(medication.id, medication.risk_factor_ids);
                  examinationContext.dismissLiveQuestion(medication.risk_factor_ids);
                }}
              />
              <div className="dismiss">
                <Icon name="close" onClick={() => examinationContext.dismissLiveQuestion(medication.risk_factor_ids)} />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default withTranslation()(RiskFactorsSidebar);
