import { useContext, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SelectInput from '../../../atoms/SelectInput/SelectInput';
import useAuth from '../../../context-providers/Auth';
import { WindowContext } from '../../../context-providers/Window';
import { ExaminationContext } from '../../../context-providers/Examination';
import ResourceApi from '../../../services/resource';
import './SlidePanel.css';

const SlidePanelMoreButton = ({
  t: __,
  setConfirmDelete,
  setShowSlideBrowser,
  onViewEvaluationReverseClick,
  onAllQualityCriteriaReverseClick,
  plane,
  isVideo,
}) => {
  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);
  const history = useHistory();
  const { isFeatureFlagEnabled } = useAuth();

  const getSlideOptions = useMemo(() => {
    return [
      {
        label: __('examination-plane.reannotate'),
        icon: 'change-slide',
        value: 'reannotate',
        disabled: !plane.media,
        keep: !isVideo,
      },
      {
        label: __('examination-plane.resetEvalaution'),
        icon: 'ai-detection',
        value: 'reset-evaluation',
        disabled: !plane.media,
        keep: !isVideo && plane.media?.verified,
      },
      {
        label: plane.media?.to_retake
          ? __('examination-plane.doNotAskForRetake')
          : __('examination-plane.askForRetake'),
        icon: 'reload',
        value: 'retake',
        disabled: !plane.media,
        keep: !isVideo && plane.media?.verified,
      },
      {
        label: __(`examination-plane.edit.image`),
        icon: 'edit',
        value: 'edit',
        disabled: !plane.media || !examinationContext.canEdit,
        keep: !isVideo && isFeatureFlagEnabled('sonio.image_edit'),
      },
      {
        label: __(`examination-plane.downloadDICOM`),
        icon: 'download',
        value: 'download',
        disabled: !plane.media,
        keep: true,
      },
      {
        label: __(`examination-plane.delete.${plane.media?.dicom_media_type}`),
        icon: 'trash',
        value: 'delete',
        disabled: !plane.media,
        keep: true,
      },
    ].filter(({ keep }) => keep);
  }, [plane.media, isVideo]);

  const onClickSlideOption = async (value) => {
    switch (value) {
      case 'delete':
        return setConfirmDelete(true);
      case 'reannotate':
        if (!plane.media) return;
        return setShowSlideBrowser({
          slide: plane.slide,
          media: plane.media,
        });
      case 'reset-evaluation':
        if (!plane.media) return;
        onViewEvaluationReverseClick();
        onAllQualityCriteriaReverseClick();
        return;
      case 'retake':
        if (!plane.media) return;
        if (!plane.slide?.id) return;
        if (plane.media?.to_retake) {
          return await ResourceApi.updateToRetake(
            examinationContext.examination.id,
            plane.media.id,
            plane.slide.id,
            false
          );
        } else {
          return await ResourceApi.updateToRetake(
            examinationContext.examination.id,
            plane.media.id,
            plane.slide.id,
            true
          );
        }
      case 'edit':
        if (!plane.media) return;
        if (windowContext.isDetached) {
          // workaround for bug happening when editing an image from a detached window in app-mode
          return history.push(`/window/dicom-editor/${examinationContext.examination.id}/${plane.media.id}`);
        } else {
          return history.push(`/dicom-instance-edit/${examinationContext.examination.id}/${plane.media.id}`);
        }
      case 'download':
        if (!plane.media) return;
        return ResourceApi.downloadDICOM(plane.media.id);
    }
  };

  return (
    <SelectInput
      placeholder={{ icon: 'more', label: '' }}
      showDropDown={false}
      optionsFontSize={'large'}
      options={getSlideOptions}
      onChange={onClickSlideOption}
    />
  );
};

export default withTranslation()(SlidePanelMoreButton);
