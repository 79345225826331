import { useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../../context-providers/App';
import useLatestVersion from '../../context-providers/LatestVersion';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import Icon from '../../atoms/Icon/Icon';
import './Disclaimer.css';

const Disclaimer = ({ t: __, onAccept }) => {
  const latestVersion = useLatestVersion();
  const appContext = useContext(AppContext);

  return (
    <div className="disclaimer-dt-container">
      <div className="disclaimer-set-language">
        <ButtonGroup
          value={appContext?.preferences?.lang ?? ''}
          options={[
            { value: 'en', label: 'EN' },
            { value: 'fr', label: 'FR' },
            { value: 'de', label: 'DE' },
            { value: 'ptbr', label: 'PTBR' },
          ]}
          onChange={appContext.changeLanguage}
        />
      </div>
      <div>
        <div className="disclaimer">
          <div
            dangerouslySetInnerHTML={{
              __html: __('dt.disclaimer.text', {
                termsOfUseLink: __('navbar.termsOfUse.dt.href'),
                privacyPolicyLink: __('navbar.privacyPolicy.dt.href'),
                iconAttention: renderToString(<Icon name="attention" />),
                version: latestVersion.currApiVersion,
              }),
            }}
          ></div>
          <div
            className="small"
            dangerouslySetInnerHTML={{
              __html: __('dt.disclaimer.smalltext', {
                termsOfUseLink: __('navbar.termsOfUse.dt.href'),
                privacyPolicyLink: __('navbar.privacyPolicy.dt.href'),
                iconAttention: renderToString(<Icon name="attention" />),
                version: latestVersion.currApiVersion,
              }),
            }}
          ></div>
        </div>
        <div className="submit">
          <button data-test-id="i-get-it" onClick={onAccept}>
            {__('disclaimer.ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Disclaimer);
