import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import { ExaminationContext } from '../../context-providers/Examination';
import { formatName } from '../../services/examination';
import ResourceApi from '../../services/resource';
import './Appointment.css';
import MedicalHistory from './MedicalHistory';

const getDateObj = (dateString) => {
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, 6);
  const day = dateString.substring(6, 8);
  return new Date(year, month - 1, day);
};

const getAge = (dobString) => {
  const dob = getDateObj(dobString);
  const today = new Date();
  return today.getFullYear() - dob.getFullYear();
};

const formatDate = (dobString) => {
  const dob = getDateObj(dobString);
  const month = `${dob.getMonth() + 1}`.padStart(2, '0');
  const day = `${dob.getDate()}`.padStart(2, '0');
  return `${dob.getFullYear()}/${month}/${day}`;
};

const formatTime = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString().split(':').slice(0, 2).join(':');
};

const Appointment = ({ appointment }) => {
  const history = useHistory();
  const examinationContext = useContext(ExaminationContext);

  const [linkedExam, setLinkedExam] = useState(null);

  const goToExam = (examination_id) => {
    history.push(`/exam/${examination_id}`);
  };

  const createExam = () => {
    examinationContext.createExamination(appointment.patient_id, null, 'T2', null).then((exam) => {
      return ResourceApi.updateAppointment(appointment.id, {
        examination_id: exam.id,
      }).then(() => {
        return history.push(`/exam/${exam.id}`);
      });
    });
  };

  useEffect(() => {
    if (appointment.examination_id) {
      ResourceApi.getExamination(appointment.examination_id).then((resp) => {
        const exam = resp.data.data;
        if (exam.state === 'merged') {
          // since merged, load merged into exam
          ResourceApi.getExamination(exam.merged_into_id).then((response) => setLinkedExam(response.data.data));
        } else {
          setLinkedExam(resp.data.data);
        }
      });
    }
  }, [appointment]);

  return (
    <div className="appointment-container">
      <div className="appointment-time">
        <div>
          {formatDate(
            appointment.scheduled_procedure_step?.ScheduledProcedureStepSequence?.[0]?.ScheduledProcedureStepStartDate
          )}
        </div>
        <div>
          <strong>{formatTime(appointment.scheduled_start_date)}</strong>
        </div>
      </div>
      <div className="appointment-details-container">
        <div className="header">
          <div className="patient-details">
            <Icon name="patient" />
            <div className="name">{formatName(appointment.patient.name).fullName}</div>
            <div className="age">{`${getAge(appointment.info?.PatientBirthDate)}yo`}</div>
            <div className="dob">{formatDate(appointment.info?.PatientBirthDate)}</div>
            {/* <div className="status">To be admitted</div> */}
          </div>
          {appointment.examination_id ? (
            <div className="cta">
              <Button variant="outline" label="Access Exam" onClick={() => goToExam(linkedExam.id)} />
            </div>
          ) : (
            <div className="cta">
              <Button variant="outline" label="Create Exam" onClick={() => createExam()} />
            </div>
          )}
        </div>
        <div className="current-exam">
          <div className="exam-type">PREGNANCY</div>
          <div className="exam-name">Second trimester exam</div>
          {linkedExam && appointment.id <= 1186 && <div className="exam-status">{linkedExam.status}</div>}
        </div>
        <div className="history">
          <div className="medical-history">
            <div className="header">
              <Icon name="paste" />
              <span>Medical history</span>
            </div>
            {linkedExam?.medical_history && <MedicalHistory medicalHistory={linkedExam.medical_history} />}
          </div>
          {appointment.id <= 1186 && (
            <div className="previous-exams">
              <div className="header">Previous Exams</div>
              <div>
                First trimester <br />
                08/01/2023
              </div>
            </div>
          )}
        </div>
        <div className="metadata">
          <div className="metadata-item">
            <div className="label">Sonographer</div>
            <SelectInput
              placeholder={{ icon: '', label: 'Martin Dupont' }}
              showDropDown={true}
              optionsFontSize={'small'}
              options={[
                {
                  label: 'Prac 1',
                },
              ]}
            />
          </div>
          <div className="metadata-item">
            <div className="label">Referring physician</div>
            <SelectInput
              placeholder={{ icon: '', label: 'Martin Dupont' }}
              showDropDown={true}
              optionsFontSize={'small'}
              options={[
                {
                  label: 'Prac 1',
                },
              ]}
            />
          </div>
          <div className="metadata-item">
            <div className="label">Room/Machine</div>
            <SelectInput
              placeholder={{ icon: '', label: 'Martin Dupont' }}
              showDropDown={true}
              optionsFontSize={'small'}
              options={[
                {
                  label: 'Prac 1',
                },
              ]}
            />
          </div>
          <div className="metadata-item">
            <div className="label">Reader</div>
            <SelectInput
              placeholder={{ icon: '', label: 'Martin Dupont' }}
              showDropDown={true}
              optionsFontSize={'small'}
              options={[
                {
                  label: 'Prac 1',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Appointment;
