import React from 'react';
import Icon from '../../atoms/Icon/Icon';

export default function PrintingContent({ examination, openChapter, setOpenChapter }) {
  if (!Array.isArray(examination?.printing_configuration)) return false;
  const BluePrintElement = (props) => {
    return (
      <span className="printing-config-plane" key={props.order_in_template}>
        {props.instance_view_id}
        &nbsp;
        {props.label}
        &nbsp; (x{props.number_of_instances})
      </span>
    );
  };
  const Trimester = (props) => {
    return (
      <div className="debug-subsection" key={`ptemplate-${props.id}`}>
        <div>
          <h3>
            {props.title} ({props.trimester.toUpperCase()})
            <span>
              id={props.id}, {props.number_of_images_per_page} img
            </span>
          </h3>
        </div>
        <div>{props.blueprint.map(BluePrintElement)}</div>
      </div>
    );
  };
  return (
    <div className={`debug-section ${openChapter === 'printing' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'printing' ? false : 'printing'))}>
        Printing Config <Icon name="down" />
      </h2>
      {openChapter === 'printing' &&
        (examination.printing_configuration ? examination.printing_configuration.map(Trimester) : false)}
    </div>
  );
}
