import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ExaminationContext } from '../../context-providers/Examination';
import ResourceApi from '../../services/resource';
import './GlobalSearchBar.css';
import GlobalSearchBarInput from './GlobalSearchBarInput';
import ChecklistItemLine from './Lines/ChecklistItemLine';

const GlobalSearchBar = ({ t: __, fullwidth = false, callback = () => {} }) => {
  const examinationContext = useContext(ExaminationContext);

  const [focus, setFocus] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResults, doSetSearchResults] = useState([]);

  const onClickOutside = () => {
    setFocus(false);
    window.removeEventListener('click', onClickOutside, false);
  };

  useEffect(() => {
    setSearchKey('');
    setSearchResults([]);
  }, [examinationContext.examination.id]);

  useEffect(() => {
    if (searchKey) setFocus(true);
  }, [searchKey, searchResults]);

  useEffect(() => {
    if (focus) {
      window.addEventListener('click', onClickOutside, false);
    } else {
      window.removeEventListener('click', onClickOutside, false);
    }
  }, [focus]);

  /*
   * This is simple version of choosing the fetus based on the examination fetus list
   *
   * The idea is to multiply every result that can be assigned to a fetus (for the moment every fetus)
   * by the number of Fetuses in the examination
   *    For instance:
   *      searchResults = ["Skull contour", "Skull contour defect"]
   *      displayedSearchResults = ["Skull contour fetus A", "Skull contour fetus B", "Skull contour defect fetus A", "Skull contour defect fetus B"]
   *
   * This rule is only applied if the number of fetuses is greater than one
   */
  const setSearchResults = (results) => {
    doSetSearchResults(
      results
        .map((result) => {
          switch (result.origin) {
            case 'fetal':
              return examinationContext?.examination?.fetuses.map((f) => ({ ...result, examination_fetus_id: f.id }));
            case 'maternal':
              return [{ ...result, examination_fetus_id: null }];
            case 'other':
              /* TODO understand what we should do with such CI. Maybe an improvement required to be made to attach a CI to multiple Fetus */
              return [{ ...result, examination_fetus_id: null }];
            default:
              console.warn(`[Checklist item] ${result.slug} has an undefined origin`, result.origin);
              return [{ ...result, examination_fetus_id: null }];
          }
        })
        .flat()
    );
  };

  return (
    <div className={`global-search-bar ${focus ? 'focus' : ''} ${fullwidth ? 'fullwidth' : ''}`}>
      <>
        <GlobalSearchBarInput
          searchKey={searchKey}
          label=""
          setFocus={setFocus}
          examinationId={examinationContext.examination.id}
          onChange={(checklistItem) => setSearchResults(checklistItem)}
          onChangeSearchKey={(searchKey) => {
            setSearchKey(searchKey);
            if (!searchKey) setSearchResults([]);
          }}
        />
        {!!searchKey && !searchResults.length && focus && (
          <ul>
            <li className="checklist-item-line-search no-results">
              <div>
                <span className="checklist-item-name">
                  <span>{__('globalSearchBar.noResultsFound')}</span>
                </span>
              </div>
            </li>
          </ul>
        )}
        {!!searchResults.length && focus && (
          <ul>
            {searchResults.map((c) => (
              <ChecklistItemLine
                key={'checklist_' + c.id}
                checklistItem={c}
                searchKey={searchKey}
                fetuses={examinationContext.examination.fetuses}
                cta={__('globalSearchBar.addChecklistItem')}
                onClick={() => {
                  ResourceApi.updateChecklistItemStatus(
                    examinationContext.examination.id,
                    c.id,
                    'unusual',
                    c.examination_fetus_id
                  ).then(() =>
                    callback({
                      type: 'checklistitem',
                      item: c,
                    })
                  );
                  setSearchResults([]);
                  return setSearchKey('');
                }}
              />
            ))}
          </ul>
        )}
      </>
    </div>
  );
};

export default withTranslation()(GlobalSearchBar);
