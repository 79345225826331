import React, { isValidElement, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
/* Config */
import { ChecklistItemStatus } from '../../../../config';
/* Atoms */
import Icon from '../../../../atoms/Icon/Icon';
import ReportTemplateBlock from '../Block';
import ReportTemplateLabel from '../Label';

import { onlyUnique } from '../../../../utils';
import { checkCondition } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';
import ChecklistHelper from '../../ChecklistHelper';

const normalizeStatus = (status) => {
  if (!status) return '';
  if (ChecklistItemStatus[status]) return status;
  return Object.entries(ChecklistItemStatus[status] || {}).find(([_, value]) => value.synonyms?.includes(status))?.[0];
};

function addComment(item, item_comment, slug, autogeneratedChecklistComments, fetus, componentUID) {
  const autoComment = autogeneratedChecklistComments?.[fetus]?.[componentUID]?.[slug];
  const comment = item_comment ?? autoComment;
  return { ...item, comment: comment };
}

const ReportTemplateChecklistBody = (fullProps) => {
  const {
    i18n: { language: currentLanguage },
    children,
    items,
    groups = [],
    fetus = 0,
    groupElements,
    componentUID,
    setEditChecklistDialogIsOpen = () => {},
    updateAutogeneratedChecklistComments = () => {},
    autogeneratedChecklistComments,
    groupCommentsSlugPrefix,
    apiVersion,
  } = fullProps;

  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const checklistItems = items
    .map((ci_id) => placeholdersHelper.selectedDatum(`checklist.item.${ci_id}`))
    .filter((datum) => datum.value?.status);

  const itemsToDisplay = checklistItems
    .filter((datum) => datum && datum.visible !== false && datum.value && datum.value.label && datum.value.status)
    .map((datum) =>
      addComment(datum.value, datum.comment, datum.slug, autogeneratedChecklistComments, fetus, componentUID)
    )
    .sort((a, b) => {
      if (a.order && b.order) return a.order - b.order;
      return a.label[currentLanguage].localeCompare(b.label[currentLanguage]);
    });

  const updateAutogeneratedItemComments = () => {
    let comments = [];
    if (!!children && Array.isArray(children)) {
      children
        .filter((child) => child?.props?.type === 'if')
        .forEach((child) => {
          const matchesConditions = checkCondition(
            { ...child?.props?.props },
            fetus,
            placeholdersHelper,
            placeholdersHelper.apiVersion
          );
          if (matchesConditions) {
            const valueElements = Array.isArray(child?.props?.children)
              ? child?.props?.children?.filter((child) => child?.props?.props?.type === 'value')
              : [];
            comments = [
              ...comments,
              ...valueElements.map((value) => ({
                data: value.props.props.data,
                attribute: value.props.props.attribute,
                content: value.props.children,
              })),
            ];
          }
        });
    }
    updateAutogeneratedChecklistComments(fetus, componentUID, comments);
  };

  useEffect(() => {
    updateAutogeneratedItemComments();
  }, [checklistItems.map((item) => `${item.value.slug}-${item.value.status}`).join('/')]);

  return (
    !!itemsToDisplay.length && (
      <div className="checklist checklist_v2">
        {groupElements.map((group, index) => {
          const groupComments = Object.fromEntries(
            groups.map((g) => [
              g.slug,
              placeholdersHelper.selectedDatum(
                `${groupCommentsSlugPrefix}${g.slug}.${normalizeStatus(
                  group.props.status ?? group.props.props.status
                )}`,
                fetus
              )?.comment,
            ])
          );

          const status = normalizeStatus(group.props.status ?? group.props.props.status);

          return (
            <ReportTemplateChecklistGroup
              key={group.props.status || group.label || group.props.label || index}
              props={{
                ...group.props,
                status: normalizeStatus(group.props.status ?? group.props.props.status),
              }}
              componentUID={componentUID}
              fetus={fetus}
              items={itemsToDisplay.filter((item) => item.status === status)}
              status={status}
              allowedSlugs={items}
              groups={groups}
              groupComments={groupComments}
              setEditChecklistDialogIsOpen={setEditChecklistDialogIsOpen}
              apiVersion={apiVersion}
            />
          );
        })}
      </div>
    )
  );
};

const ReportTemplateChecklistGroup = withTranslation()(
  ({
    t: __,
    i18n: { language: currentLanguage },
    props,
    componentUID,
    fetus,
    items,
    allowedSlugs,
    groups,
    groupComments,
    setEditChecklistDialogIsOpen,
    apiVersion,
  }) => {
    const hasNotes = groups.some((group) => !!groupComments[group.slug]);
    const printable =
      !!items.filter((item) => item.status !== 'not_applicable').length || !!groupComments.length || hasNotes;

    return (
      <div className={`checklist_group ${printable ? '' : 'not-printable'}`}>
        <label>{props.label || props.props.label}</label>
        {groups.map((group) => {
          const groupItems = items.filter(onlyUnique).filter((item) => item.group_id === group.id);
          const hasGroupNotes = !!groupComments[group.slug];
          return !groupItems.length && !hasGroupNotes ? (
            false
          ) : (
            <div
              key={group.id}
              onClick={() =>
                setEditChecklistDialogIsOpen({
                  componentUID,
                  slug: group.slug,
                  allowedSlugs,
                  fetus,
                })
              }
            >
              <ReportTemplateBlock props={{ layout: 'columns', 'auto-hide': 'true' }} apiVersion={apiVersion}>
                <ReportTemplateBlock props={{ width: '20%' }} apiVersion={apiVersion}>
                  <ReportTemplateLabel props={{}} apiVersion={apiVersion}>
                    {group.name}
                  </ReportTemplateLabel>
                </ReportTemplateBlock>
                <ReportTemplateBlock apiVersion={apiVersion}>
                  {/* Here we intersperse items with "," so we need to create a bigger array */}
                  {!!groupItems.length &&
                    [...new Array((groupItems.length - 1) * 2 + 1)].map((_, index) => {
                      /* if we are on a odd index it means we are on the intersperser */
                      const item = groupItems[Math.floor(index / 2)];
                      if (index % 2 === 1)
                        return <React.Fragment key={'checklist_item_comma_' + item.item_id}>,&nbsp; </React.Fragment>;

                      return (
                        <span key={'checklist_item_' + item.item_id} className="checklist_item has-printable-value">
                          <span style={{ whiteSpace: 'pre' }}>{item.label?.[currentLanguage]}</span>
                          {!!item.comment && <span className="checklist_comment">({item.comment})</span>}
                          {item.children?.length && (
                            <span className="children">
                              {item.children.map((child) => (
                                <span key={child.id}>
                                  <Icon name="right" />
                                  {child.label?.[currentLanguage]}
                                </span>
                              ))}
                            </span>
                          )}
                        </span>
                      );
                    })}
                  {hasGroupNotes && <div className="has-printable-value">{groupComments[group.slug]}</div>}
                </ReportTemplateBlock>
              </ReportTemplateBlock>
            </div>
          );
        })}
        {!items.length && !hasNotes && (
          <div
            className="nothing-to-display"
            onClick={() =>
              setEditChecklistDialogIsOpen({
                componentUID,
                allowedSlugs,
                fetus,
              })
            }
          >
            <ReportTemplateBlock props={{ layout: 'columns', 'auto-hide': 'true' }}>
              <ReportTemplateBlock props={{ width: '20%' }}></ReportTemplateBlock>
              <ReportTemplateBlock>{__('checklistItems.nothingToDisplay')}</ReportTemplateBlock>
            </ReportTemplateBlock>
          </div>
        )}
      </div>
    );
  }
);

const WithTranslationReportTemplateChecklistBody = withTranslation()(ReportTemplateChecklistBody);

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateChecklist(fullProps) {
  const { props, placeholders, groups, children, updateComponentChecklistAssoc = () => {}, ...otherProps } = fullProps;

  const checklistHelper = new ChecklistHelper(fullProps);
  const groupElements = Array.isArray(children)
    ? children.filter((child) => isValidElement(child) && child.props?.props?.type === 'group')
    : [];
  const componentUID = props.id || 'fetal_anatomy';

  const groupCommentsSlugPrefix = `checklist.${
    componentUID === 'fetal_anatomy' ? '' : componentUID + '.' // for backward compatibility we keep fetal_anatomy empty
  }group.`;

  const groupsRequiredPlaceholders = groupElements
    .map((group) => {
      return groups.map(
        (g) => `${groupCommentsSlugPrefix}${g.slug}.${normalizeStatus(group.props.status ?? group.props.props.status)}`
      );
    })
    .flat();

  const conditionRequiredPlaceholderList = checklistHelper.conditionRequiredPlaceholderList();

  const items = props.items
    .replace(/\s+/g, '')
    .split('|')
    .filter((ci) => ci)
    .filter(onlyUnique);

  const requiredChecklistItems = items.map((item) => `checklist.item.${item}`);

  /* Remove assoc only if the component is unmounted */
  useEffect(() => {
    return () => updateComponentChecklistAssoc(componentUID, []);
  }, []);

  /* TODO - optional view CI are not handled here */
  useEffect(() => {
    updateComponentChecklistAssoc(componentUID, items);
  }, [componentUID, props.items]);

  /* If the placeholder is not loaded put an empty array */
  const optionalViewItems =
    placeholders['checklist.optional_views'] && placeholders['fetus.order'] && componentUID === 'fetal_anatomy'
      ? new PlaceholdersHelper({
          ...otherProps,
          props,
          placeholders,
        }).selectedValue('checklist.optional_views', 0)?.value
      : [];

  const optionalViewRequiredChecklistItems = optionalViewItems.map((item) => `checklist.item.${item}`);
  const requiredPlaceholders = [
    'fetus.order',
    ...groupsRequiredPlaceholders,
    ...conditionRequiredPlaceholderList,
    ...requiredChecklistItems,
    ...optionalViewRequiredChecklistItems,
  ];

  return (
    <PlaceholderLoader
      Component={WithTranslationReportTemplateChecklistBody}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      props={props}
      items={[...items, ...optionalViewItems]}
      groupElements={groupElements}
      groups={groups}
      componentUID={componentUID}
      groupCommentsSlugPrefix={groupCommentsSlugPrefix}
      {...otherProps}
    >
      {children}
    </PlaceholderLoader>
  );
}
