import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../atoms/Button/Button';
import TextArea from '../../atoms/TextArea/TextArea';
import SimplePopup from '../Popup/SimplePopup';

const ReviseExamPopup = ({ t: __, close, onReviseExam }) => {
  const [reason, setReason] = useState('');

  const handleClose = () => {
    setReason('');
    close();
  };

  return (
    <SimplePopup>
      <p
        className="message-centred"
        dangerouslySetInnerHTML={{ __html: __('examinationReview.dialog.blockEditingReader') }}
      />
      <p>{__('examinationReview.revision.title')}</p>
      <div>
        <TextArea fullwidth={true} value={reason} onChange={(v) => setReason(v)} />
      </div>
      <Button style={{ marginTop: '1rem' }} label={__('CTA.cancel')} variant="outline" onClick={handleClose} />
      <Button
        style={{ marginLeft: '1rem' }}
        label={__('CTA.confirm')}
        onClick={() => onReviseExam(reason)}
        disabled={reason.length === 0}
        title={__('examinationReview.revision.disabledSubmitReason')}
      />
    </SimplePopup>
  );
};

export default withTranslation()(ReviseExamPopup);
