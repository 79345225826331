import React from 'react';
import './icon.css';

import { ReactComponent as AddComment } from './icons/add-comment.svg';
import { ReactComponent as Add } from './icons/add.svg';
import { ReactComponent as Annotation } from './icons/annotation.svg';
import { ReactComponent as AiDetection } from './icons/ai-detection.svg';
import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as ArrowLeft } from './icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
import { ReactComponent as Attachment } from './icons/attachment.svg';
import { ReactComponent as Attention } from './icons/attention.svg';
import { ReactComponent as Bell } from './icons/bell.svg';
import { ReactComponent as Book } from './icons/book.svg';
import { ReactComponent as Calendar } from './icons/calendar.svg';
import { ReactComponent as ChangeSlide } from './icons/change-slide.svg';
import { ReactComponent as Circle } from './icons/circle.svg';
import { ReactComponent as Clock } from './icons/clock.svg';
import { ReactComponent as Close } from './icons/close.svg';
import { ReactComponent as Comment } from './icons/comment.svg';
import { ReactComponent as Connected } from './icons/connected.svg';
import { ReactComponent as ConnectionError } from './icons/connection-error.svg';
import { ReactComponent as Deer } from './icons/deer.svg';
import { ReactComponent as Detach } from './icons/detach.svg';
import { ReactComponent as Distance } from './icons/distance.svg';
import { ReactComponent as Dna } from './icons/dna.svg';
import { ReactComponent as DoneAll } from './icons/done-all.svg';
import { ReactComponent as DoneCircled } from './icons/done-circled.svg';
import { ReactComponent as Done } from './icons/done.svg';
import { ReactComponent as Doppler } from './icons/doppler.svg';
import { ReactComponent as DownBig } from './icons/down-big.svg';
import { ReactComponent as Down } from './icons/down.svg';
import { ReactComponent as Download } from './icons/download.svg';
import { ReactComponent as Drag } from './icons/drag.svg';
import { ReactComponent as DrawCircle } from './icons/draw-circle.svg';
import { ReactComponent as Edit } from './icons/edit.svg';
import { ReactComponent as Error } from './icons/error.svg';
import { ReactComponent as EyeOff } from './icons/eye-off.svg';
import { ReactComponent as Eye } from './icons/eye.svg';
import { ReactComponent as Family } from './icons/family.svg';
import { ReactComponent as Fax } from './icons/fax.svg';
import { ReactComponent as FeedingBottle } from './icons/feeding-bottle.svg';
import { ReactComponent as Female } from './icons/female.svg';
import { ReactComponent as Fetus } from './icons/fetus.svg';
import { ReactComponent as FetusSexHidden } from './icons/fetus-sex-hidden.svg';
import { ReactComponent as Filter } from './icons/filter.svg';
import { ReactComponent as Flash } from './icons/flash.svg';
import { ReactComponent as Flowchart } from './icons/flowchart.svg';
import { ReactComponent as Heartrate } from './icons/heartrate.svg';
import { ReactComponent as Home } from './icons/home.svg';
import { ReactComponent as Hospital } from './icons/hospital.svg';
import { ReactComponent as Hpanels } from './icons/hpanels.svg';
import { ReactComponent as ImageKo } from './icons/image-ko.svg';
import { ReactComponent as ImageOk } from './icons/image-ok.svg';
import { ReactComponent as Image } from './icons/image.svg';
import { ReactComponent as Inbox } from './icons/inbox.svg';
import { ReactComponent as Info } from './icons/info.svg';
import { ReactComponent as Language } from './icons/language.svg';
import { ReactComponent as LeftBig } from './icons/left-big.svg';
import { ReactComponent as Left } from './icons/left.svg';
import { ReactComponent as Line } from './icons/line.svg';
import { ReactComponent as Link } from './icons/link.svg';
import { ReactComponent as Location } from './icons/location.svg';
import { ReactComponent as LockOpen } from './icons/lock_open.svg';
import { ReactComponent as LockClosed } from './icons/lock_closed.svg';
import { ReactComponent as Mail } from './icons/mail.svg';
import { ReactComponent as Male } from './icons/male.svg';
import { ReactComponent as Manual } from './icons/manual.svg';
import { ReactComponent as MatchingScore } from './icons/matching-score.svg';
import { ReactComponent as Menu } from './icons/menu.svg';
import { ReactComponent as Microphone } from './icons/microphone.svg';
import { ReactComponent as More } from './icons/more.svg';
import { ReactComponent as MoreVertical } from './icons/more-vertical.svg';
import { ReactComponent as Ocr } from './icons/ocr.svg';
import { ReactComponent as OcrApprox } from './icons/ocr-approx.svg';
import { ReactComponent as Off } from './icons/off.svg';
import { ReactComponent as Paste } from './icons/paste.svg';
import { ReactComponent as Patch } from './icons/patch.svg';
import { ReactComponent as Patient } from './icons/patient.svg';
import { ReactComponent as Pause } from './icons/pause.svg';
import { ReactComponent as Phone } from './icons/phone.svg';
import { ReactComponent as Pills } from './icons/pills.svg';
import { ReactComponent as Pin } from './icons/pin.svg';
import { ReactComponent as PlayCircled } from './icons/play-circled.svg';
import { ReactComponent as Play } from './icons/play.svg';
import { ReactComponent as Pointer } from './icons/pointer.svg';
import { ReactComponent as Position } from './icons/position.svg';
import { ReactComponent as PrintAdd } from './icons/print-add.svg';
import { ReactComponent as PrintDisabled } from './icons/print-disabled.svg';
import { ReactComponent as PrintRemove } from './icons/print-remove.svg';
import { ReactComponent as Print } from './icons/print.svg';
import { ReactComponent as Report } from './icons/report.svg';
import { ReactComponent as Ruler } from './icons/ruler.svg';
import { ReactComponent as QcKo } from './icons/qc-ko.svg';
import { ReactComponent as QcOk } from './icons/qc-ok.svg';
import { ReactComponent as Qrcode } from './icons/qr-code.svg';
import { ReactComponent as Question } from './icons/question.svg';
import { ReactComponent as Redo } from './icons/redo.svg';
import { ReactComponent as Reload } from './icons/reload.svg';
import { ReactComponent as RightBig } from './icons/right-big.svg';
import { ReactComponent as Right } from './icons/right.svg';
import { ReactComponent as ScoreLow } from './icons/score-low.svg';
import { ReactComponent as ScoreMedium } from './icons/score-medium.svg';
import { ReactComponent as Search } from './icons/search.svg';
import { ReactComponent as Serology } from './icons/serology.svg';
import { ReactComponent as SettingsAlt } from './icons/settings-alt.svg';
import { ReactComponent as Settings } from './icons/settings.svg';
import { ReactComponent as Share } from './icons/share.svg';
import { ReactComponent as SharingIndicator } from './icons/sharing_indicator.svg';
import { ReactComponent as Shutter } from './icons/shutter.svg';
import { ReactComponent as SidebarClose } from './icons/sidebar_close.svg';
import { ReactComponent as SidebarOpen } from './icons/sidebar_open.svg';
import { ReactComponent as Sonio } from './icons/sonio.svg';
import { ReactComponent as Spin3 } from './icons/spin3.svg';
import { ReactComponent as StarEmpty } from './icons/star-empty.svg';
import { ReactComponent as StarHalf } from './icons/star-half.svg';
import { ReactComponent as Star } from './icons/star.svg';
import { ReactComponent as Stars } from './icons/stars.svg';
import { ReactComponent as Stats } from './icons/stats.svg';
import { ReactComponent as Stethoscope } from './icons/stethoscope.svg';
import { ReactComponent as StickyNote } from './icons/sticky-note.svg';
import { ReactComponent as Subtract } from './icons/subtract.svg';
import { ReactComponent as Switch } from './icons/switch.svg';
import { ReactComponent as Tab } from './icons/tab.svg';
import { ReactComponent as Tag } from './icons/tag.svg';
import { ReactComponent as Tiles } from './icons/tiles.svg';
import { ReactComponent as Trash } from './icons/trash.svg';
import { ReactComponent as Turn } from './icons/turn.svg';
import { ReactComponent as Ultrasound } from './icons/ultrasound.svg';
import { ReactComponent as Undo } from './icons/undo.svg';
import { ReactComponent as Unfold } from './icons/unfold.svg';
import { ReactComponent as Unlink } from './icons/unlink.svg';
import { ReactComponent as UpBig } from './icons/up-big.svg';
import { ReactComponent as Up } from './icons/up.svg';
import { ReactComponent as User } from './icons/user.svg';
import { ReactComponent as Verified } from './icons/verified.svg';
import { ReactComponent as VideoExtractionAi } from './icons/video-extraction-ai.svg';
import { ReactComponent as VideoFrame } from './icons/video-frame.svg';
import { ReactComponent as Video } from './icons/video.svg';
import { ReactComponent as ViewCarousel } from './icons/view-carousel.svg';
import { ReactComponent as Virus } from './icons/virus.svg';
import { ReactComponent as Visibility } from './icons/visibility.svg';
import { ReactComponent as Vpanels } from './icons/vpanels.svg';
import { ReactComponent as Warning } from './icons/warning.svg';
import { ReactComponent as Wine } from './icons/wine.svg';
import { ReactComponent as Woman } from './icons/woman.svg';
import { ReactComponent as Workspace } from './icons/workspace.svg';
import { ReactComponent as World } from './icons/world.svg';
import { ReactComponent as ZoomIn } from './icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from './icons/zoom-out.svg';
import { ReactComponent as UploadFile } from './icons/upload-file.svg';
import { ReactComponent as Upload } from './icons/upload.svg';
import { ReactComponent as UploadArrow } from './icons/upload-arrow.svg';
import { ReactComponent as File } from './icons/file.svg';
import { ReactComponent as CheckboxDone } from './icons/checkbox-done.svg';
import { ReactComponent as CheckboxEmpty } from './icons/checkbox-empty.svg';
import { ReactComponent as Picture } from './icons/picture.svg';
import { ReactComponent as Cinema } from './icons/cinema.svg';
import { ReactComponent as FileWithLine } from './icons/file-with-line.svg';
import { ReactComponent as FolderOpen } from './icons/folder-open.svg';
import { ReactComponent as FolderClosed } from './icons/folder-closed.svg';

const displayIcon = (iconName) => {
  switch (iconName) {
    case 'add-comment':
      return <AddComment />;
    case 'add':
      return <Add />;
    case 'ai-detection':
      return <AiDetection />;
    case 'annotation':
      return <Annotation />;
    case 'arrow-down':
      return <ArrowDown />;
    case 'arrow-left':
      return <ArrowLeft />;
    case 'arrow-right':
      return <ArrowRight />;
    case 'arrow-up':
      return <ArrowUp />;
    case 'attachment':
      return <Attachment />;
    case 'attention':
      return <Attention />;
    case 'bell':
      return <Bell />;
    case 'book':
      return <Book />;
    case 'calendar':
      return <Calendar />;
    case 'circle':
      return <Circle />;
    case 'change-slide':
      return <ChangeSlide />;
    case 'clock':
      return <Clock />;
    case 'close':
      return <Close />;
    case 'comment':
      return <Comment />;
    case 'connected':
      return <Connected />;
    case 'connection-error':
      return <ConnectionError />;
    case 'deer':
      return <Deer />;
    case 'detach':
      return <Detach />;
    case 'distance':
      return <Distance />;
    case 'dna':
      return <Dna />;
    case 'done-all':
      return <DoneAll />;
    case 'done':
      return <Done />;
    case 'done-circled':
      return <DoneCircled />;
    case 'doppler':
      return <Doppler />;
    case 'down-big':
      return <DownBig />;
    case 'down':
      return <Down />;
    case 'download':
      return <Download />;
    case 'drag':
      return <Drag />;
    case 'draw-circle':
      return <DrawCircle />;
    case 'edit':
      return <Edit />;
    case 'empty':
      return false;
    case 'error':
      return <Error />;
    case 'eye-off':
      return <EyeOff />;
    case 'eye':
      return <Eye />;
    case 'fax':
      return <Fax />;
    case 'family':
      return <Family />;
    case 'feeding-bottle':
      return <FeedingBottle />;
    case 'female':
      return <Female />;
    case 'fetus':
      return <Fetus />;
    case 'fetus-sex-hidden':
      return <FetusSexHidden />;
    case 'filter':
      return <Filter />;
    case 'flash':
      return <Flash />;
    case 'flowchart':
      return <Flowchart />;
    case 'folder-open':
      return <FolderOpen />;
    case 'folder-closed':
      return <FolderClosed />;
    case 'heartrate':
      return <Heartrate />;
    case 'home':
      return <Home />;
    case 'hospital':
      return <Hospital />;
    case 'hpanels':
      return <Hpanels />;
    case 'image-ko':
      return <ImageKo />;
    case 'image-ok':
      return <ImageOk />;
    case 'image':
      return <Image />;
    case 'inbox':
      return <Inbox />;
    case 'info':
      return <Info />;
    case 'language':
      return <Language />;
    case 'left-big':
      return <LeftBig />;
    case 'left':
      return <Left />;
    case 'line':
      return <Line />;
    case 'link':
      return <Link />;
    case 'location':
      return <Location />;
    case 'lock_open':
      return <LockOpen />;
    case 'lock_closed':
      return <LockClosed />;
    case 'mail':
      return <Mail />;
    case 'male':
      return <Male />;
    case 'manual':
      return <Manual />;
    case 'matching-score':
      return <MatchingScore />;
    case 'menu':
      return <Menu />;
    case 'microphone':
      return <Microphone />;
    case 'more':
      return <More />;
    case 'more-vertical':
      return <MoreVertical />;
    case 'ocr':
      return <Ocr />;
    case 'ocr-approx':
      return <OcrApprox />;
    case 'off':
      return <Off />;
    case 'paste':
      return <Paste />;
    case 'patch':
      return <Patch />;
    case 'patient':
      return <Patient />;
    case 'pause':
      return <Pause />;
    case 'phone':
      return <Phone />;
    case 'pills':
      return <Pills />;
    case 'pin':
      return <Pin />;
    case 'play-circled':
      return <PlayCircled />;
    case 'play':
      return <Play />;
    case 'pointer':
      return <Pointer />;
    case 'position':
      return <Position />;
    case 'print-add':
      return <PrintAdd />;
    case 'print-disabled':
      return <PrintDisabled />;
    case 'print-remove':
      return <PrintRemove />;
    case 'print':
      return <Print />;
    case 'qc-ko':
      return <QcKo />;
    case 'qc-ok':
      return <QcOk />;
    case 'qrcode':
      return <Qrcode />;
    case 'question':
      return <Question />;
    case 'redo':
      return <Redo />;
    case 'reload':
      return <Reload />;
    case 'report':
      return <Report />;
    case 'right-big':
      return <RightBig />;
    case 'right':
      return <Right />;
    case 'ruler':
      return <Ruler />;
    case 'score-low':
      return <ScoreLow />;
    case 'score-medium':
      return <ScoreMedium />;
    case 'search':
      return <Search />;
    case 'serology':
      return <Serology />;
    case 'settings-alt':
      return <SettingsAlt />;
    case 'settings':
      return <Settings />;
    case 'share':
      return <Share />;
    case 'sharing-indicator':
      return <SharingIndicator />;
    case 'shutter':
      return <Shutter />;
    case 'sidebar-close':
      return <SidebarClose />;
    case 'sidebar-open':
      return <SidebarOpen />;
    case 'sonio':
      return <Sonio />;
    case 'spin3':
      return <Spin3 />;
    case 'star-empty':
      return <StarEmpty />;
    case 'star-half':
      return <StarHalf />;
    case 'star':
      return <Star />;
    case 'stars':
      return <Stars />;
    case 'stats':
      return <Stats />;
    case 'stethoscope':
      return <Stethoscope />;
    case 'sticky-note':
      return <StickyNote />;
    case 'subtract':
      return <Subtract />;
    case 'switch':
      return <Switch />;
    case 'tab':
      return <Tab />;
    case 'tag':
      return <Tag />;
    case 'tiles':
      return <Tiles />;
    case 'trash':
      return <Trash />;
    case 'turn':
      return <Turn />;
    case 'ultrasound':
      return <Ultrasound />;
    case 'undo':
      return <Undo />;
    case 'unfold':
      return <Unfold />;
    case 'unlink':
      return <Unlink />;
    case 'up-big':
      return <UpBig />;
    case 'up':
      return <Up />;
    case 'user':
      return <User />;
    case 'verified':
      return <Verified />;
    case 'video':
      return <Video />;
    case 'video-extraction-ai':
      return <VideoExtractionAi />;
    case 'video-frame':
      return <VideoFrame />;
    case 'view-carousel':
      return <ViewCarousel />;
    case 'virus':
      return <Virus />;
    case 'visibility':
      return <Visibility />;
    case 'vpanels':
      return <Vpanels />;
    case 'warning':
      return <Warning />;
    case 'wine':
      return <Wine />;
    case 'woman':
      return <Woman />;
    case 'workspace':
      return <Workspace />;
    case 'world':
      return <World />;
    case 'zoom-in':
      return <ZoomIn />;
    case 'zoom-out':
      return <ZoomOut />;
    case 'upload-file':
      return <UploadFile />;
    case 'upload':
      return <Upload />;
    case 'upload-arrow':
      return <UploadArrow />;
    case 'file':
      return <File />;
    case 'checkbox-done':
      return <CheckboxDone />;
    case 'checkbox-empty':
      return <CheckboxEmpty />;
    case 'picture':
      return <Picture />;
    case 'cinema':
      return <Cinema />;
    case 'file-with-line':
      return <FileWithLine />;
    default:
      return false;
  }
};

export default function Icon({ name = 'empty', className = '', ...props }) {
  return (
    <span data-test-id={`icon_${name}${className}`} className={`icon ${name} ${className}`} {...props}>
      {displayIcon(name)}
    </span>
  );
}
