import { withTranslation } from 'react-i18next';
import { isNullOrUndefined } from '../../utils';
import './PercentileGraph.css';

const PercentileGraph = ({ percentile, atRisk }) => {
  const svgWidth = 100;
  const svgHeight = 12;
  const markerRadius = 4;
  const backgroundWidth = svgWidth - markerRadius * 2;
  const percentileX = (backgroundWidth / 100) * percentile + markerRadius;

  return (
    <div className="percentile-graph-wrapper">
      <svg viewBox={`0 0 ${svgWidth} ${svgHeight}`} className={atRisk ? 'at-risk' : ''}>
        <rect
          className="background"
          x={markerRadius}
          y="1"
          width={svgWidth - markerRadius * 2}
          height={svgHeight - 2}
        />
        <line
          className="half"
          x1={backgroundWidth / 2 + markerRadius}
          y1="0"
          x2={backgroundWidth / 2 + markerRadius}
          y2={svgHeight}
        />
        {!isNullOrUndefined(percentile) && (
          <path
            className="marker"
            d={`M-${markerRadius} 0 L 0 ${markerRadius} L ${markerRadius} 0 L 0 -${markerRadius}Z`}
            transform={`translate(${percentileX}, ${svgHeight / 2})`}
          />
        )}
      </svg>
    </div>
  );
};

export default withTranslation()(PercentileGraph);
