import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';
import DashboardView from './DashboardView';
import DashboardSoniopediaView from './DashboardSoniopediaView';
import ResourceApi from '../../services/resource';

const DashbaordViewIndex = () => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled, isFeatureFlagsLoaded } = useAuth();

  const [exams, setExams] = useState([]);

  const deleteExamination = (exam) => {
    examinationContext.deleteDraftExamination(exam, () => {
      ResourceApi.filterExaminations().then(({ data: { data } }) => setExams(data));
    });
  };

  const canEvaluateRedirectionToLiveView = isFeatureFlagsLoaded && appContext.allPresets;
  const mustRedirectToLiveView =
    !isFeatureFlagEnabled('soniopedia') &&
    (!isFeatureFlagEnabled('sonio.detect') || isFeatureFlagEnabled('sonio.routine'));

  useEffect(async () => {
    if (canEvaluateRedirectionToLiveView) {
      if (mustRedirectToLiveView) {
        // React history.push does not work since this page is live view based.
        /* Rely on the redirection in router.ex */
        location.href = '/dashboard';
      }
      // when opening the app, create and open a new draft exam by defaul
      else if (!examinationContext.examination?.id) {
        const defaultExamType = isFeatureFlagEnabled('soniopedia')
          ? 'diagnostic'
          : isFeatureFlagEnabled('sonio.dx_v2')
          ? appContext.preferences.default_exam_type
          : 'screening';
        const trimester = defaultExamType === 'diagnostic' ? 'T2' : null;
        const presetId =
          defaultExamType === 'diagnostic'
            ? appContext.allPresets.find((preset) => preset.trimester === trimester)?.id
            : null;
        await examinationContext.createDraftExam(null, trimester, presetId);
      } else if (isFeatureFlagEnabled('soniopedia'))
        ResourceApi.filterExaminations().then(({ data: { data } }) => setExams(data));
      // Reload draft exams every time this component mounts
      else examinationContext.loadDraftExams();
    }
  }, [isFeatureFlagsLoaded, !appContext.allPresets, examinationContext.examination?.id]);

  if (!canEvaluateRedirectionToLiveView) return null;
  if (canEvaluateRedirectionToLiveView && mustRedirectToLiveView) return null;

  return isFeatureFlagEnabled('soniopedia') ? (
    <DashboardSoniopediaView
      deleteExam={deleteExamination}
      allExams={exams}
      medicalHistoryItems={examinationContext.medicalHistoryItems}
    />
  ) : !examinationContext.examination?.id ? (
    false
  ) : (
    <DashboardView
      createDraftExam={examinationContext.createDraftExam}
      deleteDraftExam={examinationContext.deleteDraftExamination}
      draftExams={examinationContext.draftExams}
      medicalHistoryItems={examinationContext.medicalHistoryItems}
    />
  );
};

export default DashbaordViewIndex;
