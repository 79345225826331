import React, { useEffect, useState } from 'react';
import Icon from '../../atoms/Icon/Icon';
import LookupApi from '../../services/lookup';

export default function SRExtraction({ openChapter, setOpenChapter, examination }) {
  const SRExtractionContent = () => {
    const [SRExtraction, setSRExtraction] = useState(null);
    const [openSR, setOpenSR] = useState(null);

    useEffect(() => {
      LookupApi.getSRExtraction(examination.id).then((response) => {
        setSRExtraction(response.data);
      });
    }, []);

    const toogleOpen = (index) => {
      if (openSR === index) {
        setOpenSR(null);
      } else {
        setOpenSR(index);
      }
    };

    if (!SRExtraction) {
      return <div>no SR extraction</div>;
    }

    return (
      <div className="debug-subsection">
        <h3>SR extraction</h3>
        {SRExtraction.map((sr, index) => (
          <div>
            <div onClick={() => toogleOpen(index)}>
              <b>
                <label>SR (id: {sr.id}) </label>
                <Icon name={openSR === index ? 'up' : 'down'} />
              </b>
            </div>
            {openSR === index && <pre>{JSON.stringify(sr.extraction, null, 2)}</pre>}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`debug-section ${openChapter === 'sr-extraction' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'sr-extraction' ? false : 'sr-extraction'))}>
        SR extraction
        <Icon name="down" />
      </h2>
      {openChapter === 'sr-extraction' && <SRExtractionContent />}
    </div>
  );
}
