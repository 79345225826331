import React from 'react';
import Icon from '../Icon/Icon';
import './CheckboxInput.css';

const CheckboxInput = ({ checked, onClick, variant = 'primary' }) => {
  if (variant === 'secondary') {
    return <Icon name={checked ? 'checkbox-done' : 'checkbox-empty'} onClick={onClick} />;
  }

  return (
    <div onClick={onClick} className={`checkbox-input-wrapper ${checked ? '' : 'not-'}checked`}>
      {checked && <Icon name="done" />}
    </div>
  );
};

export default CheckboxInput;
