import React from 'react';
import { formatName, formatProbability, getNiceGestionalAge, getSyndromeName } from '../../../../services/examination';
import { withTranslation } from 'react-i18next';
import { formatDate, formatDateTime } from '../../../../utils';
import Fetus from '../../../../components/Fetus/Fetus';
import ProgressRing from '../../../../atoms/ProgressRing/ProgressRing';
import Button from '../../../../atoms/Button/Button';
import Icon from '../../../../atoms/Icon/Icon';
import useAuth from '../../../../context-providers/Auth';

const ExaminationReviewOverview = ({
  t: __,
  patient,
  episode,
  examination,
  examinationMetadata,
  zones,
  riskFactors,
  teratogenicRisks,
  instances,
  slides,
  biometries,
  syndromes,
  appContext,
  examinationContext,
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const { isFeatureFlagEnabled } = useAuth();

  const reliabilityScore = examination?.recommendation?.diagnostic_reliability?.continuous * 100 || 0;
  const reliabilityIndex = examination?.recommendation?.diagnostic_reliability?.discrete;
  const malformationsYes = examination.malformations?.filter((malformation) => malformation.status === 'yes');

  const goToSyndromeDetails = (syndromeId) => {
    const target = document.querySelector(`#syndrome-details-${syndromeId}`);
    const container = document.querySelector('.examination-review-container .examination-review');
    if (target && container) {
      container.scrollTo({ top: target.getBoundingClientRect().top + container.scrollTop, behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="row">
        <div className="w6">
          <div className="card">
            <div className="entry">
              <label>{__('examinationReview.trimester')}</label>
              <span>
                {examination.trimester} {__('examinationReview.' + examination.type)}
              </span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.examinationDate')}</label>
              <span>{formatDateTime(examination.updated_at, appContext.preferences.date_format)}</span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.practitioner.name')}</label>
              <span>{examinationMetadata.practitioner?.name || __('examinationReview.notProvided')}</span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.practitioner.site')}</label>
              <span>{examinationMetadata.site?.name || __('examinationReview.notProvided')}</span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.practitioner.rpps')}</label>
              <span>{examinationMetadata.practitioner?.rpps || __('examinationReview.notProvided')}</span>
            </div>
          </div>
        </div>
        <div className="w6">
          <div className="card">
            <div className="entry">
              <label>{__('examinationReview.patient.name')}</label>
              <span>{patient?.name ? formatName(patient?.name).fullName : __('patients.anonymous')}</span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.patient.dob')}</label>
              <span>
                {patient?.dob
                  ? formatDate(patient?.dob, appContext.preferences.date_format)
                  : __('examinationReview.notProvided')}
              </span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.episode.conceptionDate')}</label>
              <span>
                {episode?.conception_date
                  ? formatDate(episode?.conception_date, appContext.preferences.date_format)
                  : __('examinationReview.notProvided')}
              </span>
            </div>
            <div className="entry">
              <label>{__('examinationReview.episode.lmpDate')}</label>
              <span>
                {episode?.lmp_date
                  ? formatDate(episode?.lmp_date, appContext.preferences.date_format)
                  : __('examinationReview.notProvided')}
              </span>
            </div>
            {episode?.lmp_date && (
              <div className="entry">
                <label>{__('examinationReview.episode.gestationalAge')}</label>
                <span>{getNiceGestionalAge(__, new Date(episode?.lmp_date), examination?.examination_date)}</span>
              </div>
            )}
            {examinationContext.fetusSexVisibility === 'visible' && (
              <div className="entry">
                <label>
                  {examinationContext.medicalHistoryItems?.['medicalexam.fetus.sex']?.label[currentLanguage]}
                </label>
                <span>
                  {examinationContext.medicalHistoryItems?.['medicalexam.fetus.sex']?.options?.find(
                    (option) => option.value === examination.medical_history?.['medicalexam.fetus.sex']?.value
                  )?.label[currentLanguage] || __('examinationReview.notProvided')}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="w4">
          <div className="card">
            <h2>{__('examinationReview.zones')}</h2>
            <div className="examination-review-overview-fetus">
              <Fetus
                zones={zones?.map((zone) => ({
                  id: zone.id,
                  validated: examination.zones?.find((z) => z.id === zone.id)?.status === 'validated',
                  unusual: examinationContext.examination?.malformations?.some(
                    (m) => examinationContext.getMalformationById(m.id)?.exam_zone_id === zone.id && m.status === 'yes'
                  ),
                }))}
              />
            </div>
            {examination.type === 'diagnostic' && (
              <div className="examination-review-overview-zones">
                <ul>
                  {zones.map((zone) => (
                    <li key={zone.id}>
                      <span>{zone.label[currentLanguage]}</span>
                      {examinationContext.examination?.zones?.some(
                        (z) => z.id === zone.id && z.status === 'validated'
                      ) ? (
                        <span className="validated">{__('examinationReview.zones.validated')}</span>
                      ) : (
                        <span className="not-validated">{__('examinationReview.zones.notValidated')}</span>
                      )}
                      {examinationContext.examination?.malformations?.some(
                        (m) =>
                          examinationContext.getMalformationById(m.id)?.exam_zone_id === zone.id && m.status === 'yes'
                      ) && <span className="unusual">{__('examinationReview.zones.unusual')}</span>}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="w4 cards">
          <div className="card">
            <h2>{__('examinationReview.riskFactors')}</h2>
            <div className="examination-review-risk-factors">
              {!riskFactors.length &&
              !teratogenicRisks.length &&
              !examination.medical_history?.['genetichistory.cgh'] &&
              !examination.medical_history?.['genetichistory.karyotype'] &&
              !examination.medical_history?.['genetichistory.dpni'] ? (
                <div className="examination-review-nostudies">{__('patient_details.noRiskFactorsAvailable')}</div>
              ) : (
                <>
                  {['cgh', 'karyotype', 'dpni'].map((test) =>
                    examination.medical_history?.['genetichistory.' + test] ? (
                      <div key={test} className="entry">
                        <label>
                          {examinationContext.medicalHistoryItems?.['genetichistory.' + test]?.label[currentLanguage]}
                        </label>
                        <span>
                          {examinationContext.medicalHistoryItems?.['genetichistory.' + test]?.options?.find(
                            (option) => option.value === examination.medical_history?.['genetichistory.' + test]?.value
                          )?.label[currentLanguage] || __('examinationReview.notProvided')}
                        </span>
                      </div>
                    ) : (
                      false
                    )
                  )}
                  {examination.medical_history?.['genetichistory.cgh']?.value === 'yes_and_positive' ||
                  examination.medical_history?.['genetichistory.karyotype']?.value === 'yes_and_positive' ||
                  examination.medical_history?.['genetichistory.dpni']?.value === 'yes_and_positive' ? (
                    <div className="entry">
                      <label>{__('examinationReview.geneticResults')}</label>
                      <span>
                        {examination.medical_history?.['genetichistory.geneticist_result']?.value
                          ? examinationContext.getSyndromeById(
                              examination.medical_history?.['genetichistory.geneticist_result'].value
                            ).canonical_name[currentLanguage]
                          : __('examinationReview.notProvided')}
                      </span>
                    </div>
                  ) : (
                    false
                  )}
                  {!!riskFactors.length &&
                    riskFactors.map((risk) => (
                      <div key={risk.risk_factor_id} className="entry">
                        <label>{risk.label}</label>
                        <span>
                          {!!risk.raw_value && !risk.tmp_value && <span className="raw-value">{risk.raw_value}</span>}{' '}
                          {risk.value}
                        </span>
                      </div>
                    ))}
                  {!!teratogenicRisks.length &&
                    teratogenicRisks.map((risk) => (
                      <div key={risk.risk_factor_id} className="entry teratogenic-risks">
                        <label>{__('examinationReview.medication')}</label>
                        <span key={risk.risk_factor_id} className="teratogenic-risk">
                          {risk.label}
                        </span>
                      </div>
                    ))}
                </>
              )}
            </div>
          </div>
          {malformationsYes && (
            <div className="card">
              <h2>{__('examinationReview.anomalies')}</h2>
              <div>
                {malformationsYes.map((malformation) => {
                  const m = examinationContext.getMalformationById(malformation.id);
                  if (!m) return false;
                  return (
                    <div
                      key={malformation.id}
                      className={`examination-review-malformation status-${malformation.status}`}
                    >
                      {m.canonical_name[currentLanguage]}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {!!syndromes.length && (
          <div className="w4">
            <div className="card">
              <h2>{__('examinationReview.results')}</h2>
              <div className="reliability-index">
                <ProgressRing
                  progress={reliabilityScore}
                  label={__('dx.summary.reliabilityIndex.' + reliabilityIndex)}
                />
                <div className="reliability-index-results">{__('dx.summary.reliability.' + reliabilityIndex)}</div>
              </div>
              <ul className="examination-review-syndrome-list">
                {syndromes.slice(0, 3).map((syndrome) => {
                  const fullSyndrome = examinationContext.getSyndromeById(syndrome.id);
                  const sName = getSyndromeName(
                    fullSyndrome,
                    examinationContext.MALFORMATIONS,
                    syndrome.malformations,
                    __
                  );
                  return (
                    <li key={syndrome.id}>
                      {sName}
                      {isFeatureFlagEnabled('sonio.dx_syndrome_summary') && (
                        <>
                          {fullSyndrome?.main_biological_test?.name[currentLanguage] && (
                            <> - {fullSyndrome?.main_biological_test?.name[currentLanguage]}</>
                          )}
                          {fullSyndrome?.abstract[currentLanguage] && (
                            <>
                              <br />
                              <small>{fullSyndrome?.abstract[currentLanguage]}</small>
                            </>
                          )}
                        </>
                      )}
                      <div className="details">
                        <div className="probability">
                          <Icon name="matching" />{' '}
                          <span className="label">{__('examinationReview.matchingScore')}</span>{' '}
                          {formatProbability(syndrome.probability)}
                        </div>
                        <Button
                          label={__('examinationReview.details')}
                          variant="outline"
                          onClick={(e) => goToSyndromeDetails(syndrome.id)}
                        />
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withTranslation()(ExaminationReviewOverview);
