import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ExaminationMedicalHistoryView from './ExaminationMedicalHistoryView';
import ExaminationCustomMedicalHistoryView from './ExaminationCustomMedicalHistoryView';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import useAuth from '../../context-providers/Auth';

const ExaminationMedicalHistory = ({ t: __ }) => {
  const { examId } = useParams();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);

  const {
    reportDataOptions,
    placeholders,
    setPlaceholders,
    checkCondition,
    getPlaceholderWithProps,
    onEndEditing,
    onEndEditingChecklist,
    onEndEditingDating,
    editingFieldId,
    startEditing,
    startEditingField,
    setAssignedGa,
    revertAssignedGa,
    updateEpisode,
  } = useXMLTemplate();

  const { isFeatureFlagEnabled } = useAuth();
  const custom_medical_history_ff = isFeatureFlagEnabled('sonio.medical_history_v2');

  useEffect(() => {
    if (examId) examinationContext.loadExamination(examId);
  }, [examId]);

  return custom_medical_history_ff ? (
    <ExaminationCustomMedicalHistoryView
      isFeatureFlagEnabled={isFeatureFlagEnabled}
      reportDataOptions={reportDataOptions}
      placeholders={placeholders}
      setPlaceholders={setPlaceholders}
      getPlaceholderWithProps={getPlaceholderWithProps}
      appContext={appContext}
      checkCondition={checkCondition}
      onEndEditing={onEndEditing}
      onEndEditingChecklist={onEndEditingChecklist}
      onEndEditingDating={onEndEditingDating}
      editingFieldId={editingFieldId}
      startEditingField={startEditingField}
      startEditing={startEditing}
      setAssignedGa={setAssignedGa}
      revertAssignedGa={revertAssignedGa}
      updateEpisode={updateEpisode}
    />
  ) : (
    !!Object.keys(examinationContext.medicalHistoryItems).length && (
      <ExaminationMedicalHistoryView medicalHistoryItems={examinationContext.medicalHistoryItems} />
    )
  );
};

export default withTranslation()(ExaminationMedicalHistory);
