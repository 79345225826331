import React, { useState } from 'react';
import UploadFilePopup from '../components/UploadFilePopup/UploadFilePopup';

const uploadFilePopupHOC = (Component) => (props) => {
  const { onSuccesfulUpload, submit, loadNewList, ...restProps } = props;
  const [openedPopup, setOpenedPopup] = useState(false);
  const closePopup = () => setOpenedPopup(false);
  const openPopup = () => setOpenedPopup(true);

  return (
    <>
      {openedPopup && (
        <UploadFilePopup
          onClose={closePopup}
          onSuccesfulUpload={onSuccesfulUpload}
          submit={submit}
          loadNewList={loadNewList}
        />
      )}
      <Component {...restProps} onClick={openPopup} />
    </>
  );
};

export default uploadFilePopupHOC;
