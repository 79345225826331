import { onlyUnique } from '../../utils';
import { useCallback, useMemo, useState } from 'react';

/*
 * @description Normalizes the tree for a dynamic dropdown to be compatible with nested collection
 * @param {Object} data
 * @returns {Object} normalized data
 */
export function normalizeDropdown(data) {
  const tree = data?.tree?.map((node) => {
    return { ...normalizeDropdown(node) };
  });
  return { ...data, tree, id: `${data.slug}` };
}

/*
 * @description Normalizes the tree for all dynamic dropdowns to be compatible with nested collection
 * @param {Object} data
 * @returns {Object} normalized data
 */
export function normalizeAllDropdown(data) {
  return Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, { ...normalizeDropdown(value), id: value.id }])
  );
}

export function updateOptimisticPlaceholders(localPlaceholders, newPlaceholders) {
  const slugs = newPlaceholders
    .map((d) => d.slug)
    .filter(onlyUnique)
    .filter((slug) => Object.keys(localPlaceholders).includes(slug));
  const nonUpdatedData = Object.fromEntries(
    slugs.map((slug) => {
      return [
        slug,
        localPlaceholders[slug].data.filter((datum) => {
          return !newPlaceholders.find(
            (d) => d.examination_fetus_id === datum.examination_fetus_id && d.source === datum.source && d.slug === slug
          );
        }),
      ];
    })
  );
  const updatedData = Object.fromEntries(
    slugs.map((slug) => {
      return [
        slug,
        newPlaceholders
          .filter((d) => d.slug === slug)
          .map((d) => {
            const oldDatum = localPlaceholders[slug].data.find(
              (datum) => d.examination_fetus_id === datum.examination_fetus_id && d.source === datum.source
            );
            return { ...(oldDatum ?? {}), ...d };
          }),
      ];
    })
  );
  const newLocalPlaceholders = Object.fromEntries(
    slugs.map((slug) => {
      return [
        slug,
        {
          ...localPlaceholders[slug],
          data: [...nonUpdatedData[slug], ...updatedData[slug]],
        },
      ];
    })
  );
  return { ...localPlaceholders, ...newLocalPlaceholders };
}

/*
 * @description Updates the local placeholders with the new placeholders
 * @example
 * const [add, remove, updatings] = useUpdateQueue();
 *
 * const update = add(["foo.bar", "foo.baz"]);
 * // do something
 * remove(update);
 */
export function useUpdateQueue() {
  const [inProgressUpdates, setInProgressUpdates] = useState([]);
  const addUpdate = useCallback(
    (slugs) => {
      const update = new Set(slugs);
      setInProgressUpdates((inProgressUpdates) => [...inProgressUpdates, update]);
      return update;
    },
    [setInProgressUpdates]
  );
  const removeUpdate = useCallback(
    (update) => {
      setInProgressUpdates((inProgressUpdates) => inProgressUpdates.filter((updates) => updates !== update));
    },
    [setInProgressUpdates]
  );
  const updatings = useMemo(() => {
    const allSlugs = inProgressUpdates.map((slugs) => Array.from(slugs)).flat();
    return new Set(allSlugs);
  }, [inProgressUpdates]);

  return [addUpdate, removeUpdate, updatings];
}
