import { isNullOrUndefined } from '../../utils';
import Icon from '../Icon/Icon';
import './Tabs.css';

/**
 * <Tab
 *  value
 *  options = [ {value, label, icon} ]
 *  fullwidth
 *  onChange
 * />
 */

const Tabs = ({ label = '', value, options = [], fullwidth = false, onChange = () => {} }) => {
  const onChangeHandler = (option) => {
    onChange(option.value);
  };

  return (
    <div className={`tabs-container ${fullwidth ? 'fullwidth' : ''}`}>
      <ul>
        {options.map((option) => (
          <li
            className={
              'option ' +
              (!isNullOrUndefined(value) && value.toString() === option?.value?.toString() ? 'selected' : '')
            }
            data-irrelevant={option.irrelevant}
            onClick={() => onChangeHandler(option)}
            key={option.value}
          >
            {!!option.icon && <Icon name={option.icon} />}
            {option.label}
            {!isNullOrUndefined(option.counter) && <span className="tabs-counter">{option.counter}</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
