import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { getNiceGestionalAgeFromDays, computeEstimatedDeliveryDate } from '../../../../../services/examination';
import InlineInput from '../../../../../atoms/InlineEditing/InlineInput/InlineInput';
import Icon from '../../../../../atoms/Icon/Icon';
import InlineDropdown from '../../../../../atoms/InlineEditing/InlineDropdown/InlineDropdown';
import NotVisibleOverlay from '../../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';

const NONE = 'none';

const GaItem = withTranslation()(({ t: __, data, gaItemProps, reportMode, examinationContext }) => {
  const {
    currentExamDate,
    methodsWithStandards,
    methodsDropdownOptions,
    placeholders,
    updateRow,
    fetusDropdownOptions,
    removeRow,
    setAssignedGa,
    updatingAssignedGa,
    setUpdatingAssignedGa,
    showFetusDropDown,
    reportData,
    splitSlug,
    makeSlug,
    defaultFetusFromSlug,
    appPreferences,
    print,
    currentLanguage,
  } = gaItemProps;

  const preferredDateFormat = appPreferences?.date_format;

  if (!data) return false;
  const { slug, fetus } = data;
  const { method, standard } = splitSlug(slug);

  const getDefaultLabel = () => {
    return method === NONE
      ? ''
      : __('report.gatable.defaultLabel', {
          method: reportData?.dating_methods?.[slug]?.label?.[currentLanguage] || slug,
          standard: reportData?.dating_standards?.[standard]?.[currentLanguage] || false,
        });
  };

  const getPlaceholderData = () => {
    const label = data.label || getDefaultLabel();
    const patientValue = reportData?.dating_methods?.[slug]?.patient_value;
    if (!placeholders?.[slug]?.[data.fetus ?? 0]) {
      return { ...data, label, patientValue };
    } else {
      return {
        ...placeholders[slug][data.fetus],
        ...data,
        label,
        patientValue,
      };
    }
  };

  const {
    examId = false,
    isAssigned = false,
    value = false,
    dateObtained = undefined,
    visible = false,
    rowId,
    label,
    patientValue,
  } = getPlaceholderData();

  if (print && !value) return false;

  const onChange = (valueToUpdate) => (value) => {
    updateRow({ [valueToUpdate]: value }, rowId);
  };

  const onChangeMethod = (newMethod) => {
    const newStandard = methodsWithStandards[newMethod][0].value;
    const newSlug = makeSlug(newMethod, newStandard);
    const newFetus = defaultFetusFromSlug(newSlug, fetus);

    updateRow({ slug: newSlug, label: false, fetus: newFetus }, rowId);
  };

  const onChangeStandard = (newStandard) => {
    const newSlug = makeSlug(method, newStandard);
    const newFetus = defaultFetusFromSlug(newSlug, fetus);
    updateRow({ slug: newSlug, fetus: newFetus, label: false }, rowId);
  };

  const [clickedAssignedGa, setClickedAssignedGa] = useState(false);

  const onClickAssigned = async () => {
    setClickedAssignedGa(true);
    setUpdatingAssignedGa(true);
    await setAssignedGa(slug, examId, fetus === 0 ? null : fetus, value);
    setClickedAssignedGa(false);
    setUpdatingAssignedGa(false);
  };

  const showAsAssigned = () => (isAssigned && !updatingAssignedGa) || clickedAssignedGa;

  return (
    <div className={`ga-item ${visible ? 'is-visible' : 'not-visible'} ${showFetusDropDown ? 'show-fetus' : ''}`}>
      {/* Fetus */}
      {showFetusDropDown && (
        <div>
          <span className="ga-item-fetus-number">
            <InlineDropdown
              value={fetus}
              options={patientValue ? fetusDropdownOptions.slice(0, 1) : fetusDropdownOptions.slice(1)}
              printable={true}
              onChange={onChange('fetus')}
            />
          </span>
        </div>
      )}

      {/* Custom Label */}
      <div>
        <InlineInput
          value={label}
          format={'string'}
          printable={visible}
          onChange={onChange('label')}
          appPreferences={appPreferences}
        />
      </div>

      {/* Scan Date */}
      <div className="ga-item-center">
        <InlineInput active={false} value={dateObtained} format="date" appPreferences={appPreferences} />
      </div>

      <>
        {/* Method Dropdown */}
        <div>
          {method === 'none' && (
            <div className="ga-item-hint-balloon not-printable">{__('report.gatable.selectMethod')}</div>
          )}
          <InlineDropdown
            value={method}
            options={methodsDropdownOptions}
            printable={visible}
            onChange={onChangeMethod}
          />
        </div>

        {/* Standard Dropdown  */}
        <div>
          {!print && (
            <InlineDropdown
              value={standard}
              options={methodsWithStandards[method]}
              printable={visible}
              onChange={onChangeStandard}
            />
          )}
        </div>
      </>

      {/* Current GA */}
      <div className="ga-item-center">
        {value
          ? getNiceGestionalAgeFromDays(__, Math.floor(value))
          : methodsWithStandards[method]
          ? ''
          : __('report.gatable.unavailable')}
      </div>

      {/* EDD */}
      <div className="ga-item-center">
        {computeEstimatedDeliveryDate(
          value,
          currentExamDate,
          preferredDateFormat,
          examinationContext?.examination.site.timezone,
          examinationContext?.pregnancyLengthInDays
        )}
      </div>

      {/* Assigned */}
      {!print && (
        <div className="ga-item-assign ga-item-center" onClick={onClickAssigned}>
          <input type="radio" readOnly checked={showAsAssigned()} />
        </div>
      )}
      {/* Editing options */}
      {visible && reportMode === 'edit' && (
        <div className="exam-report-editing-options">
          <div onClick={() => onChange('visible')(!visible)}>
            <Icon name={visible ? 'eye' : 'eye-off'} />
          </div>
          <div className="ga-item-remove" onClick={() => removeRow(rowId)}>
            <Icon name="trash" />
          </div>
        </div>
      )}
      {!visible && reportMode === 'edit' && <NotVisibleOverlay onClick={() => onChange('visible')(!visible)} />}
    </div>
  );
});

export default GaItem;
