import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonBack from '../../atoms/ButtonBack/ButtonBack';
import Button from '../../atoms/Button/Button';

const PreviewExamReportDialog = ({
  t: __,
  setPreviewExamReportDialogIsOpen,
  renderHTML,
  downloadReportAsPDF,
  downloadingPDF,
}) => {
  const onClickCancel = () => {
    setPreviewExamReportDialogIsOpen(false);
  };

  const [iframeContent, setIframeContent] = useState('<div class="loader-container is-loading"></div>');
  /* We only load the content once as it should not be updated here */
  useEffect(() => {
    renderHTML().then((iframeContent) => setIframeContent(iframeContent));
  }, []);

  return (
    <>
      <div className="modal-background" onClick={onClickCancel} />
      <div className="exam-report-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="exam-report-dialog-header">
          <ButtonBack onClick={onClickCancel} />
          <div className="exam-report-dialog-header_title">
            <h2>{__('examinationReview.dialog.reportPreview')}</h2>
          </div>
          <ButtonBack icon="close" onClick={onClickCancel} />
        </div>
        <div className="exam-report-dialog-body">
          <iframe className="exam-report-dialog-print-preview" srcDoc={iframeContent}></iframe>
        </div>
        <div className="exam-report-dialog-floating-bar">
          <div className="buttons">
            <Button
              onClick={downloadReportAsPDF}
              icon="download"
              label={
                downloadingPDF
                  ? __('examinationReview.dialog.generatingPdf')
                  : __('examinationReview.dialog.downloadPdf')
              }
              disabled={downloadingPDF}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(PreviewExamReportDialog);
