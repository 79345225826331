/* eslint-disable camelcase */
import axios from 'axios';
const controller = {};
const abortPreviousCall = (id) => {
  if (controller[id]) controller[id].abort();
  controller[id] = new AbortController();
};

const ResourceApi = {
  // AssocInstanceExam
  upsertAssocInstanceExam: (assoc_instance_exam) =>
    axios.put(`examination/${assoc_instance_exam.examination_id}/assoc-instance`, {
      assoc_instance_exam: { ...assoc_instance_exam },
    }),
  getAssocInstanceByExamId: (examination_id) => axios.get(`examination/${examination_id}/assoc-instance`),
  updateNextSlide: (examination_id, instance_view_id, idx_in_template, idx_in_group) =>
    axios.post(`examination/${examination_id}/next-slide`, {
      instance_view_id,
      idx_in_template,
      idx_in_group,
    }),
  update_printing_properties_association: (examination_id, dicom_instance_id, is_printable) =>
    axios.put(`examination/${examination_id}/print`, {
      dicom_instance_id,
      is_printable,
    }),
  update_sharing_properties_association: (examination_id, dicom_instance_id, is_shared) =>
    axios.put(`examination/${examination_id}/share`, {
      dicom_instance_id,
      is_shared,
    }),
  updateToRetake: (examination_id, dicom_instance_id, instance_view_id, to_retake) =>
    axios.put(`examination/${examination_id}/image-to-retake`, {
      dicom_instance_id,
      instance_view_id,
      to_retake,
    }),
  updateViewEvaluation: (examination_id, dicom_instance_id, instance_view_id, view_evaluation_override) =>
    axios.put(`examination/${examination_id}/view-evaluation`, {
      dicom_instance_id,
      instance_view_id,
      view_evaluation_override,
    }),
  updateQualityCriteria: (examination_id, dicom_instance_id, instance_view_id, quality_criteria_override) =>
    axios.put(`examination/${examination_id}/quality-criteria`, {
      dicom_instance_id,
      instance_view_id,
      quality_criteria_override,
    }),
  updateAnomaly: (examination_id, dicom_instance_id, instance_view_id, anomaly_override) =>
    axios.put(`examination/${examination_id}/anomaly`, {
      dicom_instance_id,
      instance_view_id,
      anomaly_override,
    }),
  // Examination
  getExamination: (id) => axios.get(`examination/${id}`),
  filterExaminations: (filters) => axios.get('examination', { params: filters }),
  getExaminationByPatient: (provided_patient_id) => {
    const patient_id = provided_patient_id || 'null';
    return axios.get('examination', { params: { patient_id } });
  },
  createExamination: (examination) => axios.post('examination', { examination }),
  createExaminationV2: (examination) => axios.post('examination-v2', { examination }),
  createExaminationEvent: (examination_id, type) => {
    // we send a beacon because it's kept alive even after window closing
    const blob = new Blob([JSON.stringify({ type })], {
      type: 'application/json; charset=UTF-8',
    });
    navigator.sendBeacon(`${axios.defaults.baseURL}/examination/${examination_id}/event`, blob);
  },
  updateExamination: (id, examination) => axios.put(`examination/${id}`, { examination }),
  updateExaminationMedicalHistory: (id, medical_history) =>
    axios.put(`examination/${id}/medical_history`, { medical_history }),
  syncExaminationMedicalHistory: (id) => axios.put(`examination/${id}/sync_medical_history`, {}),
  saveReport: (id, reportData) => axios.put(`examination/${id}/save_report`, reportData),
  writeDocumentHtml: (id, context, documentType, reportData) =>
    axios.put(`examination/${id}/write_document_html/${context}/${documentType}`, reportData),
  readDocumentHtml: (id, context, documentType) =>
    axios.get(`examination/${id}/read_document_html/${context}/${documentType}`),
  saveExaminationSharedParams: (id, params) => axios.put(`/examination/${id}/save_shared_params`, params),
  listExaminationData: (id, filters) => axios.get(`examination/${id}/data`, { params: filters }),
  updateExaminationData: (id, slug, examination_fetus_id, params) =>
    axios.put(`examination/${id}/data/${slug}/${examination_fetus_id}`, {
      params,
    }),
  upsertDefaultsExaminationData: (id, params) => axios.put(`examination/${id}/data/upsert_defaults`, { params }),
  getDefaultProceduresCodes: (id) => axios.get(`examination/${id}/default_procedures_codes`, {}),
  getExaminationShares: (examination_id) => axios.get(`/examination/${examination_id}/shares`),
  getExaminationMetadata: (examination_id) => axios.get(`examination/${examination_id}/metadata`),
  getExaminationFetusSexVisibility: (id) => axios.get(`examination/${id}/fetus_sex_visibility`),
  isExaminationMedicalHistorySynced: (examination_id) =>
    axios.get(`examination/${examination_id}/is_medical_history_synced`),
  getExaminationSemiology: (exam_item_id) => axios.get(`exam-semiology?exam_item_id=${exam_item_id}`),
  listPossibleEntityToExamination: (examination_id) => axios.get(`examination/${examination_id}/possible_stakeholders`),
  associateEntityToExamination: (examination_id, params) =>
    axios.put(`examination/${examination_id}/entity_assoc`, { params }),
  removeEntityFromExamination: (examination_id, params) =>
    axios.delete(`examination/${examination_id}/entity_assoc`, {
      data: { params },
    }),
  mergeDraftExamination: (examination_id, draft_examination_id) =>
    axios.get(`examination/${examination_id}/merge`, {
      params: { draft_examination_id },
    }),
  reassociateInstances: (examination_id, template_id) =>
    axios.post(`examination/${examination_id}/assoc-instance-with-automatch`, {
      template_id,
    }),
  printingTemplateViewIds: (examination_id) => axios.get(`examination/${examination_id}/printing-template-view-ids`),
  printingList: (examination_id) => axios.get(`examination/${examination_id}/printing-list`),
  sharingList: (examination_id) => axios.get(`examination/${examination_id}/sharing-list`),
  importSharingListFromPrinting: (examination_id) =>
    axios.get(`examination/${examination_id}/import-sharing-list-from-printing`),
  submitReport: (examination_id, params) => axios.post(`examination/${examination_id}/submit`, params),
  getFrozenDateTime: (examination_id) => axios.get(`examination/${examination_id}/frozen_datetime`),
  signReport: (examination_id, params) => axios.post(`examination/${examination_id}/sign`, params),
  getExamEvents: async (examination_id) => axios.get(`examination/${examination_id}/events`),
  updateChecklistItemStatus: (examination_id, checklist_item_ids, status, examination_fetus_id) => {
    abortPreviousCall('updateChecklistItemStatus');
    return axios.post('checklist-item/edit-status', {
      examination_id,
      checklist_item_id: Array.isArray(checklist_item_ids) ? checklist_item_ids : [checklist_item_ids],
      status,
      examination_fetus_id,
      signal: controller.updateChecklistItemStatus.signal,
    });
  },
  updateChecklistItemStatusV2: (examination_id, attrs) => {
    abortPreviousCall('updateChecklistItemStatus');
    return axios.post(`/examination/${examination_id}/checklist-item/edit-status`, {
      attrs,
      signal: controller.updateChecklistItemStatus.signal,
    });
  },
  getPlacentaPositions: () => axios.get('placenta-positions'),
  checkExaminationPermission: (examination_id, action) => axios.get(`examination/${examination_id}/action/${action}`),
  updateExaminationFetus: (id, fetus_id, fetus) => axios.put(`examination/${id}/fetus`, { fetus_id, fetus }),
  // Examination preset
  listExaminationPreset: () => axios.get(`examination-preset`),
  createExaminationPreset: (examination_preset) => axios.post('examination-preset', { examination_preset }),
  // Examination Template
  listExaminationTemplate: () => axios.get(`examination-template`),
  getExaminationTemplate: (id, source) => axios.get(`examination-template/${id}`, { cancelToken: source.token }),
  createExaminationTemplate: (examination_template) => axios.post('examination-template', { examination_template }),
  // Instance
  getDicomInstanceByExam: (examination_id) => axios.get('dicom-instance', { params: { examination_id } }),
  deleteDicomInstance: (dicomInstanceId) => axios.delete(`dicom-instance/${dicomInstanceId}`),
  updateDicomInstance: (dicomInstanceId, params) => axios.put(`dicom-instance/${dicomInstanceId}`, { params }),
  downloadDICOM: (dicomInstanceId) => {
    const url = `${axios.defaults.baseURL}/dicom-instance/${dicomInstanceId}/file`;
    const link = document.createElement('a');
    link.href = url;
    link.download = `dicom-${dicomInstanceId}.dcm`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
  // Episode
  listEpisodes: (patient_id) => axios.get(`patient/${patient_id}/episodes`),
  createEpisode: (patient_id, episode) => axios.post(`patient/${patient_id}/episode`, { episode }),
  updateEpisode: (patient_id, episode_id, episode) =>
    axios.put(`patient/${patient_id}/episode/${episode_id}`, { episode }),
  // Episode -> Fetus
  createFetus: (patient_id, episode_id, fetus) =>
    axios.post(`patient/${patient_id}/episode/${episode_id}/fetus`, { fetus }),
  updateFetus: (patient_id, episode_id, fetus_id, fetus) =>
    axios.put(`patient/${patient_id}/episode/${episode_id}/fetus`, {
      fetus_id,
      fetus,
    }),
  deleteFetus: (patient_id, episode_id, fetus_id) =>
    axios.delete(`patient/${patient_id}/episode/${episode_id}/fetus`, {
      data: { fetus_id },
    }),
  listFetuses: (patient_id, episode_id) => axios.get(`patient/${patient_id}/episode/${episode_id}/fetuses`),
  // Episode -> Attachment
  uploadEpisodeDocument: (patient_id, episode_id, formData) =>
    axios.post(`patient/${patient_id}/episode/${episode_id}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),

  // Sonio Listen // Ambient documentation
  getListenSupportedFields: () => axios.get(`listen/supported-fields`),
  openListenSession: (sessionId) => axios.post(`listen/live-transcript/session/${sessionId}`),
  pushListenAudioChunk: (sessionId, chunk) => axios.put(`listen/live-transcript/session/${sessionId}`, { chunk }),
  closeListenSession: (sessionId, chunk) => axios.delete(`listen/live-transcript/session/${sessionId}`, { chunk }),
  analyseTranscript: (params) => axios.post(`listen/analyse-transcript`, params),

  // Dating
  updateAssignedGa: async (exam_id, assigned_exam_id, assigned_method_id, assigned_fetus) =>
    await axios.post(`examination/${exam_id}/dating`, {
      assigned_exam_id,
      assigned_method_id,
      assigned_fetus,
    }),
  revertAssignedGaToPrevExam: async (exam_id) => await axios.post(`examination/${exam_id}/dating/revert`, {}),
  // Patient
  getPatients: (search_key = '', page = undefined, page_size = undefined) =>
    axios.get(`patient`, {
      params: {
        search_key,
        page: page,
        page_size: page_size,
      },
    }),
  getPatientsCount: (search_key = '') => axios.get(`patient/count`, { params: { search_key } }),
  getPatient: (id = null) => axios.get(`patient/${id || ''}`),
  filterPatient: (filters) => axios.get('patient', { params: filters }),
  createPatient: (patient) => axios.post('patient', { patient }),
  updatePatient: (id, patient) => axios.put(`patient/${id}`, { patient }),
  deletePatient: (id) => axios.delete(`/patient/${id}`),
  // Organization
  createOrganization: (organization) => axios.post('organization', { organization }),
  // Site
  getSite: () => axios.get('site'),
  getSiteLogo: (id) => `${axios.defaults.baseURL}/site/${id}/logo?${new Date().getTime()}`,
  getSiteHeader: (id) => `${axios.defaults.baseURL}/site/${id}/header?${new Date().getTime()}`,
  uploadBrandImage: (formData) =>
    axios.post('site', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteSiteLogo: (id) => axios.delete(`site/${id}/logo`),
  deleteSiteHeader: (id) => axios.delete(`site/${id}/header`),
  updateSite: (id, params) => axios.put(`site/${id}`, { params }),
  listSitesForGrant: (grantId) => axios.get('site', { params: { grant_id: grantId } }),

  // Uplink
  createUplink: (payload) => axios.post('/uplink', payload),
  listUplinks: () => axios.get('/uplink'),
  // Attachments
  getAttachments: () => axios.get('/attachments'),
  getAttachmentsByEpisode: (episode_id) => axios.get(`/attachments?episode_id=${episode_id}`),
  uploadAttachment: (formData) =>
    axios.post('/attachments', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  updateAttachment: (attachment_id, formData) =>
    axios.put(`/attachments/${attachment_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  deleteAttachment: (attachment_id) => axios.delete(`attachments/${attachment_id}`),
  downloadAttachment: (attachment_id) =>
    axios.get(`attachments/${attachment_id}/download`, {
      responseType: 'blob',
    }),
  getExamAssocAttachments: (exam_id) => axios.get(`examination/${exam_id}/assoc-attachment`),
  // getGeneralExamAssocAttachments: () => axios.get(`examination/assoc-attachment`),
  addSharingDocument: (params) =>
    axios.post(`examination/${params.examId}/assoc-attachment/`, {
      assoc_attachment_exam: {
        active: params.active,
        attachment_id: params.attachmentId,
        examination_id: params.examId,
        site_id: params.siteId,
      },
    }),
  updateStatusSharingDocument: (exam_id, documentId, status) =>
    axios.put(`examination/${exam_id}/assoc-attachment/${documentId}`, {
      assoc_attachment_exam: { active: status },
    }),
  uploadExamDocument: (examId, formData) =>
    axios.post(`examination/${examId}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  // PDF generator, returns a binary PDF
  getPdfFromHtml: (examination_id, html_content) =>
    axios.post(`examination/${examination_id}/pdf-report`, { html_content }, { responseType: 'blob' }),
  getReportPdf: (examination_id) =>
    axios.get(`/examination/${examination_id}/current_pdf_report`, {
      responseType: 'blob',
    }),
  emailPdfReport: (examination_id, html_content, emails) =>
    axios.post(`examination/${examination_id}/email-pdf-report`, {
      html_content,
      emails,
    }),
  // printing_template
  getPrintingTemplate: (printingTemplateId) => axios.get(`/printing-template/${printingTemplateId}`),
  updateProtocolPrintingTemplate: (protocolId, printingTemplateSetting) =>
    axios.put(`/printing-template/proto-setting/${protocolId}`, {
      printingTemplateSetting,
    }),
  shareExamination: (origin_location, examination_id, emails, annotate, documents) =>
    axios.post(`examination/${examination_id}/share`, {
      origin_location,
      emails,
      annotate,
      documents,
    }),
  deleteShareForExamination: (examination_id) => axios.post(`examination/${examination_id}/delete-share`),
  examViewVisited: (examination_id) => axios.put(`/examination/${examination_id}/exam-view-visited`),
  listPrintingProtocolSettings: () => axios.get(`/protocol-setting/list-printing`),
  createPrintingProtocolSetting: (protocolId, settingAttrs) =>
    axios.post(`/protocol-setting/create-printing`, {
      protocolId,
      settingAttrs,
    }),
  updatePrintingProtocolSetting: (id, settingAttrs) =>
    axios.put(`/protocol-setting/update-printing/${id}`, { settingAttrs }),
  listSharingProtocolSettings: () => axios.get(`/protocol-setting/list-sharing`),
  createSharingProtocolSetting: (protocolId, settingAttrs) =>
    axios.post(`/protocol-setting/create-sharing`, {
      protocolId,
      settingAttrs,
    }),
  updateSharingProtocolSetting: (id, settingAttrs) =>
    axios.put(`/protocol-setting/update-sharing/${id}`, { settingAttrs }),
  // Simulator only
  ingestDicomWithImage: (dicomData) => axios.post(`dicom-instance/metadata`, { ...dicomData }),
  // Video
  ingestDicomFromVideo: (frameData) =>
    axios.post(`dicom-instance/${frameData.videoDicomId}/extract`, {
      ...frameData,
    }),
  extractFramesFromVideo: (videoId) => axios.post(`dicom-instance/${videoId}/auto-extract`),
  processVideo: (videoId) => axios.post(`dicom-instance/${videoId}/video-process`),
  uploadDicomToUplink: (id, body) => axios.post(`/dicom-instance/${id}/uplink_upload`, body),

  worklist: () => axios.get('worklist'),
  appointment: () => axios.get('appointment'),
  updateAppointment: (id, params) => axios.put(`appointment/${id}`, { appointment: params }),
  refreshAppointmentFromWorklist: () => axios.post(`appointment/refresh_from_worklist`),
  syncWithEmr: () => axios.post(`appointment/sync-with-emr`),
  // Report
  getReport: (examination_id) => axios.get(`examination/${examination_id}/report`),
  getReportFrozen: (examination_id) => axios.get(`examination/${examination_id}/report_frozen`),
  getReportDynamic: (examination_id) => {
    abortPreviousCall('getReportDynamic');
    return axios.get(`examination/${examination_id}/report_dynamic`, {
      signal: controller.getReportDynamic.signal,
    });
  },
  getReportOptions: (examination_id) => {
    abortPreviousCall('getReportOptions');
    return axios.get(`examination/${examination_id}/report_options`, {
      signal: controller.getReportOptions.signal,
    });
  },
  getReportMeasurements: (examination_id) => axios.get(`examination/${examination_id}/report_measurements`),
  updateReport: (examination_id, changes) => axios.put(`examination/${examination_id}/report`, { changes }),
  updateMeasurements: (examination_id, changes) =>
    axios.post(`examination/${examination_id}/measurements`, { changes }),
  deleteMeasurement: (examination_id, measurement_id, fetus) =>
    axios.delete(`examination/${examination_id}/measurements/${fetus}/${encodeURIComponent(measurement_id)}`),
  getReportMeasurementLabels: (examination_id) => axios.get(`report/${examination_id}/measurement_labels`),
  searchCode: (examination_id, coding_system, question, context) => {
    abortPreviousCall('searchCode');
    return axios.get(`examination/${examination_id}/search_code_v2`, {
      params: { coding_system, question, context },
      signal: controller.searchCode.signal,
    });
  },
  suggestCode: (coding_system, indications, findings, additional_filters) =>
    axios.post(`connector/suggest_code`, {
      coding_system,
      indications,
      findings,
      additional_filters,
    }),
  searchContactPoint: (search_key) => axios.get(`contact-point`, { params: { search_key } }),
  associateContactPoint: (examination_id, params) =>
    axios.post(`examination/${examination_id}/assoc-examination-contact-point`, {
      assoc_examination_contact_point: { ...params, examination_id },
    }),
  updateContactPoint: (examination_id, association_cp_id, params) =>
    axios.put(`examination/${examination_id}/assoc-examination-contact-point/${association_cp_id}`, {
      assoc_examination_contact_point: { ...params },
    }),
  deassociateContactPoint: (examination_id, association_cp_id) =>
    axios.delete(`examination/${examination_id}/assoc-examination-contact-point/${association_cp_id}`),
  getDynamicDropdownFullTree: (examination_id, dropdown_id) =>
    axios.get(`examination/${examination_id}/report_dropdown/${dropdown_id}`),
  getDynamicDropdownOption: (examination_id, dropdown_id, option_slug) =>
    axios.get(`examination/${examination_id}/report_dropdown/${dropdown_id}/option/${option_slug}`),
  // Automation template
  getAutomationTemplate: (preset_id, slug) => axios.get(`examination-preset/${preset_id}/automation-template/${slug}`),
  createAutomationTemplate: (preset_id, attrs) =>
    axios.post(`examination-preset/${preset_id}/automation-template`, attrs),
  editAutomationTemplate: (preset_id, template_slug, changes) =>
    axios.put(`examination-preset/${preset_id}/automation-template/${template_slug}`, changes),
  deleteAutomationTemplate: (preset_id, template_slug) =>
    axios.delete(`examination-preset/${preset_id}/automation-template/${template_slug}`),
  // BI Events
  createBIEvent: (event_data) => {
    event_data = { ...event_data, user_agent: navigator.userAgent };
    axios.post(`bi-event`, { event_data });
  },
  uploadExamFile: (examination_id, form_data) =>
    axios.post(`/examination/${examination_id}/manual-upload`, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
};

export default ResourceApi;
