import React, { createContext, useContext, useState } from 'react';
import SimplePopup from '../components/Popup/SimplePopup';

const ReportDialogContext = createContext();

export const ReportDialogProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <ReportDialogContext.Provider value={{ openModal, closeModal }}>
      {children}
      {isModalOpen && <SimplePopup>{modalContent}</SimplePopup>}
    </ReportDialogContext.Provider>
  );
};

export const useReportDialog = () => useContext(ReportDialogContext);
