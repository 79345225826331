import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { ExaminationContext } from '../../context-providers/Examination';
import ResourceApi from '../../services/resource';
import PatientsView from './PatientsView';
import { LiveExaminationContext } from '../../context-providers/LiveExamination';
import PaginationControls from './PaginationControls';
import './index.css';

const Patients = ({ t: __ }) => {
  const pageSize = 60;

  const liveExaminationContext = useContext(LiveExaminationContext);
  const examinationContext = useContext(ExaminationContext);

  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getPatients();
    getTotal();
  }, [liveExaminationContext.incomingDicomInstance, searchTerm]);

  useEffect(() => {
    getPatients();
  }, [page]);

  const getPatients = (newPage) => {
    ResourceApi.getPatients(searchTerm, newPage || page, pageSize).then((resp) => setPatients(resp.data.data));
  };
  const getTotal = () => {
    ResourceApi.getPatientsCount(searchTerm).then((resp) => {
      const num = resp.data;
      if (Number.isInteger(num)) {
        setTotal(Math.max(num, 0));
      }
    });
  };

  const onSearchChange = (keyword) => {
    setPage(1);
    setSearchTerm(keyword);
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="patients-page-container">
      <PatientsView
        patientsList={patients}
        search={onSearchChange}
        createDraftExam={examinationContext.createDraftExam}
        draftExams={examinationContext.draftExams}
      />
      <PaginationControls page={page} pageSize={pageSize} total={total} onPageChange={onPageChange} />
    </div>
  );
};

export default withTranslation()(Patients);
