import i18next from 'i18next';
import React, { createContext, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonGroup from '../atoms/ButtonGroup/ButtonGroup';
import { getCurrentLanguageCode } from '../utils';
import './Unauthenticated.css';

const UnauthenticatedContext = createContext({});

function UnauthenticatedProvider({ t: __, i18n, children, withLanguageSelector = true }) {
  const currentLanguage = getCurrentLanguageCode();

  const context = {
    lang: currentLanguage,
  };

  return (
    <div data-theme="dark" className={`theme-dark`}>
      {withLanguageSelector && (
        <div className={`unauthenticated-language`}>
          <ButtonGroup
            value={i18n.language}
            options={[
              { value: 'en', label: 'EN' },
              { value: 'fr', label: 'FR' },
              { value: 'de', label: 'DE' },
              { value: 'ptbr', label: 'PTBR' },
            ]}
            size="compact"
            onChange={(lang) => i18next.changeLanguage(lang)}
          />
        </div>
      )}
      <UnauthenticatedContext.Provider value={context}>{children}</UnauthenticatedContext.Provider>
    </div>
  );
}

export function useUnauth() {
  return useContext(UnauthenticatedContext);
}

export default withTranslation()(UnauthenticatedProvider);
