import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import TextInput from '../../atoms/TextInput/TextInput';
import LookupApi from '../../services/lookup';
import { getRegExpValue } from '../../utils';
import './MedicationsSearchBar.css';
import Icon from '../../atoms/Icon/Icon';

/**
 * <MedicationsSearchBar
 *  value = array of selected medications
 *  onChange
 * />
 */

const MedicationsSearchBar = ({ t: __, i18n, value, showSelected = true, onChange = () => {} }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [medications, setMedications] = useState([]);

  if (!value) value = [];

  useEffect(() => {
    if (searchValue.length >= 3) {
      LookupApi.getMedicationsBySearchKey(searchValue).then((results) =>
        setSearchResults(results.data.filter((medication) => !medications.find((m) => m.id === medication.id)))
      );
    }
  }, [searchValue]);

  /**
   * load medications from APIs when they are not loaded yet
   */
  useEffect(() => {
    const missingMedications = value.reduce(
      (ids, id) => (!medications.find((m) => m.id === id) ? [...ids, id] : ids),
      []
    );
    if (!missingMedications.length) return false;
    LookupApi.getMedicationsByIds(missingMedications).then((results) =>
      setMedications([...medications, ...results.data])
    );
    return true;
  }, [value]);

  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if (!string) return { __html: '' };
    const expression = getRegExpValue(searchValue);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  };

  /**
   * add a medication to the list of currently selected medications
   */
  const addMedication = (medication) => {
    if (medications.find((m) => m.id === medication.id)) return;
    const newMedications = [...medications, medication];
    setMedications(newMedications);
    setSearchValue('');
    setSearchResults([]);
    onChange(
      newMedications.map((medication) => {
        return { id: medication.id, risk_factor_ids: medication.risk_factor_ids };
      }),
      newMedications.map((medication) => medication.id)
    );
  };

  /**
   * remove a medication from the list of currently selected medications
   */
  const removeMedication = (medicationId) => {
    const newMedications = medications.filter((m) => m.id !== medicationId);
    setMedications(newMedications);
    onChange(
      newMedications.map((medication) => {
        return { id: medication.id, risk_factor_ids: medication.risk_factor_ids };
      }),
      newMedications.map((medication) => medication.id)
    );
  };

  const SearchResultItem = ({ result }) => {
    // Agreed with Victoire to have English as a base, joined with the local language 'medications', removing duplicates
    const englishMedications = result.medications.en ?? [];
    const localMedications = result.medications[currentLanguage] ?? [];
    const medications = [...new Set([...localMedications, ...englishMedications])];

    return (
      <li key={result.id} onClick={() => addMedication(result)}>
        <div className="medication-name">
          <span dangerouslySetInnerHTML={highlight(result.name[currentLanguage])}></span>
          {result?.risk_factor_ids?.length > 0 && (
            <span className="medication-is-teratogenic">
              <Icon name="bell" /> {__('riskSearchBar.isTeratogenic')}
            </span>
          )}
        </div>
        {medications.length > 0 && (
          <div className="medication-medications">
            <ul>
              {medications.map((medication) => (
                <li key={medication} dangerouslySetInnerHTML={highlight(medication)}></li>
              ))}
            </ul>
          </div>
        )}
      </li>
    );
  };
  return (
    <div className="medications-search-bar-container">
      <div className="medications-search-bar">
        <TextInput
          placeholder={__('riskSearchBar.startTyping')}
          value={searchValue}
          icon="search"
          fullwidth
          onChange={(value) => setSearchValue(value)}
        />
        {searchValue.length > 0 && searchValue.length < 3 && <small>{__('riskSearchBar.min3letters')}</small>}
        {searchValue?.length >= 3 && searchResults?.length > 0 && (
          <div className="medications-search-bar-autocomplete">
            <ul>
              {searchResults.map((result) => (
                <SearchResultItem key={result.id} result={result} />
              ))}
            </ul>
          </div>
        )}
      </div>
      {showSelected && (
        <div className="medications-search-bar-list">
          <ul>
            {medications.map((medication) => (
              <li key={medication.id}>
                <div className="medication-remove" onClick={() => removeMedication(medication.id)}>
                  <Icon name="trash" />
                </div>
                <div className="medication-name">
                  <span>{medication.name[currentLanguage]}</span>
                  {!!medication.risk_factor_ids.length && (
                    <span className="medication-is-teratogenic">
                      <Icon name="bell" /> {__('riskSearchBar.isTeratogenic')}
                    </span>
                  )}
                </div>
                {!!medication.medications[currentLanguage].length && (
                  <div className="medication-medications">
                    <ul>
                      {medication.medications[currentLanguage].map((medication) => (
                        <li key={medication}>{medication}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {!!medication.description[currentLanguage] && (
                  <div
                    className={`medication-description ${
                      medication.description[currentLanguage].length > 200 ? 'long-text' : 'short-text'
                    }`}
                  >
                    {medication.description[currentLanguage]}
                  </div>
                )}
                {!!medication.malformations && (
                  <div className="medication-malformations">
                    <ul>
                      {medication.malformations.map((malformation) => (
                        <li key={malformation.id}>{malformation.canonical_name[currentLanguage]}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(MedicationsSearchBar);

MedicationsSearchBar.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
