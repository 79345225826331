import config from './config';

let longClickTimeout = false;
let clickTimeout = false;
let longClickEventType = '';
let isDragging = false;
let isDropping = false;
let dragDirection = '';
let draggedElement = false;
let hoveredElement = false;
let dragStartingPoint = { x: 0, y: 0 };
let dragPlaceholder = false;
let dragScrollOffset = 0;
let dragContainer = false;
let dragStarted = false;
let getDragPlaceholder = () => {};
let dropTargetSelector = '';
let onDragStart = (e) => {};
let onDragHover = (e) => {};
let onDrop = (e) => {};
let onClick = (e) => {};
let onDblClick = (e) => {};

/* Disable context menu on touch devices avoiding interferences with the long tap */
document.addEventListener('DOMContentLoaded', () => {
  if (navigator.maxTouchPoints > 0) document.addEventListener('contextmenu', preventDefault);
  else document.removeEventListener('contextmenu', preventDefault);
});

const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

export const setDragAndDropOptions = (options) => {
  dragDirection = options?.direction;
  dragContainer = options?.container;
  getDragPlaceholder = options?.createGhost;
  dropTargetSelector = options?.targetSelector;
  onDragHover = options?.onDragHover;
  onDragStart = options?.onDragStart;
  onClick = options?.onClick;
  onDblClick = options?.onDblClick;
  onDrop = options?.onDrop;
};

export const handleMouseDown = (e) => {
  e.preventDefault();
  e.stopPropagation();

  if (!!longClickEventType && e.type !== longClickEventType) return false;
  longClickEventType = e.type;

  if (longClickTimeout) {
    clearTimeout(longClickTimeout);
  }

  draggedElement = null;
  hoveredElement = null;
  const event = e.targetTouches?.[0] || e;
  dragStartingPoint = { x: event.clientX, y: event.clientY };

  draggedElement = e.currentTarget.closest(dropTargetSelector);
  if (e.currentTarget?.classList?.contains('is-draggable')) {
    if (longClickEventType.includes('mouse')) {
      handleStartOnLongClick(e);
    } else {
      longClickTimeout = setTimeout(() => handleStartOnLongClick(e), config.longClickDuration);
    }
  }

  if (longClickEventType.includes('mouse')) {
    document.body.addEventListener('mousemove', handleMouseMove);
    document.body.addEventListener('mouseup', handleMouseUp);
  } else {
    document.body.addEventListener('touchmove', handleMouseMove);
    document.body.addEventListener('touchend', handleMouseUp);
  }
};

const handleStartOnLongClick = (e) => {
  if (draggedElement) {
    const event = e.targetTouches?.[0] || e;
    isDragging = true;
    handleAutoScroll();
    dragContainer.style.overflow = 'hidden';

    dragPlaceholder = getDragPlaceholder(draggedElement);
    if (dragPlaceholder) {
      dragContainer.style.position = 'relative';
      const draggedBoundaries = draggedElement.getBoundingClientRect();
      const containerBoundaries = dragContainer.getBoundingClientRect();
      dragPlaceholder.classList.add('drag-placeholder');
      dragPlaceholder.classList.toggle('drag-hidden', longClickEventType.includes('mouse'));
      dragPlaceholder.style.zIndex = 10000;
      dragPlaceholder.style.top = event.clientY - containerBoundaries.y + 'px';
      dragPlaceholder.style.left = event.clientX - containerBoundaries.x + 'px';
      dragPlaceholder.style.width = draggedBoundaries.width + 'px';
      dragPlaceholder.style.height = draggedBoundaries.height + 'px';
      dragPlaceholder.style.transform = `translate(-${event.clientX - draggedBoundaries.left}px, -${
        event.clientY - draggedBoundaries.top
      }px)`;
      dragContainer?.appendChild(dragPlaceholder);
    }
  }
};

const isDraggingOutOfSafeArea = (mouseX, mouseY) => {
  return (
    !longClickEventType.includes('mouse') ||
    dragStarted ||
    (!dragStarted &&
      (Math.abs(dragStartingPoint.x - mouseX) > config.startDragSensitivity ||
        Math.abs(dragStartingPoint.y - mouseY) > config.startDragSensitivity))
  );
};

const handleMouseMove = (e) => {
  const event = e.targetTouches?.[0] || e;
  e.stopPropagation();
  e.preventDefault();
  if (isDropping) return false;

  if (!isDraggingOutOfSafeArea(event.clientX, event.clientY)) {
    draggedElement?.classList?.toggle('dragging', false);
    dragPlaceholder?.classList?.toggle('drag-hidden', true);
    dragContainer?.classList.toggle('is-dragging', false);
    dragContainer.style.removeProperty('overflow');
    return false;
  }

  if (!isDragging) {
    /* the pointer moved while waiting for long press: scrolling on tablet */
    if (longClickTimeout) clearTimeout(longClickTimeout);
    return false;
  }

  draggedElement?.classList?.toggle('dragging', true);
  dragPlaceholder?.classList?.toggle('drag-hidden', false);
  dragContainer?.classList.toggle('is-dragging', true);

  const speed = 2;
  // TODO: set scroll related to container instead of body
  const offset = (10 / (document.body.clientHeight / 2)) * (event.clientY - document.body.clientHeight / 2);
  if (offset > 0) dragScrollOffset = Math.max(0, offset - 3) * speed;
  else dragScrollOffset = Math.min(0, offset + 3) * speed;

  const newHoveredElement = dropTargetSelector
    ? document.elementFromPoint(event.clientX, event.clientY).closest(dropTargetSelector)
    : false;
  if (newHoveredElement) {
    if (newHoveredElement !== hoveredElement) {
      newHoveredElement?.classList.add('drag-overing');
      hoveredElement?.classList.remove('drag-overing');
    }
    hoveredElement = newHoveredElement;
    if (onDragHover instanceof Function) {
      const hoveredBoundaries = hoveredElement.getBoundingClientRect();
      const mouseOffset = {
        x: (100 / (hoveredBoundaries.bottom - hoveredBoundaries.top)) * (event.clientY - hoveredBoundaries.top),
        y: (100 / (hoveredBoundaries.right - hoveredBoundaries.left)) * (event.clientX - hoveredBoundaries.left),
      };
      onDragHover(e, { hoveredElement, draggedElement, dragContainer, mouseOffset });
    }
  }

  if (!dragStarted) {
    if (onDragStart instanceof Function) onDragStart(e, { hoveredElement, draggedElement, dragContainer });
    dragStarted = true;
  }

  if (dragPlaceholder) {
    const containerBoundaries = dragContainer.getBoundingClientRect();
    if (['', 'vertical'].includes(dragDirection))
      dragPlaceholder.style.top = event.clientY - containerBoundaries.y + dragContainer.scrollTop + 'px';
    if (['', 'horizontal'].includes(dragDirection))
      dragPlaceholder.style.left = event.clientX - containerBoundaries.x + dragContainer.scrollLeft + 'px';
  }
};

const handleMouseUp = async (e) => {
  e.stopPropagation();
  const event = e.targetTouches?.[0] || e;
  dragContainer?.style.removeProperty('overflow');

  if (longClickTimeout) {
    clearTimeout(longClickTimeout);
    longClickTimeout = false;
  }

  if (!isDraggingOutOfSafeArea(event.clientX, event.clientY)) {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = false;
      if (onDblClick instanceof Function) {
        onDblClick(e, { hoveredElement, draggedElement, dragContainer });
      } else if (onClick instanceof Function) {
        onClick(e, { hoveredElement, draggedElement, dragContainer });
      }
    } else {
      if (onClick instanceof Function) {
        const tmpHoveredElement = hoveredElement;
        const tmpDraggedElement = draggedElement;
        const tmpDragContainer = dragContainer;
        clickTimeout = setTimeout(() => {
          onClick(e, {
            hoveredElement: tmpHoveredElement,
            draggedElement: tmpDraggedElement,
            dragContainer: tmpDragContainer,
          });
          clickTimeout = false;
        }, config.doubleClickDuration);
      } else {
        e.target?.click();
        e.target?.focus();
      }
    }
  }

  // document.removeEventListener('contextmenu', preventDefault);
  document.body.removeEventListener('mousemove', handleMouseMove);
  document.body.removeEventListener('touchmove', handleMouseMove);
  document.body.removeEventListener('mouseup', handleMouseUp);
  document.body.removeEventListener('touchend', handleMouseUp);
  dragPlaceholder?.parentNode?.removeChild(dragPlaceholder);

  isDropping = true;
  if (dragStarted && isDraggingOutOfSafeArea(event.clientX, event.clientY)) {
    if (onDrop instanceof Function) await onDrop(e, { hoveredElement, draggedElement, dragContainer });
  }
  isDropping = false;

  dragStarted = false;
  isDragging = false;
  draggedElement = false;
  hoveredElement = false;
  longClickEventType = '';

  setTimeout(() => {
    for (const Element of dragContainer?.querySelectorAll('.dragging, .drag-overing') ?? []) {
      Element.classList?.remove('dragging');
      Element.classList?.remove('drag-hidden');
      Element.classList?.remove('drag-overing');
    }
    dragContainer?.classList.remove('is-dragging');
  }, 300);
};

const handleAutoScroll = () => {
  dragContainer?.scrollTo({ top: dragContainer?.scrollTop + dragScrollOffset });
  if (isDragging) requestAnimationFrame(handleAutoScroll);
};
