import axios from 'axios';
import { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import DxAi from '../../services/dx-ai';
import { getMedicalHistory, getRiskFactors, getTeratogenicRisks } from '../../services/examination';
import LookupApi from '../../services/lookup';
import ResourceApi from '../../services/resource';
import { convertCmToInch, convertKgToLbs, isNullOrUndefined } from '../../utils';
import ExaminationReviewDxView from '../DxChecklist/Report/ExaminationReviewDxView';
import ExaminationReviewView from './ExaminationReviewView';
import './index.css';

let loadDetailsTimeout = null;

const ExaminationReview = ({
  t: __,
  slides = null,
  instanceViews = null,
  medias = null,
  anomalies = null,
  medicalHistory = null,
  riskFactors = null,
  teratogenicRisks = null,
  medications = null,
  slidesRiskFactors,
  checklistItems = null,
  checklistItemsRiskFactors,
  assocInstanceExam = null,
  setIsPrintPreviewOpen = () => {},
  setIsSharingPreviewOpen = () => {},
}) => {
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);

  const { examId } = useParams();
  const [showTab, setShowTab] = useState('screening');
  const [loadedSlides, setLoadedSlides] = useState(slides);
  const [loadedInstanceViews, setLoadedInstanceViews] = useState(instanceViews);
  const [loadedMedias, setLoadedMedias] = useState(medias);
  const [loadedChecklistItems, setLoadedChecklistItems] = useState(checklistItems);
  const [loadedAnomalies, setLoadedAnomalies] = useState(anomalies);
  const [loadedMedicalHistory, setLoadedMedicalHistory] = useState(medicalHistory || {});
  const [loadedRiskFactors, setLoadedRiskFactors] = useState(riskFactors || []);
  const [loadedTeratogenicRisks, setLoadedTeratogenicRisks] = useState(teratogenicRisks || []);
  const [loadedMedications, setLoadedMedications] = useState(medications || []);
  const preferedUnit = appContext.preferences.units;
  const trimester = examinationContext.examination.trimester;

  /* Sonio Diagnostics */
  const [syndromes, setSyndromes] = useState([]);
  const [syndromeDetails, setSyndromeDetails] = useState([]);
  const [syndromeZero, setSyndromeZero] = useState([]);
  const allMedications = [];

  const expectedMedias = useMemo(
    () =>
      loadedInstanceViews
        ?.filter((instance) => instance.type === 'picture')
        .reduce((sum, instance) => sum + Number(instance.medias[trimester]), 0),
    [loadedInstanceViews]
  );
  const CNEOFslides = useMemo(
    () =>
      loadedInstanceViews
        ?.filter((instance) => instance.type === 'picture' && appContext.isSlideInDefaultCategory(instance, trimester))
        .reduce((sum, instance) => {
          // multi-instance support
          if (!sum[instance.id]) sum[instance.id] = { ...instance, medias: 0 };
          sum[instance.id].medias += Number(instance.medias[trimester]);
          return sum;
        }, []),
    [loadedInstanceViews]
  );

  const expectedCNEOFMedias = useMemo(
    () => CNEOFslides?.reduce((sum, instance) => sum + Number(instance.medias), 0),
    [CNEOFslides]
  );
  const takenCNEOFMedias = useMemo(
    () =>
      CNEOFslides?.reduce(
        (sum, instance) =>
          sum +
          Math.min(Number(instance.medias), loadedMedias?.filter((media) => media.slideId === instance.id).length),
        0
      ),
    [CNEOFslides, loadedMedias]
  );

  useEffect(() => {
    setShowTab(examinationContext.examination.type);
  }, [examinationContext.examination.type]);

  /**
   * load the exam data from scratch in order to be able to load this page indipendently
   * (to maintain the retro-compatibility with the DX architecture, accessing the report page from /exam-review/:id)
   */
  useEffect(() => {
    if (!!examId && Number(examId) !== examinationContext.examination.id) {
      examinationContext.loadExamination(examId);
    }
  }, [examId]);

  useEffect(() => {
    setLoadedMedias(examinationContext.instances.sort((a, b) => a.id - b.id));
  }, [examinationContext.instances]);

  useEffect(() => {
    const f = async () => {
      if (!!loadedInstanceViews && slides === null) {
        const {
          data: { data: associations },
        } = await ResourceApi.getAssocInstanceByExamId(examId);
        const associationsMap = {};
        for (let i = 0; i < associations.length; i++) {
          // currently one instance can belong to only one plane
          if (!isNullOrUndefined(associations[i].instance_view_id))
            associationsMap[associations[i].dicom_instance_id] = {
              slideId: associations[i].instance_view_id,
              selectedForPrinting: associations[i].selected_for_print,
            };
        }

        setLoadedSlides(
          loadedInstanceViews.reduce((slides, slide) => {
            if (slides[slide.id]) return slides;
            const medias = {};
            for (const trimester of Object.keys(slide.medias)) {
              medias[trimester] = loadedInstanceViews.reduce(
                (sum, instance) => (instance.id === slide.id ? sum + instance.medias[trimester] : sum),
                0
              );
            }

            slides[slide.id] = {
              ...slide,
              mediaIds: Object.entries(associationsMap).reduce(
                (mediaIds, [instanceId, { slideId }]) =>
                  slideId === slide.id ? [...mediaIds, Number(instanceId)] : mediaIds,
                []
              ),
              medias,
            };
            return slides;
          }, [])
        );
      } else {
        setLoadedSlides(slides || []);
      }
    };
    f();
  }, [slides, loadedInstanceViews]);

  useEffect(() => {
    /* TODO: Deprecated on axios >= 0.22.0 , replaced by AbortController */
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const getInstanceViews = async () => {
      if (!!examinationContext.examination.id && instanceViews === null) {
        const instanceViews = await examinationContext.getInstanceViewsForTemplate(source).catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
          } else {
            throw thrown;
          }
        });
        setLoadedInstanceViews(instanceViews);
      } else {
        if (instanceViews !== null) setLoadedInstanceViews(instanceViews);
      }
    };
    getInstanceViews().catch(() => {});

    return () => {
      source.cancel('Operation canceled. UseEffect cleanup');
    };
  }, [instanceViews, examinationContext.examination.id]);

  useEffect(() => {
    if (checklistItems === null) {
      LookupApi.getExamItem().then((resp) => {
        setLoadedChecklistItems(resp.data.data);
      });
    } else {
      setLoadedChecklistItems(checklistItems);
    }
  }, [checklistItems]);

  useEffect(() => {
    if (anomalies === null) {
      setLoadedAnomalies(
        examinationContext.examination?.exam_items?.reduce(
          (ids, item) => (item.status === 'unusual' ? [...ids, item.id] : ids),
          []
        ) || []
      );
    } else {
      setLoadedAnomalies(anomalies);
    }
  }, [anomalies, JSON.stringify(examinationContext.examination?.exam_items)]);

  useEffect(() => {
    if (medicalHistory === null)
      setLoadedMedicalHistory(
        getMedicalHistory(
          examinationContext.examination.medical_history,
          examinationContext.medicalHistoryItems,
          loadedMedications || [],
          __,
          preferedUnit,
          convertKgToLbs,
          convertCmToInch
        )
      );
    else setLoadedMedicalHistory(medicalHistory);

    if (riskFactors === null)
      setLoadedRiskFactors(
        getRiskFactors(examinationContext.examination.medical_history, examinationContext.medicalHistoryItems)
      );
    else setLoadedRiskFactors(riskFactors);

    if (teratogenicRisks === null)
      setLoadedTeratogenicRisks(getTeratogenicRisks(examinationContext.examination.medical_history, medications));
    else setLoadedTeratogenicRisks(teratogenicRisks);
  }, [
    loadedMedications,
    JSON.stringify(examinationContext.examination.medical_history),
    JSON.stringify(examinationContext.medicalHistoryItems),
  ]);

  useEffect(() => {
    if (medications === null) {
      if (!examinationContext.examination?.medical_history?.['teratogenicrisks.medications']) {
        setLoadedMedications([]);
        return false;
      }

      const value = examinationContext.examination.medical_history['teratogenicrisks.medications'].value;
      const missingMedications = value.reduce(
        (ids, id) => (!loadedMedications?.find((m) => m.id === id) ? [...ids, id] : ids),
        []
      );
      if (!missingMedications.length) return false;
      LookupApi.getMedicationsByIds(missingMedications).then((results) =>
        setLoadedMedications((medications) => [...medications, ...results.data])
      );
    } else {
      setLoadedMedications(medications);
    }
  }, [medications, JSON.stringify(examinationContext.examination?.medical_history?.['teratogenicrisks.medications'])]);

  useEffect(() => {
    if (assocInstanceExam === null) {
      if (examinationContext.examination.id) {
        const newAssocInstanceExam = {};
        ResourceApi.getAssocInstanceByExamId(examinationContext.examination.id).then((resp) => {
          resp.data.data.forEach((assoc) => {
            newAssocInstanceExam[assoc.dicom_instance_id] = assoc;
          });
        });
      }
    }
  }, [assocInstanceExam, examinationContext.examination.id]);

  /**
   * Syndromes
   */
  useEffect(() => {
    if (!examinationContext.examination?.recommendation?.syndromes) return false;
    if (!Object.keys(examinationContext.SYNDROMES).length) return false;

    const newSyndromes =
      examinationContext.examination?.recommendation?.syndromes
        ?.sort((a, b) => b.probability - a.probability)
        .filter(
          (syndrome) =>
            !examinationContext.examination?.recommendation?.user_overrides?.some(
              (rule) => rule.type === 'syndrome' && rule.id === syndrome.id && rule.action === 'remove'
            )
        ) || [];

    const newSyndromesIncludingPinned = [
      ...newSyndromes.map((syndrome) =>
        syndrome.id > 0 ? { ...syndrome, ...examinationContext.getSyndromeById(syndrome.id) } : syndrome
      ),
      ...(
        examinationContext.examination?.recommendation?.user_overrides?.reduce((syndromes, rule) => {
          return rule.type === 'syndrome' &&
            rule.action === 'add' &&
            !newSyndromes.some((syndrome) => rule.id === syndrome.id)
            ? [...syndromes, rule.id]
            : syndromes;
        }, []) || []
      ).map((syndromeId) => ({
        ...examinationContext.getSyndromeById(syndromeId),
        id: syndromeId,
        pinned: true,
        probability: 0,
      })),
    ];

    setSyndromes(newSyndromesIncludingPinned);
  }, [examinationContext.examination?.recommendation?.syndromes, examinationContext.SYNDROMES]);

  const loadDetails = () => {
    if (!syndromes || !Object.keys(examinationContext.MALFORMATIONS).length) return false;

    const syndromeIds = syndromes.map((syndrome) => syndrome.id).filter((id) => !!id);
    const syndromeZero = syndromes.find((syndrome) => syndrome.id === 0);

    if (syndromeIds.length) {
      DxAi.syndromeDetails(
        syndromeIds,
        examinationContext.examination?.medical_history?.['medicalexam.fetus.sex']?.value || 'unknown',
        examinationContext.examination.trimester,
        examinationContext.examination.malformations
      ).then((resp) => {
        const newSyndromeDetails = [];
        let medicationIds = [];

        for (const currentSyndrome of resp.data.data) {
          const syndromeMalformations = currentSyndrome.malformations?.typical_malformations.map((malformation) => ({
            ...examinationContext.MALFORMATIONS[malformation.id],
            status:
              examinationContext.examination.malformations?.find((m) => m.id === malformation.id)?.status ||
              (examinationContext.examination.zones?.some(
                (zone) =>
                  zone.status === 'validated' &&
                  zone.id === examinationContext.MALFORMATIONS[malformation.id]?.exam_zone_id
              )
                ? 'no'
                : 'unknown'),
          }));

          const syndromeAtypicalMalformations = currentSyndrome.malformations?.atypical_yes_malformations.map(
            (malformation) => ({
              ...examinationContext.MALFORMATIONS[malformation.id],
              status:
                examinationContext.examination.malformations?.find((m) => m.id === malformation.id)?.status ||
                (examinationContext.examination.zones?.some(
                  (zone) =>
                    zone.status === 'validated' &&
                    zone.id === examinationContext.MALFORMATIONS[malformation.id]?.exam_zone_id
                )
                  ? 'no'
                  : 'unknown'),
            })
          );

          const riskFactors = (
            currentSyndrome.risk_factors?.map((risk) => ({
              ...Object.values(examinationContext.medicalHistoryItems).find((mh) =>
                mh.options?.some((option) => option.risk_factor_id === risk.id)
              ),
              risk_factor_id: risk.id,
            })) || []
          )
            .filter((risk) => !!risk?.options?.length)
            .map((risk) => ({
              ...risk,
              options: risk.options.filter((option) => option.risk_factor_id === risk.risk_factor_id),
              medicalHistoryItem: examinationContext.examination.medical_history?.[
                risk.text_id
              ]?.risk_factors?.includes(risk.risk_factor_id)
                ? {
                    ...examinationContext.examination.medical_history[risk.text_id],
                  }
                : false,
            }));

          medicationIds = [
            ...medicationIds,
            ...currentSyndrome.risk_factors
              ?.filter((medication) => medication.type === 'drug' && !medicationIds.includes(medication.id))
              .map((medication) => medication.id),
          ];

          newSyndromeDetails.push({
            ...currentSyndrome,
            malformations: syndromeMalformations,
            atypicalMalformations: syndromeAtypicalMalformations,
            riskFactors,
          });
        }
        setSyndromeDetails(newSyndromeDetails);
      });
    }

    if (syndromeZero) {
      DxAi.malformationDetails(syndromeZero.malformations).then((resp) => {
        setSyndromeZero({
          ...syndromeZero,
          malformations: [...resp.data.data],
        });
      });
    }
  };

  useEffect(() => {
    if (loadDetailsTimeout) clearTimeout(loadDetailsTimeout);
    loadDetailsTimeout = setTimeout(loadDetails, 200);
  }, [syndromes, examinationContext.MALFORMATIONS]);

  if (loadedSlides === null || loadedInstanceViews === null || loadedMedias === null) return false;

  return (
    <>
      {examinationContext.examination.type === 'diagnostic' &&
        (loadedMedias.length > 0 || loadedAnomalies.length > 0) && (
          <div className="examination-review-tabs">
            <div className={showTab === 'screening' ? 'selected' : ''} onClick={() => setShowTab('screening')}>
              {__('examinationReview.screeningReport')}
            </div>
            <div className={showTab === 'diagnostic' ? 'selected' : ''} onClick={() => setShowTab('diagnostic')}>
              {__('examinationReview.diagnosticReport')}
            </div>
          </div>
        )}
      {showTab === 'diagnostic' ? (
        <ExaminationReviewDxView
          patient={examinationContext.patient}
          episode={examinationContext.episode || { lmpdate: '' }}
          examination={examinationContext.examination}
          instances={examinationContext.instances}
          slides={loadedSlides.filter((slide) => slide.type === 'picture')}
          onChangeShareCheckbox={() => {}}
          onShareInstances={() => {}}
          syndromes={syndromes}
          syndromeDetails={syndromeDetails.map((syndrome) => ({
            ...syndrome,
            teratogens: (syndrome.risk_factors || [])
              ?.filter((r) => r.type === 'drug')
              .map((r) => allMedications.find((medication) => medication.risk_factor_ids.includes(r.id)))
              .filter((m) => !!m),
          }))}
          syndromeZero={syndromeZero}
          biometries={[]}
        />
      ) : (
        <ExaminationReviewView
          slides={loadedSlides}
          anomalies={loadedAnomalies}
          expectedMedias={expectedMedias}
          expectedCNEOFMedias={expectedCNEOFMedias}
          takenCNEOFMedias={takenCNEOFMedias}
          medias={loadedMedias}
          takenMedias={loadedMedias.filter((media) => media.dicom_media_type === 'image').length}
          medicalHistory={loadedMedicalHistory}
          riskFactors={loadedRiskFactors}
          teratogenicRisks={loadedTeratogenicRisks}
          slidesRiskFactors={slidesRiskFactors}
          checklistItems={loadedChecklistItems}
          checklistItemsRiskFactors={checklistItemsRiskFactors}
          setIsPrintPreviewOpen={setIsPrintPreviewOpen}
          setIsSharingPreviewOpen={setIsSharingPreviewOpen}
        />
      )}
    </>
  );
};

export default withTranslation()(ExaminationReview);
