import React, { useContext, useEffect, useState } from 'react';
import { ExaminationContext } from '../../../context-providers/Examination';
import useAuth from '../../../context-providers/Auth';
import Icon from '../../../atoms/Icon/Icon';
import TextArea from '../../../atoms/TextArea/TextArea';
import Toggle from '../../../atoms/Toggle';
import ButtonGroup from '../../../atoms/ButtonGroup/ButtonGroup';

export const PatientNotes = ({
  t: __,
  hideTitle = false,
  maxHeight = 0,
  scrollToBottom = false,
  showNotes = false,
  placeholder,
  ...props
}) => {
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();
  const [initialNotes, setInitialNotes] = useState(props.notes);
  const [notes, setNotes] = useState(props.notes);
  const [focused, setFocused] = useState(false);
  const [initialNotesModified, setInitialNotesModified] = useState(false);
  const [solvingConflict, setSolvingConflict] = useState(false);
  const [displayByDefault, setDisplayByDefault] = useState(showNotes === true);
  const [showWarningToggleMessage, setShowWarningToggleMessage] = useState(false);

  useEffect(() => {
    if (solvingConflict) return;
    if (focused && initialNotes !== props.notes) {
      setInitialNotesModified(true);
      setInitialNotes(props.notes);
    } else if (!initialNotesModified) {
      setInitialNotes(props.notes);
      setNotes(props.notes);
    }
  }, [props.notes, setNotes, focused]);

  useEffect(() => {
    if (showNotes !== null) {
      setDisplayByDefault(showNotes);
    }
  }, [showNotes]);

  const onChange = (value) => {
    setNotes(value);
  };

  const onBlur = () => {
    setFocused(false);
    if (!initialNotesModified) {
      props.onChange(notes);
    } else {
      console.warn('Conflict between initial notes and modified notes. TODO handle this case.');
      /* For the moment we override with the new notes */
      setSolvingConflict(true);
      setInitialNotesModified(false);
    }
  };

  const onSolveConflict = (value) => {
    if (value === 'override') {
      props.onChange(notes);
    } else if (value === 'merge') {
      const mergeNotes = `${notes}\n-----------\n${props.notes}`;
      setNotes(mergeNotes);
      setInitialNotes(mergeNotes);
      props.onChange(mergeNotes);
    } else {
      setNotes(props.notes);
      setInitialNotes(props.notes);
    }
    setSolvingConflict(false);
    setInitialNotesModified(false);
  };

  const onToggleClick = (value) => {
    examinationContext.updatePatient(props.patientId, { show_notes: value });
    setShowWarningToggleMessage(!value);
    setDisplayByDefault(value);
  };

  return (
    <>
      {!hideTitle && (
        <div className="patient-notes-title">
          <Icon name="comment" /> <h2 className="section-title">{__('reportSidebar.section.notes.title')}</h2>
        </div>
      )}
      <div className="patient-notes-root">
        <TextArea
          fullwidth={true}
          value={notes || ''}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          placeholder={placeholder}
          onBlur={onBlur}
          error={initialNotesModified || solvingConflict}
          scrollToBottom={scrollToBottom}
          maxHeight={maxHeight}
          highlight={!!notes && displayByDefault}
        />
        {isFeatureFlagEnabled('sonio.sidebar') && (
          <div className="patient-notes-toggle-container">
            <Toggle state={displayByDefault ? 'on' : ''} onChangeStateFun={onToggleClick} />
            <span>{__('patient.notes.showDefault')}</span>
          </div>
        )}
        {showWarningToggleMessage && (
          <div class="text-xsmall italic">
            <div class="error-message">
              <span class="mr-small">
                <Icon name="error" />
              </span>
              {__('patient.notes.toggle.warning')}
            </div>
          </div>
        )}
        {(initialNotesModified || solvingConflict) && (
          <div class="text-xsmall italic">
            <div class="error-message">
              <span class="mr-small">
                <Icon name="error" />
              </span>
              {__('patient.notes.conflict')}
            </div>
            <ButtonGroup
              options={[{ label: __('patient.notes.merge'), value: 'merge' }]}
              value={'override'}
              onChange={onSolveConflict}
            />
          </div>
        )}
      </div>
    </>
  );
};
