import useAuth from '../../context-providers/Auth';
import DashboardViewIndex from './DashboardViewIndex';
import DashboardManagerView from './DashboardManagerView';

const Dashboard = () => {
  const { isManager, sameSiteEntities } = useAuth();

  return isManager ? <DashboardManagerView sameSiteEntities={sameSiteEntities} /> : <DashboardViewIndex />;
};

export default Dashboard;
