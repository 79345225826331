import React, { useContext, useEffect, useRef } from 'react';
import './NotificationsPanel.css';
import Alert from '../Alert/Alert';
import { NotificationContext } from '../../context-providers/Notification';
import Icon from '../../atoms/Icon/Icon';

const NotificationsPanel = () => {
  const { notifications } = useContext(NotificationContext);

  const notificationsRef = useRef();
  const moveUpRef = useRef();
  const moveDownRef = useRef();

  useEffect(() => {
    notificationsRef.current?.scrollTo({
      top: notificationsRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [notifications.slice(-1)[0]?.key]);

  const moveUp = () => {
    if (notificationsRef.current?.scrollTop >= notificationsRef.current?.childNodes[0]?.offsetHeight) {
      notificationsRef.current?.scrollTo({
        top: notificationsRef.current?.scrollTop - notificationsRef.current?.childNodes[0]?.offsetHeight,
        behavior: 'smooth',
      });
    }
  };

  const moveDown = () => {
    notificationsRef.current?.scrollTo({
      top: notificationsRef.current.scrollTop + notificationsRef.current?.childNodes[0]?.offsetHeight,
      behavior: 'smooth',
    });
  };

  const onScroll = () => {
    moveUpRef.current?.classList.toggle(
      'active',
      notificationsRef.current?.scrollTop > notificationsRef.current?.childNodes[0]?.offsetHeight
    );
    moveDownRef.current?.classList.toggle(
      'active',
      notificationsRef.current?.scrollTop + notificationsRef.current?.childNodes[0]?.offsetHeight <
        notificationsRef.current?.scrollHeight
    );
  };

  return (
    <div className={`notifications-panel ${notifications.length ? 'open' : ''}`}>
      <nav className="notifications-nav-arrows">
        {notifications.length > 1 && (
          <>
            <Icon name="up" ref={moveUpRef} onClick={moveUp} />
            <Icon name="down" ref={moveDownRef} onClick={moveDown} />
          </>
        )}
      </nav>
      <div className="notifications-notifications" ref={notificationsRef} onScroll={onScroll}>
        {!!notifications.length &&
          notifications.map((notification) => <Alert key={notification.key} notification={notification} />)}
      </div>
    </div>
  );
};

export default NotificationsPanel;
