export default {
  SUCCESS: 'success',
  ERROR: 'error',
  doubleClickDuration: 250,
  longClickDuration: 200,
  scrollWheelSensitivity: 5,
  startDragSensitivity: 20,
  examinationLive: {
    numberOfColumns: 5,
  },
  evaluationScoreThreshold: 0.7,
  defaultVideoFrameRate: 30,
};

export const presets = {
  NDid: 4,
};

// TODO: This is temporarly const information should be in db
// different images should be presented per position
export const positionIdToRotation = {
  1: { rotation: 180, flip: false },
  2: { rotation: 180, flip: true },
  3: { rotation: 180, flip: false },
  4: { rotation: 180, flip: false },
  5: { rotation: 0, flip: true },
  6: { rotation: 0, flip: false },
  7: { rotation: 0, flip: true },
  8: { rotation: 0, flip: true },
  9: { rotation: 0, flip: true },
  10: { rotation: 0, flip: false },
  11: { rotation: 0, flip: true },
  12: { rotation: 0, flip: true },
  13: { rotation: 0, flip: true },
  14: { rotation: 0, flip: false },
  15: { rotation: 0, flip: true },
  16: { rotation: 0, flip: true },
  17: { rotation: 90, flip: false },
  18: { rotation: 270, flip: true },
  19: { rotation: 0, flip: true },
  20: { rotation: 0, flip: true },
  21: { rotation: 90, flip: true },
  22: { rotation: 270, flip: false },
  23: { rotation: 90, flip: true },
  24: { rotation: 90, flip: true },
  25: { rotation: 0, flip: true },
};

export const ChecklistItemStatus = {
  usual: { id: 'usual', synonyms: ['not-normal', 'not_normal'] },
  unusual: { id: 'unusual', synonyms: ['normal'] },
  visualized: { id: 'visualized', synonyms: [] },
  non_visualized: {
    id: 'non_visualized',
    synonyms: ['not-viewed', 'not_viewed', 'non-viewed', 'non_viewed', 'not-visualized', 'not_visualized'],
  },
  suboptimal: { id: 'suboptimal', synonyms: [] },
  previously_documented: {
    id: 'previously_documented',
    synonyms: ['previously-documented', 'documented'],
  },
  soft_marker: { id: 'soft_marker', synonyms: [] },
  not_applicable: { id: 'not_applicable', synonyms: ['empty'] },
};

export const ExamStatus = {
  INPROGRESS: 'inprogress',
  COMPLETED: 'completed',
  READY_FOR_REVIEW: 'ready_for_review',
  REPORT_SIGNED: 'report_signed',
  DRAFT: 'draft',
  FROZEN: 'archived_locked',
};

export const MeasurementDefaultUnits = {
  Ratio: null,
  Mass: 'g',
  Velocity: 'cm/sec',
  Acceleration: 'cm/sec2',
  Num: null,
  Len: 'mm',
  Time: 'ms',
  Freq: 'bpm',
  Vol: 'cm3',
  Area: 'cm2',
  Index: null,
};

export const stakeholderRoles = [
  { id: 'main_performing_clinician', external: false, permission: [] },
  {
    id: 'main_reading_provider',
    external: false,
    permission: ['examination.report.sign'],
  },
  { id: 'performing_clinician', external: false, permission: [] },
  {
    id: 'reading_provider',
    external: false,
    permission: ['examination.report.sign'],
  },
  { id: 'scribe', external: false, permission: [] },
  { id: 'nurse', external: false, permission: [] },
  { id: 'medical_assistant', external: false, permission: [] },
  { id: 'other', external: false, permission: [] },
  { id: 'referring_provider', external: true, permission: [] },
  { id: 'hospital_unit', external: true, permission: [] },
  { id: 'hospital_department', external: true, permission: [] },
  { id: 'other', external: true, permission: [] },
];

export const searchSupportedLibraries = ['ICD10', 'CPT'];
