import ReportTemplateMeasurementCurveLegend_v1_0 from './MeasurementCurveLegend/v1.0.js';
import ReportTemplateMeasurementCurveLegend_v2_0 from './MeasurementCurveLegend/v2.0.js';

export default function ReportTemplateMeasurementCurveLegend(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateMeasurementCurveLegend_v1_0(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateMeasurementCurveLegend_v2_0(props);
  }
  return null;
}
