import { forwardRef, useRef, useEffect } from 'react';
import Icon from '../Icon/Icon';
import './TextArea.css';

const TextArea = forwardRef(
  (
    {
      label = '',
      icon = '',
      value = '',
      maxHeight = 0,
      fullwidth = false,
      onChange = () => {},
      error = false,
      scrollToBottom = false,
      highlight = false,
      placeholder,
      ...props
    },
    forwardedRef
  ) => {
    const fallbackRef = useRef(null);
    const ref = forwardedRef || fallbackRef;

    const updateHeight = (target) => {
      // Adapt height of textarea to content
      if (target === null) return;
      target.style.height = '0px';
      const scrollHeight = target.scrollHeight;
      target.style.height = scrollHeight + 10 + 'px';
      if (maxHeight > 0) {
        target.style.maxHeight = maxHeight + 'rem';
      }
    };

    const onChangeHandler = (e) => {
      onChange(e.currentTarget.value);
      updateHeight(e.currentTarget);
    };
    useEffect(() => {
      updateHeight(ref.current);
    }, []);

    useEffect(() => {
      if (scrollToBottom && ref.current) {
        const isAtBottom = ref.current.scrollHeight - ref.current.scrollTop >= ref.current.clientHeight;
        if (isAtBottom) {
          ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight + 50;
        }
      }
    }, [scrollToBottom, value, ref.current]);

    const iconClass = icon !== '' ? 'has-icon' : '';
    const widthClass = fullwidth !== false ? 'full-width' : '';
    const errorClass = error !== false ? 'error' : '';
    const highlightClass = highlight ? 'highlight' : '';

    return (
      <div className={`textarea ${iconClass} ${widthClass} ${highlightClass} ${errorClass}`}>
        {label !== '' && <label>{label}</label>}
        <span className="textarea-field">
          {icon !== '' && <Icon name={icon} />}
          <textarea value={value || ''} {...props} onChange={onChangeHandler} placeholder={placeholder} ref={ref} />
        </span>
      </div>
    );
  }
);

export default TextArea;
