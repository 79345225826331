import React, { createContext, useState, useCallback } from 'react';

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const removeNotification = useCallback(
    (key) => {
      setNotifications((notifications) => {
        clearTimeout(notifications.find((notification) => notification.key === key)?.timeout);
        return notifications.filter((notification) => notification.key !== key);
      });
    },
    [setNotifications]
  );

  // duration 0 = do not auto-hide
  const showNotification = useCallback(
    (text, duration = 0, key = '') => {
      const actualKey = key || Date.now();
      setNotifications((notifications) => [
        ...notifications,
        {
          text,
          duration,
          key: actualKey,
          timeout: duration ? setTimeout(() => removeNotification(actualKey), duration) : false,
        },
      ]);
    },
    [setNotifications, removeNotification]
  );

  const updateNotification = useCallback(
    (text, duration = null, key) => {
      setNotifications((notifications) => {
        const index = notifications.findIndex((notification) => notification.key === key);
        if (index < 0) {
          showNotification(text, duration, key);
          return false;
        }

        if (duration !== null) {
          clearTimeout(notifications[index]?.timeout);
        }

        const newNotifications = [...notifications];
        newNotifications[index] = {
          text,
          duration: duration ?? notifications[index].duration,
          key,
          timeout:
            duration !== null ? setTimeout(() => removeNotification(key), duration) : notifications[index].timeout,
        };
        return newNotifications;
      });
    },
    [setNotifications, removeNotification, showNotification]
  );

  const notificationExists = (key) => notifications.some((notification) => notification.key === key);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        showNotification,
        updateNotification,
        removeNotification,
        notificationExists,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
