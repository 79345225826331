import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Icon from '../../atoms/Icon/Icon';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { SocketContext } from '../../context-providers/Socket';
import { formatName } from '../../services/examination';
import ResourceApi from '../../services/resource';
import UserApi from '../../services/user';
import './ConfigurationView.css';
import { LiveSessionEventContext } from '../../context-providers/LiveSessionEvent';

export const PERFORMING_PHYSICIAN_NAME_LABEL = 'performingPhysicianName';
export const DEVICE_SERIAL_NUMBER_LABEL = 'deviceSerialNumber';

export const LIVE_CONFIG = 'live_config';

const ConfigurationView = ({ t: __ }) => {
  const allLocalLiveConfig = JSON.parse(localStorage.getItem(LIVE_CONFIG));
  const appContext = useContext(AppContext);
  const liveSessionEventContext = useContext(LiveSessionEventContext);
  const { liveConfig, setLiveConfig } = useContext(SocketContext);
  const { user, isShadow, isManager } = useAuth();

  const [site, setSite] = useState(null);
  const [practitionerList, setPractitionerList] = useState([]);
  const [machinesList, setMachinesList] = useState([]);
  const [zoomUponReceive, setZoomUponReceive] = useState(window.localStorage.getItem('zoomUponReceive') || 'off');

  useEffect(() => {
    UserApi.getDicomFilters().then((resp) => {
      setPractitionerList(resp.data.practitioners);
      setMachinesList(resp.data.machines);
    });
  }, []);

  useEffect(() => {
    if (appContext.preferences) {
      ResourceApi.getSite().then((resp) => {
        setSite(resp.data.data.find((s) => s.id === appContext.preferences.last_used_site_id));
      });
    }
  }, [appContext.preferences]);

  const updateConfigAccordingUserRole = (newConfig) => {
    if (isManager) {
      localStorage.setItem(
        LIVE_CONFIG,
        JSON.stringify({ ...allLocalLiveConfig, [user.preferences.last_used_site_id]: newConfig })
      );
      setLiveConfig(newConfig);
    } else
      UserApi.updateConfig(newConfig.id, newConfig).then((resp) => {
        liveSessionEventContext.broadcastConfigUpdate({ live_configuration: resp.data.data?.live_configuration });
        setLiveConfig(resp.data.data);
      });
  };

  const onLiveToggle = useCallback(
    (checked) => {
      if (isShadow) return;
      const newConfig = { ...liveConfig };
      newConfig.live_configuration.live_enabled = checked;
      updateConfigAccordingUserRole(newConfig);
    },
    [isShadow, liveConfig, updateConfigAccordingUserRole]
  );

  const onALLCheckHandling = (target) => {
    if (isShadow) return;

    const newConfig = { ...liveConfig };
    if (newConfig.live_configuration?.filters?.operands) {
      newConfig.live_configuration.filters.operands.forEach((operand, index, arr) => {
        if (operand.target === target) arr[index] = { operator: 'ALL', target: target };
      });
    }
    updateConfigAccordingUserRole(newConfig);
  };

  const onToggle = useCallback(
    (checked, target, item) => {
      if (isShadow) return;
      const onCheckHandling = () => {
        const newConfig = { ...liveConfig };
        newConfig.live_configuration.filters.operands.forEach((operand, index, arr) => {
          if (operand.target === target)
            if (Array.isArray(operand?.operands)) {
              operand.operands.push(item);
            } else {
              arr[index] = { operator: 'IN', target: target, operands: [item] };
            }
        });
        updateConfigAccordingUserRole(newConfig);
      };

      const onUncheckHandling = () => {
        const newConfig = { ...liveConfig };
        newConfig.live_configuration.filters.operands.forEach((operand, index, arr) => {
          if (operand.target === target) {
            const idx = operand.operands.indexOf(item);
            if (idx > -1) {
              operand.operands.splice(idx, 1);
            }
            if (operand.operands.length === 0) arr[index] = { operator: 'ALL', target: target };
          }
        });
        updateConfigAccordingUserRole(newConfig);
      };
      checked ? onCheckHandling() : onUncheckHandling();
    },
    [isShadow, isManager, liveConfig, updateConfigAccordingUserRole]
  );

  return (
    !!user &&
    !!site && (
      <div className="configuration-container">
        <div className="row">
          <div className="w12">
            <h1>{__('live_configuration.title')}</h1>
          </div>
          {!!isShadow && (
            <div className="w12 manager-info">
              <Icon name="question" />
              {__('live_configuration.infos')}
            </div>
          )}
          <div className="live-checkbox">
            <Checkbox
              label={
                <>
                  {__('live_configuration.mode')}
                  &nbsp;
                  {liveConfig === null || liveConfig?.live_configuration?.live_enabled ? (
                    <span className="mode-info-checked">{__('live_configuration.active')}</span>
                  ) : (
                    __('live_configuration.inactive')
                  )}
                  &nbsp;
                  {__('live_configuration.modePreUser')}
                  &nbsp;
                  <span className="mode-info-user">{user?.title}</span>
                  &nbsp;
                  {__('live_configuration.modePostUserPreSite')}
                  &nbsp;
                  <span className="mode-info-site">{site?.name}</span>
                  <div className="mode-info">{__('live_configuration.modeInfo')}</div>
                </>
              }
              checked={liveConfig === null || liveConfig?.live_configuration?.live_enabled}
              onChange={onLiveToggle}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="w4">{__('live_configuration.popupOnReceive')}</div>
          <div className="w8">
            <SelectInput
              value={zoomUponReceive}
              options={[
                {
                  label: __('live_configuration.always'),
                  value: 'on',
                },
                {
                  label: __('live_configuration.suboptimal'),
                  value: 'suboptimal',
                },
                {
                  label: __('live_configuration.never'),
                  value: 'off',
                },
                {
                  label: __('live_configuration.disabled'),
                  value: 'noscroll',
                },
              ]}
              onChange={(value) => {
                window.localStorage.setItem('zoomUponReceive', value);
                setZoomUponReceive(value);
              }}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="w4">{__('live_configuration.practitioners')}</div>
          <div className="w8 filter-list-container">
            <Checkbox
              key={'all'}
              label={__('live_configuration.allPractitioners')}
              checked={
                liveConfig === null ||
                liveConfig?.live_configuration?.filters?.operands.find(
                  (operand) => operand.target === PERFORMING_PHYSICIAN_NAME_LABEL
                ) === undefined ||
                liveConfig?.live_configuration?.filters?.operands.find(
                  (operand) => operand.target === PERFORMING_PHYSICIAN_NAME_LABEL
                ).operator === 'ALL'
              }
              onChange={(checked) => {
                if (checked) onALLCheckHandling(PERFORMING_PHYSICIAN_NAME_LABEL);
              }}
            />
            {practitionerList.map((practitioner) => (
              <Checkbox
                key={practitioner}
                label={formatName(practitioner).fullName}
                checked={liveConfig?.live_configuration?.filters?.operands
                  .find((operand) => operand.target === PERFORMING_PHYSICIAN_NAME_LABEL)
                  ?.operands?.includes(practitioner)}
                onChange={(checked) => {
                  onToggle(checked, PERFORMING_PHYSICIAN_NAME_LABEL, practitioner);
                }}
              />
            ))}
            <Checkbox
              key={'undefined'}
              label={__('live_configuration.undefinedPractitioner')}
              checked={liveConfig?.live_configuration?.filters?.operands
                .find((operand) => operand.target === PERFORMING_PHYSICIAN_NAME_LABEL)
                ?.operands?.includes('')}
              onChange={(checked) => {
                onToggle(checked, PERFORMING_PHYSICIAN_NAME_LABEL, '');
              }}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="w4">{__('live_configuration.machines')}</div>
          <div className="w8 filter-list-container">
            <Checkbox
              key={'all'}
              label={__('live_configuration.allMachines')}
              checked={
                liveConfig === null ||
                liveConfig?.live_configuration?.filters?.operands.find(
                  (operand) => operand.target === DEVICE_SERIAL_NUMBER_LABEL
                ) === undefined ||
                liveConfig?.live_configuration?.filters?.operands.find(
                  (operand) => operand.target === DEVICE_SERIAL_NUMBER_LABEL
                ).operator === 'ALL'
              }
              onChange={(checked) => {
                if (checked) onALLCheckHandling(DEVICE_SERIAL_NUMBER_LABEL);
              }}
            />
            {machinesList.map((machine) => (
              <Checkbox
                key={machine}
                label={machine}
                checked={liveConfig?.live_configuration?.filters?.operands
                  .find((operand) => operand.target === DEVICE_SERIAL_NUMBER_LABEL)
                  ?.operands?.includes(machine)}
                onChange={(checked) => {
                  onToggle(checked, DEVICE_SERIAL_NUMBER_LABEL, machine);
                }}
              />
            ))}
            <Checkbox
              key={'undefined'}
              label={__('live_configuration.undefinedMachine')}
              checked={liveConfig?.live_configuration?.filters?.operands
                .find((operand) => operand.target === DEVICE_SERIAL_NUMBER_LABEL)
                ?.operands?.includes('')}
              onChange={(checked) => {
                onToggle(checked, DEVICE_SERIAL_NUMBER_LABEL, '');
              }}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default withTranslation()(ConfigurationView);

ConfigurationView.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
