import { isValidElement, cloneElement } from 'react';

export default function ReportTemplateIf({
  defaultKey = '',
  children,
  getPlaceholderWithProps,
  props,
  fetus,
  checkCondition = () => {},
}) {
  if (props.fetus) fetus = props.fetus;

  const matchesConditions = checkCondition({ ...props }, fetus, getPlaceholderWithProps);

  const childrenWithFetusNumber = !matchesConditions
    ? false
    : Array.isArray(children)
    ? children.map((child, i) => {
        if (isValidElement(child)) {
          return cloneElement(child, { ...child.props, fetus, defaultKey: `${defaultKey}_${i}_if_${fetus}` });
        }
        return child;
      })
    : children;

  return childrenWithFetusNumber || false;
}
