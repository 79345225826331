// The main App Context to manage the state of the application
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useLayoutEffect, useState, useContext } from 'react';
import UserBlockingMessage from '../components/Disclaimer/UserBlockingMessage';
import LegalTermsAgreement from '../components/Disclaimer/LegalTermsAgreement';
import DisclaimerRoutine from '../components/Disclaimer';
import DisclaimerDetect from '../components-dt/Disclaimer';
import ResourceApi from '../services/resource';
import LookupApi from '../services/lookup';
import UserApi from '../services/user';
import useVersionContext from './LatestVersion';
import useAuth from './Auth';
import { presets as presetsConfig } from '../config';

export const AppContext = createContext({});

let currentUrl = '';

export const AppContextProvider = ({ children }) => {
  const {
    isFeatureFlagEnabled,
    user,
    config,
    disclaimerAccepted,
    acceptLegalTermsAgreements,
    soniopediaTOSAccepted,
    acceptDisclaimer,
    updateUserConfig,
    getUserConfig,
    isManager,
    siteId,
  } = useAuth();
  const { isSoniopediaURL } = useVersionContext();
  const isSoniopedia = isFeatureFlagEnabled && isFeatureFlagEnabled('soniopedia');
  let wideScreenMessageType = '';
  if (!disclaimerAccepted)
    wideScreenMessageType =
      isFeatureFlagEnabled && isFeatureFlagEnabled('sonio.detect') ? 'disclaimer_detect' : 'disclaimer_routine';
  if (isSoniopedia && !isSoniopediaURL()) wideScreenMessageType = 'soniopedia_user_on_sonio_url';
  if (isSoniopediaURL() && !isSoniopedia) wideScreenMessageType = 'sonio_user_on_soniopedia_url';
  if (isSoniopediaURL() && isSoniopedia && !soniopediaTOSAccepted)
    wideScreenMessageType = 'soniopedia_terms_agreements';

  const [preferences, setPreferences] = useState(null);
  const [examinationTemplatesConfiguration, setExaminationTemplatesConfiguration] = useState(null);
  const [sharingConfiguration, setSharingConfiguration] = useState(null);
  const [fetusSexVisibility, setFetusSexVisibility] = useState('hidden');
  const [placentaPositions, setPlacentaPositions] = useState([]);
  const [fetusPositions, setFetusPositions] = useState([]);
  const [displayedPresets, setDisplayedPresets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [goBackMenuButton, setGoBackMenuButton] = useState(false);
  const [goBackMenuFunction, setGoBackMenuFunction] = useState(null);
  const [site, setSite] = useState(null);
  const [allSites, setAllSites] = useState(null);
  const [allPresets, setAllPresets] = useState(null);
  const [referer, setReferer] = useState('');
  const [protocolsEnabled, setProtocolsEnabled] = useState(false);

  useLayoutEffect(() => {
    if (currentUrl !== referer) setReferer(currentUrl);
    currentUrl = window.location.pathname;
  }, [window.location.pathname]);

  // Initialize
  useEffect(() => {
    if (user) {
      setPreferences(user.preferences);
    }
  }, [user]);

  useEffect(() => {
    setExaminationTemplatesConfiguration(config?.examination_template);
  }, [config?.examination_template]);

  useEffect(() => {
    setSharingConfiguration(config?.sharing_configuration);
  }, [config?.sharing_configuration]);

  useEffect(() => {
    if (user) {
      ResourceApi.getPlacentaPositions().then(({ data: { data } }) => setPlacentaPositions(data));
      LookupApi.getFetusPositions().then(({ data: { data } }) => setFetusPositions(data));
    }
  }, [user]);

  useEffect(() => {
    if (!user) setFetusSexVisibility('hidden');
    UserApi.getUserFetusSexVisibility().then(({ data: { fetus_sex_visibility } }) =>
      setFetusSexVisibility(fetus_sex_visibility)
    );
  }, [config?.fetus_sex_visibility, user?.country_id]);

  useEffect(() => {
    setDisplayedPresets(config?.examination_preset);
  }, [config?.examination_preset]);

  // Detect changes to language and update
  useEffect(() => {
    if (preferences && i18next.language !== preferences.lang) {
      i18next.changeLanguage(preferences.lang);
    }
  }, [preferences]);

  // Load sites
  useEffect(() => {
    if (preferences && user?.id) {
      ResourceApi.getSite().then((resp) => {
        setAllSites(resp.data.data);
        setSite(resp.data.data.find((s) => s.id === siteId));
      });
    }
  }, [preferences]);

  const getAllPreset = useCallback(async () => {
    if (config && user?.id && isManager === false) {
      const {
        data: { data: allPresets },
      } = await ResourceApi.listExaminationPreset();
      setAllPresets(allPresets);
    }
  }, [config, user?.id, isManager]);

  // Load presets
  useEffect(() => {
    getAllPreset();
  }, [getAllPreset]);

  useEffect(() => {
    setProtocolsEnabled(displayedPresets && !!displayedPresets.find((preset) => preset !== presetsConfig.NDid));
  }, [displayedPresets]);

  const updateUserPreferences = (preferences) => {
    return UserApi.update(user.id, { preferences }).then((resp) => setPreferences(resp.data.data.preferences));
  };

  const changeExaminationTemplates = (newConfig) => {
    updateUserConfig(newConfig);
  };

  const activateGoBackMenuButton = (activate, goBackFunction = null) => {
    setGoBackMenuButton(activate);
    setGoBackMenuFunction(goBackFunction);
  };

  const getDefaultCategoryLabelForSlide = (slide, trimester, __) => {
    let categoryLabel = '';
    slide?.category?.[trimester]?.forEach((cat) => {
      if (cat.split('.')[0] === preferences?.default_category) {
        categoryLabel = __(`examination-plane.category.${cat}`);
      }
    });
    return categoryLabel;
  };

  const isSlideInDefaultCategory = (slide, trimester) =>
    slide.category?.[trimester]?.map((cat) => cat.split('.')[0]).includes(preferences.default_category);

  const changeFetusSexDisplay = (fetus_sex_visibility) =>
    updateUserConfig({
      id: config.id,
      fetus_sex_visibility,
    });

  const renderWideScreenMessage = (msgType) => {
    switch (msgType) {
      case 'disclaimer_detect':
        return <DisclaimerDetect onAccept={acceptDisclaimer} />;
      case 'disclaimer_routine':
        return <DisclaimerRoutine onAccept={acceptDisclaimer} />;
      case 'soniopedia_user_on_sonio_url':
      case 'sonio_user_on_soniopedia_url':
        return <UserBlockingMessage blockingSituation={msgType} />;
      case 'soniopedia_terms_agreements':
        return <LegalTermsAgreement product={'soniopedia'} onAccept={acceptLegalTermsAgreements} />;
      default:
        return <></>;
    }
  };

  return (
    <AppContext.Provider
      value={{
        preferences,
        examinationTemplatesConfiguration,
        sharingConfiguration,
        displayedPresets: displayedPresets,
        protocolsEnabled,
        isLoading,
        setIsLoading,
        goBackMenuButton,
        goBackMenuFunction,
        activateGoBackMenuButton,
        changeTheme: (theme) => updateUserPreferences({ theme }),
        changeLanguage: (lang) => updateUserPreferences({ lang }),
        changeDateFormat: (date_format) => updateUserPreferences({ date_format }),
        changeTimeFormat: (time_format) => updateUserPreferences({ time_format }),
        changeUnits: (units) => updateUserPreferences({ units }),
        changeDefaultExamType: (default_exam_type) => updateUserPreferences({ default_exam_type }),
        changeExaminationTemplates: (examination_template) =>
          changeExaminationTemplates({
            id: config.id,
            examination_template: examination_template,
          }),
        changeDefaultCategory: (default_category) => updateUserPreferences({ default_category }),
        site,
        setSite,
        allSites,
        allPresets,
        getUserConfig,
        referer,
        getDefaultCategoryLabelForSlide,
        isSlideInDefaultCategory,
        fetusSexVisibility,
        changeFetusSexDisplay,
        placentaPositions,
        fetusPositions,
        viewsDictionary: config?.vision_ai_annotations_mapping?.views || [],
      }}
    >
      <div data-theme={preferences?.theme ?? 'dark'} className={`theme-${preferences?.theme ?? 'dark'} root-wrapper`}>
        {!!wideScreenMessageType && renderWideScreenMessage(wideScreenMessageType)}
        {!wideScreenMessageType && children}
      </div>
    </AppContext.Provider>
  );
};
export const useApp = () => useContext(AppContext);

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
