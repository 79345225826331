import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import useAuth from '../../../context-providers/Auth';
import { isNullOrUndefined, joinClasses, getUniqueId } from '../../../utils.js';
import Recents from '../../../services/recents';
import TextInput from '../../TextInput/TextInput';
import Icon from '../../Icon/Icon';
import './InlineMultiSelect.css';
import '../InlineEditing.css';

const scrollableParents = [];
let closing = false;

const MultiSelect = ({
  t: __,
  value,
  options,
  printable = true,
  multiple = false,
  compactMode = false,
  showSearchBar = 'auto',
  showRecent = false, // expecting a string with the collection's name
  showMostUsed = false, // expecting a string
  compactModeLabel = '',
  fullwidth = false,
  disabled = false,
  active = true,
  onChange,
}) => {
  const { user } = useAuth();
  const [spacing, setSpacing] = useState({ top: 0, bottom: 0, left: 0, right: 0, position: 'bottom' }); // space over and below the select box at open
  const [open, setOpen] = useState(false);
  const [openCategories, setOpenCategories] = useState([]);
  const [selectedOnOpen, setSelectedOnOpen] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const container = useRef(null);
  const dropdown = useRef(null);
  const searchField = useRef(null);
  const UID = useMemo(() => getUniqueId(), []);

  const recentCollection = showRecent || showMostUsed;

  const mountEvents = () => {
    window.addEventListener('click', onCloseHandler, true);
    window.addEventListener('resize', refreshDropdownPosition, true);
    for (const node of scrollableParents) {
      node.addEventListener('scroll', refreshDropdownPosition);
    }
  };

  const unmountEvents = () => {
    window.removeEventListener('click', onCloseHandler, true);
    window.removeEventListener('resize', refreshDropdownPosition, true);
    for (const node of scrollableParents) {
      node.removeEventListener('scroll', refreshDropdownPosition);
    }
  };

  useEffect(() => {
    for (let node = container.current.parentNode; node; node = node.parentNode) {
      if (node.scrollHeight > node.clientHeight && !scrollableParents.includes(node)) scrollableParents.push(node);
    }
    return () => {
      for (const forgottenOptions of document.querySelectorAll(`.App > div > .multiselect-options[data-uid=${UID}]`)) {
        forgottenOptions.parentNode.removeChild(forgottenOptions);
      }
      unmountEvents();
    };
  }, []);

  useEffect(() => {
    if (!open) {
      if (dropdown && dropdown.current) container.current?.appendChild(dropdown.current);
      unmountEvents();
      closing = true;
      setTimeout(() => (closing = false), 100);
      return;
    }

    for (const forgottenOptions of document.querySelectorAll(`.App > div > .multiselect-options[data-uid=${UID}]`)) {
      forgottenOptions.parentNode.removeChild(forgottenOptions);
    }
    if (dropdown) document.querySelector('.App > div').appendChild(dropdown.current);

    refreshDropdownPosition();
    mountEvents();
    searchField?.current?.focus();
    dropdown?.current?.scrollTo({ top: 0 });
  }, [open]);

  useEffect(() => {
    refreshDropdownPosition();
  }, [openCategories]);

  const refreshDropdownPosition = () => {
    if (!dropdown) return;
    if (!dropdown.current) return;

    // calculate the space below and hover the button until the window border, in pixels, and pass it to the callback
    const position = container.current?.querySelector('.multiselect-current')?.getBoundingClientRect();
    if (!position) {
      setOpen(false);
      return false;
    }

    const spacing = {
      top: parseInt(position.bottom),
      left: parseInt(position.left),
      position: 'top',
      minWidth: container.current?.offsetWidth,
    };

    const maxScrollHeight = Math.ceil(
      Math.min(Math.max(window.innerHeight - position.bottom - 30, position.top - 30), dropdown.current.scrollHeight)
    );
    spacing.position = window.innerHeight - position.bottom < maxScrollHeight ? 'top' : 'bottom';
    spacing.maxHeight = maxScrollHeight;
    if (spacing.position === 'top') {
      spacing.top = parseInt(position.top - maxScrollHeight);
    }
    if (window.innerWidth < spacing.left + dropdown.current.offsetWidth) {
      spacing.left = position.right - dropdown.current.offsetWidth;
    }

    setSpacing(spacing);
  };

  const onCloseHandler = useCallback(
    (e) => {
      if (!e.target) return;
      let insideDropdown = null;
      for (let target = e.target; target; target = target.parentNode) {
        if (target.tagName === 'DIV' && target?.className?.includes('multiselect-options')) {
          insideDropdown = target;
          break;
        }
      }

      if (!insideDropdown) {
        setOpen(false);
        setOpenCategories([]);
        setSearchKey('');
      }
    },
    [setOpen, setOpenCategories]
  );

  const toggleCategory = (id) => {
    if (openCategories.includes(id)) {
      setOpenCategories((openCategories) => [...openCategories.filter((oc) => oc !== id)]);
    } else {
      setOpenCategories((openCategories) => [...openCategories, id]);
    }
  };

  const toggleOption = (id) => {
    (showRecent || showMostUsed) && Recents.addToRecent(recentCollection, user?.id, id, getOptionById(id, options));

    if (multiple) {
      const realValue = value ?? [];
      if (realValue.includes(id)) {
        onChange(realValue.filter((v) => v !== id));
      } else {
        onChange([...realValue, id]);
      }
    } else {
      onChange(id);
      setOpen(false);
    }
  };

  const getOptionById = useCallback((id, tree) => {
    if (!tree) return false;

    let elm = tree.find((node) => `${node.id}` === `${id}`);
    if (elm) return elm;

    for (const node of tree) {
      elm = getOptionById(id, node.tree);
      if (elm) return elm;
    }
    return elm;
  }, []);

  const getOptionsBySearchKey = useCallback((key, tree) => {
    if (!key || !tree) return [];

    const elms = [];

    for (const node of tree) {
      if (node.label.toLowerCase().includes(key.toLowerCase())) {
        elms.push({ ...node });
      } else {
        const childs = getOptionsBySearchKey(key, node.tree);
        if (childs.length) {
          elms.push({ ...node, tree: childs });
        }
      }
    }
    return elms;
  }, []);

  const countChildren = useCallback((tree) => {
    if (!tree) return false;
    let counter = tree?.length;
    for (const node of tree) {
      counter += countChildren(node?.tree);
    }
    return counter;
  }, []);

  const countSelectedChildren = useCallback(
    (tree) => {
      if (!tree || !value) return false;

      let counter = Array.isArray(value)
        ? tree.filter((node) => value?.some((id) => id === node?.id)).length
        : tree.filter((node) => `${node.id}` === `${value}` || node.label === value).length;

      for (const node of tree) {
        counter += countSelectedChildren(node?.tree);
      }

      return counter;
    },
    [value]
  );

  const numberOfChildren = useMemo(() => countChildren(options), [options, countChildren]);
  const selectedOptions = useMemo(
    () => (multiple ? value?.map((id) => getOptionById(id, options)) || [] : []),
    [multiple, value, getOptionById]
  );
  const displaySearchBar = showSearchBar === 'auto' ? numberOfChildren > 20 : !!showSearchBar;
  const searchResults = useMemo(
    () => getOptionsBySearchKey(searchKey, options),
    [value, getOptionsBySearchKey, searchKey]
  );

  const BranchItem = ({ type = 'node', label = '', id, tree = [], selectable = true }) => {
    const selected =
      selectable && (selectedOptions?.some((option) => option?.id === id) || `${id}` === `${value}` || label === value);
    const open = openCategories?.includes(id) || !!searchKey;
    const isHeading = !selectable;
    const selectedChildren = countSelectedChildren(tree);

    return (
      <div
        key={type + id}
        className={joinClasses([
          'multiselect-option',
          `type-${type}`,
          isHeading ? 'heading' : '',
          isHeading ? (open ? 'open' : 'closed') : '',
          selected ? 'selected' : '',
        ])}
      >
        <div
          className={joinClasses(['multiselect-label', selectable ? 'selectable' : ''])}
          onClick={(e) => {
            e.stopPropagation();
            if (selectable) {
              toggleOption(id);
              return;
            }
            if (isHeading) {
              toggleCategory(id);
            }
          }}
          key={id}
        >
          <div>
            {multiple && selectable && (
              <input
                type="checkbox"
                checked={selected}
                onChange={() => {
                  /* prevent rising up a warning */
                }}
              />
            )}
            {label || <>&nbsp;</>}
            {selectedChildren > 0 && <span className="selected-children">{selectedChildren}</span>}
          </div>
          {isHeading && (
            <span>
              <Icon name="down" />
            </span>
          )}
        </div>
        {open && !!tree?.length && <div className="nested-tree">{tree.filter((t) => t).map(BranchItem)}</div>}
      </div>
    );
  };

  const recents = (showRecent && numberOfChildren > 12 && Recents.getRecents(recentCollection, user?.id, 3)) || [];
  const mostUsed =
    (showMostUsed &&
      numberOfChildren > 12 &&
      Recents.getMostUsed(recentCollection, user?.id, 3, (item) => !recents.some((recent) => recent.id === item.id))) ||
    [];

  const optionDescription = (option) => {
    return isNullOrUndefined(option?.description) ? option?.label : option?.description;
  };

  const convertValueToArray = (value) => {
    if (isNullOrUndefined(value)) return [];
    const valueArray = Array.isArray(value) ? value : [value];
    return valueArray.filter((node) => !isNullOrUndefined(node));
  };

  return (
    <div
      className={`multiselect-container ${multiple ? 'multiple' : 'single'} ${fullwidth ? 'full-width' : ''} ${
        open ? 'open' : 'closed'
      }`}
      ref={container}
    >
      <div
        className={`multiselect-current inline-editing ${active ? 'is-active' : 'not-active'} ${
          printable ? 'is-printable' : 'not-printable'
        }`}
        onClick={() => {
          if (!open && !closing && active) {
            setSelectedOnOpen([...selectedOptions]);
            setOpen(true);
          }
        }}
      >
        <div className="inline-input-value inline-editing-value">
          <div className={`${active ? 'contenteditable' : ''}`} data-test-id="dropdown_multiselect">
            {compactMode ? (
              compactModeLabel
            ) : (
              <>
                {convertValueToArray(value)
                  .map((v) => optionDescription(getOptionById(v, options)))
                  .filter((value) => value)
                  .join(', ')}
                {!value?.length && <span></span>}
              </>
            )}
          </div>
        </div>
        {!disabled && active && <Icon name="down" className="not-printable" />}
      </div>
      {!disabled && active && (
        <div
          ref={dropdown}
          data-uid={UID}
          className={`multiselect-options ${multiple ? 'multiple' : 'single'} ${open ? 'open' : ''} not-printable`}
          style={{
            top: spacing.top,
            left: spacing.left,
            minWidth: spacing.minWidth,
            maxHeight: spacing.maxHeight,
          }}
        >
          {!!open && (
            <>
              {displaySearchBar && (
                <div className="multiselect-search">
                  <TextInput
                    value={searchKey}
                    ref={searchField}
                    onChange={(value) => setSearchKey(value)}
                    icon="search"
                    fullwidth="true"
                  />
                </div>
              )}

              {!compactMode && !searchKey && (
                <>
                  {selectedOnOpen.length > 0 &&
                    [
                      {
                        type: 'recent',
                        label: __('multiselect.previouslySelected'),
                        id: '__selected',
                        selectable: false,
                        tree: selectedOnOpen,
                      },
                    ].map(BranchItem)}
                  {recents.length > 0 &&
                    [
                      {
                        type: 'recent',
                        label: (
                          <>
                            <Icon name="clock" /> {__('suggestion.recent')}
                          </>
                        ),
                        id: '__recents',
                        selectable: false,
                        tree: recents.map((item) => item.item),
                      },
                    ].map(BranchItem)}
                  {mostUsed.length > 0 &&
                    [
                      {
                        type: 'recent',
                        label: (
                          <>
                            <Icon name="reload" /> {__('suggestion.mostUsed')}
                          </>
                        ),
                        id: '__most_used',
                        selectable: false,
                        tree: mostUsed.map((item) => item.item),
                      },
                    ].map(BranchItem)}
                </>
              )}

              {(!searchKey ? options : searchResults)?.map(BranchItem)}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(MultiSelect);
