import { withTranslation } from 'react-i18next';
import React, { useState, useContext } from 'react';
import ResourceApi from '../../services/resource';
import { AppContext } from '../../context-providers/App';
import './PrintingSharingSettings.css';
import useAnchorScroll from '../../hooks/useAnchorScroll';
import { uploadImage, getHeaderFormData, onError } from './helpers';
import { LOADING_IMAGE_URL } from './constants';
import ImageSettingField from './ImageSettingField';

const HeaderImageSettings = ({ t: __ }) => {
  useAnchorScroll();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const { site, setSite } = useContext(AppContext);

  const handleImageChange = async (e) => {
    const formData = getHeaderFormData(e, (show) =>
      setErrorMessage(show ? __('preferences.printingSharing.logo.uploadTooBig') : false)
    );
    if (formData) {
      await uploadImage(
        formData,
        (show) => setErrorMessage(show ? __('preferences.printingSharing.logo.uploadError') : false),
        setLoading,
        setSite
      );
    }
  };

  return (
    <ImageSettingField
      idSlug="header-image"
      title={__('preferences.printingSharing.headerImage')}
      subTitle={__('preferences.printingSharing.headerUploadInfo')}
      description={__('preferences.printingSharing.headerDescription')}
      uploadButtonText={__('preferences.printingSharing.upload')}
      onChange={handleImageChange}
      onDelete={handleImageChange}
      errorMessage={errorMessage}
    >
      {loading ? (
        <img src={LOADING_IMAGE_URL} alt="Loading" />
      ) : (
        <img src={ResourceApi.getSiteHeader(site?.id)} alt="Header Image" onError={onError} />
      )}
    </ImageSettingField>
  );
};

export default withTranslation()(HeaderImageSettings);
