import axios from 'axios';

const UserApi = {
  login: (email, password, site_id) => axios.post('/auth/login', { email, password, site_id }),
  validateMFACode: (code) => axios.post('/auth/validate_mfa_code', { code }),
  regenerateMFACode: () => axios.post('/auth/regenerate_mfa_code', {}),
  isSessionActivated: () => axios.get('/auth/is_session_activated'),
  logout: () => axios.get('/auth/logout'),
  requestPasswordReset: (email, language = 'en') => axios.post('/auth/request-password-reset', { email, language }),
  resetPassword: (token, newPassword, confirmPassword) =>
    axios.post(`/auth/reset-password/${token}`, { new_password: newPassword, confirm_password: confirmPassword }),
  validatePasswordDetail: (token) => axios.get(`/auth/reset-password/detail/${token}`),
  loginAsSharedUser: (share_code, share_token) => axios.post('auth/login-as-shared-user', { share_code, share_token }),
  registerInvited: (body) => axios.post(`/auth/register-invited-user`, body),
  getUser: () => axios.get('me'),
  getUserFetusSexVisibility: () => axios.get('me/fetus_sex_visibility'),
  getEntity: (id) => axios.get(`entity/${id}`),
  getManagedEntities: () => axios.get('/manager/entity'),
  switchUser: (entity_id) => axios.post('/manager/switch-user', { entity_id }),
  switchBackToManager: () => axios.post('/switch-to-manager'),
  getSessionInfo: () => axios.get('/session-info'),
  update: (entityId, params) => axios.put(`/entity/${entityId}`, params),
  changeSite: (site_id) => axios.post('/identity/change-site', { site_id }),
  GetDetailsFromInvited: (inviteCode) =>
    axios.get('/auth/invited-user-details', { params: { invite_code: inviteCode } }),
  getConfig: (source) => axios.get('/configuration', { cancelToken: source.token }),
  getSiteFlowsConnectors: () => axios.get('/configuration/site_flows_connectors'),
  getPatientAppConfig: () => axios.get('/patient-app-config'),
  setPatientAppConfigValue: ({ field, value }) => axios.put('/patient-app-config', { field, value }),
  getDicomFilters: () => axios.get('/configuration/dicom-filters'),
  createConfig: (config, source) => axios.post('/configuration', { config: config }, { cancelToken: source.token }),
  updateConfig: (configId, config) => axios.put(`/configuration/${configId}`, { config: config }),
  validatePassword: (password, additional_informations) =>
    axios.post(`/auth/validate-password`, { ...additional_informations, password }),
  supportedAuthorizationBackend: () => axios.get('/auth/config/supported-authorization-backend'),
  changeGrantForSession: (newGrantId) => axios.post('/session/change-grant', { session: { grant_id: newGrantId } }),
  changeSiteForSession: (newSiteId) => axios.post('/session/change-site', { session: { site_id: newSiteId } }),
  getSiteSharingPreselection: () => axios.get('/site-sharing-preselection'),
  setSiteSharingPreselection: (fields) => axios.put('/site-sharing-preselection', { fields: fields }),
  getUserSharingPreselection: () => axios.get('/user-sharing-preselection'),
  setUserSharingPreselection: (fields) => axios.put('/user-sharing-preselection', { fields: fields }),
  saveLegalTermsAgreements: (product, terms, lang, agreed_at) =>
    axios.post('/me/legal-terms-agreement', { product, terms, lang, agreed_at }),
};

export default UserApi;
