import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getDisclaimerAccepted, saveDisclaimerSetting } from '../../src/utils';
import UserApi from '../services/user';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [sameSiteEntities, setSameSiteEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [loginErrorStatus, setLoginErrorStatus] = useState(null);
  const [isShadow, setIsShadow] = useState(null);
  const [isManager, setIsManager] = useState(null);
  const [isPractitioner, setIsPractitioner] = useState(null);
  const [config, setConfig] = useState(null);
  const [featureFlags, setFeatureFlags] = useState(new Set());
  const [isFeatureFlagsLoaded, setIsFeatureFlagsLoaded] = useState(false);
  const [sessionAuthToken, setSessionAuthToken] = useState(null);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [recheckForUser, setRecheckForUser] = useState(true);
  const [redirectTo, setRedirectTo] = useState(null);
  const [siteFlowsConnectors, setSiteFlowsConnectors] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [grants, setGrants] = useState(null);
  const [grantId, setGrantId] = useState(null);
  // Default site for the user
  const [siteId, setSiteId] = useState(null);
  const [legalTermsAgreements, setLegalTermsAgreements] = useState([]);

  const needUserSwitch = (userName, instance) => {
    if (!isShadow && !isManager) return false;
    if (instance.modality === 'SR') return false;

    return !user.dicom_physician_names.includes(userName);
  };

  const findEntityInCurrentSiteByDicomUserName = (dicomUserName) => {
    return sameSiteEntities.find(({ dicom_physician_names }) => dicom_physician_names.includes(dicomUserName));
  };

  const history = useHistory();
  window.reactHistory = history;

  const performRedirection = (redirectTo) => {
    window.location = redirectTo;
  };
  // Initialize
  useEffect(() => {
    if (!user || recheckForUser) {
      UserApi.getUser()
        .then((resp) => {
          const userData = resp.data.data;
          setUser(
            // TODO this auto disconnects user that do now have access to the provider app
            // we must improve the way we handle this via backend rules
            // The idea is to protect the provider APP from patient accessing it.
            // https://notion.so/sonio-ai/PTT-6874
            userData.type === 'user' || userData.type === 'service' ? userData : null
          );
          setLegalTermsAgreements(userData.legal_terms_agreement || []);
          setRecheckForUser(false);
          const hasAcceptedDisclaimer = getDisclaimerAccepted();
          setDisclaimerAccepted(hasAcceptedDisclaimer);
        })
        .catch(() => false)
        .finally(() => setLoadingInitial(false));
    }
  }, [recheckForUser]);

  useEffect(() => {
    UserApi.getSiteFlowsConnectors().then((r) => {
      setSiteFlowsConnectors(r.data);
    });
  }, [user]);

  useEffect(() => {
    // TODO: Better handle shared user while using our app
    // This is a quick fix just to redirect all shared_users that logged to sonio to logout page
    // once they change user or refresh the page
    // Attention they might need manager password if they use the shared_user session on a manager machine
    if (user?.type === 'shared_user') logout();

    if (isShadow || isManager) {
      UserApi.getManagedEntities().then(({ data: { data } }) => setSameSiteEntities(data));
    }
  }, [user, isShadow, isManager]);

  const handleSession = useCallback(async () => {
    setLoading(true);
    if (user) {
      const {
        data: { id, roles, is_shadow, feature_flags, config, auth_token, grants, grant_id, site_id },
      } = await UserApi.getSessionInfo();
      setIsManager(!!roles.length && roles.map((role) => role.slug).includes('SiteManager'));
      /* TODO fix it based on role permissions */
      setIsPractitioner(
        roles.map((role) => role.slug).includes('Practitioner') ||
          roles.map((role) => role.slug).includes('reading-provider')
      );
      setIsShadow(is_shadow);
      setConfig(config);
      setFeatureFlags(new Set(feature_flags.filter((ff) => ff?.is_enabled).map((ff) => ff.flag_name)));
      setIsFeatureFlagsLoaded(true);
      setSessionAuthToken(auth_token);
      setSessionId(id);
      setGrants(grants);
      setGrantId(grant_id);
      setSiteId(site_id);
    }
    setLoading(false);
  }, [user, UserApi.getSessionInfo]);

  useEffect(() => {
    handleSession();
    return () => {
      setIsManager(null);
      setIsShadow(null);
    };
  }, [handleSession]);

  useEffect(() => {
    if (isManager !== null && !!redirectTo) {
      if (isManager) history.push('/');
      else performRedirection(redirectTo);
      setRedirectTo(null);
    }
  }, [redirectTo, isManager, history]);

  /**
   * user management
   */
  const login = async (email, password, redirectTo = '/') => {
    setLoading(true);
    setLoginErrorStatus(null);
    try {
      const {
        data: { session_state },
      } = await UserApi.login(email, password);
      if (session_state === 'active') {
        finalize_login(redirectTo);
        return 'ok';
      }
      if (session_state === 'awaiting_mfa') {
        history.push('/login/mfa' + window.location.search);
        return 'check_mfa';
      }
    } catch ({ response: { status } }) {
      setLoginErrorStatus(status);
    } finally {
      setLoading(false);
    }
  };

  const finalize_login = async (redirectTo = '/') => {
    const {
      data: { data: entity },
    } = await UserApi.getUser();
    const hasAcceptedDisclaimer = getDisclaimerAccepted();
    setUser(entity);
    setDisclaimerAccepted(hasAcceptedDisclaimer);
    setRedirectTo(redirectTo);
  };

  const switchUserAndRedirect = (newUserId, redirectTo = '/') => {
    setLoading(true);

    UserApi.switchUser(newUserId)
      .then((resp) => {
        if (resp.data.errors || resp.data.data.state !== 'active') return false;
        setUser(resp.data.data);
        performRedirection(redirectTo);
        return true;
      })
      .finally(() => setLoading(false));
  };

  const switchUser = async (newUserId) => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await UserApi.switchUser(newUserId);
      setUser(data);
      return data;
    } finally {
      setLoading(false);
    }
  };

  const addDicomPhysicianNameToUser = async (entityId, DicomPhysicianName) => {
    setLoading(true);
    const entity = sameSiteEntities.find(({ id }) => id === entityId);
    try {
      const {
        data: { data },
      } = await UserApi.update(entityId, {
        dicom_physician_names: [...entity.dicom_physician_names, DicomPhysicianName],
      });
      return data;
    } finally {
      setLoading(false);
    }
  };
  const changeAutomaticallyShareDocuments = async (newConfig) => {
    try {
      const {
        data: { data },
      } = await UserApi.updateConfig(newConfig.id, newConfig);

      setConfig((prevConfig) => ({
        ...prevConfig,
        sharing_configuration: {
          ...prevConfig.sharing_configuration,
          automatically_share_documents: data?.sharing_configuration?.automatically_share_documents,
        },
      }));
    } catch (error) {}
  };

  const switchBackToManager = (redirectTo = '/') => {
    setLoading(true);
    UserApi.switchBackToManager()
      .then((resp) => {
        if (resp.data.errors || resp.data.data.state !== 'active') return false;
        setUser(resp.data.data);
        performRedirection(redirectTo);
        return true;
      })
      .finally(() => setLoading(false));
  };

  const logout = () => {
    UserApi.logout().then(() => {
      history.push('/login');
      setUser(false);
    });
  };

  const isFeatureFlagEnabled = (flagName) => {
    if (flagName === 'sonio.dx_v2' && featureFlags.has('soniopedia')) return true;
    if (flagName === 'sonio.dx_syndrome_summary' && featureFlags.has('soniopedia')) return true;
    return featureFlags.has(flagName);
  };

  const acceptDisclaimer = () => {
    setDisclaimerAccepted(true);
    saveDisclaimerSetting(true);
  };

  const hasAgreedToTerms = (product, terms) => {
    return legalTermsAgreements.findIndex((lta) => lta.product === product && lta.terms === terms) >= 0;
  };

  const acceptLegalTermsAgreements = (product, terms, lang, acceptedAt) => {
    setLoading(true);
    try {
      return UserApi.saveLegalTermsAgreements(product, terms, lang, acceptedAt).then((resp) => {
        setLegalTermsAgreements(resp.data.data);
      });
    } finally {
      setLoading(false);
    }
  };

  const updateUserConfig = (newConfig) => {
    setLoading(true);
    try {
      UserApi.updateConfig(newConfig.id, newConfig).then((resp) => {
        setConfig(resp.data.data);
      });
    } finally {
      setLoading(false);
    }
  };

  const getUserConfig = async () => {
    const {
      data: { config },
    } = await UserApi.getSessionInfo();
    setConfig(config);
  };

  const memoedValue = useMemo(
    () => ({
      user,
      isShadow,
      isManager,
      isPractitioner,
      config,
      featureFlags,
      isFeatureFlagsLoaded,
      sessionAuthToken,
      loading,
      loginErrorStatus,
      sameSiteEntities,
      setUser,
      login,
      finalize_login,
      switchUserAndRedirect,
      switchUser,
      switchBackToManager,
      logout,
      isFeatureFlagEnabled,
      disclaimerAccepted,
      hasAgreedToTerms,
      soniopediaTOSAccepted:
        hasAgreedToTerms('soniopedia', 'terms_of_sales') && hasAgreedToTerms('soniopedia', 'terms_of_use'),
      acceptLegalTermsAgreements,
      acceptDisclaimer,
      needUserSwitch,
      findEntityInCurrentSiteByDicomUserName,
      addDicomPhysicianNameToUser,
      setRecheckForUser,
      updateUserConfig,
      getUserConfig,
      siteFlowsConnectors,
      sessionId,
      grants,
      grantId,
      siteId,
      changeAutomaticallyShareDocuments: (value) =>
        changeAutomaticallyShareDocuments({
          id: config.id,
          automatically_share_documents: value,
        }),
    }),
    [
      user,
      isShadow,
      isManager,
      isPractitioner,
      config,
      featureFlags,
      isFeatureFlagsLoaded,
      sessionAuthToken,
      loading,
      sameSiteEntities,
      loginErrorStatus,
      disclaimerAccepted,
      sessionId,
      grants,
      grantId,
      legalTermsAgreements,
    ]
  );

  return <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function useAuth() {
  return useContext(AuthContext);
}
