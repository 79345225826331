import Cookies from 'js-cookie';
import instanceViewDrawingPaths from './instanceViewDrawingPaths.json';

export const DISCLAIMER_COOKIE = 'disclaimer_accepted';
const DISCLAIMER_COOKIE_DAYS_TO_EXPIRE = 500;
export const getDisclaimerAccepted = () => Cookies.get(DISCLAIMER_COOKIE) ?? false;
export const saveDisclaimerSetting = (value) =>
  Cookies.set(DISCLAIMER_COOKIE, value, {
    expires: DISCLAIMER_COOKIE_DAYS_TO_EXPIRE,
  });

export const getSlideUriFromTrimesterAndId = (trimester, slideId, sex, version = 2) => {
  sex = (sex + '').substr(0, 1).toLowerCase();
  if (!['m', 'f'].includes(sex)) sex = '';

  if (!isNaN(version)) version = 'v' + version;

  let view = instanceViewDrawingPaths[version]?.views.find(
    (view) => view.id === slideId && view.trimester.includes(trimester) && view.sex.includes(sex)
  );
  if (!view) view = instanceViewDrawingPaths[version]?.default;
  if (!view) view = instanceViewDrawingPaths?.default;

  return view?.path ? `${process.env.PUBLIC_URL}/slides/${view.path}` : false;
};

export const getInstanceThumbnailUri = (id) => {
  return `/api/v2/dicom-instance/${id}/thumbnail`;
};

export const getInstanceVideoUri = (id) => {
  return `/api/v2/dicom-instance/${id}/video`;
};

export const getInstancePreviewUri = (id, fallbackToThumbnail) => {
  return `/api/v2/dicom-instance/${id}/preview?fallback=${fallbackToThumbnail}`;
};

export const getDeidentifiedInstanceThumbnailUri = (id) => {
  return `/api/v2/dicom-instance/${id}/deidentified-thumbnail`;
};
export const getDeidentifiedInstanceVideoUri = (id) => {
  return `/api/v2/dicom-instance/${id}/deidentified-video`;
};

export const getDeidentifiedInstancePreviewUri = (id, fallbackToThumbnail) => {
  return `/api/v2/dicom-instance/${id}/deidentified-preview?fallback=${fallbackToThumbnail}`;
};

/**
 * Return locale date string with 2 digits for day and month and 4 digits for the Year.
 * For example, for french localisation : DD/MM/YYYY
 * @param {*} dateStr any format that new Date(dateStr) can handle
 * @returns {string}
 */
export const formatDate = (dateStr, localeOrFormat = 'fr') => {
  if (localeOrFormat === 'dd/mm/yyyy') localeOrFormat = 'fr';
  if (localeOrFormat === 'mm/dd/yyyy') localeOrFormat = 'en-US';
  const date = new Date(dateStr);
  if (isNaN(date)) return '';

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.toLocaleDateString([localeOrFormat], {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatDateWithTZ = (dateStr, localeOrFormat = 'fr') => {
  if (localeOrFormat === 'dd/mm/yyyy') localeOrFormat = 'fr';
  if (localeOrFormat === 'mm/dd/yyyy') localeOrFormat = 'en-US';
  const date = new Date(dateStr);
  if (isNaN(date)) return '';

  return date.toLocaleDateString([localeOrFormat], {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

/**
 * Return locale date string with 2 digits for day and month and 4 digits for the Year, and time as 2 digits : 2 digits.
 * For example, for french localisation : DD/MM/YYYY HH:MM
 * @param {*} dateStr any format that new Date(dateStr) can handle
 * @returns {string}
 */
export const formatDateTime = (dateStr, localeOrFormat = 'fr') => {
  if (localeOrFormat === 'dd/mm/yyyy') localeOrFormat = 'fr';
  if (localeOrFormat === 'mm/dd/yyyy') localeOrFormat = 'en-US';
  const date = new Date(dateStr);
  return (
    date.toLocaleDateString([localeOrFormat], {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    (date.getHours() + '').padStart(2, '0') +
    ':' +
    (date.getMinutes() + '').padStart(2, '0')
  );
};

/**
 * Returns the HH:mm time in the local timezone from the given naive date string.
 * Since the date is naive, the UTC timezone is assumed.
 * @param {*} naiveDateStr is a naive date string in the `2025-02-18T17:06:46` ISO 8601 format.
 * @returns {string}
 */
export const formatTime = (naiveDateStr) => {
  const forcedUTC = new Date(naiveDateStr + 'Z');
  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: 'UTC',
  });
  return timeFormatter.format(forcedUTC);
};

export const formatDateTimeWithTZ = (dateStr, localeOrFormat = 'fr') => {
  if (!dateStr) return false;
  if (localeOrFormat === 'dd/mm/yyyy') localeOrFormat = 'fr';
  if (localeOrFormat === 'mm/dd/yyyy') localeOrFormat = 'en-US';

  const dateAddMinutes = (date, minutes) => {
    if (!(date instanceof Date)) return false;

    const ret = new Date(date);

    ret.setTime(ret.getTime() + minutes * 60000);

    return ret;
  };

  const date = new Date(dateStr);
  const utcDate = dateAddMinutes(date, date.getTimezoneOffset() * -1);

  return (
    utcDate.toLocaleDateString([localeOrFormat], {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) +
    ' ' +
    (utcDate.getHours() + '').padStart(2, '0') +
    ':' +
    (utcDate.getMinutes() + '').padStart(2, '0')
  );
};

export const convertTimeZone = (utcDatetime, toTz) => {
  const date = new Date(utcDatetime);
  if (isNaN(date.getTime())) return '';

  const d = date.toLocaleString('fr', toTz ? { timeZone: toTz } : {});

  const formattedDate = `${[d.substr(6, 4), d.substr(3, 2), d.substr(0, 2)].join('-')} ${[
    d.substr(11, 2),
    d.substr(14, 2),
  ].join(':')}`;

  return formattedDate;
};

/**
 * Format a date string from dd/mm/yyyy to mm/dd/yyyy and viceversa
 */
export const formatDateSwapDdMm = (dateStr) => {
  dateStr = dateStr.replace(/[^\d+]/g, '/');
  const dateArr = dateStr.split('/');
  for (let i = 0; i < 3; i++) {
    dateArr[i] = dateArr[i] ?? '';
  }
  return `${dateArr[1]}/${dateArr[0]}/${dateArr[2]}`.replace(/\/+$/, '');
};

export const formatYYYYMMDDDate = (dateStr, localeOrFormat = 'fr') => {
  if (localeOrFormat === 'en-US') localeOrFormat = 'mm/dd/yyyy';
  if (localeOrFormat === 'fr') localeOrFormat = 'dd/mm/yyyy';

  let normalizedDateStr = dateStr;

  if (normalizedDateStr instanceof Date) {
    normalizedDateStr = new Date(
      normalizedDateStr.getTime() - normalizedDateStr.getTimezoneOffset() * 60000
    ).toISOString();
    normalizedDateStr = normalizedDateStr.substr(0, 10);
  }

  normalizedDateStr = normalizedDateStr.replace('/', '-');

  if (!`${normalizedDateStr}`.match(/....-..-...?/)) return dateStr;
  const [year, month, day] = `${normalizedDateStr}`.substr(0, 10).split('-');
  return localeOrFormat === 'mm/dd/yyyy' ? `${month}/${day}/${year}` : `${day}/${month}/${year}`;
};

/**
 * Return a JS Date obj, with hour set at midday.
 * @param {DD/MM/YYYY} dateStr a french formatted date string
 * @returns {Date}
 */
export const fromStrToDate = (dateStr, localeOrFormat = 'fr') => {
  if (localeOrFormat === 'en-US') localeOrFormat = 'mm/dd/yyyy';
  if (localeOrFormat === 'fr') localeOrFormat = 'dd/mm/yyyy';

  let [day, month, year] = dateStr.substr(0, 10).split('/');
  if (localeOrFormat === 'mm/dd/yyyy') [month, day, year] = dateStr.split('/');

  // We set hour to midday to avoid day gap on ISO outputs
  return new Date(Number(year), Number(month) - 1, Number(day), 12);
};

// Return the current UTC date and time in this format: "07/01/2024 01:48 PM UTC"
// the date will be converted according to the prefered date and time format
export const getUTCTimeString = (localeOrFormat, preferedTimeFormat) => {
  const utcDate = new Date();

  const utcDateString = [
    utcDate.getUTCFullYear(),
    `0${utcDate.getUTCMonth() + 1}`.slice(-2),
    `0${utcDate.getUTCDate()}`.slice(-2),
  ].join('-');

  const utcTimeString = preferedTimeFormat
    .replace(
      'HH',
      `0${preferedTimeFormat.includes('XM') ? ((utcDate.getUTCHours() - 1) % 12) + 1 : utcDate.getUTCHours()}`.slice(-2)
    )
    .replace('MM', `0${utcDate.getUTCMinutes()}`.slice(-2))
    .replace('SS', `0${utcDate.getUTCSeconds()}`.slice(-2))
    .replace('XM', utcDate.getUTCHours() < 12 ? 'AM' : 'PM');

  return `${formatYYYYMMDDDate(utcDateString, localeOrFormat)} ${utcTimeString} UTC`;
};

/**
 * returns -1 if the first date is lower than the second,
 * 1 if the first date is higher than the first,
 * 0 if they are equal
 * false if any date is false, null or undefined
 */
export const compareDates = (d1, d2) => {
  if (!d1 || !d2) return false;
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  }
  return 0;
};

/**
 * get the patient age at a given time
 */
export const getPatientAge = (dob, now) => {
  if (!dob) return false;

  const dobDate = new Date(`${dob}`.substr(0, 10));

  let nowDate = false;
  if (now) {
    nowDate = new Date(`${now}`.substr(0, 10));
  } else {
    nowDate = new Date();
  }

  const subtractYears =
    dobDate.getMonth() < nowDate.getMonth() ||
    (dobDate.getMonth() === nowDate.getMonth() && dobDate.getDate() <= nowDate.getDate())
      ? 0
      : 1;
  const diffYears = nowDate.getFullYear() - dobDate.getFullYear();

  return Number(diffYears) - Number(subtractYears);
};

/**
 * unit conversions
 */
export const convertInchToMm = (inch) => {
  return convertCmToMm(convertInchToCm(inch));
};

export const convertInchToCm = (inch) => {
  return inch * 2.54;
};

export const convertInchToFeet = (inch) => {
  return inch / 12;
};

export const convertFeetToInch = (feet) => {
  return feet * 12;
};

export const convertFeetToCm = (feet) => {
  return convertInchToCm(convertFeetToInch(feet));
};

export const convertFeetToMm = (feet) => {
  return convertCmToMm(convertInchToCm(convertFeetToInch(feet)));
};

export const convertMmToInch = (mm) => {
  return convertCmToInch(convertMmToCm(mm));
};

export const convertMmToFeet = (mm) => {
  return convertInchToFeet(convertCmToInch(convertMmToCm(mm)));
};

export const convertCmToInch = (cm) => {
  return cm / 2.54;
};

export const convertCmToFeet = (cm) => {
  return convertInchToFeet(convertCmToInch(cm));
};

export const convertLbsToKg = (lbs) => {
  return lbs / 2.205;
};

export const convertLbsToG = (lbs) => {
  return convertLbsToKg(lbs) * 1000;
};

export const convertKgToLbs = (kg) => {
  return kg * 2.205;
};

export const convertGToLbs = (g) => {
  return convertKgToLbs(g / 1000);
};

export const convertMmToCm = (mm) => {
  return mm / 10;
};

export const convertCmToMm = (cm) => {
  return cm * 10;
};

/**
 * Convert a value from one unit to another
 * @param {number} value
 * @param {string} unitFrom
 * @param {string} unitTo
 */
export const convertValueToSelectedUnit = (value, unitFrom, unitTo) => {
  if (!value || isNaN(value)) return value;

  if (unitFrom === '"') unitFrom = 'inches';
  if (unitTo === '"') unitTo = 'inches';
  if (unitFrom === 'inch') unitFrom = 'inches';
  if (unitTo === 'inch') unitTo = 'inches';
  if (unitFrom === 'ft') unitFrom = 'feet';
  if (unitTo === 'ft') unitTo = 'feet';
  if (unitTo === 'pounds') unitTo = 'lbs';
  if (unitTo === 'pound') unitTo = 'lbs';
  if (unitTo === 'lb') unitTo = 'lbs';

  if (unitFrom === unitTo) return value;
  const conversionFunctions = {
    mm: {
      cm: convertMmToCm,
      inches: convertMmToInch,
      feet: convertMmToFeet,
    },
    cm: {
      mm: convertCmToMm,
      inches: convertCmToInch,
      feet: convertCmToFeet,
    },
    inches: {
      mm: convertInchToMm,
      cm: convertInchToCm,
      feet: convertInchToFeet,
    },
    feet: {
      inches: convertFeetToInch,
      mm: convertFeetToMm,
      cm: convertFeetToCm,
    },
    lbs: {
      g: convertLbsToG,
      kg: convertLbsToKg,
    },
    g: {
      lbs: convertGToLbs,
    },
    kg: {
      lbs: convertKgToLbs,
    },
  };

  const func = conversionFunctions[unitFrom]?.[unitTo];
  if (func) return func(value);
  return value;
};

/**
 * returns the regexp object to match the given string including variations of accented letters
 * @param {string} string
 * @returns {RegExp}
 */
export const getRegExpValue = (string) => {
  const value = '(' + string.trim() + ')';
  let expression = '';

  // all chars variations supported
  const charmap = ['aàáâãä', 'cç', 'eèéêë', 'iìíîï', 'nñ', 'oòóôõö', 'uùúûü', 'yýÿ'];

  // create expressions for each char
  const charexp = [];
  [...charmap].forEach((chars) => {
    let exp = '(?:';
    [...chars].forEach((char) => {
      exp += char + '|';
    });
    exp += chars[0] + '\\p{M})';
    charexp.push(exp);
  });

  // substitute chars in query string
  [...value].forEach((char) => {
    const index = charmap.findIndex((elm) => elm.indexOf(char) > -1);
    if (index > -1) char = charexp[index];
    expression += char;
  });

  // replace spaces with " .*?" to match single words
  expression = expression.replace(/ /g, ' .*?');

  return new RegExp(expression, 'gi');
};

/**
 * Hook to return the parsed url query parameters.
 */
export const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

export const isNullOrUndefined = (value) => value === undefined || value === null;

export const isMobileDevice = () => {
  let check = false;
  // eslint-disable-next-line
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const deepMerge = (target, source) => {
  const result = { ...(target || {}), ...(source || {}) };
  for (const key of Object.keys(result)) {
    result[key] =
      typeof target?.[key] === 'object' && typeof source?.[key] === 'object'
        ? deepMerge(target[key], source[key])
        : structuredClone(result[key]);
  }
  return result;
};

export const getCurrentLanguageCode = () => {
  const currentLanguage =
    localStorage.getItem('i18nextLng')?.toLowerCase() || navigator.language || navigator.userLanguage;

  switch (currentLanguage.substr(0, 2)) {
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'pt':
      return 'ptbr';
    default:
      return 'en';
  }
};

export const joinClasses = (list) => {
  if (!Array.isArray(list)) return '';

  return list.filter((i) => i != null && i !== false).join(' ');
};

export const timeFormatter = (militaryTime, format) => {
  const time = militaryTime.split(':');
  const hours = Number(time[0]);
  const minutes = Number(time[1]);
  const seconds = Number(time[2]);

  let timeValue = '';

  switch (format) {
    case 'HH:MM:SS':
      timeValue +=
        (hours + '').padStart(2, '0') + ':' + (minutes + '').padStart(2, '0') + ':' + (seconds + '').padStart(2, '0');
      break;
    case 'HH:MM':
      timeValue += (hours + '').padStart(2, '0') + ':' + (minutes + '').padStart(2, '0');
      break;
    case 'HH:MM:SS XM':
      if (hours > 0 && hours <= 12) {
        timeValue = '' + (hours + '').padStart(2, '0');
      } else if (hours > 12) {
        timeValue = '' + (hours - 12 + '').padStart(2, '0');
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += ':' + (minutes + '').padStart(2, '0');
      timeValue += ':' + (seconds + '').padStart(2, '0');
      timeValue += hours >= 12 ? ' PM' : ' AM';
      break;
    case 'HH:MM XM':
      if (hours > 0 && hours <= 12) {
        timeValue = '' + (hours + '').padStart(2, '0');
      } else if (hours > 12) {
        timeValue = '' + (hours - 12 + '').padStart(2, '0');
      } else if (hours === 0) {
        timeValue = '12';
      }
      timeValue += ':' + (minutes + '').padStart(2, '0');
      timeValue += hours >= 12 ? ' PM' : ' AM';
      break;

    default:
      timeValue = militaryTime;
      break;
  }

  return timeValue;
};

export const isGaInTrimester = (min_ga, max_ga, trimester) => {
  min_ga = min_ga || 0;
  max_ga = max_ga || 41;

  const ga_start = { T1: 0, T2: 14, T3: 26 };
  const ga_end = { T1: 13, T2: 25, T3: 41 };

  const allowed_trimesters = [
    ['T1', min_ga <= ga_end.T1],
    [
      'T2',
      (min_ga <= ga_start.T2 && max_ga >= ga_end.T2) ||
        (min_ga <= ga_start.T2 && max_ga > ga_start.T2) ||
        (min_ga <= ga_end.T2 && max_ga >= ga_end.T2) ||
        (min_ga >= ga_start.T2 && max_ga <= ga_end.T2),
    ],
    ['T3', max_ga >= ga_start.T3],
  ];

  return allowed_trimesters
    .filter((t) => t[1])
    .map((t) => t[0])
    .includes(trimester);
};

const findCheckListItemExaminationFetus = (checklistItem, fetuses) => {
  if (!fetuses) return null;
  if (!checklistItem.examination_fetus_id) return null;
  if (fetuses.length <= 1) return null;
  if (checklistItem.examination_fetus) return checklistItem.examination_fetus;
  return fetuses.find((f) => f.id === checklistItem.examination_fetus_id);
};

export const formatCheckListItemLabel = (checklistItem, currentLanguage, __, fetuses) => {
  const checklistItemBaseLabel = checklistItem.label?.[currentLanguage] || checklistItem.id;

  const examination_fetus = findCheckListItemExaminationFetus(checklistItem, fetuses);

  if (!examination_fetus) return checklistItemBaseLabel;

  const fetusLabel = __('checkListItem.fetus') + ' ' + examination_fetus.fetus.label;
  return checklistItemBaseLabel + ': ' + fetusLabel;
};

export const LevenshteinDistance = function (a, b) {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  // increment along the first column of each row
  let i;
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  let j;
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1
          )
        ); // deletion
      }
    }
  }

  return matrix[b.length][a.length];
};

let uniqueIdCounter = 0;
export const getUniqueId = () => {
  return 'UID' + uniqueIdCounter++;
};

// Does a DFS through the object and replaces all keys that match the pattern with the replacement.
export const replaceAllKeys = (obj, pattern, replacement) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((val) => replaceAllKeys(val, pattern, replacement));
  }

  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = key.replaceAll(pattern, replacement);
    const val = obj[key];

    newObj[newKey] = typeof val === 'object' ? replaceAllKeys(val, pattern, replacement) : val;

    return newObj;
  }, {});
};

const preloadedFiles = [];

export const preloadFiles = async (files, type) => {
  await Promise.all(
    files.map(async (file) => {
      if (type === 'image') return await preloadImage(file);
      if (type === 'video') return await preloadVideo(file);
    })
  );
};

const preloadImage = (src) => {
  if (preloadedFiles.includes(src)) return;
  preloadedFiles.push(src);
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      image.parentNode.removeChild(image);
      resolve();
    };
    image.onerror = reject;
    image.src = src;
  });
};

const preloadVideo = (src) => {
  if (preloadedFiles.includes(src)) return;
  preloadedFiles.push(src);
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onload = () => {
      video.parentNode.removeChild(video);
      resolve();
    };
    video.onerror = reject;
    video.src = src;
  });
};

// Generates a random string of the given length
export const generateRandomString = (N = 8) => {
  const s = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array(N)
    .join()
    .split(',')
    .map(function () {
      return s.charAt(Math.floor(Math.random() * s.length));
    })
    .join('');
};

export const jsonParse = (str) => {
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return false;
  }
};

// This functions returns a function responsible for batching calls to the fn function.
// The delay parameter is the time to wait before executing the batched calls.
// The function returns a promise that resolves when the batched calls are done.
// When calls occur while the batch is being executed, they are queued
export function batchRequests(fn, delay) {
  let isRequestInProgress = false;
  let isRequestBatching = false;
  let batchedPromises = [];
  let queuedPromises = [];
  const call = function (...args) {
    // If there's already a request in progress,
    // enqueue the request and promise callbacks for later
    if (isRequestInProgress) {
      return new Promise((resolve, reject) => {
        queuedPromises.push({
          resolve,
          reject,
          call: () => call(...args),
        });
      });
    }

    // If the requests are being batched
    // enqueue the promise callbacks for when the batch is resolved
    // without actually calling fn
    if (isRequestBatching) {
      return new Promise((resolve, reject) => {
        batchedPromises.push({ resolve, reject });
      });
    }

    return new Promise((resolve, reject) => {
      isRequestBatching = true;

      setTimeout(() => {
        isRequestInProgress = true;
        isRequestBatching = false;

        fn(...args)
          .then((response) => {
            // Resolve all pending promises with the same response
            batchedPromises.forEach(({ resolve: resolveFn }) => resolveFn(response));

            resolve(response);
          })
          .catch((error) => {
            // Reject all pending promises if the request fails
            batchedPromises.forEach(({ reject: rejectFn }) => rejectFn(error));

            reject(error);
          })
          .finally(() => {
            batchedPromises = []; // Clear the batch

            isRequestInProgress = false;

            // If there are queued requests, trigger them all
            queuedPromises.forEach(({ call, resolve, reject }) => call().then(resolve).catch(reject));
            queuedPromises = [];
          });
      }, delay);
    });
  };
  return call;
}

export function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

/*
 * @desc Returns a string representation of the object that is more consisten than JSON.stringify for object content comparison
 */
export function nestedObjectStringify(obj) {
  if (Array.isArray(obj)) return obj.map(nestedObjectStringify).join(', ');
  if (typeof obj === 'object')
    return Object.entries(obj || {})
      .sort(([key1], [key2]) => key1.localeCompare(key2))
      .map(([key, value]) => `${key}: ${nestedObjectStringify(value)}`)
      .join(', ');

  return `${obj}`;
}

/*
 * @desc Returns wether we can open the Checklist items view
 */
export function checklistItemsIsReady(placeholders, reportVersion) {
  if (reportVersion !== '2.0.0') {
    return true;
  }
  return !!placeholders['fetus.order'];
}
