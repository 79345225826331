import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { filesize } from 'filesize';

import Checkbox from '../../atoms/CheckboxInput/CheckboxInput';
import ToggleCheckbox from '../../atoms/Checkbox/Checkbox';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import ResourceApi from '../../services/resource';
import './SharingPreferencesView.css';
import PreselectOptions from './PreselectOptions';
import uploadFilePopupHOC from '../../hocs/uploadFilePopupHOC';
import deletePopupHOC from './deletePopupHOC';
import { useExamSharingState } from '../../providers/examSharing';
import loadGeneralExamAssocAttachments from '../../services/loadGeneralExamAssocAttachments';
import updateAttachment from '../../services/updateAttachment';
import changeStatusPreferenceGeneralDoc from '../../providers/examSharing/actions/changeStatusPreferenceGeneralDoc';
import PrintingPageDefaultOptions from './PrintingPageDefaultOptions';

export const PERFORMING_PHYSICIAN_NAME_LABEL = 'performingPhysicianName';
export const DEVICE_SERIAL_NUMBER_LABEL = 'deviceSerialNumber';

const UploadButton = uploadFilePopupHOC(Button);
const DeleteButton = deletePopupHOC(Icon);

const trackEvent = (type) => {
  const info = {
    event_type: type,
    source: 'core',
    page_url: window.location.href,
  };
  ResourceApi.createBIEvent(info);
};

const SharingPreferencesView = ({ t: __ }) => {
  const appContext = useContext(AppContext);
  const { preferenceGeneralDocuments } = useExamSharingState();
  const { isFeatureFlagEnabled, user, config, changeAutomaticallyShareDocuments } = useAuth();
  const [site, setSite] = useState(null);
  const [updateError, setUpdateError] = useState(false);
  const isAutoShare = config?.sharing_configuration?.automatically_share_documents;
  const toggleAutoShare = () =>
    changeAutomaticallyShareDocuments(!config?.sharing_configuration?.automatically_share_documents);

  useEffect(() => {
    getAttachments();
  }, []);

  useEffect(() => {
    ResourceApi.getSite().then((resp) => {
      setSite(resp.data.data.find((s) => s.id === appContext.preferences.last_used_site_id));
    });
  }, [appContext.preferences]);

  const getAttachments = () => loadGeneralExamAssocAttachments();
  const submitAttachment = async ({ files, addServerError, addStatusFile }) => {
    return new Promise((resolve) => {
      const promises = files
        .map(({ file, title }) => {
          const formData = new FormData();
          formData.append('attachment[file]', file);
          formData.append('attachment[title]', title);
          return formData;
        })
        .map((formData, index) =>
          ResourceApi.uploadAttachment(formData)
            .then(() => new Promise((resolve) => setTimeout(resolve, 400)))
            .then(() => addStatusFile(files[index].title))
            .catch((err) => {
              console.log('err', err);
              const errText = (err.response.data.errors.title || []).join(';\n');
              addServerError(errText);
              throw new Error(errText);
            })
        );
      Promise.all(promises).finally(resolve);
    });
  };

  const downloadAttachment = (e, attachment) => {
    ResourceApi.downloadAttachment(attachment.id).then((response) => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const fileName = `${attachment.original_filename}.pdf`;
      const a = document.createElement('a');
      const url = window.URL.createObjectURL(response.data);
      a.href = url;
      if (!isMobile) a.setAttribute('download', fileName);
      a.click();
      a.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 10000);
    });
  };

  const processErrorMessages = (response, errorFieldSetter) => {
    const space_quote_regex = /[ ']/g;
    const errors = response.data.errors;

    if (Object.keys(errors).includes('title')) {
      const translationKey = errors.title[0].replace(space_quote_regex, '_');
      errorFieldSetter(__(`sharingPreferences.document.title.${translationKey}`));
    } else if (typeof errors === 'string' || errors instanceof String) {
      // https://stackoverflow.com/a/9436948
      const translationKey = errors;
      errorFieldSetter(__(`sharingPreferences.document.upload.${translationKey}`));
    } else {
      // output 1st error
      const field = Object.keys(errors)[0];
      const translationKey = errors[field][0].replace(space_quote_regex, '_');
      errorFieldSetter(__(`sharingPreferences.document.${field}.${translationKey}`));
    }
  };

  const toggleDocumentEnabled = (attachment) => {
    if (attachment.id) {
      updateAttachment(attachment, processErrorMessages);
    } else {
      changeStatusPreferenceGeneralDoc({ ...attachment, enabled_by_default: !attachment.enabled_by_default });
    }
  };

  return (
    !!user &&
    !!site && (
      <div className="configuration-container">
        <div className="row">
          <div className="w12 title">
            <h1>{__('sharingPreferences.title')}</h1>
            <small className="sub-title italic">{__('sharingPreferences.description')}</small>
          </div>
        </div>
        <PrintingPageDefaultOptions />
        <PreselectOptions />
        <hr className="section-end" />
        {isFeatureFlagEnabled('sonio.attachments') && (
          <div className="row">
            <div className="filter-list-container">
              <h2 className="share-document-label">{__('sharingPreferences.documents')}</h2>
              <small className="sub-title">{__('sharingPreferences.documentsDescription')}</small>
              {preferenceGeneralDocuments.map((attachment) => (
                <div className="attachment-item" key={attachment.id || attachment.title}>
                  <Checkbox
                    checked={attachment.enabled_by_default}
                    onClick={() => toggleDocumentEnabled(attachment)}
                    variant="secondary"
                  />
                  <span className="attachment-title">
                    {`${attachment.title} (${filesize(attachment.filesize, { standard: 'jedec' })} PDF)`}
                  </span>
                  <Icon name="download" className="download-icon" onClick={(e) => downloadAttachment(e, attachment)} />
                  <DeleteButton
                    name="trash"
                    className="delete-icon"
                    attachment={attachment}
                    setUpdateError={setUpdateError}
                  />
                </div>
              ))}
              {updateError && (
                <div className="error-message">
                  <Icon name="warning" />
                  {updateError}
                </div>
              )}
              <UploadButton
                className="upload-document-button"
                variant="contained"
                icon="upload"
                label={__('sharingPreferences.uploadDocument')}
                onSuccesfulUpload={() => {}}
                loadNewList={getAttachments}
                submit={(obj) => {
                  const res = submitAttachment(obj);
                  trackEvent('user_uploads_a_general_document');
                  return res;
                }}
              />
              <ToggleCheckbox
                checked={isAutoShare}
                beforeLabel={__(`sharingPreferences.document.autoShare`)}
                onChange={toggleAutoShare}
              />
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default withTranslation()(SharingPreferencesView);

SharingPreferencesView.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
