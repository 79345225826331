import axios from 'axios';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import config from '../../config';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import { LiveExaminationContext } from '../../context-providers/LiveExamination';
import { getMedicalHistory } from '../../services/examination';
import LookupApi from '../../services/lookup';
import ResourceApi from '../../services/resource';
import { getInstanceVideoUri, isNullOrUndefined, preloadFiles } from '../../utils';
import { convertValueToSelectedUnit } from '../../unitConverter';
import ExaminationView from './ExaminationView';
import useAuth from '../../context-providers/Auth';
import setSharingImages from '../../providers/examSharing/actions/setSharingImages';
import setSharingVideos from '../../providers/examSharing/actions/setSharingVideos';

const Examination = ({ t: __, naked = null, nakedProps = {} }) => {
  const { examId } = useParams();
  const examinationContext = useContext(ExaminationContext);
  const liveExaminationContext = useContext(LiveExaminationContext);
  const appContext = useContext(AppContext);
  const currentPreset = examinationContext.examination?.preset_id;
  const currentTrimester = useMemo(
    () => appContext.allPresets?.find((preset) => preset.id === currentPreset)?.trimester,
    [currentPreset, appContext.allPresets]
  );
  const currentTemplateId = examinationContext.examination?.template_id;
  const fetusSex = examinationContext.examination?.medical_history
    ? examinationContext.examination.medical_history['medicalexam.fetus.sex']?.value
    : '';
  const preferedUnit = appContext.preferences.units;

  const [medications, setMedications] = useState([]);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [medias, setMedias] = useState([]);
  const [checklistItems, setChecklistItems] = useState([]);
  const [checklistItemsRiskFactors, setChecklistItemsRiskFactors] = useState({});
  const [slides, setSlides] = useState([]);
  const [slidesRiskFactors, setSlidesRiskFactors] = useState({});
  const [instanceViews, setInstanceViews] = useState([]);
  const [nextMatchingSlide, setNextMatchingSlide] = useState(false);
  const [manualUserInteraction, setManualUserInteraction] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [isPrintable, setIsPrintable] = useState(false);
  const [anomalies, setAnomalies] = useState([]);
  const [unusualMedias, setUnusualMedias] = useState([]);
  const [assocInstanceExam, setAssocInstanceExam] = useState();
  const { isFeatureFlagEnabled } = useAuth();

  const loadExaminationAccordingUrl = useCallback(async () => {
    if (examId) {
      await examinationContext.loadExamination(examId);
      liveExaminationContext.track(examId);
    }
  }, [examId]);

  useEffect(() => {
    loadExaminationAccordingUrl();
  }, [loadExaminationAccordingUrl]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const initSlides = async () => {
      if (currentTemplateId && currentTrimester) {
        let instanceViews = await examinationContext.getInstanceViewsForTemplate(source).catch(function (thrown) {
          if (axios.isCancel(thrown)) {
            console.log('Request canceled', thrown.message);
          } else {
            throw thrown;
          }
        });
        const slides = instanceViews
          .filter((slide) => slide.type === 'picture')
          .map((slide) => {
            if (slide.medias[currentTrimester] === null) {
              slide = {
                ...slide,
                medias: { ...slide.medias, [currentTrimester]: 0 },
              };
            }
            return slide;
          });

        const other = instanceViews.filter((slide) => slide.type === 'other');
        if (other.length) {
          for (const otherSlide of other) {
            slides.push(otherSlide);
          }
        }

        const displayedSlides = [];
        for (const [, slide] of slides.entries()) {
          displayedSlides[slide.idx_in_template] = [...Array(slide.medias[currentTrimester])].map((_, index) => ({
            ...slide,
            idx_in_group: index,
            key: `${slide.idx_in_template}_${slide.id}_${index}`,
            unusual: unusualMedias.includes(slide.mediaId),
            mediaId: false,
          }));
        }

        for (const media of medias) {
          /** optional medias has idx_in_template and idx_in_group set as null */
          const slideId = Number(media?.slideId || 39);
          // TODO: is there a better way to handle this than set it to 1000?
          const idx_in_template = media?.idx_in_template ?? 1000;
          const idx_in_group = media?.idx_in_group ?? 0;

          if (isNullOrUndefined(displayedSlides[idx_in_template])) displayedSlides[idx_in_template] = [];

          let slideIndexInGroup = 0;
          if (!isNullOrUndefined(idx_in_group) && !displayedSlides[idx_in_template][idx_in_group]?.mediaId) {
            slideIndexInGroup = idx_in_group;
          } else {
            const nextIndex = displayedSlides[idx_in_template].findIndex((slide) => !slide?.mediaId);
            slideIndexInGroup = nextIndex >= 0 ? nextIndex : displayedSlides[idx_in_template].length;
          }

          if (isNullOrUndefined(displayedSlides[idx_in_template][slideIndexInGroup])) {
            displayedSlides[idx_in_template][slideIndexInGroup] = {
              ...examinationContext.instanceViews.filter((slide) => slide.id === slideId)?.[0],
              ...(slides.find((slide) => slide.idx_in_template === idx_in_template) ??
                slides.find((slide) => slide.id === slideId)),
              idx_in_group: slideIndexInGroup,
              idx_in_template: idx_in_template,
              key: `${idx_in_template}_${slideId}_${slideIndexInGroup}`,
              unusual: unusualMedias.includes(media.id),
              mediaId: media.id,
              completed: true,
              // For optional views, the slideId will be set and not be the other slide (id 39)
              optional:
                !!media &&
                isNullOrUndefined(media.idx_in_template) &&
                !isNullOrUndefined(media.slideId) &&
                media.slideId !== 39,
            };
          } else {
            displayedSlides[idx_in_template][slideIndexInGroup].idx_in_template = idx_in_template;
            displayedSlides[idx_in_template][slideIndexInGroup].mediaId = media.id;
            displayedSlides[idx_in_template][slideIndexInGroup].unusual = unusualMedias.includes(media.id);
            displayedSlides[idx_in_template][slideIndexInGroup].completed = true;
            displayedSlides[idx_in_template][slideIndexInGroup].optional =
              !!media && isNullOrUndefined(media.idx_in_template) && !isNullOrUndefined(media.slideId);
          }
        }

        let displayedSlidesFlat = displayedSlides.flat();

        // always have an empty other slide for UX purposes
        if (!displayedSlidesFlat.some((slide) => slide.type === 'other' && !slide.mediaId)) {
          const slideOther = displayedSlidesFlat.find((slide) => slide.type === 'other');
          const idxInGroup = displayedSlidesFlat.filter((slide) => slide.type === 'other').length;
          if (slideOther) {
            displayedSlidesFlat.push({
              ...slideOther,
              mediaId: false,
              idx_in_group: idxInGroup,
              key: `${slideOther.idx_in_template}_${slideOther.id}_${idxInGroup}_`,
            });
          }
        }

        displayedSlidesFlat = displayedSlidesFlat.map((slide) => ({
          ...slide,
          view_count: displayedSlidesFlat.filter((s) => s?.id === slide.id).length,
          idx_in_view: displayedSlidesFlat.filter((s) => s?.id === slide.id).findIndex((s) => s.key === slide.key),
        }));

        displayedSlidesFlat = displayedSlidesFlat.map((slide) => {
          const media = medias.find((media) => media.id === slide.mediaId);
          return {
            ...slide,
            techno: slide.techno || slide.default_techno,
            inserted_at: media?.inserted_at,
            is_video: media?.dicom_media_type === 'video',
            dicom_media_type: media?.dicom_media_type,
            verified: media?.verified,
          };
        });

        instanceViews = instanceViews
          .filter((slide) => !!slide.id)
          .map((slide) => ({
            ...slide,
            mediaIds: medias
              .filter((media) => ['image', 'video'].includes(media.dicom_media_type) && media.slideId === slide.id)
              .map((media) => media.id),
          }));
        setSharingVideos(medias.filter((slide) => slide.dicom_media_type === 'video'));
        setInstanceViews(instanceViews);
        setSlides(displayedSlidesFlat);
      }
    };

    initSlides();

    return () => {
      source.cancel('Operation canceled. UseEffect cleanup');
    };
  }, [medias, currentTemplateId, currentTrimester, unusualMedias, examinationContext.getInstanceViewsForTemplate]);

  useEffect(() => {
    LookupApi.getExamItem().then((resp) => {
      setChecklistItems(resp.data.data);
    });
  }, []);

  useEffect(() => {
    setNextMatchingSlide(false);
    setManualUserInteraction(false);
  }, [examinationContext.examination.id]);

  useEffect(() => {
    if (isFeatureFlagEnabled('sonio.video_preload')) {
      /* preload videos */
      const videos = medias.filter((m) => m.dicom_media_type === 'video').map((m) => getInstanceVideoUri(m.id));
      preloadFiles(videos, 'video');
    }
  }, [
    medias
      .map((m) => m.id)
      .sort((a, b) => a - b)
      .join(','),
  ]);

  useEffect(() => {
    if (!examinationContext.examination?.medical_history?.['teratogenicrisks.medications']) return false;

    const value = examinationContext.examination.medical_history['teratogenicrisks.medications'].value;
    const missingMedications = value.reduce(
      (ids, id) => (!medications.find((m) => m.id === id) ? [...ids, id] : ids),
      []
    );
    if (!missingMedications.length) return false;
    LookupApi.getMedicationsByIds(missingMedications).then((results) =>
      setMedications([...medications, ...results.data])
    );
    return true;
  }, [examinationContext.examination]);

  useEffect(() => {
    setMedicalHistory(
      getMedicalHistory(
        examinationContext.examination.medical_history,
        examinationContext.medicalHistoryItems,
        medications,
        __,
        preferedUnit,
        convertValueToSelectedUnit
      )
    );
  }, [
    medications,
    JSON.stringify(examinationContext.examination.medical_history),
    JSON.stringify(examinationContext.medicalHistoryItems),
  ]);

  useEffect(() => {
    setAnomalies(
      examinationContext.examination?.exam_items?.reduce(
        (ids, item) => (item.status === 'unusual' ? [...ids, item.id] : ids),
        []
      ) || []
    );
  }, [JSON.stringify(examinationContext.examination?.exam_items)]);

  const isInstanceUnusual = useCallback(
    (instance) => {
      // view evaluation
      const ve_invalid =
        instance.view_evaluation_override !== false &&
        instance.ve_prediction?.data?.[0]?.conf?.normalized_score < config.evaluationScoreThreshold;
      if (ve_invalid) return true;

      // quality criteria
      const instance_view = slides?.find(
        (view) => view?.id === instance?.slideId && view.idx_in_template === instance.idx_in_template
      );
      const mandatoryQC = examinationContext.getQualityCriteriaByInstanceViewId(
        instance_view,
        instance,
        currentTrimester,
        'mandatory'
      );
      const qc_invalid = mandatoryQC.some((qc) => qc.status !== 'error' && qc.is_detected && !qc.is_valid);
      if (qc_invalid) return true;

      // checklist items: deprecated, to be removed once we will remove the sonio.routine.semio_v1 UI
      const checklistItemsForInstance = checklistItems.filter((item) =>
        item.instance_view_id[currentTrimester]?.includes(instance_view?.id)
      );
      const checklist_invalid = checklistItemsForInstance.some((item) => anomalies.includes(item.id));
      if (checklist_invalid) return true;

      return false;
    },
    [JSON.stringify(slides), examinationContext.getQualityCriteriaByInstanceViewId, anomalies, checklistItems]
  );

  const fetalSexField = useMemo(() => {
    const examFetalSex = examinationContext.examination?.medical_history?.['medicalexam.fetus.sex']?.value;
    const fetalSexDetections = examinationContext.instances?.filter(
      (instance) => instance.qc_prediction?.data?.[0]?.sex
    );
    const detectedFetalSex = fetalSexDetections.pop()?.qc_prediction.data[0].sex?.id;

    if (!examFetalSex && detectedFetalSex) {
      return {
        value: detectedFetalSex,
        detected_by: 'vision-ai',
      };
    } else if (examFetalSex) {
      return {
        value: examFetalSex,
        detected_by: 'user',
      };
    } else {
      return {
        value: examFetalSex,
        detected_by: '',
      };
    }
  }, [examinationContext.instances, examinationContext.examination?.medical_history, examinationContext.instanceViews]);

  const { sexConfirmedBy, sexContradictedBy } = useMemo(() => {
    const sexConfirmedBy = [];
    const sexContradictedBy = [];

    if (fetalSexField?.value) {
      for (const instance of examinationContext.instances) {
        if (instance.qc_prediction?.data?.[0].sex?.id) {
          const instance_view = examinationContext.instanceViews[instance.slideId];
          if (instance.qc_prediction?.data?.[0].sex?.id === fetalSexField.value) {
            sexConfirmedBy.push({ ...instance, instance_view });
          } else {
            sexContradictedBy.push({ ...instance, instance_view });
          }
        }
      }
    }

    return { sexConfirmedBy, sexContradictedBy };
  }, [examinationContext.instances, examinationContext.instanceViews, fetalSexField?.value]);

  const { placentaPositionConfirmedBy, placentaPositionContradictedBy } = useMemo(() => {
    const placentaPositionConfirmedBy = [];
    const placentaPositionContradictedBy = [];

    if (examinationContext.examination.placenta_position_id) {
      for (const instance of examinationContext.instances) {
        const instance_view = examinationContext.instanceViews[instance.slideId];

        // Check for AI detection
        if (instance.qc_prediction?.data?.[0].placenta_location) {
          if (
            instance.qc_prediction?.data?.[0].placenta_location?.id ===
            examinationContext.examination.placenta_position_id
          ) {
            placentaPositionConfirmedBy.push({ ...instance, instance_view });
          } else {
            placentaPositionContradictedBy.push({
              ...instance,
              instance_view,
            });
          }
        }

        // Check for OCR detection
        const ocrPrediction = instance.predictions.filter((p) => p.type === 'sonio_ocr')?.[0]?.data?.[0]
          ?.placenta_position;
        if (ocrPrediction) {
          const {
            id: ocrPlacentaPostionId,
            // instance_view_id: instanceViewId,
          } = ocrPrediction;
          if (ocrPlacentaPostionId === examinationContext.examination.placenta_position_id) {
            placentaPositionConfirmedBy.push({ ...instance, instance_view });
          } else {
            placentaPositionContradictedBy.push({
              ...instance,
              instance_view,
            });
          }
        }
      }
    }

    return { placentaPositionConfirmedBy, placentaPositionContradictedBy };
  }, [
    examinationContext.instances,
    examinationContext.instanceViews,
    examinationContext.examination.placenta_position_id,
  ]);

  const placentaPositionField = {
    id: examinationContext.examination.placenta_position_id,
    detected_by: !examinationContext.examination.placenta_position_id
      ? ''
      : examinationContext.examination.placenta_position_id_source,
  };

  useEffect(() => {
    const newUnusualMedias =
      examinationContext.instances
        ?.filter((instance) => !['OT', 'SR'].includes(instance.modality) && isInstanceUnusual(instance))
        .map((instance) => instance.id) || [];
    setUnusualMedias(newUnusualMedias);
    const mediaList = examinationContext.instances
      .filter((instance) => !['OT', 'SR'].includes(instance.modality))
      .map((instance) => ({
        ...instance,
        associations: Object.values(assocInstanceExam || {})?.filter(
          (assoc) => assoc.dicom_instance_id === instance.id
        ),
      }));
    setSharingImages(mediaList.filter((instance) => instance.dicom_media_type === 'image'));
    setMedias(mediaList);
  }, [examinationContext.instances, isInstanceUnusual]);

  useEffect(() => {
    if (!checklistItems.length || !examinationContext.examination?.medical_history) {
      setChecklistItemsRiskFactors({});
      setSlidesRiskFactors({});
      return false;
    }

    const riskFactors = Object.values(examinationContext.examination.medical_history).reduce(
      (risks, risk) => (risk = [...risks, ...(risk.risk_factors || [])]),
      []
    );

    if (!riskFactors.length) {
      setChecklistItemsRiskFactors({});
      return false;
    }

    LookupApi.getExamItemByRiskFactor(riskFactors).then((resp) => {
      const newChecklistItemsRiskFactors = {};
      for (const item of checklistItems) {
        const riskFactors = !item.risk_factors
          ? { malformations: [], risk_factors: [], syndromes: [] }
          : item.risk_factors;
        const newRiskFactor = resp.data.find((i) => i.id === item.id);
        if (newRiskFactor) {
          for (const malformation of newRiskFactor.malformations || []) {
            if (!riskFactors.malformations.some((m) => m.id === malformation.id))
              riskFactors.malformations.push(malformation);
          }
          for (const risk_factor of newRiskFactor.risk_factors || []) {
            if (!riskFactors.risk_factors.some((m) => m.id === risk_factor.id))
              riskFactors.risk_factors.push(risk_factor);
          }
          for (const syndrome of newRiskFactor.syndromes || []) {
            if (!riskFactors.syndromes.some((m) => m.id === syndrome.id)) riskFactors.syndromes.push(syndrome);
          }
        }
        newChecklistItemsRiskFactors[item.id] = riskFactors;
      }
      setChecklistItemsRiskFactors(newChecklistItemsRiskFactors);
    });
  }, [JSON.stringify(examinationContext.examination?.medical_history), checklistItems]);

  useEffect(() => {
    const riskFactors = {};
    for (const item of checklistItems) {
      for (const slideId of item.instance_view_id[currentTrimester] ?? []) {
        if (!riskFactors[slideId])
          riskFactors[slideId] = {
            malformations: [],
            risk_factors: [],
            syndromes: [],
          };
        for (const malformation of checklistItemsRiskFactors?.[item.id]?.malformations || []) {
          riskFactors[slideId].malformations[malformation.id] = malformation;
        }
        for (const riskFactor of checklistItemsRiskFactors?.[item.id]?.risk_factors || []) {
          riskFactors[slideId].risk_factors[riskFactor.id] = riskFactor;
        }
        for (const syndrome of checklistItemsRiskFactors?.[item.id]?.syndromes || []) {
          riskFactors[slideId].risk_factors[syndrome.id] = syndrome;
        }
      }
    }
    setSlidesRiskFactors(riskFactors);
  }, [JSON.stringify(checklistItemsRiskFactors), checklistItems, currentTemplateId]);

  const resetNextMatchingSlide = useCallback(() => {
    if (isPaused) {
      setNextMatchingSlide([...slides].reverse().find((slide) => slide.type === 'other' && !slide.is_video));
    } else {
      let nextSlide = slides[0];
      if (examinationContext.examination.next_association_view?.instance_view_id) {
        const slidesWithSameId = slides.filter(
          (s) => s.id === examinationContext.examination.next_association_view.instance_view_id
        );
        nextSlide = slidesWithSameId.find((s) => !s.mediaId) ?? slidesWithSameId[slidesWithSameId.length - 1];
      }
      setNextMatchingSlide(nextSlide);
    }
  }, [slides, isPaused, examId, examinationContext.examination.next_association_view?.instance_view_id]);

  useEffect(() => {
    resetNextMatchingSlide();
  }, [resetNextMatchingSlide]);

  useEffect(() => {
    setIsPaused(examinationContext.examination.association_status?.status === 'paused');
    setIsManual(examinationContext.examination.association_status?.status === 'manual');
  }, [examinationContext.examination.association_status?.status]);

  useEffect(() => {
    setIsPrintable(examinationContext.examination.association_status?.selected_for_print);
  }, [examinationContext.examination.association_status?.selected_for_print]);

  const toggleIsPaused = useCallback(
    async (newIsPaused = null) => {
      let currAssocStatus = examinationContext.examination.association_status?.status;
      if (newIsPaused !== null) currAssocStatus = newIsPaused ? 'paused' : 'active';
      else currAssocStatus = isPaused ? 'active' : 'paused';
      await examinationContext.updateExamination({
        ...examinationContext.examination,
        association_status: {
          ...examinationContext.examination.association_status,
          status: currAssocStatus,
        },
      });
    },
    [isPaused, JSON.stringify(examinationContext.examination)]
  );

  const toggleIsManual = useCallback(
    async (newIsManual = null, newExam = examinationContext.examination) => {
      setIsManual((isManual) => (newIsManual !== null ? newIsManual : !isManual));

      let currAssocStatus = newExam.association_status?.status;
      if (newIsManual !== null) currAssocStatus = newIsManual ? 'manual' : 'active';
      else currAssocStatus = isManual ? 'active' : 'manual';
      await examinationContext.updateExamination({
        ...newExam,
        association_status: {
          ...newExam.association_status,
          status: currAssocStatus,
        },
      });
      if (newIsManual?.id) {
        await examinationContext.updateExaminationNextSlide(
          examinationContext.examination.id,
          newIsManual?.id,
          newIsManual?.idx_in_template,
          newIsManual?.idx_in_group
        );
      }
    },
    [isManual, JSON.stringify(examinationContext.examination)]
  );

  const toggleIsPrintable = async (newIsPrintable = null) => {
    let newState;
    if (newIsPrintable !== null) {
      newState = newIsPrintable;
    } else {
      newState = !isPrintable;
    }
    await examinationContext.updateExamination({
      ...examinationContext.examination,
      association_status: {
        ...examinationContext.examination.association_status,
        selected_for_print: newState,
      },
    });
  };

  const setExaminationNextView = async (slide) => {
    return await examinationContext.updateExaminationNextSlide(
      examinationContext.examination.id,
      slide.id,
      slide.idx_in_template,
      slide.idx_in_group
    );
  };

  /* sharing and printing */
  useEffect(() => {
    if (examinationContext.examination.id) {
      const newAssocInstanceExam = {};
      ResourceApi.getAssocInstanceByExamId(examinationContext.examination.id).then((resp) => {
        resp.data.data.forEach((assoc) => {
          newAssocInstanceExam[assoc.dicom_instance_id] = assoc;
        });
        setAssocInstanceExam(newAssocInstanceExam);
      });
    }
  }, [examinationContext.examination.id]);

  const setSharingStatusForInstance = async (instanceId, shared, recipient = 'patient') => {
    if (recipient === 'patient') {
      await ResourceApi.update_sharing_properties_association(examinationContext.examination.id, instanceId, shared);

      setAssocInstanceExam((prev) => ({
        ...prev,
        [instanceId]: {
          ...prev[instanceId],
          shared_with_patient: shared,
        },
      }));
    }
  };

  return (
    <ExaminationView
      instanceViews={instanceViews}
      slides={slides}
      slidesRiskFactors={slidesRiskFactors}
      medias={medias}
      setMedias={setMedias}
      currentTrimester={currentTrimester}
      currentPreset={currentPreset}
      fetusSex={fetusSex}
      medicalHistory={medicalHistory}
      checklistItems={checklistItems}
      checklistItemsRiskFactors={checklistItemsRiskFactors}
      anomalies={anomalies}
      unusualMedias={unusualMedias}
      nextMatchingSlide={nextMatchingSlide}
      manualUserInteraction={manualUserInteraction}
      setManualUserInteraction={setManualUserInteraction}
      isPaused={isPaused}
      toggleIsPaused={toggleIsPaused}
      isManual={isManual}
      toggleIsManual={toggleIsManual}
      isPrintable={isPrintable}
      toggleIsPrintable={toggleIsPrintable}
      setExaminationNextView={setExaminationNextView}
      assocInstanceExam={assocInstanceExam}
      setSharingStatusForInstance={setSharingStatusForInstance}
      isInstanceUnusual={isInstanceUnusual}
      naked={naked}
      nakedProps={nakedProps}
      fetalSexField={fetalSexField}
      sexConfirmedBy={sexConfirmedBy}
      sexContradictedBy={sexContradictedBy}
      placentaPositionField={placentaPositionField}
      placentaPositionConfirmedBy={placentaPositionConfirmedBy}
      placentaPositionContradictedBy={placentaPositionContradictedBy}
    />
  );
};

export default withTranslation()(Examination);
