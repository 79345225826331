import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon/Icon';
import useAuth from '../../context-providers/Auth';
import './DashboardView.css';

const DashboardManagerView = ({ t: __, sameSiteEntities }) => {
  const { user, switchUserAndRedirect } = useAuth();

  return (
    <div className="dashboard-container">
      <div className="manager-dashboard-new-exam-info">
        <Icon name="question" /> {__('dashboard.manager.info')}
      </div>
      <div className="row">
        {sameSiteEntities
          .filter(({ id }) => id != user.id)
          .map((entity) => (
            <div key={entity.id} className="w6">
              <div
                className="dashboard-new-exam-button"
                onClick={() => {
                  switchUserAndRedirect(entity.id);
                }}
              >
                <Icon name="user" />
                <div className="label">{entity.title}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default withTranslation()(DashboardManagerView);

DashboardManagerView.propTypes = {
  t: PropTypes.any,
};
