import React, { useEffect, useState } from 'react';
/* Contexts */
import { useExamination } from '../../context-providers/Examination';
import useAuth from '../../context-providers/Auth';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import ResourceApi from '../../services/resource';

/* Atoms */
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';

/* Components */
import PrintingContent from './PrintingContent';
import SessionContent from './SessionContent';
import AppMetaContent from './AppMetaContent';
import ExaminationContent from './Examination';
import QuickReportEditor from './QuickReportEditor';
import I18nDebugger from './I18nDebugger';
import Sharing from './Sharing';
import Report from './Report';
import AmbientListening from './AmbientListening';
import Workspace from './Workspace';
import Measurements from './Measurements';
import SRExtraction from './SRExtraction';

/* Styles */
import './styles.css';

const Content = ({ open, setTemplateEditorOpen, templateEditorOpen, examination }) => {
  if (!open) return null;
  const { debugTemplate } = useExamination();
  const [openChapter, setOpenChapter] = useState(false);
  const { isFeatureFlagEnabled } = useAuth();

  return (
    <div className="debug-panel-content">
      <div className="debug-panel-template-editor-toggle">
        <Button
          checked={templateEditorOpen}
          color={debugTemplate ? 'red' : 'common'}
          onClick={() => setTemplateEditorOpen(true)}
          label="Open template editor"
        />
        <Button
          checked={templateEditorOpen}
          variant="outline"
          color="common"
          onClick={() => examination.id && ResourceApi.refreshReportTemplate(examination.id)}
          label="Get the last version of the template"
        />
      </div>
      <AppMetaContent openChapter={openChapter} setOpenChapter={setOpenChapter} />
      <SessionContent openChapter={openChapter} setOpenChapter={setOpenChapter} />
      <ExaminationContent openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      <PrintingContent openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      <Sharing openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      <Report openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      <Workspace openChapter={openChapter} setOpenChapter={setOpenChapter} />
      <Measurements openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      <SRExtraction openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      <QuickReportEditor openChapter={openChapter} setOpenChapter={setOpenChapter} />
      <I18nDebugger openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      {isFeatureFlagEnabled('sonio.ambient_listening') && (
        <AmbientListening openChapter={openChapter} setOpenChapter={setOpenChapter} examination={examination} />
      )}
    </div>
  );
};

function fallbackBluePrint(selectedBlueprint, reportDataOptions) {
  switch (selectedBlueprint) {
    case 'report':
      return reportDataOptions?.report_template?.blueprint || '';
    case 'medical-history':
      return reportDataOptions?.medical_history_template?.blueprint || '';
    default:
      return '';
  }
}

const DebugPanel = () => {
  const [open, setOpen] = useState(false);
  const [selectedBlueprint, setSelectedBlueprint] = useState('report');
  const [templateEditorOpen, setTemplateEditorOpen] = useState(false);
  const [examination, setExamination] = useState(null);
  const { examination: incomingExamination, debugTemplate, setDebugTemplate } = useExamination();
  const nodeRef = React.useRef(null);
  const castedIncoming = JSON.stringify(incomingExamination);
  const castedCurrent = JSON.stringify(examination);

  const setNewTemplateContent = (content) => {
    setDebugTemplate(content);
  };

  const updateSelectedBlueprint = (type) => {
    setDebugTemplate('');
    setSelectedBlueprint(type);
  };

  const { reportDataOptions } = useXMLTemplate();
  const fallback = fallbackBluePrint(selectedBlueprint, reportDataOptions);
  const currentTemplate = debugTemplate || fallback;

  useEffect(() => {
    if (castedIncoming !== '{}' && castedIncoming !== castedCurrent) {
      setExamination(incomingExamination);
    }
  }, [castedCurrent, castedIncoming]);

  if (examination == null || castedCurrent === '{}' || castedIncoming === '{}') return null;
  return (
    <>
      <div id="debug-panel" className={open ? 'open' : ''} ref={nodeRef}>
        <div className="debug-panel-toggle" onClick={() => setOpen((open) => !open)}>
          <Icon name={open ? 'right' : 'left'} /> DEBUG
        </div>
        <Content
          open={open}
          templateEditorOpen={templateEditorOpen}
          setTemplateEditorOpen={setTemplateEditorOpen}
          examination={examination}
        />
        {templateEditorOpen && (
          <div className="debug-panel-template-editor">
            <div style={{ display: 'block' }}>
              <div>
                <label htmlFor="report-blueprint">Report</label>
                <input
                  className="ml-small"
                  id="report-blueprint"
                  type="radio"
                  checked={selectedBlueprint === 'report'}
                  onChange={() => updateSelectedBlueprint('report')}
                />
              </div>
              <div>
                <label htmlFor="medical-history-blueprint">Medical History</label>
                <input
                  className="ml-small"
                  id="report-blueprint"
                  type="radio"
                  checked={selectedBlueprint === 'medical-history'}
                  onChange={() => updateSelectedBlueprint('medical-history')}
                />
              </div>
            </div>
            <textarea
              className="debug-panel-textarea"
              onChange={(e) => setNewTemplateContent(e.currentTarget.value)}
              value={currentTemplate}
            ></textarea>
            <div>
              <Button icon="right" label="Hide the editor" onClick={() => setTemplateEditorOpen(false)} />
              <div>
                Nothing will be saved. By reloading or navigating away or clicking "Clean and Exit" you'll loose every
                modification you made.
              </div>
              <Button
                icon="close"
                label="Clean and Exit"
                color="red"
                onClick={() => {
                  setTemplateEditorOpen(false);
                  setDebugTemplate('');
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default DebugPanel;
