import axios from 'axios';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@sonio-ai/design-system/dist/esm/index.css';

import AppRoutes from './AppRoutes';
import NotificationsPanel from './components/NotificationsPanel/NotificationsPanel';
import { NotificationProvider } from './context-providers/Notification';
import { LatestVersionProvider } from './context-providers/LatestVersion';
import { WindowMessagingContextProvider } from './context-providers/WindowMessaging';
import ErrorBoundary from './ErrorBoundary';
import './i18n';
import './styles/fonts.css';
import './styles/layout.css';

import './polyfills';

axios.defaults.baseURL = '/api/v2';

function App() {
  return (
    <ErrorBoundary>
      <div className="App">
        <BrowserRouter>
          <NotificationProvider>
            <LatestVersionProvider>
              <WindowMessagingContextProvider>
                <AppRoutes />
                <NotificationsPanel />
              </WindowMessagingContextProvider>
            </LatestVersionProvider>
          </NotificationProvider>
        </BrowserRouter>
      </div>
    </ErrorBoundary>
  );
}

export default App;
