import { isValidElement, cloneElement } from 'react';

export default function ReportTemplateCondition({
  defaultKey = '',
  props,
  children,
  getPlaceholderWithProps,
  fetus,
  checkCondition = () => {},
}) {
  if (props.fetus) fetus = props.fetus;
  if (!Array.isArray(children)) children = [children];

  for (const child of children) {
    if (
      isValidElement(child) &&
      child.props.type === 'if' &&
      checkCondition({ ...child.props?.props }, fetus, getPlaceholderWithProps)
    ) {
      return Array.isArray(child.props?.children)
        ? child.props?.children
            .map((child, i) => {
              if (isValidElement(child)) {
                return cloneElement(child, { ...props, fetus, defaultKey: `${defaultKey}_${i}_block_${fetus}` });
              } else return false;
            })
            .filter((child) => child)
        : cloneElement(child.props?.children, { ...props, fetus, defaultKey: `${defaultKey}_0_block_${fetus}` });
    }
  }

  for (const child of children) {
    if (isValidElement(child) && child.props.type === 'else') {
      return Array.isArray(child.props?.children)
        ? child.props?.children
            .map((child, i) => {
              if (isValidElement(child)) {
                return cloneElement(child, { ...props, fetus, defaultKey: `${defaultKey}_${i}_block_${fetus}` });
              } else return false;
            })
            .filter((child) => child)
        : cloneElement(child.props?.children, { ...props, fetus, defaultKey: `${defaultKey}_0_block_${fetus}` });
    }
  }

  return false;
}
