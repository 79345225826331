import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../../../atoms/Icon/Icon';
import Slide from '../../Slide/Slide';
import TextInput from '../../../atoms/TextInput/TextInput';
import { ExaminationContext } from '../../../context-providers/Examination';
import './SlideBrowser.css';

const SlideBrowser = ({
  t: __,
  title = null,
  slides,
  selectedSlideId = false,
  trimester = 'T2',
  onChange = () => {},
  onClose = () => {},
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationContext = useContext(ExaminationContext);
  const [searchKey, setSearcKey] = useState('');
  const containerRef = useRef(null);

  const uniqueSlides = useMemo(
    () =>
      slides
        .filter(
          (slide, index) =>
            slides.findIndex((s) => s.type === 'picture' && s?.id === slide?.id && s.state === 'active') === index
        )
        .map((slide) => ({ ...slide, matchedMedias: slides.filter((s) => s?.id === slide?.id && !!s?.mediaId).length }))
        .map((slide) => ({
          ...slide,
          completed: slide?.medias?.[trimester] ? slide?.matchedMedias >= slide?.medias?.[trimester] : false,
        })),
    [slides]
  );

  const otherSlide = useMemo(
    () =>
      slides
        .filter(
          (slide, index) =>
            slides.findIndex((s) => s.type === 'other' && s.state === 'active' && s?.id === slide?.id) === index
        )
        .map((slide) => ({
          ...slide,
          type: 'picture',
          matchedMedias: slides.filter((s) => s?.id === slide?.id && !!s?.mediaId).length,
        }))
        .map((slide) => ({
          ...slide,
          completed: slide?.medias[trimester] ? slide?.matchedMedias >= slide?.medias[trimester] : false,
        })),
    [slides]
  );

  const allSlides = useMemo(() => {
    const slideIds = slides.map((s) => s.id);
    return examinationContext.instanceViews.filter(
      (view) =>
        !Object.keys(slideIds).includes(view.id) &&
        view.type === 'picture' &&
        view.state === 'active' &&
        !uniqueSlides.some((slide) => slide.id === view.id)
    );
  }, [slides, uniqueSlides]);

  useEffect(() => {
    if (!!selectedSlideId && selectedSlideId !== 39) goToSlide(selectedSlideId);
  });

  const goToSlide = useCallback((slideId) => {
    const target = containerRef.current.querySelector('.slide-id-' + slideId);
    if (!target) return false;

    containerRef?.current?.scrollTo({
      top: target.offsetTop - containerRef.current.offsetHeight / 2 + target.offsetHeight / 2,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <div className="slide-browser-container" ref={containerRef}>
        <div className="slide-browser-search">
          <TextInput icon="search" value={searchKey} onChange={(searchKey) => setSearcKey(searchKey)} />
        </div>
        <h2>{title || __('examination.slideBrowser.selectSlide')}</h2>
        <div className="slide-browser-close" onClick={onClose}>
          <Icon name="close" />
        </div>
        {searchKey ? (
          <>
            <ul>
              {[...uniqueSlides, ...allSlides]
                .filter((slide) => (slide.label[currentLanguage] || '').toLowerCase().includes(searchKey))
                .map((s) => (
                  <li
                    key={s.id}
                    onClick={() => onChange(s)}
                    className={`slide-id-${s.id} ${selectedSlideId === s.id ? 'selected' : ''} ${
                      s.completed ? 'completed' : ''
                    }`}
                  >
                    <Slide
                      version="2"
                      trimester={trimester}
                      slide={s}
                      type={s.type}
                      sex="M"
                      zoomLevel="5"
                      rotation={{ rotate: 0, flip: false }}
                    />
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <>
            <ul>
              {[...otherSlide, ...uniqueSlides].map((s) => (
                <li
                  key={s.id}
                  onClick={() => onChange(s)}
                  className={`slide-id-${s.id} ${selectedSlideId === s.id ? 'selected' : ''} ${
                    s.completed ? 'completed' : ''
                  }`}
                >
                  <Slide
                    version="2"
                    trimester={trimester}
                    slide={s}
                    type={s.type}
                    sex="M"
                    zoomLevel="5"
                    rotation={{ rotate: 0, flip: false }}
                  />
                </li>
              ))}
            </ul>
            <br />
            <h2>{__('examination.slideBrowser.allSlides')}</h2>
            <ul>
              {allSlides.map((s) => (
                <li
                  key={s.id}
                  onClick={() => onChange(s)}
                  className={`slide-id-${s.id} ${selectedSlideId === s.id ? 'selected' : ''} ${
                    s.completed ? 'completed' : ''
                  }`}
                >
                  <Slide
                    version="2"
                    trimester={trimester}
                    slide={s}
                    type={s.type}
                    sex="M"
                    zoomLevel="5"
                    rotation={{ rotate: 0, flip: false }}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="modal-background" onClick={() => onClose()} />
    </>
  );
};

export default withTranslation()(SlideBrowser);
