import { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ExaminationContext } from '../../../context-providers/Examination';
import { DxContext } from '../../../context-providers/Dx';
import SignSearchBar from '../../../components/SignSearchBar';
import PatientName from '../../../components/PatientName/PatientName';
import AnomalyLine from '../../../components-dx/AnomalyLine';
import SyndromeLine from '../../../components-dx/SyndromeLine';
import './DiagnosticPanelView.css';
import SignLine from '../../../components-dx/SignLine';
import Button from '../../../atoms/Button/Button';
import RiskFactorLine from '../../../components-dx/RiskFactorLine';

const DiagnosticPanelView = ({ t: __, malformations, signs }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const history = useHistory();
  const { examId } = useParams();

  const [searchKey, setSearchKey] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchNotification, setSearchNotification] = useState('');

  const examinationContext = useContext(ExaminationContext);
  const dxContext = useContext(DxContext);

  useEffect(() => {
    setSearchKey('');
    setSearchResults([]);
    dxContext.setRecommendation((recommendation) => ({ ...recommendation, malformations: [], syndromes: [] }));
    dxContext.loadRecommendations();
  }, [examinationContext.examination.id]);

  useEffect(() => {
    setTimeout(() => setSearchNotification(''), 3000);
  }, [searchNotification]);

  const openSummary = () => {
    dxContext.setChecklistSummaryOpen(true);
  };

  const validateCurrentZoneAndOpenSummary = () => {
    if (examinationContext.examination.zones.filter((zone) => zone.status === 'validated').length < 5) {
      const newMalformations = [
        ...(examinationContext.examination.malformations || []),
        ...dxContext.recommendation.malformations
          .filter(
            (malformation) =>
              !examinationContext.examination.malformations?.some((m) => m.id === malformation.id) &&
              malformation.exam_zone_id === dxContext.currentZoneId
          )
          .map((malformation) => ({ id: malformation.id, status: 'no' })),
      ];
      const zones = dxContext.updateZone(dxContext.currentZoneId, 'validated');
      examinationContext.updateExamination({
        ...examinationContext.examination,
        malformations: newMalformations,
        zones: zones,
      });
      dxContext.setRecommendation((recommendation) => ({ ...recommendation, syndromes: [] }));
    }
    dxContext.setChecklistSummaryOpen(true);
  };

  const validateCurrentZoneAndGoToNext = () => {
    const zones = dxContext.updateZone(dxContext.currentZoneId, 'validated');
    const newMalformations = [
      ...(examinationContext.examination.malformations || []),
      ...dxContext.recommendation.malformations
        .filter(
          (malformation) =>
            !examinationContext.examination.malformations?.some((m) => m.id === malformation.id) &&
            malformation.exam_zone_id === dxContext.currentZoneId
        )
        .map((malformation) => ({ id: malformation.id, status: 'no' })),
    ];
    examinationContext.updateExamination({
      ...examinationContext.examination,
      zones: zones,
      malformations: newMalformations,
    });
    dxContext.setRecommendation((recommendation) => ({ ...recommendation, malformations: [] }));
    dxContext.setCurrentZoneId(dxContext.getNextZoneId);
    dxContext.loadRecommendations();
  };

  const goToNextZone = () => {
    dxContext.setCurrentZoneId(dxContext.getNextZoneId);
  };

  const invalidateCurrentZone = () => {
    const zones = dxContext.updateZone(dxContext.currentZoneId, 'not-validated');
    examinationContext.updateExamination({ ...examinationContext.examination, zones: zones });
    dxContext.setRecommendation((recommendation) => ({ ...recommendation, malformations: [] }));
    dxContext.loadRecommendations();
  };

  const isCurrentZoneValidated = examinationContext.examination?.zones?.some(
    (zone) => zone.id === dxContext.currentZoneId && zone.status === 'validated'
  );

  return (
    <div className={`diagnostic-panel`}>
      <div className="diagnostic-panel-container">
        <div className="search-for-sign">
          <SignSearchBar
            searchKey={searchKey}
            excludeIds={[]}
            label={__('signSearchBar.searchForAnomalies')}
            notification={searchNotification}
            onChange={(signs) => setSearchResults(signs)}
            onChangeSearchKey={(searchKey) => {
              setSearchKey(searchKey);
              if (!searchKey) setSearchResults([]);
            }}
            isLoading={(isSearching) => setIsSearching(isSearching)}
          />
          {examinationContext.examination && (
            <div className="examination-patient-name">
              <PatientName
                patient={examinationContext.patient}
                episode={examinationContext.episode}
                examinationDate={examinationContext.examination.examination_date}
              />
            </div>
          )}
        </div>
        <div className="to-check">
          {searchKey || isSearching ? (
            <>
              {/* Search results */}
              <h2>
                {[
                  __('dx.summary.searchResults').split('{searchKey}')[0],
                  <span key="search-key" className="entity">
                    {searchKey}
                  </span>,
                  __('dx.summary.searchResults').split('{searchKey}')[1],
                ]}
              </h2>
              <ul>
                {!searchResults.length ? (
                  <li className="no-results">{__('dx.summary.searchResultsEmpty')}</li>
                ) : (
                  searchResults.map((m) =>
                    m.type === 'malformation' ? (
                      <AnomalyLine
                        key={'anomaly' + m.id}
                        anomaly={m}
                        searchKey={searchKey}
                        cta={__('dx.summary.searchResults.malformation')}
                        isHidden={dxContext.isLoadingRecommendation}
                        onClick={(anomaly, status) => {
                          dxContext.updateMalformation(anomaly, status, true);
                          setSearchResults([]);
                          setSearchNotification(
                            __('dx.summary.searchResults.malformation.added').replace(
                              '{malformation}',
                              anomaly.canonical_name[currentLanguage]
                            )
                          );
                          return setSearchKey('');
                        }}
                      />
                    ) : m.type === 'sign' ? (
                      <SignLine
                        key={'sign' + m.id}
                        sign={m}
                        searchKey={searchKey}
                        cta={__('dx.summary.searchResults.sign')}
                        isHidden={dxContext.isLoadingRecommendation}
                        onClick={(sign) => {
                          dxContext.addSemiologyItem(sign, 'sign', true);
                          setSearchResults([]);
                          setSearchNotification(
                            __('dx.summary.searchResults.sign.added').replace(
                              '{sign}',
                              sign.canonical_name[currentLanguage]
                            )
                          );
                          return setSearchKey('');
                        }}
                      />
                    ) : m.type === 'medicalhistory' || m.type === 'teratogen' ? (
                      <RiskFactorLine
                        key={'riskfactor' + m.id}
                        riskFactor={m}
                        searchKey={searchKey}
                        cta={__('dx.summary.searchResults.riskFactor.' + m.type)}
                        isHidden={dxContext.isLoadingRecommendation}
                        onClick={(risk, optionValue = null) => {
                          if (risk.type === 'medicalhistory') {
                            let raw_value = null;
                            if (optionValue) {
                              const option = Object.values(examinationContext.medicalHistoryItems)
                                .find((item) => item.id === risk.id)
                                ?.options.find((option) => option.value === optionValue);
                              raw_value = !option ? optionValue : (option.lower_limit + option.upper_limit) / 2;
                            }
                            examinationContext.addRiskFactorById(risk.id, raw_value, optionValue, optionValue);
                          } else if (risk.type === 'teratogen')
                            examinationContext.addMedicationById(risk.id, risk.risk_factor_ids);
                          setSearchResults([]);
                          setSearchNotification(
                            __('dx.summary.searchResults.riskFactor.' + risk.type + '.added').replace(
                              '{risk}',
                              risk.canonical_name[currentLanguage]
                            )
                          );
                          return setSearchKey('');
                        }}
                      />
                    ) : m.type === 'syndrome' ? (
                      <SyndromeLine
                        key={'syndrome' + m.id}
                        syndrome={m}
                        searchKey={searchKey}
                        cta={__('dx.summary.searchResults.syndrome')}
                        isHidden={dxContext.isLoadingRecommendation}
                        onClick={(syndrome) => {
                          dxContext.setUserOverrides((userOverrides) =>
                            userOverrides
                              .filter((u) => !(u.type === 'syndrome' && u.id === syndrome.id))
                              .concat({
                                type: 'syndrome',
                                id: syndrome.id,
                                action: 'add',
                              })
                          );
                          setSearchResults([]);
                          setSearchNotification(
                            __('dx.summary.searchResults.syndrome.added').replace(
                              '{syndrome}',
                              syndrome.canonical_name[currentLanguage]
                            )
                          );
                          return setSearchKey('');
                        }}
                      />
                    ) : (
                      false
                    )
                  )
                )}
              </ul>
            </>
          ) : (
            <>
              {!signs.length && !malformations.length && (
                <div className="diagnostic-panel-warning">
                  {isCurrentZoneValidated ? (
                    <div>
                      <div className="diagnostic-panel-warning-alert">
                        {__('dx.checklist.warning.zoneValidated').replace(
                          '{zone}',
                          dxContext.zones?.[dxContext.currentZoneId]?.label[currentLanguage]
                        )}
                      </div>
                      <Button
                        label={__('dx.checklist.warning.zoneValidated.unblock')}
                        variant="outline"
                        onClick={() => invalidateCurrentZone()}
                      />
                    </div>
                  ) : dxContext.isLoadingRecommendation ? (
                    __('dx.checklist.warning.loadingSigns')
                  ) : (
                    __('dx.checklist.warning.noSigns')
                  )}
                </div>
              )}
              {/* Signs to check */}
              {!!signs.length && (
                <>
                  <h2>
                    {[
                      __('dx.checklist.signsToCheck').split('{sign}')[0],
                      <span key="sign-entity" className="entity">
                        {dxContext.currentChecklistItem?.label[currentLanguage]}
                      </span>,
                      __('dx.checklist.signsToCheck').split('{sign}')[1],
                    ]}
                  </h2>
                  <ul>
                    {signs.map((sign) => (
                      <SignLine
                        key={sign.id}
                        sign={sign}
                        isHidden={dxContext.isLoadingRecommendation}
                        onClick={() => dxContext.addSemiologyItem(sign, 'sign')}
                      />
                    ))}
                  </ul>
                </>
              )}

              {/* Anomalies to check */}
              {!!malformations.length && (
                <>
                  <h2>
                    {dxContext.currentChecklistItem?.label[currentLanguage]
                      ? [
                          __('dx.checklist.anomaliesToCheckLinkedTo').split('{sign}')[0],
                          <span key="anomaly-entity" className="entity">
                            {dxContext.currentChecklistItem?.label[currentLanguage]}
                          </span>,
                          __('dx.checklist.anomaliesToCheckLinkedTo').split('{sign}')[1],
                        ]
                      : dxContext.semiologyWorkflowStack.length
                      ? [
                          __('dx.checklist.anomaliesToCheckLinkedTo').split('{sign}')[0],
                          ...dxContext.semiologyWorkflowStack.map((sign) => (
                            <span
                              key={sign.id}
                              className="entity"
                              onClick={() => dxContext.goToSemiologyWorkflowSign(sign.id)}
                            >
                              {sign.canonical_name?.[currentLanguage] || sign.canonical_name}
                            </span>
                          )),
                          __('dx.checklist.anomaliesToCheckLinkedTo').split('{sign}')[1],
                        ]
                      : [
                          __('dx.checklist.anomaliesToCheck').split('{zone}')[0],
                          <span key="anomaly-entity" className="entity">
                            {dxContext.zones?.[dxContext.currentZoneId]?.label?.[currentLanguage]}
                          </span>,
                          __('dx.checklist.anomaliesToCheck').split('{zone}')[1],
                        ]}
                  </h2>
                  <ul>
                    {malformations
                      .map((malformation) => ({
                        ...examinationContext.getMalformationById(malformation.id),
                        ...malformation,
                      }))
                      .map((m) => (
                        <AnomalyLine
                          key={m.id}
                          anomaly={m}
                          syndromes={m.syndromes || []}
                          isolated={m.isolated}
                          isHidden={dxContext.isLoadingRecommendation && !dxContext.semiologyWorkflowStack.length}
                          onClick={(anomaly, status) => dxContext.updateMalformation(anomaly, status, true)}
                        />
                      ))}
                  </ul>
                </>
              )}
            </>
          )}
        </div>
        {searchKey ? (
          <div className="zone-validation">
            <Button
              icon="close"
              label={__('dx.checklist.nothingOfThatGoBack')}
              onClick={() => {
                setSearchKey('');
                setSearchResults([]);
              }}
            />
          </div>
        ) : dxContext.currentChecklistItem ? (
          <div className="zone-validation">
            <Button
              icon="close"
              label={__('dx.checklist.nothingOfThatGoBack')}
              onClick={() => {
                dxContext.setCurrentChecklistItem(null);
                history.push(`/exam/${examId}/`);
              }}
            />
          </div>
        ) : Object.keys(dxContext.zones).length ? (
          <div className="zone-validation">
            {examinationContext.examination.zones?.filter(
              (zone) => zone.id !== dxContext.currentZoneId && zone.status === 'validated'
            ).length >= 5 ? (
              <Button icon="tab" label={__('dx.checklist.openSummary')} onClick={() => openSummary()} />
            ) : examinationContext.examination.zones?.filter(
                (zone) => zone.id !== dxContext.currentZoneId && zone.status === 'validated'
              ).length === 4 ? (
              <Button
                icon="tab"
                label={__('dx.checklist.nothingElseOpenSummary')}
                onClick={() => {
                  if (dxContext.semiologyWorkflowStack?.length) dxContext.leaveSemiologyWorkflow();
                  validateCurrentZoneAndOpenSummary();
                }}
              />
            ) : examinationContext.examination.zones?.find((zone) => zone.id === dxContext.currentZoneId)?.status !==
              'validated' ? (
              <Button
                icon="done-all"
                label={__('dx.checklist.nothingElseGoToNextZone').replace(
                  '{nextZone}',
                  dxContext.zones?.[dxContext.getNextZoneId()]?.label[currentLanguage]
                )}
                onClick={() => {
                  if (dxContext.semiologyWorkflowStack?.length) dxContext.leaveSemiologyWorkflow();
                  validateCurrentZoneAndGoToNext();
                }}
              />
            ) : isCurrentZoneValidated ? (
              <Button
                icon="tab"
                label={__('dx.checklist.goToNextZone').replace(
                  '{nextZone}',
                  dxContext.zones?.[dxContext.getNextZoneId()]?.label[currentLanguage]
                )}
                onClick={() => {
                  if (dxContext.semiologyWorkflowStack?.length) dxContext.leaveSemiologyWorkflow();
                  goToNextZone();
                }}
              />
            ) : (
              false
            )}
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default withTranslation()(DiagnosticPanelView);
