import React, { useContext } from 'react';
import Icon from '../../atoms/Icon/Icon';
import { useExamination } from '../../context-providers/Examination';
import { AppContext } from '../../context-providers/App';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import ResourceApi from '../../services/resource';

function ReportVersion({ reportVersion, updateReportVersion }) {
  return (
    <div className="mb-small text-medium">
      <h3>Report Version</h3>
      <select
        id="report-version"
        style={{ color: 'black' }}
        value={reportVersion}
        onChange={(e) => updateReportVersion(e.target.value)}
      >
        <option value="1.0.0">1.0.0</option>
        <option value="2.0.0">2.0.0</option>
      </select>
    </div>
  );
}

function Summary({ examination, reportDataOptions }) {
  if (!examination) return null;

  const fields = [
    { label: 'id', value: examination.id },
    { label: 'date', value: examination.examination_date },
    { label: 'preset_id', value: examination.preset_id },
    { label: 'template_id', value: examination.template_id },
    { label: 'status', value: examination.status },
    { label: 'site_id', value: examination.site_id },
    { label: 'site_name', value: examination.site?.name },
    { label: 'practitioner_id', value: examination.practitioner_id },
    { label: 'reader_id', value: examination.reader_id },
    { label: 'episode_id', value: examination.episode_id },
    { label: 'report_template_id', value: reportDataOptions.report_template?.id },
    { label: 'report_template site_id', value: reportDataOptions.report_template?.site_id || 'null' },
    { label: 'report_template practice_id', value: reportDataOptions.report_template?.practice_id || 'null' },
    { label: 'report_template organization_id', value: reportDataOptions.report_template?.organization_id || 'null' },
  ];
  return (
    <div>
      <h3>Examination Summary</h3>
      {fields.map((field) => (
        <div>
          <label>{field.label}</label> {field.value}
        </div>
      ))}
    </div>
  );
}

export default function Examination({ examination, openChapter, setOpenChapter }) {
  const appContext = useContext(AppContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationContext = useExamination();
  const xmlTemplateContext = useXMLTemplate();
  let previousViewId = false;
  let sameViewIdCount = 0;
  const reportVersion = examinationContext.examination.report_version;

  const updateExamination = (attrs) => {
    /* Do not pass via the examination context as we need to be sure the info goes straight to the backend before reloading the page */
    ResourceApi.updateExamination(examination.id, attrs).then(() => window.location.reload());
  };

  return (
    <div className={`debug-section ${openChapter === 'Examination' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'Examination' ? false : 'Examination'))}>
        Examination <Icon name="down" />
      </h2>
      {openChapter === 'Examination' && (
        <div className="debug-subsection">
          {!examination ? (
            <div>No open exams</div>
          ) : (
            <>
              <ReportVersion
                reportVersion={reportVersion}
                updateReportVersion={(report_version) => updateExamination({ report_version })}
              />
              <Summary
                examination={examinationContext.examination}
                reportDataOptions={xmlTemplateContext.reportDataOptions}
              />
              <div className="mb-small text-medium">
                <h3>Association Dictionary</h3>
                <table>
                  <tbody>
                    {appContext.viewsDictionary
                      .sort((a, b) => a.instance_view_id - b.instance_view_id)
                      .map((term) => {
                        const viewId = term.instance_view_id;
                        sameViewIdCount = viewId === previousViewId ? sameViewIdCount + 1 : 0;
                        const label =
                          previousViewId === viewId
                            ? ''
                            : examinationContext.instanceViews?.[viewId]?.label[currentLanguage];
                        previousViewId = viewId;
                        return (
                          <React.Fragment key={`${viewId}-${sameViewIdCount}`}>
                            {label ? (
                              <tr className="spacer" key={`${viewId}-spacer`}>
                                <td colSpan="2" />
                              </tr>
                            ) : null}
                            <tr key={`${viewId}-${term.text_on_img}`}>
                              {label ? <th>{label}</th> : <td></td>}
                              <td>{term.text_on_img}</td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
