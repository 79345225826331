import React from 'react';

function provider(reducer, defaultState, displayName) {
  const StateContext = React.createContext(defaultState);
  const DispatchContext = React.createContext((action) => {});

  if (displayName) {
    StateContext.displayName = displayName;
  }

  let dispatchInstance = null;
  let stateInstance = null;
  const useState = () => React.useContext(StateContext);
  const useDispatch = () => React.useContext(DispatchContext);
  const getDispatch = () => dispatchInstance;
  const getState = () => stateInstance;

  const Provider = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, defaultState);
    dispatchInstance = dispatch;
    stateInstance = state;

    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
      </StateContext.Provider>
    );
  };

  return {
    useState,
    useDispatch,
    Provider,
    getDispatch,
    getState,
    dispatch: (...arg) => dispatchInstance(...arg),
  };
}

export default provider;
