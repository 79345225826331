import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../atoms/Button/Button';
import ResourceApi from '../../services/resource';
import loadGeneralExamAssocAttachments from '../../services/loadGeneralExamAssocAttachments';

const Popup = ({ message = false, hint = false, cta = false, onBackgroundClick = () => {} }) => {
  return (
    <div className="popup-container">
      <div className="popup">
        {!!message && <h1 className="popup-message" dangerouslySetInnerHTML={{ __html: message }}></h1>}
        {!!hint && <span className="popup-hint" dangerouslySetInnerHTML={{ __html: hint }}></span>}
        {!!cta && <div className="cta">{cta}</div>}
      </div>
      <div className="modal-background" onClick={onBackgroundClick} />
    </div>
  );
};

const getKey = (total) => {
  switch (total) {
    case 0:
      return 'WithoutPatients';
    case 1:
      return 'WithPatient';
    default:
      return 'WithPatients';
  }
};

const deletePopupHOC =
  (Component) =>
  ({ attachment, setUpdateError, ...restProps }) => {
    const { t: __ } = useTranslation();
    const [popup, setPopup] = useState(null);

    const handleConfirmClick = async () => {
      setPopup(false);
      try {
        await ResourceApi.deleteAttachment(attachment.id);
      } catch (e) {
        setUpdateError(__('sharingPreferences.document.deleteFailed'));
      } finally {
        loadGeneralExamAssocAttachments();
      }
    };

    const showDeletePopup = () => {
      setUpdateError(null);
      setPopup({
        message: __('sharingPreferences.deleteDocumentConfirmation', { title: attachment.title }),
        hint: __(`sharingPreferences.deleteDocument${getKey(attachment.assoc_count)}Hint`, {
          number: attachment.assoc_count,
        }),
        onBackgroundClick: () => setPopup(null),
        cta: (
          <>
            <Button label={__('CTA.cancel')} variant="outline" onClick={() => setPopup(null)} />
            <Button label={__('CTA.confirm')} onClick={handleConfirmClick} />
          </>
        ),
      });
    };

    return (
      <>
        {popup && <Popup {...popup} onBackgroundClick={() => setPopup(null)} />}
        <Component {...restProps} onClick={showDeletePopup} />
      </>
    );
  };

export default deletePopupHOC;
