import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../context-providers/App';
import Button from '../../../atoms/Button/Button';
import { formatName } from '../../../services/examination';
import { formatDateWithTZ, formatTime } from '../../../utils';
import './ExamCard.css';
import Icon from '../../../atoms/Icon/Icon';

const ExamCard = ({ t: __, exam, deleteExam = false, dateFormat = 'dd/mm/yyyy' }) => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const [mouseIsOver, setMouseIsOver] = useState(false);

  return (
    <div
      className="exam-card-container"
      onClick={() => history.push(`/exam/${exam.id}`)}
      onMouseOver={() => setMouseIsOver(true)}
      onMouseOut={() => setMouseIsOver(false)}
    >
      <div className="name">
        {exam.patient?.name ? formatName(exam.patient?.name)?.fullName : __('patients.anonymous')}
      </div>
      <div className="card-info">
        <span className="trimester">
          {appContext.allPresets?.find((preset) => preset.id === exam.preset_id)?.name}{' '}
          {exam.type === 'diagnostic' ? __('patients.diagnostics') : ''}
        </span>
        <span className="nbr-media">
          <Icon name="image" /> {exam.nbr_media}
        </span>
        <span className="day">
          <Icon name="calendar" /> {formatDateWithTZ(exam.updated_at, dateFormat)}
        </span>
        <span className="time">
          <Icon name="clock" /> {formatTime(exam.updated_at)}
        </span>
      </div>
      <div className="cta">
        <Button
          label={__('patients.exams.open')}
          variant={mouseIsOver ? '' : 'outline'}
          icon="tab"
          onClick={(event) => {
            event.stopPropagation();
            history.push(`/exam/${exam.id}`);
          }}
        />
        {deleteExam && (
          <div
            className="button outline cta-delete"
            onClick={(event) => {
              event.stopPropagation();
              deleteExam(exam);
            }}
          >
            <Icon name="trash" />
            <span>{__('patients.delete')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(ExamCard);
