import { useContext, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ExamTopBar from '../../components/ExamTopBar/ExamTopBar';
import { ExaminationContext } from '../../context-providers/Examination';
import { XMLDocument } from '../../components/XMLDocument';
import ResourceApi from '../../services/resource';
import './ExaminationCustomMedicalHistoryView.css';

const ExaminationCustomMedicalHistoryView = ({ t: __, reportDataOptions }) => {
  const examinationContext = useContext(ExaminationContext);
  const templateBlueprint = examinationContext.debugTemplate || reportDataOptions?.medical_history_template?.blueprint;

  const [frozenMedicalHistory, setFrozenMedicalHistory] = useState({
    state: 'not_loaded',
  });

  const readFrozenMedicalHistoryHtml = useCallback(
    async (mode) => {
      return ResourceApi.readDocumentHtml(examinationContext.examination.id, mode, 'medical_history')
        .then((response) => response)
        .catch(({ response }) => response);
    },
    [examinationContext.examination.id, examinationContext.examination.canEdit]
  );

  const readAllFrozenMedicalHistoryHtml = useCallback(async () => {
    const edit = await readFrozenMedicalHistoryHtml('edit');
    const print = await readFrozenMedicalHistoryHtml('print');
    return { edit, print };
  }, [readFrozenMedicalHistoryHtml]);

  useEffect(() => {
    if (examinationContext.examination.status !== 'report_signed') return;
    readAllFrozenMedicalHistoryHtml().then(({ edit, print }) => {
      if (edit.status === 200 && print.status === 200) {
        setFrozenMedicalHistory({
          state: 'loaded',
          edit: edit.data,
          print: print.data,
        });
      } else {
        setFrozenMedicalHistory({ state: 'error' });
      }
    });
    return () => setFrozenMedicalHistory({ state: 'not_loaded' });
  }, [readFrozenMedicalHistoryHtml, examinationContext.examination.status === 'report_signed']);

  const getError = () => {
    if (!examinationContext.examination?.patient_id) return __('medicalhistory.error.anonymousExam');
    if (!examinationContext.examination?.episode) return __('medicalhistory.error.missingEpisode');
  };

  return (
    <div className="custom-medical-history-container">
      {!!templateBlueprint && (
        <>
          <div className="custom-medical-history-header">
            <ExamTopBar openPanel="medical-history" setOpenPanel={() => {}} />
          </div>
          <div className="exam-report-content">
            <div className="exam-report-inner-content">
              {getError() ? (
                <ExaminationReportError error={getError()} />
              ) : (
                <>
                  {(examinationContext.canEdit || frozenMedicalHistory.state === 'error') && (
                    <XMLDocument templateBlueprint={templateBlueprint} page="medical-history" reportMode="edit" />
                  )}
                  {!examinationContext.canEdit && frozenMedicalHistory.state === 'loaded' && (
                    <XMLDocument
                      templateBlueprint={templateBlueprint}
                      page="medical-history"
                      reportMode="edit"
                      frozenHTML={frozenMedicalHistory.edit}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withTranslation()(ExaminationCustomMedicalHistoryView);

const ExaminationReportError = ({ error }) => {
  return <div className="exam-report-inner-content exam-report-error">{error}</div>;
};
