import { useState, useEffect } from 'react';
import ResourceApi from '../../services/resource';
import UplinkConfigurationView from './UplinkConfigurationView';

const UplinkConfiguration = () => {
  const [uplinks, setUplinks] = useState([]);

  const loadUplinks = () => {
    ResourceApi.listUplinks().then((resp) => setUplinks(resp.data.data));
  };

  useEffect(() => {
    loadUplinks();
  }, []);

  return <UplinkConfigurationView uplinks={uplinks} refresh={loadUplinks} />;
};

export default UplinkConfiguration;
