import axios from 'axios';

/* TODO: Deprecated on axios >= 0.22.0 , replaced by AbortController */
const CancelToken = axios.CancelToken;
let recommendationCancel = false;
/* /deprecated */

const DxAi = {
  recommendation: (data) => {
    if (recommendationCancel) recommendationCancel();
    return axios.post('/ai/recommendation', data, {
      cancelToken: new CancelToken(function executor(c) {
        recommendationCancel = c;
      }),
    });
  },
  recommendationCancelCall: () => (recommendationCancel ? recommendationCancel() : false),
  semiologyExamItems: (exam_item_id, gender = null, trimester = null) =>
    axios.post(`/ai/semiology/exam-item`, { exam_item_id, gender, trimester }),
  semiology: (data) => axios.post('ai/semiology', data),
  syndromeDetails: (syndrome_ids, gender, trimester, malformations_history) =>
    axios.post('ai/syndrome-details', {
      syndrome_ids,
      gender,
      trimester,
      malformations_history,
    }),
  malformationDetails: (malformation_ids) =>
    axios.get(`ai/malformation-details?malformation_ids=${malformation_ids.join(',')}`),
};

export default DxAi;
