import sanitizeHtml from 'sanitize-html';
import { useEffect, useState } from 'react';

export const ContentEditable = ({
  innerRef,
  html,
  disabled,
  onChange = () => {},
  onFocus,
  onBlur,
  sanitize = sanitizeHtml,
  ...props
}) => {
  const [safeHtml, setSafeHtml] = useState(html);

  useEffect(() => {
    setSafeHtml(sanitize(html));
  }, [html, sanitize]);

  const pasteAsPlainText = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');

    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const cleanUpAsYouType = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();

      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      const textNode = document.createTextNode('\n');
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);

      // Get the next sibling, skipping any empty text nodes
      let nextSibling = textNode.nextSibling;
      let count = 0; // Prevent infinite loops which happen for some reason
      while (nextSibling && nextSibling.nodeType === Node.TEXT_NODE && nextSibling.data === '' && count++ < 10000) {
        nextSibling = nextSibling.nextSibling;
      }

      // If there's no meaningful content after the \n, force another \n to ensure new line is created
      if (!nextSibling && !range.startContainer.wholeText.endsWith('\n\n')) {
        const doubledTextNode = document.createTextNode('\n');
        range.insertNode(doubledTextNode);
        range.selectNodeContents(doubledTextNode);
        range.collapse(false);
      }
    }
  };

  return (
    <div
      ref={innerRef}
      contentEditable={!disabled}
      dangerouslySetInnerHTML={{ __html: safeHtml }}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onPaste={pasteAsPlainText}
      onKeyDown={cleanUpAsYouType}
      {...props}
    />
  );
};
