export const extractFirstLine = (content, titles) => {
  const titlesRegexp = new RegExp(`\\b(${titles.join('|')})\\.`, 'i');
  const placeholder = '<<<DOT>>>';
  const replacedContent = `${content}`.replace(titlesRegexp, (m) => m.replace('.', placeholder));

  let [firstLine, ...rest] = replacedContent.split('\n');
  firstLine = firstLine.replace(placeholder, '.');
  rest = rest.join('\n');

  return [
    firstLine.trimEnd() + '\n', // since it will be displayed in an inline element, it needs to end with a \n
    rest.trimEnd(),
  ];
};
