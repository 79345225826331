import React, { useState } from 'react';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import Icon from '../../atoms/Icon/Icon';
import Button from '../../atoms/Button/Button';
import { isNullOrUndefined } from '../../utils';

export default function Report({ examination, openChapter, setOpenChapter }) {
  if (examination == null) return <div>No open exams</div>;
  const { placeholders } = useXMLTemplate();
  const [openedValue, setOpenedValue] = useState('');

  const ReportContent = () => {
    return (
      <div className="debug-subsection">
        <div>
          <label>Preset ID</label> {examination?.preset_id}
        </div>
        <div>
          <label>Template ID</label> (undefined until Exam finalizes)
        </div>
        <br />
        <div>
          <label>Last loaded placeholders</label>
        </div>
        <div>
          {Object.entries(placeholders || {})
            .sort((a, b) => a[0].localeCompare(b[0]))
            .map(([key, value]) => (
              <div className={openedValue === key ? 'open' : ''}>
                <div className="debug-subsection_title">
                  <b onClick={() => setOpenedValue((k) => (k === key ? '' : key))}>
                    {key} <Icon name="down" />
                  </b>{' '}
                  <span onClick={() => navigator.clipboard.writeText(key)}>
                    <Button icon="paste" label="copy" size="small" />
                  </span>
                </div>
                <div className="value">
                  {!!value && Array.isArray(value) ? (
                    value.map((fetusValue, fetusNumber) => (
                      <>
                        <b>Fetus {fetusNumber}</b>
                        <table>
                          <tbody>
                            {Object.entries(fetusValue).map(([param, value]) => (
                              <tr>
                                <th>{param}</th>
                                <td>{!isNullOrUndefined(value) && JSON.stringify(value)?.replace(',', ', ')}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ))
                  ) : (
                    <table>
                      <tbody>
                        {Object.entries(value || {}).map(([param, value]) => (
                          <tr>
                            <th>{param}</th>
                            <td>{!isNullOrUndefined(value) && JSON.stringify(value)?.replace(',', ', ')}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };
  return (
    <div className={`debug-section ${openChapter === 'report' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'report' ? false : 'report'))}>
        Report data for the current exam <Icon name="down" />
      </h2>
      {openChapter === 'report' && <ReportContent />}
    </div>
  );
}
