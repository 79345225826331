import ResourceApi from '../services/resource';
import changeStatusGeneralDoc from '../providers/examSharing/actions/changeStatusGeneralDoc';

const changeStatusGeneralDocument = async (shareDoc) => {
  const { status } = await ResourceApi.updateStatusSharingDocument(
    shareDoc.examination_id,
    shareDoc.id,
    !shareDoc.active
  );

  if (status === 200) {
    changeStatusGeneralDoc({ ...shareDoc, active: !shareDoc.active });
  }
};

export default changeStatusGeneralDocument;
