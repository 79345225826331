import Icon from '../../../atoms/Icon/Icon';
import SlideDrawing from '../../../components/Slide/SlideDrawing/SlideDrawing';
import React from 'react';
import { withTranslation } from 'react-i18next';

export default withTranslation()(NotToForget);

function NotToForget({
  t: __,
  medicalHistory,
  currentLanguage,
  trimester,
  checklistItems,
  slides,
  slidesRiskFactors,
  checklistItemsRiskFactors,
  selectedRiskFactorId,
  resetSelectedRiskFactorId,
}) {
  const selectedRiskFactor = medicalHistory.find((item) => item.risk_factor_id === selectedRiskFactorId);

  const shouldBeVisible = (riskFactors) =>
    !selectedRiskFactorId || riskFactors?.some((risk) => risk.id === selectedRiskFactorId);

  return (
    <div>
      <h2 className="section-title">
        {__('examination.notToForget')}
        {selectedRiskFactor && (
          <span className="selected-risk-factor">
            {selectedRiskFactor.label} {__(':') + ' '}
            {selectedRiskFactor.value}
            <Icon name="close" onClick={resetSelectedRiskFactorId} />
          </span>
        )}
      </h2>
      <div className="examination-live-phenotype-panel-slide-risk-factors">
        {!Object.values(slidesRiskFactors).filter((slide) => !!slide.risk_factors?.length).length ? (
          <div className="examination-live-phenotype-panel-norisks">{__('examination.noRiskFactors')}</div>
        ) : (
          Object.keys(slidesRiskFactors).map((slideId) => {
            if (!slidesRiskFactors[slideId]?.risk_factors?.length) return false;
            const slide = slides.find((s) => s.id === Number(slideId));
            if (!slide) return false;
            return (
              <div
                className={shouldBeVisible(slidesRiskFactors[slideId].risk_factors) ? 'visible-risk' : 'hidden-risk'}
                key={slide.id}
              >
                <div className="examination-live-phenotype-panel-slide">
                  <SlideDrawing version="2" trimester={trimester} slide={slide} type={slide?.type} />
                  <div className="examination-live-phenotype-panel-checklist">
                    <div className="examination-live-phenotype-panel-name">{slide.label[currentLanguage]}</div>
                    <ul>
                      {checklistItems
                        .filter(
                          (item) =>
                            item.instance_view_id[trimester].includes(slide.id) &&
                            !!checklistItemsRiskFactors[item.id]?.risk_factors.length
                        )
                        .map((item) => (
                          <li
                            key={item.id}
                            className={
                              shouldBeVisible(checklistItemsRiskFactors[item.id].risk_factors)
                                ? 'visible-risk'
                                : 'hidden-risk'
                            }
                          >
                            {item.label[currentLanguage]}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
