import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Icon from '../../atoms/Icon/Icon';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import ExamNavBarDetect from '../../components-dt/ExamNavBar/ExamNavBar';
import ExamCompletedBannerDetect from '../../components-dt/Examination/ExamCompletedBanner/ExamCompletedBanner';
import ExamConfirmMatchingBanner from '../../components-dt/Examination/ExamConfirmMatchingBanner/ExamConfirmMatchingBanner';
import SlideBrowserDetect from '../../components-dt/Examination/SlideBrowser/SlideBrowser';
import SlideGridDetect from '../../components-dt/Examination/SlideGrid/SlideGrid';
import SlidePanelDetect from '../../components-dt/Examination/SlidePanel/SlidePanel';
import ExamNavBarRoutine from '../../components/ExamNavBar/ExamNavBar';
import ExamPresetSelector from '../../components/ExamPresetSelector';
import ExamPresetButtons from '../../components/ExamPresetButtons/ExamPresetButtons';
import SlideBrowserRoutine from '../../components/Examination/SlideBrowser/SlideBrowser';
import SlideGridRoutine from '../../components/Examination/SlideGrid/SlideGrid';
import SlidePanelRoutine from '../../components/Examination/SlidePanel/SlidePanel';
import GlobalSearchBar from '../../components/GlobalSearchBar/GlobalSearchBar';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import { NotificationContext } from '../../context-providers/Notification';
import { ZoomLevelContext } from '../../context-providers/ZoomLevel';
import { AmbientListeningContext } from '../../context-providers/AmbientListening';
import { isNullOrUndefined } from '../../utils';
import { formatName } from '../../services/examination';
import ExaminationReport from '../ExaminationReport';
import ExaminationReviewView from '../ExaminationReview';
import './ExaminationView.css';
import FetusPanel from './FetusPanel/FetusPanel';
import PhenotypePanel from './PhenotypePanel/PhenotypePanel';
import SearchBar from './SearchBar/SearchBar';
import { LiveExaminationContext } from '../../context-providers/LiveExamination';
import Button from '../../atoms/Button/Button';
import { WindowContext } from '../../context-providers/Window';
import ArrangeOnTop from '../../atoms/ArrangeOnTop/ArrangeOnTop';
import ResourceApi from '../../services/resource';
import MeasurementsDialog from '../ExaminationReport/MeasurementsDialog';
import EditChecklistItems from '../ExaminationReport/editors/EditChecklistItems';
import ViewExamStakeholdersDialog from '../ExaminationReport/ViewExamStakeholdersDialog';
import { useXMLDocumentPrinter } from '../../components/XMLDocument';
import ViewExamEventsDialog from '../ExaminationReport/ViewExamEventsDialog';
import PreviewExamReportDialog from '../ExaminationReport/PreviewExamReportDialog';
import { ExamStatus } from '../../config';
import SharingSelect from '../ExaminationSharingFlow/SharingSelect';
import SharingPreviewPopup from '../ExaminationReview/SharingPreviewPopup';
import PrintPreview from '../ExaminationReview/PrintPreview';
import ExamTopBar from '../../components/ExamTopBar/ExamTopBar';
import { PreviewAttachmentPopup } from './PhenotypePanel/Documents';

const planeToShow = (userId, examId, defaultPlane) => {
  const json =
    window.localStorage.getItem(`defaultShownPlanes-user-${userId}`) || JSON.stringify([examId, defaultPlane]);
  const [savedExam, savedPlane] = JSON.parse(json);
  return savedExam === examId ? savedPlane : defaultPlane;
};

async function uploadDicomFile(file, examination_id) {
  const formData = new FormData();
  formData.append('attachment', file);
  const response = await ResourceApi.uploadExamFile(examination_id, formData);
  const errors = response?.data?.errors;
  if (errors) {
    throw new Error(errors);
  }
}

function useManualUpload(__) {
  const { showNotification } = useContext(NotificationContext);
  const examination_id = useContext(ExaminationContext)?.examination?.id;
  const [currentUploads, setCurrentUploads] = useState([]);
  const updateUploadStatus = useCallback(
    (file, status) => {
      setCurrentUploads((currentUploads) =>
        currentUploads.map((upload) => (upload.file === file ? { ...upload, status } : upload))
      );
    },
    [setCurrentUploads]
  );

  const onManualUpload = useCallback(
    async (files) => {
      setCurrentUploads((currentUploads) => [
        ...currentUploads,
        ...files.map((file) => ({
          status: 'uploading',
          file,
        })),
      ]);
      const promises = files.map(async (file) => {
        try {
          await uploadDicomFile(file, examination_id);
          updateUploadStatus(file, 'success');
          return file;
        } catch (error) {
          console.log('error', error);
          const reason = error?.response?.data?.error;
          updateUploadStatus(file, 'failed');
          showNotification(
            <>
              <Icon name="warning" /> {__('examination.manualUpload.error', { filename: file.name, error: reason })}
            </>,
            5000
          );
        }
      });
      return await Promise.all(promises);
    },
    [examination_id, showNotification, setCurrentUploads, updateUploadStatus, __]
  );

  return [onManualUpload, currentUploads];
}

const ExaminationView = ({
  t: __,
  instanceViews,
  slides,
  slidesRiskFactors,
  medias,
  setMedias,
  currentTrimester,
  currentPreset,
  fetusSex,
  medicalHistory,
  checklistItems,
  checklistItemsRiskFactors,
  anomalies,
  unusualMedias,
  nextMatchingSlide,
  setLastMatchedSlide,
  isPaused,
  toggleIsPaused,
  isManual,
  toggleIsManual,
  setExaminationNextView,
  assocInstanceExam,
  setSharingStatusForInstance,
  isInstanceUnusual,
  naked = false,
  nakedProps = {},
  fetalSexField,
  sexConfirmedBy,
  sexContradictedBy,
  placentaPositionField,
  placentaPositionConfirmedBy,
  placentaPositionContradictedBy,
}) => {
  const windowContext = useContext(WindowContext);
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);
  const liveExaminationContext = useContext(LiveExaminationContext);
  const ambientListeningContext = useContext(AmbientListeningContext);
  const { zoomLevel, zoomIn, zoomOut } = useContext(ZoomLevelContext);
  const history = useHistory();
  const location = useLocation();
  const { isFeatureFlagEnabled, user } = useAuth();

  const { editReportBackup, isEditMode, setIsEditMode, setReportData, setAutomationTemplateFieldsVisible } =
    useXMLTemplate();

  const [currentSection, setCurrentSection] = useState(false);
  const [openPanel, doSetOpenPanel] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const [selectedPlane, setSelectedPlane] = useState(false);
  const [oneShotIncomingInstance, setOneShotIncomingInstance] = useState(false);
  const [atRiskSlides, setAtRiskSlides] = useState([]);
  const [shownPlanes, setShownPlanes] = useState(planeToShow(user.id, examinationContext.examination?.id, 'all'));
  const [sortPlanesBy, setSortPlanesBy] = useState(
    window.localStorage.getItem(`defaultSortPlanesBy-user-${user.id}`) || 'slide'
  );
  const [showSlideBrowser, setShowSlideBrowser] = useState(false);
  const [videos, setVideos] = useState([]);
  const [extractedFrames, setExtractedFrames] = useState([]);
  const [voiceSubmenuOpen, setVoiceSubmenuOpen] = useState(false);
  const imageChapterRef = useRef(false);
  const matchingChapterRef = useRef(false);
  const videoChapterRef = useRef(false);

  /* Load examination HTML reports */
  const [frozenReport, setFrozenReport] = useState({ state: 'not_loaded' });

  const readFrozenReportHtml = useCallback(
    async (mode) => {
      return ResourceApi.readDocumentHtml(examinationContext.examination.id, mode, 'report')
        .then((response) => response)
        .catch(({ response }) => response);
    },
    [examinationContext.examination.id, examinationContext.examination.canEdit]
  );

  const readAllFrozenReportHtml = useCallback(async () => {
    const edit = await readFrozenReportHtml('edit');
    const print = await readFrozenReportHtml('print');
    return { edit, print };
  }, [readFrozenReportHtml]);
  useEffect(() => {
    if (examinationContext.examination.status !== 'report_signed') return;
    readAllFrozenReportHtml().then(({ edit, print }) => {
      if (edit.status === 200 && print.status === 200) {
        setFrozenReport({
          state: 'loaded',
          edit: edit.data,
          print: print.data,
        });
      } else {
        setFrozenReport({ state: 'error' });
      }
    });
    return () => setFrozenReport({ state: 'not_loaded' });
  }, [readFrozenReportHtml, examinationContext.examination.status === 'report_signed']);

  const highlightNextMatching = useMemo(
    () => !isFeatureFlagEnabled('sonio.detect') || isManual || isPaused,
    [isFeatureFlagEnabled, isManual, isPaused]
  );

  const trackEvent = (event_type, event_data) => {
    ResourceApi.createBIEvent({
      ...event_data,
      event_type: event_type,
      examination_id: examinationContext.examination.id,
      examination_status: examinationContext.examination.status,
      examination_preset_id: examinationContext.examination.preset_id,
      detect: isFeatureFlagEnabled('sonio.detect'),
    });
  };

  const filteredSlides = useMemo(() => {
    const filteredSlides = [...slides].filter(
      (slide) =>
        shownPlanes === 'all' ||
        (shownPlanes === 'taken' && (slide.mediaId || slide.is_video)) ||
        (shownPlanes === 'left' && !slide.mediaId) ||
        (shownPlanes === 'unusual' && unusualMedias.some((mediaId) => mediaId === slide.mediaId))
    );

    const output = {
      protocol: [],
      optional: [],
      unmatched: [],
      videos: [],
    };

    if (sortPlanesBy === 'slide') {
      output.protocol = [...filteredSlides].filter((s) => !s.optional && s.type === 'picture');
      output.optional = [...filteredSlides].filter(
        (s) => (s.optional && s.type === 'picture') || (s.type === 'other' && (s.verified || !s.mediaId) && !s.is_video)
      );
      output.unmatched = [...filteredSlides].filter((s) => s.mediaId && !s.optional && !s.verified && !s.is_video);
      output.videos = [...filteredSlides].filter((s) => s.is_video);
    } else if (sortPlanesBy === 'time') {
      output.protocol = [...filteredSlides].sort((a, b) =>
        sortPlanesBy === 'time'
          ? a.inserted_at === b.inserted_at
            ? 0
            : (a.inserted_at || 'z') > (b.inserted_at || 'z')
            ? 1
            : -1
          : 0
      );
    } else {
      output.protocol = [...filteredSlides];
    }

    return output;
  }, [slides, shownPlanes, sortPlanesBy]);

  const editedByAmbientListeningCounter = useMemo(() => {
    return Object.keys(ambientListeningContext.editedByAmbientListening || {}).length;
  }, [ambientListeningContext.editedByAmbientListening]);

  useEffect(() => {
    if (examinationContext.examination?.id && isFeatureFlagEnabled('soniopedia')) {
      history.replace(`/exam/${examinationContext.examination.id}/dx`);
    }
  }, [history, isFeatureFlagEnabled, examinationContext.examination.id]);

  const setOpenPanel = (panel) => {
    if (typeof panel === 'function') {
      panel = panel(openPanel);
    }

    const panelToWindow = {
      stats: 'report',
      phenotype: 'anamnesis',
      slide: 'slide',
    };

    if (isEditMode) {
      setIsEditMode(false);
      setAutomationTemplateFieldsVisible(false);
      setReportData(editReportBackup);
    }

    if (
      panelToWindow[panel] &&
      isFeatureFlagEnabled('sonio.multiscreen') &&
      !windowContext.isDetached &&
      (windowContext.windowExists(panelToWindow[panel]) ||
        windowContext.getAllActiveWindows().some((w) => w.view === panelToWindow[panel]))
    ) {
      windowContext.detach(panelToWindow[panel], [examinationContext.examination.id]);
    } else {
      if (!panel && location.hash === '#report') history.replace(location.pathname);
      doSetOpenPanel(panel);
    }
  };

  useEffect(() => {
    if (selectedPlane) {
      setOpenPanel(false);
      setOpenPopup(false);
    }
  }, [selectedPlane]);

  useEffect(() => {
    if (openPanel) {
      setSelectedPlane(false);
      setOpenPopup(false);
    }
  }, [openPanel]);

  const parseWindowMessage = (e) => {
    if (e.origin !== window.location.origin) return;
    if (!isNullOrUndefined(e.data?.setSelectedPlane)) {
      setSelectedPlane(e.data?.setSelectedPlane);
    }
  };

  useEffect(() => {
    if (selectedPlane?.media?.id) {
      const media = examinationContext.instances.find((instance) => instance.id === selectedPlane.media.id);
      if (media) setSelectedPlane({ ...selectedPlane, media });
    }
  }, [examinationContext.instances]);

  const onCloseWindow = () => {
    windowContext.postMessageToView('core', { setSelectedPlane: false });
  };

  useEffect(() => {
    window.addEventListener('message', parseWindowMessage);
    window.addEventListener('beforeunload', onCloseWindow);
    return () => {
      window.removeEventListener('message', parseWindowMessage);
      window.removeEventListener('beforeunload', onCloseWindow);
    };
  }, []);

  useEffect(() => {
    if (
      examinationContext?.patient?.id &&
      examinationContext?.patient?.show_notes &&
      !!examinationContext?.patient?.notes
    ) {
      doSetOpenPanel((panel) => (panel === 'phenotype' ? panel : 'phenotype'));
    }
  }, [examinationContext?.patient?.id]);

  useEffect(() => {
    setExtractedFrames(
      selectedPlane?.media?.dicom_media_type !== 'video'
        ? []
        : medias
            .filter((media) => media?.dicom_origin_id === selectedPlane.media.id)
            .map((frame) => ({
              ...frame,
              slide: slides.find((slide) => slide.id === frame.slideId),
            }))
    );
  }, [JSON.stringify(selectedPlane?.media), JSON.stringify(medias), slides]);

  useEffect(() => {
    const atRiskSlides = Object.entries(slidesRiskFactors)
      .map(([key, value]) => (value.risk_factors.length ? Number(key) : false))
      .filter((riskId) => !!riskId);
    setAtRiskSlides(() => atRiskSlides);
  }, [slidesRiskFactors, slides]);

  useEffect(() => {
    setSelectedPlane(false);
    setShownPlanes(planeToShow(user.id, examinationContext.examination.id, 'all'));
  }, [examinationContext.examination.id, user.id]);

  /** follow the image on reassociation when image is open in a popup */
  useEffect(() => {
    if (examinationContext.lastReassociation && windowContext.windowExists('slide')) {
      splitScreen('slide', [examinationContext.lastReassociation.key]);
    }
  }, [examinationContext.lastReassociation]);

  const splitScreen = useCallback(
    (view, props) => {
      windowContext.detach(view, [examinationContext?.examination?.id, ...(props || [])]);
      setOpenPanel(false);
    },
    [examinationContext?.examination?.id, windowContext.detach, setOpenPanel, setSelectedPlane]
  );

  const onDoubleClickHandle = useCallback(
    (item, callbackFunctionParams) => {
      windowContext.windowExists('slide') && splitScreen('slide', [item?.key || callbackFunctionParams?.media?.id]);
      setSelectedPlane(callbackFunctionParams);
    },
    [splitScreen, windowContext.windowExists]
  );

  const [onManualUploadHandler] = useManualUpload(__);

  const onClickHandle = useCallback(
    async (item, callbackFunctionParams, next) => {
      if (isFeatureFlagEnabled('sonio.detect')) {
        if (isPaused) {
          item.type === 'other' ? toggleIsPaused(false) : toggleIsManual(item);
        } else if (isManual) {
          item.type === 'other'
            ? toggleIsPaused(true)
            : toggleIsManual(item?.id === nextMatchingSlide?.id ? false : item);
        } else {
          onDoubleClickHandle(item, callbackFunctionParams, next);
        }
      } else {
        if (highlightNextMatching && item.key === next?.key) {
          onDoubleClickHandle(item, callbackFunctionParams, next);
        } else if (item.type === 'picture') {
          await setExaminationNextView(item);
        } else if (item.dicom_media_type !== 'video') {
          toggleIsPaused(true);
        }
      }
    },
    [
      onDoubleClickHandle,
      highlightNextMatching,
      toggleIsPaused,
      toggleIsManual,
      nextMatchingSlide,
      isFeatureFlagEnabled,
    ]
  );

  const onPlaneClose = useCallback(() => {
    setSelectedPlane(null);
    history.push();
  }, []);

  useEffect(() => {
    const newSlide = slides
      .filter((slide) => !!slide.mediaId)
      .find((slide) => slide?.mediaId === selectedPlane?.media?.id);

    !!selectedPlane &&
      !!newSlide &&
      selectedPlane?.slide?.id !== newSlide?.id &&
      setSelectedPlane({
        slide: newSlide,
        media: selectedPlane?.media,
      });
  }, [slides, selectedPlane?.media]);

  useEffect(() => {
    if (!selectedPlane?.media) return false;
    const newMedia = medias.find((media) => media.id === selectedPlane?.media?.id);
    newMedia !== selectedPlane?.media &&
      setSelectedPlane((plane) => ({
        slide: plane?.slide,
        media: medias.find((media) => media.id === selectedPlane?.media.id),
      }));
  }, [JSON.stringify(medias)]);

  useEffect(() => {
    !selectedPlane?.slide && !selectedPlane?.media && setSelectedPlane(false);
  }, [JSON.stringify(selectedPlane)]);

  useEffect(() => {
    setOneShotIncomingInstance(liveExaminationContext.incomingDicomInstance?.id);
  }, [liveExaminationContext.incomingDicomInstance]);

  useEffect(() => {
    /* auto-zoom all views */
    if (
      !oneShotIncomingInstance ||
      currentTrimester === 'ND' ||
      window.localStorage.getItem('zoomUponReceive') !== 'on' ||
      liveExaminationContext.incomingDicomInstance?.dicom_origin_id ||
      liveExaminationContext.incomingDicomInstance?.modality === 'SR' ||
      !medias.some((media) => media.id === oneShotIncomingInstance)
    )
      return false;

    setOneShotIncomingInstance(false);
    setSelectedPlaneByMediaId(liveExaminationContext.incomingDicomInstance?.id);
  }, [medias, oneShotIncomingInstance, liveExaminationContext.incomingDicomInstance]);

  useEffect(() => {
    /* auto-zoom unusual views */
    if (!examinationContext.isExaminationLoaded || window.localStorage.getItem('zoomUponReceive') !== 'suboptimal')
      return false;

    const predictedMedia = medias.find(
      (media) => media.id === liveExaminationContext.incomingQCPrediction?.[0]?.dicom_instance_id
    );
    if (!predictedMedia || predictedMedia.modality === 'SR') return false;

    const isUnusual = isInstanceUnusual({
      ...predictedMedia,
      qc_prediction: liveExaminationContext.incomingQCPrediction?.[0]?.qc_prediction,
    });
    if (
      isUnusual &&
      !predictedMedia.dicom_origin_id // exclude extracted images
    )
      setSelectedPlaneByMediaId(predictedMedia.id);
  }, [liveExaminationContext.incomingQCPrediction]);

  useEffect(() => {
    const videos = medias.filter((media) => media.dicom_media_type === 'video');
    setVideos(videos.filter((video, index) => videos.findIndex((v) => v.id === video.id) === index));
  }, [medias]);

  useEffect(() => {
    if (location.hash === '#report') {
      setOpenPanel('stats');
      setSelectedPlane(false);
      history.replace(location.pathname);
    } else if (location.hash === '#anamnesis') {
      setOpenPanel('phenotype');
      history.replace(location.pathname);
    }
  }, [examinationContext.examination.id, location.hash]);

  useEffect(() => {
    if (location.hash.indexOf('#media-') === 0) {
      const mediaId = Number(location.hash.split('-')[1]);
      const matchingElement = document.getElementById(`matching-media-${mediaId}`);
      if (matchingElement) {
        // this allow us to easily display proposed associations in the zoomed slide
        const event_down = new MouseEvent('mousedown', {
          view: window,
          cancelable: true,
          bubbles: true,
        });
        matchingElement.dispatchEvent(event_down);
        const event_up = new MouseEvent('mouseup', {
          view: window,
          cancelable: true,
          bubbles: true,
        });
        matchingElement.dispatchEvent(event_up);
      } else {
        const media = medias.find((m) => m.id === mediaId);
        const slide = slides.find((s) => s.mediaId === mediaId);
        setSelectedPlane({ slide, media });
      }
    }
  }, [examinationContext.examination.id, location.hash, medias, slides]);

  const setSelectedPlaneByKey = useCallback(
    (key) => {
      const slide = slides.find((s) => s.key === key);
      const media = medias.find((m) => m.id === slide?.mediaId);
      setSelectedPlane({ slide, media });
      if (!windowContext.isCompared) {
        windowContext.postMessageToView('core', {
          setSelectedPlane: { slide, media },
        });
      }
    },
    [medias, slides, windowContext.isCompared]
  );

  const setSelectedPlaneByMediaId = useCallback(
    (mediaId) => {
      const media = medias.find((m) => m.id === mediaId);
      const slide = slides.find((s) => s.id === media?.slideId);
      setSelectedPlane({ slide, media });
      windowContext.postMessageToView('core', {
        setSelectedPlane: { slide, media },
      });
    },
    [medias, slides]
  );

  useEffect(() => {
    if (nakedProps.slideKey) {
      if (nakedProps.slideKey.includes('_')) {
        setSelectedPlaneByKey(nakedProps.slideKey);
      } else {
        setSelectedPlaneByMediaId(Number(nakedProps.slideKey));
      }
    }
  }, [nakedProps.slideKey, setSelectedPlaneByKey, setSelectedPlaneByMediaId]);

  useEffect(() => {
    if (examinationContext?.examination?.preset?.imaging_expected === false) setOpenPanel('stats');
  }, [examinationContext?.examination?.preset?.imaging_expected]);

  useEffect(() => examinationContext.cleanInstancePreviewBlobs(), []);

  useEffect(() => {
    window.localStorage.setItem(
      `defaultShownPlanes-user-${user.id}`,
      JSON.stringify([examinationContext.examination?.id, shownPlanes])
    );
    windowContext.refreshWindow('slide');
  }, [shownPlanes, examinationContext.examination?.id, user.id]);

  useEffect(() => {
    window.localStorage.setItem(`defaultSortPlanesBy-user-${user.id}`, sortPlanesBy);
    windowContext.refreshWindow('slide');
  }, [sortPlanesBy]);

  useEffect(() => examinationContext.cleanInstancePreviewBlobs(), []);

  const showTopBar = !isFeatureFlagEnabled('sonio.detect') || isFeatureFlagEnabled('sonio.routine');
  const showBanner = showTopBar && examinationContext.isCompleted();
  const showDetectBanner = !showTopBar && showBanner;
  const showDetectConfirmBanner = !showTopBar && !examinationContext.isCompleted();

  const filterOptions =
    currentTrimester === 'ND'
      ? [
          {
            type: 'heading',
            label: __('examination.settings.zoomLevel'),
          },
          {
            icon: 'zoom-in',
            label: __('examination.settings.zoomIn'),
            value: 'zoom-in',
            disabled: zoomLevel <= 1,
            autoClose: false,
          },
          {
            icon: 'zoom-out',
            label: __('examination.settings.zoomOut'),
            value: 'zoom-out',
            disabled: zoomLevel > 5,
            autoClose: false,
          },
        ]
      : [
          {
            type: 'heading',
            label: __('examination.settings.display'),
          },
          {
            icon: shownPlanes === 'all' ? 'done' : 'empty',
            label: __('examination.settings.showAll'),
            value: 'showAll',
          },
          {
            icon: shownPlanes === 'taken' ? 'done' : 'empty',
            label: __('examination.settings.showTakenPlanes'),
            value: 'showTakenPlanes',
          },
          {
            icon: shownPlanes === 'left' ? 'done' : 'empty',
            label: __('examination.settings.showLeftPlanes'),
            value: 'showLeftPlanes',
          },
          {
            icon: shownPlanes === 'unusual' ? 'done' : 'empty',
            label: __('examination.settings.showUnusualPlanes'),
            value: 'showUnusualPlanes',
          },
          {
            type: 'separator',
          },
          {
            type: 'heading',
            label: __('examination.settings.sorting'),
          },
          {
            icon: sortPlanesBy === 'slide' ? 'done' : 'empty',
            label: __('examination.settings.sortByRoutine'),
            value: 'sortBySlide',
          },
          {
            icon: sortPlanesBy === 'time' ? 'done' : 'empty',
            label: __('examination.settings.sortByTime'),
            value: 'sortByTime',
          },
          {
            type: 'separator',
          },
          {
            type: 'heading',
            label: __('examination.settings.zoomLevel'),
          },
          {
            icon: 'zoom-in',
            label: __('examination.settings.zoomIn'),
            value: 'zoom-in',
            disabled: zoomLevel <= 1,
            autoClose: false,
          },
          {
            icon: 'zoom-out',
            label: __('examination.settings.zoomOut'),
            value: 'zoom-out',
            disabled: zoomLevel > 5,
            autoClose: false,
          },
        ];

  const filterChangeAction = (action) => {
    let filter;
    let value;

    switch (action) {
      case 'showAll':
        filter = 'show';
        value = 'all';
        break;
      case 'showTakenPlanes':
        filter = 'show';
        value = 'taken';
        break;
      case 'showLeftPlanes':
        filter = 'show';
        value = 'left';
        break;
      case 'showUnusualPlanes':
        filter = 'show';
        value = 'unusual';
        break;
      case 'sortBySlide':
        filter = 'sort';
        value = 'slide';
        break;
      case 'sortByTime':
        filter = 'sort';
        value = 'time';
        break;
      case 'zoom-in':
        filter = 'zoom';
        value = 'in';
        break;
      case 'zoom-out':
        filter = 'zoom';
        value = 'out';
        break;
    }

    switch (filter) {
      case 'show':
        setShownPlanes(value);
        break;
      case 'sort':
        setSortPlanesBy(value);
        break;
      case 'zoom':
        value === 'in' ? zoomIn() : zoomOut(3);
        break;
    }

    trackEvent('examination_view_update_filter', { filter, value });
  };

  const returnNakedComponents = () => {
    if (naked === 'report') {
      return (
        <>
          <ExaminationReport setOpenPopup={setOpenPopup} frozenReport={frozenReport} />
          <Popups openPopup={openPopup} setOpenPopup={setOpenPopup} t={__} frozenReport={frozenReport} />
        </>
      );
    } else if (naked === 'slide') {
      if (!selectedPlane) return <></>;

      return (
        <div
          className={`examination-live-container ${
            notificationContext.notifications.length ? 'has-notifications' : ''
          } status-${examinationContext.examination.status}`}
        >
          <div className={`examination-live-panel selected-plane ${!!selectedPlane && 'open'}`}>
            {isFeatureFlagEnabled('sonio.detect') ? (
              <SlidePanelDetect
                plane={selectedPlane}
                slides={slides}
                filteredSlides={Object.values(filteredSlides).flat()}
                extractedFrames={extractedFrames}
                trimester={currentTrimester}
                checklistItems={checklistItems.filter((item) =>
                  item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id)
                )}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
                anomalies={anomalies}
                setLastMatchedSlide={setLastMatchedSlide}
                setSelectedPlaneByKey={setSelectedPlaneByKey}
                setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                onClose={onPlaneClose}
                setShowSlideBrowser={setShowSlideBrowser}
              />
            ) : (
              <SlidePanelRoutine
                plane={selectedPlane}
                slides={slides}
                filteredSlides={Object.values(filteredSlides).flat()}
                extractedFrames={extractedFrames}
                trimester={currentTrimester}
                checklistItems={checklistItems.filter((item) =>
                  item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id)
                )}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
                anomalies={anomalies}
                setLastMatchedSlide={setLastMatchedSlide}
                setSelectedPlaneByKey={setSelectedPlaneByKey}
                setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                onClose={onPlaneClose}
                setShowSlideBrowser={setShowSlideBrowser}
              />
            )}
          </div>
          {!!showSlideBrowser &&
            (isFeatureFlagEnabled('sonio.detect') ? (
              <SlideBrowserDetect
                slides={slides}
                selectedSlideId={showSlideBrowser?.slide?.id}
                trimester={currentTrimester}
                onChange={async (slide) => {
                  if (isNullOrUndefined(slide?.id)) return false;
                  if (showSlideBrowser.media) {
                    const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                    setMedias((medias) =>
                      medias.map((m) =>
                        m.id === showSlideBrowser.media.id
                          ? {
                              ...m,
                              slideId: slide.id,
                              idx_in_template: slide.idx_in_template,
                              idx_in_group: assoc.idx_in_group,
                            }
                          : m
                      )
                    );
                  }
                  if (showSlideBrowser.callback) showSlideBrowser.callback();
                  setShowSlideBrowser(false);
                }}
                onClose={() => setShowSlideBrowser(false)}
              />
            ) : (
              <SlideBrowserRoutine
                slides={slides}
                selectedSlideId={showSlideBrowser?.slide?.id}
                trimester={currentTrimester}
                onChange={async (slide) => {
                  if (isNullOrUndefined(slide?.id)) return false;
                  if (showSlideBrowser.media) {
                    const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                    setMedias((medias) =>
                      medias.map((m) =>
                        m.id === showSlideBrowser.media.id
                          ? {
                              ...m,
                              slideId: slide.id,
                              idx_in_template: slide.idx_in_template,
                              idx_in_group: assoc.idx_in_group,
                            }
                          : m
                      )
                    );
                  }
                  if (showSlideBrowser.callback) showSlideBrowser.callback();
                  setShowSlideBrowser(false);
                }}
                onClose={() => setShowSlideBrowser(false)}
              />
            ))}
        </div>
      );
    }

    return false;
  };

  const searchBarWidget = (
    <div className="examination-live-searchbar">
      {isFeatureFlagEnabled('sonio.checklist') ? (
        <GlobalSearchBar
          callback={(data) => {
            if (data.type === 'checklistitem') {
              const slide = slides.find((slide) => data.item.instance_view_id.includes(slide.id));
              if (slide) {
                setSelectedPlaneByKey(slide.key);
              }
            }
          }}
        />
      ) : (
        <SearchBar />
      )}
    </div>
  );

  const filterFeedbackWidget = (
    <div className="feedback">
      {shownPlanes === 'taken' ? (
        <>
          <span>{__('examination.settings.showingTakenPlanes')}</span>{' '}
          <Icon name="close" onClick={() => setShownPlanes('all')} />
        </>
      ) : shownPlanes === 'left' ? (
        <>
          <span>{__('examination.settings.showingLeftPlanes')}</span>{' '}
          <Icon name="close" onClick={() => setShownPlanes('all')} />
        </>
      ) : shownPlanes === 'unusual' ? (
        <>
          <span>{__('examination.settings.showingUnusualPlanes')}</span>{' '}
          <Icon name="close" onClick={() => setShownPlanes('all')} />
        </>
      ) : (
        false
      )}
    </div>
  );

  const showExamSelector = appContext.protocolsEnabled;
  const displayZoomedView = !windowContext.windowExists('slide') && !!selectedPlane;

  return (
    returnNakedComponents() || (
      <div
        className={`examination-live-container ${
          notificationContext.notifications.length ? 'has-notifications' : ''
        } status-${examinationContext.examination.status}`}
      >
        {showTopBar && <ExamTopBar openPanel={openPanel} setOpenPanel={setOpenPanel} />}
        <div className="examination-live-sidebar">
          {windowContext.isDetached && (
            <div className="examination-live_child-window-warning">
              <span>{__('examination.comparedExam')}</span>
            </div>
          )}
          {isFeatureFlagEnabled('sonio.detect') && !isFeatureFlagEnabled('sonio.routine') ? (
            <ExamNavBarDetect
              medicalHistory={medicalHistory}
              selected={openPanel}
              instances={medias}
              anomalies={anomalies}
              videos={sortPlanesBy === 'slide' ? videos : []}
              refs={{
                imageChapterRef: imageChapterRef,
                matchingChapterRef: matchingChapterRef,
                videoChapterRef: videoChapterRef,
              }}
              onOptionClick={(panel) => {
                setOpenPanel((openPanel) => (openPanel === panel ? false : panel));
                panel === 'stats' && openPanel !== panel && trackEvent('user_clicks_on_the_report_icon', {});
              }}
              onChangeSection={(current) => setCurrentSection(current)}
              sexConfirmedBy={sexConfirmedBy}
              sexContradictedBy={sexContradictedBy}
              placentaPositionConfirmedBy={placentaPositionConfirmedBy}
              placentaPositionContradictedBy={placentaPositionContradictedBy}
            />
          ) : (
            <ExamNavBarRoutine
              medicalHistory={medicalHistory}
              selected={openPanel}
              instances={medias}
              anomalies={anomalies}
              videos={sortPlanesBy === 'slide' ? videos : []}
              refs={{
                imageChapterRef: imageChapterRef,
                matchingChapterRef: matchingChapterRef,
                videoChapterRef: videoChapterRef,
              }}
              onOptionClick={(panel) => {
                setOpenPanel((openPanel) => (openPanel === panel ? false : panel));
                panel === 'stats' && openPanel !== panel && trackEvent('user_clicks_on_the_report_icon', {});
              }}
              onChangeSection={(current) => setCurrentSection(current)}
              sexConfirmedBy={sexConfirmedBy}
              sexContradictedBy={sexContradictedBy}
              placentaPositionConfirmedBy={placentaPositionConfirmedBy}
              placentaPositionContradictedBy={placentaPositionContradictedBy}
            />
          )}

          {!!examinationContext.canEdit && !!ambientListeningContext.canRecord && (
            <div className="exam_voice-wrapper" data-is-recording={ambientListeningContext.isRecording}>
              <div
                className={`exam_voice-submenu ${editedByAmbientListeningCounter ? 'highlight' : ''}`}
                onClick={() => setVoiceSubmenuOpen((o) => !o)}
              >
                {editedByAmbientListeningCounter || <Icon name="down" />}
                {voiceSubmenuOpen && (
                  <ArrangeOnTop>
                    <div className="exam_voice-submenu_options">
                      <div onClick={() => ambientListeningContext.setHighlightSupportedFields((h) => !h)}>
                        <Icon name={ambientListeningContext.highlightSupportedFields ? 'done' : 'empty'} />
                        {__('report.highlightSupportedFields')}
                      </div>
                    </div>
                  </ArrangeOnTop>
                )}
              </div>
              <div className="exam_voice-button" onClick={ambientListeningContext.toggleRecording}>
                {ambientListeningContext.isRecording ? (
                  <>
                    <div
                      className="recording-animation"
                      style={{
                        '--volume': ambientListeningContext.isSpeaking,
                      }}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </>
                ) : (
                  <Icon name="microphone" />
                )}
              </div>
            </div>
          )}
        </div>

        <div className="examination-live-content">
          <div className="examination-live-panels">
            <div className={`examination-live-panel panel-phenotype ${openPanel === 'phenotype' ? 'open' : ''}`}>
              <div className="panel-close">
                <Icon name="close" onClick={() => setOpenPanel(false)} />
                {isFeatureFlagEnabled('sonio.multiscreen') &&
                  !windowContext.isDetached &&
                  !!examinationContext.examination.patient_id && (
                    <Icon name="detach" onClick={() => splitScreen('anamnesis')} />
                  )}
              </div>
              <div className="panel-content">
                {openPanel === 'phenotype' && (
                  <PhenotypePanel
                    episode={examinationContext.episode}
                    anomalies={anomalies}
                    medicalHistory={medicalHistory}
                    slides={slides}
                    trimester={currentTrimester}
                    slidesRiskFactors={slidesRiskFactors}
                    checklistItems={checklistItems}
                    checklistItemsRiskFactors={checklistItemsRiskFactors}
                    setOpenPopup={setOpenPopup}
                  />
                )}
              </div>
            </div>
            <div className={`examination-live-panel panel-fetus ${openPanel === 'fetus' ? 'open' : ''}`}>
              <div className="panel-close">
                <Icon name="close" onClick={() => setOpenPanel(false)} />
              </div>
              <div className="panel-content">
                {openPanel === 'fetus' && (
                  <FetusPanel
                    currentPreset={currentPreset}
                    fetalSexField={fetalSexField}
                    sexConfirmedBy={sexConfirmedBy}
                    sexContradictedBy={sexContradictedBy}
                    placentaPositionField={placentaPositionField}
                    placentaPositionConfirmedBy={placentaPositionConfirmedBy}
                    placentaPositionContradictedBy={placentaPositionContradictedBy}
                  />
                )}
              </div>
            </div>
            <div className={`examination-live-panel panel-stats ${openPanel === 'stats' ? 'open' : ''}`}>
              <div className="panel-close">
                <Icon name="close" onClick={() => setOpenPanel(false)} />
                {isFeatureFlagEnabled('sonio.multiscreen') && !windowContext.isDetached && (
                  <Icon name="detach" onClick={() => splitScreen('report')} />
                )}
              </div>
              <div className="panel-content">
                {openPanel === 'stats' &&
                  (isFeatureFlagEnabled('sonio.report') ? (
                    <div className="examination-live-stats-panel-container report">
                      <ExaminationReport setOpenPopup={setOpenPopup} frozenReport={frozenReport} />
                    </div>
                  ) : (
                    <div className="examination-live-stats-panel-container">
                      <ExaminationReviewView
                        medias={medias}
                        anomalies={anomalies}
                        slides={slides}
                        instanceViews={instanceViews}
                        trimester={currentTrimester}
                        slidesRiskFactors={slidesRiskFactors}
                        checklistItems={checklistItems}
                        checklistItemsRiskFactors={checklistItemsRiskFactors}
                        assocInstanceExam={assocInstanceExam}
                        setSharingStatusForInstance={setSharingStatusForInstance}
                        medicalHistory={medicalHistory}
                        setIsPrintPreviewOpen={() => setOpenPanel('print')}
                        setIsSharingPreviewOpen={() => setOpenPanel('share')}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>

          {/* SHARING PANELS */}
          {openPanel === 'print' &&
            (isFeatureFlagEnabled('sonio.share') ? (
              <SharingSelect
                examination={examinationContext.examination}
                instances={medias.filter((instance) => instance.dicom_media_type === 'image')}
                slides={slides}
                close={() => setOpenPanel(false)}
                mode="print"
                onReport={() => setOpenPanel('stats')}
                onShare={() => {
                  history.push(`/exam/${examinationContext.examination.id}/report/sharing-list`);
                }}
              />
            ) : (
              <PrintPreview
                examination={examinationContext.examination}
                instances={medias.filter((instance) => instance.dicom_media_type === 'image')}
                slides={slides}
                assocInstanceExam={assocInstanceExam}
                close={() => setOpenPanel(false)}
              />
            ))}
          {openPanel === 'share' &&
            (isFeatureFlagEnabled('sonio.share') ? (
              <SharingSelect
                examination={examinationContext.examination}
                instances={medias}
                slides={slides}
                close={() => setOpenPanel(false)}
                mode="share"
                onReport={() => setOpenPanel('stats')}
                onPrint={() => setOpenPanel('print')}
              />
            ) : (
              <SharingPreviewPopup
                examination={examinationContext.examination}
                instances={medias.filter((instance) => instance.dicom_media_type === 'image')}
                slides={slides}
                close={() => setOpenPanel(false)}
              />
            ))}
          {/* /SHARING PANELS */}

          <div className={`examination-live-panel selected-plane ${displayZoomedView && 'open'}`}>
            <div className="panel-close">
              <Icon name="close" onClick={onPlaneClose} />
              {isFeatureFlagEnabled('sonio.multiscreen') && !windowContext.isDetached && (
                <Icon
                  name="detach"
                  onClick={() => splitScreen('slide', [selectedPlane?.slide?.key || selectedPlane?.media?.id])}
                />
              )}
            </div>
            {displayZoomedView &&
              (isFeatureFlagEnabled('sonio.detect') ? (
                <SlidePanelDetect
                  plane={selectedPlane}
                  slides={slides}
                  filteredSlides={Object.values(filteredSlides).flat()}
                  extractedFrames={extractedFrames}
                  trimester={currentTrimester}
                  checklistItems={checklistItems.filter((item) =>
                    item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id)
                  )}
                  checklistItemsRiskFactors={checklistItemsRiskFactors}
                  anomalies={anomalies}
                  setLastMatchedSlide={setLastMatchedSlide}
                  setSelectedPlaneByKey={setSelectedPlaneByKey}
                  setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                  onClose={onPlaneClose}
                  setShowSlideBrowser={setShowSlideBrowser}
                />
              ) : (
                <SlidePanelRoutine
                  plane={selectedPlane}
                  slides={slides}
                  filteredSlides={Object.values(filteredSlides).flat()}
                  extractedFrames={extractedFrames}
                  trimester={currentTrimester}
                  checklistItems={checklistItems.filter((item) =>
                    item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id)
                  )}
                  checklistItemsRiskFactors={checklistItemsRiskFactors}
                  anomalies={anomalies}
                  setLastMatchedSlide={setLastMatchedSlide}
                  setSelectedPlaneByKey={setSelectedPlaneByKey}
                  setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                  onClose={onPlaneClose}
                  setShowSlideBrowser={setShowSlideBrowser}
                />
              ))}
          </div>

          {showDetectConfirmBanner && (
            <div className="examination-live-ended-container detect">
              <ExamConfirmMatchingBanner
                medias={medias}
                unusualMedias={unusualMedias}
                instanceViews={instanceViews}
                trimester={currentTrimester}
                setShownPlanes={setShownPlanes}
                endExamination={examinationContext.endExamination}
              />
            </div>
          )}
          {showDetectBanner && (
            <div className="examination-live-ended-container detect">
              <ExamCompletedBannerDetect
                medias={medias}
                unusualMedias={unusualMedias}
                instanceViews={instanceViews}
                trimester={currentTrimester}
                setOpenPanel={setOpenPanel}
                setShownPlanes={setShownPlanes}
                setIsPrintPreviewOpen={() => setOpenPanel('print')}
                setIsSharingPreviewOpen={() => setOpenPanel('share')}
              />
            </div>
          )}

          <div className="examination-live-top">
            <div className="examination-live-trimester">
              {examinationContext.canEdit ? (
                showExamSelector ? (
                  <ExamPresetSelector action="change-preset" size="compact" wrap={false} collapse={true} />
                ) : (
                  <ExamPresetButtons
                    action="change-preset"
                    size="compact"
                    wrap={false}
                    collapse={true}
                    theme="grey"
                    stayExpanded={false}
                  />
                )
              ) : (
                <Button
                  label={
                    appContext.allPresets?.find((preset) => preset.id === examinationContext.examination.preset_id)
                      ?.name
                  }
                />
              )}
            </div>
            <div className="examination-live-settings">
              <SelectInput
                value={undefined}
                placeholder={{
                  icon: 'settings-alt',
                  label: '',
                  value: '',
                }}
                onChange={filterChangeAction}
                options={filterOptions}
              />
              {filterFeedbackWidget}
            </div>
            {searchBarWidget}
          </div>
          <div className="examination-live-main">
            {isFeatureFlagEnabled('sonio.detect') ? (
              <SlideGridDetect
                currentTrimester={currentTrimester}
                currentPreset={currentPreset}
                currentSection={currentSection}
                zoomLevel={zoomLevel}
                fetusSex={fetusSex}
                slides={filteredSlides}
                selectedPlane={selectedPlane}
                atRiskSlides={atRiskSlides}
                unusualMedias={unusualMedias}
                medias={medias}
                onItemClick={onClickHandle}
                onItemDoubleClick={onDoubleClickHandle}
                nextMatchingSlide={nextMatchingSlide}
                setShowSlideBrowser={setShowSlideBrowser}
                openPanel={openPanel}
                setOpenPanel={setOpenPanel}
                setSelectedPlane={setSelectedPlane}
                imageChapterRef={imageChapterRef}
                matchingChapterRef={matchingChapterRef}
                videoChapterRef={videoChapterRef}
                highlightNextMatching={highlightNextMatching}
                onManualUpload={onManualUploadHandler}
              />
            ) : (
              <SlideGridRoutine
                currentTrimester={currentTrimester}
                currentPreset={currentPreset}
                currentSection={currentSection}
                zoomLevel={zoomLevel}
                fetusSex={fetusSex}
                slides={filteredSlides}
                selectedPlane={selectedPlane}
                atRiskSlides={atRiskSlides}
                unusualMedias={unusualMedias}
                medias={medias}
                onItemClick={onClickHandle}
                onItemDoubleClick={onDoubleClickHandle}
                nextMatchingSlide={nextMatchingSlide}
                setShowSlideBrowser={setShowSlideBrowser}
                openPanel={openPanel}
                setOpenPanel={setOpenPanel}
                setSelectedPlane={setSelectedPlane}
                imageChapterRef={imageChapterRef}
                matchingChapterRef={matchingChapterRef}
                videoChapterRef={videoChapterRef}
                highlightNextMatching={highlightNextMatching}
                onManualUpload={onManualUploadHandler}
              />
            )}
          </div>
        </div>
        {!!showSlideBrowser &&
          (isFeatureFlagEnabled('sonio.detect') ? (
            <SlideBrowserDetect
              slides={slides}
              selectedSlideId={showSlideBrowser?.slide?.id}
              trimester={currentTrimester}
              onChange={async (slide) => {
                if (isNullOrUndefined(slide?.id)) return false;
                if (showSlideBrowser.media) {
                  const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                  setMedias((medias) =>
                    medias.map((m) =>
                      m.id === showSlideBrowser.media.id
                        ? {
                            ...m,
                            slideId: slide.id,
                            idx_in_template: slide.idx_in_template,
                            idx_in_group: assoc.idx_in_group,
                          }
                        : m
                    )
                  );
                }
                if (showSlideBrowser.callback) showSlideBrowser.callback();
                setShowSlideBrowser(false);
              }}
              onClose={() => setShowSlideBrowser(false)}
            />
          ) : (
            <SlideBrowserRoutine
              slides={slides}
              selectedSlideId={showSlideBrowser?.slide?.id}
              trimester={currentTrimester}
              onChange={async (slide) => {
                if (isNullOrUndefined(slide?.id)) return false;
                if (showSlideBrowser.media) {
                  const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                  setMedias((medias) =>
                    medias.map((m) =>
                      m.id === showSlideBrowser.media.id
                        ? {
                            ...m,
                            slideId: slide.id,
                            idx_in_template: slide.idx_in_template,
                            idx_in_group: assoc.idx_in_group,
                          }
                        : m
                    )
                  );
                }
                if (showSlideBrowser.callback) showSlideBrowser.callback();
                setShowSlideBrowser(false);
              }}
              onClose={() => setShowSlideBrowser(false)}
            />
          ))}

        <Popups openPopup={openPopup} setOpenPopup={setOpenPopup} t={__} frozenReport={frozenReport} />
      </div>
    )
  );
};

const Popups = ({ t: __, openPopup, setOpenPopup, frozenReport }) => {
  const examinationContext = useContext(ExaminationContext);
  const [commentValue, setCommentValue] = useState('');
  const [downloadingPDF, setDownloadingPDF] = useState(false);

  const {
    componentChecklistAssoc,
    checklistItemsToDisplay,
    placeholders,
    reportData,
    reportDataOptions,
    /* Legacy will be removed from API */
    setReportData = () => {},
    loadDynamicReportData = () => {},
  } = useXMLTemplate();

  const XMLPrinter = useXMLDocumentPrinter({ page: 'report' });
  const templateBlueprint = examinationContext.debugTemplate || reportDataOptions?.report_template?.blueprint;

  /* return a promise that is resolved when the report is signed for real */
  const signReport = (comment) => {
    if (!!placeholders['examination.status'] === ExamStatus.REPORT_SIGNED) {
      return new Promise((resolve, reject) => reject(new Error('report already signed')));
    }
    return examinationContext.signReport({
      comment: comment,
      user_timezone: examinationContext.examination.site?.timezone,
    });
  };

  /* return a promise that is resolved when the submition is a success */
  const submitReport = (comment, event_type) => {
    const user_timezone = examinationContext.examination.site.timezone;
    if (event_type === ExamStatus.REPORT_SIGNED) {
      return signReport(comment);
    } else {
      return examinationContext.submitReport({
        comment,
        event_type,
        user_timezone,
      });
    }
  };

  const afterAssociatingContactPoint = (contactPoint) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data.associated_contact_points.push(contactPoint);
    setReportData(newReportData);
    loadDynamicReportData();
  };

  const afterDeassociatingContactPoint = (contactPointId) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data.associated_contact_points =
      newReportData.examination_data.associated_contact_points?.filter((cp) => cp.id !== contactPointId);
    setReportData(newReportData);
    loadDynamicReportData();
  };

  const renderReportHtml = useCallback(async () => {
    if (frozenReport.state === 'loaded' && !examinationContext.canEdit) {
      return frozenReport.print;
    }
    return await XMLPrinter({
      base64Images: [],
      templateBlueprint,
    });
  }, [templateBlueprint, XMLPrinter, frozenReport.state, examinationContext.canEdit]);

  const downloadReportAsPDF = async () => {
    setDownloadingPDF(true);
    ResourceApi.getReportPdf(examinationContext.examination.id)
      .then((response) => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const date = new Date().toJSON().slice(0, 16).replace(/[T|:]/g, '-');
        const patientName = examinationContext.patient?.name
          ? formatName(examinationContext.patient.name).fullName
          : __('patients.anonymous');
        const fileName = `Sonio-${date}-${patientName}.pdf`;
        const a = document.createElement('a');
        let blob = new Blob([response.data]);
        blob = blob.slice(0, blob.size, 'application/pdf');
        a.href = window.URL.createObjectURL(blob);
        if (!isMobile) a.setAttribute('download', fileName);
        a.click();
        a.remove();

        setOpenPopup(false);
      })
      .catch(() => {
        // TODO: handle error
      })
      .finally(() => setDownloadingPDF(false));
  };

  const associateContactPoint = (params) =>
    examinationContext.associateContactPoint(params, afterAssociatingContactPoint);
  const deassociateContactPoint = (association_cp_id) =>
    examinationContext.deassociateContactPoint(association_cp_id, afterDeassociatingContactPoint);

  return (
    <>
      {openPopup?.view === 'submit' && (
        <ViewExamStakeholdersDialog
          status={openPopup.status}
          setSubmitDialogIsOpen={() => setOpenPopup((openPopup) => (openPopup?.view === 'submit' ? false : 'submit'))}
          setStakeholdersDialogIsOpen={() =>
            setOpenPopup((openPopup) => (openPopup?.view === 'submit' ? false : 'submit'))
          }
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          examEvents={examinationContext.examination.events}
          submitReport={submitReport}
          submitAllowed={openPopup.submitAllowed !== false}
          examination={examinationContext.examination}
          associateEntity={examinationContext.associateEntity}
          deassociateEntity={examinationContext.deassociateEntity}
          associateContactPoint={associateContactPoint}
          deassociateContactPoint={deassociateContactPoint}
        />
      )}

      {examinationContext.canEdit && openPopup?.view === 'measurements' && (
        <MeasurementsDialog
          manageMeasurementsIsOpen={openPopup}
          setManageMeasurementsIsOpen={() =>
            setOpenPopup((openPopup) => (openPopup?.view === 'measurements' ? false : 'measurements'))
          }
        />
      )}

      {examinationContext.canEdit && openPopup?.view === 'checklist' && (
        <EditChecklistItems
          fetus={openPopup?.fetus ?? 1}
          items={checklistItemsToDisplay.filter((item) => {
            const slug = item[openPopup?.fetus ?? 1]?.slug;
            return openPopup?.allowedSlugs
              ? openPopup.allowedSlugs.includes(slug)
              : Object.values(componentChecklistAssoc).flat().includes(slug);
          })}
          initialGroup={openPopup?.slug}
          componentChecklistAssoc={openPopup?.componentUID ? componentChecklistAssoc[openPopup.componentUID] : false}
          componentUID={
            Object.keys(componentChecklistAssoc).length === 1
              ? Object.keys(componentChecklistAssoc)[0]
              : openPopup?.componentUID
          }
          close={() => setOpenPopup((openPopup) => (openPopup?.view === 'checklist' ? false : { view: 'checklist' }))}
        />
      )}

      {openPopup?.view === 'activity' && (
        <ViewExamEventsDialog
          setReviewReportDialogIsOpen={(value) => setOpenPopup(value === false ? false : { view: 'activity' })}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          examEvents={examinationContext.examination.events}
          submitReport={submitReport}
          examination={examinationContext.examination}
          updateExamination={examinationContext.updateExamination}
        />
      )}

      {openPopup?.view === 'printing-preview' && (
        <PreviewExamReportDialog
          setPreviewExamReportDialogIsOpen={(value) =>
            setOpenPopup(value === false ? false : { view: 'printing-preview' })
          }
          renderHTML={renderReportHtml}
          downloadReportAsPDF={downloadReportAsPDF}
          downloadingPDF={downloadingPDF}
        />
      )}
      {openPopup?.view === 'document-preview' && (
        <PreviewAttachmentPopup
          attachment={openPopup.attachment}
          onClickCancel={() => setOpenPopup(false)}
          onDeleted={openPopup.onDeleted}
        />
      )}
    </>
  );
};

export default withTranslation()(ExaminationView);
