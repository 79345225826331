import { useContext } from 'react';
import Icon from '../../atoms/Icon/Icon';
import { MeasurementsContext } from '../../context-providers/Measurements';

export default function Measurements({ openChapter, setOpenChapter }) {
  const measurementsContext = useContext(MeasurementsContext);

  const MeasurementsContent = () => {
    const measurementData = measurementsContext?.measurementData;
    if (!measurementData?.available_measurements) return <div>Open the report to see this section</div>;

    if (!measurementData.available_measurements.used) return <div>This exam is too old</div>;

    const groupedMeasurements = [
      ...measurementData.available_measurements.used.map((m) => ({
        used: true,
        ...m,
      })),
      ...measurementData.available_measurements.unused.map((m) => ({
        used: false,
        ...m,
      })),
    ];

    const measurementsByFetus = Object.groupBy(groupedMeasurements, ({ fetus }) => fetus);

    const unextractedMeasurements = measurementData.unextracted;

    return (
      <div className="debug-subsection">
        <h3>Measurements</h3>
        {Object.entries(measurementsByFetus).map(([key, value], index) => (
          <div className="measurements">
            <div>
              <label>Fetus {key}</label>
            </div>
            <table>
              <tbody>
                {value
                  .filter((m) => m.slug)
                  .map(({ slug, used }) => (
                    <tr>
                      <th>{slug}</th>
                      <td>{used ? <span>used</span> : <span className="unused">unused</span>}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        ))}

        <br />
        <br />
        <h3>Unextracted measurements</h3>
        <div className="measurements">
          <table className="unextracted-table">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Code</th>
                <th>Code scheme</th>
                <th>Type</th>
              </tr>
              {unextractedMeasurements
                .sort((a, b) => a.name?.localeCompare(b.name))
                .map((measurement, index) => (
                  <tr>
                    <th>{measurement.name}</th>
                    <td>{measurement.code}</td>
                    <td>{measurement.code_scheme}</td>
                    <td>{measurement.type}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className={`debug-section ${openChapter === 'measurements' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'measurements' ? false : 'measurements'))}>
        Measurements
        <Icon name="down" />
      </h2>
      {openChapter === 'measurements' && <MeasurementsContent />}
    </div>
  );
}
