import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../atoms/Button/Button';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import config from '../../config';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import './PreferencesView.css';

const PreferencesView = ({ t: __ }) => {
  const { isFeatureFlagEnabled } = useAuth();
  const appContext = useContext(AppContext);
  const [defaultZoomLevel, setDefaultZoomLevel] = useState(
    window.localStorage.getItem('defaultZoomLevel') || config.examinationLive.numberOfColumns
  );
  const [zoomUponReceive, setZoomUponReceive] = useState(window.localStorage.getItem('zoomUponReceive') || 'off');

  return (
    <div className="preferences-container">
      <div className="row">
        <div className="w12">
          <h1>{__('preferences.title')}</h1>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="w4">{__('preferences.language')}</div>
        <div className="w8">
          <ButtonGroup
            value={appContext?.preferences?.lang ?? ''}
            options={[
              { value: 'en', label: 'EN' },
              { value: 'fr', label: 'FR' },
              { value: 'de', label: 'DE' },
              { value: 'ptbr', label: 'PTBR' },
            ]}
            onChange={appContext.changeLanguage}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="w4">{__('preferences.dateFormat')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: 'dd/mm/yyyy',
                label: 'dd/mm/yyyy',
              },
              {
                value: 'mm/dd/yyyy',
                label: 'mm/dd/yyyy',
              },
            ]}
            value={appContext?.preferences?.date_format}
            onChange={appContext.changeDateFormat}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__('preferences.units')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: 'metric',
                label: __('preferences.cm-kg'),
              },
              {
                value: 'imperial',
                label: __('preferences.inch-lbs'),
              },
            ]}
            value={appContext?.preferences?.units}
            onChange={appContext.changeUnits}
          />
        </div>
      </div>
      <hr />
      {isFeatureFlagEnabled('sonio.dx_v2') && (
        <div className="row">
          <div className="w4">{__('preferences.defaultExaminationType')}</div>
          <div className="w8">
            <SelectInput
              options={[
                {
                  value: 'screening',
                  label: __('preferences.defaultExaminationType.screening'),
                },
                {
                  value: 'diagnostic',
                  label: __('preferences.defaultExaminationType.diagnostic'),
                },
              ]}
              value={appContext.preferences.default_exam_type}
              onChange={appContext.changeDefaultExamType}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="w4">{__('preferences.defaultZoomLevel')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: 3,
                label: __('preferences.defaultZoomLevel.3'),
              },
              {
                value: 5,
                label: __('preferences.defaultZoomLevel.5'),
              },
            ]}
            value={defaultZoomLevel}
            onChange={(value) => {
              window.localStorage.setItem('defaultZoomLevel', value);
              setDefaultZoomLevel(value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__('live_configuration.popupOnReceive')}</div>
        <div className="w8">
          <SelectInput
            value={zoomUponReceive}
            options={[
              {
                label: __('live_configuration.always'),
                value: 'on',
              },
              {
                label: __('live_configuration.suboptimal'),
                value: 'suboptimal',
              },
              {
                label: __('live_configuration.never'),
                value: 'off',
              },
              {
                label: __('live_configuration.disabled'),
                value: 'noscroll',
              },
            ]}
            onChange={(value) => {
              window.localStorage.setItem('zoomUponReceive', value);
              setZoomUponReceive(value);
            }}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="w4">{__('preferences.checkForUpdates')}</div>
        <div className="w8">
          <Button label={__('preferences.reload')} icon="reload" onClick={() => (window.location = '/')} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(PreferencesView);

PreferencesView.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
