import { withTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { TailSpin } from 'react-loader-spinner'; // TODO: remove TailSpin and use our loader instead
import sanitizeHtml from 'sanitize-html';
import ResourceApi from '../../services/resource';
import { AppContext } from '../../context-providers/App';
import 'react-quill/dist/quill.snow.css';

const DEBOUNCE_TIME = 700;
const loader_dimension_in_px = '80';
const loader_color = '#849b87';

const editModules = {
  toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['link'], ['clean']],
};

const editFormats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'link'];
const MessageEditor = ({ t: __ }) => {
  const { site, setSite } = useContext(AppContext);
  const [initialValue, setInitialValue] = useState('');
  const [value, setValue] = useState('');
  const [siteLoaded, setSiteLoaded] = useState(false);

  // Initial load after site is fetched async
  useEffect(() => {
    if (!siteLoaded && site != null) {
      setInitialValue(site.brand_message ?? '');
      setSiteLoaded(true);
    }
  }, [site?.brand_message, siteLoaded, site]);

  // Debounce API update for 700ms
  useEffect(() => {
    // Initialize timer
    const timeout = setTimeout(async () => {
      // Make update request
      if (site?.id != null) {
        const res = await ResourceApi.updateSite(site?.id, { brand_message: value });
        if (res?.data?.error == null && res?.data?.data != null) {
          setSite(res.data.data);
        }
      }
    }, DEBOUNCE_TIME);

    // Clear timer if 'value' changes
    return () => clearTimeout(timeout);
  }, [value, site?.id]);

  const Loader = () => {
    return (
      <div className="content">
        <TailSpin
          height={loader_dimension_in_px}
          width={loader_dimension_in_px}
          color={loader_color}
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  };

  const Editor = useCallback(() => {
    const sanitizedHtml = sanitizeHtml(initialValue);
    return (
      <div className="content">
        <ReactQuill
          theme="snow"
          defaultValue={sanitizedHtml}
          style={{ width: '100%' }}
          modules={editModules}
          formats={editFormats}
          onChange={setValue}
        />
      </div>
    );
  }, [initialValue, editFormats, editModules]);

  return (
    <div className="section">
      <div className="row">
        <div className="w4">
          <div className="title">{__('preferences.messageEditor.title')}</div>
          <div className="subtitle">{__('preferences.messageEditor.subtitle')}</div>
        </div>
        <div className="w8">
          <div className="message section">{siteLoaded ? <Editor /> : <Loader />}</div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(MessageEditor);
