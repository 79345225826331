import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { isNullOrUndefined } from '../../../../utils';
import { convertValueToSelectedUnit } from '../../../../unitConverter';
import BiometryGraph from '../../../BiometryGraph';
import { MeasurementDefaultUnits } from '../../../../config';
import Icon from '../../../../atoms/Icon/Icon';
import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';

const ReportTemplateMeasurementCurve = ({
  t: __,
  props,
  placeholder: originalPlaceholder,
  placeholders,
  reportData,
  editingFieldId,
  startEditingField,
  reportMode,
  onEndEditing,
  measurementsContext,
  showOptions = null,
}) => {
  if (!originalPlaceholder) return false;
  showOptions = reportMode === 'edit' && (showOptions ?? originalPlaceholder?.showOptions ?? true);

  const fetus = Number(props.fetus || 1);
  const placeholder = Array.isArray(originalPlaceholder) ? originalPlaceholder[fetus] : originalPlaceholder;

  if (!placeholder) return false;
  const { data, curve: propsCurveSlug, percentile = '%', decimals = '2', units: userUnits } = props;
  let { visible_graph } = placeholder || {};
  if (isNullOrUndefined(visible_graph))
    visible_graph = isNullOrUndefined(props.visible) ? null : props.visible !== 'false';
  if (isNullOrUndefined(visible_graph)) visible_graph = false;

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (editingFieldId !== data) setIsEditing(false);
  }, [editingFieldId, data]);

  if (!data || !reportData) return false;

  // Priority - 1: Report edit, 2: xml prop, 3: Hadlock, 4: first available
  const curveSlug =
    placeholder?.curve_slug ??
    (propsCurveSlug ? `${data}.${propsCurveSlug}` : null) ??
    placeholder?.availableCurveSlugs.find((slug) => slug.includes('hadlock')) ??
    placeholder?.availableCurveSlugs[0];

  const storedUnit =
    MeasurementDefaultUnits[measurementsContext?.labels?.measurement?.[data?.split('.')[0].split('/')[0]]?.type];
  const displayUnit =
    userUnits || (isNullOrUndefined(placeholder?.units) || placeholder?.units === 'null' ? '' : placeholder.units);

  const getMeasurementData = () => {
    const curve = reportData.curves?.[curveSlug];
    const growthStandardId = curve?.growth_standard_id;
    const yAxis = placeholder;
    const xAxisId = curve?.horizontal_axis_id;
    const xAxis = { id: xAxisId };
    const isAtRisk = placeholder?.sonio_percentile > 97 || placeholder?.sonio_percentile < 3;
    const defaultCurveSlug = curve?.slug?.split('.')[1];

    // Retrieve current and previous measurements for all fetuses
    const allMeasurements = (fetus === 0 ? [originalPlaceholder[0]] : originalPlaceholder.slice(1)).map(
      ({ value, xvalue, previousMeasurements }, currentFetus) => {
        return [{ examId: 'current', value, xvalue }, ...previousMeasurements]
          .sort((a, b) => a.xvalue - b.xvalue) // Sort by GA
          .reduce((acc, elem) => {
            let xvalue;

            if (xAxisId === 'ga') xvalue = elem.xvalue;
            else {
              const xAxisPlaceholder = placeholders?.[`measurement.${xAxisId}`];
              if (xAxisPlaceholder)
                xvalue =
                  elem?.examId === 'current'
                    ? xAxisPlaceholder?.[currentFetus + 1]?.value
                    : xAxisPlaceholder?.[currentFetus + 1]?.previousMeasurements?.find(
                        (m) => m?.examId === elem?.examId
                      )?.value;
            }
            if (xvalue)
              acc.push({
                value: convertValueToSelectedUnit(elem.value, storedUnit, displayUnit),
                xvalue,
              });
            return acc;
          }, []);
      }
    );

    return {
      measurement: placeholder,
      growthStandardId,
      curve,
      xAxis,
      yAxis,
      isAtRisk,
      defaultCurveSlug,
      allMeasurements,
      xAxisId,
    };
  };

  const { curve, yAxis, xAxis, isAtRisk, allMeasurements } = getMeasurementData();

  if (!allMeasurements?.length || !allMeasurements[0]?.length) return false;
  const toggleEditing = () => {
    if (!!placeholder?.editor && editingFieldId !== data) {
      startEditingField(data, props);
      setIsEditing(true);
    }
  };

  const toggleVisible = (e) => {
    e.stopPropagation();
    onEndEditing(`measurement.${props.data}`, {
      visible_graph: !visible_graph,
      fetus,
    });
  };

  return !placeholder || placeholder?.value === false || !curve ? (
    <div className="measurement-curve empty" />
  ) : (
    /* don't change "measurement-curve not-visible" order because it impacts on visibility inside the <graphs> component */
    <div
      className={`
      exam-report-editing-field measurement-curve ${visible_graph ? 'is-visible' : 'not-visible'}
      ${visible_graph ? 'has-printable-value' : ''}
      ${placeholder?.editor ? 'is-editable' : ''}
      ${isEditing && editingFieldId === data ? 'editing' : ''}
    `}
      onClick={toggleEditing}
    >
      <div className="measurement-curve-label">{placeholder.editedLabel || placeholder.label}</div>
      <div className={`measurement-curve-values ${isAtRisk ? 'highlight' : ''}`}>
        <div className="measurement-curve-value">
          {placeholder.value && (
            <>
              {convertValueToSelectedUnit(Number(placeholder.value), storedUnit, displayUnit).toFixed(Number(decimals))}{' '}
              {displayUnit}
            </>
          )}
        </div>
        <div className="measurement-curve-percentile">
          {placeholder.sonio_percentile && (
            <>
              {percentile === '%' && (
                <>
                  <span className="biometry-item-percentile-value percent">
                    {placeholder.sonio_percentile || '< 1'}
                  </span>
                  {placeholder.abbreviation}
                </>
              )}
              {percentile === 'zscore' && (
                <>
                  <span className="biometry-item-percentile-value zscore">{placeholder.sonio_zscore?.toFixed(2)}</span>
                  &nbsp;{__('report.zscore')}
                </>
              )}
            </>
          )}
          <span className="measurement-curve-growth-standard">
            {curveSlug.slice(curveSlug.lastIndexOf('.') + 1).toUpperCase()}
          </span>
        </div>
      </div>
      <div className="measurement-curve-graph">
        <BiometryGraph xAxis={xAxis} yAxis={yAxis} equations={curve} measurements={allMeasurements} atRisk={isAtRisk} />
      </div>
      {editingFieldId === data &&
        isEditing &&
        placeholder.editor({
          ...placeholder,
          editingGraph: true,
          decimals,
          userUnits,
          defaultUnit: storedUnit,
          curveSlug,
          availableCurveSlugs: placeholder.availableCurveSlugs,
        })}
      {visible_graph && showOptions && (
        <div className="content-options exam-report-editing-options">
          <div onClick={toggleVisible}>
            <Icon name={visible_graph ? 'eye' : 'eye-off'} />
          </div>
        </div>
      )}
      {!visible_graph && reportMode === 'edit' && <NotVisibleOverlay onClick={toggleVisible} />}
    </div>
  );
};

export default withTranslation()(ReportTemplateMeasurementCurve);
