import { useContext } from 'react';
import { DxContext } from '../../context-providers/Dx';
import { ExaminationContext } from '../../context-providers/Examination';
import FetusControllerView from './FetusControllerView';

/**
 * currentZone = zoneId
 * zoneSummaries = { zoneId: { completed: bool, unusual: bool, toCheck: bool }}
 * gotToZone = (zoneId) => {}
 * validateZone = (zoneId) => {}
 */
const FetusController = () => {
  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);

  const getClassName = (zoneId) => {
    let className = '';
    if (examinationContext.examination?.zones?.find((z) => z.id === zoneId && z.status === 'validated')) {
      className += ' completed';
    }
    if (dxContext.currentZoneId === zoneId) className += ' current';
    return className;
  };

  const getMarkerClassName = (zoneId) => {
    return dxContext.zones[zoneId.toString()]?.toCheck ? 'visible' : 'hidden';
  };

  const getUnusualClassName = (zoneId) => {
    return dxContext.zones[zoneId.toString()]?.unusual ? 'visible' : 'hidden';
  };

  const goToZone = (zoneId) => {
    dxContext.setCurrentZoneId(zoneId);
  };

  return (
    <FetusControllerView
      getClassName={getClassName}
      getMarkerClassName={getMarkerClassName}
      getUnusualClassName={getUnusualClassName}
      goToZone={goToZone}
    />
  );
};

export default FetusController;
