import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './ReportSidebar.css';
import { ExaminationContext } from '../../context-providers/Examination';
import ResourceApi from '../../services/resource';
import { PatientNotes } from '../../pages/ExaminationLive/PhenotypePanel/PatientNotes';
import Section from './Section';
import { formatName } from '../../services/examination';
import { formatDate } from '../../utils';
import { AppContext } from '../../context-providers/App';
import Icon from '../../atoms/Icon/Icon';

const ReportSidebar = ({ t: __ }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(localStorage.getItem('sidebar_open') !== 'false');
  const [hideSidebar, setHideSidebar] = useState(!(localStorage.getItem('sidebar_open') !== 'false'));
  const [initialsNotes] = useState(examinationContext.patient?.notes);
  const rootRef = useRef(null);

  const handleTransitionEnd = useCallback(() => {
    if (!isSidebarOpen) {
      setHideSidebar(true);
    }
  }, [rootRef.current, isSidebarOpen, setHideSidebar]);

  useEffect(() => {
    const divElement = rootRef.current;

    divElement.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      divElement.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [handleTransitionEnd]);

  const updatePatientNotes = (notes) => {
    ResourceApi.createBIEvent({
      event_type: 'patient_note_updated',
      examination_id: examinationContext?.examination?.id,
      page: 'report-sidebar',
      notes: notes,
    });
    examinationContext.updatePatient(examinationContext.patient.id, { notes });
  };

  const openSidebar = () => {
    if (!isSidebarOpen) {
      setHideSidebar(false);
    }
    localStorage.setItem('sidebar_open', !isSidebarOpen);
    setIsSidebarOpen((open) => !open);
  };

  return examinationContext?.patient ? (
    <div
      className={`report-sidebar-wrapper ${isSidebarOpen ? 'open' : 'closed'} ${hideSidebar ? 'hide' : ''}`}
      ref={rootRef}
    >
      <div className="report-sidebar-inner">
        <div
          className={`report-sidebar-open-button ${isSidebarOpen ? '' : 'closed'} ${hideSidebar ? 'hide' : ''}`}
          onClick={openSidebar}
        >
          <Icon name={`${isSidebarOpen ? 'right' : 'sidebar-open'}`} />
        </div>
        <Section title={__('reportSidebar.section.patient.title')} icon="woman" hide={hideSidebar}>
          <div>
            <span className="patient-attribute">Patient:</span>{' '}
            {formatName(examinationContext.patient.name)?.fullName || ''}
          </div>
          <div>
            <span className="patient-attribute">DOB:</span>{' '}
            {formatDate(examinationContext.patient.dob, appContext?.preferences?.date_format)}
          </div>
          <div>
            <span className="patient-attribute">PRN:</span> {examinationContext.patient.dicom_patient_id}
          </div>
        </Section>
        <Section
          title={__('reportSidebar.section.notes.title')}
          icon="comment"
          collapse={!initialsNotes}
          hide={hideSidebar}
        >
          <PatientNotes
            t={__}
            notes={examinationContext.patient?.notes}
            showNotes={examinationContext.patient?.show_notes}
            onChange={updatePatientNotes}
            placeholder={__('reportSidebar.notes.placeholder')}
            hideTitle
            scrollToBottom
            maxHeight={10}
            patientId={examinationContext?.patient?.id}
          />
        </Section>
      </div>
    </div>
  ) : null;
};

export default withTranslation()(ReportSidebar);
