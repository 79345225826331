import React, { useEffect, useRef, useState } from 'react';
import { ContentEditable } from '../ContentEditable/ContentEditable';
import {
  computeHtmlFromValue as defaultComputeHtmlFromValue,
  computeValueFromHtml as defaultComputeValueFromHtml,
} from '../../../components/XMLDocument/inlineTextArea';

export default function InlineTextarea({
  value,
  autofocus = false,
  active = true,
  printable = true,
  placeholder = null,
  computeHtmlFromValue = defaultComputeHtmlFromValue,
  computeValueFromHtml = defaultComputeValueFromHtml,
  contentEditableProps = {},
  onStartEditing = () => {},
  onChange = () => {},
  onClick = () => {},
}) {
  const [editing, setEditing] = useState(false);
  const [html, setHtml] = useState(computeHtmlFromValue(value));
  const contentEditable = useRef(null);

  const handleChange = (e) => {
    const html = e.target.value;
    const value = computeValueFromHtml(html);
    const newHtml = computeHtmlFromValue(value);
    setHtml(newHtml);
  };

  useEffect(() => {
    if (autofocus) {
      contentEditable.current.focus();
    }
  }, [contentEditable, autofocus]);

  const onFocus = () => {
    onStartEditing();
    setEditing(true);
  };
  const onBlur = () => {
    setEditing(false);
    const currentHtml = contentEditable.current.innerHTML;
    const value = computeValueFromHtml(currentHtml);
    const newHtml = computeHtmlFromValue(value);
    setHtml(newHtml);
    onChange(value);
  };

  useEffect(() => {
    if (value?.trim() === '') {
      setHtml(null);
      return;
    }
    setHtml(computeHtmlFromValue(value));
  }, [placeholder, value]);

  return (
    <div
      className={`inline-editing inline-textarea ${active ? 'is-active' : 'not-active'} ${
        printable ? 'is-printable' : 'not-printable'
      } ${editing ? 'is-editing' : ''}`}
    >
      <div className="inline-textarea-value inline-editing-value" onClick={(e) => onClick(e, contentEditable)}>
        <ContentEditable
          innerRef={contentEditable}
          html={html}
          disabled={!active}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          {...contentEditableProps}
        />
      </div>
    </div>
  );
}
