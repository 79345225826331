import { useContext, useEffect, useRef, useState, isValidElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { formatYYYYMMDDDate, fromStrToDate, isNullOrUndefined } from '../../../utils';
import { AppContext } from '../../../context-providers/App';
import '../InlineEditing.css';

/**
 * Important: appPreferences is passed as props because inside XMLtoReact the contexts don't work
 */

export default function InlineInput({
  value,
  format = null,
  decimals = null,
  autofocus = false,
  active = true,
  defaultEditor = false,
  printable = true,
  fullWidth = false,
  onStartEditing = () => {},
  onChange = () => {},
  appPreferences = null,
}) {
  const appContext = useContext(AppContext);
  const preferedDateFormat = appPreferences?.date_format || appContext?.preferences?.date_format;

  const [editing, setEditing] = useState(false);
  const valueField = useRef(false);

  const getContentFormat = (value) => {
    if (format) return format;

    if (!value || isNaN(value)) {
      if (`${value}`?.match(/^\d{4}.\d{2}.\d{2}$/)) return 'date';
      return 'string';
    }
    return 'number';
  };

  const contentFormat = getContentFormat(value || '');

  useEffect(() => {
    if (editing) {
      onStartEditing();
    }
  }, [editing]);

  useEffect(() => {
    if (autofocus) {
      valueField.current.focus();
    }
  }, [valueField, autofocus]);

  const cleanUpContent = (content, beforeSaving = false) => {
    let string = '';
    if (Array.isArray(content))
      string = content
        .map((c) => {
          return isValidElement(c) ? renderToStaticMarkup(c) : c;
        })
        .join('');
    else if (isValidElement(content)) string = renderToStaticMarkup(content.textContent);
    else string = content;

    if (contentFormat === 'date') {
      if (beforeSaving) {
        try {
          const newDate = fromStrToDate(content, preferedDateFormat);
          string = newDate.toISOString().split('T')[0];
        } catch {
          string = '';
        }
      } else {
        string = formatYYYYMMDDDate(string, preferedDateFormat);
      }
    }

    if (contentFormat === 'number' && string !== '') {
      string = decimals !== null ? Number(string).toFixed(Number(decimals)) : Number(string);
    }

    // strip html
    const div = document.createElement('DIV');
    div.innerHTML = string;
    let stringNoHtml = div.textContent;

    // remove new lines
    stringNoHtml = stringNoHtml.replace(/\n|\r/g, '');

    return stringNoHtml
      .trim()
      .replace(/^\s+|\t*|\s+$/g, '')
      .replace(/ +/g, ' ');
  };

  const cleanUpAsYouType = (e) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      valueField.current?.blur();
    }
    if (!['Home', 'End', 'Delete', 'Backspace', 'Period', 'Comma', 'ArrowRight', 'ArrowLeft', 'Tab'].includes(e.code)) {
      if (contentFormat === 'number' && e.key.match(/[^\d]/)) e.preventDefault();
      if (contentFormat === 'date' && e.key.match(/[^\d|/|-]/)) e.preventDefault();
    }
  };

  const displayedValue = cleanUpContent(isNullOrUndefined(value) ? '' : value);

  const onChangeHandler = () => {
    const value = valueField.current?.textContent;
    setTimeout(setEditing(false), 200);
    onChange(cleanUpContent(value, true));
    const selection = window.getSelection();
    selection.removeAllRanges();
  };

  const onFocusHandler = () => {
    setEditing(true);
    const value = valueField.current?.textContent;
    if (!isNaN(Number(value))) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(valueField.current);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const pasteAsPlainText = (event) => {
    event.preventDefault();
    const text = cleanUpContent(event.clipboardData.getData('text/plain'));

    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <div
      className={`inline-editing inline-input ${active || defaultEditor ? 'is-active' : 'not-active'} ${
        printable ? 'is-printable' : 'not-printable'
      } ${editing ? 'is-editing' : ''} ${fullWidth ? 'full-width' : ''}`}
    >
      <div className="inline-input-value inline-editing-value">
        <div
          data-test-id="input"
          ref={valueField}
          contentEditable={active}
          onKeyDown={cleanUpAsYouType}
          onFocus={onFocusHandler}
          onBlur={onChangeHandler}
          onPaste={pasteAsPlainText}
          suppressContentEditableWarning={true}
          className={defaultEditor ? 'contenteditable' : ''}
          onClick={defaultEditor || undefined}
        >
          {displayedValue}
        </div>
      </div>
    </div>
  );
}
