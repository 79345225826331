import { useState, useContext, useEffect } from 'react';
import DxAi from '../../../services/dx-ai';
import { DxContext } from '../../../context-providers/Dx';
import LookupApi from '../../../services/lookup';
import { ExaminationContext } from '../../../context-providers/Examination';
import ChecklistSummaryView from './ChecklistSummaryView';
import { isNullOrUndefined } from '../../../utils';

const ChecklistSummary = ({}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);
  const [currentSyndrome, setCurrentSyndrome] = useState(false);
  const [allMedications, setAllMedications] = useState([]);

  const onSyndromeClick = (syndrome) => {
    setCurrentSyndrome(syndrome);
  };

  const onSyndromePinClick = (syndromeId, pinned) => {
    dxContext.setUserOverrides((userOverrides) =>
      pinned
        ? userOverrides
            .filter((rule) => !(rule.id === syndromeId && rule.type === 'syndrome'))
            .concat([
              {
                type: 'syndrome',
                id: syndromeId,
                action: 'add',
              },
            ])
        : userOverrides.filter((rule) => !(rule.id === syndromeId && rule.type === 'syndrome' && rule.action === 'add'))
    );
  };

  const onSyndromeHideClick = (syndromeId, hidden) => {
    dxContext.setUserOverrides((userOverrides) =>
      hidden
        ? userOverrides
            .filter((rule) => !(rule.id === syndromeId && rule.type === 'syndrome'))
            .concat([
              {
                type: 'syndrome',
                id: syndromeId,
                action: 'remove',
              },
            ])
        : userOverrides.filter(
            (rule) => !(rule.id === syndromeId && rule.type === 'syndrome' && rule.action === 'remove')
          )
    );
  };

  useEffect(() => {
    if (dxContext.checklistSummaryOpen === false) setCurrentSyndrome(false);
  }, [dxContext.checklistSummaryOpen]);

  useEffect(() => {
    setCurrentSyndrome(false);
  }, [examinationContext.examination.id]);

  useEffect(() => {
    if (isNullOrUndefined(currentSyndrome.id) || currentSyndrome.id === false) return false;
    if (!Object.keys(examinationContext.medicalHistoryItems)?.length) return false;

    if (currentSyndrome.id === 0) {
      let malformationIds = [];
      if (currentSyndrome.malformations.length > 0 && Number.isInteger(currentSyndrome.malformations[0]))
        malformationIds = currentSyndrome.malformations;
      else malformationIds = currentSyndrome.malformations.map((m) => m.id);
      DxAi.malformationDetails(malformationIds).then((resp) => {
        const riskFactors = resp.data.data.map((malformation) => ({
          malformation_id: malformation.id,
          risk_factors: malformation.risk_factors
            ?.map((risk) => ({
              ...Object.values(examinationContext.medicalHistoryItems).find((mh) =>
                mh.options?.some((option) => option.risk_factor_id === risk.id)
              ),
              risk_factor_id: risk.id,
            }))
            .filter((risk) => !!risk?.options?.length)
            .map((risk) => ({
              ...risk,
              options: risk.options.filter((option) => option.risk_factor_id === risk.risk_factor_id),
              medicalHistoryItem: examinationContext.examination.medical_history?.[
                risk.text_id
              ]?.risk_factors?.includes(risk.risk_factor_id)
                ? { ...examinationContext.examination.medical_history[risk.text_id] }
                : false,
            })),
        }));

        const medicationIds = [
          ...new Set(
            resp.data.data.reduce(
              (ids, malformation) => [
                ...ids,
                ...malformation.risk_factors
                  ?.filter((medication) => medication.type === 'drug')
                  .map((medication) => medication.id),
              ],
              []
            )
          ),
        ];

        if (medicationIds.length)
          LookupApi.getMedicationsByRiskFactorIds(medicationIds).then((results) => setAllMedications(results.data));
        else setAllMedications([]);

        const malformations = resp.data.data
          .map((malformation) => ({
            ...examinationContext.getMalformationById(malformation.id),
            ...malformation,
            status: 'yes',
          }))
          .sort((a, b) => (b.canonical_name[currentLanguage] < a.canonical_name[currentLanguage] ? 1 : -1))
          .sort((a, b) => b.probability - a.probability)
          .map((malformation) => ({
            ...malformation,
            riskFactors: riskFactors.find((risk) => risk.malformation_id === malformation.id)?.risk_factors,
          }));

        const newCurrentSyndrome = {
          id: currentSyndrome.id,
          malformations,
          probability: dxContext.recommendation?.syndromes.find((s) => s.id === currentSyndrome.id)?.probability,
        };

        return setCurrentSyndrome((currentSyndrome) =>
          isNullOrUndefined(currentSyndrome.id) ? currentSyndrome : newCurrentSyndrome
        );
      });
    } else {
      DxAi.syndromeDetails(
        [currentSyndrome.id],
        examinationContext.examination?.medical_history?.['medicalexam.fetus.sex']?.value || 'unknown',
        examinationContext.examination.trimester,
        examinationContext.examination.malformations
      ).then((resp) => {
        const malformations = resp.data.data[0].malformations.typical_malformations.map((malformation) => ({
          id: malformation.id,
          status:
            examinationContext.examination?.malformations?.find((m) => m.id === malformation.id)?.status || 'unknown',
        }));

        const atypicalMalformations = resp.data.data[0].malformations.atypical_yes_malformations.map(
          (malformation) => ({
            id: malformation.id,
            status:
              examinationContext.examination?.malformations?.find((m) => m.id === malformation.id)?.status || 'unknown',
          })
        );

        const riskFactors = (
          resp.data.data[0].risk_factors?.map((risk) => ({
            ...Object.values(examinationContext.medicalHistoryItems).find((mh) =>
              mh.options?.some((option) => option.risk_factor_id === risk.id)
            ),
            risk_factor_id: risk.id,
          })) || []
        )
          .filter((risk) => !!risk?.options?.length)
          .map((risk) => ({
            ...risk,
            options: risk.options.filter((option) => option.risk_factor_id === risk.risk_factor_id),
            medicalHistoryItem: examinationContext.examination.medical_history?.[risk.text_id]?.risk_factors?.includes(
              risk.risk_factor_id
            )
              ? { ...examinationContext.examination.medical_history[risk.text_id] }
              : false,
          }));

        const medicationIds = resp.data.data[0].risk_factors
          ?.filter((medication) => medication.type === 'drug')
          .map((medication) => medication.id);

        if (medicationIds.length)
          LookupApi.getMedicationsByRiskFactorIds(medicationIds).then((results) => setAllMedications(results.data));
        else setAllMedications([]);

        const newCurrentSyndrome = {
          ...resp.data.data[0],
          malformations,
          riskFactors,
          atypicalMalformations,
          probability: dxContext.recommendation?.syndromes.find((s) => s.id === currentSyndrome.id)?.probability,
        };

        return setCurrentSyndrome((currentSyndrome) =>
          isNullOrUndefined(currentSyndrome.id) ? currentSyndrome : newCurrentSyndrome
        );
      });
    }
  }, [
    currentSyndrome.id,
    JSON.stringify(examinationContext.examination.zones),
    JSON.stringify(examinationContext.examination.malformations),
    JSON.stringify(examinationContext.examination.medical_history),
    JSON.stringify(examinationContext.examination.recommendation),
    JSON.stringify(examinationContext.medicalHistoryItems),
  ]);

  return (
    <ChecklistSummaryView
      syndromes={dxContext.recommendation?.syndromes.sort((a, b) => b.probability - a.probability)}
      currentSyndrome={currentSyndrome}
      onSyndromeClick={onSyndromeClick}
      onSyndromePinClick={onSyndromePinClick}
      onSyndromeHideClick={onSyndromeHideClick}
      malformations={examinationContext.examination?.malformations || []}
      riskFactors={examinationContext.examination?.risk_factors || []}
      medicalHistory={examinationContext.examination?.medical_history || {}}
      allMedications={allMedications}
    />
  );
};

export default ChecklistSummary;
