import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon/Icon';
import LoaderInline from '../../../atoms/LoaderInline/LoaderInline';
import DetectionScore from '../../../components-dt/DetectionScore/DetectionScore';
import Popup from '../../../components/Popup/Popup';
import config from '../../../config';
import { AppContext } from '../../../context-providers/App';
import { WindowContext } from '../../../context-providers/Window';
import { ExaminationContext } from '../../../context-providers/Examination';
import ResourceApi from '../../../services/resource';
import { getInstanceThumbnailUri, isNullOrUndefined, isGaInTrimester } from '../../../utils';
import './SlidePanel.css';
import SlidePanelBody from './SlidePanelBody';
import SlidePanelMoreButton from './SlidePanelMoreButton';

const SlidePanel = ({
  t: __,
  plane,
  slides,
  filteredSlides,
  extractedFrames,
  checklistItems,
  anomalies,
  trimester,
  setSelectedPlaneByKey,
  setSelectedPlaneByMediaId,
  setShowSlideBrowser,
  setLastMatchedSlide = () => {},
  onClose = () => {},
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const appContext = useContext(AppContext);
  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);

  const history = useHistory();

  const goToNextSlideArrow = useRef();
  const goToPreviousSlideArrow = useRef();
  const isVideo = plane.media?.dicom_media_type === 'video';
  const [isFullScreen, setIsFullScreen] = useState(
    isVideo || trimester === 'ND' || window.localStorage.getItem('slide-full-screen') === 'true'
  );
  const [lastSelectedAnomaly, setLastSelectedAnomaly] = useState(false);
  const [predictionSource, setPredictionSource] = useState(false);
  const [predictionScore, setPredictionScore] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [qualityCriteriaList, setQualityCriteriaList] = useState([]);
  const [viewEvaluation, setViewEvaluation] = useState(null);
  const [predictedQualityCriteria, setPredictedQualityCriteria] = useState([]);
  const [alternativeSlides, setAlternativeSlides] = useState([]);
  const [enteredFromMedia, setEnteredFromMedia] = useState(false);

  const unmatched =
    plane.media && !plane.media?.verified && !plane.slide?.optional && !plane.slide?.verified && !plane.slide?.is_video;
  const isOtherNotVerified = useMemo(
    () => plane?.slide?.type === 'other' && plane.media?.verified === false,
    [plane.slide?.type, plane.media?.verified]
  );

  const instancePrediction = useMemo(
    () =>
      (plane?.media?.predictions
        ?.sort((a, b) => b.id - a.id)
        .find((prediction) => prediction.type === 'view' && prediction.status === 'done')?.data || [])?.[0],
    [plane?.media?.predictions]
  );
  const external_to_routine_detected = instancePrediction?.external_to_routine_detected?.[0];
  const annotation_detected =
    instancePrediction?.annotation_detected ||
    (plane?.slide?.type === 'other' && !!external_to_routine_detected?.view_id
      ? examinationContext.instanceViews[external_to_routine_detected?.view_id]?.label[currentLanguage]
      : '');

  const dictionaryTerms = appContext.viewsDictionary
    ?.filter((d) => d.instance_view_id === plane.slide?.id)
    .map((d) => d.text_on_img);

  const updateIsFullScreen = (value) => {
    window.localStorage.setItem('slide-full-screen', value);
    setIsFullScreen(value);
  };

  const nextSlideKey = useMemo(() => {
    const currentSlideIndex = filteredSlides.findIndex((s) => s.key === plane.slide?.key);
    const nextSlides = filteredSlides.slice(currentSlideIndex + 1);
    return nextSlides.find((s) => (enteredFromMedia ? s.mediaId : s.id))?.key; // next media if current slide contain a media, otherwise next slide
  }, [filteredSlides, plane.slide?.key, enteredFromMedia]);

  const prevSlideKey = useMemo(() => {
    const currentSlideIndex = filteredSlides.findIndex((s) => s.key === plane.slide?.key);
    const previousSlides = filteredSlides.slice(0, currentSlideIndex).reverse();
    return previousSlides.find((s) => (enteredFromMedia ? s.mediaId : s.id))?.key; // next media if current slide contain a media, otherwise next slide
  }, [filteredSlides, plane.slide?.key, enteredFromMedia]);

  const changeAssociation = useCallback(
    (slide) => {
      examinationContext.setInstanceAssociation(slide, plane.media.id);
      setLastMatchedSlide(slide);
    },
    [plane.media?.id, examinationContext.examination.id]
  );

  // TODO: move this to examination context.
  const getAssociations = useCallback(async () => {
    if (examinationContext.examination.id) {
      const {
        data: { data: associations },
      } = await ResourceApi.getAssocInstanceByExamId(examinationContext.examination.id);
      const newAssociations = {};
      for (const association of associations) {
        newAssociations[association.dicom_instance_id] = association;
      }
      return newAssociations;
    }
  }, [examinationContext.examination.id]);

  const goToVideo = () => {
    if (plane.media?.dicom_origin_id) {
      setSelectedPlaneByMediaId(plane.media?.dicom_origin_id);
    }
  };

  const goToPreviousSlide = useCallback(() => {
    if (prevSlideKey) {
      windowContext.isDetached && history.push(`${prevSlideKey}`);
      setSelectedPlaneByKey(prevSlideKey);
    }
  }, [prevSlideKey, windowContext.isDetached, setSelectedPlaneByKey]);

  const goToNextSlide = useCallback(() => {
    if (nextSlideKey) {
      windowContext.isDetached && history.push(`${nextSlideKey}`);
      setSelectedPlaneByKey(nextSlideKey);
    }
  }, [nextSlideKey, windowContext.isDetached, setSelectedPlaneByKey]);

  const onKeyDown = useCallback(
    (e) => {
      switch (e.code) {
        case 'ArrowRight':
        case 'ArrowDown':
          goToNextSlide();
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          goToPreviousSlide();
          break;
        case 'Escape':
          !windowContext.windowExists('slide') && onClose();
          break;
      }
    },
    [goToNextSlide, goToPreviousSlide, onClose, windowContext.windowExists('slide'), onClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  const onViewEvaluationClick = async () => {
    if (examinationContext.canEdit && plane.media?.id && plane.slide?.id) {
      await ResourceApi.updateViewEvaluation(
        examinationContext.examination.id,
        plane.media.id,
        plane.slide.id,
        !viewEvaluation?.warning
      );
    }
  };

  const onViewEvaluationReverseClick = async () => {
    if (examinationContext.canEdit && plane.media?.id && plane.slide?.id) {
      await ResourceApi.updateViewEvaluation(
        examinationContext.examination.id,
        plane.media.id,
        plane.slide.id,
        plane.media?.view_evaluation_override
      );
    }
  };

  const onAllQualityCriteriaClick = async (currentValue) => {
    if (!examinationContext.canEdit || !plane.slide?.id || !plane.media?.id) return null;
    const newQualityCriterias = predictedQualityCriteria?.quality_criterias.map((q) =>
      q.is_valid !== currentValue ? { ...q, is_valid: currentValue, source: 'user' } : q
    );
    await ResourceApi.updateQualityCriteria(
      examinationContext.examination.id,
      plane.media?.id,
      plane.slide.id,
      newQualityCriterias.filter((item) => item.source === 'user')
    );
  };

  const onAllQualityCriteriaReverseClick = async () => {
    if (!examinationContext.canEdit || !plane.slide?.id || !plane.media?.id) return null;
    await ResourceApi.updateQualityCriteria(examinationContext.examination.id, plane.media?.id, plane.slide.id, []);
  };

  useEffect(() => {
    setEnteredFromMedia(!!plane.media);
  }, []);

  useEffect(() => {
    setIsFullScreen(isVideo || trimester === 'ND' || window.localStorage.getItem('slide-full-screen') === 'true');
  }, [trimester, isVideo]);

  useEffect(() => {
    setQualityCriteriaList(() =>
      !plane.slide
        ? []
        : examinationContext.QUALITYCRITERIA.filter((q) =>
            q.instance_views.some(
              (assoc_instance_view) =>
                assoc_instance_view.trimester === trimester && plane.slide?.id === assoc_instance_view.instance_view_id
            )
          )
    );
  }, [examinationContext.QUALITYCRITERIA, trimester, plane.slide?.id]);

  useEffect(() => {
    const base = examinationContext.getQualityCriteriaByInstanceViewId(plane.slide, plane.media);
    const newPredictedQualityCriteria = {
      score: plane.media?.qc_prediction?.data?.[0]?.score,
    };
    newPredictedQualityCriteria.quality_criterias = [
      ...base
        ?.filter((qc) => qc.is_valid === false)
        .sort((a, b) => {
          return Number(b.detectable) - Number(a.detectable) || a.warning_level === 'mandatory' ? -1 : 1;
        }),
      ...base
        ?.filter((qc) => qc.is_valid === true)
        .sort((a, b) => {
          return Number(b.detectable) - Number(a.detectable) || a.warning_level === 'mandatory' ? -1 : 1;
        }),
      ...base?.filter((qc) => isNullOrUndefined(qc.is_valid)),
    ];

    setPredictedQualityCriteria(newPredictedQualityCriteria);

    setViewEvaluation(() => {
      const score = plane.media?.ve_prediction?.data?.[0]?.conf;
      const viewEvaluationIsOverriden = !isNullOrUndefined(plane.media?.view_evaluation_override);
      const warningFromOverride = viewEvaluationIsOverriden && plane.media?.view_evaluation_override === true;
      const warningFromScore =
        plane.media?.ve_prediction?.data?.[0]?.conf.normalized_score < config.evaluationScoreThreshold;

      return {
        score: score,
        warning: viewEvaluationIsOverriden ? warningFromOverride : warningFromScore,
        source: viewEvaluationIsOverriden ? 'user' : 'vision-ai',
      };
    });
  }, [JSON.stringify(plane.media), qualityCriteriaList]);

  useEffect(() => {
    const predictions = examinationContext.getPredictions(plane.media, isOtherNotVerified ? false : plane.slide, 3);
    setAlternativeSlides(
      [
        ...predictions.filter((p) => p?.type && p.type !== 'other'),
        slides.find((slide) => slide?.type === 'other'),
      ].map((prediction) => slides.find((slide) => prediction?.id === slide.id))
    );
  }, [JSON.stringify(plane.media), JSON.stringify(plane.slide), slides, isOtherNotVerified]);

  useEffect(() => {
    setLastSelectedAnomaly(
      anomalies
        .map((anomalyId) => checklistItems.find((item) => item.id === anomalyId))
        .filter((anomaly) => anomaly)
        .pop()
    );
    setConfirmDelete(false);
  }, [anomalies, plane.media?.id]);

  useEffect(() => {
    setPredictionSource(plane.media?.source);
    setPredictionScore({
      score: predictedQualityCriteria?.score?.normalized_score,
      min: predictedQualityCriteria?.quality_criterias,
    });
    setConfirmDelete(false);
  }, [JSON.stringify(plane.media), predictedQualityCriteria]);

  useEffect(() => {
    getAssociations();
  }, [getAssociations]);

  const detectedAnnotationShouldBeDisplayed = () => {
    const annotation = annotation_detected?.trim();
    if (!dictionaryTerms?.includes(annotation)) return false;
    return !!annotation && annotation?.toLowerCase() !== plane.slide?.label?.[currentLanguage]?.toLowerCase();
  };

  return (
    <div
      className={`slide-panel-dt-container trimester-${trimester} ${plane.media?.id ? 'has-media' : ''} ${
        isFullScreen ? 'is-fullscreen' : ''
      }`}
    >
      <div className="slide-panel-dt-header">
        <div className="slide-panel-dt-slide">
          {!isVideo && trimester !== 'ND' && (
            <div
              className={`slide-panel-dt-slide-container ${plane.media ? 'active' : 'inactive'} ${
                unmatched ? 'smart-match' : ''
              }`}
            >
              <div className="slide-panel-dt-slide-meta">
                {unmatched && !plane.slide && (
                  <div className="slide-panel-dt-slide-slide">
                    <div className="slide-panel-dt-slide-feedback">
                      <LoaderInline />
                      &nbsp;
                      {__('examination-plane.pending')}
                    </div>
                  </div>
                )}
                <div className="slide-panel-dt-slide-name">
                  {plane.slide && (!plane.media || !unmatched) && (
                    <div>
                      <div>
                        {detectedAnnotationShouldBeDisplayed() ? (
                          <span className="original-annotation">{annotation_detected}</span>
                        ) : (
                          <span className="original-annotation">{plane.slide?.label?.[currentLanguage]}</span>
                        )}
                        {!!plane.slide?.view_count && plane.slide?.view_count > 1 && (
                          <span className="slide-counter">
                            <b>{plane.slide?.idx_in_view + 1}</b>/{plane.slide?.view_count}
                          </span>
                        )}
                        {plane.media?.to_retake && (
                          <span className="slide-panel-dt-slide-to-retake">
                            <Icon name="bell" /> {__('examination-plane.toBeRetaken')}
                          </span>
                        )}
                      </div>
                      <span className="slide-panel-dt-detection-score">
                        {!!plane.media && (
                          <DetectionScore
                            score={
                              viewEvaluation?.score?.normalized_score ??
                              external_to_routine_detected?.conf?.normalized_score
                            }
                            onDismiss={onViewEvaluationClick}
                            view={plane.slide}
                            viewName={plane.slide?.label[currentLanguage]}
                            source={viewEvaluation?.source}
                            confirmedMatching={!viewEvaluation?.warning && viewEvaluation?.source === 'user'}
                            alternativeSlides={alternativeSlides}
                            changeAssociation={changeAssociation}
                            instancePrediction={instancePrediction}
                            isDetectable={
                              plane?.slide?.detectable &&
                              isGaInTrimester(plane?.slide?.min_ga, plane?.slide?.max_ga, trimester) &&
                              plane?.slide?.techno === 'us'
                            }
                            dictionaryTerms={dictionaryTerms}
                          />
                        )}
                        {detectedAnnotationShouldBeDisplayed() && (
                          <span className="template-annotation">{plane.slide?.label?.[currentLanguage]}</span>
                        )}
                      </span>
                    </div>
                  )}
                  {unmatched && (
                    <div className="slide-panel-dt-slide-confirm-matching">
                      <h2>{__('examination-plane.smartMatching.title')}</h2>
                    </div>
                  )}
                </div>
                <div className="slide-panel-dt-slide-score">
                  {!!plane.media && !!predictionSource && predictionScore === 'error' && (
                    <div>
                      <Icon name="info" />
                      &nbsp;
                      <span>{__('examination-plane.error')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="slide-panel-dt-slide-cta">
          <div className="slide-panel-dt-options">
            {!!plane.media && examinationContext.canEdit && (
              <SlidePanelMoreButton
                {...{
                  setConfirmDelete,
                  setShowSlideBrowser,
                  onViewEvaluationReverseClick,
                  onAllQualityCriteriaReverseClick,
                  plane,
                  isVideo,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="slide-panel-dt-body">
        <SlidePanelBody
          {...{
            plane,
            slides,
            isVideo,
            filteredSlides,
            extractedFrames,
            setSelectedPlaneByKey,
            setSelectedPlaneByMediaId,
            onAllQualityCriteriaClick,
            isFullScreen,
            updateIsFullScreen,
            getInstanceThumbnailUri,
            goToVideo,
            goToNextSlide,
            goToPreviousSlide,
            alternativeSlides,
            changeAssociation,
            trimester,
            setShowSlideBrowser,
            lastSelectedAnomaly,
            predictedQualityCriteria,
            unmatched,
          }}
        />
      </div>
      <div className="slide-panel-dt-pagination">
        {prevSlideKey ? (
          <div onClick={goToPreviousSlide} ref={goToPreviousSlideArrow} className="previous">
            <Icon name="left" />
          </div>
        ) : (
          <div className="previous"></div>
        )}
        {nextSlideKey ? (
          <div onClick={goToNextSlide} ref={goToNextSlideArrow} className="next">
            <Icon name="right" />
          </div>
        ) : (
          <div className="next"></div>
        )}
      </div>
      {confirmDelete && (
        <Popup
          message={__('examination-plane.delete.confirmMessage')}
          cta={
            <>
              <Button
                label={__('examination-plane.delete.cancel')}
                variant="outline"
                onClick={() => examinationContext.canEdit && setConfirmDelete(false)}
              />
              &nbsp;
              <Button
                label={__('examination-plane.delete.confirm')}
                onClick={() => examinationContext.canEdit && examinationContext.deleteInstance(plane.media.id)}
              />
            </>
          }
          onBackgroundClick={() => setConfirmDelete(false)}
        />
      )}
    </div>
  );
};

export default withTranslation()(SlidePanel);
