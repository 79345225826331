export const SET_SHARING_DOC = 'SET_SHARING_DOC';
export const SET_GENERAL_DOC = 'SET_GENERAL_DOC';
export const SET_PREFERENCE_GENERAL_DOC = 'SET_PREFERENCE_GENERAL_DOC';
export const CHANGE_ACTIVE_STATUS_SHARE_DOC = 'CHANGE_ACTIVE_STATUS_SHARE_DOC';
export const CHANGE_ACTIVE_STATUS_GENERAL_DOC = 'CHANGE_ACTIVE_STATUS_GENERAL_DOC';
export const CHANGE_ACTIVE_STATUS_GENERAL_DOC_WITHOUT_ASSOC = 'CHANGE_ACTIVE_STATUS_GENERAL_DOC_WITHOUT_ASSOC';
export const CHANGE_STATUS_PREFERENCE_GENERAL_DOC = 'CHANGE_STATUS_PREFERENCE_GENERAL_DOC';
export const SET_SHARING_IMAGES = 'SET_SHARING_IMAGES';
export const SET_SHARING_VIDEOS = 'SET_SHARING_VIDEOS';

export default {
  SET_SHARING_DOC,
  SET_GENERAL_DOC,
  SET_SHARING_IMAGES,
  SET_SHARING_VIDEOS,
  SET_PREFERENCE_GENERAL_DOC,
  CHANGE_ACTIVE_STATUS_SHARE_DOC,
  CHANGE_ACTIVE_STATUS_GENERAL_DOC,
  CHANGE_ACTIVE_STATUS_GENERAL_DOC_WITHOUT_ASSOC,
  CHANGE_STATUS_PREFERENCE_GENERAL_DOC,
};
