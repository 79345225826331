import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { isNullOrUndefined } from '../../../../utils';
import { convertValueToSelectedUnit } from '../../../../unitConverter';
import PercentileGraph from '../../../PercentileGraph';
import { MeasurementDefaultUnits } from '../../../../config';
import { getNiceGestionalAgeFromDays } from '../../../../services/examination';
import { getPercentileValueString } from '../../../../services/measurements';
import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';

const ReportTemplateBiometryItem = ({
  t: __,
  props,
  placeholder: originalPlaceholder,
  getPlaceholderWithProps,
  fetus = 1,
  editingFieldId,
  reportMode,
  setManageMeasurementsIsOpen,
  measurementsContext,
  onEndEditing = () => {},
}) => {
  if (!originalPlaceholder) return false;

  const placeholder = Array.isArray(originalPlaceholder)
    ? originalPlaceholder[Number(props.fetus || fetus || 1)]
    : originalPlaceholder;
  if (isNullOrUndefined(placeholder?.value)) return false;

  const {
    data,
    label: templateLabel,
    required = null,
    percentile = '%',
    decimals = '1',
    ga: gaStandard,
    reference,
    units,
  } = props ?? {};
  const gaValue = getPlaceholderWithProps({
    data: `ga.${data}.${gaStandard ?? 'hadlock1984'}`,
  })?.[fetus]?.value;

  // Label Priority: 1. edited from UI, 2. set in report_template, 3. default for this biometry
  const label = placeholder?.editedLabel ? placeholder.editedLabel : templateLabel || placeholder?.label;
  const [isEditing, setIsEditing] = useState(false);
  const isMultiUnits = units?.includes('.'); // eg. feet.inches or lbs.oz

  let { visible } = placeholder || {};
  if (isNullOrUndefined(visible)) visible = isNullOrUndefined(props.visible) ? null : props.visible !== 'false';
  if (isNullOrUndefined(visible)) visible = true;

  useEffect(() => {
    if (editingFieldId !== data) setIsEditing(false);
  }, [editingFieldId, data]);

  if (!data) return false;

  // curveSlug Priority - 1: Report edit, 2: xml prop, 3: Hadlock, 4: first available
  const curveSlug = placeholder?.curve_slug;

  const {
    percentile: percentileValue = null,
    zscore = null,
    reference_value = null,
  } = placeholder?.percentiles?.[curveSlug] || {};

  const isAtRisk = !isNullOrUndefined(percentileValue) && (percentileValue > 95 || percentileValue < 5);

  const saveChange = (updates) => {
    onEndEditing(`measurement.${props.data}`, { ...updates, fetus });
  };

  const PercentileMiniGraph = () => {
    return (
      <div className="biometry-item-percentile-graph">
        {curveSlug && !isNullOrUndefined(percentileValue) && percentileValue !== false && (
          <PercentileGraph percentile={percentileValue} atRisk={isAtRisk} />
        )}
      </div>
    );
  };

  const defaultUnit =
    MeasurementDefaultUnits[measurementsContext?.labels?.measurement?.[data?.split('.')[0].split('/')[0]]?.type];
  const displayUnit = units || (placeholder?.units === 'null' ? '' : placeholder?.units) || defaultUnit;
  const displayValue = convertValueToSelectedUnit(placeholder?.value, defaultUnit, displayUnit, Number(decimals));

  const handleClick = () => {
    if (!visible) return;
    setManageMeasurementsIsOpen({ data, fetus });
  };

  const fetusNames = getPlaceholderWithProps({ data: 'fetus.name' })?.value || [];
  const numberOfFetuses = getPlaceholderWithProps({ data: 'patient.nb_fetuses' })?.value || 1;

  return (
    <div
      className={`biometry-item
    ${isAtRisk ? 'at-risk' : ''}
    ${visible ? 'is-visible' : 'not-visible'}
    ${placeholder.editor ? 'is-editable' : ''}
    ${visible ? 'has-printable-value' : 'not-printable'}
    ${isEditing && editingFieldId === data ? 'editing' : ''}`}
      onClick={handleClick}
    >
      <div className="biometry-item-label">
        {numberOfFetuses > 1 && <span className="biometry-item-fetus-number">{fetusNames?.[fetus]}</span>}
        {label} {required ? <span className="required">*</span> : false}
      </div>
      <div className="biometry-item-value" data-multiunit={isMultiUnits}>
        {placeholder?.value &&
          (isMultiUnits ? (
            <>
              <span>
                <span className="value">{displayValue[0]}</span>
                <span className="unit">{displayUnit.split('.')[0]}</span>
              </span>
              <span>
                <span className="value">{displayValue[1]}</span>
                <span className="unit">{displayUnit.split('.')[1]}</span>
              </span>
            </>
          ) : (
            <>
              <span className="value">{displayValue}</span>
              <span className="unit">{displayUnit}</span>
            </>
          ))}
      </div>
      <div className="biometry-item-ga">
        {gaValue && <span className="ga">{getNiceGestionalAgeFromDays(__, gaValue)}</span>}
      </div>
      <div className="biometry-item-percentile">
        <div className="biometry-item-percentile-value-wrapper">
          {curveSlug && !isNullOrUndefined(percentileValue) && percentileValue !== false && (
            <>
              {percentile === '%' && (
                <>
                  <span className="biometry-item-percentile-value percent">
                    {getPercentileValueString(percentileValue, __)}
                  </span>
                </>
              )}
              {percentile === 'zscore' && (
                <>
                  <span className="biometry-item-percentile-value zscore">{Number(zscore).toFixed(2)}</span>
                </>
              )}
            </>
          )}
        </div>
        <PercentileMiniGraph />
        {reference && reference_value && (
          <div className="biometry-item-reference-value">
            {` (${reference_value?.toFixed(Number(decimals))}` + (displayUnit ? ` ${displayUnit}` : '') + ')'}
          </div>
        )}
      </div>
      {editingFieldId === data &&
        isEditing &&
        placeholder.editor({
          ...placeholder,
          visible,
          label,
          decimals,
          userUnits: units,
          defaultUnit,
          curveSlug,
          availableCurveSlugs: placeholder.availableCurveSlugs,
        })}
      {!visible && reportMode === 'edit' && (
        <NotVisibleOverlay
          onClick={(e) => {
            e.stopPropagation();
            saveChange({ visible: !visible });
          }}
        />
      )}
    </div>
  );
};

export default withTranslation()(ReportTemplateBiometryItem);
