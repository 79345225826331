import { useContext } from 'react';
import SectionsNavBar from '../../components/SectionsNavBar/SectionsNavBar';
import { withTranslation } from 'react-i18next';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';

const ExamNavBar = ({
  t: __,
  medicalHistory = {},
  selected = '',
  instances = [],
  anomalies = [],
  videos = [],
  refs = {},
  disableOptions = false,
  disableActions = false,
  onOptionClick = () => {},
  onChangeSection = () => {},
  sexConfirmedBy,
  sexContradictedBy,
  placentaPositionConfirmedBy,
  placentaPositionContradictedBy,
}) => {
  const { isFeatureFlagEnabled } = useAuth();
  const examinationContext = useContext(ExaminationContext);

  const currentTrimester = examinationContext.examination.trimester;
  const unverifiedMedias = isFeatureFlagEnabled('sonio.detect')
    ? instances.filter((media) => media.dicom_media_type === 'image' && media.modality !== 'SR' && !media?.verified)
    : [];

  const getActions = () => {
    const actions = [];

    if (isFeatureFlagEnabled('sonio.detect_v2')) {
      actions.push({
        icon: 'fetus',
        label: __('examination.sidebar.phenotype'),
        selected: selected === 'fetus',
        counter: false,
        unusual: !!sexContradictedBy?.length || !!placentaPositionContradictedBy?.length,
        onClick: () => onOptionClick('fetus'),
      });
    }

    return actions;
  };

  const getSections = () => {
    const sections = [];

    sections.push({
      id: 'images',
      icon: 'image',
      title: __('examination.sidebar.images'),
      counter: anomalies.length,
      target: refs.imageChapterRef?.current,
    });

    sections.push({
      id: 'matching',
      icon: 'matching-score',
      title: __('examination.sidebar.matching'),
      counter: currentTrimester !== 'ND' && unverifiedMedias.length,
      disabled: currentTrimester === 'ND' || !unverifiedMedias.length,
      target: refs.matchingChapterRef?.current,
    });

    sections.push({
      id: 'videos',
      icon: 'video',
      title: __('examination.sidebar.videos'),
      disabled: !videos.length,
      target: refs.videoChapterRef?.current,
    });

    return sections;
  };

  return (
    <SectionsNavBar
      onOptionClickCurrentOption={onChangeSection}
      options={getSections()}
      disableOptions={disableOptions}
      disableActions={disableActions}
      actions={getActions()}
      actionsAfter={[]}
    />
  );
};

export default withTranslation()(ExamNavBar);
