import { useContext, useEffect, useMemo, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon/Icon';
import useAuth from '../../../context-providers/Auth';
import { DxContext } from '../../../context-providers/Dx';
import { AppContext } from '../../../context-providers/App';
import { ExaminationContext } from '../../../context-providers/Examination';
import ResourceApi from '../../../services/resource';
import { isNullOrUndefined, formatCheckListItemLabel } from '../../../utils';
import config from '../../../config';
import './SlidePanelBody.css';

import GlobalSearchBar from '../../../components/GlobalSearchBar/GlobalSearchBar';
import SlidePanelVideo from './SlidePanelVideo';
import SmartMatch from './SmartMatch';
import SlideDrawing from '../../Slide/SlideDrawing/SlideDrawing';

const SlidePanelBody = ({
  t: __,
  plane,
  slides,
  isVideo,
  filteredSlides,
  extractedFrames,
  setSelectedPlaneByKey,
  setSelectedPlaneByMediaId,
  onAllQualityCriteriaClick,
  isFullScreen,
  updateIsFullScreen,
  getInstanceThumbnailUri,
  goToNextSlide,
  goToPreviousSlide,
  goToVideo,
  alternativeSlides,
  changeAssociation,
  trimester,
  setShowSlideBrowser,
  lastSelectedAnomaly,
  predictedQualityCriteria,
  unmatched,
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const dxContext = useContext(DxContext);
  const history = useHistory();
  const { isFeatureFlagEnabled } = useAuth();
  const [mouseWheelDelta, setMouseWheelDelta] = useState(0);
  const [watchVideo, setWatchVideo] = useState(false);

  const imageWrapperRef = useRef(null);
  const imageRef = useRef(null);
  const videoRef = useRef(null);

  const isExtractedFromVideo = !!plane.media?.dicom_origin_id;
  const isNotDoppler = plane.slide?.techno !== 'doppler';

  const frameRate = parseInt(plane?.media?.frame_rate) || config.defaultVideoFrameRate;
  const currentFrame = plane.media?.sop_instance_uid?.split('_')?.slice(-1)[0];

  const detectedFetalSex = plane.media?.qc_prediction?.data?.[0]?.sex;
  const detectedPlacentaLocation = plane.media?.qc_prediction?.data?.[0]?.placenta_location;

  const fetusSex =
    examinationContext.fetusSexVisibility === 'hidden'
      ? false
      : examinationContext.examination?.medical_history?.['medicalexam.fetus.sex']?.value;

  const checklistItemForView = examinationContext.examination.checklist_items?.filter((checklist_item) =>
    checklist_item.instance_view_id.includes(plane.slide?.id)
  );

  const invalidQualityCriteriaArePresent = useMemo(
    () => !!predictedQualityCriteria?.quality_criterias?.find((qc) => !qc.is_valid),
    [predictedQualityCriteria]
  );

  const unusualQualityCriteriaCount = useMemo(
    () =>
      predictedQualityCriteria?.quality_criterias?.filter(
        (qc) => qc.is_valid === false && qc.warning_level === 'mandatory'
      ).length,
    [JSON.stringify(predictedQualityCriteria?.quality_criterias)]
  );

  /* refresh after having edited */
  useEffect(() => {
    if (imageRef.current) {
      const src = imageRef.current?.src;
      imageRef.current.src = src;
    }
  }, [JSON.stringify(plane?.media?.user_edits)]);

  useEffect(() => {
    imageWrapperRef.current?.addEventListener('wheel', onWheelScrolling);
    return () => {
      imageWrapperRef.current?.removeEventListener('wheel', onWheelScrolling);
    };
  }, [imageWrapperRef.current]);

  const onWheelScrolling = (e) => {
    setMouseWheelDelta((delta) => {
      let scrollOffset = delta;
      if ((delta >= 0 && e.deltaY < 0) || (delta <= 0 && e.deltaY > 0)) scrollOffset = 0;
      return scrollOffset + (e.deltaY > 0 ? 1 : -1);
    });
  };

  useEffect(() => {
    if (mouseWheelDelta === config.scrollWheelSensitivity) {
      setMouseWheelDelta(0);
      goToNextSlide();
    } else if (mouseWheelDelta === config.scrollWheelSensitivity * -1) {
      setMouseWheelDelta(0);
      goToPreviousSlide();
    }
  }, [mouseWheelDelta, goToNextSlide, goToPreviousSlide]);

  const onQualityCriteriaClick = async (criteria) => {
    if (!plane.slide?.id || !plane.media?.id) return null;
    const newQualityCriterias = predictedQualityCriteria?.quality_criterias.map((q) =>
      q.id === criteria.id ? { ...q, is_valid: !(q.is_valid ?? true), source: 'user' } : q
    );
    await ResourceApi.updateQualityCriteria(
      examinationContext.examination.id,
      plane.media?.id,
      plane.slide.id,
      newQualityCriterias.filter((item) => item.source === 'user')
    );
  };

  return (
    <div className="slide-panel-dt-main_wrapper">
      <div className="slide-panel-dt-main_inner">
        <div className="slide-panel-dt-main">
          <div className="slide-panel-dt-image">
            <div className="slide-panel-dt-image-inner" ref={imageWrapperRef}>
              {isVideo ? (
                <SlidePanelVideo
                  plane={plane}
                  filteredSlides={filteredSlides}
                  setIsFullScreen={updateIsFullScreen}
                  extractedFrames={extractedFrames}
                  setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                />
              ) : (
                <>
                  {!plane.media && (
                    <div className="slide-panel-dt-waiting">
                      {!!plane.slide && (
                        <SlideDrawing
                          version="2"
                          trimester={examinationContext.examination?.trimester}
                          slide={plane.slide}
                          sex={fetusSex}
                          rotation=""
                        />
                      )}
                      <b>
                        {plane.slide?.label?.[currentLanguage]}
                        {!!plane.slide?.view_count && plane.slide?.view_count > 1 && (
                          <>
                            {' '}
                            {plane.slide?.idx_in_view + 1}/{plane.slide?.view_count}
                          </>
                        )}
                      </b>
                      {__('examination.missingPlane')}
                    </div>
                  )}
                  {trimester !== 'ND' && (
                    <div
                      className="slide-panel-dt-switchfullscreen"
                      onClick={() => updateIsFullScreen(!isFullScreen)}
                    ></div>
                  )}
                  {!!plane.media &&
                    (!watchVideo ? (
                      <img src={getInstanceThumbnailUri(plane.media.id)} alt="" ref={imageRef} />
                    ) : (
                      <div className="slide-panel-dt-video inline">
                        <video
                          src={`/api/v2/dicom-instance/${plane.media?.dicom_origin_id}/video`}
                          ref={videoRef}
                          onLoadedData={() => {
                            if (currentFrame) videoRef.current.currentTime = currentFrame / frameRate;
                            videoRef?.current?.play();
                          }}
                          autoPlay
                          muted
                          preload="none"
                          playsInline
                          loop
                          type="video/mp4"
                        />
                      </div>
                    ))}
                </>
              )}
            </div>
            {isExtractedFromVideo && (
              <div className="slide-panel-dt-extracted-from-video">
                <Icon name="video" /> <Icon name="ai-detection" /> {__('examination.extractedFromVideo')}
                <br />
                <Button
                  label={watchVideo ? __('examination.backToImage') : __('examination.watchVideo')}
                  icon={watchVideo ? 'image' : 'play'}
                  size="small"
                  variant="outline"
                  onClick={() => {
                    setWatchVideo((prev) => !prev);
                  }}
                />
                &nbsp;
                <Button
                  label={__('examination.goToVideo')}
                  icon="video"
                  size="small"
                  variant="outline"
                  onClick={() => {
                    goToVideo();
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {unmatched ? (
          <div className="slide-panel-dt-alternatives">
            <div className="slide-panel-dt-alternatives-inner">
              <SmartMatch
                media={plane.media}
                alternativeSlides={alternativeSlides}
                trimester={trimester}
                changeAssociation={changeAssociation}
                setShowSlideBrowser={setShowSlideBrowser}
              />
            </div>
          </div>
        ) : (
          <div className="slide-panel-dt-sidebar-container">
            <div className="slide-panel-dt-sidebar">
              <div className="slide-panel-dt-sidebar-inner">
                <div className="slide-panel-dt-checklist">
                  {examinationContext.canEdit && isFeatureFlagEnabled('sonio.checklist') && (
                    <div className="slide-panel-dt-searchbar">
                      <GlobalSearchBar
                        fullwidth="true"
                        callback={(data) => {
                          if (data.type === 'checklistitem') {
                            const slide = slides.find((slide) => data.item.instance_view_id.includes(slide.id));
                            if (slide) {
                              setSelectedPlaneByKey(slide.key);
                            }
                          }
                        }}
                      />
                    </div>
                  )}

                  {!!checklistItemForView?.length && (
                    <>
                      <div className="slide-panel-dt-sidebar-title">
                        <h2 className="section-title">{__('examination-plane.checklistItems')}</h2>
                      </div>
                      {(!plane.media || plane.media?.verified !== false) && (
                        <>
                          <div className="slide-panel-dt-checklist-items">
                            <ul>
                              {checklistItemForView.map((checklistItem) => {
                                return (
                                  !isNullOrUndefined(checklistItem) && (
                                    <li
                                      key={`ci_${checklistItem.id}`}
                                      className={'is-not-verified warning-mandatory'}
                                      onClick={() => {
                                        examinationContext.canEdit &&
                                          ResourceApi.updateChecklistItemStatus(
                                            examinationContext.examination.id,
                                            checklistItem.id,
                                            'usual',
                                            checklistItem.examination_fetus_id
                                          );
                                      }}
                                    >
                                      <span className="exam-item-name">
                                        <span>
                                          <div className="exam-item-context">
                                            <span>
                                              {checklistItem.parents
                                                .map(
                                                  (parentChecklistItem) => parentChecklistItem.label[currentLanguage]
                                                )
                                                .join(' > ')}
                                            </span>
                                          </div>
                                          {formatCheckListItemLabel(
                                            checklistItem,
                                            currentLanguage,
                                            __,
                                            examinationContext.examination.fetuses
                                          )}
                                        </span>
                                        <Icon name="close" />
                                      </span>
                                    </li>
                                  )
                                );
                              })}
                            </ul>
                          </div>
                          {isFeatureFlagEnabled('sonio.dx_v2') && lastSelectedAnomaly && (
                            <div className="slide-panel-dt-gotodx">
                              <Button
                                label={__('examination.startDx')}
                                size="full-width"
                                color="ocra"
                                iconAfter="right"
                                onClick={() => {
                                  dxContext.setCurrentChecklistItem(lastSelectedAnomaly);
                                  return history.push(`/exam/${examinationContext.examination.id}/dx`);
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="slide-panel-dt-qc">
                  {!!detectedFetalSex?.id && (
                    <div className="slide-panel-dt-detected">
                      <Icon name={detectedFetalSex.id} />{' '}
                      {__('examination-plane.fetalSexDetected', {
                        value: __('report.fetusSex.' + detectedFetalSex.id),
                      })}
                    </div>
                  )}
                  {!!detectedPlacentaLocation?.id && (
                    <div className="slide-panel-dt-detected">
                      <Icon name={detectedPlacentaLocation.id} />{' '}
                      {__('examination-plane.placentaLocationDetected', {
                        value: appContext.placentaPositions.find(
                          (position) => position.id === detectedPlacentaLocation.id
                        )?.label[currentLanguage],
                      })}
                    </div>
                  )}
                  <div className="slide-panel-dt-sidebar-title">
                    <h2 className="section-title">
                      {__('examination-plane.qualityCriteria')}
                      {!!unusualQualityCriteriaCount && (
                        <span className="unusual-quality-criteria-count">{unusualQualityCriteriaCount}</span>
                      )}
                    </h2>
                    {!!plane.media && (
                      <Button
                        variant="link"
                        icon="done-all"
                        disabled={!invalidQualityCriteriaArePresent}
                        label={__('examination-plane.qualityCriteria.dismiss')}
                        onClick={() =>
                          examinationContext.canEdit && onAllQualityCriteriaClick(invalidQualityCriteriaArePresent)
                        }
                      />
                    )}
                  </div>
                  {(!plane.media || plane.media?.verified !== false) && (
                    <>
                      <div className="slide-panel-dt-checklist-items">
                        <div className="slide-panel-dt-checklist-items-scroll-up" />
                        <div className="slide-panel-dt-checklist-items-scroll-down" />
                        <ul>
                          {predictedQualityCriteria?.quality_criterias?.map((criteria) => {
                            return (
                              !isNullOrUndefined(criteria) &&
                              isNotDoppler && (
                                <li
                                  key={`qc_${criteria.id}`}
                                  className={`${
                                    isNullOrUndefined(criteria.is_valid)
                                      ? ''
                                      : criteria.is_valid
                                      ? ''
                                      : 'is-not-verified'
                                  } warning-${criteria.warning_level} ${criteria?.detectable ? 'is-detectable' : ''} ${
                                    criteria?.is_detected ? 'is-detected' : 'is-not-detected'
                                  } ${criteria?.is_detected && !!criteria.source ? 'checked' : ''}`}
                                  onClick={() => examinationContext.canEdit && onQualityCriteriaClick(criteria)}
                                >
                                  <span className="exam-item-name">
                                    <span>{criteria.label[currentLanguage]}</span>
                                    <span className={`item-progress ${!criteria.is_valid ? 'highlight' : ''}`}>
                                      {criteria.is_detected && (
                                        <Icon name={criteria.source === 'user' ? 'manual' : 'ai-detection'} />
                                      )}
                                      {criteria.is_detected && (
                                        <div className="progress">
                                          {!criteria.is_valid
                                            ? __('examination-plane.qualityCriteria.notValid')
                                            : __('examination-plane.qualityCriteria.valid')}
                                        </div>
                                      )}
                                    </span>
                                  </span>
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SlidePanelBody);
