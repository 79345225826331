import { createContext, useEffect, useState } from 'react';

export const WindowMessagingContext = createContext({});

export const WindowMessagingContextProvider = ({ children }) => {
  const [connectionState, setConnectionState] = useState('no-parent-window');
  const [liveContext, setLiveContext] = useState({});

  const handleMessageReception = (message) => {
    switch (message.data.event) {
      case 'live-sonio-wait-react-to-mount':
        setConnectionState('live-connection-established');
        window.top.postMessage({ event: 'child-react-sonio-mounted' }, window.location.origin);
        return;
      case 'live-sonio-ack-react-mounted':
        setConnectionState('live-connection-alive');
        setLiveContext(message.data.context);
        return;
      default:
        if (typeof message.data.source === 'string' && message.data.source.startsWith('react-devtools')) return;
        console.warn('Unhandled parent message', message);
    }
  };

  useEffect(() => {
    window.top.postMessage({ event: 'child-react-sonio-mounted' }, window.location.origin);
    const oldOnMessage = window.onmessage;
    window.onmessage = (message) => {
      if (message.source != window.top) {
        if (typeof oldOnMessage === 'function') return oldOnMessage(message);
        return;
      }
      handleMessageReception(message);
    };
  }, []);

  return (
    <WindowMessagingContext.Provider
      value={{
        connectionState: connectionState,
        liveContext: liveContext,
      }}
    >
      {children}
    </WindowMessagingContext.Provider>
  );
};
