import Button from '../../../atoms/Button/Button';
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { insertBMIinMedicalHistory, sortMedicalHistoryItems } from '../../../context-providers/Examination/utils';
import { ExaminationContext } from '../../../context-providers/Examination';

export default withTranslation()(MedicalHistory);

function MedicalHistory({ t: __, medicalHistory, currentLanguage, onClickRisk }) {
  const examinationContext = useContext(ExaminationContext);
  const history = useHistory();
  const [medicalHistoryWithBMI, setMedicalHistoryWithBMI] = useState(medicalHistory);

  const handleGoToMedicalHistory = () => {
    history.push(`/exam-anamnesis/${examinationContext.examination.id}`);
  };

  const printRiskRawValue = (risk) => {
    if (
      risk.raw_value &&
      !risk.tmp_value &&
      (!risk.value || (Array.isArray(risk.value) && !risk.value.includes(risk.raw_value)))
    )
      return <span className="raw-value">{getNiceRawValue(risk.raw_value)}</span>;
    return false;
  };

  useEffect(() => {
    setMedicalHistoryWithBMI((mh) => {
      const sortedMh = sortMedicalHistoryItems(mh);
      return insertBMIinMedicalHistory(sortedMh, examinationContext, currentLanguage);
    });
  }, [
    medicalHistory,
    examinationContext.medicalHistoryItems?.['medicalexam.mother.bmi']?.label,
    examinationContext.examination?.medical_history?.['medicalexam.mother.weight']?.value,
    examinationContext.examination?.medical_history?.['medicalexam.mother.height']?.value,
    examinationContext.examination?.medical_history?.['medicalexam.mother.bmi'],
  ]);

  const getNiceRawValue = (value) => {
    if (typeof value === 'string' || !isNaN(value)) return value;
    if (value.canonical_name) return value.canonical_name[currentLanguage];
    const rawValues = Object.values(value);
    const firstRawValueByOrder = Object.values(rawValues).find(({ order }) => order === 0);
    if (firstRawValueByOrder) return firstRawValueByOrder.label;
    const allRawValues = rawValues.map(({ label }) => label).join(', ');
    if (allRawValues) return allRawValues;
    return false;
  };

  return (
    <div>
      <h2 className="section-title">{__('examination.medicalHistory')}</h2>
      <ul className="risk-factors">
        {medicalHistoryWithBMI
          ?.filter((mh) => mh.label)
          .map((risk, index) => {
            return (
              <li key={index} className={risk.is_risky ? 'is-risky' : ''} onClick={() => onClickRisk(risk)}>
                <label>{risk.label}</label>
                <span>
                  {printRiskRawValue(risk)} {risk.value}
                </span>
              </li>
            );
          })}
      </ul>
      {!medicalHistory.length && <div className="risk-factors-empty">{__('medicalhistory.noRiskFactors')}</div>}
      <div className="examination-live-phenotype-panel-cta">
        <Button
          label={
            examinationContext.canEdit ? __('examination.goToMedicalHistory') : __('examination.consultMedicalHistory')
          }
          variant={!medicalHistory.length ? '' : 'outline'}
          onClick={handleGoToMedicalHistory}
        />
      </div>
    </div>
  );
}
