/* React */
import { useCallback, useMemo, useEffect, useState, useContext, useReducer } from 'react';

import useStability from '../../hooks/useStability';

/* APIs */
import ResourceApi from '../../services/resource';
import evalTemplate from '../../services/automation-template';

/* Contexts */
import { ExaminationContext } from '../Examination';
import { SocketContext } from '../Socket';
import { MeasurementsContext } from '../Measurements';
import { NotificationContext } from '../Notification';

/* Atoms */
import Icon from '../../atoms/Icon/Icon';

/* Utils */
import { PlaceholdersHelper, selectedDatum } from '../../components/XMLDocument/placeholders';
import { isFlashReportField } from '../../components/XMLDocument/flashReport';

import { normalizeDropdown, normalizeAllDropdown, updateOptimisticPlaceholders, useUpdateQueue } from './utils';
import { onlyUnique, isNullOrUndefined, replaceAllKeys } from '../../utils';

const mandatoryPlaceholders = [
  'examination.finding',
  'examination.indication',
  'ga.assigned.value',
  'examination.method',
  'examination.signed',
  'fetus.number',
  'fetus.order',
  'fetus.name',
];

const reloadPlaceholders = (channel, ids) => {
  channel.push('require_data', { ids });
};

const placeholdersReducer = (state, { event, data: { data, reportV2Channel } }) => {
  const { placeholders, requiredPlaceholders } = state;
  const ids =
    event === 'require' &&
    data.ids.filter(
      (id) => !Object.prototype.hasOwnProperty.call(placeholders, id) && !requiredPlaceholders.includes(id)
    );
  switch (event) {
    case 'require':
      if (ids.length === 0) return state;
      return {
        placeholders,
        requiredPlaceholders: [...requiredPlaceholders, ...ids],
      };
    case 'reload':
      reloadPlaceholders(reportV2Channel, [...Object.keys(placeholders), ...requiredPlaceholders]);
      return { placeholders, requiredPlaceholders };
    case 'update':
      return {
        placeholders: { ...placeholders, ...data },
        requiredPlaceholders: requiredPlaceholders.filter((id) => !Object.hasOwnProperty.call(data, id)),
      };
    default:
      throw new Error(`Unknown event: ${event}`);
  }
};

const XMLTemplateContextProvider = ({ t: __, XMLTemplateContext, children }) => {
  const apiVersion = '2.0';

  const [socketLoaded, setSocketLoaded] = useState(false);
  const [edited, setEdited] = useState(false);
  const [reportV2Channel, setReportV2Channel] = useState(null);
  const [reportDataOptions, setReportDataOptions] = useState({});
  const [requiredPlaceholdersPromises, setRequiredPlaceholdersPromises] = useState([]);
  const [dynamicDropdowns, setDynamicDropdowns] = useState({});
  const examinationContext = useContext(ExaminationContext);
  const measurementsContext = useContext(MeasurementsContext);
  const notificationContext = useContext(NotificationContext);
  const [flashTemplateFieldsVisible, setFlashTemplateFieldsVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [highlightedFields, setHighlightedFields] = useState([]);

  const { socket } = useContext(SocketContext);

  const [{ placeholders, requiredPlaceholders }, doDispatchPlaceholders] = useReducer(placeholdersReducer, {
    placeholders: {},
    requiredPlaceholders: mandatoryPlaceholders,
  });

  const dispatchPlaceholders = useCallback(
    (event, data) => {
      doDispatchPlaceholders({ event, data: { data, reportV2Channel } });
    },
    [reportV2Channel, doDispatchPlaceholders]
  );

  const loadStaticReportOptions = async () => {
    if (!examinationContext?.examination?.id) return;
    const response = await ResourceApi.getReportOptions(examinationContext.examination.id);
    setReportDataOptions({
      ...response.data,
      report_dropdowns: normalizeAllDropdown(response.data.report_dropdowns),
    });
  };

  const [componentChecklistAssoc, setComponentChecklistAssoc] = useState({});

  const updateComponentChecklistAssoc = useCallback(
    (CIComponentId, items_id) => {
      setComponentChecklistAssoc((componentChecklistAssoc) => {
        return { ...componentChecklistAssoc, [CIComponentId]: items_id };
      });
    },
    [setComponentChecklistAssoc]
  );

  /*
   * Initialize dynamic dropdowns
   */
  useEffect(() => {
    const initialState = Object.entries(reportDataOptions?.report_dropdowns ?? {}).map(([key, value]) => [
      key,
      { ...value, state: 'not-loaded', promises: [] },
    ]);
    setDynamicDropdowns(Object.fromEntries(initialState));
  }, [Object.keys(reportDataOptions?.report_dropdowns || {}).join('|')]);

  /*
   * Legacy fields
   */

  const [autogeneratedChecklistComments, setAutogeneratedChecklistComments] = useState([]);
  const updateAutogeneratedChecklistComments = useCallback(
    (fetus, collection, comments) => {
      comments = comments.filter((c) => c.attribute === 'comment');
      setAutogeneratedChecklistComments((autogeneratedChecklistComments) => {
        if (!autogeneratedChecklistComments[fetus]) autogeneratedChecklistComments[fetus] = {};
        let pendingUpdates = comments.length !== autogeneratedChecklistComments[fetus][collection]?.length;
        if (!pendingUpdates) {
          const currentAutomation = Object.fromEntries(
            autogeneratedChecklistComments[fetus][collection]?.map((c) => [c.data, c.content]) || []
          );
          for (const comment of comments) {
            if (comment?.data && currentAutomation[comment.data] !== comment?.content) {
              pendingUpdates = true;
              break;
            }
          }
        }
        if (pendingUpdates) {
          const newAutomation = Object.fromEntries(comments?.map((c) => [c.data, c.content]) || []);
          autogeneratedChecklistComments[fetus][collection] = newAutomation;
          // simple copy to trigger re-render and useEffect-s
          return { ...autogeneratedChecklistComments };
        }
        // no updates - will not trigger re-render and useEffect-s
        return autogeneratedChecklistComments;
      });
    },
    [setAutogeneratedChecklistComments]
  );

  /*
   * Load static data for the report
   */
  useEffect(() => {
    loadStaticReportOptions();
  }, [
    examinationContext?.examination?.id,
    examinationContext?.examination?.site_id,
    examinationContext?.examination?.preset_id,
  ]);

  /*
   * Socket and dynamic data
   */

  useEffect(() => {
    const examinationId = examinationContext?.examination?.id;
    if (reportV2Channel) reportV2Channel.leave();
    setSocketLoaded(false);

    if (socket && examinationId) {
      const channelTopic = `report_v2:${examinationId}`;
      const channel = socket.channel(channelTopic);

      channel.on('update', (payload) => {
        // TODO
        console.log('• ReportV2 Channel: received "update" message', payload);
        /* For the moment we only send data_attribute */
        switch (payload.resource_type) {
          case 'data_attributes':
            setSocketLoaded(true);
            dispatchPlaceholders('update', payload.data);
            break;
        }
      });

      channel.on('error', (payload) => {
        console.error('• ReportV2 Channel: received "error" message', payload);
      });

      channel.on('debug', (payload) => {
        console.debug('• ReportV2 Channel: received "debug" message', payload);
      });

      channel.on('warning', (payload) => {
        // TODO put this messages on the debug pannel
        console.warn('• ReportV2 Channel: received "warning" message', payload);
      });

      channel
        .join()
        .receive('ok', () => {
          /* Example of usage:
           * reportV2Channel.push("require_data", {ids: ["patient.firstname", "patient.lastname", "patient.dob", "patient.age", "patient.sex"]})
           * reportV2Channel.push("update_data", {data: [{slug: "patient.firstname", examination_fetus_id: null, source: "user", selected: true, options: {comment: "", visible: true}, value: {value: "John"}}]})
           */
          window.reportV2Channel = channel;
          setReportV2Channel(channel);
          doDispatchPlaceholders({
            event: 'reload',
            data: { reportV2Channel: channel },
          });
        })
        .receive('error', () => {
          console.error(`Join resource channel with topic ${channelTopic} failed`);
        });

      channel.onError((...errors) => {
        /* Providing info in the console for the moment. Might be intresting to provide it to the user */
        console.info('%c• ReportV2 Channel: reconnecting', 'color: orange', errors);
      });
    }
  }, [socket, examinationContext?.examination?.id]);

  useEffect(() => {
    if (!reportV2Channel) {
      return;
    }
    const requestedIds = requiredPlaceholders.filter((id) => !placeholders[id]);
    if (requestedIds.length === 0) return;
    const timeout = setTimeout(() => {
      reportV2Channel.push('require_data', { ids: requestedIds });
    }, 100);
    return () => clearTimeout(timeout);
  }, [requiredPlaceholders.join('|'), !reportV2Channel]);

  useEffect(() => {
    if (requiredPlaceholdersPromises.length === 0) return;
    setRequiredPlaceholdersPromises((requiredPlaceholdersPromises) => {
      return requiredPlaceholdersPromises.filter(({ ids, resolve }) => {
        if (ids.every((id) => placeholders[id])) {
          resolve(Object.fromEntries(ids.map((id) => [id, placeholders[id]])));
          return false;
        }
        return true;
      });
    });
  }, [
    /* If we have a new placeholder */
    Object.keys(placeholders).sort().join('|'),
    /* If we have a new required placeholder */
    /* Warning here when we remove a promise it will call the useEffect again */
    requiredPlaceholdersPromises.map(({ ids }) => ids.sort().join('|')).join('"'),
  ]);

  /*
   * @param {Array} ids - Array of placeholders to require
   * @returns {Promise} - Promise that resolves when all placeholders are loaded. Resolve value is the loaded placeholders
   *
   * @example
   * // returns { "patient.firstname": {...}, "patient.lastname": {...}, "patient.dob": {...}, "patient.age": {...}, "patient.sex": {...}}
   * const placeholders = await requirePlaceholders(["patient.firstname", "patient.lastname", "patient.dob", "patient.age", "patient.sex"]);
   */
  const requirePlaceholders = useCallback(
    (ids) => {
      return new Promise((resolve) => {
        dispatchPlaceholders('require', { ids });
        setRequiredPlaceholdersPromises((requiredPlaceholdersPromises) => {
          return [...requiredPlaceholdersPromises, { ids, resolve }];
        });
      });
    },
    [reportV2Channel]
  );

  const sendInfoToBackend = useCallback((payload) => reportV2Channel.push('info', payload), [reportV2Channel]);
  const toggleBackendDebug = useCallback(() => {
    return new Promise((resolve, _reject) => {
      reportV2Channel.push('toggle_debug_logs', {}).receive('ok', (debug) => resolve(debug));
    });
  }, [reportV2Channel]);

  window.toggleBackendDebug = toggleBackendDebug;

  const BIContext = {
    examination_status: examinationContext.examination.preset_id,
    examination_preset_id: examinationContext.examination.preset_id,
    examination_id: examinationContext.examination.id,
    report_id: reportDataOptions?.report_id,
    report_version: examinationContext.examination.report_version,
  };

  // copied from 1.1
  const getHighligthedWithProps = (props) => {
    if (
      !props.unit &&
      (props.custom ||
        ['examination.method', 'examination.indication', 'examination.finding'].includes(props?.data || []))
    ) {
      const slug = (props.custom === 'true' ? 'custom.' : '') + props.data;
      return highlightedFields.find((e) => e.slug === slug);
    }
  };

  /*
   * Load dynamic dropdown corresponding to the provided slug
   * @param {String} slug - Slug of the dynamic dropdown to load
   * @returns {Promise} - Promise that resolves when the dynamic dropdown is loaded. Resolve value is the dynamic dropdown
   */
  const loadDynamicDropdownFullTree = useCallback(
    async (slug) => {
      return new Promise((resolve, reject) => {
        setDynamicDropdowns((dynamicDropdowns) => {
          // being in the setDynamicDropdowns callback function,
          // I am sure that no one else is trying to load my dropdown at the same time
          // and so only 1 call to the backend will be performed
          if (dynamicDropdowns[slug]?.state === 'loaded') {
            resolve(dynamicDropdowns[slug]);
            return dynamicDropdowns;
          }
          if (dynamicDropdowns[slug]?.state === 'loading') {
            return {
              ...dynamicDropdowns,
              [slug]: {
                ...dynamicDropdowns[slug],
                promises: [...dynamicDropdowns[slug]?.promises, { resolve, reject }],
              },
            };
          }

          const dropdownId = dynamicDropdowns[slug]?.id;
          const hasSubNodes = dynamicDropdowns[slug]?.tree?.some((node) => !node.selectable);
          if (!dropdownId) {
            reject(new Error(`Dynamic dropdown with slug ${slug} does not exists`));
            return dynamicDropdowns;
          }
          if (!hasSubNodes) {
            resolve(dynamicDropdowns[slug]);
            return {
              ...dynamicDropdowns,
              [slug]: { ...dynamicDropdowns[slug], state: 'loaded' },
            };
          }

          // calling the resource API in an async manner
          ResourceApi.getDynamicDropdownFullTree(examinationContext.examination.id, dropdownId)
            .then((response) => {
              if (response.status === 200) {
                setDynamicDropdowns((dynamicDropdowns) => {
                  const data = normalizeDropdown(response.data);
                  dynamicDropdowns[slug].promises.forEach(({ resolve }) => resolve(data));
                  return {
                    ...dynamicDropdowns,
                    [slug]: {
                      ...data,
                      state: 'loaded',
                      promises: [],
                    },
                  };
                });
              }
            })
            .catch((error) => {
              console.error(`Error while loading dynamic dropdown ${slug}`, error);
              setDynamicDropdowns((dynamicDropdowns) => {
                dynamicDropdowns[slug].promises.forEach(({ reject }) => reject(error));
                return {
                  ...dynamicDropdowns,
                  [slug]: {
                    ...dynamicDropdowns[slug],
                    state: 'not-loaded',
                    promises: [],
                  },
                };
              });
            });
          return {
            ...dynamicDropdowns,
            [slug]: {
              ...dynamicDropdowns[slug],
              state: 'loading',
              promises: [{ resolve, reject }],
            },
          };
        }); // end setDynamicDropdowns
      }); // end new Promise
    },
    [setDynamicDropdowns]
  );

  const [editingFieldId, setEditingFieldId] = useState({});

  const startEditingField = (placeholder, _override = false) => {
    if (!examinationContext.canEdit) return;
    setEditingFieldId(placeholder);
  };

  const endEditingField = () => {
    setEditingFieldId(false);
  };

  const [addUpdate, removeUpdate, updatingSlugs] = useUpdateQueue();

  const applyChanges = useCallback(
    async (changes, opts = {}) => {
      if (!examinationContext.canEdit) return;
      if (!isEditMode && !flashTemplateFieldsVisible) {
        setHighlightedFields((currentValue) => {
          return currentValue.filter((field) => !Object.keys(changes).includes(field.slug));
        });
      }
      // TODO - Create BI Event
      const data = PlaceholdersHelper.applySlugs(changes);
      optimisticUpdatePlaceholders(data);
      onEndEditingBIEvent(data, { ...BIContext, ...opts.BIContext });
      if (data.find(({ source }) => source === 'user')) setEdited(true);
      const update = addUpdate(data.map(({ slug }) => slug));
      return await new Promise((resolve, _reject) =>
        reportV2Channel.push('update_data', { data }).receive('ok', (...res) => {
          removeUpdate(update);
          return resolve(...res);
        })
      );
    },
    [reportV2Channel, examinationContext.canEdit]
  );

  const onEndEditing = useCallback(
    async (slug, newPlaceholders, opts = {}) => {
      if (!examinationContext.canEdit) return;

      console.log('• onEndEditing', { slug, newPlaceholders });
      return applyChanges({ [slug]: newPlaceholders }, opts);
    },
    [applyChanges]
  );

  /*
   * TODO make the API call possible with a bulk.
   * @description Send BI event when a field is edited
   *
   */
  const onEndEditingBIEvent = useCallback(
    (changes, BIContext) => {
      changes
        .filter((change) => !isNullOrUndefined(change))
        .forEach((changes) => {
          ResourceApi.createBIEvent({
            ...BIContext,
            changes,
            'data-id': changes.slug,
            report_version: examinationContext.examination.report_version,
            event_type: 'report_field_edited',
          });
        });
    },
    [examinationContext.examination.report_version]
  );

  const onEndEditingChecklistData = async (checklistItem, updatedData, opts = {}) => {
    if (!checklistItem) return;
    const checklistItems = Array.isArray(checklistItem) ? checklistItem : [checklistItem];

    const data = checklistItems
      .map((checklistItem) => {
        return {
          ...placeholdersHelper.selectedDatum(`checklist.item.${checklistItem.slug}`, opts.fetus),
          ...updatedData,
          source: 'user',
        };
      })
      .map((val) => {
        return { slug: `checklist.item.${checklistItem.slug}`, ...val };
      });

    optimisticUpdatePlaceholders(data);
    onEndEditingBIEvent(data, { ...BIContext, page: 'checklist-items' });
    console.log('• onEndEditingChecklistData', { data });
    reportV2Channel.push('update_data', { data });
  };

  const onEndEditingChecklist = async (value, checklistItem, opts = {}) => {
    if (!checklistItem) return;
    const checklistItems = Array.isArray(checklistItem) ? checklistItem : [checklistItem];

    const data = checklistItems
      .map((checklistItem) => {
        return placeholdersHelper
          .editSelectedDataValue(`checklist.item.${checklistItem.slug}`, { status: value }, opts.fetus, 'user')
          .map((val) => {
            return { slug: `checklist.item.${checklistItem.slug}`, ...val };
          });
      })
      .flat();

    optimisticUpdatePlaceholders(data);
    onEndEditingBIEvent(data, { ...BIContext, page: 'checklist-items' });
    console.log('• onEndEditingChecklist', { data });

    try {
      return await ResourceApi.updateChecklistItemStatus(
        examinationContext.examination.id,
        checklistItems.map((item) => item.id),
        value,
        checklistItems?.[0]?.examination_fetus_id
      );
    } catch (error) {
      notificationContext.showNotification(
        <>
          <Icon name="warning" /> {'report.unableToUpdate'}
        </>,
        5000
      );
    }
  };

  const checklistItemSlugIdMap = useMemo(() => {
    return reportDataOptions?.checklist_items?.reduce((acc, item) => {
      acc[item.slug] = item.id;
      return acc;
    }, {});
  }, [reportDataOptions?.checklist_items]);

  const onEndEditChecklistStatus = async (operations) => {
    if (operations.length === 0) return;

    const statusOperations = operations
      .map((op) => {
        const checklist_item_id = checklistItemSlugIdMap[op.slug];
        return {
          checklist_item_id,
          status: op.value,
          examination_fetus_id: op.examination_fetus_id,
        };
      })
      .filter(({ checklist_item_id }) => checklist_item_id);

    const data = operations
      .map((op) => {
        return placeholdersHelper
          .editSelectedDataValue(`checklist.item.${op.slug}`, { status: op.value }, op.fetus, 'user')
          .map((val) => {
            return { slug: `checklist.item.${op.slug}`, ...val };
          });
      })
      .flat();

    console.log('• onEndEditChecklistStatus', { data });

    optimisticUpdatePlaceholders(data);
    onEndEditingBIEvent(data, { ...BIContext, page: 'checklist-items' });

    try {
      return await ResourceApi.updateChecklistItemStatusV2(examinationContext.examination.id, statusOperations);
    } catch (error) {
      notificationContext.showNotification(
        <>
          <Icon name="warning" /> {__('report.unableToUpdate')}
        </>,
        5000
      );
    }
  };

  const addAutomationTemplate = (newTemplate) => {
    setReportDataOptions({
      ...reportDataOptions,
      automation_templates: [...reportDataOptions.automation_templates, newTemplate],
    });
  };

  const deleteAutomationTemplate = (template) => {
    setReportDataOptions((rpDataOptions) => ({
      ...rpDataOptions,
      automation_templates: rpDataOptions.automation_templates.filter((tp) => tp.slug !== template.slug),
    }));
  };

  const updateTemplateList = (slug, template) => {
    setReportDataOptions({
      ...reportDataOptions,
      automation_templates: reportDataOptions.automation_templates.map((temp) =>
        temp.slug === slug ? template : temp
      ),
    });
  };

  /*
   * Define if the report placeholders are stable.
   * We are checking that no additional placeholders are loading so
   * we can display the report without showing blinking fields
   */
  const loaded = !!(reportDataOptions?.report_template && reportDataOptions?.medical_history_template);
  const stable = useStability(
    1000,
    100,
    /* We need thoses data to be loaded before stating that the report is loaded */
    !(loaded && requiredPlaceholders.length === 0),
    [loaded, requiredPlaceholders.length]
  );

  useEffect(() => {
    if (socketLoaded) return;
    if (!stable) return;
    /* Providing info in the console for the moment. Might be intresting to provide it to the user */
    console.info('%c• ReportV2 Channel: reconnecting', 'color: orange');
  }, [socketLoaded]);

  const enrichedReportDataOptions = useMemo(() => {
    return {
      ...reportDataOptions,
      report_dropdowns: dynamicDropdowns,
      labels: measurementsContext.labels,
    };
  }, [reportDataOptions, dynamicDropdowns, reportDataOptions, measurementsContext.labels]);

  const revertAssignedGa = useCallback(async () => {
    try {
      await ResourceApi.revertAssignedGaToPrevExam(examinationContext?.examination?.id);
      await examinationContext.refreshDating();
    } catch (error) {
      console.error(error);
      notificationContext.showNotification(
        <>
          <Icon name="warning" /> {__('report.unableToUpdate')}
        </>,
        5000
      );
    }
  }, [examinationContext?.examination?.id]);

  const value = {
    /* Legacy fields */
    onEndEditing,
    applyChanges,
    startEditingField,
    endEditingField,
    editingFieldId,
    loadDynamicDropdownFullTree,
    addAutomationTemplate,
    revertAssignedGa,
    deleteAutomationTemplate,
    getHighligthedWithProps,
    /* New fields */
    loaded,
    // We don't want to show loader if the report has already been edited
    stable: stable || edited,
    requirePlaceholders,
    sendInfoToBackend,
    reportDataOptions: enrichedReportDataOptions,
    BIContext,
    apiVersion,
    flashTemplateFieldsVisible,
    canEdit: examinationContext.canEdit,
    isEditMode,
    setIsEditMode,
    // Keeping the old name of old report template
    setAutomationTemplateFieldsVisible: setFlashTemplateFieldsVisible,
    automationTemplateFieldsVisible: flashTemplateFieldsVisible,
    updateTemplateList,
  };

  const checklistValue = {
    onEndEditChecklistStatus,
    updateAutogeneratedChecklistComments,
    autogeneratedChecklistComments,
    componentChecklistAssoc,
    onEndEditingChecklist,
    onEndEditingChecklistData,
    updateComponentChecklistAssoc,
  };

  const checklistItemsToDisplayIds = useMemo(() => {
    return Object.values(componentChecklistAssoc)
      .flatMap((cis) => cis)
      .filter(onlyUnique);
  }, [componentChecklistAssoc]);

  const defaultComment = (id, fetus) => {
    const data_id = `checklist.item.${id}`;
    return Object.values(autogeneratedChecklistComments[fetus] || {}).find((c) => c[data_id])?.[data_id] ?? '';
  };

  const [localPlaceholders, setLocalPlaceholders] = useState(placeholders ?? {});

  useEffect(() => {
    setLocalPlaceholders((localPlaceholders) => {
      const entries = Object.entries(placeholders)
        .filter(([_, v]) => !isNullOrUndefined(v))
        .map(([key, placeholder]) => {
          if (placeholder.update_guid === localPlaceholders[key]?.update_guid || updatingSlugs.has(key)) {
            /* the placeholder has not yet been updated by the backend or it is currently being updated */
            return [key, localPlaceholders[key]];
          } else {
            /* the placeholder has been updated by the backend */
            return [key, placeholder];
          }
        });
      return Object.fromEntries(entries);
    });
  }, [
    /*
     * updatingSlugs is on purpose not in the dependencies
     * We don't want to trigger a sync with the placeholders if the update is performed by the backend
     * We know the placeholder was outdated. And we are waiting to receive the next update that will
     * come when the channel has synced.
     */
    Object.entries(placeholders)
      .filter(([_, v]) => !isNullOrUndefined(v))
      .map(([key, { update_guid }]) => `${key}-${update_guid}`)
      .sort()
      .join('|'),
  ]);

  const optimisticUpdatePlaceholders = useCallback(
    (newPlaceholders) => {
      setLocalPlaceholders((localPlaceholders) => updateOptimisticPlaceholders(localPlaceholders, newPlaceholders));
    },
    [setLocalPlaceholders]
  );

  const placeholdersHelper = localPlaceholders['fetus.order']
    ? new PlaceholdersHelper({ ...value, placeholders: localPlaceholders })
    : {};

  const checklistItemsToDisplay = checklistItemsToDisplayIds
    .map((id) => {
      const fieldId = `checklist.item.${id}`;
      if (!localPlaceholders[fieldId]) return null;
      const fetuses = placeholdersHelper.allOrderedFetuses ?? [];
      return fetuses
        .map((_, fetus) => [fetus, placeholdersHelper.selectedDatum(fieldId, fetus)])
        .map(([fetus, datum]) => {
          return {
            ...datum.value,
            examination_fetus_id: datum.examination_fetus_id,
            id: datum.value?.item_id,
            assoc_checklist_item_checklist_item_group: {
              checklist_item_group_id: datum.value?.group_id,
              checklist_item_id: datum.value?.item_id,
            },
            visible: datum.visible ?? true,
            comment: datum.comment ?? defaultComment(id, fetus),
            slug: id,
          };
        });
    })
    .filter((item) => item);

  /*
   * Generate the automation template - This implementation is partial and do not manage a lot of things:
   *  - It only save the value of default fetus
   *  - Audit on going ...
   *
   * @param {Function} setTemplateCallback - Callback called with the generated template as first argument
   * @returns {String} - The automation generated template
   */
  const generateAutomationTemplate = async (setTemplateCallback = null) => {
    const template = Object.keys(placeholders)
      .filter(isFlashReportField)
      .reduce((acc, key) => {
        const json = placeholdersHelper.allOrderedFetuses
          .map((examination_fetus_id, fetus) => {
            const datum = selectedDatum(
              placeholdersHelper.apiVersion,
              placeholdersHelper.placeholders[key],
              examination_fetus_id
            );
            const value = datum?.value?.value;
            return { fetus, value };
          })
          .filter(({ value }) => !!value);
        if (json.length === 0) return acc;
        acc.push('{% assign ' + key.replaceAll('.', '__') + ' = ' + JSON.stringify(JSON.stringify(json)) + ' %}');
        return acc;
      }, [])
      .join('\n');

    if (setTemplateCallback) {
      setTemplateCallback(template);
    }

    return template;
  };

  /*
   * Evaluate the provided template to a data bundle provided
   * @param {String} templateStr - The automation template to evaluate
   * @param {Object} initialData - The initial data to use for the evaluation
   * @returns {Object} - The evaluated data
   */
  const evaluateAutomationTemplate = async (templateStr, initialData = {}) => {
    // At the end of the template add the assigns_to_json - to convert all assigned items to JSON structure.
    const template = templateStr + `\n{% assigns_to_json %}`;
    // Prepare the input data for the template.
    // Note: we replace all dots in the keys with double underscores since LiquidJS does not support dots in keys.
    const data = replaceAllKeys(initialData, '.', '__');
    const output = evalTemplate(template, data);
    return Object.entries(replaceAllKeys(JSON.parse(output), '__', '.'))
      .map(([key, valueJSONString]) => {
        return JSON.parse(valueJSONString).map(({ fetus, value }) => ({
          key,
          fetus,
          value,
        }));
      })
      .flat();
  };

  /*
   * Apply the automation template to the current report
   * @param {String} template id - The automation template id to apply
   * @return {Promise} - Promise that resolves when the template is applied
   */
  const applyAutomationTemplate = useCallback(
    (slug) => {
      // TODO be able to load until the socket has applied all the value to the placeholders
      return new Promise((resolve, reject) => {
        if (examinationContext.examination?.preset_id && slug) {
          ResourceApi.getAutomationTemplate(examinationContext.examination.preset_id, slug)
            .then((resp) => evaluateAutomationTemplate(resp.data.data.template, {}))
            .then((data) => {
              const payload = data
                .filter(({ fetus }) => placeholdersHelper.numberOfFetuses >= fetus)
                .map(({ key, value, fetus }) => {
                  return placeholdersHelper
                    .editSelectedDataValue(key, { value }, fetus, 'flash_report')
                    .map((k) => ({ slug: key, ...k }));
                })
                .flat();

              console.log('• applyAutomationTemplate', { payload });
              reportV2Channel.push('update_data', { data: payload }, 10000).receive('ok', () => {
                console.log('• applyAutomationTemplate applied');
                setHighlightedFields(
                  payload.map((field) => ({
                    icon: 'flash',
                    iconClass: '',
                    slug: field.slug,
                    source: 'flashReport',
                  }))
                );
                resolve();
              });
            })
            .catch(reject);
        }
      });
    },
    [examinationContext.examination?.preset_id, evaluateAutomationTemplate, reportV2Channel]
  );

  // Debug
  window.placeholders = placeholders;
  window.localPlaceholders = localPlaceholders;
  window.apiVersion = '2.0';
  window.requirePlaceholders = requirePlaceholders;
  window.reportDataOptions = enrichedReportDataOptions;
  window.autogeneratedChecklistComments = autogeneratedChecklistComments;
  window.examinationContext = examinationContext;

  return (
    <XMLTemplateContext.Provider
      value={{
        ...value,
        ...checklistValue,
        generateAutomationTemplate,
        applyAutomationTemplate,
        checklistItemsToDisplay,
        fetuses: placeholdersHelper.fetuses ?? [],
        placeholders: localPlaceholders,
      }}
    >
      {children}
    </XMLTemplateContext.Provider>
  );
};

export default XMLTemplateContextProvider;
