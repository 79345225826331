import { useContext, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

/* Contextes */
import { ExaminationContext } from '../../context-providers/Examination';
import { WindowContext } from '../../context-providers/Window';
import useAuth from '../../context-providers/Auth';

/* Services */
import ResourceApi from '../../services/resource';

/* CSS */
import './index.css';

import { ImageManipulationTool } from '@sonio-ai/image-manipulation-tool';

const uncacheThumbnail = (dicomInstanceId) => {
  /* This hack is to reload the thumbnail in the browser cache ... */

  const iframe = document.createElement('iframe');
  document.body.append(iframe);
  const img = iframe.contentWindow.document.createElement('img');
  img.addEventListener(
    'load',
    () => {
      /* We can now reload the iframe */
      iframe.addEventListener('load', () => {
        /* Now we can delete everything */
        iframe.remove();
      });
      iframe.contentWindow.location.reload(true);
    },
    false
  );
  img.src = `/api/v2/dicom-instance/${dicomInstanceId}/thumbnail`;
};

/****************************/
/* Component implementation */
/****************************/

const ImageManipulation = ({ t: __, instanceId }) => {
  const examinationContext = useContext(ExaminationContext);
  const windowContext = useContext(WindowContext);
  const { isFeatureFlagEnabled } = useAuth();
  const history = useHistory();
  const examId = Number(useParams()?.examId);
  const dicomInstanceId = instanceId || useParams()?.dicomInstanceId;
  const instance = (examinationContext?.instances || []).find(({ id }) => id == dicomInstanceId);

  const trackEvent = useCallback(
    (event_type, event_data) => {
      ResourceApi.createBIEvent({
        ...event_data,
        event_type: event_type,
        examination_id: examId,
        instance_id: dicomInstanceId,
      });
    },
    [ResourceApi.createBIEvent]
  );

  /* Load examination */
  useEffect(() => {
    examinationContext.loadExamination(examId);
  }, [examId]);

  const closeImageEditor = useCallback(() => {
    if (isFeatureFlagEnabled('sonio.multiscreen') && windowContext.isDetached) {
      windowContext.postMessageToView('core', { event: 'refreshInstances' });
      windowContext.postMessageToView('core', { event: 'refreshInstanceImg', mediaId: dicomInstanceId });
      // In multiscreen mode, we simply go back to the image from which the measurement started.
      history.goBack();
    } else {
      history.push(`/exam/${examId}/#media-${dicomInstanceId}`);
    }
  }, [dicomInstanceId, isFeatureFlagEnabled, windowContext.isDetached, windowContext.postMessageToView, history]);

  const onSave = useCallback(
    ({ measurements, caption, thumbnailDataURL }) => {
      if (!instance) return;
      trackEvent('measurement-tool-save', {});

      ResourceApi.updateDicomInstance(dicomInstanceId, {
        user_edits: {
          measurement: measurements,
          caption,
        },
        thumbnailDataURL,
      })
        .then(({ data: { data } }) => {
          uncacheThumbnail(dicomInstanceId);
        })
        .finally(() => {
          closeImageEditor();
        });
    },
    [instance, trackEvent, ResourceApi.updateDicomInstance, uncacheThumbnail, closeImageEditor]
  );

  return (
    <div>
      {instance && (
        <ImageManipulationTool
          {...{
            __,
            instance,
            trackEvent,
            isDetached: windowContext.isDetached,
            onSave,
            closeImageEditor,
          }}
        />
      )}
    </div>
  );
};

export default withTranslation()(ImageManipulation);
