import Icon from '../../Icon/Icon';

// allowUpdates does actually do the opposite of what it should
// so we introduce preventUpdates and should remove allowUpdates asap
const NotVisibleOverlay = ({ onClick, allowUpdates = false, preventUpdates = false }) => {
  return (
    <div
      className="inline-editing-not-visible-mask"
      data-allow-updates={`${preventUpdates || allowUpdates}`}
      onClick={onClick}
    >
      <Icon name="eye-off" />
    </div>
  );
};

export default NotVisibleOverlay;
