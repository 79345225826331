import DiagnosticPanel from './DiagnosticPanel';
import ChecklistSummary from './ChecklistSummary';
import LeftNavbar from '../../components/LeftNavbar';
import './DxChecklistView.css';
import DxSidebar from './DxSidebar';

const DxChecklistView = ({ medications }) => {
  return (
    <>
      <LeftNavbar />
      <div className="dx-container">
        <div className="dx-sidebar">
          <DxSidebar medications={medications} />
        </div>
        <div className="dx-checklist-zones">
          <DiagnosticPanel />
          <ChecklistSummary />
        </div>
      </div>
    </>
  );
};

export default DxChecklistView;
