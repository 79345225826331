import { withTranslation } from 'react-i18next';
import { formatDynamicValue, sanitizeDynamicMacros } from '../../dynamicMacros';
import './DynamicElement.css';

// This module is responsible for
// - rendering the macros dynamic elements in both edit and print mode
// - providing the necessary dynamic macro callbacks that depends on the way its is rendered (isDynamicElement, sanitize, dynamicElementToValue)

export const PrintableDynamicValue = withTranslation()(({ t: __, placeholder, attributes, raw }) => {
  const displayedValue = formatDynamicValue(__, placeholder, attributes);
  return (
    <span data-raw={raw} contentEditable="false" suppressContentEditableWarning={true}>
      {displayedValue}
    </span>
  );
});

export const DynamicValue = withTranslation()(({ t: __, placeholder, attributes, raw }) => {
  const displayedValue = formatDynamicValue(__, placeholder, attributes);
  return (
    <span className="dynamic-macro" data-raw={raw} contentEditable="false" suppressContentEditableWarning={true}>
      {displayedValue}
    </span>
  );
});

export const isDynamicElement = (element) => {
  return element?.classList?.contains('dynamic-macro');
};
export const dynamicElementToValue = (element) => {
  return element.dataset.raw;
};

export const sanitize = (html) =>
  sanitizeDynamicMacros(html, {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['data-raw', 'contentEditable', 'contenteditable', 'suppressContentEditableWarning', 'class'],
    },
  });
