import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import DraftExamCard from '../../components/DraftExamCard/DraftExamCard';
import ExamPresetButtons from '../../components/ExamPresetButtons/ExamPresetButtons';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { getRiskFactors, getTeratogenicRisks } from '../../services/examination';
import LookupApi from '../../services/lookup';
import './DashboardView.css';

const DashboardView = ({ t: __, createDraftExam, deleteDraftExam, draftExams = [], medicalHistoryItems = [] }) => {
  const appContext = useContext(AppContext);
  const [medications, setMedications] = useState([]);
  const [draftExamsLength, setDraftExamsLength] = useState(6);
  const { isFeatureFlagEnabled } = useAuth();

  const defaultExamType = isFeatureFlagEnabled('soniopedia')
    ? 'diagnostic'
    : isFeatureFlagEnabled('sonio.dx_v2')
    ? appContext.preferences.default_exam_type
    : 'screening';

  /**
   * load medications data into "medication" variable starting from their IDs
   * @param {array} medicationIds list of IDs
   */
  // TODO: refactor: we already load medications inside the examination context, maybe this part can be replaced by that one
  const loadMedications = (medicationIds) => {
    if (!Array.isArray(medicationIds) || !medicationIds.length) return false;
    const missingMedications = medicationIds.reduce(
      (ids, id) => (!medications.find((m) => m.id === id) ? [...ids, id] : ids),
      []
    );
    if (!missingMedications.length) return false;
    LookupApi.getMedicationsByIds(missingMedications).then((results) =>
      setMedications([...medications, ...results.data])
    );
    return true;
  };

  useEffect(() => {
    for (const draft of draftExams.slice(0, draftExamsLength)) {
      if (draft.medical_history && draft.medical_history['teratogenicrisks.medications'])
        loadMedications(draft.medical_history['teratogenicrisks.medications']?.value);
    }
  }, [draftExams, draftExamsLength]);

  return (
    <div className="dashboard-container">
      <div className="start-new-exam">
        <div className="dashboard-new-exam-button">
          <div className="icon-wrapper">
            <Icon name="ultrasound" />
          </div>
          <div
            className="label"
            dangerouslySetInnerHTML={{
              __html:
                defaultExamType === 'diagnostic'
                  ? __('dashboard.startNewExaminationDx')
                  : __('dashboard.startNewExamination'),
            }}
          ></div>
          <ExamPresetButtons action="new-exam" variant="outline" label="" />
        </div>
      </div>
      {!!draftExams.length && (
        <div className="row">
          <div className="w12">
            <h1>{__('patients.draftExams')}</h1>
            <ul className="dashboard-drafts">
              {draftExams.slice(0, draftExamsLength).map((draft) => {
                const riskFactors = draft.medical_history
                  ? getRiskFactors(draft.medical_history, medicalHistoryItems)
                  : [];
                const teratogenicRisks = draft.medical_history
                  ? getTeratogenicRisks(draft.medical_history, medications)
                  : [];

                return (
                  <li key={draft.id}>
                    <DraftExamCard
                      exam={draft}
                      riskFactors={riskFactors}
                      teratogenicRisks={teratogenicRisks}
                      deleteDraftExam={deleteDraftExam}
                      dateFormat={appContext.preferences.date_format}
                      fullHeight={true}
                    />
                  </li>
                );
              })}
            </ul>
            {draftExams.length > draftExamsLength && (
              <div className="dashboard-load-more">
                <Button
                  label={__('patients.loadMore')}
                  variant="outline"
                  onClick={() => setDraftExamsLength((count) => count + 6)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(DashboardView);

DashboardView.propTypes = {
  t: PropTypes.any,
  createDraftExam: PropTypes.func,
  draftExams: PropTypes.array,
};
