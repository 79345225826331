import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import useAuth from '../../context-providers/Auth';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import { WindowContext } from '../../context-providers/Window';
import { useHistory } from 'react-router-dom';
import { ExamStatus, stakeholderRoles } from '../../config';
import ContactPointCard from '../../components/ContactPointCard/ContactPointCard';
import ResourceApi from '../../services/resource';
import SearchBar from '../../components/SearchBar/SearchBar';
import SimpleSearchEngine from '../../components/SearchBar/SimpleSearchEngine';
import Icon from '../../atoms/Icon/Icon';
import ButtonBack from '../../atoms/ButtonBack/ButtonBack';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import TextArea from '../../atoms/TextArea/TextArea';
import Button from '../../atoms/Button/Button';
import { formatName } from '../../services/examination';
import './SubmitExamDialog.css';

const SubmitStatus = {
  NOT_SUBMITTED: 1,
  LOADING: 2,
  SUCCESS: 3,
  ERROR: 4,
};

const ViewExamStakeholdersDialog = ({
  t: __,
  status,
  setSubmitDialogIsOpen,
  examination,
  commentValue,
  setCommentValue,
  submitReport,
  submitAllowed = true,
  allowClosing = true,
  associateEntity,
  deassociateEntity,
  associateContactPoint,
  deassociateContactPoint,
}) => {
  const appContext = useContext(AppContext);
  const windowContext = useContext(WindowContext);
  const history = useHistory();
  const { user } = useAuth();

  const [possibleEntities, setPossibleEntities] = useState([]);
  const [tempNewCard, setTempNewCard] = useState(false);
  const [internalSubmitStatus, setSubmitStatus] = useState(SubmitStatus.NOT_SUBMITTED);
  const [willGoToDashboard, setWillGoToDashboard] = useState(false);

  const examinationContext = useContext(ExaminationContext);
  const submitStatus = examinationContext?.canEdit ? internalSubmitStatus : SubmitStatus.SUCCESS;

  const goToDashboard = () => {
    if (windowContext.isDetached) {
      windowContext.parentWindow.location = '/';
    } else {
      history.push('/');
    }
    appContext.activateGoBackMenuButton(false);
  };

  const onClickSubmit = () => {
    setSubmitStatus(SubmitStatus.LOADING);
    submitReport(commentValue, status)
      .then((res) => {
        setSubmitStatus(res.status === 200 ? SubmitStatus.SUCCESS : SubmitStatus.ERROR);
        setWillGoToDashboard(true);
      })
      .catch(() => setSubmitStatus(SubmitStatus.ERROR));
  };

  useEffect(() => {
    if (!willGoToDashboard) return;
    const timeout = setTimeout(goToDashboard, 1000);
    return () => clearTimeout(timeout);
  }, [willGoToDashboard]);

  const onClickCancel = () => {
    setCommentValue && setCommentValue('');
    setSubmitDialogIsOpen(false);
  };

  useEffect(() => {
    ResourceApi.listPossibleEntityToExamination(examination.id).then((r) => {
      setPossibleEntities(r.data.data);
    });
  }, []);

  useEffect(() => {
    // frozen exam: do nothing
    if (ExamStatus.REPORT_SIGNED === examinationContext.examination.status) return;

    // submitting with no performing clinician, ask for a perf clinician
    if (
      submitReport &&
      submitAllowed &&
      [SubmitStatus.NOT_SUBMITTED, SubmitStatus.LOADING].includes(submitStatus) &&
      possibleEntities.some((entity) => entity.id === user.id) &&
      !examinationContext.examination.practitioner_id
    ) {
      const option = getStakeholderRoleOptions().find((option) => option.value === 'main_performing_clinician');
      if (option) setTempNewCard(option);
    }

    // submitting with no reader, ask for a reader
    if (
      submitReport &&
      submitAllowed &&
      [SubmitStatus.NOT_SUBMITTED, SubmitStatus.LOADING].includes(submitStatus) &&
      possibleEntities.some((entity) => entity.id === user.id) &&
      !examinationContext.examination.reader_id
    ) {
      const option = getStakeholderRoleOptions().find((option) => option.value === 'main_reading_provider');
      if (option) setTempNewCard(option);
    }

    // signing with no main reader, add myself as main main reader
    if (
      submitReport &&
      submitAllowed &&
      examinationContext.canSign &&
      possibleEntities.some((entity) => entity.id === user.id) &&
      !examinationContext.examination.reader_id
    )
      examinationContext.updateExamination({ reader_id: user.id });
  }, [possibleEntities]);

  useEffect(() => {
    if (examinationContext.examination?.reader_id) {
      setTempNewCard(false);
    }
  }, [examinationContext.examination?.reader_id]);

  const addEntity = (entity, role) => {
    if (role.id === 'main_performing_clinician') {
      examinationContext.updateExamination({ practitioner_id: entity?.id });
    } else if (role.id === 'main_reading_provider') {
      examinationContext.updateExamination({ reader_id: entity?.id });
    } else {
      associateEntity({ entity_id: entity.id, role: role.id }, entity.title);
    }
  };

  const removeEntity = (entity) => {
    if (entity.role === 'main_performing_clinician') {
      examinationContext.updateExamination({ practitioner_id: null });
    } else if (entity.role === 'main_reading_provider') {
      examinationContext.updateExamination({ reader_id: null });
    } else {
      deassociateEntity({ id: entity.id });
    }
  };

  const addContactPoint = (contact, role) => {
    if (contact?.value) {
      associateContactPoint({ contact_point_id: contact?.value, role }, contact?.contact_point);
    }
  };

  const removeContactPoint = (contact) => {
    if (contact?.association_id) {
      deassociateContactPoint(contact?.association_id);
    }
  };

  const getStakeholderRoleOptions = () => {
    const hasMainPractitioner = !!examination?.practitioner_id;
    const hasMainReader = !!examination?.reader_id;

    const roles = stakeholderRoles?.map((r) => ({
      label: __('examinationStakeholder.role.' + (r.external ? 'external.' : '') + r.id),
      value: r.id,
      id: r.id,
      external: r.external,
      icon: r.external ? 'hospital' : 'user',
      disabled:
        (r.id === 'main_performing_clinician' && hasMainPractitioner) ||
        (r.id === 'main_reading_provider' && hasMainReader),
    }));
    return [
      { type: 'heading', label: __('examinationStakeholder.role.internal') },
      ...roles.filter((r) => !r.external),
      { type: 'separator' },
      { type: 'heading', label: __('examinationStakeholder.role.external') },
      ...roles.filter((r) => r.external),
    ];
  };

  const allEntities = useMemo(() => {
    return [
      {
        id: examination.practitioner_id,
        entity_id: examination.practitioner_id,
        contact_point: {
          id: examination.practitioner_id,
          name: possibleEntities.find((e) => e.id === examination.practitioner_id)?.title,
        },
        role: 'main_performing_clinician',
      },
      {
        id: examination.reader_id,
        entity_id: examination.reader_id,
        contact_point: {
          id: examination.reader_id,
          name: possibleEntities.find((e) => e.id === examination.reader_id)?.title,
        },
        role: 'main_reading_provider',
      },
      ...(examination.entities || []).map((entity) => ({
        ...entity,
        contact_point: { id: entity?.entity_id, name: entity?.entity?.title },
      })),
      ...({}.examination_data?.associated_contact_points || []),
    ].filter((entity) => entity.entity_id);
  }, [
    examination.reader_id,
    examination.practitioner_id,
    possibleEntities,
    examination.entities,
    examination.associated_contact_points?.map(({ id }) => id)?.join('|'),
  ]);

  return (
    <>
      <div className="modal-background" onClick={() => allowClosing && onClickCancel()} />
      <div className="exam-report-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="exam-report-dialog-header">
          {allowClosing && <ButtonBack onClick={onClickCancel} />}
          <div className="exam-report-dialog-header_title">
            {![SubmitStatus.LOADING, SubmitStatus.SUCCESS, SubmitStatus.ERROR].includes(submitStatus) && (
              <>
                <h2>{__('examinationReview.stakeholders.title')}</h2>
                <SelectInput
                  placeholder={{
                    label: __('examinationReview.dialog.stakeholders.addNew'),
                    value: '',
                    icon: 'add',
                  }}
                  theme="grey"
                  options={getStakeholderRoleOptions()}
                  onChange={(role, option) => setTempNewCard(option)}
                />
              </>
            )}
          </div>
          {allowClosing ? <ButtonBack icon="close" onClick={onClickCancel} /> : <ButtonBack icon="empty" />}
        </div>

        {![SubmitStatus.LOADING, SubmitStatus.SUCCESS, SubmitStatus.ERROR].includes(submitStatus) && (
          <div className="exam-report-dialog-body">
            <div>
              <h3 className="section-title">
                <Icon name="user" /> {__('examinationStakeholder.role.internal')}
              </h3>
              <div className="exam-report-dialog-stakeholder-section">
                <div className="exam-report-dialog-stakeholder-list">
                  {stakeholderRoles
                    .filter((r) => !r.external)
                    .map((r) => (
                      <Fragment key={r.id}>
                        {allEntities
                          .filter((e) => e.role === r.id)
                          .map((entity) => (
                            <StakeholderLine
                              key={r.id + '_' + entity.id}
                              entity={entity}
                              onRemove={removeEntity}
                              __={__}
                            />
                          ))}
                        {!tempNewCard?.external && tempNewCard?.value === r.id && (
                          <StakeholderTempCard
                            setTempNewCard={setTempNewCard}
                            role={r}
                            possibleEntities={possibleEntities}
                            addEntity={addEntity}
                            __={__}
                          />
                        )}
                      </Fragment>
                    ))}
                </div>
              </div>
            </div>
            <div>
              <h3 className="section-title">
                <Icon name="hospital" /> {__('examinationStakeholder.role.external')}
              </h3>
              <div className="exam-report-dialog-stakeholder-section">
                <div className="exam-report-dialog-stakeholder-list">
                  {examination.associated_contact_points?.map((stakeholder) => (
                    <ContactPointLine
                      key={stakeholder.id}
                      stakeholder={stakeholder}
                      onRemove={stakeholder.id ? removeContactPoint : false}
                      __={__}
                    />
                  ))}
                  {stakeholderRoles.some((r) => tempNewCard?.external && tempNewCard?.value === r.id && r.external) && (
                    <ContactPointTempCard
                      setTempNewCard={setTempNewCard}
                      role={tempNewCard?.value}
                      addContactPoint={(entity) => addContactPoint(entity, tempNewCard?.value)}
                      __={__}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {submitReport && submitAllowed && [SubmitStatus.NOT_SUBMITTED].includes(submitStatus) && (
          <div className="exam-report-dialog_body">
            <div className="exam-report-dialog-comment">
              <ExamSubmitCommentBox
                examination={examination}
                submitStatus={submitStatus}
                onClickSubmit={onClickSubmit}
                commentValue={commentValue}
                setCommentValue={setCommentValue}
                onClickCancel={onClickCancel}
                status={status}
              />
            </div>
          </div>
        )}

        {submitReport &&
          submitAllowed &&
          [SubmitStatus.LOADING, SubmitStatus.SUCCESS, SubmitStatus.ERROR].includes(submitStatus) && (
            <ExamSubmitted
              goToDashboard={goToDashboard}
              submitStatus={submitStatus}
              onClickCancel={onClickCancel}
              status={status}
            />
          )}
      </div>
    </>
  );
};

export default withTranslation()(ViewExamStakeholdersDialog);

const StakeholderLine = ({ entity, onRemove, __ }) => {
  return (
    <div className="exam-report-dialog-stakehoder">
      <ContactPointCard
        contact={{
          name: entity.contact_point?.name,
          role: __(`examinationStakeholder.role.${entity.role}`),
        }}
        onRemove={entity.id ? () => onRemove(entity) : false}
      />
    </div>
  );
};

const ContactPointLine = ({ stakeholder, onRemove, __ }) => {
  if (!stakeholder.contact_point) return false;

  return (
    <div className="exam-report-dialog-stakehoder">
      <ContactPointCard
        contact={{
          ...stakeholder,
          ...stakeholder.contact_point,
          role: __(`examinationStakeholder.role.external.${stakeholder.role}`),
          association_id: stakeholder.id,
        }}
        onRemove={onRemove ? (contact) => onRemove(contact) : false}
        icon="hospital"
      />
    </div>
  );
};

const ContactPointTempCard = ({ role, setTempNewCard, addContactPoint, __ }) => {
  const onSearch = async (searchKey) => {
    if (searchKey) {
      const contactPoints = await ResourceApi.searchContactPoint(searchKey);
      return (
        contactPoints?.data?.data?.map((cp) => ({
          label: formatName(cp.name).fullName,
          value: cp.id,
          contact_point: cp,
        })) || []
      );
    }
    return [];
  };

  return (
    <div className="contact-point-card exam-report-dialog-tempcard">
      <div className="contact-point-card_icon">
        <Icon name="hospital" />
      </div>
      <div className="contact-point-card_name">
        <div className="contact-point-card_role">{__(`examinationStakeholder.role.external.${role}`)}</div>
        <div>
          <SimpleSearchEngine
            placeholder={__('examinationReview.referents.search')}
            onSearch={onSearch}
            minLength="3"
            onSelect={(contactPoint) => {
              setTempNewCard(false);
              addContactPoint(contactPoint);
            }}
          >
            <SearchBar />
          </SimpleSearchEngine>
        </div>
      </div>
      <div className="contact-point-card_remove" onClick={() => setTempNewCard(false)}>
        <Icon name="close" />
      </div>
    </div>
  );
};

const StakeholderTempCard = ({ role, setTempNewCard, possibleEntities, addEntity, __ }) => {
  const validUsers = possibleEntities
    .filter(
      (entity) =>
        !role.permission.length ||
        entity.actions?.some((action) => action.allowed && role.permission.includes(action.action))
    )
    .map((entity) => ({
      label: entity?.title,
      value: entity?.id,
      id: entity?.id,
    }));

  return (
    <div className="contact-point-card exam-report-dialog-tempcard">
      <div className="contact-point-card_icon">
        <Icon name="user" />
      </div>
      <div className="contact-point-card_name">
        <div className="contact-point-card_role">{__(`examinationStakeholder.role.${role.id}`)}</div>
        <div>
          <SelectInput
            value=""
            placeholder={{ label: 'Select a user' }}
            options={validUsers}
            theme="white"
            showRecent={'stakeholders_' + role.id}
            showMostUsed={'stakeholders' + role.id}
            showSearchBar="true"
            onChange={(entityId) => {
              addEntity(
                possibleEntities.find((entity) => entity.id === entityId),
                role
              );
              setTempNewCard(false);
            }}
          />
        </div>
      </div>
      <div className="contact-point-card_remove" onClick={() => setTempNewCard(false)}>
        <Icon name="close" />
      </div>
    </div>
  );
};

const ExamSubmitCommentBox = withTranslation()(
  ({ t: __, examination, submitStatus, onClickSubmit, commentValue, setCommentValue, onClickCancel, status }) => {
    const submitButtonLabel =
      status === ExamStatus.REPORT_SIGNED ? __('examinationReview.dialog.signReportButton') : __('common.submit');
    const { user } = useAuth();

    /* Disbale the sign button if the report is not signable:
     *  - If the reader ID is not provided (to the report or to the examination)
     */
    const sign_disabled = status === ExamStatus.REPORT_SIGNED && !examination?.reader_id;

    /*
     * Disable the submit button if loading or if should sign and it is not signable
     */
    const submit_disabled = submitStatus === SubmitStatus.LOADING || sign_disabled;

    return (
      <>
        <div className="exam-report-dialog-content">
          <h2>
            {__(
              status === ExamStatus.REPORT_SIGNED
                ? 'examinationReview.dialog.signReport'
                : 'examinationReview.dialog.submitForReview'
            )}
            <em>{' ' + __('examinationReview.dialog.submitBy', { name: user.title })}</em>
          </h2>
          <TextArea
            value={commentValue}
            fullwidth={true}
            onChange={(value) => setCommentValue(value)}
            placeholder={__('examinationReview.dialog.writeAComment')}
            disabled={submitStatus === SubmitStatus.LOADING}
          />
        </div>
        <div className="exam-report-dialog-bottom-bar direction-column">
          <small>
            <Icon name="info" />{' '}
            {__(
              !ExamStatus.READY_FOR_REVIEW
                ? 'examinationReview.beforeExaminationSigned'
                : 'examinationReview.beforeExaminationSubmitted'
            )}{' '}
            {__('examinationReview.checkMatching')}
          </small>
          <div className="buttons">
            <Button variant="outline" onClick={onClickCancel} icon="close" label={__('examination.cancel')} />
            <Button
              onClick={onClickSubmit}
              icon={status === ExamStatus.REPORT_SIGNED ? 'edit' : 'done'}
              label={submitStatus === SubmitStatus.NOT_SUBMITTED ? submitButtonLabel : __('common.loading')}
              disabled={submit_disabled}
            />
          </div>
        </div>
      </>
    );
  }
);

const ExamSubmitted = withTranslation()(({ t: __, submitStatus, onClickCancel, status, goToDashboard }) => {
  const messages = {
    [SubmitStatus.LOADING]: {
      message:
        status === ExamStatus.REPORT_SIGNED
          ? __('examinationReview.dialog.reportSigning')
          : __('examinationReview.dialog.reportSubmitting'),
      icon: 'spin3',
    },
    [SubmitStatus.SUCCESS]: {
      message:
        status === ExamStatus.REPORT_SIGNED
          ? __('examinationReview.dialog.reportSigned')
          : __('examinationReview.dialog.reportSubmitted'),
      icon: 'done-circled',
    },
    [SubmitStatus.ERROR]: {
      message: __('examinationReview.dialog.somethingWentWrong'),
      icon: 'error',
    },
  };

  return (
    <div className="exam-report-dialog-done">
      <div className="dialog-done-content icon">
        <Icon name={messages[submitStatus].icon} />
      </div>
      <div className="dialog-done-content message"> {messages[submitStatus].message} </div>
      <div className="dialog-done-content dialog-done-content-button">
        <Button variant="outline" onClick={onClickCancel} icon="close" label={__('examinationReview.closeModal')} />
        {submitStatus === SubmitStatus.SUCCESS && <Button onClick={goToDashboard} label={__('navbar.dashboard')} />}
      </div>
    </div>
  );
});
