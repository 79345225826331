import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import { formatName } from '../../services/examination';
import { formatDateWithTZ, formatTime } from '../../utils';
import './DraftExamCard.css';
import useAuth from '../../context-providers/Auth';

/**
 * <DraftExamCard
 *  exam = {exam object}
 *  riskFactors = [risk factor list]
 *  teratogenicRisks = [teratogenic risk list]
 *  deleteDraftExam = () => {}
 * >
 */
const DraftExamCard = ({
  t: __,
  exam,
  riskFactors = [],
  teratogenicRisks = [],
  deleteDraftExam = null,
  dateFormat = 'dd/mm/yyyy',
  fullHeight = false,
  searchText = '',
}) => {
  const history = useHistory();
  const { isFeatureFlagEnabled } = useAuth();
  const [patientName, setPatientName] = useState('');
  const [syndromes, setSyndromes] = useState([]);

  const backToExamination = (event) => {
    event.stopPropagation();
    let url = `/exam/${exam.id}`;
    if (!!exam.malformations?.length || isFeatureFlagEnabled('soniopedia')) url += '/dx';
    history.push(url);
  };

  useEffect(() => {
    setPatientName(exam.patient?.name ? formatName(exam.patient?.name)?.fullName : __('patients.anonymous'));
  }, [exam.id]);

  useEffect(() => {
    if (searchText) {
      const r = new RegExp(searchText, 'i');
      if (exam.patient) {
        setPatientName(
          formatName(exam.patient?.name)?.fullName.replace(r, (searchMatch) => `<mark>${searchMatch}</mark>`)
        );
      }
      if (exam.recommendation?.syndromes) {
        setSyndromes(
          exam.recommendation.syndromes
            .filter((s) => r.test(s.name))
            .map((s) => s.name.replace(r, (searchMatch) => `<mark>${searchMatch}</mark>`))
        );
      }
    } else {
      setSyndromes([]);
      setPatientName(exam.patient?.name ? formatName(exam.patient?.name)?.fullName : __('patients.anonymous'));
    }
  }, [searchText]);

  return (
    <div
      className={`draft-exam-card-container ${fullHeight ? 'full-height' : ''}`}
      onClick={() =>
        history.push(isFeatureFlagEnabled('soniopedia') ? `/exam/${exam.id}/dx` : `/exam-anamnesis/${exam.id}`)
      }
    >
      <div className="name ph-no-capture" dangerouslySetInnerHTML={{ __html: patientName }}></div>
      <div className="date">
        <span className="trimester">
          {exam.trimester} {exam.type === 'diagnostic' ? __('patients.diagnostics') : ''}
        </span>
        <span className="day">
          <Icon name="calendar" /> {formatDateWithTZ(exam.updated_at, dateFormat)}
        </span>
        <span className="time">
          <Icon name="clock" /> {formatTime(exam.updated_at)}
        </span>
      </div>
      <div className="draft-exam-card-risk-factors">
        {(!!riskFactors?.length || !!teratogenicRisks?.length) && (
          <ul>
            {!!riskFactors?.length &&
              riskFactors?.map((risk, index) => (
                <li key={index}>
                  <label>{risk.label}</label>
                  <span>
                    {!!risk.raw_value && !risk.tmp_value && <span className="raw-value">{risk.raw_value}</span>}{' '}
                    {risk.value}
                  </span>
                </li>
              ))}
            {!!teratogenicRisks?.length && (
              <li key="teratogenic" className="teratogenic-risks">
                <label>{__('medicalhistory.teratogenicRisks')}</label>
                <span>
                  {teratogenicRisks?.map((risk, index) => (
                    <span key={index} className="teratogenic-risk">
                      {risk.label}
                    </span>
                  ))}
                </span>
              </li>
            )}
          </ul>
        )}
      </div>
      {syndromes.length > 0 && (
        <div className="draft-exam-card-syndromes">
          <ul>
            {syndromes.map((s) => (
              <li key={s} dangerouslySetInnerHTML={{ __html: s }}></li>
            ))}
          </ul>
        </div>
      )}
      <div className="cta">
        <Button label={__('patients.draftExams.resume')} variant="outline" icon="tab" onClick={backToExamination} />
        {!!deleteDraftExam && (
          <div
            className="button outline cta-delete"
            onClick={(event) => {
              event.stopPropagation();
              deleteDraftExam(exam);
            }}
          >
            <Icon name="trash" />
            <span>{__('patients.delete')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(DraftExamCard);
