import { useState, useEffect, isValidElement, cloneElement } from 'react';
import PlaceholderLoader from '../../PlaceholderLoader';

function doRecursivelyApplyFetus(children, fetus) {
  if (Array.isArray(children)) {
    return children.map((child) => {
      if (isValidElement(child)) {
        return recursivelyApplyFetus(child, fetus);
      }
      return child;
    });
  } else if (isValidElement(children)) {
    return recursivelyApplyFetus(children, fetus);
  } else {
    return children;
  }
}

function recursivelyApplyFetus(child, fetus) {
  return {
    ...child,
    props: {
      ...child.props,
      fetus,
      children: doRecursivelyApplyFetus(child.props.children, fetus),
    },
  };
}

function cloneAndApplyFetus(children, fetus) {
  const keyPrefix = `fetus-${fetus.examination_fetus_id}`;
  const order = fetus.value.value;

  if (Array.isArray(children)) {
    return children.map((child, i) => {
      if (isValidElement(child)) {
        return recursivelyApplyFetus(cloneElement(child, { key: `${keyPrefix}-${i}` }), order);
      }
      return child;
    });
  }
}

function orderFetuses(f1, f2) {
  return f1.value.value - f2.value.value;
}

function ReportTemplateFetusBody({ fetuses, children, reportMode }) {
  const [childrenWithFetuses, setChildrenWithFetuses] = useState({});
  const orderedFetuses = fetuses
    // Remove the mother entry in the fetuses array
    .filter(({ examination_fetus_id }) => examination_fetus_id !== null)
    .sort(orderFetuses);

  const initializeChildrenWithFetuses = (orderedFetuses, children) => {
    return orderedFetuses.reduce((acc, fetus) => {
      acc[fetus.examination_fetus_id] = cloneAndApplyFetus(children, fetus);
      return acc;
    }, {});
  };
  /* If the children tree changes we need to rebuild all the components */
  useEffect(() => {
    setChildrenWithFetuses(initializeChildrenWithFetuses(orderedFetuses, children));
  }, [children]);

  /* If a new fetuses is added to the examination we need to add it to the children tree */
  useEffect(() => {
    setChildrenWithFetuses((childrenWithFetuses) => {
      return orderedFetuses.reduce((acc, fetus) => {
        if (acc.hasOwnProperty(fetus.examination_fetus_id)) return acc;
        acc[fetus.examination_fetus_id] = cloneAndApplyFetus(children, fetus);
        return acc;
      }, childrenWithFetuses);
    });
  }, [orderedFetuses.map(({ examination_fetus_id }) => examination_fetus_id).join('|')]);

  /* useEffect are not working in reportMode print, so we need to initialize the children tree in the render */
  if (reportMode === 'print') {
    const initializedChildrenWithFetuses = initializeChildrenWithFetuses(orderedFetuses, children);
    return orderedFetuses.map(({ examination_fetus_id }) => initializedChildrenWithFetuses[examination_fetus_id]);
  }
  return orderedFetuses.map(({ examination_fetus_id }) => childrenWithFetuses[examination_fetus_id]);
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateFetus({ props, placeholders, ...otherProps }) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateFetusBody}
      placeholders={placeholders}
      requiredPlaceholders={['fetus.order']}
      fetuses={placeholders['fetus.order']?.data || []}
      props={props}
      {...otherProps}
    />
  );
}
