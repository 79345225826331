import React, { useEffect, useState } from 'react';
import './ReportSidebar.css';
import Icon from '../../atoms/Icon/Icon';

const Section = ({ title, icon, collapse = false, hide = false, children }) => {
  const [isOpen, setIsOpen] = useState(!collapse);

  useEffect(() => setIsOpen(!collapse), [collapse]);

  return (
    <div className={`report-sidebar-section ${hide ? 'hide' : ''}`}>
      <div className="report-sidebar-section-title" onClick={() => setIsOpen((isOpen) => !isOpen)}>
        {icon && <Icon name={icon} />}
        &nbsp;<h2 className="section-title">{title}</h2>
        <span className="arrow-icon">
          <Icon name={isOpen ? 'down' : 'up'} />
        </span>
      </div>
      {isOpen ? <div className="report-sidebar-section-content">{children}</div> : null}
    </div>
  );
};

export default Section;
