import ReportTemplateHeading from '../Heading';
import ReportTemplateBlock from '../Block';
import ReportTemplateSpacer from '../Spacer';

import {
  isMeasurementCurveEmpty,
  measurementCurveRequiredPlaceholders,
  measurementCurvePlaceholderId,
} from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';

import { isNullOrUndefined } from '../../../../utils';

function ReportTemplateGraphsBody(fullProps) {
  const { props, reportDataOptions, placeholders, children, apiVersion, reportMode = 'print' } = fullProps;

  const columnNumber = props.columns || 1;

  const groupedChildren = children
    .filter((child) => {
      // Only measurement curves accepted as children
      if (child?.props?.props?.type !== 'measurement-curve') return false;
      // Note here we use placeholders loaded by PlaceholderLoader for Graphs
      // as MeasurementCurve are not loaded yet as they are not yet rendered

      const fieldId = measurementCurvePlaceholderId(child.props.props);
      const curvePlaceholdersHelper = new PlaceholdersHelper({
        ...fullProps,
        props: child.props.props,
      });
      // Only non empty curves accepted
      if (isMeasurementCurveEmpty(child.props.props, placeholders, reportDataOptions, apiVersion)) {
        return false;
      }
      // Only visible curves accepted except in edit mode where we display all the curves
      if (reportMode === 'edit') return true;
      if (!curvePlaceholdersHelper.curveVisible(fieldId)) return false;
      return true;
    })
    .reduce((grouped, child, index) => {
      const groupedIndex = Math.floor(index / columnNumber);
      grouped[groupedIndex] = grouped[groupedIndex] || [];
      grouped[groupedIndex].push(child);
      return grouped;
    }, []);

  const isEmpty = groupedChildren.length === 0;

  return isEmpty ? (
    false
  ) : (
    <div className={`graphs-wrapper ${isEmpty ? 'is-empty' : ''}`}>
      <div className="avoid-break">
        {props.label && (
          <>
            <ReportTemplateHeading props={{}} {...{ apiVersion, reportMode }}>
              {props.label}
            </ReportTemplateHeading>
            <ReportTemplateSpacer size="s" />
          </>
        )}
        {!!groupedChildren[0].length && (
          <ReportTemplateGraphsLine key={`graph-line-0`} columns={columnNumber} apiVersion={apiVersion}>
            {groupedChildren[0]}
          </ReportTemplateGraphsLine>
        )}
      </div>
      {groupedChildren.slice(1).map((line, index) => {
        return (
          <ReportTemplateGraphsLine key={`graph-line-${index}`} columns={columnNumber} apiVersion={apiVersion}>
            {line}
          </ReportTemplateGraphsLine>
        );
      })}
    </div>
  );
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateGraphs({
  props,
  placeholders,
  apiVersion,
  reportDataOptions,
  children,
  ...otherProps
}) {
  const acceptedChildren = children.filter((child) => child?.props?.props?.type === 'measurement-curve');
  const refusedChildren = children.filter(
    (child) => !isNullOrUndefined(child?.props?.props?.type) && child?.props?.props?.type !== 'measurement-curve'
  );

  if (acceptedChildren.length === 0 && refusedChildren.length > 0) {
    console.error('graphs accepts only measurement-curve as children');
    return false;
  }
  if (refusedChildren.length > 0) {
    console.error('graphs only accepts measurement-curve as children');
  }

  const requiredPlaceholders = acceptedChildren
    .map((child) =>
      measurementCurveRequiredPlaceholders(child.props.props, placeholders, reportDataOptions, apiVersion)
    )
    .flat();

  return (
    <PlaceholderLoader
      apiVersion={apiVersion}
      Component={ReportTemplateGraphsBody}
      placeholders={placeholders}
      props={props}
      requiredPlaceholders={requiredPlaceholders}
      reportDataOptions={reportDataOptions}
      {...otherProps}
    >
      {acceptedChildren}
    </PlaceholderLoader>
  );
}

function ReportTemplateGraphsLine({ children, columns, apiVersion }) {
  return (
    <div className="graphs-line">
      <ReportTemplateBlock props={{ layout: 'columns' }} reportMode={'edit'} origin={'graph'} apiVersion={apiVersion}>
        {children}
        {Array.from('0'.repeat(columns - children.length)).map((_, index) => (
          <div key={`empty-${index}`} />
        ))}
      </ReportTemplateBlock>
    </div>
  );
}
