/* eslint-disable camelcase */
import { useContext, useState, useCallback, useRef } from 'react';
import { withTranslation } from 'react-i18next';

import { Snack, initialSnackState, snackEvent } from '../../atoms/Snack';
import { AppContext } from '../../context-providers/App';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';

import ResourceApi from '../../services/resource';

import LoaderInline from '../../atoms/LoaderInline/LoaderInline';
import { useXMLDocumentPrinter } from '../../components/XMLDocument';

import ExaminationReportView from './ExaminationReportView';

import { ReportDialogProvider } from '../../context-providers/ReportDialogProvider';

const buildMultipartForm = (html, json) => {
  // Build a form with the HTML content as blob
  const formData = new FormData();
  const htmlBlob = new Blob([html], { type: 'text/html' });
  formData.append('html', htmlBlob);
  if (json) {
    const jsonBlob = new Blob([JSON.stringify(json)], { type: 'text/html' });
    formData.append('json', jsonBlob);
  }

  return formData;
};

const ExaminationReport = ({ t: __, setOpenPopup = () => {}, frozenReport, closePanel }) => {
  const examinationContext = useContext(ExaminationContext);

  const appContext = useContext(AppContext);
  const { loaded, reportDataOptions } = useXMLTemplate();

  const XMLPrinter = useXMLDocumentPrinter({ page: 'report' });

  const { isFeatureFlagEnabled, siteFlowsConnectors } = useAuth();

  const [snack, setSnack] = useState(initialSnackState({}));

  const [uploadingReportAsPDF, setUploadingReportAsPDF] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const reportContentRef = useRef(null);

  /* contains null or an Object with information to perform the sign event
   * except the HTML.
   */
  const templateBlueprint = examinationContext.debugTemplate || reportDataOptions?.report_template?.blueprint;
  const examEvents = examinationContext.examination.events;

  const uploadReportAsPDF = async () => {
    if (uploadingReportAsPDF) return;
    const HTML = await renderReportHtml();

    ResourceApi.sharePdfFromHtmlChrome(buildMultipartForm(HTML))
      .then((_response) => {
        snackEvent('show', setSnack, {
          type: 'success',
          assigns: { state: 'uploaded' },
          hideAfter: 3000,
        });
      })
      .catch(() => {
        snackEvent('show', setSnack, {
          type: 'error',
          assigns: { state: 'error' },
          hideAfter: 5000,
        });
      })
      .finally(() => setUploadingReportAsPDF(false));
  };

  const renderReportHtml = useCallback(
    async () =>
      await XMLPrinter({
        base64Images,
        templateBlueprint,
      }),
    [base64Images, templateBlueprint, XMLPrinter]
  );

  const snackMessage = ({ state }) => {
    switch (state) {
      case 'uploaded':
        return 'Report uploaded successfully';
      case 'error':
        return 'Unable to upload your report. (Retry later)';
    }
  };

  const getError = () => {
    if (!examinationContext.examination?.patient_id) return __('report.error.anonymousExam');
    if (!examinationContext.examination?.episode) return __('report.error.missingEpisode');
  };

  if (!examEvents) return null;

  if (getError()) return <ExaminationReportError error={getError()} />;

  return loaded ? (
    <>
      <ReportDialogProvider>
        <Snack snack={snack} setSnack={setSnack}>
          {snackMessage(snack.assigns)}
        </Snack>
        <ExaminationReportView
          reportContentRef={reportContentRef}
          setOpenPopup={setOpenPopup}
          isFeatureFlagEnabled={isFeatureFlagEnabled}
          templateBlueprint={templateBlueprint}
          uploadReportAsPDF={siteFlowsConnectors?.upload_report?.length ? uploadReportAsPDF : null}
          uploadingReportAsPDF={uploadingReportAsPDF}
          examStatus={examinationContext.examination.status}
          examEvents={examEvents}
          appContext={appContext}
          renderHTML={renderReportHtml}
          associateContactPoint={(params) => examinationContext.associateContactPoint(params)}
          deassociateContactPoint={(associationCPId) => examinationContext.deassociateContactPoint(associationCPId)}
          base64Images={base64Images}
          setBase64Images={setBase64Images}
          frozenReport={frozenReport}
          closePanel={closePanel}
        />
      </ReportDialogProvider>
    </>
  ) : (
    <ExaminationReportLoading />
  );
};

export default withTranslation()(ExaminationReport);

const ExaminationReportLoading = () => {
  return (
    <div className="exam-report-inner-content exam-report-loading">
      <LoaderInline />
    </div>
  );
};

const ExaminationReportError = ({ error }) => {
  return <div className="exam-report-inner-content exam-report-error">{error}</div>;
};
