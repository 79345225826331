import { useContext, useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../../atoms/Button/Button';
import Switch from '../../../atoms/Switch/Switch';
import ProgressRing from '../../../atoms/ProgressRing/ProgressRing';
import FetusController from '../../../components-dx/FetusController';
import { DxContext } from '../../../context-providers/Dx';
import { ExaminationContext } from '../../../context-providers/Examination';
import { getRiskFactors, getTeratogenicRisks } from '../../../services/examination';
import './DxSidebarView.css';
import RiskFactorsSidebar from '../../../components/RiskFactorsSidebar/RiskFactorsSidebar';
import ButtonAdd from '../../../atoms/ButtonAdd/ButtonAdd';
import Icon from '../../../atoms/Icon/Icon';

const DxSidebarView = ({ t: __, medications, instances }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);
  const riskFactorsContainerRef = useRef(null);

  const [riskFactors, setRiskFactors] = useState([]);
  const [teratogenicRisks, setTeratogenicRisks] = useState([]);
  const [recommendedMedicalHistoryItemIds, setRecommendedMedicalHistoryItemIds] = useState([]);
  const [recommendedTeratogenIds, setRecommendedTeratogenIds] = useState([]);

  const [previousTab, setPreviousTab] = useState('fetus');
  const [newRiskFactorsAreAvailable, setNewRiskFactorsAreAvailable] = useState(false);
  const [riskFactorsCount, setRiskFactorsCount] = useState(0);

  const phenotypeMalformationsNo = useRef(null);
  const [showMalformationsNo, setShowMalformationsNo] = useState(false);

  useEffect(() => {
    if (!phenotypeMalformationsNo.current) return false;
    phenotypeMalformationsNo.current.style.height = showMalformationsNo
      ? phenotypeMalformationsNo.current.scrollHeight + 'px'
      : 0;
  }, [
    showMalformationsNo,
    JSON.stringify(examinationContext.examination?.malformations?.filter((m) => m.id && m.status === 'no')),
  ]);

  useEffect(() => {
    setShowMalformationsNo(false);
  }, [examinationContext.examination.id]);

  useEffect(() => {
    setRiskFactorsCount(
      riskFactors.length +
        teratogenicRisks.length +
        (examinationContext.examination?.malformations?.filter((m) => m.status !== 'no').length || 0)
    );
  }, [riskFactors, teratogenicRisks, JSON.stringify(examinationContext.examination?.malformations)]);

  useEffect(() => {
    const excludeRiskFactorIds = examinationContext.riskFactorIds
      .map((riskId) =>
        Object.values(examinationContext.medicalHistoryItems).find((item) =>
          item.options?.some((option) => option.risk_factor_id === riskId)
        )
      )
      .filter((item) => !!item)
      .reduce((ids, item) => [...ids, ...(item.options || []).map((i) => i.risk_factor_id).filter((i) => !!i)], []);
    const cleanRiskFactorIds =
      dxContext.recommendation?.risk_factors?.filter(
        (risk) =>
          !examinationContext.dismissedLiveQuestions.includes(risk.id) && !excludeRiskFactorIds.includes(risk.id)
      ) || [];
    const newRecommendedMedicalHistoryItemIds = cleanRiskFactorIds
      ? Object.values(examinationContext.medicalHistoryItems)
          .filter(
            (item) =>
              item.options?.filter((opt) =>
                opt.risk_factor_id
                  ? cleanRiskFactorIds.some((riskFactor) => riskFactor.id === opt.risk_factor_id)
                  : false
              ).length
          )
          .map((item) => item.id)
          .filter(
            (itemId) =>
              !Object.values(examinationContext.examination.medical_history || {}).some(
                (medicalHistoryItem) => medicalHistoryItem.id === itemId
              )
          )
      : [];
    setRecommendedMedicalHistoryItemIds(newRecommendedMedicalHistoryItemIds);

    const newTeratogenIds = cleanRiskFactorIds.filter(
      (riskId) =>
        !Object.values(examinationContext.medicalHistoryItems).some((item) =>
          item.options?.some((option) => option.risk_factor_id === riskId)
        )
    );
    setRecommendedTeratogenIds(newTeratogenIds);
  }, [
    JSON.stringify(dxContext.recommendation?.risk_factors),
    JSON.stringify(examinationContext.dismissedLiveQuestions),
    JSON.stringify(examinationContext.riskFactorIds),
    JSON.stringify(examinationContext.examination.medical_history),
  ]);

  useEffect(() => {
    if (examinationContext.examination.medical_history) {
      setRiskFactors(
        getRiskFactors(examinationContext.examination.medical_history, examinationContext.medicalHistoryItems)
      );
      setTeratogenicRisks(getTeratogenicRisks(examinationContext.examination.medical_history, medications));
    } else {
      setRiskFactors([]);
      setTeratogenicRisks([]);
    }
  }, [JSON.stringify(examinationContext.examination)]);

  /**
   * LIVE QUESTIONS
   * Once new recommended risk factors, show risk factors tab and scroll down to live question section
   */
  useEffect(() => {
    if (!newRiskFactorsAreAvailable) return false;
    if (![...recommendedMedicalHistoryItemIds, ...recommendedTeratogenIds].length) return false;

    if (dxContext.currentTab !== 'risks') {
      setPreviousTab(dxContext.currentTab);
      dxContext.setCurrentTab(dxContext.checklistSummaryOpen && previousTab ? previousTab : 'risks');
    }
  }, [recommendedMedicalHistoryItemIds, recommendedTeratogenIds, newRiskFactorsAreAvailable]);

  useEffect(() => {
    setNewRiskFactorsAreAvailable(!![...recommendedMedicalHistoryItemIds, ...recommendedTeratogenIds].length);
  }, [recommendedMedicalHistoryItemIds, recommendedTeratogenIds]);

  useEffect(() => {
    if (!newRiskFactorsAreAvailable) return false;
    setNewRiskFactorsAreAvailable(false);

    if (!riskFactorsContainerRef?.current) return false;
    const recommendedRiskFactorsRef = riskFactorsContainerRef.current.querySelector('.recommended');
    if (!recommendedRiskFactorsRef) return false;

    riskFactorsContainerRef.current.scrollTo({
      top: recommendedRiskFactorsRef.offsetTop,
      behavior: 'smooth',
    });
  }, [dxContext.currentTab]);
  /** / LIVE QUESTIONS */

  if (!Object.keys(examinationContext?.MALFORMATIONS).length) return false;

  return (
    <>
      <div className="dx-sidebar-container">
        <div className="examination-sidebar-switch">
          <Switch
            options={[
              { value: 'fetus', icon: 'fetus' },
              {
                value: 'risks',
                icon: 'bell',
                count: riskFactorsCount,
              },
            ]}
            selected={dxContext.currentTab}
            onChange={dxContext.setCurrentTab}
          />
        </div>

        {dxContext.currentTab === 'fetus' && (
          <div className="dx-sidebar-tab dx-sidebar-fetus-controller">
            <FetusController />
          </div>
        )}
        {dxContext.currentTab === 'risks' && (
          <div className="dx-sidebar-tab dx-sidebar-risks" ref={riskFactorsContainerRef}>
            <div className="dx-sidebar-tab-malformations">
              <h3>{__('dx.summary.phenotype')}</h3>
              {!examinationContext.examination?.malformations?.filter((m) => m.id && m.status !== 'no').length && (
                <div className="dx-sidebar-add-malformation">
                  <ButtonAdd
                    label={__('dx.summary.addAnomaly')}
                    onClick={() => {
                      setTimeout(() => {
                        const searchField = document.querySelector('.sign-search-bar input');
                        if (searchField) searchField.focus();
                      }, 500);
                      return dxContext.setChecklistSummaryOpen(false);
                    }}
                  />
                </div>
              )}
              {!!examinationContext.examination?.malformations?.length && (
                <>
                  {!!examinationContext.examination?.malformations?.filter((m) => m.id && m.status !== 'no').length && (
                    <ul>
                      {examinationContext.examination?.malformations
                        ?.filter((m) => m.id && m.status !== 'no')
                        .map((malformation) => (
                          <li key={malformation.id} className={`status-${malformation.status}`}>
                            <span
                              className="malformation-name"
                              onClick={() =>
                                dxContext.updateMalformation(
                                  malformation,
                                  malformation.status === 'yes' ? 'maybe' : 'yes',
                                  true
                                )
                              }
                            >
                              {examinationContext.MALFORMATIONS[malformation.id].canonical_name[currentLanguage]}
                            </span>
                            <span className="malformation-cta">
                              <Icon name="close" onClick={() => dxContext.removeMalformation(malformation.id)} />
                            </span>
                          </li>
                        ))}
                    </ul>
                  )}
                  {examinationContext.examination?.malformations?.some((m) => m.id && m.status === 'no') && (
                    <div className="dx-sidebar-tab-marlformations-no">
                      <div>
                        <Button
                          variant="link"
                          label={__('dx.summary.phenotype.showMalformationsNo').replace(
                            '{count}',
                            examinationContext.examination?.malformations?.filter((m) => m.id && m.status === 'no')
                              .length
                          )}
                          iconAfter={showMalformationsNo ? 'up' : 'down'}
                          onClick={() => setShowMalformationsNo(!showMalformationsNo)}
                        />
                      </div>
                      <ul className={`${showMalformationsNo ? 'open' : 'close'}`} ref={phenotypeMalformationsNo}>
                        {examinationContext.examination?.malformations
                          ?.filter((m) => m.id && m.status === 'no')
                          .map((malformation) => (
                            <li key={malformation.id} className={`status-${malformation.status}`}>
                              <span
                                className="malformation-name"
                                onClick={() => dxContext.updateMalformation(malformation, 'yes', true)}
                              >
                                {examinationContext.MALFORMATIONS[malformation.id].canonical_name[currentLanguage]}
                              </span>
                              <span className="malformation-cta">
                                <Icon name="close" onClick={() => dxContext.removeMalformation(malformation.id)} />
                              </span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="dx-sidebar-tab-medicalhistory">
              <h3>{__('medicalhistory.initialInterview')}</h3>
              <RiskFactorsSidebar
                examinationId={examinationContext.examination?.id}
                medicalHistory={examinationContext.examination.medical_history}
                medications={medications}
                recommendedMedicalHistoryItemIds={recommendedMedicalHistoryItemIds}
                recommendedTeratogenIds={recommendedTeratogenIds}
              />
            </div>
          </div>
        )}

        <div className="dx-checklist-summary-btn">
          <div
            className={`go-to-summary ${dxContext.checklistSummaryOpen ? 'open' : 'closed'}`}
            onClick={() => {
              if (!dxContext.checklistSummaryOpen) setPreviousTab(dxContext.currentTab);
              dxContext.setCurrentTab(dxContext.checklistSummaryOpen ? previousTab : 'risks');
              dxContext.setChecklistSummaryOpen((prev) => !prev);
            }}
          >
            <div className="dx-sidebar-progress-meter">
              <div className="progress-bar">
                {Object.values(dxContext.zones)
                  .slice(0, 5)
                  .map((zone) => (
                    <div
                      key={zone.id}
                      className={`section ${zone.unusual ? 'unusual' : ''} ${zone.toCheck ? 'to-check' : ''} ${
                        zone.validated ? 'validated' : ''
                      } ${zone.id === dxContext.currentZoneId ? 'current' : ''}`}
                    />
                  ))}
              </div>
            </div>
            <div className="dx-sidebar-reliability-meter">
              <ProgressRing
                progress={dxContext.recommendation?.diagnostic_reliability?.continuous * 100 || 0}
                label={<Icon name={dxContext.checklistSummaryOpen ? 'left' : 'right'} />}
                stroke="0.4"
              />
            </div>
            <Button
              fontSize="13"
              label={__('dx.diseases.summary')}
              variant={!dxContext.checklistSummaryOpen ? 'outline' : ''}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DxSidebarView);
