import ResourceApi from '../../services/resource';

export const trackEvent = (type, examinationContext, event_data) => {
  const info = {
    ...event_data,
    event_type: type,
    examination_id: examinationContext.examination.id,
    examination_status: examinationContext.examination.status,
    examination_preset_id: examinationContext.examination.preset_id,
    source: 'core',
    page_url: window.location.href,
  };
  // console.log(info)
  ResourceApi.createBIEvent(info);
};
