import sanitize from 'sanitize-html';

const defaultConvertElement = (element) => {
  if (element.nodeType === Node.TEXT_NODE) return element.textContent;

  switch (element.tagName) {
    case 'BR':
      return '\n';
    case 'P': // fall through to DIV
    case 'DIV':
      return (element.previousSibling ? '\n' : '') + Array.from(element.childNodes).map(defaultConvertElement).join('');
    default:
      return element.textContent;
  }
};
export const computeHtmlFromValue = (value) => sanitize(value);
export const computeValueFromHtml = (html, convertElement = defaultConvertElement) => {
  const tree = document.createElement('div');
  tree.innerHTML = html;
  return convertElement(tree);
};
