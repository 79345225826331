import { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Button from '../../atoms/Button/Button';
import PreselectSlideGrid from './PreselectSlideGrid';
import axios from 'axios';
import ResourceApi from '../../services/resource';
import { isList, isEmptyList } from '../../services/user-preselect-config';

const idsToSlides = (ids, allSlides) => {
  const slidesIndex = new Map(allSlides.map((slide) => [slide.id, slide]));
  return ids.map((id) => slidesIndex.get(id));
};

function ProtocolSetting({ setting, allowGuideline, templateKey, allSlides, onSave, trimester, userConfig, t: __ }) {
  // TODO: get rid of userConfig
  const [slides, setSlides] = useState([]);
  const [protocolSlides, setProtocolSlides] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [availableOptions, setAvailableOptions] = useState([]);

  const showPresetEditButton = setting.option === 'template';

  useEffect(() => {
    const fetchExaminationTemplate = async () => {
      if (!setting.examination_preset_id) return;

      const exam_template = userConfig?.examination_template;
      if (!exam_template) return;

      const template = exam_template.find((x) => x.examination_preset_id === setting.examination_preset_id);
      if (!template) return;

      const examination_template_id = template?.examination_template_id;
      if (!examination_template_id) return;

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      try {
        const examinationTemplateResponse = await ResourceApi.getExaminationTemplate(
          examination_template_id,
          source.token
        );
        const {
          data: { data: examinationTemplate },
        } = examinationTemplateResponse;
        const protocolViewIds = examinationTemplate.configuration.instance_views.map((v) => v.instance_view_id);

        const slides = allSlides.filter((slide) => protocolViewIds.includes(slide.id));
        setProtocolSlides(slides);
      } catch (error) {
        console.error('Failed to fetch examination template', error);
      }
    };

    fetchExaminationTemplate();
  }, [allSlides, setting, userConfig]);

  useEffect(() => {
    if (protocolSlides && isList(protocolSlides) && !isEmptyList(protocolSlides)) {
      setAvailableOptions(
        [
          {
            label: __('sharingPreferences.preselect.option.personalized'),
            value: 'template',
          },
          allowGuideline && {
            label: __('sharingPreferences.preselect.option.guideline'),
            value: 'guideline',
          },
          {
            label: __('sharingPreferences.preselect.option.all'),
            value: 'all',
          },
          {
            label: __('sharingPreferences.preselect.option.none'),
            value: 'none',
          },
        ].filter((x) => x)
      );
    } else {
      setAvailableOptions([
        { label: __('sharingPreferences.preselect.option.all'), value: 'all' },
        {
          label: __('sharingPreferences.preselect.option.none'),
          value: 'none',
        },
      ]);
    }
  }, [protocolSlides, allowGuideline, __]);

  const handleOptionChange = useCallback(
    (value) => {
      if (value === 'template') {
        setShowEdit(true);
      } else {
        onSave(setting, value);
      }
    },
    [onSave, setting]
  );

  const handlePresetEdit = useCallback(() => {
    let slidesToShow = isEmptyList(protocolSlides) ? allSlides : protocolSlides;
    const blueprint = setting && setting[templateKey] && setting[templateKey].blueprint;

    if (blueprint && !isEmptyList(blueprint)) {
      slidesToShow = idsToSlides(
        blueprint.map((item) => item.instance_view_id),
        allSlides
      );
    }

    setSlides(slidesToShow);
    setShowEdit(true);
  }, [protocolSlides, setting, allSlides, templateKey]);

  const handlePresetUpdate = useCallback(
    (value) => {
      onSave(setting, value);
      setShowEdit(false);
    },
    [onSave, setting]
  );

  const handleCancel = useCallback(() => {
    setShowEdit(false);
  }, []);

  return (
    <>
      <SelectInput
        value={!allowGuideline && setting.option === 'guideline' ? 'none' : setting.option}
        onChange={handleOptionChange}
        options={availableOptions}
      />
      {showPresetEditButton && (
        <>
          <div className="personalised-edit-button">
            <Button
              icon="edit"
              size="small"
              onClick={() => {
                handlePresetEdit();
              }}
            />
          </div>
        </>
      )}
      {showEdit && (
        <PreselectSlideGrid
          slides={slides}
          protocolSlides={isEmptyList(protocolSlides) ? allSlides : protocolSlides}
          trimester={trimester}
          onCancel={handleCancel}
          onUpdate={handlePresetUpdate}
        />
      )}
    </>
  );
}
export default withTranslation()(ProtocolSetting);
