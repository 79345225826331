import { formatYYYYMMDDDate } from '../../../../utils';

export default function ReportTemplateValue({
  props,
  placeholder: originalPlaceholder,
  getPlaceholderWithProps,
  multiSelectValue = false,
  fetus = 1,
  appPreferences,
}) {
  const preferedDateFormat = appPreferences?.date_format;

  let placeholder;
  if (props.data?.startsWith('measurement.')) {
    fetus = (getPlaceholderWithProps({ data: 'fetus.number' })?.value || [])[Number(props.fetus ?? fetus ?? 1)] ?? -1;
  } else {
    fetus = Number(props.fetus ?? fetus ?? 1);
  }
  if (Array.isArray(originalPlaceholder) || props.data?.startsWith('measurement.')) {
    placeholder = originalPlaceholder[fetus];
  } else if (Array.isArray(originalPlaceholder.value)) {
    placeholder = {
      ...originalPlaceholder,
      value: originalPlaceholder.value[fetus],
    };
  } else {
    placeholder = originalPlaceholder;
  }

  const value = placeholder?.value;
  const { decimals } = props ?? {};

  const convertDates = (label) =>
    isNaN(label) && `${label}`?.match(/^\d{4}.\d{2}.\d{2}$/) ? formatYYYYMMDDDate(label, preferedDateFormat) : label;

  const getDisplayedValue = () => {
    let dispValue = value;

    if (multiSelectValue) {
      dispValue = placeholder?.value?.[multiSelectValue];
    }
    if (props.attribute) {
      return multiSelectValue ? dispValue?.[props.attribute] : placeholder?.[props.attribute];
    }

    return dispValue;
  };

  let output = getDisplayedValue();
  if (Array.isArray(output)) output = output[fetus];

  if (decimals && !isNaN(output)) output = Number(output).toFixed(decimals);

  output = convertDates(output);

  /* The <value> component should always return a string with no JSX. If you need JSX, please use <content> instead */
  return output || null;
}
