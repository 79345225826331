import { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ResourceApi from '../../services/resource';
import useAuth from '../../context-providers/Auth';
import { AppContext } from '../../context-providers/App';
import { WindowContext } from '../../context-providers/Window';
import { ExaminationContext } from '../../context-providers/Examination';
import { convertTimeZone, formatDate, formatYYYYMMDDDate } from '../../utils';
import { getNiceGestionalAgeFromDays } from '../../services/examination';
import PatientName from '../../components/PatientName/PatientName';
import InstantQrCode from '../../pages/ExaminationReview/InstantQrCode';
import SharingIndicator from '../../pages/ExaminationLive/SharingIndicator/SharingIndicator';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import './ExamTopBar.css';

const ExamTopBarPatient = withTranslation()(({ t: __, patient, episode, editable }) => {
  return (
    <div className="exam-topbar_patient">
      <label>{__('examTopBar.patient')}</label>
      <PatientName patient={patient} episode={episode} editable={editable} />
    </div>
  );
});

const ExamTopBarEpisode = withTranslation()(
  ({ t: __, examination, site, preferences, episodes, isDetached, trackEvent }) => {
    const windowContext = useContext(WindowContext);
    const { isFeatureFlagEnabled } = useAuth();
    const history = useHistory();

    const detachExam = (examination) => {
      windowContext.detach('exam', [examination.id]);
    };

    return (
      <div className="exam-topbar_episode">
        <label>{__('examTopBar.examDate')}</label>
        {!isDetached && !!episodes.length ? (
          <SelectInput
            variant="transparent"
            value={false}
            placeholder={{
              label: (
                <span className="exam-topbar_episode_exam placeholder-label">
                  <span className="exam-topbar_episode_exam_date">
                    {formatYYYYMMDDDate(
                      convertTimeZone(examination.examination_date, site?.timezone),
                      preferences.date_format
                    )}
                  </span>
                  {!!examination.dating?.value && (
                    <span className="exam-topbar_episode_exam_ga">
                      {getNiceGestionalAgeFromDays(__, examination.dating?.value)}
                    </span>
                  )}
                </span>
              ),
              value: false,
            }}
            options={episodes
              ?.sort((a, b) => (a.inserted_at < b.inserted_at ? 1 : -1))
              .map((episode) => [
                {
                  label:
                    episode.name ||
                    __('episode.defaultName', {
                      inserted_at: formatDate(episode.inserted_at, preferences.date_format),
                    }),
                  type: 'heading',
                },
                ...(episode?.examinations
                  ?.sort((a, b) => (a.examination_date < b.examination_date ? 1 : -1))
                  .map((exam) => ({
                    label: (
                      <span className="exam-topbar_episode_exam" key={exam.id}>
                        <span className="exam-topbar_episode_exam_date">
                          {formatYYYYMMDDDate(
                            convertTimeZone(exam.examination_date, site?.timezone),
                            preferences.date_format
                          )}
                        </span>
                        <span className="exam-topbar_episode_exam_ga">
                          {!!exam.dating?.value && getNiceGestionalAgeFromDays(__, exam.dating?.value)}
                        </span>
                        <span className="exam-topbar_episode_exam_preset">{exam.preset?.name}</span>
                        {isFeatureFlagEnabled('sonio.multiscreen') &&
                          !windowContext.isDetached &&
                          exam.id !== examination.id && (
                            <span
                              className="exam-topbar_episode_exam_cta"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                detachExam(exam);
                                document.body.click();
                                trackEvent('user_clicks_on_compare_exam', {});
                              }}
                            >
                              <Icon name="detach" />
                            </span>
                          )}
                      </span>
                    ),
                    value: exam.id,
                    selected: exam.id === examination?.id,
                  })) || []),
              ])
              .flat()}
            onChange={(examId) => {
              trackEvent('user_clicks_on_change_exam', {
                scope: 'same_patient',
              });
              history.push(`/exam/${examId}`);
            }}
          />
        ) : (
          <div className="exam-topbar_anonymous-date">
            <span className="exam-topbar_episode_exam placeholder-label placeholder-label_not-editable">
              <span className="exam-topbar_episode_exam_date">
                {formatYYYYMMDDDate(
                  convertTimeZone(examination.examination_date, site?.timezone),
                  preferences.date_format
                )}
              </span>
              <span className="exam-topbar_episode_exam_ga">
                {!!examination.dating?.value && getNiceGestionalAgeFromDays(__, examination.dating?.value)}
              </span>
            </span>
          </div>
        )}
      </div>
    );
  }
);

const ExamTopBar = ({ t: __, openPanel = '', setOpenPanel = () => {} }) => {
  const windowContext = useContext(WindowContext);
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const history = useHistory();
  const { isFeatureFlagEnabled } = useAuth();

  const showSharingFlowTopBar = isFeatureFlagEnabled('sonio.share');

  const trackEvent = (event_type, event_data) => {
    ResourceApi.createBIEvent({
      ...event_data,
      event_type: event_type,
      examination_id: examinationContext.examination.id,
      examination_status: examinationContext.examination.status,
      examination_preset_id: examinationContext.examination.preset_id,
      detect: isFeatureFlagEnabled('sonio.detect'),
      user_agent: navigator.userAgent,
    });
  };

  const backToExamination = (url = false) => {
    // save modifications before leaving (avoid waiting examinationContext timeout)
    examinationContext.updateExamination({ ...examinationContext.examination });
    examinationContext.updateEpisode({ ...examinationContext.episode });

    if (url === false) url = `/exam/${examinationContext.examination.id}`;

    if (
      url.match(new RegExp(`^/exam/${Number(examinationContext.examination.id)}$`)) &&
      isFeatureFlagEnabled('soniopedia')
    )
      url += '/dx';

    history.push(url);
  };

  const backToDashboard = () => {
    history.push('/');
  };

  return (
    <div className="exam-topbar">
      <ExamTopBarPatient
        patient={examinationContext.patient}
        episode={examinationContext.episode}
        editable={!windowContext.isDetached}
      />
      <ExamTopBarEpisode
        examination={examinationContext.examination}
        site={examinationContext.examination?.site}
        preferences={appContext.preferences}
        episodes={examinationContext.episodes}
        isDetached={windowContext.isDetached}
        trackEvent={trackEvent}
      />
      <div className="exam-topbar_spacer" />
      <div className="exam-topbar_cta">
        <div className="examination-sharing-flow-top-bar">
          {!examinationContext.canEdit && (
            <div className="exam-sharing-flow_status">
              <Icon name="lock_closed" /> {__('examinationReview.examIsLocked')}
              {examinationContext.canUnfreeze && (
                <>
                  {__(':')}
                  <Button
                    variant="link"
                    size="small"
                    label={__('examinationReview.editExam')}
                    onClick={() => examinationContext.reopenExamination()}
                  />
                </>
              )}
            </div>
          )}

          {openPanel === 'medical-history' ? (
            <>
              {/*
              <div>
                <Button
                  label={__("examinationSharingFlow.actions.medicalHistory")}
                  icon="paste"
                  size="input"
                  variant={
                    openPanel === "medical-history" ? "contained-shadow" : ""
                  }
                  color={
                    openPanel !== false && openPanel !== "medical-history"
                      ? "grey"
                      : ""
                  }
                  onClick={() => {
                    setOpenPanel((openPanel) =>
                      openPanel === "medical-history"
                        ? false
                        : "medical-history"
                    );
                    history.push(
                      `/exam-anamnesis/${examinationContext.examination.id}`
                    );
                    trackEvent("user_clicks_on_the_mh_button", {});
                  }}
                />
              </div>
              */}
              <div>
                {windowContext.isDetached ? (
                  false
                ) : appContext.referer.match(new RegExp(`^/exam/${Number(examinationContext.examination.id)}/dx$`)) ? (
                  <Button
                    size="input"
                    label={__('examination.backToExamination')}
                    iconAfter="right-big"
                    onClick={() => backToExamination(appContext.referer)}
                  />
                ) : appContext.referer.match(new RegExp(`^/exam/${Number(examinationContext.examination.id)}$`)) ? (
                  <Button
                    size="input"
                    label={__('examination.backToExamination')}
                    iconAfter="right-big"
                    onClick={() => backToExamination(appContext.referer)}
                  />
                ) : examinationContext.examination.status === 'draft' ? (
                  <>
                    <Button
                      size="input"
                      label={__('examination.backToTheDashboard')}
                      icon="home"
                      onClick={() => backToDashboard()}
                    />
                    <Button
                      size="input"
                      label={__('examination.startTheExamination')}
                      variant="outline"
                      iconAfter="right-big"
                      onClick={() => backToExamination()}
                    />
                  </>
                ) : (
                  <Button
                    size="input"
                    variant="outline"
                    label={
                      examinationContext.examination.status !== 'draft'
                        ? __('examination.backToExamination')
                        : __('examination.startTheExamination')
                    }
                    iconAfter="right-big"
                    onClick={() => backToExamination()}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              {isFeatureFlagEnabled('sonio.report') && (
                <div>
                  <Button
                    label={__('examinationSharingFlow.actions.report')}
                    icon="report"
                    size="input"
                    variant={openPanel === 'stats' ? 'contained-shadow' : ''}
                    color={showSharingFlowTopBar && openPanel !== false && openPanel !== 'stats' ? 'grey' : ''}
                    onClick={() => {
                      setOpenPanel((openPanel) => (openPanel === 'stats' ? false : 'stats'));
                      trackEvent('user_clicks_on_the_report_button', {});
                    }}
                  />
                </div>
              )}
              {showSharingFlowTopBar && (
                <>
                  <div>
                    <Button
                      label={__('examinationReview.print')}
                      icon="print"
                      size="input"
                      variant={openPanel === 'print' ? 'contained-shadow' : ''}
                      color={openPanel !== false && openPanel !== 'print' ? 'grey' : ''}
                      onClick={() => {
                        setOpenPanel((openPanel) => (openPanel === 'print' ? false : 'print'));
                        trackEvent('user_opens_the_printing_tab', {});
                      }}
                    />
                  </div>
                  <div>
                    <Button
                      label={__('examinationReview.share')}
                      icon="sharing-indicator"
                      size="input"
                      variant={openPanel === 'share' ? 'contained-shadow' : ''}
                      color={openPanel !== false && openPanel !== 'share' ? 'grey' : ''}
                      onClick={() => {
                        setOpenPanel((openPanel) => (openPanel === 'share' ? false : 'share'));
                        trackEvent('user_opens_the_sharing_tab', {});
                      }}
                    />
                  </div>
                  <InstantQrCode />
                  {examinationContext.examination &&
                    (!isFeatureFlagEnabled('sonio.detect') || isFeatureFlagEnabled('sonio.routine')) && (
                      <>{isFeatureFlagEnabled('sonio.share') && <SharingIndicator />}</>
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ExamTopBar);
