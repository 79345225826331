import { getSlideUriFromTrimesterAndId } from '../../../utils';
import './SlideDrawing.css';

/**
 * <SlideDrawing
 *  version
 *  trimester
 *  slide
 *  sex
 *  rotation = {rotation: 180, flip: true}
 *  preventAnimations
 * />
 */

const SlideDrawing = ({ version = 1, trimester, slide, sex = '', rotation, preventAnimations = false }) => {
  if (!rotation) rotation = { rotation: 0, flip: false };
  const className = `slide-container slide-${slide.id} rotate-${rotation.rotation} ${
    rotation.flip ? 'rotate-flip' : ''
  } ${preventAnimations ? 'prevent-animations' : ''}`;

  return (
    <div className={className}>
      <div className="slide-container-slide">
        <img src={getSlideUriFromTrimesterAndId(trimester, slide.id, sex, version)} alt="" />
      </div>
    </div>
  );
};

export default SlideDrawing;
