import { isValidElement, cloneElement } from 'react';

export default function ReportTemplateFetus({ defaultKey = '', getPlaceholderWithProps, children }) {
  const numberOfFetuses = getPlaceholderWithProps({ data: 'patient.nb_fetuses' })?.value || 1;

  const childrenWithFetusNumber = [...Array(numberOfFetuses).keys()]
    .map((_, fetus) => {
      return (Array.isArray(children) ? children : [children]).map((child, i) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            key: `${defaultKey}_${i}_fetus_${fetus}`,
            fetus: fetus + 1,
            defaultKey: `${defaultKey}_${i}_fetus_${fetus}`,
          });
        }
        return child;
      });
    })
    .flat();

  return childrenWithFetusNumber;
}
