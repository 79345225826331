import React, { useContext } from 'react';
import { WindowContext } from '../../context-providers/Window';
import Icon from '../../atoms/Icon/Icon';

export default function Workspace({ openChapter, setOpenChapter }) {
  const windowContext = useContext(WindowContext);

  const windows = windowContext.windows.map((w) => ({ ...w, window: w.window?.closed }));

  const printValue = (value, param) => {
    if (param === 'window') {
      return value && !value.closed ? 'is open' : 'is closed';
    }
    if (param === 'active') {
      return value ? 'open by default' : 'closed by default';
    }
    if (value === null) return 'null';
    if (value === undefined) return 'undefined';
    if (value === false) return 'false';
    if (value === true) return 'true';
    if (value === '') return <em>{'<empty>'}</em>;
    return value;
  };

  const readableWindows = (windows) => {
    return windows.map((window, index) => (
      <div key={index}>
        <div className="debug-subsection_title">
          <b>{window.view}</b>
        </div>
        <div className="open">
          <div className="value">
            <table>
              <tbody>
                {Object.entries(window).map(([param, value]) => (
                  <tr>
                    <th>{param}</th>
                    <td>{printValue(value, param)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ));
  };

  const WorkspaceContent = () => {
    return (
      <div className="debug-subsection">
        <div>
          <label>This session setup</label>
        </div>
        <div className="debug-subsection">{readableWindows(windows)}</div>
        <div>
          <label>Default configuration</label>
        </div>
        <div className="debug-subsection">{readableWindows(windowContext.savedConfig || [])}</div>
      </div>
    );
  };

  return (
    <div className={`debug-section ${openChapter === 'workspace' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'workspace' ? false : 'workspace'))}>
        Workspace <Icon name="down" />
      </h2>
      {openChapter === 'workspace' && <WorkspaceContent />}
    </div>
  );
}
