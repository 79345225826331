import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { withTranslation } from 'react-i18next';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import Popup from '../../components/Popup/Popup';
import { ExaminationContext } from '../../context-providers/Examination';
import LookupApi from '../../services/lookup';
import { isNullOrUndefined } from '../../utils';
import DxChecklistView from './DxChecklistView';

const DxChecklist = ({ t: __ }) => {
  const { examId } = useParams();

  const examinationContext = useContext(ExaminationContext);
  const [medications, setMedications] = useState([]);
  const [isSelectTrimesterVisible, setIsSelectTrimesterVisible] = useState(false);

  useEffect(() => {
    setIsSelectTrimesterVisible(
      isNullOrUndefined(examinationContext.examination?.trimester) || examinationContext.examination.trimester === 'ND'
    );
  }, [examinationContext.examination?.trimester]);

  useEffect(() => {
    if (
      !examinationContext.examination ||
      !examinationContext.examination.medical_history ||
      !examinationContext.examination.medical_history['teratogenicrisks.medications']
    )
      return false;

    const value = examinationContext.examination.medical_history['teratogenicrisks.medications'].value;
    const missingMedications = value.reduce(
      (ids, id) => (!medications.some((m) => m.id === id) ? [...ids, id] : ids),
      []
    );
    if (!missingMedications.length) return false;
    LookupApi.getMedicationsByIds(missingMedications).then((results) =>
      setMedications([...medications, ...results.data])
    );
    return true;
  }, [JSON.stringify(examinationContext.examination.medical_history)]);

  useEffect(() => {
    if (examId != examinationContext.examination?.id) {
      examinationContext.loadExamination(examId);
      setIsSelectTrimesterVisible(false);
    }
  }, [examId, examinationContext.examination?.id]);

  return (
    <>
      <DxChecklistView medications={medications} instances={examinationContext.instances} />
      {isSelectTrimesterVisible && (
        <Popup
          message={__('examination.selectTrimester')}
          cta={
            <ButtonGroup
              options={[
                { value: 'T1', label: 'T1' },
                { value: 'T2', label: 'T2' },
                { value: 'T3', label: 'T3' },
              ]}
              onChange={(value) => {
                examinationContext.setTrimester(value);
              }}
            />
          }
        />
      )}
    </>
  );
};

export default withTranslation()(DxChecklist);
