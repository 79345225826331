import React from 'react';
import './Loader.css';

/**
 * <Loader
 *  isLoading={boolean}
 * />
 */
const Loader = ({ isLoading }) => {
  return <div className={`loader-container ${isLoading ? 'is-loading' : ''}`}></div>;
};

export default Loader;
