import Icon from '../../../atoms/Icon/Icon';
import { stakeholderRoles } from '../../../config';
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../atoms/Button/Button';
import { withTranslation } from 'react-i18next';
import { ExaminationContext } from '../../../context-providers/Examination';
import ResourceApi from '../../../services/resource';
import ContactPointCard from '../../../components/ContactPointCard/ContactPointCard';

export default withTranslation()(Stakeholders);

function Stakeholders({ t: __, setOpenPopup }) {
  const examinationContext = useContext(ExaminationContext);
  const [possibleEntities, setPossibleEntities] = useState([]);

  const { examination, canEdit } = examinationContext;
  const { reader_id, practitioner_id } = examination;

  useEffect(() => {
    ResourceApi.listPossibleEntityToExamination(examination.id).then((r) => {
      setPossibleEntities(r.data.data);
    });
  }, []);

  const mainPerformingClinician = {
    id: practitioner_id,
    entity_id: practitioner_id,
    contact_point: {
      id: practitioner_id,
      name: possibleEntities.find((e) => e.id === practitioner_id)?.title,
    },
    role: 'main_performing_clinician',
  };

  const mainReadingProvider = {
    id: reader_id,
    entity_id: reader_id,
    contact_point: {
      id: reader_id,
      name: possibleEntities.find((e) => e.id === reader_id)?.title,
    },
    role: 'main_reading_provider',
  };

  const otherEntities = (examination.entities || []).map((entity) => ({
    ...entity,
    contact_point: { id: entity?.entity_id, name: entity?.entity?.title },
  }));

  const associatedContactPoints = examination.associated_contact_points || [];

  const entities = [mainPerformingClinician, mainReadingProvider, ...otherEntities, ...associatedContactPoints].filter(
    (entity) => entity.entity_id
  );

  return (
    <div className="examination-live-phenotype-panel-stakeholders" title="stakeholders-panel">
      <div>
        <h2 className="section-title">
          <Icon name="user" /> {__('examinationStakeholder.role.internal')}
        </h2>
        <div className="exam-live-phenotype-stakeholder-section">
          <div className="exam-live-phenotype-stakeholder-section-list">
            {stakeholderRoles
              .filter((r) => !r.external)
              .map((r) => (
                <React.Fragment key={r.id}>
                  {entities
                    .filter((e) => e.role === r.id)
                    .map((entity) => (
                      <StakeholderLine key={r.id + '_' + entity.id} entity={entity} __={__} />
                    ))}
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>

      {associatedContactPoints && (
        <div>
          <h2 className="section-title">
            <Icon name="hospital" /> {__('examinationStakeholder.role.external')}
          </h2>
          <div className="exam-live-phenotype-stakeholder-section">
            <div className="exam-live-phenotype-stakeholder-section-list">
              {associatedContactPoints.map((stakeholder) => (
                <ContactPointLine key={stakeholder.id} stakeholder={stakeholder} __={__} />
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="examination-live-phenotype-panel-cta">
        {canEdit && (
          <Button
            variant="outline"
            onClick={() => setOpenPopup({ view: 'submit', submitAllowed: false })}
            icon="user"
            label={__('examinationReview.dialog.editStakeholders')}
          />
        )}
      </div>
    </div>
  );
}

const StakeholderLine = ({ entity, __ }) => {
  return (
    <div className="exam-report-dialog-stakehoder">
      <ContactPointCard
        contact={{
          name: entity.contact_point?.name,
          role: __(`examinationStakeholder.role.${entity.role}`),
        }}
      />
    </div>
  );
};

const ContactPointLine = ({ stakeholder, __ }) => {
  if (!stakeholder.contact_point) return false;

  return (
    <div className="exam-report-dialog-stakehoder">
      <ContactPointCard
        contact={{
          ...stakeholder,
          ...stakeholder.contact_point,
          role: __(`examinationStakeholder.role.external.${stakeholder.role}`),
          association_id: stakeholder.id,
        }}
        icon="hospital"
      />
    </div>
  );
};
