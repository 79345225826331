import { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { getNiceGestionalAgeFromDays, computeEstimatedDeliveryDate } from '../../../../../services/examination';
import { formatYYYYMMDDDate } from '../../../../../utils';

/* Atoms */
import InlineInput from '../../../../../atoms/InlineEditing/InlineInput/InlineInput';
import Icon from '../../../../../atoms/Icon/Icon';

import { prevUsOptionsLabels, conceptionDateOptions } from '../gaConstants';

const NONE = 'none';

const AssignedGaItem = withTranslation()(
  ({
    t: __,
    data,
    gaData,
    selectedAt,
    gaItemProps,
    prevUsMeasurement,
    conceptionMethod,
    reportMode,
    timezone,
    reportDataOptions,
    pregnancyLengthInDays,
  }) => {
    const {
      currentExamDate,
      updateRow,
      showFetusDropDown,
      splitSlug,
      appPreferences,
      print,
      currentLanguage,
      revertAssignedGa,
      fetusDropdownOptions,
      updatingAssignedGa,
      datingMethod,
    } = gaItemProps;

    const preferredDateFormat = appPreferences?.date_format;

    const conceptionDateOptionsTranslated = useMemo(
      () => conceptionDateOptions.map((o) => ({ ...o, label: __(o.label) })),
      [conceptionDateOptions]
    );
    const prevUsOptionsLabelsTranslated = useMemo(
      () => Object.entries(prevUsOptionsLabels).map((o) => [o[0], __(o[1])]),
      [prevUsOptionsLabels]
    );

    if (!data || !gaData) return false;
    const { slug, value, fetus } = gaData;

    const { method, standard } = splitSlug(slug);

    const getDefaultLabel = () => {
      if (method === NONE) return '';
      let defaultLabel = datingMethod(slug)?.label?.[currentLanguage] || slug;
      if (standard !== NONE) {
        const standardLabel = reportDataOptions?.dating_standards?.[standard]?.[currentLanguage] || false;
        defaultLabel += standardLabel ? ` (${standardLabel})` : '';
      }
      if (method === 'prev_ultrasound') {
        const prevUsOptionsLabel = prevUsOptionsLabelsTranslated?.[prevUsMeasurement];
        defaultLabel += prevUsOptionsLabel ? ` (${prevUsOptionsLabel})` : '';
      }
      if (method === 'conception_date') {
        const conceptionMethodLabel = conceptionDateOptionsTranslated.find((m) => m.value === conceptionMethod)?.label;
        defaultLabel += conceptionMethodLabel ? ` (${conceptionMethodLabel})` : '';
      }
      if (method === 'embryo_transfer') {
        defaultLabel = __('report.gatable.ivfEt');
      }
      return defaultLabel;
    };

    const { rowId } = data;
    const label = data.label || __('report.gatable.assignedDating');

    const prevExamsExist = Object.keys(reportDataOptions?.previous_exams || {}).length !== 0;

    const onChange = (valueToUpdate) => (value) => {
      updateRow({ [valueToUpdate]: value }, rowId);
    };

    const assignedLabel = slug
      ? __('report.ga.basedOn', {
          date: formatYYYYMMDDDate(selectedAt, preferredDateFormat),
          assignedLabel:
            getDefaultLabel() +
            (showFetusDropDown && fetus
              ? ` (${__('examinationReview.fetus')} ${fetusDropdownOptions?.[fetus]?.label})`
              : ''),
        })
      : '';

    const { assignedOnDifferentExam, dateObtained } = gaData;

    // TODO remove this line if product is not satisfied with the assignedLabelExtension
    const assignedLabelExtension = assignedOnDifferentExam
      ? ` (${__('report.ga.assignedOnDifferentExam', {
          date: formatYYYYMMDDDate(dateObtained || '', preferredDateFormat),
        })})`
      : '';

    return (
      <div className={`ga-item ga-item-assigned-row is-visible ${showFetusDropDown ? 'show-fetus' : ''} assigned`}>
        {/* Fetus */}
        {showFetusDropDown && (
          <div>
            <span className="ga-item-fetus-number">{/* {fetus} */}</span>
          </div>
        )}

        {/* Custom Label */}
        <div>
          <InlineInput value={label} format={'string'} printable={true} onChange={onChange('label')} />
        </div>

        {/* Scan Date */}
        <div className="ga-item-assigned-details">
          {updatingAssignedGa ? `${__('common.loading')}...` : assignedLabel + assignedLabelExtension}
        </div>

        {/* Current GA */}
        <div className="ga-item-center">
          {updatingAssignedGa
            ? '...'
            : value
            ? getNiceGestionalAgeFromDays(__, value)
            : __('report.gatable.unavailable')}
        </div>

        {/* EDD */}
        <div className="ga-item-center">
          {updatingAssignedGa
            ? '...'
            : computeEstimatedDeliveryDate(
                value,
                currentExamDate,
                preferredDateFormat,
                timezone,
                pregnancyLengthInDays
              )}
        </div>

        {/* Assigned */}
        {!print && <div className="ga-item-assign ga-item-center"></div>}
        {/* Editing options */}
        {reportMode === 'edit' && (
          <div className="exam-report-editing-options">
            {prevExamsExist && (
              <div onClick={revertAssignedGa}>
                <Icon name="reload" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default AssignedGaItem;
