import { conditionRequiredPlaceholders, checkCondition } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';

function ReportTemplateIfBody(fullProps) {
  const { children, props } = fullProps;
  const placeholdersHelper = new PlaceholdersHelper(fullProps);

  const matchesConditions = checkCondition({ ...props }, 0, placeholdersHelper, placeholdersHelper.apiVersion);

  if (matchesConditions) {
    return children;
  }
  return false;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateIf({ props, ...otherProps }) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateIfBody}
      requiredPlaceholders={conditionRequiredPlaceholders(props).concat(['fetus.order'])}
      props={props}
      {...otherProps}
    />
  );
}
