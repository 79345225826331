import { useState, useEffect } from 'react';

export default function useChannel(socket, channelTopic, channelSetupCallback) {
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (!socket || !channelTopic) {
      return;
    }

    if (channel === null) {
      const channel = socket.channel(channelTopic, {});

      channelSetupCallback(channel);

      setChannel(channel);
    }

    return () => {
      if (channel !== null) {
        channel.leave();
      }
    };
  }, [socket, channelTopic]);
}
