import React, { useContext, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../atoms/Button/Button';
import ProgressRing from '../../atoms/ProgressRing/ProgressRing';
import ExamPresetButtons from '../../components/ExamPresetButtons/ExamPresetButtons';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';
import LookupApi from '../../services/lookup';
import ResourceApi from '../../services/resource';
import './ExaminationReviewView.css';
import ExaminationReviewOverview from './chapters/Overview';

const ExaminationReviewView = ({
  t: __,
  itemsAtRisk,
  slides,
  expectedMedias,
  expectedCNEOFMedias,
  takenCNEOFMedias,
  medicalHistory,
  riskFactors,
  teratogenicRisks,
  medias,
  takenMedias,
  anomalies = [],
  checklistItems = [],
  setIsPrintPreviewOpen,
  setIsSharingPreviewOpen,
}) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();

  const { patient, episode, examination, instances } = examinationContext;

  const [examinationMetadata, setExaminationMetadata] = useState([]);
  const [zones, setZones] = useState([]);
  const malformations = examination?.malformations || [];
  const currentTrimester = examination.trimester;
  const examinationType = examination.type;

  examination.type = examination.type || (malformations.length ? 'diagnostic' : 'screening');

  /** sections management */
  const sectionDashboard = useRef(null);
  const sectionRiskFactors = useRef(null);

  useEffect(() => {
    LookupApi.getExamZone().then((resp) => {
      setZones(resp.data.data.slice(0, 5));
    });
  }, []);

  useEffect(() => {
    if (examination?.id) {
      ResourceApi.getExaminationMetadata(examination.id).then((resp) => {
        setExaminationMetadata(resp.data.data);
      });
    }
  }, [examination.id]);

  return (
    <div className="examination-review-container">
      <div className="examination-review">
        {examinationContext.isCompleted() && (
          <div className="examination-review-shortcuts">
            <div className="examination-review-start-new">
              <ExamPresetButtons
                action="new-exam"
                label={__('examinationReview.startNewExam')}
                variant="outline"
                size="compact"
              />
            </div>
          </div>
        )}
        <section className="examination-live-review-chapter dashboard" ref={sectionDashboard}>
          <div className="examination-review-dashboard-cta">
            <h1>{__('examinationReview.report')}</h1>
            {examinationContext.examination.status !== 'completed' && (
              <div className="examination-live-review-not-completed">
                {__('examinationReview.notCompleted')}
                <Button
                  variant="outline"
                  label={__('examinationReview.endExamination')}
                  onClick={() => !!examinationContext.endExamination && examinationContext.endExamination()}
                />
              </div>
            )}

            <div className="examination-live-review-stat">
              {examinationContext.examination.trimester !== 'ND' ? (
                <>
                  {!!expectedCNEOFMedias && (
                    <div className="examination-live-review-stat-graph">
                      <div>
                        <ProgressRing
                          progress={Math.min(100, (100 / expectedCNEOFMedias) * takenCNEOFMedias)}
                          stroke="0.5"
                          label={`${takenCNEOFMedias} / ${expectedCNEOFMedias}`}
                          color={takenCNEOFMedias < expectedCNEOFMedias ? 'ocra' : 'blue'}
                        />
                      </div>
                      <label>
                        {expectedCNEOFMedias - takenCNEOFMedias
                          ? `${expectedCNEOFMedias - takenCNEOFMedias} ${__('examination.stats.missingFeaturedMedias', {
                              category: __(`examination-plane.category.${appContext.preferences.default_category}`),
                            })}`
                          : __('examination.stats.allFeaturedMediasTaken', {
                              category: __(`examination-plane.category.${appContext.preferences.default_category}`),
                            })}
                      </label>
                    </div>
                  )}
                  <div className="examination-live-review-stat-graph">
                    <div>
                      <ProgressRing
                        progress={(100 / expectedMedias) * takenMedias}
                        stroke="0.5"
                        label={`${takenMedias} / ${expectedMedias}`}
                        color={takenMedias < expectedMedias ? 'ocra' : 'blue'}
                      />
                    </div>
                    <label>
                      {expectedMedias - takenMedias > 0
                        ? `${expectedMedias - takenMedias} ${__('examination.stats.missingMedias')}`
                        : __('examination.stats.allMediasTaken')}
                    </label>
                  </div>
                </>
              ) : (
                <div className="examination-live-review-stat-text">
                  <span className="count">
                    {
                      medias.filter(
                        ({ modality, dicom_media_type }) => modality !== 'SR' && dicom_media_type === 'image'
                      ).length
                    }
                  </span>
                  <span className="label">{__('examination.stats.takenMedias')}</span>
                </div>
              )}
            </div>

            {isFeatureFlagEnabled('sonio.share') && (
              <div className="cta">
                <Button
                  label={__('examinationReview.shareImages')}
                  icon={isFeatureFlagEnabled('sonio.share') ? 'sharing-indicator' : 'image'}
                  onClick={setIsSharingPreviewOpen}
                  variant={'outline'}
                />

                <Button
                  label={__('examinationReview.printFeaturedImages')}
                  icon="print"
                  onClick={setIsPrintPreviewOpen}
                  variant={'outline'}
                />
              </div>
            )}
          </div>
        </section>

        <section className="examination-live-review-chapter" ref={sectionRiskFactors}>
          <ExaminationReviewOverview
            {...{
              examinationType,
              currentTrimester,
              patient,
              episode,
              examination,
              examinationMetadata,
              zones,
              instances,
              itemsAtRisk,
              slides,
              anomalies,
              checklistItems,
              medicalHistory,
              riskFactors,
              teratogenicRisks,
              appContext,
              examinationContext,
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default withTranslation()(ExaminationReviewView);
