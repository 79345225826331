import axios from 'axios';

const UtilApi = {
  logError: (error) => {
    const stackAsStr = `${error.stack}`;
    const userAgent = navigator.userAgent;
    const currentLocation = window.location.href;
    axios.post('front-end-error', { error: stackAsStr, metadata: { userAgent, currentLocation } });
  },
};

export default UtilApi;
