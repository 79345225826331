import ReportTemplateBlock_v1 from './Block/v1.js';
import ReportTemplateBlock_v2 from './Block/v2.js';

export default function ReportTemplateBlock(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0' || apiVersion === '1.1') {
    return ReportTemplateBlock_v1(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateBlock_v2(props);
  }
  return null;
}
