import { useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { withTranslation } from 'react-i18next';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import Icon from '../../atoms/Icon/Icon';
import './Disclaimer.css';
import useLatestVersion from '../../context-providers/LatestVersion';

const Disclaimer = ({ t: __, onAccept }) => {
  const { isFeatureFlagEnabled } = useAuth();
  const appContext = useContext(AppContext);
  const latestVersion = useLatestVersion();
  const currentProduct = isFeatureFlagEnabled('soniopedia') && latestVersion.isSoniopediaURL() ? 'soniopedia' : 'sdx';

  return (
    <div className="disclaimer-container">
      <div className="disclaimer-set-language">
        <ButtonGroup
          value={appContext?.preferences?.lang ?? ''}
          options={[
            { value: 'en', label: 'EN' },
            { value: 'fr', label: 'FR' },
            { value: 'de', label: 'DE' },
            { value: 'ptbr', label: 'PTBR' },
          ]}
          onChange={appContext.changeLanguage}
        />
      </div>
      {isFeatureFlagEnabled('sonio.dx_v2') ? (
        <div>
          <div className="disclaimer">
            <div
              dangerouslySetInnerHTML={{
                __html: __('dx.disclaimer.text', {
                  termsOfUseLink: __(`navbar.termsOfUse.${currentProduct}.href`),
                  privacyPolicyLink: __(`navbar.privacyPolicy.${currentProduct}.href`),
                  iconAttention: renderToString(<Icon name="attention" />),
                }),
              }}
            ></div>
            <div
              className="small"
              dangerouslySetInnerHTML={{
                __html: __('disclaimer.text', {
                  termsOfUseLink: __(`navbar.termsOfUse.${currentProduct}.href`),
                  privacyPolicyLink: __(`navbar.privacyPolicy.${currentProduct}.href`),
                  iconAttention: renderToString(<Icon name="attention" />),
                }),
              }}
            ></div>
          </div>
          <div className="submit">
            <button onClick={onAccept}>{__('disclaimer.ok')}</button>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="disclaimer"
            dangerouslySetInnerHTML={{
              __html: __('disclaimer.text', {
                termsOfUseLink: __('navbar.termsOfUse.sx.href'),
                privacyPolicyLink: __('navbar.privacyPolicy.sx.href'),
                iconAttention: renderToString(<Icon name="attention" />),
              }),
            }}
          ></div>
          <div className="submit">
            <button onClick={onAccept}>{__('disclaimer.ok')}</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Disclaimer);
