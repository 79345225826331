import axios from 'axios';
import { Socket as PhxSocket } from 'phoenix';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DEVICE_SERIAL_NUMBER_LABEL,
  LIVE_CONFIG,
  PERFORMING_PHYSICIAN_NAME_LABEL,
} from '../pages/Configuration/ConfigurationView';
import UserApi from '../services/user';
import useAuth from './Auth';
import useLatestVersion from './LatestVersion';

export const SocketContext = createContext(null);

const Socket = ({ children }) => {
  const { user, sessionAuthToken, isManager, isShadow } = useAuth();
  const [socket, setSocket] = useState(null);
  const [liveConfig, setLiveConfig] = useState(null);
  const latestVersion = useLatestVersion();
  const lastVisibilityChange = useRef(null);

  const wsUrl = useMemo(
    () => `${window.location.protocol === 'http:' ? 'ws:' : 'wss:'}//${window.location.host}/socket`,
    [window.location.protocol, window.location.host]
  );

  const params = useMemo(
    () => ({
      live_config: JSON.stringify({ live_configuration: liveConfig?.live_configuration }),
      session_auth_token: sessionAuthToken,
    }),
    [liveConfig, sessionAuthToken]
  );

  // TODO: investigate why there is a web socket call in case we are in manager
  const setupSocket = useCallback(() => {
    if (!socket) {
      const reconnectAfterMs = (tries) => {
        return [10, 50, 100, 150, 200, 250, 500, 1000][tries - 1] || 2000;
      };

      const newSocket = new PhxSocket(wsUrl, { reconnectAfterMs, heartbeatIntervalMs: 10000, params });
      newSocket.connect();
      setSocket(newSocket);
    }
  }, [socket, sessionAuthToken, wsUrl, params]);

  const teardownSocket = useCallback(() => {
    if (socket) {
      console.debug('WebSocket routes unmounted disconnect Socket', socket);
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  useEffect(() => {
    if (isManager === false && isShadow != null && !!user && !!user.preferences.last_used_site_id) {
      if (!socket && !!liveConfig) {
        setupSocket(socket, setSocket, wsUrl, params);
      }
    }
    return () => teardownSocket(socket, setSocket);
  }, [socket, wsUrl, params, user, liveConfig, isManager, isShadow, latestVersion.updateAvailable, sessionAuthToken]);

  const setConfigFromLocalStorage = () => {
    const allLocalLiveConfig = JSON.parse(localStorage.getItem(LIVE_CONFIG));
    const localLiveConfig = allLocalLiveConfig?.[user.preferences.last_used_site_id];
    if (localLiveConfig) {
      setLiveConfig(localLiveConfig);
    } else {
      const newLocalLiveConfig = {
        live_configuration: {
          live_enabled: true,
          filters: {
            operator: 'AND',
            operands: [
              { operator: 'ALL', target: PERFORMING_PHYSICIAN_NAME_LABEL },
              { operator: 'ALL', target: DEVICE_SERIAL_NUMBER_LABEL },
            ],
          },
        },
      };
      localStorage.setItem(
        LIVE_CONFIG,
        JSON.stringify({
          ...allLocalLiveConfig,
          [user.preferences.last_used_site_id]: newLocalLiveConfig,
        })
      );
      setLiveConfig(newLocalLiveConfig);
    }
  };

  const setConfigFromUser = async (source) => {
    const {
      data: { data: config },
    } = await UserApi.getConfig(source);
    /* If the live configuration is not set and the entity has no configuration, create a new one with default values */
    if (!config?.live_configuration && !config?.entity_id) {
      const {
        data: { data: newConfig },
      } = await UserApi.createConfig(
        {
          live_configuration: {
            live_enabled: true,
            filters: {
              operator: 'AND',
              operands: [
                { operator: 'ALL', target: PERFORMING_PHYSICIAN_NAME_LABEL },
                { operator: 'ALL', target: DEVICE_SERIAL_NUMBER_LABEL },
              ],
            },
          },
        },
        source
      );
      setLiveConfig(newConfig);
      /* If the live configuration is not set but then entity has a configuration, update it with default values */
    } else if (!config.live_configuration) {
      const {
        data: { data: newConfig },
      } = await UserApi.updateConfig(config.id, {
        live_configuration: {
          live_enabled: true,
          filters: {
            operator: 'AND',
            operands: [
              { operator: 'ALL', target: PERFORMING_PHYSICIAN_NAME_LABEL },
              { operator: 'ALL', target: DEVICE_SERIAL_NUMBER_LABEL },
            ],
          },
        },
      });
      setLiveConfig(newConfig);
      /* If the live configuration is set (inherited), but the user has no configuration,
       * create empty user configuration
       * So if the user updates the live configuration it will be saved in the user configuration
       */
    } else if (!config.entity_id) {
      const {
        data: { data: newConfig },
      } = UserApi.createConfig(
        {
          live_configuration: null,
        },
        source
      );
      setLiveConfig({ ...newConfig, live_configuration: config.live_configuration });
    } else {
      setLiveConfig(config);
    }
  };

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (user && (isManager || isShadow)) {
      setConfigFromLocalStorage();
    } else {
      if (user) {
        setConfigFromUser(source);
      }
    }
    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [user, isManager, isShadow]);

  const memoedValue = useMemo(
    () => ({
      socket,
      liveConfig,
      setLiveConfig,
    }),
    [socket, liveConfig, setLiveConfig]
  );

  // Set the name of the hidden property and the change event for visibility
  let hidden, visibilityChange;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      lastVisibilityChange.current = Date.now();
    } else {
      // Reset socket connection
      if (Date.now() - lastVisibilityChange.current > 30000) {
        setSocket(null);
      }
    }
  };

  useEffect(() => {
    // Warn if the browser doesn't support addEventListener or the Page Visibility API
    if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
      console.log(
        'This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.'
      );
    } else {
      document.addEventListener(visibilityChange, handleVisibilityChange, false);
    }

    return () => document.removeEventListener(visibilityChange, handleVisibilityChange);
  }, []);

  return (
    !!user &&
    !!user.preferences?.last_used_site_id &&
    !!liveConfig && <SocketContext.Provider value={memoedValue}>{children}</SocketContext.Provider>
  );
};
export default Socket;
