import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import './EditFlashReportDialog.css';

import { useReportDialog } from '../../../context-providers/ReportDialogProvider';
import { ExaminationContext } from '../../../context-providers/Examination';
import { useXMLTemplate } from '../../../context-providers/XMLTemplate';
import EditFlashReportNameDialog from '../EditFlashReportNameDialog/EditFlashReportNameDialog';
import Button from '../../../atoms/Button/Button';
import ConfirmFlashReportDialog from '../ConfirmDialog/ConfirmFlashReportDialog';
import ResourceApi from '../../../services/resource';
import { NotificationContext } from '../../../context-providers/Notification';
import Icon from '../../../atoms/Icon/Icon';

const EditFlashReportDialog = ({ t: __ }) => {
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);

  const { closeModal, openModal } = useReportDialog();
  const { deleteAutomationTemplate, reportDataOptions, setAutomationTemplateFieldsVisible, setIsEditMode } =
    useXMLTemplate();

  const onRename = (template) => {
    openModal(<EditFlashReportNameDialog t={__} template={template} />);
  };

  const onClose = () => {
    openModal(<EditFlashReportDialog t={__} />);
  };

  const onDeleteConfirm = (template) => {
    ResourceApi.deleteAutomationTemplate(examinationContext.examination.preset.id, template.slug)
      .then(() => {
        notificationContext.showNotification(
          <>
            <Icon name="done" />
            {__('reportDialog.edit.notification.delete', {
              name: template.name,
            })}
          </>,
          5000
        );
        deleteAutomationTemplate(template);
        setIsEditMode(false);
        setAutomationTemplateFieldsVisible(false);
      })
      .catch((error) => {
        console.error(error);
        notificationContext.showNotification(
          <>
            <Icon name="warning" />
            {__('reportDialog.edit.notification.deleteError', {
              name: template.name,
            })}
          </>,
          5000
        );
      });
  };

  const onDelete = (template) => {
    openModal(
      <ConfirmFlashReportDialog t={__} onConfirm={() => onDeleteConfirm(template)} onClose={onClose} hideContent />
    );
  };

  return (
    <div className="edit-flash-report-root">
      <div className="edit-flash-report-container">
        <div className="edit-flash-report-title">Flash Reports</div>
        <hr />
        <div className="edit-flash-report-templates-container">
          {reportDataOptions.automation_templates
            .filter(({ report_version }) => report_version === examinationContext.examination.report_version)
            .map((template) => (
              <div key={`edit-report-${template.slug}`} className="edit-flash-report-templates">
                <div>{template.name}</div>
                <div className="edit-flash-report-template-buttons">
                  <Button variant="outline" onClick={() => onRename(template)} label={__('reportDialog.edit.rename')} />
                  <Button
                    variant="outline"
                    onClick={() => onDelete(template)}
                    icon="trash"
                    label={__('reportDialog.edit.delete')}
                  />
                </div>
              </div>
            ))}
        </div>
        <hr />
        <Button
          variant="outline"
          onClick={closeModal}
          customClass="edit-flash-report-close-button"
          label={__('reportDialog.edit.close')}
        />
      </div>
    </div>
  );
};

export default withTranslation()(EditFlashReportDialog);
