import { useEffect, useState } from 'react';
import Icon from '../../atoms/Icon/Icon';
import './index.css';

/* Usage example:
 * import {Snack, initialSnackState, snackEvent} from "atoms/Snack"
 * [snack, setSnack] = useState(initialSnackState())
 * const onSucess = () => snackEvent("show", setSnack, {assigns: {message: "event performed correctly"}, type: "sucess", hideAfter: 3000})
 * const onError = () => snackEvent("show", setSnack, {assigns: {message: "event had error"}, type: "error", hideAfter: 5000})
 * const onEvent = () => {
 *   snackEvent("show", setSnack, {assigns: {message: "performing event"}, type: "info"})
 *   return <somePromise such as an API>
 * }).then(onSucess).catch(onError)
 *
 * return (
 *  <>
 *    <Snack snack={snack} setSnack={setSnack}>{sack.assigns.message}</Snack>
 *    <button onClick={onEvent}>Click me!</button>
 *  </>
 * )
 */

const iconName = (type) => {
  switch (type) {
    case 'sucess':
      return 'done-circled';
    case 'warning':
      return 'attention';
    case 'error':
      return 'error';
    case 'info':
      return 'info';
    default:
      return 'empty';
  }
};

function initialSnackState(assigns) {
  return {
    timeout: null,
    message: '',
    type: 'info',
    assigns,
  };
}
let eventRef = 0;

function snackEvent(event_type, setSnack, message) {
  eventRef++;
  switch (event_type) {
    case 'show':
      return showSnack(setSnack, eventRef, message);
    case 'hide':
      return hideSnack(setSnack, eventRef, message);
  }
}

function showSnack(setSnack, eventRef, { type = 'success', hideAfter, assigns }) {
  setSnack((snack) => ({ type, hideAfter, eventRef, show: true, assigns }));
}

function hideSnack(setSnack, eventRef) {
  setSnack((snack) => ({ ...snack, show: false, hideAfter: false }));
}

function Snack({ setSnack, snack, children }) {
  const containerClass = ['snack-container', snack.show ? 'show' : 'hide', snack.type].join(' ');
  const snackBarClass = ['snack-bar', snack.type].join(' ');
  const [snackBarStyle, setSnackBarStyle] = useState({});
  const loadingSnackBarStyle = (hideAfter) => ({
    transition: `width ${hideAfter}ms linear`,
    width: '0%',
  });

  useEffect(() => {
    if (!snack.hideAfter) return;
    const timeout = setTimeout(() => setSnack((snack) => ({ ...snack, show: false })), snack.hideAfter);

    setSnackBarStyle({});
    const styleTimeout = setTimeout(() => setSnackBarStyle(loadingSnackBarStyle(snack.hideAfter)), 10); // this is very hacky but I didn't found any alternative solution
    return () => {
      clearTimeout(timeout);
      clearTimeout(styleTimeout);
    };
  }, [snack.eventRef]); /* useEffect on every new event only */

  return (
    <div className={containerClass}>
      <div className={snackBarClass} style={snackBarStyle} />
      <div className="snack-internal-container">
        <div className="snack-row">
          <Icon name={iconName(snack.type)} />
          <div>{children}</div>
        </div>
        <Icon name="close" className="clickable" onClick={() => snackEvent('hide', setSnack, {})} />
      </div>
    </div>
  );
}

export { Snack, initialSnackState, snackEvent };
