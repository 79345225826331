import ReportTemplateMeasurementCurve_v1_0 from './MeasurementCurve/v1.0.js';
import ReportTemplateMeasurementCurve_v2_0 from './MeasurementCurve/v2.0.js';

export default function ReportTemplateMeasurementCurve(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateMeasurementCurve_v1_0(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateMeasurementCurve_v2_0(props);
  }
  return null;
}
