import ReportTemplateTextBlock_v1_0 from './TextBlock/v1.0.js';
import ReportTemplateTextBlock_v2_0 from './TextBlock/v2.0.js';

export default function ReportTemplateTextBlock(props) {
  const { apiVersion } = props;
  if (apiVersion === '1.0') {
    return ReportTemplateTextBlock_v1_0(props);
  } else if (apiVersion === '2.0') {
    return ReportTemplateTextBlock_v2_0(props);
  }
  return null;
}
