import ResourceApi from '../services/resource';

const uploadDocumentList = async ({ siteId, examId, files, addStatusFile, addServerError }) => {
  return new Promise((resolve) => {
    const promises = files
      .map(({ file, title }) => {
        const formData = new FormData();
        formData.append('attachment[file]', file);
        formData.append('attachment[title]', title);
        return formData;
      })
      .map((form, index) =>
        ResourceApi.uploadExamDocument(examId, form).then((response) => {
          const errors = response?.data?.errors;
          if (typeof errors === 'string') {
            if (typeof errors === 'string' || errors instanceof String) {
              // https://stackoverflow.com/a/9436948
              const translationKey = errors;
              addServerError(translationKey);
            }
          }
          if (errors) return;
          const { id } = response.data.data;
          return ResourceApi.addSharingDocument({
            examId,
            attachmentId: id,
            siteId,
          })
            .then(() => new Promise((resolve) => setTimeout(resolve, 400)))
            .then(() => addStatusFile(files[index].title));
        })
      );
    Promise.all(promises).finally(resolve);
  });
};

export default uploadDocumentList;
