import { useRef, useState, useEffect } from 'react';
import Icon from '../../Icon/Icon';
import '../InlineEditing.css';
import './InlineDropdown.css';

export default function InlineDropdown({
  value = null,
  options = [],
  active = true,
  printable = true,
  onStartEditing = () => {},
  onChange = () => {},
}) {
  const [editing, setEditing] = useState(false);

  const dialogRef = useRef(false);
  const valueField = useRef(false);

  const selectedValue =
    (
      options.find((option) => option?.value?.toString() === value?.toString()) ||
      options.find((option) => option?.default)
    )?.value ||
    options
      .find((option) => option?.default)
      ?.label?.toString()
      .trim();
  const displayedValue = (
    options.find((option) => option?.value?.toString() === value?.toString()) ||
    options.find((option) => option?.default)
  )?.label;

  const onCloseHandler = () => {
    setTimeout(() => setEditing(false), 200);
    window.removeEventListener('click', onCloseHandler, false);
  };

  useEffect(() => {
    if (editing) {
      window.removeEventListener('click', onCloseHandler, false);
      window.addEventListener('click', onCloseHandler, false);
      onStartEditing();
    }
  }, [editing]);

  const onChangeHandler = (value, option) => {
    onChange(value, option);
    onCloseHandler();
  };

  return (
    <div
      className={`inline-editing inline-dropdown ${active ? 'is-active' : 'not-active'} ${
        printable ? 'is-printable' : 'not-printable'
      } ${editing ? 'is-editing' : ''}`}
    >
      <div className="inline-dropdown-value inline-editing-value">
        <div ref={valueField} onClick={() => active && setEditing(true)}>
          {displayedValue?.trim() || <>&nbsp;</>}
        </div>
        {active && <Icon name="down" onClick={() => setEditing(true)} />}
      </div>
      <span className="inline-dropdown-options-wrapper">
        {editing && active && options.length && (
          <div className="inline-dropdown-options" ref={dialogRef}>
            {options
              .filter((option) => !option.invisible)
              .map((option, index) => (
                <div
                  key={index}
                  className={`inline-dropdown-option ${option?.value === selectedValue ? 'selected' : ''}`}
                  onClick={() => onChangeHandler(option?.value ?? option?.label, option)}
                >
                  {option?.label?.trim() || <>&nbsp;</>}
                </div>
              ))}
          </div>
        )}
      </span>
    </div>
  );
}
