import React, { useState, createContext, useEffect, useContext } from 'react';
import { AppContext } from './App';
import { ExaminationContext } from './Examination';
import { useHistory } from 'react-router-dom';
import LookupApi from '../services/lookup';
import DxAiApi from '../services/dx-ai';
import PropTypes from 'prop-types';
import useAuth from './Auth';

let loadRecommendationsTimeout = false;
let temporaryAddedMalformationId = false;

export const DxContext = createContext({});

export const DxContextProvider = ({ children }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const history = useHistory();

  const { isFeatureFlagEnabled } = useAuth();
  const isDxUrl = history.location.pathname.match(/^\/exam\/\d+\/dx.*/);

  const [currentZoneId, setCurrentZoneId] = useState(1);
  const [zones, setZones] = useState({});
  const [semiologyWorkflowStack, setSemiologyWorkflowStack] = useState([]);

  const [currentChecklistItem, setCurrentChecklistItem] = useState(null);

  const [checklistSummaryOpen, setChecklistSummaryOpen] = useState(false);

  const [currentTab, setCurrentTab] = useState('fetus');

  const [recommendation, setRecommendation] = useState({
    malformations: [],
    syndromes: [],
    signs: [],
  });
  const [isLoadingRecommendation, setIsLoadingReccomendation] = useState(false);
  const [isLoadingSemiology, setIsLoadingSemiology] = useState(false);

  const [userOverrides, setUserOverrides] = useState([]);

  useEffect(() => {
    resetExamination();
    setUserOverrides(examinationContext.examination.recommendation?.user_overrides || []);
  }, [examinationContext.examination.id]);

  useEffect(() => {
    resetExamination();
    LookupApi.getExamZone().then((resp) => {
      const tmpZones = {};

      resp.data.data.forEach((zone) => {
        tmpZones[zone.id] = { ...zones[zone.id], ...zone };
      });

      setZones(addAttributesToZones(tmpZones));
    });
  }, []);

  useEffect(() => {
    if (!examinationContext.examination?.trimester) return false;
    if (!examinationContext.MALFORMATIONS) return false;
    if (!isDxUrl) return false;
    if (loadRecommendationsTimeout) clearTimeout(loadRecommendationsTimeout);
    loadRecommendationsTimeout = setTimeout(() => loadRecommendations(), 200);
  }, [
    examinationContext.examination.trimester,
    examinationContext.MALFORMATIONS,
    JSON.stringify(examinationContext.examination.malformations),
    JSON.stringify(examinationContext.riskFactorIds),
    JSON.stringify(examinationContext.examination.medical_history?.['genetichistory.cgh']),
    JSON.stringify(examinationContext.examination.medical_history?.['genetichistory.karyotype']),
    JSON.stringify(examinationContext.examination.medical_history?.['genetichistory.dpni']),
    JSON.stringify(examinationContext.examination.medical_history?.['genetichistory.geneticist_result']),
    JSON.stringify(examinationContext.examination.zones),
    semiologyWorkflowStack,
  ]);

  /**
   * Handle exam item click
   */
  useEffect(() => {
    setIsLoadingSemiology(true);
    setSemiologyWorkflowStack([]);
    if (!currentChecklistItem) return false;

    setCurrentZoneId(currentChecklistItem.exam_zone_id);

    DxAiApi.semiologyExamItems(currentChecklistItem.id).then((semioResp) => {
      setRecommendation({
        ...recommendation,
        signs: semioResp.data.data.signs,
        malformations: semioResp.data.data.malformations,
      });
      setIsLoadingSemiology(false);
    });
  }, [currentChecklistItem]);

  const addAttributesToZones = (zones, recommendedZones = []) => {
    if (!Object.keys(examinationContext.MALFORMATIONS).length) return zones;
    const malformations =
      examinationContext.examination?.malformations?.map(
        (malformation) => examinationContext.MALFORMATIONS[malformation.id]
      ) || [];

    const zonesToCheck = recommendedZones
      .filter(
        (zone) =>
          zone.exam_zone_id !== currentZoneId &&
          !examinationContext.examination.zones?.some((z) => z.id === zone.exam_zone_id && z.status === 'validated')
      )
      .sort((a, b) => b.score - a.score)
      .slice(0, 2)
      .map((zone) => zone.exam_zone_id);

    const newZones = {};
    for (const zone of Object.values(zones)) {
      newZones[zone.id] = {
        ...zone,
        validated: examinationContext.examination.zones?.some((z) => z.id === zone.id && z.status === 'validated'),
        unusual: malformations.some((malformation) => malformation.exam_zone_id === zone.id),
        toCheck:
          (!!malformations.length ||
            !!examinationContext.riskFactorIds.filter((riskFactorId) => ![72, 73].includes(riskFactorId)).length) &&
          zonesToCheck.includes(zone.id),
      };
    }

    return newZones;
  };

  /**
   * Reset the vars to the initial state
   */
  const resetExamination = () => {
    setCurrentTab('fetus');
    setCurrentZoneId(1);
    setZones((zones) => {
      for (const zoneId of Object.keys(zones)) {
        zones[zoneId].unusual = false;
        zones[zoneId].toCheck = false;
      }
      return zones;
    });
    setChecklistSummaryOpen(false);
    setRecommendation({
      malformations: [],
      syndromes: [],
      signs: [],
    });
    if (loadRecommendationsTimeout) clearTimeout(loadRecommendationsTimeout);
    loadRecommendations();
  };

  /**
   * add or update a malformation to the phenotype
   * if the malformation has any linked signs. enter the semiology workflow
   */
  const updateMalformation = (malfo, status, resetSemiologyWorkflow = true) => {
    if (
      malfo.linked_signs?.length > 0 &&
      status === 'yes' &&
      (semiologyWorkflowStack.length === 0 || !resetSemiologyWorkflow)
    ) {
      addSemiologyItem(malfo, 'malformation');
    } else {
      const currExamination = { ...examinationContext.examination };
      const newMalformations = currExamination.malformations ?? [];
      const found = newMalformations.findIndex((i) => i.id === malfo.id);

      if (found >= 0) {
        newMalformations[found].status = status;
      } else {
        newMalformations.push({
          id: malfo.id,
          status: status,
        });
      }

      if (temporaryAddedMalformationId) {
        if (!!semiologyWorkflowStack.length && resetSemiologyWorkflow) {
          for (const absentMalfo of recommendation.malformations) {
            if (
              absentMalfo &&
              absentMalfo.id !== malfo.id &&
              !newMalformations.some((malfo) => malfo.id === absentMalfo.id)
            ) {
              newMalformations.push({
                id: absentMalfo.id,
                status: 'no',
              });
            }
          }
        }
      }

      if (resetSemiologyWorkflow) {
        temporaryAddedMalformationId = false;
        setSemiologyWorkflowStack([]);
      }

      examinationContext.updateExamination({
        ...currExamination,
        malformations: newMalformations,
      });

      setCurrentChecklistItem(null);

      const newCurrentZoneId = examinationContext.getMalformationById(malfo.id)?.exam_zone_id;
      if (newCurrentZoneId && newCurrentZoneId !== currentZoneId) {
        setRecommendation((recommendation) => ({ ...recommendation, malformations: [] }));
        setCurrentZoneId(newCurrentZoneId);
      }
    }
  };

  const removeMalformation = (malformationId) => {
    const newMalformations = (examinationContext.examination.malformations || []).filter(
      (malformation) => malformation.id !== malformationId
    );
    examinationContext.updateExamination({ ...examinationContext.examination, malformations: newMalformations });
  };

  const updateZone = (zoneId, status) => {
    const currExamination = { ...examinationContext.examination };

    const found = currExamination.zones?.find((z) => z.id === zoneId);

    let newZones = currExamination.zones ?? [];

    if (found) {
      newZones = currExamination.zones.map((z) => (found.id === z.id ? { id: z.id, status: status } : z));
    } else {
      newZones.push({ id: zoneId, status: status });
    }

    setZones((zones) => {
      for (const k of Object.keys(zones)) {
        if (zones[k].id === zoneId) {
          if (status === 'validated') zones[k].validated = true;
          else zones[k].validated = false;
        }
      }
      return zones;
    });

    return newZones;
  };

  const getNextZoneId = () => {
    const first5zones = Object.values(zones).slice(0, 5);
    const nextZone = first5zones.find((zone) => zone.id !== currentZoneId && zone.toCheck);
    if (!nextZone) {
      const zoneIds = first5zones.map((zone) => zone.id);
      const nextZoneId = zoneIds[(zoneIds.indexOf(currentZoneId) + 1) % zoneIds.length] || zoneIds[0];
      return nextZoneId || first5zones[0]?.id;
    }
    return nextZone.id || first5zones[0]?.id;
  };

  /* Leave semiology WF when changing the zone */
  useEffect(() => {
    if (semiologyWorkflowStack?.length) leaveSemiologyWorkflow();
  }, [currentZoneId]);

  /**
   * add an item to the semiology workflow
   * @param {object} item the semiology item that was clicked
   * @param {string} type malformation | sign
   */
  const addSemiologyItem = (item, type, resetSemiologyWorkflow = false) => {
    setIsLoadingReccomendation(true);
    appContext.setIsLoading(true);

    const signIds = type === 'malformation' ? item.linked_signs : [item.id];

    if (resetSemiologyWorkflow) {
      temporaryAddedMalformationId = false;
      setSemiologyWorkflowStack([]);
    } else if (semiologyWorkflowStack.length === 0) {
      temporaryAddedMalformationId = false;
    }

    if (signIds.length) {
      setIsLoadingSemiology(true);
      DxAiApi.semiology({
        examination: {
          signs: signIds,
          trimester: examinationContext.examination?.trimester || 'ND',
          gender: examinationContext.examination?.medical_history?.['medicalexam.fetus.sex']?.value || 'unknown',
        },
      }).then((semioResp) => {
        let allMalformations = semioResp.data.data.malformations;
        const malformations = examinationContext.examination?.malformations
          ? [...examinationContext.examination?.malformations]
          : [];

        // remove items already checked
        allMalformations = allMalformations.filter(
          (malformation) => !malformations.some((examMalformation) => examMalformation.id === malformation.id)
        );

        // add the isolated anomaly on top of results
        const signAnomaly = item.linked_signs?.length
          ? { ...item, linked_signs: [] }
          : examinationContext.getMalformationBySignId(item.id);
        if (signAnomaly) {
          allMalformations.unshift({
            ...signAnomaly,
            signs: [],
            linked_signs: [],
            isolated: true,
            status: 'no',
            exam_zone_id: currentZoneId,
          });

          // temporary adding the isolated anomaly to the phenotype, in case the semio wf will be interrupted without choosing any item
          updateMalformation(
            { ...signAnomaly, linked_signs: null },
            malformations.find((examMalformation) => examMalformation.id === signAnomaly.id)?.status || 'yes',
            false
          );
          if (!malformations.some((examMalformation) => examMalformation.id === signAnomaly.id)) {
            temporaryAddedMalformationId = signAnomaly.id;
          }
        }

        // remove duplicates
        allMalformations = allMalformations.filter(
          (malformation, index) => allMalformations.findIndex((m) => m.id === malformation.id) === index
        );

        setRecommendation((recommendation) => ({
          ...recommendation,
          malformations: allMalformations,
          signs: [],
        }));
        setIsLoadingReccomendation(false);
        setIsLoadingSemiology(false);
        appContext.setIsLoading(false);

        if (allMalformations.length === 0) {
          // no items: leave the semiology wf
          temporaryAddedMalformationId = false;
          setSemiologyWorkflowStack([]);
          loadRecommendations();
        } else if (allMalformations.length === 1) {
          // one item: auto-select it
          updateMalformation(allMalformations[0], 'yes');
        } else {
          const newItem =
            item.type === 'malformation' ? { ...item, ...examinationContext.getMalformation(item.id) } : item;
          setSemiologyWorkflowStack((semiologyWorkflowStack) => [...semiologyWorkflowStack, newItem]);
        }
      });
    }
  };

  const goToSemiologyWorkflowSign = (signId) => {
    const index = semiologyWorkflowStack.findIndex((s) => s.id === signId);
    if (index < 0) return false;
    const sign = semiologyWorkflowStack[index];
    setSemiologyWorkflowStack(semiologyWorkflowStack.slice(0, index));
    addSemiologyItem(sign);
  };

  const leaveSemiologyWorkflow = () => {
    temporaryAddedMalformationId = false;
    setRecommendation((recommendation) => ({ ...recommendation, malformations: [] }));
    setSemiologyWorkflowStack([]);
  };

  /**
   * load recommendations once semiology wf has been ended
   */
  useEffect(() => {
    if (!semiologyWorkflowStack.length && !currentChecklistItem) loadRecommendations();
  }, [semiologyWorkflowStack, currentChecklistItem]);

  const loadRecommendations = () => {
    if (!isFeatureFlagEnabled('sonio.dx_v2')) return false;
    if (!examinationContext.examination?.id) return false;

    const updateRecommendation = !semiologyWorkflowStack.length && !currentChecklistItem;

    setIsLoadingReccomendation(true);
    appContext.setIsLoading(true);

    const risk_factors = examinationContext.riskFactorIds.map((id) => ({ id, value: 'yes' }));
    const malformations = examinationContext.examination.malformations ?? [];
    const exam_items = examinationContext.examination.exam_items;

    const genetic_analysis = {};
    if (examinationContext.examination?.medical_history) {
      for (const param of ['cgh', 'karyotype', 'dpni']) {
        const value = examinationContext.examination?.medical_history['genetichistory.' + param]?.value;
        const geneticResult =
          examinationContext.examination?.medical_history['genetichistory.geneticist_result']?.value;
        if (value === 'yes_and_negative' || (value === 'yes_and_positive' && geneticResult)) {
          genetic_analysis[param] = value === 'yes_and_positive' ? geneticResult : false;
        }
      }
    }

    if (isDxUrl) {
      DxAiApi.recommendation({
        examination: {
          trimester: examinationContext.examination?.trimester || 'ND',
          gender:
            examinationContext.examination?.medical_history &&
            examinationContext.examination?.medical_history['medicalexam.fetus.sex']
              ? examinationContext.examination.medical_history['medicalexam.fetus.sex'].value
              : 'unknown',
          malformations,
          exam_items,
          risk_factors,
          zones: examinationContext.examination.zones ?? [],
          genetic_analysis,
        },
      })
        .then((resp) => {
          const newRecommendation = { ...recommendation, ...resp.data };
          const newRecommendationForExamObject = {
            examination_evaluation: newRecommendation.examination_evaluation,
            diagnostic_reliability: newRecommendation.diagnostic_reliability,
            syndromes: newRecommendation.syndromes,
            user_overrides: userOverrides,
          };

          // update malformations only when outside the semiology workflow and the checklistitem workflow
          if (updateRecommendation) setRecommendation({ ...newRecommendation, signs: [] });
          else
            setRecommendation((recommendation) => ({
              ...recommendation,
              syndromes: newRecommendation.syndromes,
              zones: newRecommendation.zones,
            }));

          examinationContext.updateExamination({
            ...examinationContext.examination,
            recommendation: newRecommendationForExamObject,
          });

          setZones(addAttributesToZones(zones, newRecommendation.zones));
          setIsLoadingReccomendation(false);
          appContext.setIsLoading(false);

          if (!newRecommendation.malformations?.length) setChecklistSummaryOpen(true);

          return true;
        })
        .catch(() => {
          return false;
        });
    }
  };

  /**
   * user overrides
   */
  useEffect(() => {
    if (userOverrides.length > 0) {
      examinationContext.updateExamination({
        ...examinationContext.examination,
        recommendation: {
          ...examinationContext.examination.recommendation,
          user_overrides: userOverrides,
        },
      });
    }
  }, [userOverrides]);

  return (
    <DxContext.Provider
      value={{
        resetExamination,
        currentZoneId,
        setCurrentZoneId,
        zones,
        getNextZoneId,
        currentChecklistItem,
        setCurrentChecklistItem,
        updateMalformation,
        removeMalformation,
        updateZone,
        checklistSummaryOpen,
        setChecklistSummaryOpen,
        currentTab,
        setCurrentTab,
        recommendation,
        setRecommendation,
        loadRecommendations,
        isLoadingRecommendation,
        isLoadingSemiology,
        addSemiologyItem,
        semiologyWorkflowStack,
        goToSemiologyWorkflowSign,
        leaveSemiologyWorkflow,
        userOverrides,
        setUserOverrides,
      }}
    >
      {children}
    </DxContext.Provider>
  );
};

DxContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
