// The main Window Context to manage the state of the windows and screens

import { createContext, useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ExaminationContext } from './Examination';
import useAuth from './Auth';
import SimplePopup from '../components/Popup/SimplePopup';
import Button from '../atoms/Button/Button';

export const WindowContext = createContext({});

const LOCALSTORAGE_WINDOW_CONGIF_PARAM = 'window_config';

export const WindowContextProvider = withTranslation()(({ t: __, children }) => {
  const defaultScreen = useMemo(
    () => ({
      availHeight: window.screen.availHeight,
      availLeft: 0,
      availTop: 0,
      availWidth: window.screen.availWidth,
      colorDepth: 24,
      devicePixelRatio: 2,
      height: window.screen.height,
      isExtended: false,
      isInternal: true,
      isPrimary: true,
      label: '',
      left: 0,
      onchange: null,
      orientation: {
        angle: 0,
        onchange: null,
        type: 'landscape-primary',
      },
      pixelDepth: 24,
      top: 0,
      width: window.screen.width,
    }),
    []
  );

  const getFromStorage = (storage = window.localStorage) => {
    const item = storage.getItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM);
    if (!item) return [];
    const json = JSON.parse(item);
    return Array.isArray(json) ? json : [];
  };

  const examinationContext = useContext(ExaminationContext);
  const [multiscreenGranted, setMultiscreenGranted] = useState(null);
  const [showMultiscreenPopup, setShowMultiscreenPopup] = useState(false);
  const [screens, setScreens] = useState(false);
  const [windows, setWindows] = useState(getFromStorage());
  const [currentView, setCurrentView] = useState('');
  const [preventClosing, setPreventClosing] = useState(false);
  const { isFeatureFlagEnabled, isFeatureFlagsLoaded } = useAuth();

  const isDetached = !!window.opener;
  const isCompared = isDetached && currentView === 'exam';

  /** get multiscreen details on supported browsers (Chrome) */
  useEffect(async () => {
    if (window.opener) return;
    // from here, executed by core only

    window.addEventListener('beforeunload', () => closeAllWindows());
    try {
      const { state } = await navigator.permissions.query({
        name: 'window-management',
      });
      setMultiscreenGranted(state === 'granted');
      if (
        state === 'prompt' &&
        'getScreenDetails' in window &&
        isFeatureFlagEnabled('sonio.multiscreen') &&
        !localStorage.getItem('automation_run')
      ) {
        setShowMultiscreenPopup(true);
      }
    } catch {
      setMultiscreenGranted(false);
    }
  }, []);

  const askForMultiscreenPermissions = async () => {
    try {
      await window.getScreenDetails();
      setShowMultiscreenPopup(false);
    } catch (e) {
      console.error('Catch: ', e);
    }
  };

  useEffect(() => {
    const promise = async () => {
      if (multiscreenGranted !== null && screens) return true;
      try {
        const details = await window.getScreenDetails();
        setScreens(details);
      } catch {
        // fallback for browsers not supporting multi-screen
        setScreens({
          currentScreen: defaultScreen,
          oncurrentscreenchange: null,
          onscreenschange: null,
          screens: [defaultScreen],
        });
      }
    };
    promise();
  }, [multiscreenGranted, setScreens]);

  // recreate workspace only when on an examination
  useEffect(() => {
    if (currentView === 'core' && examinationContext.examination?.id) {
      rebuildWindowsFromLocalStorage();
    }
  }, [currentView, examinationContext.examination?.id]);

  useEffect(() => {
    let heartBeatInterval = false;
    if (
      isFeatureFlagsLoaded &&
      isFeatureFlagEnabled('sonio.multiscreen') &&
      currentView &&
      currentView !== 'core' &&
      examinationContext.examination?.id
    ) {
      clearInterval(heartBeatInterval);
      heartBeatInterval = setInterval(heartBeat, 1500);
    }

    return () => !!heartBeatInterval && clearInterval(heartBeatInterval);
  }, [currentView, examinationContext.examination?.id, preventClosing]);

  useEffect(() => {
    setCurrentView(window.location.href.match(/.*?window\/(.*?)\/.*/)?.[1] || 'core');
  }, [window.location.href]);

  const windowExists = (view) => {
    if (view === currentView) return true;
    return windows.some((w) => w.view === view && !!getValidWindow(w.window));
  };

  const getAllActiveWindows = () => {
    const sessionStorage = JSON.parse(window.sessionStorage.getItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM) || '[]');
    const localStorage = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM) || '[]');
    return [
      ...localStorage.filter((w) => w.active),
      ...sessionStorage.filter(
        (w) => w.active && !localStorage.some((lsw) => lsw.active && w.view === lsw.view) && !!getValidWindow(w.window)
      ),
    ].map((w) => ({
      ...w,
      window: windows.find((ww) => (ww.view = w.view))?.window,
    }));
  };

  const detach = useCallback(
    (view, props = [null], toScreen = null) => {
      if (currentView !== 'core') return;
      if (!isFeatureFlagsLoaded || !isFeatureFlagEnabled('sonio.multiscreen')) return;
      if (view === 'core') return;

      setWindows((windows) => {
        const screenId = screens?.screens?.[toScreen || 0] ? toScreen || 0 : 0;
        const screen = screens?.screens?.[screenId] || defaultScreen;
        const windowUrl = `${window.location.origin}/window/${view}/${props.join('/')}`;

        const targetOpenWindow = windows.find(
          (w) => w.view === view && w.window?.name === `sonio-window-${view}` && getValidWindow(w.window)
        );

        if (targetOpenWindow) {
          /* Window already opened redirecting the window to the correct URL */
          targetOpenWindow.window?.postMessage({ url: windowUrl, view, props }, window.location.origin);
          targetOpenWindow.window?.focus();
          return windows;
        }

        /* Window was not opened. Let's open it */
        const localStorageWindow = getWindowFromLocalStorage(view);
        const windowLeft = localStorageWindow?.screenX || screen.left;
        const windowTop = localStorageWindow?.screenY || screen.top;
        const windowWidth =
          localStorageWindow?.outerWidth ||
          Math.round(screen.availWidth / (windows.filter((w) => w.active)?.length + 1));
        const windowHeight = localStorageWindow?.outerHeight || screen.availHeight;
        const options = `screenX=${screen.left},screenY=${screen.top},width=${windowWidth},height=${windowHeight},left=${windowLeft},top=${windowTop}`;
        const newWindow = window.open(windowUrl, `sonio-window-${view}`, options);
        if (!newWindow) console.error('Unable to open new window', [windowUrl, `sonio-window-${view}`, options]);
        newWindow?.focus();
        window.mypopup = newWindow;

        const newWindows = [
          ...windows.filter((w, index) => w.view !== view && windows.findIndex((ww) => ww.view === w.view) === index),
          {
            window: newWindow,
            view,
            active: true,
            screenX: windowTop,
            screenY: windowLeft,
            outerWidth: windowWidth,
            outerHeight: windowHeight,
          },
        ];

        return newWindows.sort((a, b) => (a.view < b.view ? -1 : 1));
      });
    },
    [windows, JSON.stringify(screens?.screens), currentView]
  );

  const refreshWindow = useCallback((view) => {
    if (windowExists(view)) {
      getWindowByView(view)?.location?.reload();
    }
  });

  const processMessages = useCallback(
    (message) => {
      if (message.origin !== window.location.origin) return;
      if (message.data.event === 'setPreventClosing' && currentView === 'core') {
        setWindows((windows) =>
          windows.map((w) => ({
            ...w,
            preventClosing: w.view === message.data.view ? message.data.value : w.preventClosing,
          }))
        );
      }
      if (message.data.event === 'detach' && currentView === 'core') {
        detach(message.data.view, message.data.props);
      }
      if (message.data.event === 'refreshInstanceImg') {
        document.querySelectorAll(`img[data-media-id="${message.data.mediaId}"]`).forEach((media) => {
          const mediaSrc = media.src;
          media.src = mediaSrc;
        });
      }
      if (message.data.event === 'refreshInstances') {
        examinationContext.loadInstances(examinationContext.examination);
      }
      if (message.data.event === 'refreshWindow' && currentView === 'core') {
        refreshWindow(message.data.view);
      }
    },
    [setWindows, detach]
  );

  useEffect(() => {
    window.addEventListener('message', processMessages);
    return () => {
      window.removeEventListener('message', processMessages);
    };
  }, [processMessages]);

  const postMessageToView = (view, message, autofocus = false) => {
    const targetOpenWindow =
      view === 'core'
        ? { window: window.opener ?? window }
        : windows.find((w) => w.view === view && getValidWindow(w.window));

    if (targetOpenWindow) {
      targetOpenWindow.window?.postMessage(message, window.location.origin);
      if (autofocus) targetOpenWindow.window?.focus();
      return true;
    }

    return false;
  };

  const getValidWindow = (w) => (w?.toString() === '[object Window]' && !w.closed ? w : false);

  const windowsToJSON = (windows) =>
    JSON.stringify(
      windows
        ?.map((w) => ({
          ...w,
          screenX: getValidWindow(w.window)?.screenX,
          screenY: getValidWindow(w.window)?.screenY,
          outerWidth: getValidWindow(w.window)?.outerWidth,
          outerHeight: getValidWindow(w.window)?.outerHeight,
          window: !!getValidWindow(w.window),
        }))
        .sort((a, b) => (a.view < b.view ? -1 : 1)) || '[]'
    );

  const heartBeat = useCallback(() => {
    if (preventClosing) return;
    if (currentView && currentView !== 'core') {
      if (
        !window.opener ||
        window.opener.closed ||
        !examinationContext.examination?.id ||
        !window.opener?.location.href.includes(window.location.origin) ||
        (currentView !== 'exam' &&
          !`${window.opener?.location.pathname}`.match(
            new RegExp(`^/exam/${examinationContext.examination?.id}([/#].*)?$`)
          ))
      ) {
        const parentExamId = `${window.opener?.location.pathname}`.match(`^/exam/([0-9]+)([/#].*)?$`);
        if (parentExamId?.[1]) {
          window.location.href = `/window/${currentView}/${parentExamId[1]}`;
        } else {
          window?.close();
        }
      }
    }
  }, [
    preventClosing,
    currentView,
    examinationContext.examination?.id,
    window.opener?.location.href,
    window.location.origin,
  ]);

  const closeAllWindows = useCallback(() => {
    for (const w of windows) {
      if (w.preventClosing) continue;
      if (w.window?.toString() === '[object Window]') w.window.close();
    }
  }, [windows]);

  const getWindowFromLocalStorage = (view) => {
    return (
      getFromStorage(window.localStorage)?.find((w) => w.view === view) ||
      getFromStorage(window.sessionStorage)?.find((w) => w.view === view)
    );
  };

  const getWindowByView = (view) => windows.find((w) => w.view === view)?.window;

  const saveConfig = useCallback(() => {
    const verifiedWindows = windows.map((w) => ({
      ...w,
      active: !!getValidWindow(w.window),
    }));
    setWindows(verifiedWindows);
    const json = windowsToJSON(verifiedWindows);
    window.localStorage.setItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM, json);
    window.sessionStorage.setItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM, json);
  }, [windows]);

  const cleanConfig = useCallback(() => {
    closeAllWindows();
    window.localStorage.setItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM, '[]');
    window.sessionStorage.setItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM, '[]');
    setWindows([]);
  }, [closeAllWindows, setWindows]);

  useEffect(() => {
    window.sessionStorage.setItem(LOCALSTORAGE_WINDOW_CONGIF_PARAM, windowsToJSON(windows));
  }, [windows]);

  const rebuildWindowsFromLocalStorage = () => {
    if (currentView !== 'core') return;
    for (const w of getAllActiveWindows()) {
      if (!['core', 'exam'].includes(w.view)) detach(w.view, predictProps(w.view));
    }
  };

  const predictProps = (view) => {
    switch (view) {
      case 'report':
      case 'anamnesis':
      case 'slide':
        return [examinationContext.examination?.id];
      default:
        return [];
    }
  };

  const showPreventClosingAlert = useCallback((e) => {
    e.returnValue = `Are you sure you want to leave?`; // this should be replaced by the browser with a default text
  }, []);

  useEffect(() => {
    postMessageToView('core', {
      event: 'setPreventClosing',
      value: preventClosing,
      view: currentView,
    });
    if (preventClosing) {
      window.addEventListener('beforeunload', showPreventClosingAlert, true);
    } else {
      window.removeEventListener('beforeunload', showPreventClosingAlert, true);
    }
  }, [preventClosing]);

  return (
    <WindowContext.Provider
      value={{
        windows,
        currentView,
        parentWindow: window.opener,
        isMultiscreen: multiscreenGranted,
        isDetached,
        isCompared,
        screens: screens,
        windowExists,
        detach,
        setPreventClosing,
        postMessageToView,
        refreshWindow,
        saveConfig,
        cleanConfig,
        savedConfig: getFromStorage(),
        hasConfig: !!getFromStorage().length,
        getWindowByView,
        getAllActiveWindows,
        rebuildWorkspace: rebuildWindowsFromLocalStorage,
      }}
    >
      {!window.opener && window.location.pathname.match(`/exam/`) && showMultiscreenPopup && (
        <SimplePopup>
          <div className="multiscreen-popup">
            <div>{__('navbar.multiscreen.askForPermission')}</div>
            <div>
              <Button label={__('CTA.cancel')} onClick={() => setShowMultiscreenPopup(false)} variant="outline" />
              &nbsp;
              <Button label={__('navbar.multiscreen.askForPermission.grant')} onClick={askForMultiscreenPermissions} />
            </div>
          </div>
        </SimplePopup>
      )}
      {children}
    </WindowContext.Provider>
  );
});
export const useWindow = () => useContext(WindowContext);

WindowContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
