export default function ReportTemplateImage({ props }) {
  const { align, src, width } = props;

  const style = {};
  if (width) style.width = width;

  return (
    <span className={`custom-image align-${align || ''}`}>
      <img src={src} style={style} />
    </span>
  );
}
