import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * If your page is accessed with an anchor tag: http://$host/$pagePath#$someAnchor and you
 * would like it to automatically focus on the element with id `$someAnchor` when the is
 * loaded, invoke useAnchor() at the top of your page.
 *
 * https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
 */
function useAnchorScroll() {
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change
}

export default useAnchorScroll;
