import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import FetusesTable from '../../../components/FetusesTable/FetusesTable';
import FetusSexHandler from '../../../components/MedicalHistoryInput/FetusSexHandler';
import PatientDocuments from './Documents';
import { AppContext } from '../../../context-providers/App';
import useAuth from '../../../context-providers/Auth';
import { ExaminationContext } from '../../../context-providers/Examination';
import { formatName, getNiceGestionalAgeFromDays } from '../../../services/examination';
import { formatDate } from '../../../utils';
import ResourceApi from '../../../services/resource';
import './PhenotypePanel.css';
import EpisodesDetails from './EpisodesDetails';
import Stakeholders from './Stakeholders';
import MedicalHistory from './MedicalHistory';
import NotToForget from './NotToForget';
import { PatientNotes } from './PatientNotes';

const PhenotypePanel = ({
  t: __,
  episode,
  trimester,
  checklistItems,
  medicalHistory,
  slides,
  slidesRiskFactors,
  checklistItemsRiskFactors,
  setOpenPopup,
}) => {
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const { isFeatureFlagEnabled, isFeatureFlagsLoaded } = useAuth();
  const [selectedRiskFactorId, setSelectedRiskFactorId] = useState(null);

  const { patient } = examinationContext;
  const dateFormat = appContext.preferences.date_format;
  const isAnonymousPatient = !patient || patient?.id === 0;

  const updatePatientNotes = (notes) => {
    ResourceApi.createBIEvent({
      event_type: 'patient_note_updated',
      examination_id: examinationContext?.examination?.id,
      page: 'prep_exam_page',
      notes: notes,
    });
    examinationContext.updatePatient(patient.id, { notes });
  };

  const refreshEpisodes = (patientId) => {
    return ResourceApi.listEpisodes(patientId)
      .then((res) => res.data.data)
      .catch((err) => {
        console.log(`Could not list episodes for ${patientId}: ${err}`);
        return [];
      });
  };

  /* Do not display anything if depeding on the FF */
  if (!isFeatureFlagsLoaded) return null;

  return (
    <>
      <div className="examination-live-phenotype-panel-container">
        <div className="examination-live-phenotype-panel-risk-phenotype">
          <div>
            <h2 className="section-title">{__('examinationReview.patient')}</h2>
            <div className="entry">
              <label>{__('examinationReview.patient.name')}</label>
              <span className="ph-no-capture">
                {patient?.name ? formatName(patient?.name).fullName : __('patients.anonymous')}
              </span>
            </div>

            {!!episode?.dob && (
              <div className="entry">
                <label>{__('examinationReview.patient.dob')}</label>
                <span className="ph-no-capture">
                  {patient?.dob ? formatDate(patient?.dob, dateFormat) : __('examinationReview.notProvided')}
                </span>
              </div>
            )}

            {!!episode?.conception_date && (
              <div className="entry">
                <label>{__('examinationReview.episode.conceptionDate')}</label>
                <span className="ph-no-capture">
                  {episode?.conception_date
                    ? formatDate(episode?.conception_date, dateFormat)
                    : __('examinationReview.notProvided')}
                </span>
              </div>
            )}

            {!!episode?.lmp_date && (
              <>
                <div className="entry">
                  <label>{__('examinationReview.episode.lmpDate')}</label>
                  <span className="ph-no-capture">
                    {episode?.lmp_date
                      ? formatDate(episode?.lmp_date, appContext.preferences.date_format)
                      : __('examinationReview.notProvided')}
                  </span>
                </div>
                <div className="entry">
                  <label>{__('examinationReview.episode.gestationalAge')}</label>
                  <span className="ph-no-capture">
                    {getNiceGestionalAgeFromDays(__, examinationContext?.dating?.value)}
                  </span>
                </div>
              </>
            )}

            <br />

            {!isAnonymousPatient && (
              <PatientNotes
                t={__}
                notes={patient?.notes}
                onChange={updatePatientNotes}
                maxHeight={16}
                showNotes={patient?.show_notes}
                placeholder={__('reportSidebar.notes.placeholder')}
                patientId={patient?.id}
                scrollToBottom
              />
            )}
          </div>

          {!!examinationContext.episode && (
            <div>
              <h2 className="section-title">{__('examination.episode')}</h2>
              <div className="outlined">
                <FetusSexHandler showFetusSexButton={false} />
              </div>
              {!isFeatureFlagEnabled('sonio.dx_v2') && (
                <>
                  <h6>{__('fetusesTable.fetuses')}</h6>
                  <FetusesTable
                    episode={examinationContext.episode}
                    examination={examinationContext.examination}
                    fetusSexVisibility={examinationContext.fetusSexVisibility}
                    updateExaminationFetus={examinationContext.updateExaminationFetus}
                    createEpisodeFetus={examinationContext.createEpisodeFetus}
                    updateEpisodeFetus={examinationContext.updateEpisodeFetus}
                    deleteEpisodeFetus={examinationContext.deleteEpisodeFetus}
                    canEdit={examinationContext.canEdit}
                  />
                </>
              )}
            </div>
          )}

          {!isAnonymousPatient && (
            <MedicalHistory
              medicalHistory={medicalHistory}
              currentLanguage={currentLanguage}
              onClickRisk={(risk) => {
                if (risk.is_risky) {
                  setSelectedRiskFactorId(selectedRiskFactorId ? null : risk.risk_factor_id);
                }
              }}
            />
          )}
          {!isAnonymousPatient && <PatientDocuments t={__} patient={patient} setOpenPopup={setOpenPopup} />}
        </div>

        <div className="examination-live-phenotype-panel-risk-factors">
          {!isAnonymousPatient && <Stakeholders setOpenPopup={setOpenPopup} />}

          {!isAnonymousPatient && isFeatureFlagEnabled('sonio.episode_details') && (
            <EpisodesDetails
              canEdit={examinationContext.canEdit}
              examinationId={examinationContext.examination.id}
              examinationTz={examinationContext.examination.site?.timezone || 'UTC'}
              patientId={examinationContext.examination.patient_id}
              refreshEpisodes={refreshEpisodes}
            />
          )}

          {isFeatureFlagEnabled('sonio.risk') && (
            <NotToForget
              medicalHistory={medicalHistory}
              currentLanguage={currentLanguage}
              trimester={trimester}
              checklistItems={checklistItems}
              slides={slides}
              slidesRiskFactors={slidesRiskFactors}
              checklistItemsRiskFactors={checklistItemsRiskFactors}
              selectedRiskFactorId={selectedRiskFactorId}
              resetSelectedRiskFactorId={() => setSelectedRiskFactorId(null)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(PhenotypePanel);
