import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../atoms/Button/Button';
import './FallbackPage.css';

function FallbackPage({ t: __, i18n }) {
  return (
    <div className="fallback-container">
      <h1>{__('fallback.message')}</h1>
      <h5>{__('fallback.innerMessage')}</h5>
      <Button label={__('fallback.button')} icon="reload" onClick={() => (window.location = '/')} />
    </div>
  );
}

export default withTranslation()(FallbackPage);
