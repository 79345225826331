import { withTranslation } from 'react-i18next';
import { getNiceGestionalAgeFromDays } from '../../../../services/examination';
import { formatYYYYMMDDDate } from '../../../../utils';
import Icon from '../../../../atoms/Icon/Icon';
import { useEffect, useState } from 'react';
import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';
import { getPercentileValueString } from '../../../../services/measurements';

const ReportFetalGrowthTable = ({
  t: __,
  props,
  placeholders,
  getPlaceholderWithProps,
  reportData,
  appPreferences,
  reportMode,
  onEndEditing = () => {},
}) => {
  if (!placeholders) return false;

  const { 'column-labels': columnLabels, measurements: userMeasurementsToDisplay, title } = props;

  const defaultColumnLabels = [
    __('report.fetalGrowth.examDate'),
    __('report.fetalGrowth.ga'),
    __('report.fetalGrowth.bpd'),
    __('report.fetalGrowth.hc'),
    __('report.fetalGrowth.ac'),
    __('report.fetalGrowth.fl'),
    __('report.fetalGrowth.hl'),
    __('report.fetalGrowth.efw'),
  ];

  const measurementsToDisplay = ['bpd', 'hc', 'ac', 'fl', 'hl', 'efw'];

  const columnLabelsArray = columnLabels?.split('|') || [];
  for (let i = 0; columnLabelsArray[i]; i++) {
    defaultColumnLabels[i] = columnLabelsArray[i];
  }

  const userMeasurementsArray = userMeasurementsToDisplay?.split('|') || [];
  for (let i = 0; userMeasurementsArray[i]; i++) {
    measurementsToDisplay[i] = userMeasurementsArray[i];
  }

  const numberOfFetuses = getPlaceholderWithProps({ data: 'patient.nb_fetuses' })?.value || 1;

  const curveSlugs = Array(numberOfFetuses)
    .fill()
    .reduce((acc, _, idx) => {
      const fetus = idx + 1;
      acc[fetus] = {};
      for (const measurement of measurementsToDisplay) {
        const [slug] = measurement?.split('.');
        const placeholder = placeholders?.[`measurement.${slug}`]?.[fetus] || {};
        const curveSlug = placeholder?.curve_slug ?? measurement ?? placeholder?.availableCurveSlugs?.[0];
        acc[fetus][slug] = curveSlug;
      }
      return acc;
    }, {});

  const getDataFromPreviousExams = (fetus) => {
    const previousExams = reportData?.previous_exams || {};

    return Object.entries(previousExams || {})
      .filter(
        ([_, { examination_date }]) =>
          new Date(examination_date?.split('T')?.[0]) <= new Date(placeholders?.['examination.date']?.value)
      )
      .sort((a, b) => new Date(a[1].examination_date) - new Date(b[1].examination_date))
      .map(([_examId, { examination_date, measurements, dating }], idx) => {
        const data = measurements?.measurements?.fetus?.[fetus];
        const vals = measurementsToDisplay.map((measurement) => {
          const [slug] = measurement.split('.');
          const { y: value, percentiles } = data?.[slug]?.selected_value || {};
          const curveSlug = curveSlugs?.[fetus]?.[slug];
          const percentile = percentiles?.[curveSlug]?.percentile;
          return { value, percentile, slug };
        });
        return [{ value: examination_date, slug: 'examination_date' }, { value: dating?.value, slug: 'ga' }, ...vals];
      });
  };

  const getDataFromCurrentExam = (fetus) => {
    const data = measurementsToDisplay.map((measurement) => {
      const [slug] = measurement?.split('.');
      const placeholder = placeholders?.[`measurement.${slug}`]?.[fetus] || {};
      const curveSlug = curveSlugs?.[fetus]?.[slug];
      return { slug, percentile: placeholder?.percentiles?.[curveSlug]?.percentile, value: placeholder?.value };
    });

    return [
      { ...placeholders['examination.date'], slug: 'examination_date' },
      { ...placeholders['ga.assigned.value'], slug: 'ga' },
      ...data,
    ];
  };

  const getDataFromAllExams = () => {
    const data = {};
    for (let fetus = 1; fetus <= (numberOfFetuses || 1); fetus++) {
      data[fetus] = [...getDataFromPreviousExams(fetus), getDataFromCurrentExam(fetus)];
    }
    return data;
  };

  const dataFromAllExams = getDataFromAllExams();

  const createDefaultTableMetadata = () => {
    const data = {};
    const numExams = Object.keys(reportData?.previous_exams || {}).length + 1;
    for (let fetus = 1; fetus <= (numberOfFetuses || 1); fetus++) {
      data[`fetus-${fetus}`] = new Array(numExams).fill().map((_, i) => {
        const visible = (dataFromAllExams[fetus][i] || [])
          .filter((elem) => measurementsToDisplay.some((measurement) => measurement.startsWith(elem.slug)))
          .some((elem) => elem.value);
        return { visible };
      });
    }
    return data;
  };

  const defaultTableMetadata = placeholders?.fetal_growth || createDefaultTableMetadata();
  const [tableMetadata, setTableMetadata] = useState(defaultTableMetadata);

  useEffect(() => {
    if (!placeholders?.fetal_growth) setTableMetadata(createDefaultTableMetadata());
  }, [numberOfFetuses, JSON.stringify(placeholders)]);

  // If no table is visible hide the Title
  const titleVisible = Object.values(tableMetadata || {}).some((rows) => rows?.some((row) => row.visible));

  const onClickVisible = (id) => () => {
    const data = JSON.parse(JSON.stringify(tableMetadata));
    if (id.startsWith('row')) {
      const [, fetus, row] = id.split('-');
      const fetusId = `fetus-${fetus}`;
      data[fetusId][row].visible = !data[fetusId][row].visible;
    } else {
      const newVisibleValue = !data[id].some((row) => row.visible);
      data[id] = data[id].map((_) => ({ visible: newVisibleValue }));
    }

    // Optimistic update
    setTableMetadata(data);
    onEndEditing('fetal_growth', data);
  };

  return (
    <div className="fetal-growth-table-container">
      <h4
        className={`fetal-growth-title ${titleVisible ? 'is-visible is-printable' : 'not-visible not-printable'}`}
        style={{}}
      >
        {title}
      </h4>
      {Array(numberOfFetuses)
        .fill()
        .map((_, idx) => {
          const fetus = idx + 1;
          const tableId = `fetus-${fetus}`;
          const tableVisible = tableMetadata?.[tableId]?.some((row) => row.visible);

          return (
            dataFromAllExams[fetus].some((d) => d.some((dd) => dd.value)) && (
              <div className={`fetal-growth ${tableVisible ? 'is-visible' : 'not-visible'}`} key={fetus}>
                <div className="fetal-growth-report-options-container">
                  <div>
                    {numberOfFetuses > 1 && (
                      <div className="fetal-growth-fetus-label">
                        {__('report.fetusName', { label: placeholders['fetus.name']?.value[fetus] })}
                      </div>
                    )}
                  </div>
                  {tableVisible && reportMode === 'edit' && (
                    <div className="content-options exam-report-editing-options">
                      <div onClick={onClickVisible(tableId)}>
                        <Icon name={tableVisible ? 'eye' : 'eye-off'} />
                      </div>
                    </div>
                  )}
                </div>
                <div className="fetal-growth-table">
                  <div className={`fetal-growth-item column-heading`}>
                    {defaultColumnLabels.map((label, index) => {
                      return <div key={index}>{label || <>&nbsp;</>}</div>;
                    })}
                  </div>
                  {dataFromAllExams[fetus].map((data, index) => {
                    return (
                      <FetalGrowthItem
                        key={index}
                        defaultKey={index}
                        data={data}
                        rowMetadata={tableMetadata?.[tableId] || []}
                        appPreferences={appPreferences}
                        onClickVisible={onClickVisible}
                        fetus={fetus}
                      />
                    );
                  })}
                </div>
                {!tableVisible && reportMode === 'edit' && <NotVisibleOverlay onClick={onClickVisible(tableId)} />}
              </div>
            )
          );
        })}
    </div>
  );
};

export default withTranslation()(ReportFetalGrowthTable);

const FetalGrowthCell = withTranslation()(({ t: __, data, appPreferences }) => {
  let { value, percentile, slug } = data || {};
  switch (slug) {
    case 'ga':
      value = getNiceGestionalAgeFromDays(__, Math.floor(value));
      percentile = false;
      break;
    case 'examination_date':
      value = formatYYYYMMDDDate(value, appPreferences?.date_format);
      percentile = false;
      break;
    case 'efw':
      value = (isNaN(value) ? value : value.toFixed(0)) ?? '-';
      percentile = getPercentileValueString(percentile, __);
      break;
    default:
      value = (isNaN(value) ? value : value.toFixed(1)) ?? '-';
      percentile = getPercentileValueString(percentile, __);
      break;
  }

  return (
    <div className="fetal-growth-cell">
      <span className="fetal-growth-cell-value">{value}</span>
      {percentile && <span className="fetal-growth-cell-seperator">|</span>}
      <span className="fetal-growth-cell-percentile">{percentile}</span>
    </div>
  );
});

const FetalGrowthItem = withTranslation()(
  ({ t: __, data: allData, appPreferences, rowMetadata, defaultKey = '', onClickVisible, fetus }) => {
    // Don't show row if all values are empty
    if (!allData.some((d) => !['examination_date', 'ga'].includes(d.slug) && d.value)) return null;

    const rowId = `row-${fetus}-${defaultKey}`;

    return (
      <div
        key={defaultKey}
        className={`fetal-growth-item ${rowMetadata?.[defaultKey]?.visible ? 'is-visible' : 'not-visible'}`}
      >
        {allData.map((data, index) => {
          return <FetalGrowthCell data={data} key={index} appPreferences={appPreferences} />;
        })}
        <div className="content-options exam-report-editing-options">
          <div onClick={onClickVisible(rowId)}>
            <Icon name={rowMetadata?.[defaultKey]?.visible ? 'eye' : 'eye-off'} />
          </div>
        </div>
      </div>
    );
  }
);
