import { useState } from 'react';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import { useXMLTemplate } from '../../context-providers/XMLTemplate';

const QuickReportEditor = ({ openChapter, setOpenChapter }) => {
  const [template, setTemplate] = useState('');
  const { evaluateAutomationTemplate, generateAutomationTemplate } = useXMLTemplate();

  return (
    <div className={`debug-section ${openChapter === 'quick-report-editor' ? 'open' : 'closed'}`}>
      <h2
        onClick={() => setOpenChapter((chapter) => (chapter === 'quick-report-editor' ? false : 'quick-report-editor'))}
      >
        Quick Reports Editor
        <Icon name="down" />
      </h2>
      {openChapter === 'quick-report-editor' && (
        <>
          <textarea
            style={{ color: '#000' }}
            rows={40}
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          />
          <Button variant="outline" label="Clear" onClick={() => setTemplate('')} />
          <Button
            variant="contained"
            label="Apply"
            onClick={() => evaluateAutomationTemplate(template, { type: 'automation_template', slug: 'debug-panel' })}
          />
          <Button variant="contained" label="Generate" onClick={() => generateAutomationTemplate(setTemplate)} />
        </>
      )}
    </div>
  );
};

export default QuickReportEditor;
