import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './ConfirmDialog.css';
import { useReportDialog } from '../../../context-providers/ReportDialogProvider';
import { useXMLTemplate } from '../../../context-providers/XMLTemplate';
import Button from '../../../atoms/Button/Button';

const ConfirmFlashReportDialog = ({ onConfirm, onClose, content, hideContent, showOnce, t: __ }) => {
  const [boxValue, setBoxValue] = useState(false);

  const { closeModal } = useReportDialog();
  const { editReportBackup, setReportData, setIsEditMode, setAutomationTemplateFieldsVisible } = useXMLTemplate();

  const onCloseModal = () => {
    closeModal();
    onClose && onClose();
  };

  const confirmChanges = () => {
    onConfirm && onConfirm();
    if (showOnce && boxValue) {
      window.localStorage.setItem(showOnce, 'true');
    }
    setIsEditMode(false);
    setAutomationTemplateFieldsVisible(false);
    setReportData && setReportData(editReportBackup);
    closeModal();
  };

  return (
    <div className="confirm-dialog-root">
      <div className="confirm-dialog-title">{__('reportDialog.confirm.title')}</div>
      {!hideContent && (
        <div className="confirm-dialog-content">{content || `${__('reportDialog.confirm.unsave')}`}</div>
      )}
      {showOnce && (
        <div className="confirm-dialog-checkbox">
          <input type="checkbox" onChange={(event) => setBoxValue(event.target.checked)} />
          &nbsp;{__('reportDialog.confirm.noShow')}
        </div>
      )}
      <div className="confirm-dialog-buttons">
        <Button variant="outline" onClick={onCloseModal} label={__('reportDialog.confirm.cancel')} />
        <Button onClick={confirmChanges} label={__('reportDialog.confirm.submit')} />
      </div>
    </div>
  );
};

export default withTranslation()(ConfirmFlashReportDialog);
