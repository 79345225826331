import ReportTemplateTextBlockBody from '../TextBlock/v2.0';
import { placeholderIdFromProps } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';
import {
  convertElement as convertDynamicElement,
  dynamicPlaceholderIds,
  renderDynamicContent,
} from '../../dynamicMacros';
import { computeValueFromHtml as defaultComputeValueFromHtml } from '../../inlineTextArea';
import {
  isDynamicElement,
  dynamicElementToValue,
  sanitize,
  DynamicValue,
  PrintableDynamicValue,
} from './DynamicElement';

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateDynamicTextBlock({ props, placeholders, ...otherProps }) {
  const fieldId = placeholderIdFromProps(props);
  const placeholder = placeholders[fieldId];
  const dynamicFieldIds = placeholder ? dynamicPlaceholderIds(placeholder, otherProps?.multiSelectValue) : [];

  const renderStaticElement = (raw) => raw;
  const renderDynamicElement = (raw, attributes) => {
    const placeholder = new PlaceholdersHelper({
      props: attributes,
      placeholders,
      apiVersion: otherProps.apiVersion,
      appPreferences: otherProps.appPreferences,
      reportDataOptions: otherProps.reportDataOptions,
    });

    return <DynamicValue placeholder={placeholder} attributes={attributes} raw={raw} />;
  };

  const renderDynamicElementForPrint = (raw, attributes) => {
    const placeholder = new PlaceholdersHelper({
      props: attributes,
      placeholders,
      apiVersion: otherProps.apiVersion,
      appPreferences: otherProps.appPreferences,
      reportDataOptions: otherProps.reportDataOptions,
    });
    return <PrintableDynamicValue placeholder={placeholder} attributes={attributes} raw={raw} />;
  };

  const renderContentForPrint = (content) => renderDynamicContent(content, renderDynamicElementForPrint);
  const renderContent = (content) => renderDynamicContent(content, renderDynamicElement, renderStaticElement, sanitize);

  const onClick = (e, contentEditable) => {
    if (isDynamicElement(e.target)) {
      const parent = contentEditable.current;
      parent.focus();
      const nextSibling = e.target.nextSibling;
      const sel = window.getSelection();
      const range = document.createRange();
      if (nextSibling) {
        range.setStart(nextSibling, 0);
      } else {
        range.selectNodeContents(parent);
        range.collapse(false);
      }
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const computeValueFromHtml = (html) =>
    defaultComputeValueFromHtml(html, (element) =>
      convertDynamicElement(element, {
        isDynamicElement,
        dynamicElementToValue,
      })
    );
  const textAreaProps = {
    contentEditableProps: {
      sanitize,
    },
    computeHtmlFromValue: renderContent,
    computeValueFromHtml: computeValueFromHtml,
    onClick,
  };

  return (
    <PlaceholderLoader
      Component={ReportTemplateTextBlockBody}
      placeholders={placeholders}
      requiredPlaceholders={[fieldId, 'fetus.order', 'examination.date', ...dynamicFieldIds]}
      textAreaProps={textAreaProps}
      renderContentForPrint={renderContentForPrint}
      props={props}
      {...otherProps}
    />
  );
}
