import { useMemo } from 'react';
import Coding from './Coding';
import SectionsNavBar from '../../../components/SectionsNavBar/SectionsNavBar';

function ReportTemplateEditDocument({ children, props, page, codingProps, frozenHTML, renderMode }) {
  const { theme = 'basic', country = 'US' } = props;

  const childrenWithSections = useMemo(
    () =>
      Array.isArray(children) && renderMode !== 'freeze'
        ? children.map((child, index) => {
            if (child?.props?.type === 'section') {
              const select = <ReportTemplateSection index={index} key={index} children={child} {...child.props} />;
              return select;
            }
            return child;
          })
        : children,
    [children, renderMode]
  );

  const chapters = useMemo(
    () =>
      Array.isArray(childrenWithSections) && renderMode !== 'freeze'
        ? childrenWithSections
            .map((child, index) =>
              child?.props?.type === 'section'
                ? {
                    child: child,
                    icon: child.props.props.icon,
                    title: child.props.props.name,
                    description: '',
                    index,
                    targetClass: `.template-section-${index}` /* TODO: find a way to manage this by using refs */,
                  }
                : false
            )
            .filter((chapter) => chapter)
        : [],
    [childrenWithSections, renderMode]
  );

  return frozenHTML ? (
    <>
      {!!chapters.length && <SectionsNavBar options={[]} />}
      <div
        dangerouslySetInnerHTML={{
          __html: frozenHTML,
        }}
      />
    </>
  ) : (
    <>
      {!!chapters.length && <SectionsNavBar options={chapters} />}
      <div className="exam-report-template" data-theme={theme} data-country={country}>
        {childrenWithSections}
        {!!codingProps && page === 'report' && <Coding {...codingProps} />}
      </div>
    </>
  );
}

function ReportTemplateSection({ children, index }) {
  return <section className={`template-section template-section-${index}`}>{children}</section>;
}

function ReportTemplatePrintDocument({ children, props }) {
  const {
    theme,
    country,
    'text-color': textColor,
    'heading-color': headingColor,
    'heading-background': headingBackground,
    'warning-color': warningColor,
  } = props;

  const getDocumentStyle = () => {
    const style = [];

    if (textColor) style.push(`--text-color: ${textColor};`);
    if (headingColor) style.push(`--heading-color: ${headingColor};`);
    if (headingBackground) style.push(`--heading-background: ${headingBackground};`);
    if (warningColor) style.push(`--warning-color: ${warningColor};`);

    return `.exam-report-template {${style.join('')}}`;
  };

  return (
    <>
      <style>{getDocumentStyle()}</style>
      <div
        className={`exam-report-template print ${
          headingBackground?.toLowerCase() === 'transparent' ? 'transparent-heading-background' : ''
        }`}
        data-theme={theme}
        data-country={country}
      >
        {children}
      </div>
    </>
  );
}

export default function ReportTemplateDocument({ reportMode, children, ...props }) {
  if (reportMode === 'edit')
    return (
      <ReportTemplateEditDocument {...props} reportMode={reportMode}>
        {children}
      </ReportTemplateEditDocument>
    );
  if (reportMode === 'print')
    return (
      <ReportTemplatePrintDocument {...props} reportMode={reportMode}>
        {children}
      </ReportTemplatePrintDocument>
    );
  return `Mode ${reportMode} not supported`;
}
