import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import Icon from '../../atoms/Icon/Icon';
import { presets as presetsConfig } from '../../config';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import './ExamPresetButtons.css';

/**
 * <ExamPresetButtons
 *  value = (string)
 *  action = false | "new-exam" | "change-preset" // the default action to perform on changing
 *  label = (string)
 *  size = (string)
 *  wrap = (boolean)
 *  variant = (string)
 *  collapse = (boolean)
 *  theme = (string) "" | "light" | "grey"
 *  onChange = () => {}
 * >
 */

// TODO: scroll nowrap containers when button group is too large

const ExamPresetButtons = ({
  value = false,
  action = false,
  label = '',
  size = '',
  wrap = true,
  variant = '',
  theme = '',
  collapse = false,
  onChange = () => {},
  stayExpanded = false,
}) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const [collapsed, setCollapsed] = useState(collapse);
  const [containerStyle, setContainerStyle] = useState({});

  const containerRef = useRef(false);

  useEffect(() => setCollapsed(collapse), [collapse]);

  const startNewExamination = useCallback(
    async (trimester = 'ND', presetId = presetsConfig.NDid) => {
      examinationContext.createDraftExam(null, trimester, presetId);
    },
    [examinationContext.createDraftExam]
  );

  const onChangeButtonGroup = useCallback(
    (value) => {
      if (action === 'new-exam') {
        startNewExamination(
          appContext.allPresets?.find((preset) => preset.id === parseInt(value))?.trimester,
          parseInt(value)
        );
      } else if (action === 'change-preset') {
        examinationContext.setPreset(value);
      }

      if (typeof onChange === 'function') onChange(value);
    },
    [appContext.allPresets, value, action, examinationContext.setPreset, startNewExamination]
  );

  const realValue = useMemo(() => {
    if (value) return value;
    return action === 'new-exam' ? '' : examinationContext.examination.preset_id?.toString();
  }, [value, examinationContext.examination.preset_id]);

  const availableOptions = useMemo(() => {
    return appContext.displayedPresets?.map((presetId) =>
      Number(presetId) === Number(presetsConfig.NDid)
        ? { presetId: Number(presetId), icon: 'ultrasound', value: presetId.toString() }
        : {
            presetId: Number(presetId),
            label: appContext.allPresets?.find((preset) => preset.id === presetId)?.name,
            value: presetId.toString(),
          }
    );
  }, [appContext.allPresets, appContext.displayedPresets]);

  const realOptions = useMemo(() => {
    return collapse && collapsed
      ? availableOptions?.filter((option) => option.presetId === Number(realValue))
      : availableOptions;
  }, [realValue, availableOptions, collapsed]);

  const recalculateWidth = useCallback(() => {
    if (!collapse || !containerRef.current) return false;

    const content = containerRef.current.childNodes;
    let width = 0;
    for (const elm of content) {
      width += elm.scrollWidth;
    }
    setContainerStyle({ width: width + 'px' });
  }, [collapse, containerRef.current, JSON.stringify(realOptions)]);

  useEffect(() => {
    window.addEventListener('resize', recalculateWidth, true);
    return () => {
      window.removeEventListener('resize', recalculateWidth);
    };
  }, [collapsed, recalculateWidth]);

  useEffect(() => {
    recalculateWidth();
  }, [recalculateWidth]);

  const showExpandButton = (stayExpanded || (collapse && collapsed)) && availableOptions?.length >= 2;

  return (
    <div
      className={`new-exam-buttons-container theme-${theme} variant-${variant} ${wrap ? 'wrap' : ''}`}
      ref={containerRef}
      style={containerStyle}
    >
      <ButtonGroup
        value={realValue}
        label={label}
        size={size}
        variant={variant}
        theme={theme}
        wrap={wrap}
        options={realOptions}
        testId="exam-protocol-button"
        onChange={(value) => {
          if (collapse && collapsed) {
            setCollapsed(false);
          } else {
            setCollapsed(true);
            onChangeButtonGroup(value);
          }
        }}
      />
      {showExpandButton && (
        <div className="new-exam-buttons-expand" onClick={() => setCollapsed(!collapsed)}>
          <Icon name={collapsed ? 'right' : 'left'} />
        </div>
      )}
    </div>
  );
};

export default ExamPresetButtons;
