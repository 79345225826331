import React, { useState, useContext, useEffect, useRef } from 'react';
import { AmbientListeningContext } from '../../context-providers/AmbientListening';
import Icon from '../../atoms/Icon/Icon';
import TextArea from '../../atoms/TextArea/TextArea';
import Button from '../../atoms/Button/Button';
import { jsonParse } from '../../utils';

export default function Report({ examination, openChapter, setOpenChapter }) {
  if (examination == null) return <div>No open exams</div>;

  return (
    <div className={`debug-section ${openChapter === 'ambient-listening' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'ambient-listening' ? false : 'ambient-listening'))}>
        Ambient listening <Icon name="down" />
      </h2>
      {openChapter === 'ambient-listening' && <ReportContent />}
    </div>
  );
}

function ReportContent() {
  const [ambientListeningInputModified, setAmbientListeningInputModified] = useState(false);
  const [ambientListeningChangesModified, setAmbientListeningChangesModified] = useState(false);

  const ambientListeningContext = useContext(AmbientListeningContext);
  const audioRef = useRef(null);
  const [downloadURL, setDownloadURL] = useState(null);
  const [uploadIsOpen, setUploadIsOpen] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    if (audioRef.current && !!ambientListeningContext.chunks?.length) {
      const blob = new Blob(ambientListeningContext.chunks, { type: AmbientListeningContext.chunks?.[0]?.type });
      const audioURL = window.URL.createObjectURL(blob);
      audioRef.current.src = audioURL;
      setDownloadURL(audioURL);
      if (ambientListeningContext.sessionId) setSessionId(ambientListeningContext.sessionId);
    }
  }, [ambientListeningContext.chunks]);

  return (
    <div className="debug-subsection">
      <div>
        <label>Last Sentence Recording</label>
      </div>
      <div className="debug_audioplayer">
        <audio controls="controls" ref={audioRef} />
        <div>
          <small>{ambientListeningContext.isSpeaking ? '🗣 Speaking' : 'Not speaking'}</small>
        </div>
      </div>
      {!!downloadURL && (
        <div>
          <small>
            <a href={downloadURL} download={sessionId || 'download'}>
              Download audio file
            </a>
          </small>
        </div>
      )}
      {!uploadIsOpen && (
        <div>
          <small>
            <a
              onClick={(e) => {
                e.preventDefault();
                setUploadIsOpen(true);
              }}
            >
              Upload wav file(s)
            </a>
          </small>
        </div>
      )}

      {uploadIsOpen && (
        <div className="outlined">
          <div>
            <label>Send custom WAV chunks</label>
          </div>
          <div className="debug_audioplayer">
            <input
              type="file"
              multiple="multiple"
              onChange={(e) => {
                const files = [...e.target?.files];
                if (files.length) {
                  ambientListeningContext.processChunks(files);
                }
                e.target.value = '';
              }}
            />
            <span onClick={() => setUploadIsOpen(false)}>
              <Icon name="close" />
            </span>
          </div>
        </div>
      )}

      <div>
        <label>Transcript</label>
      </div>
      <div>
        <TextArea
          fullwidth="true"
          value={ambientListeningContext.transcript}
          onChange={(value) => {
            setAmbientListeningInputModified(true);
            ambientListeningContext.setTranscript(value);
          }}
        />
        <Button
          color="common"
          icon="reload"
          size="small"
          label={!ambientListeningInputModified ? 'Sent' : 'Send'}
          variant={!ambientListeningInputModified ? 'outline' : ''}
          onClick={() => {
            setAmbientListeningInputModified(false);
            ambientListeningContext.applyTranscriptToReport();
          }}
        />
      </div>

      <div>
        <label>JSON Response {sessionId ? <>(ID {sessionId})</> : false}</label>
      </div>
      <div>
        <TextArea
          fullwidth="true"
          value={
            typeof ambientListeningContext.reportChanges === 'object'
              ? JSON.stringify(ambientListeningContext.reportChanges)
              : ambientListeningContext.reportChanges
          }
          onChange={(value) => {
            setAmbientListeningChangesModified(true);
            ambientListeningContext.setReportChanges(jsonParse(value));
          }}
        />
        <Button
          color="common"
          icon="reload"
          size="small"
          label={!ambientListeningChangesModified ? 'Applied to the report' : 'Apply to the report'}
          variant={!ambientListeningInputModified ? 'outline' : ''}
          onClick={() => {
            setAmbientListeningChangesModified(false);
            ambientListeningContext.applyChangesToReport();
          }}
        />
      </div>
    </div>
  );
}
