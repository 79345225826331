import { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { formatName } from '../../services/examination';
import { formatDate } from '../../utils';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';
import parse from 'html-react-parser';

const extraPageCount = (slides, perPage, qrEnabled) => {
  if (!qrEnabled) return 0;
  const remainder = slides % perPage;
  if (remainder === 0) return 1;
  if (perPage === 2) return (remainder > 1 && 1) || 0;
  if (perPage === 8) return (remainder > 6 && 1) || 0;
  if (perPage === 15) return (remainder > 12 && 1) || 0;
  return 0;
};

const chunk = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunk(tail, size)];
};

const ExamPagedPrint = ({
  t: __,
  printingPreferenceImgPerPage,
  annotateToggle,
  anonymizeToggle,
  printOnlyQr = true,
  headerPlaceholderCount, // ?
  instancesToPrint,
}) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { user } = useAuth();
  const { site } = appContext;
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const isSet = (x) => x && x !== '';
  const showHeader = isSet(site?.header_image_base64);
  const showMessage = isSet(site?.brand_message);

  const InstanceView = ({ instance }) => {
    let imgWidth = 48;
    let imgHeight = 21;

    if (printingPreferenceImgPerPage === 2) {
      imgWidth = 98;
      imgHeight = 42;
    } else if (printingPreferenceImgPerPage === 15) {
      imgWidth = 32;
      imgHeight = 16;
    }

    // We do not want to show the label for "other" type instances on the print
    const view = examinationContext.instanceViews.find(
      (view) => view?.id === instance.instance_view_id && view?.type !== 'other'
    );
    const imageBlob = examinationContext.instancePreviewBlobs[instance.dicom_instance_id];
    return (
      <div className="img-item" style={{ width: imgWidth + '%', height: imgHeight + 'vh' }}>
        <div className={`img-container ${anonymizeToggle ? 'anonymized' : ''}`}>
          {imageBlob && <img src={URL.createObjectURL(imageBlob)} alt="instance" />}
        </div>
        {annotateToggle && (
          <div className="label-container">
            <p>{view?.label[currentLanguage] || <>&nbsp;</>}</p>
          </div>
        )}
      </div>
    );
  };
  const ShallowInstanceView = () => {
    let imgWidth = 48;
    let imgHeight = 21;

    if (printingPreferenceImgPerPage === 2) {
      imgWidth = 99;
      imgHeight = 46;
    } else if (printingPreferenceImgPerPage === 15) {
      imgWidth = 32;
      imgHeight = 16;
    }

    return <div className="img-item" style={{ width: imgWidth + '%', height: imgHeight + '%' }}></div>;
  };

  const Header = () => {
    return (
      <div className="page-header-container">
        {showHeader && (
          <div className="page-header">
            <img src={`data:image/png;base64, ${site.header_image_base64}`} />
          </div>
        )}
        {showMessage && <div className="page-header-message common-message">{parse(site.brand_message)}</div>}
      </div>
    );
  };
  const Footer = ({ currentPage, totalPages }) => {
    const date = examinationContext?.examination?.examination_date;
    const examDate = (date && formatDate(date)) || '';
    const practitionerName = user?.title;
    const patientFirstName = formatName(examinationContext?.examination?.patient?.name).firstName || '';
    const patientDOB = formatDate(examinationContext?.patient?.dob);

    const showPage = totalPages > 1;

    return (
      <div className="page-footer new-footer">
        <div className="footer-block">
          <div className="footer-line">
            <div>
              {site?.name && <span className="part site-name">{site?.name}</span>}
              {practitionerName && <span className="part">{practitionerName}</span>}
              {examDate && <span className="part">{examDate}</span>}
            </div>
            {showPage && (
              <div className="pagination">
                Page {currentPage}/{totalPages}
              </div>
            )}
          </div>
          <div className="footer-line">
            <div>
              {patientFirstName && <span className="part patient-name">{patientFirstName}</span>}
              {patientDOB && <span className="part">{patientDOB}</span>}
            </div>
            <div className="powered-by">
              {__('branding.poweredBy')}
              <img height="1rem" width="4rem" src="/logo-full.svg" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const FullSlide = ({ children, currentPage, totalPages }) => {
    return (
      <div className="print-preview-page">
        {currentPage === 1 && <Header />}
        {children}
        <Footer currentPage={currentPage} totalPages={totalPages} />
      </div>
    );
  };
  const qrCodeContent =
    examinationContext.share?.share_link == null ? (
      <></>
    ) : (
      <div className={printOnlyQr ? 'single-qr-code' : 'footer-qr-code'} key="qr-code">
        <QRCodeSVG
          value={`${examinationContext.share.share_link}&source=qr`}
          size={120}
          level="Q"
          includeMargin={true}
          imageSettings={{
            src: '/logo192.png',
            x: null,
            y: null,
            height: 22,
            width: 22,
          }}
        />
        <p>{__('examinationReview.qrCode.label')}</p>
      </div>
    );

  const QrOnlyContent = ({ totalPages }) => {
    return (
      <FullSlide currentPage={totalPages} totalPages={totalPages}>
        <div className="qr-only-content">
          <div>{qrCodeContent}</div>
        </div>
      </FullSlide>
    );
  };
  const MaybePrintQrSection = () => {
    if (examinationContext.includeQRCode === false) return null;
    return <div className="qr-row">{qrCodeContent}</div>;
  };
  const MaybePrintQrPage = ({ totalPages }) => {
    if (examinationContext.includeQRCode === false) return null;
    return <QrOnlyContent totalPages={totalPages} />;
  };
  const LastSlideContent = ({ elements, currentPage, totalPages }) => {
    let qrThreshold = 7;
    if (printingPreferenceImgPerPage === 2) qrThreshold = 2;
    else if (printingPreferenceImgPerPage === 15) qrThreshold = 13;

    if (currentPage === 1) {
      qrThreshold -= headerPlaceholderCount;
    }

    const renderShallowElements = (count) => {
      const list = [];
      for (let i = 0; i < count; i++) {
        list.push(<ShallowInstanceView key={'shallow' + i} />);
      }
      return list;
    };

    const className = 'slides-content last-page' + printingPreferenceImgPerPage;

    if (elements.length && elements.length < qrThreshold) {
      return (
        <FullSlide key="last-page" currentPage={currentPage} totalPages={totalPages}>
          <div className={className}>
            {elements.map((i) => (
              <InstanceView instance={i} key={`slide-${i.dicom_instance_id}`} />
            ))}
            {renderShallowElements(qrThreshold - elements.length - 1)}
            <MaybePrintQrSection />
          </div>
        </FullSlide>
      );
    }
    return (
      <>
        <FullSlide key="last-page" currentPage={currentPage} totalPages={totalPages}>
          <div className="slides-content">
            {elements.map((i) => (
              <InstanceView instance={i} key={`slide-${i.dicom_instance_id}`} />
            ))}
          </div>
        </FullSlide>
        <MaybePrintQrPage totalPages={totalPages} />
      </>
    );
  };
  const OnlySlidesContent = ({ elements, currentPage, totalPages }) => {
    return (
      <FullSlide currentPage={currentPage} totalPages={totalPages}>
        <div className="slides-content">
          {elements.map((i) => (
            <InstanceView instance={i} key={`slide-${i.dicom_instance_id}`} />
          ))}
        </div>
      </FullSlide>
    );
  };
  const SlidesContent = () => {
    if (printingPreferenceImgPerPage == null) return null;

    const allItems = instancesToPrint ?? [];
    // Chunk elements based on the amount of numbers per page selected
    // to separate pages

    const prefill = ((perPage) => {
      if (!headerPlaceholderCount) return [];

      const placeHolders = [];
      for (let i = -headerPlaceholderCount; i < 0; i++) {
        placeHolders.push(false);
      }

      return placeHolders;
    })();

    const chunks = chunk([...prefill, ...allItems], printingPreferenceImgPerPage).map((c) => c.filter((i) => i));
    const extraPage = extraPageCount(
      [...prefill, ...allItems].length,
      printingPreferenceImgPerPage,
      examinationContext.includeQRCode
    );
    if (chunks.length > 1) {
      const totalPages = chunks.length + extraPage;
      const lastChunk = chunks.slice(-1)[0];
      const firstChunks = chunks.slice(0, -1);
      return (
        <>
          {firstChunks.map((chunk, chunkIndex) => (
            <OnlySlidesContent
              elements={chunk}
              key={`page-${chunkIndex}`}
              currentPage={chunkIndex + 1}
              totalPages={totalPages}
            />
          ))}
          <LastSlideContent elements={lastChunk} currentPage={chunks.length} totalPages={totalPages} />
        </>
      );
    } else if (chunks.length === 0)
      return <LastSlideContent elements={[]} currentPage={1} totalPages={1 + extraPage} />;
    return <LastSlideContent elements={chunks[0]} currentPage={1} totalPages={1 + extraPage} />;
  };

  return printOnlyQr ? <QrOnlyContent totalPages={1} /> : <SlidesContent />;
};

export const updatePagedPrintHeaderOffset = (doc, printingPreferenceImgPerPage, setter) => {
  const el = doc.querySelector('.page-header-container');

  if (el?.clientHeight > 20) {
    const placeholderCount = ((headerOffset, perPage) => {
      const rowHeightMap = {
        2: 490,
        8: 245,
        15: 195,
      };
      const rowHeight = rowHeightMap[perPage];
      if (!rowHeight) return 0;

      const rows = Math.ceil(headerOffset / rowHeight);
      const placesPerRowMap = {
        2: 1,
        8: 2,
        15: 3,
      };
      const placesPerRow = placesPerRowMap[perPage];

      return Math.min(perPage, rows * placesPerRow);
    })(el.clientHeight, printingPreferenceImgPerPage);

    setter(placeholderCount);
  }
};

export default withTranslation()(ExamPagedPrint);

const previewStyles = `
      * {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        vertical-align:baseline;
        background: transparent;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
      *::-webkit-scrollbar {
        display: none;
      }

      @page {
        margin: 0;
      }

      html,body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      body {
        font-size: 13px;
        font-family: "Metropolis", sans-serif;
        text-align: center;
      }

      .print-preview-page {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 100%;
        margin-bottom: 15px;
        position: relative;
        padding: 0.4cm 0.6cm;
        background: white;
        color: black;
        font-size: 13px;
      }
      .qr-only-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .common-message {
        font-size: 12px;
        text-align: left;
        margin-bottom: 20px;
      }
      .page-header-container {
        width: 100%;
        padding: 0 1cm 0 1cm;
      }
      .page-header-container:has(div) {
        padding-top: 0.5cm
      }
      .page-header {
        display: flex;
        width: 100%;
        background: white;
        // height: 300px;
        max-height: 300px;
        align-items: center;
        margin-bottom: 0.1cm;
      }
      .page-header-message {
        width: 100%;
        background: white;
        align-items: center;
        margin-bottom: 0.1cm;
      }
      .page-header + .common-message.page-header-message {
        margin-top: 1cm;
      }
      .common-message.page-header-message {
        text-align: center;
      }
      .page-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        padding: 0.1cm 0.3cm;
      }
      .page-footer > .powered-by {
        font-size: 11px;
      }
      .page-header > img {
        background: white;
        max-height: 250px;
        aspect-ratio: auto;
        height: auto:
      }
      .page-footer > img {
        width: 0.82cm;
        height: 0.82cm;
        max-width: 0.82cm;
        max-height: 0.82cm;
        background: white;
        margin-left: 0.1cm;
      }
      .page-header > .site-name {
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
      .img-item {
        margin: 0;
        padding: 0;
      }
      .slides-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 100%;
        column-gap: 1%;
        row-gap: 3%;
        align-content: flex-start;
      }
      .last-page2 .img-item {
        margin-bottom: 32%;
      }
      .qr-row {
        width: 100%;
      }
      .label-container {
        max-width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }
      .label-container > p {
        text-overflow: ellipsis;
        margin: 0;
        font-size: 12px;
        margin-bottom: 2px;
      }
      .img-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      img {
        border: 0;
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
        aspect-ratio: 4 / 3;
        background: #000;
      }
      .img-container.anonymized {
        margin-top: 5%;
      }
      .img-container.anonymized img {
        top: -10%;
      }

      .single-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-column: span var(--number-of-columns);
        grid-row-start: span var(--number-of-rows);
      }
      .footer-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        margin-top: 2px;
      }

      .footer-qr-code > p, .top-qr-code > p {
        font-size: 11px;
        text-align: left;
      }
      .page-footer.new-footer {
        height: 6rem;
      }
      .footer-block {
        display: block;
        width: 100%;
        font-family: "Montserrat", sans-serif;
        padding: 0 3rem 2.3rem 3rem;
      }
      .footer-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 0.3rem;
      }

      .footer-line .site-name {
        font-weight: bold;
        text-transform: uppercase;
      }
      .footer-line .patient-name {
        font-weight: bold;
        text-transform: capitalize;
      }

      .footer-line .part:first-child::before {
        content: "";
        padding: 0;
      }
      .footer-line .part::before {
        content: "|";
        padding: 0 0.4rem 0 0.3rem;
      }
      .footer-line img {
        padding-left: 0.2rem;
        max-height: 1.2rem;
        width: 4.4rem;
      }
      
      .footer-line .powered-by {
        display: flex;
        align-items: center;
      }
`;
export const pagedPrintStylesText = `
    ${previewStyles}
    @media print {
      html {
        box-sizing: border-box;
        font-size: 12px;
      }
      html, body {
        width: 100%;
        height: 100%;
      }
      *, *:before, *:after {
        box-sizing: inherit;
        margin: 0;
        padding: 0;
      }
      body, h1, h2, h3, h4, h5, h6, p, ol, ul {
        font-weight: normal;
      }
      ol, ul {
        list-style: none;
      }
      .print-preview-page {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 100%;
        position: relative;
        padding: 0 0.6cm 0.4cm 0.6cm;
        background: white;
        color: black;
      }
      .page-footer {
        height: 40px;
      }
      .label-container {
        max-width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        margin:0;
        margin-top: -1.5rem;
        margin-left: 1rem;
        margin-bottom: 2px;
      }
      .label-container > p {
        text-overflow: ellipsis;
        margin: 0;
        font-size: 12px;
      }
      .slides-content {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        column-gap: 1%;
        row-gap: 2%;
        align-content: flex-start;
        width: 100%;
        margin-top: 0.6cm;
      }

      .img-item {
        display: inline-block;
      }
      img {
        border:0;
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
        aspect-ratio: 4 / 3;
        background: #000;
      }
      .img-container {
        display: flex;    
        flex-direction: column;
        align-items: flex-start;
      }
      .img-container.anonymized {
        margin-top: 5%;
      }
      .img-container.anonymized img {
        top: -10%;
      }
      .footer-qr-code > p, .top-qr-code > p {
        font-size: 13px;
      }
      .common-message {
        font-size: 16px;
      }
    }
`;
