import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import TextInput from '../../atoms/TextInput/TextInput';
import { getRegExpValue } from '../../utils';
import './SyndromeSearchBar.css';

/**
 * <SyndromeSearchBar
 *  value = selected syndrome id
 *  onChange
 * />
 */

let blurTimeout = false;

const SyndromeSearchBar = ({ t: __, value, label = false, SYNDROMES = [], onChange = () => {} }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const [searchValue, setSearchValue] = useState(
    value && SYNDROMES[value] ? SYNDROMES[value].canonical_name[currentLanguage] : ''
  );
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    if (value && SYNDROMES[value] && searchValue === SYNDROMES[value].canonical_name[currentLanguage]) return false;

    onChange(false, false);
    if (searchValue.length >= 3) {
      const expression = getRegExpValue(searchValue);
      setSearchResults(
        Object.values(SYNDROMES).filter((syndrome) => syndrome.canonical_name[currentLanguage].match(expression))
      );
    }
  }, [searchValue]);

  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if (!string) return { __html: '' };
    const expression = getRegExpValue(searchValue);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  };

  /**
   * add a medication to the list of currently selected Syndrome
   */
  const setSyndrome = (syndrome) => {
    clearTimeout(blurTimeout);
    setSearchValue(syndrome.canonical_name[currentLanguage]);
    setSearchResults([]);
    onChange(SYNDROMES[syndrome.id], syndrome.id);
  };

  const onBlurHandle = () => {
    blurTimeout = setTimeout(() => {
      setSearchResults([]);
      if (!value || searchValue !== SYNDROMES[value].canonical_name[currentLanguage]) {
        setSearchValue('');
        onChange(false, false);
      }
    }, 500);
  };

  return (
    <div className="syndrome-search-bar-container">
      <div className="syndrome-search-bar">
        <TextInput
          placeholder={__('syndromeSearchBar.startTyping')}
          value={searchValue}
          label={label}
          icon="search"
          fullwidth
          onChange={(value) => setSearchValue(value)}
          onBlur={onBlurHandle}
        />
        {searchValue.length > 0 && searchValue.length < 3 && <small>{__('syndromeSearchBar.min3letters')}</small>}
        {searchValue.length >= 3 && !!searchResults.length && (
          <div className="syndrome-search-bar-autocomplete">
            <ul>
              {searchResults.map((result) => (
                <li key={result.id} onClick={() => setSyndrome(result)}>
                  <div className="syndrome-name">
                    <span dangerouslySetInnerHTML={highlight(result.canonical_name[currentLanguage])}></span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SyndromeSearchBar);
