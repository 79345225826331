import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import './index.css';

const NotFound = ({ t: __ }) => {
  const history = useHistory();
  return (
    <div className="not-found-page">
      <div className="not-found-layout">
        <h1 className="not-found-title">{__('notFound.title')}</h1>

        <p className="not-found-desc">{__('notFound.description')}</p>
        <div className="not-found-action-wrapper">
          <Button label={__('notFound.back')} onClick={() => history.goBack()} />
          <Button label={__('notFound.home')} onClick={() => history.push('/')} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NotFound);
