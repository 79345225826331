import { placeholderIdFromProps } from './utils';

/*
 * Return if a component can highlight for flash report
 * @param {Object} props - The props of the component
 * @returns {boolean} - If the component should highlight
 */
export function flashReportHighlightable(props) {
  // This function can be improved if we find the need for additional complexity for defining highlighted fields
  const fieldId = placeholderIdFromProps(props);

  const highlight = {
    slug: fieldId,
    icon: 'flash',
    iconClass: '',
    source: 'flashReport',
  };
  if (!props.unit && isFlashReportField(fieldId)) {
    return highlight;
  }
  return false;
}

/*
 * Return if a field is a flash report field
 * @param {string} fieldId - The field id
 * @returns {boolean} - If the field is a flash report field
 */
export function isFlashReportField(fieldId) {
  return (
    fieldId.startsWith('custom.') ||
    ['examination.method', 'examination.indication', 'examination.finding'].includes(fieldId)
  );
}
