import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import { withTranslation } from 'react-i18next';

const ImageSettingField = ({
  t: __,
  children,
  idSlug,
  title,
  subTitle,
  description,
  uploadButtonText,
  onChange,
  onDelete,
  errorMessage,
}) => {
  return (
    <div id={`${idSlug}-section`} className="section">
      <div className="row">
        <div className="w4">
          <div className="title">{title}</div>
          <div className="subtitle">{subTitle}</div>
        </div>
        <div className="w8">
          <div className="content">
            <div className="image-thumbnail">{children}</div>
            <div className="image-actions">
              <label htmlFor={`${idSlug}-edit`} className="button">
                {uploadButtonText}
                <input
                  id={`${idSlug}-edit`}
                  type="file"
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  onChange={onChange}
                />
              </label>
              <Button label={__('preferences.printingSharing.delete')} onClick={onDelete} />
            </div>
          </div>
          <div className="subtitle">{description}</div>
        </div>
      </div>
      {!!errorMessage && (
        <div className="error-message">
          <Icon name="warning" /> {errorMessage}
        </div>
      )}
    </div>
  );
};
export default withTranslation()(ImageSettingField);
