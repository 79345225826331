import { useState, useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { DxContext } from '../../../context-providers/Dx';
import { ExaminationContext } from '../../../context-providers/Examination';
import SignLine from '../../../components-dx/SignLine';
import { nicePrevalence, formatProbability, getSyndromeName } from '../../../services/examination';
import './SyndromeDetails.css';
import MedicationLine from '../../../components/MedicationLine';
import Publications from '../../../components-dx/Publications';
import Icon from '../../../atoms/Icon/Icon';

const MalformationDetails = ({ t: __, currentMalformations, allMedications, onClose }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);

  const malformationTabs = [
    {
      id: 'malformations',
      label: __('dx.summary.tab.malformations'),
      status: 'active',
    },
    {
      id: 'riskFactors',
      label: __('dx.summary.tab.riskFactors'),
      status:
        currentMalformations.malformations.some((malformation) => malformation.risk_factors?.length) ||
        !!allMedications.length
          ? 'active'
          : 'inactive',
    },
    {
      id: 'publications',
      label: __('dx.summary.tab.publications'),
      status: currentMalformations.publications?.length ? 'active' : 'inactive',
    },
    {
      id: 'toConfirm',
      label: __('dx.summary.tab.toConfirm'),
      status:
        (currentMalformations.diagnosticable_by &&
          Object.values(currentMalformations.diagnosticable_by).includes(true)) ||
        !!currentMalformations.future_malformations?.length
          ? 'active'
          : 'inactive',
    },
  ];

  const [currentMalformationsTab, setcurrentMalformationsTab] = useState(malformationTabs[0].id);

  currentMalformations.prevalence =
    currentMalformations.malformations?.length === 1 ? currentMalformations.malformations[0].prevalence : false;

  useEffect(() => {
    if (!dxContext.checklistSummaryOpen) setcurrentMalformationsTab(malformationTabs[0]);
  }, [dxContext.checklistSummaryOpen]);

  return (
    <div className="dx-checklist-syndrome-details-container">
      <div className="dx-checklist-summary-syndrome-header">
        <div className="close-syndrome" onClick={() => onClose(false)}>
          <Icon name="left" />
        </div>
        <div className="dx-checklist-summary-syndrome-name">
          {getSyndromeName(
            examinationContext.getSyndromeById(currentMalformations.id),
            examinationContext.MALFORMATIONS,
            currentMalformations.malformations.map((m) => m.id),
            __
          )}
        </div>
      </div>
      <div className="dx-checklist-summary-syndrome-tabs">
        <ul>
          {malformationTabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => setcurrentMalformationsTab(tab.id)}
              className={`${currentMalformationsTab === tab.id ? 'selected' : ''} ${tab.status}`}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="dx-checklist-summary-syndrome-body">
        {malformationTabs.map((tab) => (
          <div
            key={tab.id}
            className={`dx-checklist-summary-syndrome-tab ${currentMalformationsTab === tab.id ? 'visible' : 'hidden'}`}
          >
            {tab.id === 'malformations' && (
              <>
                <div className="row">
                  <div className="w12">
                    <div className="proximity-score">
                      <Icon name="matching" /> {__('dx.summary.matchingScore')}
                      <span className="score-value">
                        <b>{formatProbability(currentMalformations.probability || 0)}</b>
                        <small> / 10</small>
                      </span>
                    </div>
                  </div>
                </div>

                {!!currentMalformations.malformations.length &&
                  currentMalformations.malformations.map((malformation) => (
                    <div key={malformation.id} className="row">
                      <div className="w3">
                        <h3 className="sticky">
                          <small>{__('dx.summary.typicalSigns')}</small>
                          {malformation.canonical_name?.[currentLanguage]}
                          <br />
                          <small className="malformation-prevalence">
                            {nicePrevalence(malformation.prevalence || 0, __)}
                          </small>
                        </h3>
                      </div>
                      <div className="w9">
                        {!malformation.signs?.length && (
                          <div className="dx-checklist-summary-syndrome-empty-tab">
                            {__('dx.summary.noSignAvailableForThisMalformation').replace(
                              '{malformation}',
                              malformation.canonical_name?.[currentLanguage]
                            )}
                          </div>
                        )}
                        {malformation.signs?.map((sign) => (
                          <div key={sign.id} className="sign-line">
                            <SignLine sign={sign} disableOnClick={false} />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </>
            )}
            {tab.id === 'riskFactors' &&
              (!currentMalformations.malformations.some((malformation) => malformation.risk_factors?.length) &&
              !allMedications.length ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.noRiskFactorsAssociatedWithThisAnomaly')}
                </div>
              ) : (
                <div className="dx-checklist-summary-syndrome-risk-factors">
                  {currentMalformations.malformations.map(
                    (malformation) =>
                      !!malformation.riskFactors?.length && (
                        <div key={malformation.id} className="row">
                          <div className="w3">
                            <h3>{malformation.canonical_name?.[currentLanguage]}</h3>
                          </div>
                          <div className="w9">
                            {malformation.riskFactors?.map((risk) => (
                              <div key={risk.id} className={`risk-factor ${risk.medicalHistoryItem ? 'yes' : 'no'}`}>
                                <span className="label">{risk.label[currentLanguage]}</span>
                                <span className="option">{risk.options[0].label[currentLanguage]}</span>
                                {!!risk.medicalHistoryItem?.raw_value && !risk.medicalHistoryItem?.tmp_value && (
                                  <span className="value">{risk.medicalHistoryItem.raw_value}</span>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                  )}

                  {!!allMedications.length && (
                    <div className="row">
                      <div className="w3">
                        <h3>{__('dx.summary.drugs')}</h3>
                      </div>
                      <div className="w9">
                        <div className="medications-list">
                          {allMedications.map((medication) => (
                            <MedicationLine key={medication.id} medication={medication} />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            {tab.id === 'publications' &&
              (![].concat(...currentMalformations?.malformations.map((m) => m.publications))?.length ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.noPublicationsAvailableForThisSyndrome')}
                </div>
              ) : (
                <div className="row">
                  <div className="w3">
                    <h3 className="sticky">{__('dx.summary.publications')}</h3>
                  </div>
                  <div className="w9">
                    <Publications
                      publications={[]
                        .concat(...currentMalformations?.malformations.map((m) => m.publications))
                        .filter((m) => m)}
                    />
                  </div>
                </div>
              ))}
            {tab.id === 'toConfirm' &&
              (!(
                currentMalformations.diagnosticable_by &&
                Object.values(currentMalformations.diagnosticable_by).includes(true) &&
                !currentMalformations.future_malformations?.length
              ) ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.noFurtherActionsForThisSyndrome')}
                </div>
              ) : (
                <div>
                  {Object.values(currentMalformations.diagnosticable_by).includes(true) && (
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__('dx.summary.diagnosticableBy')}</h3>
                      </div>
                      <div className="w9">
                        <ul className="diagnosticable-by">
                          {currentMalformations.diagnosticable_by.cgh && <li>{__('dx.summary.cgh')}</li>}
                          {currentMalformations.diagnosticable_by.caryotype && <li>{__('dx.summary.karyotype')}</li>}
                          {currentMalformations.diagnosticable_by.dpni && <li>{__('dx.summary.dpni')}</li>}
                        </ul>
                      </div>
                    </div>
                  )}
                  {!!currentMalformations.future_malformations?.length && (
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__('dx.summary.toCheckInTheFuture')}</h3>
                      </div>
                      <div className="w9">
                        <ul className="to-check-in-the-future">
                          {currentMalformations.future_malformations
                            .sort((a, b) => b.probability - a.probability)
                            .slice(0, 5)
                            .map((m) => (
                              <li key={m.id}>
                                {examinationContext.MALFORMATIONS[m.id]?.canonical_name[currentLanguage]}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withTranslation()(MalformationDetails);
