import React, { useState, useEffect } from 'react';
import './DragAndDropOverlay.css';

const DragAndDropOverlay = ({ onFilesDropped, dropFilesMessage }) => {
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setIsDragging(false);
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      const files = Array.from(e.dataTransfer.files);
      onFilesDropped(files);
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleDrop);
    };
  }, [onFilesDropped]);

  return (
    <div className={`drag-and-drop-overlay ${isDragging ? 'dragging' : ''}`}>
      <div className="drag-and-drop-message">{dropFilesMessage}</div>
    </div>
  );
};

export default DragAndDropOverlay;
