import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import TextInput from '../../atoms/TextInput/TextInput';
import { AppContext } from '../../context-providers/App';
import { formatName } from '../../services/examination';
import { formatDate } from '../../utils';
import './PatientsView.css';

const PatientsView = ({ t: __, patientsList = [], search, createDraftExam }) => {
  const appContext = useContext(AppContext);
  const preferedDateFormat = appContext.preferences.date_format;
  const location = useLocation();
  const isNewExamMode = location.search.includes('new-exam');

  const [searchKeyword, setSearchKeyword] = useState('');

  return (
    <div className="patients-container">
      <div className="row">
        <div className="w6">
          <h1>{!isNewExamMode ? __('patients.recentFolders') : __('patients.chooseThePatient')}</h1>
        </div>
        <div className="w6">
          <div className="patients-searchbar ph-no-capture">
            <TextInput
              placeholder={__('patients.search.placeholder')}
              icon="search"
              value={searchKeyword}
              fullwidth
              onChange={(value) => {
                setSearchKeyword(value);
                search(value);
                return true;
              }}
            />
          </div>
        </div>
      </div>

      {!!patientsList.length && (
        <div className="patients-list">
          {[{ name: __('patients.anonymousPatients'), id: 0, dob: false }, ...patientsList].map((patient, index) => {
            const patientName = formatName(patient.name);
            const onClickPatient = () => {
              if (isNewExamMode) return createDraftExam(patient.id);

              window.location.href = `patient/${patient.id}`;
            };

            return (
              <div key={index} className="row" onClick={onClickPatient}>
                <div className="w8 vertical-center ph-no-capture">
                  {patientName.fullName}
                  {!!patient.dob && <div className="dob">{formatDate(patient.dob, preferedDateFormat)}</div>}
                </div>
                <div className="w4 vertical-center cta">
                  {!isNewExamMode || !patient.id ? (
                    <Icon name="right-big" />
                  ) : (
                    <Button
                      label={__('patients.prepareNewExamination')}
                      variant="outline"
                      icon="add"
                      onClick={(e) => {
                        e.stopPropagation();
                        createDraftExam(patient.id);
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {!patientsList.length && <div className="patients-list-empty">{__('patients.noPatientFound')}</div>}
    </div>
  );
};

export default withTranslation()(PatientsView);

PatientsView.propTypes = {
  t: PropTypes.any,
  patientsList: PropTypes.array,
  search: PropTypes.func,
  createDraftExam: PropTypes.func,
};
