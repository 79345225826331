import { isValidElement } from 'react';
import { conditionRequiredPlaceholders } from './utils';

export default class ChecklistHelper {
  constructor(props) {
    this.props = props;
  }

  conditionRequiredPlaceholderList() {
    if (!Array.isArray(this.props.children)) {
      return [];
    }
    return this.props.children
      .filter((child) => isValidElement(child) && child.props?.type === 'if')
      .map((child) => conditionRequiredPlaceholders(child.props?.props))
      .flat();
  }
}
