// This module is responsible for handling the dynamic macros.
// It is independent of the way they are rendered.
// The dependencies needed (rendering dependent) are injected as callbacks.
// (isDynamicElement, sanitize, dynamicElementToValue)

// Current syntax for dynamic part of the macro is <macro data="patient.ga" />

import { isValidElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import sanitizeHtml from 'sanitize-html';

import { computeEstimatedDeliveryDate, getNiceGestionalAgeFromDays } from '../../services/examination';
import { getCurrentLanguageCode } from '../../utils';

// Regular expression to match <macro ... />
export const TAG = 'macro';
const REGEXP = /(<macro[^>]*>.*?<\/macro>|<macro[^>]*\/>)/g;
export const VALID_ATTRIBUTES = [
  'data',
  'format',
  'fetus',
  'decimals',
  'unit',
  'attribute',
  'source',
  'default',
  'type',
  'options-selector',
];

const defaultSanitizeOptions = {
  allowedTags: [],
  allowedAttributes: {},
};

export const sanitizeDynamicMacros = (input, { allowedTags = [], allowedAttributes = [] } = defaultSanitizeOptions) => {
  return sanitizeHtml(input, {
    allowedTags: [TAG, ...allowedTags],
    allowedAttributes: {
      [TAG]: VALID_ATTRIBUTES,
      ...allowedAttributes,
    },
  });
};

export const convertElement = (element, { isDynamicElement = (_el) => false, dynamicElementToValue = (el) => el }) => {
  if (element.nodeType === Node.TEXT_NODE) return element.textContent;
  if (isDynamicElement(element)) {
    return dynamicElementToValue(element);
  }
  switch (element.tagName) {
    case 'BR':
      return '\n';
    case 'P': // fall through to DIV
    case 'DIV':
      return (
        (element.previousSibling ? '\n' : '') +
        Array.from(element.childNodes)
          .map((el) =>
            convertElement(el, {
              isDynamicElement,
              dynamicElementToValue,
            })
          )
          .join('')
      );
    default:
      return element.textContent;
  }
};

export const extractDynamicAttributes = (macro) => {
  // Since the current syntax is valid xml, we can use DOMParser to extract the attributes
  const parser = new DOMParser();
  const doc = parser.parseFromString(macro, 'text/xml');
  const macroEl = doc.firstElementChild;
  const attributes = {};

  for (let i = 0; i < macroEl.attributes.length; i++) {
    const { name, value } = macroEl.attributes[i];
    if (VALID_ATTRIBUTES.includes(name)) attributes[name] = value;
  }

  return attributes;
};

export const removeHtmlExceptDynamicParts = (input) => {
  return input.replace(/<[^>]*>/g, (match) => {
    return match.match(REGEXP) ? match : '';
  });
};

export const extractTextAndDynamicParts = (input) => {
  // Split the input separate text and dynamic segments
  return input.split(REGEXP);
};

const extractDynamicPart = (inputString) => {
  REGEXP.lastIndex = 0; // reset the regex
  const matches = [];
  let match;

  // Find all matches
  while ((match = REGEXP.exec(inputString)) !== null) {
    matches.push(match[0]); // Extracted full tag
  }

  return matches;
};

export const dynamicPlaceholderIds = (placeholder, multiSelectValue) => {
  if (!placeholder) return [];
  // dynamic values are stored inside xml tags
  // e.g. <macro data="patient.ga" />

  const placeholdersIds = placeholder.data.reduce((acc, data) => {
    const {
      value: { value },
    } = data;
    const placeholdersIds = [];

    const macros = multiSelectValue in value ? [value[multiSelectValue]] : Object.values(value);
    macros.forEach(({ description }) => {
      const dynamicParts = extractDynamicPart(description);
      dynamicParts.forEach((segment) => {
        const attributes = extractDynamicAttributes(segment);
        const placeholderId = attributes.data;
        if (!placeholderId) return;
        placeholdersIds.push(placeholderId);
      });
    });
    return [...acc, ...placeholdersIds];
  }, []);
  return [...new Set(placeholdersIds)];
};

export const renderDynamicContent = (
  value,
  renderDynamic,
  renderStatic = (el) => el,
  sanitize = sanitizeDynamicMacros
) => {
  return extractTextAndDynamicParts(value || '')
    .map((part) => {
      const attributes = extractDynamicAttributes(part);
      return attributes.data ? renderDynamic(part, attributes) : renderStatic(part);
    })
    .map((el) => {
      return isValidElement(el) ? renderToStaticMarkup(el) : el;
    })
    .map(sanitize)
    .join('');
};

export const formatDynamicValue = (__, placeholder, attributes, emptyValue = '·') => {
  const {
    data: slug,
    fetus,
    source,
    decimals,
    type,
    'options-selector': optionsSelector,
    default: defaultValue = emptyValue,
  } = attributes;

  try {
    const template = placeholder.displayedValue(slug, fetus, source);
    const value =
      template
        .map((item) => {
          if (typeof item === 'string') return item;
          return item.value;
        })
        .join(' ')
        .trim() || defaultValue;

    const appPreferences = placeholder.appPreferences;
    const reportDataOptions = placeholder.reportDataOptions;
    const dating_methods = placeholder.reportDataOptions?.dating_methods || {};
    const dating_standards = placeholder.reportDataOptions?.dating_standards || {};
    const currentLanguage = getCurrentLanguageCode();
    switch (type) {
      case 'ga':
        return getNiceGestionalAgeFromDays(__, value);
      case 'edd':
        return computeEstimatedDeliveryDate(
          value,
          placeholder.selectedValue('examination.date', 0).value,
          appPreferences.date_format,
          reportDataOptions.site.timezone,
          reportDataOptions.pregnancyLengthInDays
        );
      case 'select':
        return (
          placeholder.options(slug).find((option) => option.id.toString() === value.toString())?.label || defaultValue
        );
      case 'dropdown':
        return findOptions(slug, reportDataOptions.report_template.blueprint, value, optionsSelector) || defaultValue;
      case 'dating_method':
        return (dating_methods[value]?.label || {})[currentLanguage] || value;
      case 'dating_standard':
        return (dating_standards[value.split('.')[2]] || {})[currentLanguage] || value;
      case 'checklist-item-status':
        return __(`checklistItem.status.${value}`);
      case 'number':
        return parseFloat(value).toFixed(decimals);
      default:
        return value;
    }
  } catch (error) {
    console.error('Cannot format value', slug, type, error);
    return defaultValue;
  }
};

const findOptions = (slug, blueprint, value, selector = null) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(blueprint, 'text/xml');
  const isCustom = slug.startsWith('custom.');
  const slugWithoutCustom = slug.replace('custom.', '');
  const optionSelector = `option[value="${value}"]`;
  const dropdownSelector = isCustom ? `[data="${slugWithoutCustom}"]` : `[data="${slug}"]`;

  const option = doc.querySelector(selector || `${dropdownSelector} ${optionSelector}`);
  if (!option) return null;
  return option.textContent.trim();
};
