import Checklist_v1 from './Checklist_v1';
import Checklist_v2 from './Checklist_v2';

const ReportTemplateChecklist = ({
  // common
  props,
  apiVersion,

  // v1
  zones = [],
  onEndEditing,
  onEndEditingChecklist,
  reportMode = 'edit',
  showOptions = true,

  // v2
  checkCondition,
  groups = [],
  setEditChecklistDialogIsOpen,
  autogeneratedChecklistComments,
  updateComponentChecklistAssoc,
  updateAutogeneratedChecklistComments,

  ...otherProps
}) => {
  if (props.status && apiVersion === '1.0') {
    return (
      <Checklist_v1
        {...{
          props,
          zones,
          onEndEditing,
          onEndEditingChecklist,
          reportMode,
          showOptions,
          ...otherProps,
        }}
      />
    );
  }

  return (
    <Checklist_v2
      {...{
        props,
        apiVersion,
        checkCondition,
        apiVersion,
        groups,
        setEditChecklistDialogIsOpen,
        updateComponentChecklistAssoc,
        autogeneratedChecklistComments,
        updateAutogeneratedChecklistComments,
        reportMode,
        ...otherProps,
      }}
    />
  );
};

export default ReportTemplateChecklist;
