import { useCallback, useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ResourceApi from '../../services/resource';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import LookupApi from '../../services/lookup';
import './PreselectOptions.css';
import ProtocolSetting from './ProtocolSetting';

const defaultProtocolSetting = (id) => {
  return {
    examination_preset_id: id,
    option: 'none',
  };
};

const PreselectOptions = ({ t: __ }) => {
  const appContext = useContext(AppContext);
  const { config: userConfig } = useAuth();
  const [allSlides, setAllSlides] = useState([]);
  const [printingProtocolSettings, setPrintingProtocolSettings] = useState({});
  const [sharingProtocolSettings, setSharingProtocolSettings] = useState({});

  useEffect(() => {
    ResourceApi.listPrintingProtocolSettings().then((resp) => {
      const map = {};
      resp.data.data.forEach((x) => (map[x.examination_preset_id] = x));
      setPrintingProtocolSettings(map);
    });
  }, []);

  useEffect(() => {
    ResourceApi.listSharingProtocolSettings().then((resp) => {
      const map = {};
      resp.data.data.forEach((x) => (map[x.examination_preset_id] = x));
      setSharingProtocolSettings(map);
    });
  }, []);

  const getAllSlides = useCallback(async () => {
    const {
      data: { data: slides },
    } = await LookupApi.listImagePlane();
    setAllSlides(slides.filter((slide) => ['picture', 'other'].includes(slide.type)));
  }, [LookupApi.listImagePlane]);

  useEffect(() => {
    getAllSlides();
  }, [getAllSlides]);

  const getActivePresets = () =>
    appContext.displayedPresets.map((x) => appContext.allPresets.find((p) => p.id === x)).filter((x) => !!x);

  const handlePrintingProtocolSettingUpdate = (presetId, setting, value) => {
    if (setting.id && setting.entity_id && setting.site_id) {
      ResourceApi.updatePrintingProtocolSetting(setting.id, value).then((x) => {
        const updatedSetting = x.data;
        setPrintingProtocolSettings({
          ...printingProtocolSettings,
          [presetId]: updatedSetting,
        });
      });
    } else {
      ResourceApi.createPrintingProtocolSetting(presetId, value).then((x) => {
        const createdSetting = x.data;
        setPrintingProtocolSettings({
          ...printingProtocolSettings,
          [presetId]: createdSetting,
        });
      });
    }
  };

  const handleSharingProtocolSettingUpdate = (presetId, setting, value) => {
    if (setting.id && setting.entity_id && setting.site_id) {
      ResourceApi.updateSharingProtocolSetting(setting.id, value).then((x) => {
        const updatedSetting = x.data;
        setSharingProtocolSettings({
          ...sharingProtocolSettings,
          [presetId]: updatedSetting,
        });
      });
    } else {
      ResourceApi.createSharingProtocolSetting(presetId, value).then((x) => {
        const createdSetting = x.data;
        setSharingProtocolSettings({
          ...sharingProtocolSettings,
          [presetId]: createdSetting,
        });
      });
    }
  };

  return (
    <>
      <div className="row">
        <h4>{__('sharingPreferences.preselect.title.printed')}</h4>
        <small className="sub-title">{__('sharingPreferences.preselect.description.printed')}</small>
      </div>
      <div className="row">
        {getActivePresets().map((preset) => (
          <div className="w4" key={`widget-${preset.id}`}>
            <div>{preset?.name}</div>
            <div>
              <ProtocolSetting
                allSlides={allSlides}
                setting={printingProtocolSettings[preset.id] || defaultProtocolSetting(preset.id)}
                templateKey="printing_template"
                allowGuideline={appContext?.preferences?.default_category === 'CNEOF_2022'}
                onSave={(setting, value) => handlePrintingProtocolSettingUpdate(preset.id, setting, value)}
                trimester={preset.trimester}
                userConfig={userConfig}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="row spacer"></div>

      <div className="row">
        <h4>{__('sharingPreferences.preselect.title.shared')}</h4>
        <small className="sub-title">{__('sharingPreferences.preselect.description.shared')}</small>
      </div>
      <div className="row">
        {getActivePresets().map((preset) => (
          <div className="w4" key={`widget-${preset.id}`}>
            <div>{preset?.name}</div>
            <div>
              <ProtocolSetting
                allSlides={allSlides}
                setting={sharingProtocolSettings[preset.id] || defaultProtocolSetting(preset.id)}
                templateKey="sharing_template"
                allowGuideline={false}
                onSave={(setting, value) => handleSharingProtocolSettingUpdate(preset.id, setting, value)}
                trimester={preset.trimester}
                userConfig={userConfig}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default withTranslation()(PreselectOptions);
