import { createContext, useContext, useEffect, useState } from 'react';
import { SocketContext } from './Socket';
import useAuth from './Auth';

export const LiveSessionEventContext = createContext({});

export const LiveSessionEventContextProvider = ({ children }) => {
  const { sessionId } = useAuth();
  const { socket } = useContext(SocketContext);

  const [sessionChannel, setSessionChannel] = useState(null);

  const [newExamination, setNewExamination] = useState(null);
  const [firstDicomInstanceOfExamination, setFirstDicomInstanceOfExamination] = useState(null);

  useEffect(() => {
    if (socket) {
      const channel = socket.channel(`session:${sessionId}`);

      channel.on('new_examination', (payload) => {
        setNewExamination(payload);
      });

      channel.on('first_dicom_instance_of_examination', (payload) => {
        setFirstDicomInstanceOfExamination(payload);
      });

      channel
        .join()
        .receive('ok', (resp) => setSessionChannel(channel))
        .receive('error', () => console.error('Failed to join session channel'));
    }

    return () => {
      if (sessionChannel) {
        sessionChannel.leave();
        setSessionChannel(null);
      }
    };
  }, [socket]);

  const broadcastConfigUpdate = (data) => {
    if (sessionChannel) {
      sessionChannel.push('update-config', data);
    } else {
      console.error('sessionChannel not connected - unable to broadcast');
    }
  };

  return (
    <LiveSessionEventContext.Provider
      value={{ newExamination, firstDicomInstanceOfExamination, broadcastConfigUpdate }}
    >
      {children}
    </LiveSessionEventContext.Provider>
  );
};
