import axios from 'axios';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from './context-providers/Auth';
import useLatestVersion from './context-providers/LatestVersion';

export const useAuthentificate = () => {
  const { user, loading } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const latestVersion = useLatestVersion();

  useEffect(() => {
    if (!user && !loading) {
      history.push(`/login?next=${encodeURIComponent(location.pathname + location.search)}`);
    } else {
      axios.interceptors.response.use(
        (resp) => {
          latestVersion.checkForUpdate(resp.headers['api-version'], resp.headers['api-min-ui-version']);
          return resp;
        },
        (err) => {
          if (err.response?.status === 401) {
            // Set the next param to redirect back to the same location after login
            history.push(`/login?next=${encodeURIComponent(location.pathname + location.search)}`);
          }

          return Promise.reject(err);
        }
      );
    }
  }, [history, location, user, loading]);
};
