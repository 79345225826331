import { useContext } from 'react';
import Button from '../../atoms/Button/Button';
import { ExaminationContext } from '../../context-providers/Examination';
import './SlideStarButton.css';
import { trackEvent } from '../../pages/ExaminationSharingFlow/examinationTracking';

const SlideStarButton = ({ media, onClickCallback = () => {} }) => {
  const examinationContext = useContext(ExaminationContext);
  const isVideo = media?.dicom_media_type === 'video';

  return (
    !!media && (
      <div className={`slide-star-button-container`}>
        {!isVideo && (
          <div className={media?.selected_for_print ? 'selected' : ''}>
            <Button
              icon={'print'}
              size="small"
              label={''}
              variant={'link'}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => {
                e.stopPropagation();
                examinationContext.toggleInstanceSelectedForPrinting(media.id);
                trackEvent('user_click_on_the_print_icon', examinationContext, { dicom_instance_id: media.id });
              }}
            />
          </div>
        )}
        <div className={media?.shared_with_patient ? 'selected' : ''}>
          <Button
            icon={'share'}
            size="small"
            label={''}
            variant={'link'}
            onMouseDown={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
              examinationContext.toggleInstanceSelectedForSharing(media.id);
              trackEvent('user_click_on_the_share_icon', examinationContext, { dicom_instance_id: media.id });
            }}
          />
        </div>
      </div>
    )
  );
};

export default SlideStarButton;
