import { forwardRef, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../Icon/Icon';
import './TextInput.css';
import LoaderInline from '../LoaderInline/LoaderInline';

/**
 * <TextInput
 *  label
 *  icon
 *  value
 *  fullwidth
 *  variant = "outline" | "underline"
 *  onChange
 *  maxLength
 *  ...props />
 */

const TextInput = forwardRef(
  (
    {
      label = '',
      icon = '',
      loading = false,
      value = '',
      variant = 'outline',
      fullwidth = false,
      maxLength,
      onChange = () => {},
      onBlur = () => {},
      changeOnBlur = true,
      clearButton = false,
      bounceDelay = 300,
      t: __,
      ...props
    },
    ref
  ) => {
    const [bounceTimeout, setBounceTimeout] = useState(false);
    const [displayedValue, setDisplayedValue] = useState(value);

    useEffect(() => {
      if (displayedValue !== value) setDisplayedValue(value);
    }, [value]);

    const onBlurHandler = (value) => {
      if (changeOnBlur) onChange(value);
      onBlur(value);
    };

    const onChangeHandler = (e) => {
      setDisplayedValue(e.target.value);
      if (bounceDelay || bounceTimeout) {
        clearTimeout(bounceTimeout);
        setBounceTimeout(false);
        setBounceTimeout(setTimeout(() => onChange(e.target.value), bounceDelay));
      } else {
        onChange(e.target.value);
      }
    };

    return (
      <div
        className={`text-input ${!!icon || !!loading ? 'has-icon' : ''} variant-${variant} ${
          fullwidth ? 'full-width' : ''
        }`}
      >
        {!!label && <label>{label}</label>}
        <span className={`text-input-field ${maxLength ? 'has-maxLength' : ''}`}>
          {loading ? (
            <span className="text-input_loader">
              <LoaderInline />
            </span>
          ) : (
            !!icon && <Icon name={icon} />
          )}
          {!!clearButton && !!displayedValue && <Icon name="close" className="cancel" onClick={() => onChange('')} />}
          <input
            type="text"
            value={displayedValue || ''}
            {...props}
            onChange={onChangeHandler}
            onBlur={(e) => onBlurHandler(e.currentTarget.value)}
            ref={ref}
            maxLength={maxLength}
          />
          {maxLength && (
            <span className="text-input-length">
              {__('textInput.maxLength', {
                currentLength: value.length,
                maxLength,
              })}
            </span>
          )}
        </span>
      </div>
    );
  }
);

export default withTranslation()(TextInput);
