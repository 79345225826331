import { withTranslation } from 'react-i18next';
import Icon from '../../atoms/Icon/Icon';
import { getRegExpValue } from '../../utils';
import './SyndromeLine.css';
import { getSyndromeName } from '../../services/examination';

const SyndromeLine = ({ t: __, syndrome, cta = '', onClick = () => {}, searchKey = false }) => {
  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if (!string) return { __html: '' };
    if (!searchKey) return { __html: string };
    const expression = getRegExpValue(searchKey);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  };

  return (
    <li className="syndrome-line-search">
      <div onClick={() => onClick(syndrome)}>
        <span className="syndrome-name">
          <span dangerouslySetInnerHTML={highlight(getSyndromeName(syndrome))} />
        </span>
        <span className="syndrome-cta">
          {cta} <Icon name="pin" />
        </span>
      </div>
    </li>
  );
};

export default withTranslation()(SyndromeLine);
