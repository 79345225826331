import { useState } from 'react';
import Icon from '../../../../atoms/Icon/Icon';
import InlineTextarea from '../../../../atoms/InlineEditing/InlineTextarea/InlineTextarea';
import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';
import { placeholderIdFromProps, propsToTag } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';
import { extractFirstLine } from './helpers';
import { withTranslation } from 'react-i18next';

function ReportTemplateTextBlockBody(fullProps) {
  const {
    t: __,
    props,
    textAreaProps = {},
    renderContentForPrint,
    children,
    showErrors,
    onEndEditing = () => {},
    showOptions: parentShowOptions = null,
    reportMode = 'edit',
    placeholders,
    canEdit = false,
    highlight = false,
    BIContext: InitialBIContext,
  } = fullProps;
  const [editing, setEditing] = useState(false);
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const fieldId = placeholderIdFromProps(props);
  const selectedDatum = placeholdersHelper.selectedDatum(fieldId);
  const visible = highlight ? true : selectedDatum?.visible ?? true;

  const format = placeholdersHelper.format(fieldId);

  const BIContext = {
    ...InitialBIContext,
    component: 'text-block',
    format: format,
  };

  const attribute = placeholdersHelper?.attribute(fieldId);
  if (format !== 'string' && attribute === 'value') {
    // TODO move it to the debug pannel
    console.error(`text-block is only allowed for strings, received ${format} for ${propsToTag(props)}`);
  }
  const value =
    placeholdersHelper.selectedValue(fieldId)?.[attribute] ?? (typeof children === 'string' ? children : '');
  const showOptions = parentShowOptions || placeholders[fieldId].showOptions !== false;

  const invalidValue = () => {
    return !value;
  };

  const getClassName = () => {
    return `
      textblock-wrapper exam-report-editing-field
      ${visible ? 'is-visible' : 'not-visible'}
      ${value?.trim()?.length > 0 ? (visible ? 'has-printable-value' : '') : 'not-printable'}
      ${props.required === 'true' && invalidValue() && showErrors ? 'required-error' : ''}
      ${props.label ? 'is-block' : 'is-inline'}
      ${props.data ? 'is-editable' : ''}
      ${editing ? 'is-editing' : ''}
    `;
  };

  const saveChange = (value) => {
    setTimeout(setEditing(false), 200);
    if (!props.data) return false;

    onEndEditing(fieldId, placeholdersHelper.editSelectedDataValue(fieldId, { [attribute]: value }, null, 'user'), {
      BIContext,
    });
  };

  const changeVisibility = (visible) => {
    const { value: _, ...datum } = selectedDatum;
    datum.visible = visible;
    onEndEditing(fieldId, [datum], { BIContext });
  };

  const isRequired = () => {
    return props.required === 'true' ? <span className="required">*</span> : false;
  };

  const printableVersion = () => {
    // this has been done to keep the first sentence attached with the previous title / label when printing on different pages
    const content = `${value ?? ''}`;
    const localizedTitles = __('report.titleAbbreviations').split(',');
    const [firstSentence, rest] = extractFirstLine(content, localizedTitles);
    const firstSentenceRendered = renderContentForPrint(firstSentence);
    const restRendered = renderContentForPrint(rest);

    return (
      <>
        {!!firstSentence && (
          <div
            className={getClassName()}
            dangerouslySetInnerHTML={{
              __html: firstSentenceRendered,
            }}
          />
        )}
        {!!rest && (
          <>
            <div className={getClassName()} dangerouslySetInnerHTML={{ __html: restRendered }} />
          </>
        )}
      </>
    );
  };

  const labelStyle = props['label-width'] ? { width: props['label-width'], minWidth: props['label-width'] } : {};

  return reportMode === 'edit' ? (
    <div className={getClassName()}>
      {!!props.label && (
        <div className="label" style={labelStyle}>
          {props.label} {isRequired()}
        </div>
      )}
      <span className={`${highlight ? 'highlight-field' : ''}`}>
        <div className="textblock-text exam-report-editing-value" data-editing={editing === 'value'}>
          {props.required === 'true' && <span className="required">*</span>}
          <InlineTextarea
            value={value}
            printable={visible}
            active={canEdit}
            placeholder={placeholdersHelper}
            onStartEditing={() => setEditing('value')}
            onChange={(value) => saveChange(value, 0)}
            {...textAreaProps}
          />
          {!props.label && isRequired()}
        </div>
        {highlight && highlight.icon && (
          <span className={`highlight-field_icon ${highlight.iconClass}` || ''}>
            <Icon name={highlight.icon} />
          </span>
        )}
        {!highlight && visible && canEdit && reportMode === 'edit' && showOptions && (
          <div className="content-options exam-report-editing-options">
            <div onClick={() => changeVisibility(!visible)}>
              <Icon name={visible ? 'eye' : 'eye-off'} />
            </div>
          </div>
        )}
      </span>
      {!visible && reportMode === 'edit' && <NotVisibleOverlay onClick={() => changeVisibility(true)} />}
    </div>
  ) : (
    printableVersion()
  );
}

/* This is just a squelton to ensure placeholders are loaded */
function ReportTemplateTextBlock({ props, placeholders, ...otherProps }) {
  const fieldId = placeholderIdFromProps(props);
  const requiredPlaceholders = [fieldId, 'fetus.order'];
  const renderContentForPrint = (content) => content.replace('\n', '<br />');

  return (
    <PlaceholderLoader
      Component={ReportTemplateTextBlockBody}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      renderContentForPrint={renderContentForPrint}
      props={props}
      {...otherProps}
    />
  );
}

export default withTranslation()(ReportTemplateTextBlock);
