import { isValidElement } from 'react';
import { conditionRequiredPlaceholders, checkCondition } from '../../utils';
import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';

function ReportTemplateConditionBody(fullProps) {
  const { children } = fullProps;
  const childrenArray = Array.isArray(children) ? children : [children];
  for (const child of childrenArray) {
    if (
      isValidElement(child) &&
      child.props.type === 'if' &&
      checkCondition(
        { ...child.props?.props },
        fullProps.fetus,
        new PlaceholdersHelper({ ...fullProps, props: child.props?.props }),
        fullProps.apiVersion
      )
    ) {
      return child.props.children;
    }
  }

  /* If no if has ever matched we match the first matching else */
  for (const child of childrenArray) {
    if (isValidElement(child) && child.props.type === 'else') {
      return child.props.children;
    }
  }

  return false;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateCondition({ children, ...otherProps }) {
  const requiredPlaceholders = children
    .filter((child) => isValidElement(child) && child.props?.type === 'if')
    .map((child) => conditionRequiredPlaceholders(child.props?.props))
    .flat()
    .concat(['fetus.order']);

  return (
    <PlaceholderLoader
      Component={ReportTemplateConditionBody}
      requiredPlaceholders={requiredPlaceholders}
      {...otherProps}
    >
      {children}
    </PlaceholderLoader>
  );
}
