import { isValidElement, cloneElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

export default function ReportTemplateBlock({ defaultKey = '', children, props, fetus = 1, reportMode }) {
  const {
    layout,
    width = null,
    align = null,
    color = null,
    background = null,
    size = '',
    outlined = null,
    'avoid-break': avoidBreak,
    'auto-hide': autoHide,
  } = props ?? {};

  const style = {
    minWidth: width ? `min(${width}, 100%)` : 'auto',
    maxWidth: width ? `min(${width}, 100%)` : '100%',
  };

  if (align) style.textAlign = align;
  if (color && reportMode === 'print') style.color = color;
  if (background && reportMode === 'print') style.backgroundColor = background;

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };

  const childrenWithFetusNumber = Array.isArray(children) ? (
    children.map((child, i) => {
      if (isValidElement(child)) {
        const key = `${defaultKey || ''}_${i}_block_${fetus}`;
        return (
          <WrappedChild key={i} {...{ layout, reportMode }}>
            {cloneElement(child, {
              ...child.props,
              fetus,
              defaultKey: key,
              key,
            })}
          </WrappedChild>
        );
      }
      return (
        <WrappedChild key={i} {...{ layout, reportMode }}>
          {child}
        </WrappedChild>
      );
    })
  ) : (
    <WrappedChild {...{ layout, reportMode }}>{children}</WrappedChild>
  );

  // TODO this is super ugly. We need to find a better way to check if a block is printable
  // Example of solution is to bring modularity like for measurement-curve and graphs components
  const notPrintable =
    reportMode === 'print' &&
    autoHide &&
    !renderToStaticMarkup(childrenWithFetusNumber).includes('has-printable-value');

  return (
    <div
      className={`block size-${sizes[size] || 'default'} ${outlined ? 'is-outlined' : ''} ${
        avoidBreak ? 'avoid-break' : ''
      } ${notPrintable ? 'not-printable' : ''} ${background ? 'has-background' : ''}`}
      style={style}
      data-layout={layout}
    >
      {!notPrintable && childrenWithFetusNumber}
    </div>
  );
}

// wrap the textblock only in column mode to avoid create a columns for each line in PDF mode
const WrappedChild = ({ children, layout, reportMode }) =>
  children && layout === 'columns' && reportMode === 'print' && children.props?.type === 'textblock' ? (
    <div class="textblock_outer-wrapper">{children}</div>
  ) : (
    children || null
  );
