import { useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { getNiceGestionalAgeFromDays, computeEstimatedDeliveryDate } from '../../../../../services/examination';

import InlineInput from '../../../../../atoms/InlineEditing/InlineInput/InlineInput';
import Icon from '../../../../../atoms/Icon/Icon';
import InlineDropdown from '../../../../../atoms/InlineEditing/InlineDropdown/InlineDropdown';
import NumericInput from '../../../../../atoms/NumericInput/NumericInput';
import InlineMultiSelect from '../../../../../atoms/InlineEditing/InlineMultiSelect/InlineMultiSelect';
import GaInput from '../../../../../atoms/GaInput/GaInput';
import NotVisibleOverlay from '../../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';

import { slugDateObtainedMapping, conceptionDateOptions, eddOptions, prevUsOptions } from '../gaConstants';

const NONE = 'none';

const NonEditableGaItem = withTranslation()(
  ({
    t: __,
    data,
    gaItemProps,
    getEpisodeValueFromPlaceholders,
    onChangeEpisodeDatingField,
    examinationContext,
    reportMode,
    onEndEditing = () => {},
  }) => {
    const {
      currentExamDate,
      placeholders,
      updateRow,
      setAssignedGa,
      updatingAssignedGa,
      setUpdatingAssignedGa,
      fetusDropdownOptions,
      showFetusDropDown,
      reportData,
      splitSlug,
      appPreferences,
      print,
      currentLanguage,
    } = gaItemProps;

    const preferredDateFormat = appPreferences?.date_format;

    const conceptionDateOptionsTranslated = useMemo(
      () => conceptionDateOptions.map((o) => ({ ...o, label: __(o.label) })),
      [conceptionDateOptions]
    );
    const eddOptionsTranslated = useMemo(
      () => eddOptions.map((o) => ({ ...o, label: __(o.label), description: __(o.description) })),
      [eddOptions]
    );
    const prevUsOptionsTranslated = useMemo(
      () => prevUsOptions.map((o) => ({ ...o, label: __(o.label), description: __(o.description) })),
      [prevUsOptions]
    );

    if (!data) return false;
    const { slug, fetus, visible } = data;
    const { method, standard } = splitSlug(slug);

    const getDefaultLabel = () => {
      if (method === NONE) return '';
      let defaultLabel = reportData?.dating_methods?.[slug]?.label?.[currentLanguage] || slug;
      if (standard !== NONE) {
        const standardLabel = reportData?.dating_standards?.[standard]?.[currentLanguage] || false;
        defaultLabel += standardLabel ? ` (${standardLabel})` : '';
      }
      return defaultLabel;
    };

    const getPlaceholderData = () => {
      const label = data.label || getDefaultLabel();
      const patientValue = reportData?.dating_methods?.[slug]?.patient_value;
      if (!placeholders?.[slug]?.[data.fetus]) {
        return { ...data, label, patientValue };
      } else {
        return {
          ...placeholders[slug][data.fetus],
          ...data,
          label,
          patientValue,
        };
      }
    };

    const {
      examId = false,
      isAssigned = false,
      value = false,
      dateObtained = undefined,
      rowId,
      label,
    } = getPlaceholderData();

    if (print) {
      if (slug === 'ga.conception_date' && getEpisodeValueFromPlaceholders('conception_method')) null;
      else if (!value) return false;
    }

    const onChange = (valueToUpdate) => (value) => {
      updateRow({ [valueToUpdate]: value }, rowId);
    };

    const [loading, setLoading] = useState(false);

    const onChangeField = (fieldName) => async (value) => {
      setLoading(true);
      await onChangeEpisodeDatingField(fieldName, value);
      setLoading(false);
    };

    const [clickedAssignedGa, setClickedAssignedGa] = useState(false);

    const onClickAssigned = async () => {
      setClickedAssignedGa(true);
      setUpdatingAssignedGa(true);
      await setAssignedGa(slug, examId, fetus === 0 ? null : fetus, value);
      setClickedAssignedGa(false);
      setUpdatingAssignedGa(false);
    };

    const showAsAssigned = () => (isAssigned && !updatingAssignedGa) || clickedAssignedGa;

    let extraOptions;
    switch (slug) {
      case 'ga.lmp':
        extraOptions = (
          <>
            {print ? (
              <>
                {/*
                            {getEpisodeValueFromPlaceholders("cycle_length") ?
                                <div className="ga-item-extra-info-text not-printable">
                                    Cycle length:
                                </div> : ""
                            }
                            <div>{getEpisodeValueFromPlaceholders("cycle_length")} {getEpisodeValueFromPlaceholders("cycle_length") ? __("report.editGa.days") : ""}</div>
                        */}
              </>
            ) : (
              <>
                <div className="ga-item-extra-info-text">
                  {__('report.gatable.cycleLength')}
                  {__(':')}
                </div>
                <div>
                  <NumericInput
                    value={getEpisodeValueFromPlaceholders('cycle_length')}
                    onChange={onChangeField('cycle_length')}
                  />
                </div>
                <div className="ga-item-extra-info-text" style={{ paddingLeft: '1rem' }}>
                  {__('report.editGa.days')}
                </div>
              </>
            )}
          </>
        );
        break;
      case 'ga.conception_date':
        extraOptions = (
          <>
            <div className="ga-item-extra-info-text">
              {__('report.gatable.conceptionMethod')}
              {__(':')}
            </div>
            <div>
              <InlineDropdown
                value={getEpisodeValueFromPlaceholders('conception_method')}
                options={conceptionDateOptionsTranslated}
                printable={true}
                onChange={onChangeField('conception_method')}
              />
            </div>
          </>
        );
        break;
      case 'ga.embryo_transfer':
        extraOptions = (
          <>
            <div className="ga-item-extra-info-text">
              {__('report.gatable.ivfEt')}
              {__(':')}
            </div>

            {print ? (
              <div>
                {getEpisodeValueFromPlaceholders('embryo_transfer_day')}{' '}
                {getEpisodeValueFromPlaceholders('embryo_transfer_day') ? __('report.editGa.days') : ''}
              </div>
            ) : (
              <>
                <div>
                  <NumericInput
                    value={getEpisodeValueFromPlaceholders('embryo_transfer_day')}
                    onChange={onChangeField('embryo_transfer_day')}
                  />
                </div>
                <div className="ga-item-extra-info-text" style={{ paddingLeft: '1rem' }}>
                  {__('report.editGa.days')}
                </div>
              </>
            )}
          </>
        );
        break;
      case 'ga.prev_ultrasound':
        extraOptions = (
          <>
            <div className="ga-item-extra-info-text">
              {__('report.gatable.basedUpon')}
              {__(':')}
            </div>

            <div className="margin-right-2">
              <InlineMultiSelect
                value={getEpisodeValueFromPlaceholders('prev_ultrasound_option')}
                options={prevUsOptionsTranslated}
                printable={visible}
                multiple={false}
                fullwidth={true}
                onChange={onChangeField('prev_ultrasound_option')}
              />
            </div>
            <div>
              {getEpisodeValueFromPlaceholders('prev_ultrasound_option') === 'ga' ? (
                print ? (
                  getNiceGestionalAgeFromDays(__, Math.floor(getEpisodeValueFromPlaceholders('prev_ultrasound_ga')))
                ) : (
                  <GaInput
                    value={getEpisodeValueFromPlaceholders('prev_ultrasound_ga')}
                    onChange={onChangeField('prev_ultrasound_ga')}
                  />
                )
              ) : (
                <>
                  <NumericInput
                    value={getEpisodeValueFromPlaceholders('prev_ultrasound_biometry_value')}
                    onChange={onChangeField('prev_ultrasound_biometry_value')}
                    suffix="mm"
                    step={0.1}
                  />
                </>
              )}
            </div>
          </>
        );
        break;
      case 'ga.edd':
        extraOptions = (
          <div>
            <InlineMultiSelect
              value={getEpisodeValueFromPlaceholders('edd_methods')}
              options={eddOptionsTranslated}
              printable={visible}
              multiple={true}
              fullwidth={true}
              onChange={onChangeField('edd_methods')}
            />
          </div>
        );
        break;
      default:
        extraOptions = <></>;
    }

    return (
      <div className={`ga-item ${visible ? 'is-visible' : 'not-visible'} ${showFetusDropDown ? 'show-fetus' : ''}`}>
        {/* Fetus */}
        {showFetusDropDown && (
          <div>
            <span className="ga-item-fetus-number">{fetusDropdownOptions?.[fetus]?.label || '-'}</span>
          </div>
        )}

        {/* Custom Label */}
        <div>
          <InlineInput
            value={label}
            format={'string'}
            printable={visible}
            onChange={onChange('label')}
            appPreferences={appPreferences}
          />
        </div>

        {/* Scan Date */}
        <div className="ga-item-center">
          <InlineInput
            value={dateObtained}
            format="date"
            printable={visible}
            onChange={onChangeField(slugDateObtainedMapping[slug])}
            appPreferences={appPreferences}
          />
        </div>

        {/* Extra data */}
        <div className="ga-item-non-editable-details">{extraOptions}</div>

        {/* Current GA */}
        <div className="ga-item-center">
          {loading
            ? `${__('common.loading')}...`
            : value
            ? getNiceGestionalAgeFromDays(__, Math.floor(value))
            : print
            ? ''
            : __('report.gatable.unavailable')}
        </div>

        {/* EDD */}
        <div className="ga-item-center">
          {loading
            ? '...'
            : computeEstimatedDeliveryDate(
                value,
                currentExamDate,
                preferredDateFormat,
                examinationContext?.examination.site.timezone,
                examinationContext?.pregnancyLengthInDays
              )}
        </div>

        {/* Assigned */}
        {!print && (
          <div className="ga-item-assign ga-item-center" onClick={() => (value ? onClickAssigned() : null)}>
            <input type="radio" readOnly checked={showAsAssigned()} />
          </div>
        )}
        {/* Editing options */}
        {visible && reportMode === 'edit' && (
          <div className="exam-report-editing-options">
            <div onClick={() => onChange('visible')(!visible)}>
              <Icon name={visible ? 'eye' : 'eye-off'} />
            </div>
          </div>
        )}
        {!visible && reportMode === 'edit' && <NotVisibleOverlay onClick={() => onChange('visible')(!visible)} />}
      </div>
    );
  }
);

export default NonEditableGaItem;
