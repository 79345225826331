import { createContext, useState } from 'react';

export const ExaminationSharingContext = createContext({});

export const ExaminationSharingContextProvider = ({ children }) => {
  const [generallySharedDocuments, setGenerallySharedDocuments] = useState([]);
  const [sharingDocuments, setSharingDocuments] = useState([]);
  const [anonymizeToggle, setAnonymizeToggle] = useState(true);
  const [annotateToggle, setAnnotateToggle] = useState(false);
  const [documentsToggle, setDocumentsToggle] = useState(true);

  return (
    <ExaminationSharingContext.Provider
      value={{
        anonymizeToggle,
        annotateToggle,
        documentsToggle,
        sharingDocuments,
        setAnonymizeToggle,
        setAnnotateToggle,
        setDocumentsToggle,
        setSharingDocuments,
        generallySharedDocuments,
        setGenerallySharedDocuments,
      }}
    >
      {children}
    </ExaminationSharingContext.Provider>
  );
};
