import createProvider from '../initialProvider';
import useReducer, { defaultExamSharingState } from './reducer';

export const {
  Provider: ExamSharingProvider,
  useState: examSharingUseState,
  useDispatch: useExamSharingDispatch,
  getState: getExamSharingState,
  dispatch: examSharingDispatch,
} = createProvider(useReducer, defaultExamSharingState, 'ExamSharingProvider');

export const useExamSharingState = () => examSharingUseState().data;
