import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getCurrentLanguageCode } from './utils';
import LanguageDetector from 'i18next-browser-languagedetector';

// eslint-disable-next-line camelcase
import translation_en_US from './locales/en/translation.json';
// eslint-disable-next-line camelcase
import soniopedia_en_US from './locales/en/soniopedia.json';
// eslint-disable-next-line camelcase
import translation_fr_FR from './locales/fr/translation.json';
// eslint-disable-next-line camelcase
import soniopedia_fr_FR from './locales/fr/soniopedia.json';
// eslint-disable-next-line camelcase
import translation_de_DE from './locales/de/translation.json';
// eslint-disable-next-line camelcase
import soniopedia_de_DE from './locales/de/soniopedia.json';
// eslint-disable-next-line camelcase
import translation_ptbr_PTBR from './locales/ptbr/translation.json';
// eslint-disable-next-line camelcase
import soniopedia_ptbr_PTBR from './locales/ptbr/soniopedia.json';

const currentLanguage = getCurrentLanguageCode();
const browserLanguage = window.navigator.userLanguage || window.navigator.language;
if (browserLanguage !== currentLanguage) {
  console.warn(`🗣 The language "${browserLanguage}" is not supported, falling back to "${currentLanguage}"`);
}

const warningsEnabled = localStorage.getItem('i18nDebugWarnings');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,

    lang: currentLanguage,
    fallbackLng: 'en',

    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
      if (warningsEnabled === 'true')
        console.error(`🗣 Translation for "${key}" is missing in the "${currentLanguage}" language`);
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    nsSeparator: false, // allow keys to be phrases having `:`, `.`

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en: {
        translations: translation_en_US,
        soniopedia: soniopedia_en_US,
      },
      fr: {
        translations: translation_fr_FR,
        soniopedia: soniopedia_fr_FR,
      },
      de: {
        translations: translation_de_DE,
        soniopedia: soniopedia_de_DE,
      },
      ptbr: {
        translations: translation_ptbr_PTBR,
        soniopedia: soniopedia_ptbr_PTBR,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations', 'soniopedia'],
    defaultNS: 'translations',
    fallbackNS: 'translations',
  });

if (localStorage.getItem('i18nextLng') !== currentLanguage) {
  i18n.changeLanguage(currentLanguage);
}
