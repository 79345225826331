import { isNullOrUndefined } from '../../utils';
import { convertValueToSelectedUnit } from '../../unitConverter';
import { MeasurementDefaultUnits } from '../../config';

import { getPlaceholder, getFetusIdFromOrder, getFetusOrderFromId, allOrderedFetuses } from './placeholders';

/*
 * @type {Props} - XML props of the condition block statement
 */

/*
 * @desc - Try to rerender the XML tag from received props of the component
 * @param {Props} props - XML props of the component
 * @return {string} - XML tag string
 * @example
 * // returns "<content data="examination.status">"
 * propsToTag({type: "content", data: "examination.status"})
 */
export const propsToTag = (props) => {
  const { type: _, ...rest } = props;
  const attributes = Object.entries(rest)
    .filter(([_, value]) => typeof value === 'string')
    .map(([key, value]) => `${key}="${value}"`)
    .join(' ');

  const attributesString = attributes ? ` ${attributes}` : '';
  return `<${props.type}${attributesString}>`;
};

const getDataAttributeSlugs = (dataAttribute, custom) => {
  if (typeof dataAttribute !== 'string') {
    console.error('Attribute is not a string', dataAttribute);
    return [];
  }
  return dataAttribute.includes('|')
    ? dataAttribute
        .split('|')
        .map((v) => (custom ? 'custom.' : '') + v.replace(/&/g, '').trim())
        .filter((v) => v !== '')
    : dataAttribute
        .split('&')
        .map((v) => (custom ? 'custom.' : '') + v.replace(/|/g, '').trim())
        .filter((v) => v !== '');
};

const getDataAttributeOperator = (dataAttribute) => {
  if (typeof dataAttribute !== 'string') {
    console.error('Attribute is not a string', dataAttribute);
    return 'AND';
  }
  return dataAttribute.includes('|') ? 'OR' : 'AND';
};

/*
 * @desc - Extracts placeholders IDs from the props passed to a XML component
 * @param {Props} props - XML props of the component
 * @return {string} - placeholder ID applying to this component (extracted from the data attribute)
 * @example
 * // returns "examination.status"
 * placeholderIdFromProps({data: "examination.status"})
 *
 * // returns "custom.examination.status"
 * placeholderIdFromProps({data: "examination.status", custom: "true"})
 */
export const placeholderIdFromProps = (props) => {
  return `${props.custom === 'true' ? 'custom.' : ''}${props.data}`;
};

/*
 * @desc - Extracts placeholders IDs required for the evaluation of the condition block statement
 *         This helps us define the condition block placeholder requirement squeleton
 *
 * @param {Props} props - XML props of the condition block statement
 * @return {Array} - Array of placeholders IDs
 *
 * @example
 * // returns ["examination.status"]
 */
export const conditionRequiredPlaceholders = (props) => {
  const { data: dataAttribute, custom = false } = props;
  return getDataAttributeSlugs(dataAttribute, custom);
};

/*
 * @param {Props} props - XML props of the condition block statement
 * @param {Number} fetus - Fetus index
 * @param {Object} fetchedPlaceholders - Placeholders fetched from the report relative data
 * @return {Boolean} - True if the condition is met, false otherwise
 *
 * @example
 * // TODO write an example (also we can use in test)
 */
export const checkCondition = (props, fetus = 0, fetchedPlaceholders, apiVersion) => {
  let { data: dataAttribute, attribute, includes = '', is = '', to = '', than = '', low = '', high = '' } = props;
  if (!dataAttribute) return false;

  if (!than && to) than = to; // to is an alias of than
  if (includes) is = 'includes';
  if (!is) is = 'not-empty';

  const placeholders = [];
  const operator = getDataAttributeOperator(dataAttribute);
  const dataList = conditionRequiredPlaceholders(props);
  const includesList = getDataAttributeSlugs(includes || '');

  let isTrueAccordingToOperator = operator === 'AND';

  for (const placeholderData of dataList) {
    let placeholder;
    if (['2.0'].includes(apiVersion)) {
      placeholder = { ...fetchedPlaceholders.selectedValue(placeholderData) };
    } else {
      placeholder = fetchedPlaceholders[placeholderData];
    }

    if (!Array.isArray(placeholder)) {
      if (!Object.prototype.hasOwnProperty.call(placeholder, 'value')) placeholder.value = null;
      if (!Object.prototype.hasOwnProperty.call(placeholder, 'normal')) placeholder.normal = null;
      if (!Object.prototype.hasOwnProperty.call(placeholder, 'viewed')) placeholder.viewed = null;
    }

    if (['2.0'].includes(apiVersion)) {
      placeholder.valueToCheck = attribute ? placeholder?.[attribute] || false : placeholder?.value;
    } else {
      if (fetus && placeholder[0] && !placeholder[fetus]) fetus = 0; // temporary workaround for patient measurements
      const fetusPlaceholder = !Array.isArray(placeholder)
        ? !Array.isArray(placeholder.value)
          ? placeholder
          : { ...placeholder, value: placeholder.value[fetus] }
        : fetus
        ? placeholder[fetus]
        : placeholder.find((elm) => elm);
      placeholder.valueToCheck = attribute ? fetusPlaceholder?.[attribute] || false : fetusPlaceholder?.value;
      if (!!placeholder.value && typeof placeholder.value !== 'object') {
        placeholder.valueToCheck = placeholder.valueToCheck ? `${placeholder.valueToCheck}` : false; // to string only if set -> important for matching empty/not-empty statuses
      }
    }

    placeholders.push(placeholder);
  }

  for (const placeholder of placeholders) {
    let isTrue = false;
    const valueToCheck = placeholder.valueToCheck;
    const normal = placeholder.normal;
    const viewed = placeholder.viewed;

    switch (is) {
      case 'empty':
      case '':
        isTrue = !valueToCheck;
        break;

      case 'not-empty':
      case 'not_empty':
        isTrue = !!valueToCheck;
        break;

      case 'equal':
      case '=':
        if (valueToCheck !== null && than !== null) {
          isTrue = `${valueToCheck}`.toLowerCase() === `${than}`.toLowerCase();
        }
        break;

      case 'not-equal':
      case 'not_equal':
      case '!=':
        if (valueToCheck !== null && than !== null) {
          isTrue = `${valueToCheck}`.toLowerCase() !== `${than}`.toLowerCase();
        }
        break;

      case 'greater':
      case '>':
        isTrue = Number(valueToCheck) > Number(than);
        break;

      case 'greater_or_equal':
      case 'greater-or-equal':
      case 'greater or equal':
      case '>=':
        isTrue = Number(valueToCheck) >= Number(than);
        break;

      case 'less':
      case 'lower':
      case '<':
        isTrue = Number(valueToCheck) < Number(than);
        break;

      case 'less_or_equal':
      case 'lower_or_equal':
      case 'less-or-equal':
      case 'lower-or-equal':
      case 'less or equal':
      case 'lower or equal':
      case '<=':
        isTrue = Number(valueToCheck) <= Number(than);
        break;

      case 'between':
      case '><':
        if (low !== null && high !== null) {
          isTrue = Number(valueToCheck) <= Number(high) && Number(valueToCheck) >= Number(low);
        }
        break;

      case 'not-between':
      case 'not_between':
      case '<>':
        if (low !== null && high !== null) {
          isTrue = Number(valueToCheck) > Number(high) || Number(valueToCheck) < Number(low);
        }
        break;

      case 'normal':
        isTrue = !!normal;
        break;

      case 'not_normal':
      case 'not-normal':
        isTrue = normal !== null && !normal;
        break;

      case 'viewed':
        isTrue = !!viewed;
        break;

      case 'not_viewed':
      case 'not-viewed':
        isTrue = viewed !== null && !viewed;
        break;

      case 'includes':
        // TODO: check includesList by operator, at now everything is always considered as OR
        isTrue =
          !!valueToCheck && typeof valueToCheck === 'object'
            ? includesList.some((v) => !!valueToCheck[v])
            : valueToCheck?.includes(includes);
        break;
    }

    if (operator === 'AND') {
      isTrueAccordingToOperator = (isTrueAccordingToOperator && isTrue && true) || false;
    } else if (operator === 'OR' && isTrue) {
      isTrueAccordingToOperator = true;
    }
  }

  return isTrueAccordingToOperator;
};

/*
 * @desc - Return the placeholder ID from props of measurement-curve component.
 * @param {Props} props - XML props of the component
 * @return {string} - Placeholder ID
 *
 * @example
 * // returns "measurement.bpd"
 * measurementCurvePlaceholderId({data: "bpd"})
 */
export const measurementCurvePlaceholderId = (props) => {
  // TODO This is super dumb we should always use placehodlers as they come
  // We will need to fix once we can avoid caring about backwards compatibility
  if (!props.data)
    throw new Error(`measurement-curve must always come with a data attribute.\nIn ${propsToTag(props)}`);
  return `measurement.${placeholderIdFromProps(props)}`;
};

/*
 * @desc - Return the placeholder from props of measurement-curve component.
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @param {String} apiVersion - API version
 * @return {Object} - Placeholder object
 *
 * TODO @example
 */
export const measurementCurvePlaceholder = (props, placeholders, apiVersion, fetus = null) => {
  if (apiVersion === '1.0' || apiVersion === '1.1' || !apiVersion)
    return measurementCurvePlaceholderV1(props, placeholders);
  if (apiVersion === '2.0') return measurementCurvePlaceholderV2(props, placeholders, apiVersion, fetus);
};

function measurementCurvePlaceholderV1(props, placeholders, _apiVersion) {
  const placeholderId = measurementCurvePlaceholderId(props);
  const fetus = Number(props.fetus || 1);
  const originalPlaceholder = placeholders[placeholderId];
  return Array.isArray(originalPlaceholder) ? originalPlaceholder[fetus] : originalPlaceholder;
}

function measurementCurvePlaceholderV2(props, placeholders, apiVersion, fetus = null) {
  const placeholderId = measurementCurvePlaceholderId(props);
  const fetusIndex = Number(fetus || props.fetus || 1);
  if (!placeholders[placeholderId]) return null;
  if (!placeholders['fetus.order']) return null;
  return getPlaceholder(
    apiVersion,
    placeholders[placeholderId],
    getFetusIdFromOrder(apiVersion, placeholders['fetus.order'], fetusIndex)
  );
}

/* Return all the measurement placeholders value as an array ordered by report fetus id
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @param {String} apiVersion - API version
 * @return {Array} - Array of placeholders values
 */
export const measurementCurveAllPlaceholders = (props, placeholders, apiVersion) => {
  if (apiVersion === '1.0' || apiVersion === '1.1' || !apiVersion)
    return measurementCurveAllPlaceholdersV1(props, placeholders);
  if (apiVersion === '2.0') return measurementCurveAllPlaceholdersV2(props, placeholders, apiVersion);
};

function measurementCurveAllPlaceholdersV1(props, placeholders) {
  const placeholderId = measurementCurvePlaceholderId(props);
  return placeholders[placeholderId];
}

function measurementCurveAllPlaceholdersV2(props, placeholders, apiVersion) {
  const placeholderId = measurementCurvePlaceholderId(props);
  const allFetuses = allOrderedFetuses(apiVersion, placeholders['fetus.order']);
  return allFetuses.map((fetus) => getPlaceholder(apiVersion, placeholders[placeholderId], fetus));
}

/*
 * @desc - Return the curve slug from props of measurement-curve component.
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {string} - Curve slug
 *
 * TODO @example
 */
export const measurementCruveSlug = (props, placeholders, apiVersion, fetus = null) => {
  // Priority - 1: Report edit, 2: xml prop, 3: Hadlock, 4: first available
  const placeholder = measurementCurvePlaceholder(props, placeholders, apiVersion, fetus);
  try {
    if (apiVersion === '2.0') {
      return (
        placeholder?.curve_slug ??
        (props.curve ? `${props.data}.${props.curve}` : null) ??
        placeholder?.available_cruve_slugs.find((slug) => slug.includes('hadlock')) ??
        placeholder?.available_cruve_slugs[0]
      );
    } else {
      return (
        placeholder?.curve_slug ??
        (props.curve ? `${props.data}.${props.curve}` : null) ??
        placeholder?.availableCurveSlugs.find((slug) => slug.includes('hadlock')) ??
        placeholder?.availableCurveSlugs[0]
      );
    }
  } catch (e) {
    console.error('Error while searching for measurement curve', {
      props,
      apiVersion,
    });
    throw e;
  }
};

export const measurementCurve = (props, placeholders, reportDataOptions, apiVersion, fetus = null) => {
  const slug = measurementCruveSlug(props, placeholders, apiVersion, fetus);
  return reportDataOptions?.curves?.[slug];
};

/*
 * @desc - Check if the measurement graph is visible
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {Boolean} - True if the measurement graph is visible, false otherwise
 *
 * TODO @example
 */
export const isMeasurementCurveVisible = (props, placeholders, apiVersion) => {
  const placeholder = measurementCurvePlaceholder(props, placeholders, apiVersion);
  if (!placeholder) return false;
  const propsVisible = !isNullOrUndefined(props.visible) ? props.visible !== 'false' : null;
  /* For the moment measurement visibility is still in the value
   * We need to move it on the examination data level */
  return placeholder.visible_graph ?? propsVisible ?? false;
};

export const measurementCurveData = (props, placeholders, reportDataOptions, apiVersion, fetus = null) => {
  fetus = Number(fetus || props.fetus || 1);
  const placeholder = measurementCurvePlaceholder(props, placeholders, apiVersion, fetus);
  const curve = measurementCurve(props, placeholders, reportDataOptions, apiVersion, fetus);

  const measurementId = props.data?.split('.')[0].split('/')[0];
  const storedUnit = MeasurementDefaultUnits[reportDataOptions?.labels?.measurement?.[measurementId]?.type];
  const displayUnit =
    props.units ||
    (isNullOrUndefined(placeholder?.display_units) || placeholder?.display_units === 'null'
      ? ''
      : placeholder.display_units);

  const yAxis = { ...placeholder, units: displayUnit };
  const xAxisId = curve?.horizontal_axis_id;
  const xAxis = { id: xAxisId };
  const isAtRisk = placeholder?.sonio_percentile > 97 || placeholder?.sonio_percentile < 3;

  const allPlaceholders = measurementCurveAllPlaceholders(props, placeholders, apiVersion);

  // Retrieve current and previous measurements for all fetuses
  const withFetuses = (placeholder, currentFetus) => ({
    placeholder,
    currentFetus: currentFetus,
  });
  const allMeasurements = (
    fetus === 0 ? [allPlaceholders.map(withFetuses)[0]] : allPlaceholders.map(withFetuses).slice(1)
  )
    .filter(({ placeholder }) => placeholder)
    .map(({ placeholder: { value, xvalue }, currentFetus }) => {
      const previousMeasurements = getPreviousMeasurements(currentFetus, measurementId, reportDataOptions);
      return [{ examId: 'current', value, xvalue }, ...(previousMeasurements || [])]
        .sort((a, b) => a.xvalue - b.xvalue) // Sort by GA
        .reduce((acc, elem) => {
          let xvalue;

          if (xAxisId === 'ga') xvalue = elem.xvalue;
          else {
            const xAxisPlaceholder =
              xAxisId && measurementCurveAllPlaceholders({ ...props, data: xAxisId }, placeholders, apiVersion);
            if (xAxisPlaceholder)
              xvalue =
                elem?.examId === 'current'
                  ? xAxisPlaceholder?.[currentFetus]?.value
                  : getPreviousMeasurements(currentFetus, xAxisId, reportDataOptions)?.find(
                      (m) => m?.examId === elem?.examId
                    )?.value;
          }
          if (xvalue)
            acc.push({
              value: convertValueToSelectedUnit(elem.value, storedUnit, displayUnit),
              xvalue,
            });
          return acc;
        }, []);
    });

  return {
    placeholder,
    fetus,
    allMeasurements,
    xAxis,
    yAxis,
    isAtRisk,
    storedUnit,
    displayUnit,
  };
};

function getPreviousMeasurements(fetusId, measurementId, reportDataOptions) {
  return Object.entries(reportDataOptions?.previous_exams)
    .map(([examId, examData]) => {
      const measurements = examData?.measurements?.measurements;
      const selectedValue = (fetusId === 0 ? measurements?.patient : measurements?.fetus?.[`${fetusId}`])?.[
        measurementId
      ]?.selected_value;

      return { examId, value: selectedValue?.y, xvalue: selectedValue?.x };
    })
    .filter((v) => v.value && v.xvalue);
}

/*
 * @desc - Check if the measurement graph is empty
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {Boolean} - True if the measurement graph is empty, false otherwise
 */
export const isMeasurementCurveEmpty = (props, placeholders, reportDataOptions, apiVersion) => {
  const placeholderId = measurementCurvePlaceholderId(props);
  const placeholder = placeholders[placeholderId];
  if (!placeholder) {
    return true;
  }

  if (apiVersion !== '2.0') {
    return isMeasurementCurveEmptyForFetus(props, placeholders, reportDataOptions, apiVersion, null);
  }

  return placeholder.data.reduce((acc, data) => {
    const index = getFetusOrderFromId(apiVersion, placeholders['fetus.order'], data.examination_fetus_id);
    if (!isMeasurementCurveEmptyForFetus(props, placeholders, reportDataOptions, apiVersion, index)) {
      acc = false;
    }
    return acc;
  }, true);
};

export const isMeasurementCurveEmptyForFetus = (props, placeholders, reportDataOptions, apiVersion, fetus) => {
  const placeholder = measurementCurvePlaceholder(props, placeholders, apiVersion, fetus);
  if (!placeholder) return true;
  const curve = measurementCurve(props, placeholders, reportDataOptions, apiVersion, fetus);
  const { allMeasurements } = measurementCurveData(props, placeholders, reportDataOptions, apiVersion, fetus);

  /* If no measurement data is available, the curve is considered empty */
  if (!allMeasurements?.length || !allMeasurements[0]?.length) return true;
  return !placeholder || placeholder?.value === false || !curve;
};

export const measurementCurveRequiredPlaceholders = (props, placeholders, reportDataOptions, apiVersion) => {
  const fieldId = measurementCurvePlaceholderId(props);
  const curve = measurementCurve(props, placeholders, reportDataOptions, apiVersion);

  const xAxisId = curve?.horizontal_axis_id;
  const xAxisRequiredField = xAxisId ? [`measurement.${xAxisId}`] : [];
  return [...xAxisRequiredField, fieldId, 'fetus.number', 'fetus.order'];
};
