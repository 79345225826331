import { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import ResourceApi from '../../services/resource';

import { useXMLTemplate } from '../../context-providers/XMLTemplate';
import { ExaminationContext } from '../../context-providers/Examination';

import { useXMLDocumentPrinter, XMLDocument } from '../../components/XMLDocument';

import useStability from '../../hooks/useStability';

function buildMultipartForm(html, json) {
  // Build a form with the HTML content as blob
  const formData = new FormData();
  const htmlBlob = new Blob([html], { type: 'text/html' });
  formData.append('html', htmlBlob);
  if (json) {
    const jsonBlob = new Blob([JSON.stringify(json)], { type: 'text/html' });
    formData.append('json', jsonBlob);
  }

  return formData;
}

function blueprintKey(documentType) {
  switch (documentType) {
    case 'report':
      return 'report_template';
    case 'medical_history':
      return 'medical_history_template';
    default:
      throw new Error(`Unknown document type: ${documentType}`);
  }
}

export default function ReportWeb() {
  const { examId } = useParams();
  const location = useLocation();
  const documentType = new URLSearchParams(location.search).get('document_type') || 'report';
  const examinationContext = useContext(ExaminationContext);
  const XMLPrinter = useXMLDocumentPrinter({ page: documentType });
  const { reportDataOptions, placeholders, stable: xmlStability, loaded } = useXMLTemplate();
  const [placeholderStabilityTimeout, setPlaceholderStabilityTimeout] = useState(1);
  const [base64Images, setBase64Images] = useState([]);
  const reportContentRef = useRef(null);
  const [printing, setPrinting] = useState(false);
  const stable = useStability(1000, 100, printing || !xmlStability, [printing, xmlStability]);

  const templateBlueprint =
    examinationContext.debugTemplate || reportDataOptions?.[blueprintKey(documentType)]?.blueprint;

  const renderReportHtml = async (signal) =>
    await XMLPrinter({
      base64Images: [],
      templateBlueprint,
      signal,
    });

  useEffect(() => {
    /* Load placeholders for report HTML */
    if (!templateBlueprint) return;
    if (!reportDataOptions) return;
    setPrinting(true);
    const controller = new AbortController();
    renderReportHtml(controller.signal)
      .then(() => setPrinting(false))
      .catch((e) => {
        if (e === controller.signal) return;
        throw e;
      });
    return () => controller.abort('New rendering from scratch requested...');
  }, [templateBlueprint, reportDataOptions]);

  useEffect(() => {
    setPlaceholderStabilityTimeout(setTimeout(() => setPlaceholderStabilityTimeout(null), 5000));
    return () => clearTimeout(placeholderStabilityTimeout);
  }, [placeholders, setPlaceholderStabilityTimeout]);

  useEffect(() => {
    examinationContext.loadExamination(examId);
  }, [examId]);

  useEffect(() => {
    if (placeholderStabilityTimeout !== null) return;
    if (stable === false) return;
    if (loaded === false) return;
    document.body.setAttribute('ready-to-print', 'true');
  }, [!!placeholderStabilityTimeout, stable]);

  const writeEditReportHtml = async () => {
    /*
     * Fetching the XMLDocument content.
     * There might be better way of doing it but for the moment it is the smoother I can think of
     */
    const HTML = Array.from(reportContentRef.current.children)
      .map((c) => c.outerHTML)
      .join('\n');
    return ResourceApi.writeDocumentHtml(
      examinationContext.examination.id,
      'edit',
      documentType,
      buildMultipartForm(HTML)
    );
  };

  const writePrintReportHtml = async () => {
    const HTML = await renderReportHtml();
    return ResourceApi.writeDocumentHtml(
      examinationContext.examination.id,
      'print',
      documentType,
      buildMultipartForm(HTML)
    );
  };

  const writeDocumentHtml = async () => {
    const resEdit = await writeEditReportHtml();
    const resPrint = await writePrintReportHtml();
    return [resEdit, resPrint];
  };

  window.writeDocumentHtml = writeDocumentHtml;

  return (
    <div ref={reportContentRef} className="exam-report-content">
      <XMLDocument
        templateBlueprint={templateBlueprint}
        page={documentType}
        showErrors={false}
        submitClicked={false}
        reportMode="edit"
        renderMode="freeze"
        base64Images={base64Images}
        setBase64Images={setBase64Images}
      />
    </div>
  );
}
