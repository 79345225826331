import { Redirect, Route, Switch } from 'react-router-dom';

/* Contexts */
import { AppContextProvider } from './context-providers/App';
import useAuth, { AuthProvider } from './context-providers/Auth';
import { DxContextProvider } from './context-providers/Dx';
import { ExaminationContextProvider } from './context-providers/Examination';
import { ExaminationSharingContextProvider } from './context-providers/ExaminationSharing';
import { LiveExaminationContextProvider } from './context-providers/LiveExamination';
import { LiveSessionEventContextProvider } from './context-providers/LiveSessionEvent';
import SocketProvider from './context-providers/Socket';
import UnauthenticatedProvider from './context-providers/Unauthenticated';
import BaseLayout from './layouts/BaseLayout';
import WindowLayout from './layouts/WindowLayout';
import { useAuthentificate } from './useAuthentificate';
import { WindowContextProvider } from './context-providers/Window';
import { XMLTemplateContextProvider } from './context-providers/XMLTemplate';
import { MeasurementsContextProvider } from './context-providers/Measurements';
import { AmbientListeningContextProvider } from './context-providers/AmbientListening';

import { ExamSharingProvider } from './providers/examSharing';

/* Pages */
import AuthUplink from './pages/AuthUplink';
import CodeAuthenticate from './pages/CodeAuthenticate';
import Configuration from './pages/Configuration';
import Dashboard from './pages/Dashboard';
import DxChecklist from './pages/DxChecklist';
import ExaminationLive from './pages/ExaminationLive';
import ReportPrint from './pages/Report/Print';
import ReportWeb from './pages/Report/Web';
import ExaminationMedicalHistory from './pages/ExaminationMedicalHistory';
import ExaminationReview from './pages/ExaminationReview';
import Exams from './pages/Exams';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Patients from './pages/Patients';
import Preferences from './pages/Preferences';
import PrintingSharingSettings from './pages/PrintingSharingSettings';
import PatientApplicationSettings from './pages/PatientApplicationSettings';
import RegisterInvitedUser from './pages/RegisterInvitedUser';
import ResetPassword from './pages/ResetPassword';
import RiskFactors from './pages/RiskFactors';
import SharingPreferences from './pages/SharingPreferences';
import Simulator from './pages/Simulator';
import ImageManipulation from './pages/ImageManipulation';

import {
  ActivateSessionError,
  ActivateSessionSuccess,
  RegistrationUserSuccess,
  ResetPasswordSuccess,
} from './pages/StatusPages';
import TemplateManager from './pages/TemplateManager';

/* Utils */
import DebugPanel from './components/DebugPanel';
import Appointments from './pages/Appointments';
import UplinkConfiguration from './pages/UplinkConfiguration';
import WindowView from './pages/Window/WindowView';
import { ZoomLevelContextProvider } from './context-providers/ZoomLevel';

function RestrictedUserConfig(props) {
  const { isPractitioner, isFeatureFlagEnabled } = useAuth();
  if (isPractitioner && isFeatureFlagEnabled('sonio.prevent_config_edits')) return <Redirect to="/preferences" />;
  return props.children;
}

// Initialize XMLTemplateContextProvider only if the report feature flag is enabled
const XMLTemplateContextProviderForReport = ({ enabled = true, children }) => {
  if (enabled) {
    return <XMLTemplateContextProvider>{children}</XMLTemplateContextProvider>;
  }
  return children;
};

function LoginRedirectProvider({ ...props }) {
  const { isManager, isShadow, isFeatureFlagEnabled, loading } = useAuth();
  useAuthentificate();

  if (loading) return null;

  if (props.featureFlags) {
    for (const ff of props.featureFlags) {
      if (!isFeatureFlagEnabled(ff)) return <Redirect to="/" />;
    }
  }

  return (
    <AppContextProvider>
      <SocketProvider>
        {isManager != null &&
          isShadow != null &&
          (isManager ? (
            <Route {...props} />
          ) : (
            <LiveSessionEventContextProvider>
              <LiveExaminationContextProvider>
                <ExamSharingProvider>
                  <ExaminationSharingContextProvider>
                    <ExaminationContextProvider>
                      <ZoomLevelContextProvider>
                        <WindowContextProvider>
                          <DxContextProvider>
                            <MeasurementsContextProvider>
                              <XMLTemplateContextProviderForReport
                                enabled={
                                  isFeatureFlagEnabled('sonio.report') ||
                                  isFeatureFlagEnabled('sonio.medical_history_v2')
                                }
                              >
                                <AmbientListeningContextProvider>
                                  {isFeatureFlagEnabled('sonio.debug') && <DebugPanel />}
                                  <Route {...props} />
                                </AmbientListeningContextProvider>
                              </XMLTemplateContextProviderForReport>
                            </MeasurementsContextProvider>
                          </DxContextProvider>
                        </WindowContextProvider>
                      </ZoomLevelContextProvider>
                    </ExaminationContextProvider>
                  </ExaminationSharingContextProvider>
                </ExamSharingProvider>
              </LiveExaminationContextProvider>
            </LiveSessionEventContextProvider>
          ))}
      </SocketProvider>
    </AppContextProvider>
  );
}

function AuthenticatedRouteWithContexts({ ...props }) {
  return (
    <AuthProvider>
      <LoginRedirectProvider {...props} />
    </AuthProvider>
  );
}
function AuthenticatedRouteWithAuth({ ...props }) {
  return (
    <AuthProvider>
      <AuthenticatedRoute {...props} />
    </AuthProvider>
  );
}

function AuthenticatedRoute({ roles: _, ...props }) {
  useAuthentificate();

  return (
    <AppContextProvider>
      <Route {...props} />
    </AppContextProvider>
  );
}

function UnauthenticatedRoute({ children, withLanguageSelector, ...props }) {
  return (
    <Route {...props}>
      <UnauthenticatedProvider withLanguageSelector={withLanguageSelector}>{children}</UnauthenticatedProvider>
    </Route>
  );
}

const AppRoutes = () => {
  return (
    <Switch>
      <UnauthenticatedRoute path="/login/mfa">
        <AuthProvider>
          <CodeAuthenticate />
        </AuthProvider>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/login">
        <AuthProvider>
          <Login />
        </AuthProvider>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/forgot-password">
        <ForgotPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/activate-session/success">
        <ActivateSessionSuccess />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/activate-session/error">
        <ActivateSessionError />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/reset-password/success">
        <ResetPasswordSuccess />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/reset-password/:token">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/register/invite/:inviteCode">
        <RegisterInvitedUser />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute path="/register/success">
        <RegistrationUserSuccess />
      </UnauthenticatedRoute>
      <AuthenticatedRouteWithContexts path="/auth/uplink">
        <AuthUplink />
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/configuration">
        <RestrictedUserConfig>
          <BaseLayout>
            <Configuration />
          </BaseLayout>
        </RestrictedUserConfig>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/sharing-preferences">
        <RestrictedUserConfig>
          <BaseLayout>
            <SharingPreferences />
          </BaseLayout>
        </RestrictedUserConfig>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/manage-templates">
        <RestrictedUserConfig>
          <BaseLayout>
            <TemplateManager />
          </BaseLayout>
        </RestrictedUserConfig>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/risk-factors">
        <BaseLayout>
          <RiskFactors />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts featureFlags={['sonio.dx_v2']} path="/exam/:examId/dx">
        <BaseLayout>
          <DxChecklist />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/exam/:examId/report/print">
        <ReportPrint />
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/exam/:examId/report/web">
        <ReportWeb />
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/exam/:examId">
        <BaseLayout>
          <ExaminationLive />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/exam-review/:examId">
        <BaseLayout>
          <ExaminationReview />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/exam-anamnesis/:examId">
        <BaseLayout>
          <ExaminationMedicalHistory />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/dicom-instance-edit/:examId/:dicomInstanceId">
        <BaseLayout>
          <ImageManipulation />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/exams">
        <BaseLayout>
          <Exams />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      {/* TODO: use a private route hoc - https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs */}
      <AuthenticatedRouteWithContexts path="/patients">
        <BaseLayout>
          <Patients />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/preferences">
        <BaseLayout>
          <Preferences />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithAuth path="/simulator">
        <Simulator />
      </AuthenticatedRouteWithAuth>
      <AuthenticatedRouteWithContexts path="/printing-sharing-settings">
        <RestrictedUserConfig>
          <BaseLayout>
            <PrintingSharingSettings />
          </BaseLayout>
        </RestrictedUserConfig>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/patient-application-settings">
        <BaseLayout>
          <PatientApplicationSettings />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/uplink-configuration">
        <BaseLayout>
          <UplinkConfiguration />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <AuthenticatedRouteWithContexts path="/appointment">
        <BaseLayout>
          <Appointments />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      {/* split-screen / multi-window */}
      <AuthenticatedRouteWithContexts path="/window/:view/:examId?">
        <WindowLayout>
          <WindowView />
        </WindowLayout>
      </AuthenticatedRouteWithContexts>
      {/* / */}
      <AuthenticatedRouteWithContexts exact path="/">
        <BaseLayout>
          <Dashboard />
        </BaseLayout>
      </AuthenticatedRouteWithContexts>
      <UnauthenticatedRoute path="*">
        <NotFound />
      </UnauthenticatedRoute>
    </Switch>
  );
};

export default AppRoutes;
