import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import SyndromeLine from '../../../components-dx/SyndromeLine/SyndromeLine';
import { DxContext } from '../../../context-providers/Dx';
import { ExaminationContext } from '../../../context-providers/Examination';
import SyndromeDetails from './SyndromeDetails';
import './ChecklistSummaryView.css';
import MalformationDetails from './MalformationDetails';
import Button from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon/Icon';

const ChecklistSummaryView = ({
  t: __,
  syndromes,
  currentSyndrome,
  onSyndromeClick,
  onSyndromePinClick,
  onSyndromeHideClick,
  malformations = [],
  medicalHistory,
  allMedications,
}) => {
  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);

  const [userDefinedSyndromes, setUserDefinedSyndromes] = useState([]);
  const [hiddenSyndromes, setHiddenSyndromes] = useState([]);
  const [relevantSyndromes, setRelevantSyndromes] = useState([]);
  const [notRelevantSyndromes, setNotRelevantSyndromes] = useState([]);

  const threshold = 0.2;
  const syndromeVsMalformation = !syndromes?.length
    ? ''
    : relevantSyndromes.some((syndrome) => syndrome.probability >= threshold)
    ? relevantSyndromes.filter((syndrome) => syndrome.id > 0 && syndrome.probability >= threshold).length > 1
      ? 'syndromes'
      : relevantSyndromes.filter((syndrome) => syndrome.id > 0 && syndrome.probability >= threshold).length === 1
      ? 'syndrome'
      : relevantSyndromes.find((syndrome) => syndrome.id === 0)?.malformations.length > 1
      ? 'association'
      : 'isolated'
    : relevantSyndromes.some((syndrome) => syndrome.id === 0 && syndrome.malformations.length > 1)
    ? 'uncertain.association'
    : relevantSyndromes.some((syndrome) => syndrome.id === 0 && syndrome.malformations.length === 1)
    ? 'uncertain.isolated'
    : 'uncertain.syndromes';

  const isHealthy = !syndromes?.length && !userDefinedSyndromes.length;
  const readyToStop =
    examinationContext.examination.status !== 'completed' &&
    (dxContext.recommendation?.diagnostic_reliability?.continuous >= 0.975 ||
      Object.values(dxContext.zones).filter((zone) => zone.validated).length >= 5);

  useEffect(() => {
    if (!Object.keys(examinationContext.SYNDROMES).length) {
      setUserDefinedSyndromes([]);
      setHiddenSyndromes([]);
    } else {
      setUserDefinedSyndromes(
        dxContext.userOverrides.filter(
          (rule) =>
            rule.type === 'syndrome' && rule.action === 'add' && !syndromes.some((syndrome) => syndrome.id === rule.id)
        )
      );
      setHiddenSyndromes(
        dxContext.userOverrides.filter((rule) => rule.type === 'syndrome' && rule.action === 'remove')
      );
    }
  }, [examinationContext.SYNDROMES, dxContext.userOverrides, syndromes]);

  useEffect(() => {
    setRelevantSyndromes(
      syndromes
        ?.filter(
          (syndrome) =>
            !dxContext.userOverrides.some((s) => s.type === 'syndrome' && s.id === syndrome.id && s.action === 'remove')
        )
        .filter((syndrome, index) => syndrome.probability > 0.1 || index < 7)
        .map((syndrome) => ({
          ...syndrome,
          isPinned: dxContext.userOverrides.some(
            (rule) => rule.type === 'syndrome' && rule.id === syndrome.id && rule.action === 'add'
          ),
        })) || []
    );

    setNotRelevantSyndromes(
      syndromes
        ?.filter(
          (syndrome) =>
            !dxContext.userOverrides.some((s) => s.type === 'syndrome' && s.id === syndrome.id && s.action === 'remove')
        )
        .filter((syndrome, index) => syndrome.probability <= 0.1 && index >= 7)
        .map((syndrome) => ({
          ...syndrome,
          isPinned: dxContext.userOverrides.some(
            (rule) => rule.type === 'syndrome' && rule.id === syndrome.id && rule.action === 'add'
          ),
        })) || []
    );
  }, [syndromes, dxContext.userOverrides]);

  return (
    <div className={`dx-checklist-summary-container ${dxContext.checklistSummaryOpen ? 'show' : ''}`}>
      <div className="close-panel" onClick={() => dxContext.setChecklistSummaryOpen(false)}></div>
      <div className={`dx-checklist-summary ${currentSyndrome ? 'show-syndrome' : 'show-syndromes'}`}>
        <div className="dx-checklist-summary-syndromes">
          {isHealthy && (
            <div className="diagnostic-panel-is-healty">
              {dxContext.isLoadingRecommendation
                ? __('dx.checklist.warning.loadingSyndromes')
                : __('dx.checklist.isHealty')}
            </div>
          )}

          {!isHealthy && (
            <>
              <div className="dx-checklist-summary-main-panel">
                {syndromeVsMalformation && (
                  <div className="dx-checklist-summary-reliability">
                    <div className="dx-checklist-summary-syndrome-vs-malformation">
                      {__(`dx.summary.syndromeVsMalformation.${syndromeVsMalformation}`)}
                    </div>
                  </div>
                )}
                <div className="dx-checklist-summary-relevant-syndromes">
                  {relevantSyndromes.map((syndrome) => (
                    <SyndromeLine
                      key={syndrome.id}
                      syndrome={syndrome}
                      malformations={syndrome.next_recommended_malformations?.slice(0, 2) || []}
                      onPinClick={syndrome.isPinned ? onSyndromePinClick : false}
                      onClick={() => onSyndromeClick(syndrome)}
                      onMalformationClick={(malformationId) => {
                        const malformation = examinationContext.getMalformationById(malformationId);
                        if (!malformation) return false;
                        return dxContext.updateMalformation(
                          { ...malformation, signs: [], linked_signs: [] },
                          'yes',
                          true
                        );
                      }}
                      isHidden={dxContext.isLoadingRecommendation}
                    />
                  ))}
                </div>
                {!!userDefinedSyndromes.length && (
                  <div className="dx-checklist-summary-not-relevant-syndromes">
                    <h6>
                      <Icon name="pin" /> {__('dx.checklist.userDefinedDiagnoses')}
                    </h6>
                    {userDefinedSyndromes.map((syndrome) => (
                      <SyndromeLine
                        key={syndrome.id}
                        syndrome={syndrome}
                        onPinClick={onSyndromePinClick}
                        mode="compact"
                        onClick={() => onSyndromeClick(syndrome)}
                        isHidden={dxContext.isLoadingRecommendation}
                      />
                    ))}
                  </div>
                )}
                {!!notRelevantSyndromes.length && (
                  <div className="dx-checklist-summary-not-relevant-syndromes">
                    <h6>{__('dx.checklist.notRelevantDiagnoses')}</h6>
                    {notRelevantSyndromes.map((syndrome) => (
                      <SyndromeLine
                        key={syndrome.id}
                        syndrome={syndrome}
                        onPinClick={syndrome.isPinned ? onSyndromePinClick : false}
                        mode="compact"
                        onClick={() => onSyndromeClick(syndrome)}
                        isHidden={dxContext.isLoadingRecommendation}
                      />
                    ))}
                  </div>
                )}
                {!!hiddenSyndromes.length && (
                  <div className="dx-checklist-summary-hidden-syndromes">
                    <h6>
                      <Icon name="eye-off" /> {__('dx.checklist.hiddenDiagnoses')}
                    </h6>
                    {hiddenSyndromes.map((syndrome) => (
                      <SyndromeLine
                        key={syndrome.id}
                        syndrome={syndrome}
                        mode="compact"
                        onHideClick={(syndromeId, hide) => onSyndromeHideClick(syndromeId, hide)}
                        isHidden={dxContext.isLoadingRecommendation}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="checklist-summary-end-examination">
                <div className="feedback">
                  {__('dx.summary.reliability.' + dxContext.recommendation?.diagnostic_reliability?.discrete)}
                </div>
                <div className="cta">
                  <Button
                    label={__('dx.summary.returnToExam')}
                    variant={readyToStop ? 'outline' : ''}
                    onClick={() => dxContext.setChecklistSummaryOpen((prev) => !prev)}
                  />{' '}
                  <Button
                    label={__('dx.summary.createReport')}
                    variant={!readyToStop ? 'outline' : ''}
                    onClick={() => examinationContext.endExamination()}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        {/** syndrome */}
        <div className="dx-checklist-summary-syndrome">
          {!!currentSyndrome?.id && (
            <SyndromeDetails
              currentSyndrome={currentSyndrome}
              medicalHistory={medicalHistory}
              allMedications={allMedications}
              onClose={onSyndromeClick}
              onPinClick={onSyndromePinClick}
              onHideClick={onSyndromeHideClick}
            />
          )}
          {currentSyndrome?.id === 0 && (
            <MalformationDetails
              currentMalformations={currentSyndrome}
              allMedications={allMedications}
              onClose={onSyndromeClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ChecklistSummaryView);
