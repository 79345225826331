import { useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';

import Icon from '../../../../atoms/Icon/Icon';
import Button from '../../../../atoms/Button/Button';

import './CarryForwardSuggestions.css';
import { getDefaultCheckedCodes } from './carryForward';
import { convertTimeZone, formatYYYYMMDDDate } from '../../../../utils';

const CarryForwardSuggestions = ({
  t: __,
  suggestions,
  addCodes,
  shouldBeFolded = true,
  timezone = 'Etc/UTC',
  dateFormat = 'dd/mm/yyyy',
}) => {
  const sortedSuggestions = useMemo(
    () =>
      suggestions.toSorted((a, b) => {
        // neither have the "order" field -> we keep the array order
        if (!('order' in a) && !('order' in b)) return true;
        // if only b has an order -> b should be before a
        if (!('order' in a)) return false;
        // we use the order field (considering b's order greater than a's if not specified)
        return a.order - (b.order ?? a.order + 1);
      }),
    [suggestions]
  );
  const [prevShouldBeFolded, setPrevShouldBeFolded] = useState(shouldBeFolded);
  const [isFolded, setIsFolded] = useState(shouldBeFolded);
  const [selectedCodes, setSelectedCodes] = useState(getDefaultCheckedCodes(suggestions));

  // useEffect not necessary here, see:
  // https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes
  if (prevShouldBeFolded !== shouldBeFolded) {
    setPrevShouldBeFolded(shouldBeFolded);
    setIsFolded(shouldBeFolded);
  }

  const toggleCheck = (code) => {
    const uniqSelectedCodes = new Set(selectedCodes);
    if (uniqSelectedCodes.has(code)) uniqSelectedCodes.delete(code);
    else uniqSelectedCodes.add(code);
    setSelectedCodes(Array.from(uniqSelectedCodes));
  };
  const selectAll = () => setSelectedCodes(suggestions.map((cf) => cf.codes));
  const unselectAll = () => setSelectedCodes([]);

  const addSelection = () => addCodes(suggestions.filter((suggestion) => selectedCodes.includes(suggestion.codes)));

  if (!suggestions?.length) return null;
  return (
    <div role="region" className="exam-report-carry-forward-section_suggestions">
      <h6 className="section-title clickable" onClick={() => setIsFolded((f) => !f)}>
        <Icon className="fold" name={isFolded ? 'right-big' : 'down-big'} />
        {__('suggestion.previousExaminations')}
        {isFolded && ` (${suggestions.length})`}
      </h6>
      {!isFolded && (
        <div className="exam-report-carry-forward-section_suggestions-actions">
          <Button
            label={__('suggestion.previousExaminations.selectAll')}
            onClick={selectAll}
            variant="link"
            size="small"
          />
          /
          <Button
            label={__('suggestion.previousExaminations.deselectAll')}
            onClick={unselectAll}
            variant="link"
            size="small"
          />
        </div>
      )}
      {!isFolded &&
        sortedSuggestions?.map((suggestion) => (
          <div
            role="listitem"
            key={suggestion.label}
            onClick={() => toggleCheck(suggestion.codes)}
            className="exam-report-carry-forward-section_suggestion"
          >
            <Icon name={`checkbox-${selectedCodes.includes(suggestion.codes) ? 'done' : 'empty'}`} />
            <dfn className="code">{suggestion.codes}</dfn>
            <span className="meta">
              <span role="definition" className="description">
                {suggestion.label}
              </span>
              {!!suggestion.origins && suggestion.origins.length === 1 && (
                <span role="note" className="context">
                  {__('suggestion.previousExaminations.context', {
                    cfSection:
                      suggestion.origins[0].section.charAt(0).toUpperCase() + suggestion.origins[0].section.slice(1),
                    cfDate: formatYYYYMMDDDate(
                      convertTimeZone(suggestion.origins[0].examination_date, timezone),
                      dateFormat
                    ),
                    cfPreset: suggestion.origins[0].examination_preset_name,
                  })}
                </span>
              )}
              {!!suggestion.origins && suggestion.origins.length > 1 && (
                <span role="note" className="context">
                  {__('suggestion.previousExaminations.context.multipleOrigins', {
                    cfOriginCount: suggestion.origins.length,
                  })}
                </span>
              )}
            </span>
          </div>
        ))}
      {!isFolded && (
        <h6 className="exam-report-carry-forward-section_suggestions-actions">
          <Button
            label={__('suggestion.previousExaminations.addSelected')}
            onClick={addSelection}
            variant="outline"
            size="small"
          />
        </h6>
      )}
    </div>
  );
};

export default withTranslation()(CarryForwardSuggestions);
