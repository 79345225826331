export default function ReportTemplateLogo({ props, url }) {
  const Image = () => {
    if (!url) return false;
    else return <img src={url} />;
  };

  return (
    <div className="logo">
      <Image />
    </div>
  );
}
