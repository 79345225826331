import { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Button from '../atoms/Button/Button';
import Icon from '../atoms/Icon/Icon';
import Popup from '../components/Popup/Popup';
import { formatName } from '../services/examination';
import { formatDateTime } from '../utils';
import './SelectExamToStartDialog.css';

const SelectExamToStartDialog = ({
  t: __,
  close,
  draftExams,
  ignoreStudy,
  mergeExaminations,
  patient,
  examinations,
  dicomStudyId,
}) => {
  const history = useHistory();
  const draftList = useRef(null);
  const pertinentDrafts = draftExams.filter((exam) => (exam.patient.id ? exam.patient.id === patient.id : true));

  const stay = () => {
    close();
    ignoreStudy(dicomStudyId);
  };

  const goToExam = (examId) => {
    close();
    history.push(`/exam/${examId}#medias`);
  };

  const showDrafts = () => {
    draftList.current.style.height = draftList.current.scrollHeight + 'px';
  };

  const popupContent = {
    message: __('examination.theMediaBelongsToDifferentExam'),
    icon: '',
    cta: (
      <div className="select-exam-to-start-dialog-container">
        <div className="select-exam-to-start-dialog">
          <div className="breaking-cta questa">
            {pertinentDrafts.length > 1 && (
              <div>
                <Button label={__('examination.switchToDraft')} onClick={showDrafts} variant="outline" />
              </div>
            )}
            {pertinentDrafts.length === 1 &&
              pertinentDrafts.map((exam) => (
                <div key={exam.id}>
                  <small>
                    {exam?.patient?.name ? formatName(exam.patient.name).fullName : __('patients.anonymous')}{' '}
                    <Icon name="clock" /> {formatDateTime(exam.updated_at)}
                  </small>
                  <Button
                    label={__('examination.switchToThisDraft')}
                    onClick={() => mergeExaminations(examinations[0], exam.id)}
                    variant="outline"
                  />
                </div>
              ))}
            <div>
              <Button
                label={__('examination.switchToExam')}
                onClick={() => goToExam(examinations[0])}
                variant="outline"
              />
            </div>
            <div>
              <Button label={__('examination.stayOnTheCurrentExam')} iconAfter="right" onClick={stay} />
            </div>
          </div>
        </div>
        {pertinentDrafts.length > 1 && (
          <div className="draft-list-container" ref={draftList}>
            <h4>{__('examination.selectDraft')}</h4>
            <div className="draft-list">
              {pertinentDrafts.map((exam) => (
                <div key={exam.id} onClick={() => mergeExaminations(examinations[0], exam.id)}>
                  <div className="draft-name">
                    {exam?.patient?.name ? formatName(exam.patient.name).fullName : __('patients.anonymous')}
                  </div>
                  <div className="draft-date">
                    <Icon name="clock" /> {formatDateTime(exam.updated_at)}
                  </div>
                  <div className="draft-cta">{__('examination.switchToThisDraft')}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    ),
  };

  return <Popup message={popupContent.message} icon={popupContent.icon} cta={popupContent.cta} />;
};

export default withTranslation()(SelectExamToStartDialog);
