import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import { isNullOrUndefined } from '../../../../utils';
import { convertValueToSelectedUnit } from '../../../../unitConverter';
import { getNiceGestionalAgeFromDays } from '../../../../services/examination';
import { getPercentileValueString } from '../../../../services/measurements';

import NotVisibleOverlay from '../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';

import PercentileGraph from '../../../PercentileGraph';

import PlaceholderLoader from '../../PlaceholderLoader';
import { PlaceholdersHelper } from '../../placeholders';
import { measurementCurvePlaceholderId } from '../../utils';

const ReportTemplateBiometryItemBody = (fullProps) => {
  const {
    t: __,
    i18n: { language: currentLanguage },
    props,
    fetus = 1,
    editingFieldId,
    reportMode,
    setManageMeasurementsIsOpen,
    onEndEditing = () => {},
    gaValueId,
    InitialBIContext,
    fieldId,
  } = fullProps;
  const placeholdersHelper = new PlaceholdersHelper(fullProps);
  const placeholder = placeholdersHelper.selectedValue(fieldId);
  const selectedDatum = placeholdersHelper.selectedDatum(fieldId);

  if (isNullOrUndefined(placeholder?.value)) return false;

  const {
    data,
    label: templateLabel,
    required = null,
    percentile = '%',
    decimals = '1',
    reference,
    units,
  } = props ?? {};
  const gaValue = placeholdersHelper.selectedValue(gaValueId)?.value;

  // Label Priority: 1. edited from UI, 2. set in report_template, 3. default for this biometry
  const label = placeholder?.editedLabel
    ? placeholder.editedLabel
    : templateLabel || placeholder?.label[currentLanguage];
  const [isEditing, setIsEditing] = useState(false);
  const isMultiUnits = units?.includes('.'); // eg. feet.inches or lbs.oz

  let { visible } = selectedDatum;
  if (isNullOrUndefined(visible)) visible = isNullOrUndefined(props.visible) ? null : props.visible !== 'false';
  if (isNullOrUndefined(visible)) visible = true;

  useEffect(() => {
    if (editingFieldId !== data) setIsEditing(false);
  }, [editingFieldId, data]);

  if (!data) return false;

  // curveSlug Priority - 1: Report edit, 2: xml prop, 3: Hadlock, 4: first available
  const curveSlug = placeholder?.curve_slug;

  const {
    percentile: percentileValue = null,
    zscore = null,
    reference_value = null,
  } = placeholder?.percentiles?.[curveSlug] || {};

  const isAtRisk = !isNullOrUndefined(percentileValue) && (percentileValue > 95 || percentileValue < 5);

  const BIContext = { ...InitialBIContext, component: 'biometry-item' };

  const changeVisibility = (visible) => {
    const { value: _, ...datum } = selectedDatum;
    datum.visible = visible;
    onEndEditing(fieldId, [datum], { BIContext });
  };

  const PercentileMiniGraph = () => {
    return (
      <div className="biometry-item-percentile-graph">
        {curveSlug && !isNullOrUndefined(percentileValue) && percentileValue !== false && (
          <PercentileGraph percentile={percentileValue} atRisk={isAtRisk} />
        )}
      </div>
    );
  };

  const defaultUnit = placeholdersHelper.defaultUnit(fieldId);

  const displayUnit = placeholdersHelper.displayedUnit(fieldId) || defaultUnit;

  const displayValue = convertValueToSelectedUnit(placeholder?.value, defaultUnit, displayUnit, Number(decimals));

  const handleClick = () => {
    if (!visible) return;
    setManageMeasurementsIsOpen({ data, fetus });
  };

  const numberOfFetuses = placeholdersHelper.numberOfFetuses;

  return (
    <div
      className={`biometry-item
    ${isAtRisk ? 'at-risk' : ''}
    ${visible ? 'is-visible' : 'not-visible'}
    ${placeholder.editor || visible ? 'is-editable' : ''}
    ${visible ? 'has-printable-value' : 'not-printable'}
    ${isEditing && editingFieldId === data ? 'editing' : ''}`}
      onClick={handleClick}
    >
      <div className="biometry-item-label">
        {numberOfFetuses > 1 && (
          <span className="biometry-item-fetus-number">
            {placeholdersHelper.selectedValue('fetus.name', fetus)?.value}
          </span>
        )}
        {label} {required ? <span className="required">*</span> : false}
      </div>
      <div className="biometry-item-value" data-multiunit={isMultiUnits}>
        {placeholder?.value &&
          (isMultiUnits ? (
            <>
              <span>
                <span className="value">{displayValue[0]}</span>
                <span className="unit">{displayUnit.split('.')[0]}</span>
              </span>
              <span>
                <span className="value">{displayValue[1]}</span>
                <span className="unit">{displayUnit.split('.')[1]}</span>
              </span>
            </>
          ) : (
            <>
              <span className="value">{displayValue}</span>
              <span className="unit">{displayUnit}</span>
            </>
          ))}
      </div>
      <div className="biometry-item-ga">
        {gaValue && <span className="ga">{getNiceGestionalAgeFromDays(__, gaValue)}</span>}
      </div>
      <div className="biometry-item-percentile">
        <div className="biometry-item-percentile-value-wrapper">
          {curveSlug && !isNullOrUndefined(percentileValue) && percentileValue !== false && (
            <>
              {percentile === '%' && (
                <>
                  <span className="biometry-item-percentile-value percent">
                    {getPercentileValueString(percentileValue, __)}
                  </span>
                </>
              )}
              {percentile === 'zscore' && (
                <>
                  <span className="biometry-item-percentile-value zscore">{Number(zscore).toFixed(2)}</span>
                </>
              )}
            </>
          )}
        </div>
        <PercentileMiniGraph />
        {reference && reference_value && (
          <div className="biometry-item-reference-value">
            {` (${reference_value?.toFixed(Number(decimals))}` + (displayUnit ? ` ${displayUnit}` : '') + ')'}
          </div>
        )}
      </div>
      {/* TODO This is not working ¯\_(ツ)_/¯ @Roberto help please !highlight && visible && canEdit && reportMode === "edit" && (
        <div className="dropdown-options exam-report-editing-options not-printable">
          <div onClick={() => changeVisibility(!visible)}>
            <Icon name={visible ? "eye" : "eye-off"} />
          </div>
        </div>
      ) */}
      {editingFieldId === data &&
        isEditing &&
        placeholder.editor({
          ...placeholder,
          visible,
          label,
          decimals,
          userUnits: units,
          defaultUnit,
          curveSlug,
          availableCurveSlugs: placeholder.availableCurveSlugs,
        })}
      {!visible && reportMode === 'edit' && (
        <NotVisibleOverlay
          onClick={(e) => {
            e.stopPropagation();
            changeVisibility(!visible);
          }}
        />
      )}
    </div>
  );
};

const WithTranslationReportTemplateBiometryItemBody = withTranslation()(ReportTemplateBiometryItemBody);

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateMeasurementCurveLegend({ props, placeholders, ...otherProps }) {
  const { data, ga: gaStandard } = props ?? {};
  const fieldId = measurementCurvePlaceholderId(props);
  const gaValueId = `ga.${data}.${gaStandard ?? 'hadlock1984'}`;
  // No need to make them uniq as PlaceholderLoader will take care of it
  const requiredPlaceholders = ['fetus.name', fieldId, gaValueId, 'fetus.order'];

  return (
    <PlaceholderLoader
      Component={WithTranslationReportTemplateBiometryItemBody}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      fieldId={fieldId}
      gaValueId={gaValueId}
      props={props}
      {...otherProps}
    />
  );
}
