import React, { useCallback, useContext, useEffect, useState } from 'react';

/* Atoms */
import Icon from '../../../atoms/Icon/Icon';
import Button from '../../../atoms/Button/Button';
import ButtonBack from '../../../atoms/ButtonBack/ButtonBack';

/* Components */
import UploadFilePopup from '../../../components/UploadFilePopup/UploadFilePopup';

/* Contexts */
import { AppContext } from '../../../context-providers/App';
import { ExaminationContext } from '../../../context-providers/Examination';

/* Services & Utils */
import { formatDate, formatYYYYMMDDDate, convertTimeZone } from '../../../utils';
import ResourceApi from '../../../services/resource';

/* CSS */
import './Documents.css';
import { withTranslation } from 'react-i18next';

const PatientDocuments = ({ t: __, patient, setOpenPopup }) => {
  const [episodes, setEpisodes] = useState([]);
  const popupControlledFromParent = useState(false); // used to control the upload file popup from parent

  const openUploadPopup = () => {
    const [, setOpened] = popupControlledFromParent;
    setOpened(true);
  };

  useEffect(() => {
    if (patient?.id) {
      ResourceApi.listEpisodes(patient.id).then((response) => {
        const episodes = response.data.data;
        setEpisodes(episodes);
      });
    }
  }, [patient?.id]);

  return (
    <div className="examination-live-phenotype-panel-documents">
      <div className="section-title examination-live-phenotype-panel-documents-header">
        <span className="examination-live-phenotype-panel-documents-title">{__('patient.documents')}</span>
        <div className="examination-live-phenotype-panel-documents-header-actions">
          <Button
            icon="upload-arrow"
            variant="text"
            label={__('sharingPreferences.uploadDocument')}
            onClick={openUploadPopup}
          />
        </div>
      </div>
      {episodes.map((episode) =>
        episode.id === patient.current_episode_id ? (
          <PatientDocumentsEpisode
            episode={episode}
            key={episode.id}
            current
            popup={popupControlledFromParent}
            setOpenPopup={setOpenPopup}
          />
        ) : (
          <PatientDocumentsEpisode episode={episode} key={episode.id} setOpenPopup={setOpenPopup} />
        )
      )}
    </div>
  );
};

const PatientDocumentsEpisode = withTranslation()(({ t: __, episode, current, popup, setOpenPopup }) => {
  const appContext = useContext(AppContext);
  const [opened, setOpened] = useState(current);
  const [openedPopup, setOpenedPopup] = popup || useState(false);
  const [documents, setDocuments] = useState([]);

  const openPreview = (attachment) => {
    setOpenPopup({
      view: 'document-preview',
      attachment,
      onDeleted: loadDocuments,
    });
  };

  const loadDocuments = useCallback(async () => {
    try {
      const { data } = await ResourceApi.getAttachmentsByEpisode(episode.id);
      setDocuments(data.data);
    } catch (error) {
      console.error(error);
    }
  }, [episode?.id]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  const toggleOpened = () => {
    setOpened(!opened);
  };

  const closePopup = () => setOpenedPopup(false);
  const openPopup = () => setOpenedPopup(true);
  const submit = async ({ files, addServerError, addStatusFile }) => {
    await Promise.all(
      files.map(async ({ file, title }) => {
        try {
          const formData = new FormData();
          formData.append('attachment[file]', file);
          formData.append('attachment[title]', title);
          const response = await ResourceApi.uploadEpisodeDocument(episode.patient_id, episode.id, formData);
          const errors = response?.data?.errors;
          if (typeof errors === 'string') {
            if (typeof errors === 'string' || errors instanceof String) {
              // https://stackoverflow.com/a/9436948
              const translationKey = errors;
              addServerError(translationKey);
            }
          }
          if (errors) return;
          addStatusFile(title);
        } catch (error) {
          addServerError(error.message);
        }
      })
    );
    closePopup();
  };

  const defaultName = current
    ? __('episode.current')
    : __('episode.defaultName', { inserted_at: formatDate(episode.inserted_at, appContext.preferences.date_format) });
  const name = episode?.name || defaultName;
  return (
    <div className="examination-live-phenotype-panel-documents-episode">
      <div className="examination-live-phenotype-panel-documents-episode-header">
        <div onClick={toggleOpened}>
          <Icon name={opened ? 'folder-open' : 'folder-closed'} />
          <span className="examination-live-phenotype-panel-documents-episode-name">{name}</span>
        </div>
        <Icon className="clickable" name="upload-arrow" onClick={openPopup} />
      </div>
      <div className={opened ? '' : 'hidden'}>
        <EpisodeDocuments
          episode={episode}
          documents={documents}
          reloadDocuments={loadDocuments}
          openPreview={openPreview}
        />
      </div>

      {openedPopup && <UploadFilePopup onClose={closePopup} submit={submit} loadNewList={loadDocuments} />}
    </div>
  );
});

const EpisodeDocuments = withTranslation()(({ t: __, documents, reloadDocuments, openPreview }) => {
  return (
    <div>
      <div className="examination-live-phenotype-panel-documents-episode-attachments">
        {documents.map((document) => (
          <EpisodeDocument
            document={document}
            key={document.id}
            reloadDocuments={reloadDocuments}
            openPreview={() => openPreview(document)}
          />
        ))}
      </div>
    </div>
  );
});

const EpisodeDocument = withTranslation()(({ t: __, document, reloadDocuments, openPreview }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const date = formatYYYYMMDDDate(
    convertTimeZone(document.inserted_at, examinationContext.examination?.site?.timezone),
    appContext.preferences.date_format
  );

  const getFileExtension = (filename) => {
    const parts = filename.split('.');
    return '.' + parts.pop();
  };
  const getFileBasename = (filename) => {
    const parts = filename.split('.');
    parts.pop();
    return parts.join('.');
  };

  return (
    <div className="examination-live-phenotype-panel-documents-episode-attachment" onClick={openPreview}>
      <div className="examination-live-phenotype-panel-documents-episode-attachment-title">
        <Icon className="examination-live-phenotype-panel-documents-episode-attachment-icon" name="file" />
        <div className="examination-live-phenotype-panel-documents-episode-attachment-container">
          <div title={document.title} className="examination-live-phenotype-panel-documents-episode-attachment-name">
            <div className="filename__basename">{getFileBasename(document.title)}</div>
            <span className="filename__ext">{getFileExtension(document.title)}</span>
          </div>
        </div>
      </div>
      <span className="examination-live-phenotype-panel-documents-episode-attachment-date">
        {' '}
        {__('patient.documents.uploaded_on', { date: date })}
      </span>
    </div>
  );
});

export const PreviewAttachmentPopup = withTranslation()(({ t: __, attachment, onClickCancel, onDeleted }) => {
  const [src, setSrc] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const downloadAttachment = async () => {
    setDownloading(true);
    try {
      const link = document.createElement('a');
      link.href = src;
      link.setAttribute('download', attachment.title);
      link.click();
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  const deleteAttachment = async () => {
    setDeleting(true);
    try {
      await ResourceApi.deleteAttachment(attachment.id);
      await onDeleted();
      onClickCancel();
    } catch (error) {
      console.error(error);
    } finally {
      setDeleting(false);
    }
  };

  const printAttachment = async () => {
    setPrinting(true);
    try {
      const iframe = document.getElementById(`preview-modal-attachment-${attachment.id}-iframe`);
      iframe.contentWindow.print();
    } catch (error) {
      console.error(error);
    } finally {
      setPrinting(false);
    }
  };

  const resizeContent = async (event) => {
    // Resize images to fit the iframe
    const iframe = event.target;
    const imgs = Array.from(iframe.contentDocument.getElementsByTagName('img'));
    imgs.forEach((el) => (el.style = 'max-height: 100vh; max-width: 100vw; display: block; margin: auto;'));
  };

  useEffect(() => {
    const id = attachment?.id;
    if (id) {
      ResourceApi.downloadAttachment(id).then((response) => {
        const { data: blob } = response;
        const src = `${URL.createObjectURL(blob)}#toolbar=0&navpanes=0`;
        setSrc(src);
      });
    }
  }, [attachment?.id]);

  return (
    <>
      <div className="modal-background" onClick={onClickCancel} />
      <div className="exam-report-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="exam-report-dialog-header">
          <ButtonBack onClick={onClickCancel} />
          <div className="exam-report-dialog-header_title">
            <h2>{__('patient.documents.preview')}</h2>
          </div>
          <ButtonBack icon="close" onClick={onClickCancel} />
        </div>
        <div className="exam-report-dialog-body">
          <iframe
            id={`preview-modal-attachment-${attachment.id}-iframe`}
            title={`preview-modal-attachment-${attachment.id}-iframe`}
            name={`preview-modal-attachment-${attachment.id}-iframe`}
            type={attachment.mime_type}
            className="exam-report-dialog-print-preview gray-background"
            src={src}
            loading="lazy"
            onLoad={resizeContent}
          ></iframe>
        </div>
        <div className="exam-report-dialog-floating-bar">
          <div className="buttons">
            <Button
              onClick={downloadAttachment}
              icon="download"
              label={downloading ? __('patient.documents.downloading') : __('patient.documents.download')}
              disabled={downloading}
            />
            <Button
              onClick={printAttachment}
              icon="print"
              label={printing ? __('patient.documents.printing') : __('patient.documents.print')}
              disabled={printing}
            />
            <Button onClick={deleteAttachment} icon="trash" variant="outline" disabled={deleting} />
          </div>
        </div>
      </div>
    </>
  );
});

export default PatientDocuments;
