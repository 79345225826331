import { isNullOrUndefined } from './utils';

const unitTypes = {
  Weight: {
    default: 'g',
    conversion_factors: {
      g: 1,
      kg: 0.001,
      lbs: 0.00220462,
      oz: 0.0352733686067019,
    },
  },
  Length: {
    default: 'mm',
    conversion_factors: {
      cm: 1,
      mm: 10,
      m: 0.01,
      inches: 0.3937007874,
      feet: 0.032808399,
    },
  },
  Time: {
    default: 'ms',
    conversion_factors: {
      days: 1,
      hours: 24,
      minutes: 1440,
      seconds: 86400,
      ms: 86_400_000,
    },
  },
  Volume: {
    default: 'cm3',
    conversion_factors: {
      mm3: 1000,
      cm3: 1,
      ml: 1,
      l: 1000,
    },
  },
  Speed: {
    default: 'cm/sec',
    conversion_factors: {
      'cm/sec': 1,
    },
  },
  Area: {
    default: 'cm2',
    conversion_factors: {
      mm2: 100,
      cm2: 1,
    },
  },
};

export const convertValueToSelectedUnit = (value, fromUnit, toUnit, decimals = 8) => {
  if (
    isNullOrUndefined(value) ||
    value === false ||
    value === '' ||
    (isNaN(value) && !Array.isArray(value) && !`${value}`.match(/\d+ \d+/))
  ) {
    if (toUnit.includes('.')) {
      return [value, value];
    }
    return value;
  }
  if (!fromUnit || !toUnit) return Number(Number(value).toFixed(decimals));

  fromUnit = standariseUnitString(fromUnit);
  toUnit = standariseUnitString(toUnit);
  const conversionFactors = findConversion(fromUnit);

  if (fromUnit === toUnit) {
    if (fromUnit.includes('.')) {
      if (Array.isArray(value)) {
        return value;
      } else {
        return `${Number(value).toFixed(Number(decimals))} `.split(' ').map((i) => Number(i));
      }
    } else {
      return Number(Number(value).toFixed(Number(decimals)));
    }
  }

  value = Array.isArray(value) ? value.map((v) => Number(v)) : Number(value);

  // "lbs.oz", "feet.inches"...
  if (fromUnit.includes('.') && toUnit.includes('.')) {
    const valueInteger = value.includes(' ') ? value.split(' ')[0] : value[0];
    const valueDecimal = value.includes(' ') ? value.split(' ')[1] : value[1];
    const [fromIntegerUnit, fromDecimalUnit] = fromUnit.split('.');
    const [toIntegerUnit, toDecimalUnit] = fromUnit.split('.');
    return [
      convertValueToSelectedUnit(valueInteger, fromIntegerUnit, toIntegerUnit, 0),
      convertValueToSelectedUnit(valueDecimal, fromDecimalUnit, toDecimalUnit, decimals),
    ];
  }
  if (fromUnit.includes('.')) {
    if (!Array.isArray(value)) value = [value, 0];
    const [fromIntegerUnit, fromDecimalUnit] = fromUnit.split('.');
    const integerValue = convertValueToSelectedUnit(value[0], fromIntegerUnit, toUnit);
    const decimalValue = convertValueToSelectedUnit(value[1], fromDecimalUnit, toUnit);
    return Number(Number(integerValue + decimalValue).toFixed(Number(decimals)));
  }
  if (toUnit.includes('.')) {
    const [toIntegerUnit, toDecimalUnit] = toUnit.split('.');
    const integerValue = convertValueToSelectedUnit(value, fromUnit, toIntegerUnit);
    const toValue = integerValue - Math.floor(integerValue);
    const decimalValue = convertValueToSelectedUnit(toValue, toIntegerUnit, toDecimalUnit, decimals);

    // compensate integers in case of rounding of decimals is >= 1 integer unit
    const integerFromDecimalValue = convertValueToSelectedUnit(decimalValue, toDecimalUnit, toIntegerUnit);
    const integerValueCompensated = Math.floor(integerValue) + integerFromDecimalValue;
    const toValueCompensated = integerValueCompensated - Math.floor(integerValueCompensated);
    const decimalValueCompensated = convertValueToSelectedUnit(
      toValueCompensated,
      toIntegerUnit,
      toDecimalUnit,
      decimals
    );

    return [Math.floor(integerValueCompensated), Number(decimalValueCompensated.toFixed(Number(decimals)))];
  }

  if (
    conversionFactors &&
    Object.prototype.hasOwnProperty.call(conversionFactors, fromUnit) &&
    Object.prototype.hasOwnProperty.call(conversionFactors, toUnit)
  ) {
    return Number(
      ((Number(value) * conversionFactors[toUnit]) / conversionFactors[fromUnit]).toFixed(Number(decimals))
    );
  }

  console.error(`Conversion from ${fromUnit} to ${toUnit} not available`);
  return Number(Number(value).toFixed(Number(decimals)));
};

const findConversion = (unit) => {
  for (const unitType of Object.values(unitTypes)) {
    if (unitType.conversion_factors[unit] !== undefined) {
      return unitType.conversion_factors;
    }
  }
  return false;
};

const standariseUnitString = (unit) => {
  const unitLower = unit.toLowerCase();
  if (['"', 'in', 'inch'].includes(unitLower)) return 'inches';
  if (["'", 'ft'].includes(unitLower)) return 'feet';
  if (['pounds', 'pound', 'lb'].includes(unitLower)) return 'lbs';
  if (['ounces', 'ounce'].includes(unitLower)) return 'oz';
  if (['gr', 'grs', 'gms'].includes(unitLower)) return 'g';
  if (['cm/s'].includes(unitLower)) return 'cm/sec';
  return unitLower;
};
