import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import { AppContext } from '../../context-providers/App';
import useAuth from '../../context-providers/Auth';
import { ExaminationContext } from '../../context-providers/Examination';
import { ExaminationSharingContext } from '../../context-providers/ExaminationSharing';
import { ZoomLevelContext } from '../../context-providers/ZoomLevel';
import ResourceApi from '../../services/resource';
import '../../components/Examination/SlideGrid/SlideGrid.css';
import './SharingSelect.css';
import globalConfig from '../../config';
import Slide from '../../components/Slide/Slide';
import SlideShareDisplay from '../../components/Slide/SlideShareDisplay';
import SharingPreviewPopup from '../ExaminationReview/SharingPreviewPopup';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import SharingListWidget from './SharingListWidget';
import UserApi from '../../services/user';
import SelectInput from '../../atoms/SelectInput/SelectInput';
import loadExamAssocAttachments from '../../services/loadExamAssocAttachments';
import { useExamSharingState } from '../../providers/examSharing';
import ExamPagedPrint, {
  pagedPrintStylesText,
  updatePagedPrintHeaderOffset,
} from '../../components/ExamPagedPrint/ExamPagedPrint';
import { isEmptyList, isList } from '../../services/user-preselect-config';
import { trackEvent } from './examinationTracking';

const DEFAULT_NB_IMG_PER_PAGE = 8;

const reorder = (instances, slideMap, sortBy) => {
  const orderBySlide = (instances, slideMap) => {
    const sortBySlideKey = (instances, slideMap) =>
      [...instances].sort((instanceA, instanceB) => {
        const slideA = slideMap.get(instanceA.dicom_instance_id || instanceA.id);
        const slideB = slideMap.get(instanceB.dicom_instance_id || instanceB.id);

        if (!slideB) return -1;
        if (!slideA) return 1;
        if (slideA.key === slideB.key) return 0;

        const keyToWeight = (key) => {
          const [a, b, c] = key.split('_').map((str) => parseInt(str));
          const weight = (a || 0) * 1000000 + (b || 0) * 1000 + (c || 0);
          return weight;
        };
        const weightA = keyToWeight(slideA.key);
        const weightB = keyToWeight(slideB.key);

        return weightA > weightB ? 1 : -1;
      });

    const groupByTypes = (instances, slideMap) => {
      const ifSlide = (condition) => (i) => {
        const slide = slideMap.get(i.dicom_instance_id || i.id);
        return condition(slide);
      };
      const isPicture = (slide) => slide && !slide.optional && slide.type === 'picture';
      const isOptional = (slide) =>
        slide &&
        ((slide.optional && slide.type === 'picture') ||
          (slide.type === 'other' && (slide.verified || !slide.mediaId) && !slide.is_video));
      const isUnmatched = (slide) => slide && slide.mediaId && !slide.optional && !slide.verified && !slide.is_video;
      const isVideo = (slide) => slide && slide.is_video;
      const absent = (slide) => !slide;

      return [
        ...instances.filter(ifSlide(isPicture)),
        ...instances.filter(ifSlide(isOptional)),
        ...instances.filter(ifSlide(isUnmatched)),
        ...instances.filter(ifSlide(isVideo)),
        ...instances.filter(ifSlide(absent)),
      ];
    };

    const sortedInstances = sortBySlideKey(instances, slideMap);
    const sortedAndGroupedInstances = groupByTypes(sortedInstances, slideMap);

    return sortedAndGroupedInstances;
  };

  const orderByTime = (instances, slideMap) =>
    [...instances].sort((instanceA, instanceB) => {
      const slideA = slideMap.get(instanceA.id || instanceA.dicom_instance_id);
      const slideB = slideMap.get(instanceB.id || instanceB.dicom_instance_id);

      if (!slideA && !slideB) return 0;
      if (!slideB) return -1;
      if (!slideA) return 1;

      return slideA.inserted_at === slideB.inserted_at
        ? 0
        : (slideA.inserted_at || 'z') > (slideB.inserted_at || 'z')
        ? 1
        : -1;
    });

  if (sortBy === 'slide') return orderBySlide(instances, slideMap);
  if (sortBy === 'time') return orderByTime(instances, slideMap);
  return instances;
};

const SharingSelect = ({ t: __, close = null, instances, mode, slides }) => {
  const history = useHistory();
  const { examId } = useParams();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const examinationSharingContext = useContext(ExaminationSharingContext);
  const { generalDocuments, totalActiveUploadedDocuments } = useExamSharingState();
  const { zoomLevel } = useContext(ZoomLevelContext);
  const { config } = useAuth();
  const { instancePreviewBlobs, loadedPreviewsList, loadShareForExam, reloadInstancePreviewBlobList } =
    examinationContext;
  const { updateExamSharedParams } = examinationContext;

  const { anonymizeToggle, annotateToggle, documentsToggle, setAnnotateToggle, setDocumentsToggle } =
    examinationSharingContext;

  const [selectedInstanceAmount, setSelectedInstanceAmount] = useState(0);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [printPreviewsLoading, setPrintPreviewsLoading] = useState(false);
  const [printingTemplateViews, setPrintingTemplateViews] = useState([]);

  const [closeAction, setCloseAction] = useState(null);
  const [qrCodePopupOpen, setQrCodePopupOpen] = useState(false);

  const [annotateConfig, setAnnotateConfig] = useState(null);
  const [annotateParam, setAnnotateParam] = useState(null);
  const [documentsParam, setDocumentsParam] = useState(null);
  const [annotateChange, setAnnotateChange] = useState(null);
  const [documentsChange, setDocumentsChange] = useState(null);
  const [isSharingListWidget, setIsSharingListWidget] = useState(false);
  const { isFeatureFlagEnabled, user } = useAuth();

  const sortPlanesBy = window.localStorage.getItem(`defaultSortPlanesBy-user-${user.id}`) || 'slide';

  const createSharingWithDocuments = async () => {
    const generalDocListWithoutAssoc = generalDocuments.filter((doc) => doc.id === null);
    await examinationContext.shareExamination(annotateToggle, documentsToggle, generalDocListWithoutAssoc);
  };

  useEffect(() => {
    UserApi.getUserSharingPreselection().then((resp) => {
      examinationContext.setIncludeQRCode(resp.data.preselection.qr_code_auto_enabled);
    });
  }, []);

  useEffect(() => {
    if (examinationContext.includeQRCode) {
      examinationContext.setExclusivelyQr(false);
      if (!examinationContext.share?.share_link) {
        createSharingWithDocuments();
      }
    }
  }, [examinationContext.includeQRCode]);

  const slideMap = useMemo(() => {
    return new Map((!!slides && slides.map((slide) => [slide.mediaId, slide])) || []);
  }, [slides]);

  const sortedInstances = useMemo(
    () => reorder(instances, slideMap, sortPlanesBy),
    [instances, slideMap, sortPlanesBy]
  );

  useEffect(() => {
    const closeActionProvider = () => (!close ? history.goBack : close);
    appContext.activateGoBackMenuButton(true, closeActionProvider);
    setCloseAction(closeActionProvider);
    if (isFeatureFlagEnabled('sonio.attachments')) {
      loadExamAssocAttachments(examId);
    }
    return () => appContext.activateGoBackMenuButton(false);
  }, []);

  useEffect(() => {
    UserApi.getPatientAppConfig().then((res) => {
      setAnnotateConfig(res.data.show_names_on_views);
    });
    setDocumentsToggle(true);
  }, []);

  useEffect(() => {
    if (examinationContext.share?.parameters?.annotate_images !== undefined) {
      setAnnotateParam(examinationContext.share.parameters.annotate_images);
    }
  }, [examinationContext.share]);

  useEffect(() => {
    if (examinationContext.share?.parameters?.share_documents !== undefined) {
      setDocumentsParam(examinationContext.share.parameters.share_documents);
    }
  }, [examinationContext.share]);

  useEffect(() => {
    if (annotateChange !== null) {
      setAnnotateToggle(annotateChange);
    } else if (annotateParam !== null) {
      setAnnotateToggle(annotateParam);
    } else if (annotateConfig !== null) {
      setAnnotateToggle(annotateConfig);
    }
  }, [annotateConfig, annotateParam, annotateChange]);

  useEffect(() => {
    if (documentsChange !== null) {
      setDocumentsToggle(documentsChange);
    } else if (documentsParam !== null) {
      setDocumentsToggle(documentsParam);
    }
  }, [documentsParam, documentsChange]);

  useEffect(() => {
    const instances = examinationContext.instances;
    let total = 0;
    let selected = 0;

    if (mode === 'share') {
      total = instances.filter((instance) => instance.modality !== 'SR').length;
      selected = instances.filter((i) => isMediaSelected(i)).length;
    }

    if (mode === 'print') {
      total = instances.filter(
        (instance) => instance.dicom_media_type === 'image' && instance.modality !== 'SR'
      ).length;
      selected = instances
        .filter((instance) => instance.dicom_media_type === 'image')
        .filter((i) => isMediaSelected(i)).length;
    }

    setSelectedInstanceAmount(selected);
    setIsAllSelected(total === selected);
  }, [examinationContext.instances, mode]);

  useEffect(() => {
    if (annotateChange !== null && examinationContext.share?.share_id) {
      updateExamSharedParams(examinationContext.examination.id, 'annotate', annotateChange);
      loadShareForExam();
    }
  }, [annotateChange]);

  useEffect(() => {
    if (
      documentsChange !== null &&
      examinationContext.share?.share_id &&
      examinationContext.share?.parameters !== null
    ) {
      updateExamSharedParams(examinationContext.examination.id, 'documents', documentsChange);
      loadShareForExam();
    }
  }, [documentsChange]);

  const containerRef = useRef(null);

  const { examination } = examinationContext;
  const currentTrimester = examination.trimester;
  const malformations = examination?.malformations || [];
  examination.type = examination.type || (malformations.length ? 'diagnostic' : 'screening');

  const printRef = useRef(null);

  const printingTemplate = useMemo(
    () =>
      config?.printing_configuration?.find(
        (printing_config) => printing_config.trimester === currentTrimester?.toLowerCase()
      ),
    [currentTrimester, config]
  );
  const [printingPreferenceImgPerPage, setPrintingPreferenceImgPerPage] = useState(DEFAULT_NB_IMG_PER_PAGE);
  const [mountNode, setMountNode] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [instancesToPrint, setInstancesToPrint] = useState([]);
  const [headerPlaceholderCount, setHeaderPlaceholderCount] = useState(0);

  // The page will render a single QR whenever it's selected to exclusively do so or
  // the user did not select a single instance image yet selected to include a QR
  const printOnlyQr = useMemo(() => {
    return examinationContext.exclusivelyQr || (instancesToPrint.length === 0 && examinationContext.includeQRCode);
  }, [examinationContext.exclusivelyQr, instancesToPrint, examinationContext.includeQRCode]);
  /** medias */
  const printableFrame = useRef(null);

  useEffect(() => {
    if (mode === 'print')
      if (examinationContext.examination.id) {
        ResourceApi.printingList(examinationContext.examination.id).then((resp) => {
          const slideMapString = JSON.stringify(slideMap);
          const sortedInstances = reorder(resp.data.data, slideMap, sortPlanesBy).filter((i) => {
            if (slideMapString === '{}') return true;
            const slide = slideMap.get(i.dicom_instance_id);
            return !!slide;
          });
          setInstancesToPrint(sortedInstances);
        });
        ResourceApi.printingTemplateViewIds(examinationContext.examination.id).then((resp) => {
          setPrintingTemplateViews(resp.data.data);
        });
      }
  }, [examinationContext.examination.id, examinationContext.instances, mode]);

  useEffect(() => {
    const sortedInstances = reorder(instancesToPrint, slideMap, sortPlanesBy).filter((i) => {
      const slide = slideMap.get(i.dicom_instance_id);
      return !!slide;
    });
    if (sortedInstances.length === 0) return;

    const examProtoId = examinationContext?.examination?.preset_id;
    const printingTemplateConfig = config?.preselect_configuration?.printing_preset;
    const instanceViewIds = printingTemplateConfig && printingTemplateConfig[examProtoId];

    let wantedIds = [];
    if (instanceViewIds && isList(instanceViewIds)) {
      wantedIds = instanceViewIds;
    } else {
      wantedIds = printingTemplateViews;
    }

    if (isList(instanceViewIds) && isList(wantedIds) && !isEmptyList(wantedIds)) {
      const { wanted, list } = [...instanceViewIds].reduce(
        (acc, instanceViewId) => {
          const foundIndex = acc.list.findIndex((x) => x.instance_view_id === instanceViewId);
          if (foundIndex === -1) return acc;

          acc.wanted.push(acc.list[foundIndex]);
          acc.list.splice(foundIndex, 1);

          return acc;
        },
        { wanted: [], list: sortedInstances }
      );
      setInstancesToPrint([...wanted, ...list]);
    } else {
      setInstancesToPrint(sortedInstances);
    }
  }, [slideMap, JSON.stringify(instancesToPrint), JSON.stringify(printingTemplateViews)]);

  useEffect(() => {
    if (printingTemplate?.number_of_images_per_page != null) {
      setPrintingPreferenceImgPerPage(printingTemplate?.number_of_images_per_page);
    }
  }, [printingTemplate?.number_of_images_per_page]);

  useEffect(() => {
    /** awaiting for DOM creation inside the iframe (Firefox only) */
    if (printableFrame.current) printableFrame.current?.addEventListener('load', () => setIframeLoaded(true));
  }, [JSON.stringify(instancesToPrint)]);

  useEffect(() => {
    if (!iframeLoaded && !printableFrame.current?.contentWindow) return false;
    if (printableFrame.current?.contentWindow?.document?.head.getElementsByClassName('main-style').length > 0)
      return false;

    const css = document.createElement('style');
    css.className = 'main-style';
    css.appendChild(document.createTextNode(pagedPrintStylesText));
    printableFrame.current?.contentWindow?.document?.head.appendChild(css);

    setMountNode(printableFrame.current?.contentWindow?.document?.body);
  }, [iframeLoaded, printableFrame.current?.contentWindow]);

  const frameComponent = useMemo(() => {
    return (
      <ExamPagedPrint
        printingPreferenceImgPerPage={printingPreferenceImgPerPage}
        printOnlyQr={printOnlyQr}
        annotateToggle={annotateToggle}
        anonymizeToggle={anonymizeToggle}
        headerPlaceholderCount={headerPlaceholderCount}
        instancesToPrint={instancesToPrint}
      />
    );
  }, [
    JSON.stringify(instancesToPrint),
    printingPreferenceImgPerPage,
    annotateToggle,
    anonymizeToggle,
    iframeLoaded,
    printableFrame.current?.contentWindow?.document,
    examinationContext.share,
    examinationContext.instanceViews,
    examinationContext.includeQRCode,
    examinationContext.exclusivelyQr,
    instancePreviewBlobs,
    headerPlaceholderCount,
  ]);

  const printIframe = async (ref) => {
    setPrintPreviewsLoading(true);
    await reloadInstancePreviewBlobList();

    const iframeWindow = ref.current.contentWindow || ref.current;
    setTimeout(() => {
      updatePagedPrintHeaderOffset(iframeWindow.document, printingPreferenceImgPerPage, setHeaderPlaceholderCount);

      setTimeout(() => iframeWindow.print(), 100);
    }, 100);

    setPrintPreviewsLoading(false);
    return false;
  };

  const handleIncludeQRCode = async () => {
    const isQrToggleEnabled = examinationContext.includeQRCode;

    if (isQrToggleEnabled === false && !examinationContext.share?.share_link) {
      createSharingWithDocuments();
    }
    examinationContext.setExclusivelyQr((_) => false);
    examinationContext.setIncludeQRCode((s) => !s);

    !isQrToggleEnabled && trackEvent('user_enables_the_toggle_to_print_the_QRcode', examinationContext);
  };

  // Generating selector function according to mode
  const isMediaSelected = ((m) => {
    if (m === 'share') {
      return (instance) => instance.shared_with_patient;
    }
    if (m === 'print') {
      return (instance) => instance.selected_for_print;
    }
    return (_instance) => false;
  })(mode);

  // Generating toggle function according to mode
  const toggleMediaSelection = ((m) => {
    if (m === 'share') {
      return (instanceId) => examinationContext.toggleInstanceSelectedForSharing(instanceId);
    }
    if (m === 'print') {
      return (instanceId) => {
        examinationContext.toggleInstanceSelectedForPrinting(instanceId);
      };
    }
    return (_instanceId) => false;
  })(mode);

  const openQrCodePopup = async () => {
    setShareLoading(true);
    if (!examinationContext.share?.instant_share_link) {
      await createSharingWithDocuments();
    }

    setQrCodePopupOpen(true);
    setShareLoading(false);

    trackEvent('user_shares_with_the_share_button', examinationContext);
  };

  const selectOnClick = (e, mediaId) => {
    e.stopPropagation();
    toggleMediaSelection(mediaId);

    const instance = examinationContext.instances.find((i) => i.id === mediaId);
    const becomesSelected =
      (mode === 'print' && !instance.selected_for_print) || (mode === 'share' && !instance.shared_with_patient);
    const type =
      mode === 'print'
        ? 'user_selects_or_unselects_an_image_for_printing'
        : 'user_selects_or_unselects_an_image_for_sharing';

    trackEvent(type, examinationContext, {
      selected: becomesSelected,
      dicom_instance_id: mediaId,
    });
  };

  const toggleSharingListWidget = () => setIsSharingListWidget((value) => !value);

  const SelectionCount = () => {
    return (
      <b>
        {__('examinationSharingFlow.nImagesSelected', {
          count: selectedInstanceAmount,
        })}
      </b>
    );
  };

  const SelectAllLink = () => {
    const unselectAllInstances = () => {
      instances.filter((i) => isMediaSelected(i)).forEach((i) => toggleMediaSelection(i.id));

      // track unselect all for mode
      trackEvent(
        mode === 'share'
          ? 'user_clicks_on_unselect_all_in_the_sharing_selection'
          : 'user_clicks_on_unselect_all_in_the_printing_selection',
        examinationContext
      );
    };
    const selectAllInstances = () => {
      instances.filter((i) => !isMediaSelected(i)).forEach((i) => toggleMediaSelection(i.id));

      trackEvent(
        mode === 'share'
          ? 'user_clicks_on_select_all_in_the_sharing_selection'
          : 'user_clicks_on_select_all_in_the_printing_selection',
        examinationContext
      );
    };

    return isAllSelected ? (
      <a onClick={unselectAllInstances}>{__('examinationSharingFlow.unselectAll')}</a>
    ) : (
      <a onClick={selectAllInstances}>{__('examinationSharingFlow.selectAll')}</a>
    );
  };

  const cancelClickEvent = (e) => {
    e.stopPropagation();
  };

  const showAnnotate = currentTrimester !== 'ND';
  const showQrToggle = mode === 'print';
  const showPageSize = showQrToggle;
  const showDocuments = mode === 'share' && isFeatureFlagEnabled('sonio.attachments');
  const parametersCount = [showAnnotate, showQrToggle, showPageSize, showDocuments].filter((x) => x).length;

  return (
    <>
      <div className="examination-sharing-flow-sharing-select-container">
        {parametersCount && (
          <div className="examination-sharing-flow-parameters-bar">
            <span className="parameters-title">
              {__('examinationSharingFlow.parameters', {
                count: parametersCount,
              })}
              :{' '}
            </span>
            {showAnnotate && (
              <label onClick={() => setAnnotateChange(!annotateToggle)}>
                {__('examinationSharingFlow.parameters.annotateImages')}
                <Checkbox checked={annotateToggle} onChange={setAnnotateChange} />
              </label>
            )}
            {showDocuments && (
              <div className="examination-sharing-flow-parameters-bar-document-label">
                <label onClick={cancelClickEvent}>
                  <span onClick={toggleSharingListWidget}>
                    {__('examinationSharingFlow.parameters.documents', {
                      count: totalActiveUploadedDocuments,
                    })}
                  </span>
                  <Icon
                    className="switch-icon"
                    name={isSharingListWidget ? 'up' : 'down'}
                    onClick={toggleSharingListWidget}
                  />
                  <Checkbox checked={documentsToggle} onChange={() => setDocumentsChange(!documentsToggle)} />
                </label>
                {isSharingListWidget && (
                  <SharingListWidget
                    onSuccesfulUpload={() => setDocumentsChange(true)}
                    closeWidget={() => toggleSharingListWidget(false)}
                    trackEvent={(event_name) => trackEvent(event_name, examinationContext)}
                  />
                )}
              </div>
            )}
            {showQrToggle && (
              <>
                <label onClick={handleIncludeQRCode}>
                  {__('examinationSharingFlow.parameters.addQRcode')}
                  <Checkbox checked={examinationContext.includeQRCode} />
                </label>
              </>
            )}
            {showPageSize && (
              <>
                <label>
                  {__('examinationSharingFlow.parameters.numberPerPage')}
                  <SelectInput
                    value={printingPreferenceImgPerPage}
                    placeholder={{
                      icon: 'settings-alt',
                      label: '',
                      value: '',
                    }}
                    onChange={setPrintingPreferenceImgPerPage}
                    options={[
                      { type: 'string', label: '2', value: 2 },
                      { type: 'string', label: '8', value: 8 },
                      { type: 'string', label: '15', value: 15 },
                    ]}
                  />
                </label>
              </>
            )}
          </div>
        )}
        <div className="examination-sharing-flow-sharing-select">
          <div
            className={`examination-live-grid-container trimester-${currentTrimester} zoom-level-${zoomLevel}`}
            ref={containerRef}
          >
            <div className="examination-live-grid">
              <div className="selection-status">
                <SelectionCount />
                <SelectAllLink />
              </div>
              {instances.length ? (
                <div
                  className="slide-grid-container"
                  style={{
                    '--number-of-columns': zoomLevel || globalConfig.examinationLive.numberOfColumns,
                  }}
                >
                  {sortedInstances.map((media) => {
                    const slide = annotateToggle && currentTrimester !== 'ND' ? slideMap.get(media.id) : null;
                    const isSelected =
                      (mode === 'share' && media?.shared_with_patient) ||
                      (mode === 'print' && media?.selected_for_print);
                    return (
                      <div
                        key={media.id}
                        className={isSelected ? 'examination-live-slide selected' : 'examination-live-slide'}
                        onClick={(e) => {
                          selectOnClick(e, media.id);
                        }}
                      >
                        <Slide
                          slide={slide}
                          trimester={currentTrimester}
                          media={media}
                          zoomLevel={zoomLevel}
                          shareButtonsOverride={<SlideShareDisplay media={media} mode={mode} />}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div className="examination-sharing-flow-action-bar">
            <Button label={__('examinationSharingFlow.actions.back')} variant="outline" onClick={closeAction} />
            {mode === 'share' && (
              <Button
                icon={shareLoading ? '' : 'sharing-indicator'}
                label={__('examinationSharingFlow.actions.share')}
                isLoading={shareLoading}
                size="full-width"
                disabled={
                  shareLoading ||
                  (selectedInstanceAmount === 0 && !(totalActiveUploadedDocuments !== 0 && documentsToggle))
                }
                onClick={openQrCodePopup}
              />
            )}
            {mode === 'print' && (
              <Button
                icon={printPreviewsLoading ? '' : 'print'}
                label={__('examinationSharingFlow.actions.print')}
                isLoading={printPreviewsLoading}
                size="full-width"
                disabled={
                  examinationContext.patient === null ||
                  loadedPreviewsList.length !== 0 ||
                  (selectedInstanceAmount === 0 && !examinationContext.includeQRCode)
                }
                onClick={() => {
                  console.log(printRef);
                  printIframe(printableFrame);

                  trackEvent('user_prints_images', examinationContext);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {mode === 'share' && qrCodePopupOpen && (
        <SharingPreviewPopup
          onClose={() => setQrCodePopupOpen(false)}
          annotate={annotateToggle}
          documents={documentsToggle}
        />
      )}

      <iframe className="examination-review-print-preview-iframe" ref={printableFrame} title="CNEOF medias">
        {mountNode && createPortal(frameComponent, mountNode)}
      </iframe>
    </>
  );
};

export default withTranslation()(SharingSelect);
