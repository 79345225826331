// Helper function to update the selected items,
// By removing those that are no longer selected,
// adding the new ones at the end

import { isNullOrUndefined } from '../../utils';

// and updating the order of the selected items.
export const reorderSelectedItems = (selectedItemIds, oldItems, getItem) => {
  const oldEntries = Object.entries(oldItems);
  const newEntries = selectedItemIds.filter((v) => !(v in oldItems)).map((v) => [v, getItem(v)]);
  const entries = oldEntries
    // sort the items by order
    .sort((a, b) => a[1].order - b[1].order)
    // remove items that are not in the new value
    .filter(([id, _]) => selectedItemIds.includes(id))
    // add new items to the end
    .concat(newEntries)
    // update the order of the items
    .map(([id, value], order) => [id, { ...value, order }]);
  return Object.fromEntries(entries);
};

const defaultValueFromTree = (tree) => {
  if (!tree) return [];

  let nodes = Object.fromEntries(
    tree.filter((node) => node.selectable && node.default).map((node) => [node.id, { value: true }])
  );

  for (const node of tree) {
    nodes = { ...nodes, ...getDefaultValue(node.tree) };
  }
  return nodes;
};

export const getDefaultValue = (tree, isMultiple) => {
  const defaultValues = defaultValueFromTree(tree);
  if (Object.keys(defaultValues).length === 0) return null;
  return isMultiple ? defaultValues : Object.keys(defaultValues)[0];
};

export function getOptionById(id, tree) {
  if (!tree || !id) return false;

  const elm = tree.find((node) => `${node.id}`.toLowerCase() === `${id}`.toLowerCase());
  if (elm) return elm;

  for (const node of tree) {
    const elm = getOptionById(id, node.tree);
    if (elm) return elm;
  }

  return false;
}

export const defaultValueIsEmpty = (value) => {
  if (isNullOrUndefined(value)) {
    return true;
  }
  if (value === '') {
    return true;
  }

  return false;
};
