import React from 'react';
import Icon from '../../atoms/Icon/Icon';

export default function SharingContent({ examination, openChapter, setOpenChapter }) {
  if (!Array.isArray(examination?.sharing_configuration?.templates)) return false;

  const BluePrintElement = (props) => {
    return (
      <span className="sharing-config-plane" key={props.order_in_template}>
        {props.instance_view_id}
        &nbsp;
        {props.label}
        &nbsp; (x{props.number_of_instances})
      </span>
    );
  };
  const Trimester = (props) => {
    return (
      <div className="debug-subsection" key={`ptemplate-${props.id}`}>
        <div>
          <h3>
            {props.title} ({props.trimester.toUpperCase()})<span>id={props.id}</span>
          </h3>
        </div>
        <div>{props.blueprint.map(BluePrintElement)}</div>
      </div>
    );
  };
  return (
    <div className={`debug-section ${openChapter === 'sharing' ? 'open' : 'closed'}`}>
      <h2 onClick={() => setOpenChapter((chapter) => (chapter === 'sharing' ? false : 'sharing'))}>
        Sharing Config <Icon name="down" />
      </h2>
      {openChapter === 'sharing' && examination.sharing_configuration.templates.map(Trimester)}
    </div>
  );
}
