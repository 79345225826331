import { useEffect, useState, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import ResourceApi from '../../services/resource';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import ExamCard from './ExamCard/ExamCard';
import Button from '../../atoms/Button/Button';
import './ExamList.css';

const ExamList = ({ t: __ }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);

  const [exams, setExams] = useState([]);
  const [examLimit, setExamLimit] = useState(24);

  useEffect(() => {
    reloadExams();
  }, []);

  const reloadExams = () => {
    ResourceApi.filterExaminations().then((resp) => {
      const exams = resp?.data?.data || [];

      ResourceApi.getPatients().then((resp) => {
        const patients = resp?.data?.data || [];
        const examsWithPatients = exams.map((exam) => ({
          ...exam,
          patient: patients.find((patient) => patient.pregnancies?.some((episode) => episode.id === exam.episode_id)),
        }));

        setExams(examsWithPatients.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
      });
    });
  };

  const deleteExam = async (exam) => {
    await examinationContext.deleteDraftExamination(exam, onDeleteCallback);
  };

  const onDeleteCallback = (exam) => {
    setExams((exams) => exams.filter((ex) => ex.id !== exam.id)); // optmistic update
    reloadExams();
  };

  return (
    <div className="examlist-dt-container">
      <h1>{__('patient_details.examinations')}</h1>
      <ul className="examlist-dt-exams">
        {exams?.slice(0, examLimit).map((exam) => {
          return (
            <li key={exam.id}>
              <ExamCard exam={exam} dateFormat={appContext.preferences.date_format} deleteExam={deleteExam} />
            </li>
          );
        })}
      </ul>
      {exams?.length > examLimit && (
        <div className="examlist-dt-load-more">
          <Button
            label={__('patients.loadMore')}
            variant="outline"
            onClick={() => setExamLimit((count) => count + 6)}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ExamList);
