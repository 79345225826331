import { formatDate, fromStrToDate, formatYYYYMMDDDate, convertTimeZone, isNullOrUndefined } from '../utils';

/**
 * Format first name and last name from DICOM name format (lastname^firstname^)
 * @param {string} dicomname
 * @returns {firstName, lastName, niceName, originalName}
 */
export const formatName = (originalName) => {
  const [lastName, firstName, middleName, prefix] = [...(`${originalName}`.trim().split('^') || ['']), '', '', '', ''];
  return {
    firstName,
    lastName,
    middleName,
    prefix,
    fullName: `${prefix} ${lastName} ${firstName}`.trim(),
    originalName,
  };
};

export const createFullName = (lastName, firstName, middleName, prefix) =>
  [lastName?.trim(), middleName?.trim(), firstName?.trim(), prefix?.trim()].join('^');

export const gaToTrimester = (gaInDays) => {
  if ([false, null, undefined].includes(gaInDays)) return false;
  if (gaInDays < 14 * 7) return 1;
  if (gaInDays < 28 * 7) return 2;
  return 3;
};

/**
 * get Gestational age (Age gestationnel) based on last period date and reference date
 */
export const computeGestationalAge = (lastMenstrualDate, atDate = false) => {
  if (!atDate) atDate = Date.now();
  if (lastMenstrualDate.length < 10) return false;
  if (formatDate(atDate).length < 10) return false;
  const lastMentrualTimestamp = Date.parse(lastMenstrualDate) / 1000;
  const atTimestamp = Number.isInteger(atDate) ? atDate / 1000 : Date.parse(atDate) / 1000;
  if (atTimestamp < lastMentrualTimestamp) return 0;
  const weeks = Math.floor((atTimestamp - lastMentrualTimestamp) / (60 * 60 * 24 * 7));
  const days = Math.floor((atTimestamp - lastMentrualTimestamp) / (60 * 60 * 24)) - weeks * 7;
  if (isNaN(weeks) || isNaN(days)) return false;
  return { weeks, days };
};

/**
 * return a nice, human readable version of the gestational age starting from LMP
 */
export const getNiceGestionalAge = (__, lastMenstrualDate, atDate = false) => {
  const computedGestionalAge = computeGestationalAge(lastMenstrualDate, atDate);
  return getNiceGestionalAgeFromDaysAndWeeks(__, computedGestionalAge);
};

/**
 * return a nice, human readable version of the gestational age starting from day count
 */
export const getNiceGestionalAgeFromDays = (__, days) => {
  return getNiceGestionalAgeFromDaysAndWeeks(__, {
    weeks: Math.floor(days / 7),
    days: days % 7,
  });
};

/**
 * Return a nice, human readable version of GA starting from a object with weeks and days
 * @param {*} ga {weeks: (int), days: (int)}
 * @returns (string)
 */
export const getNiceGestionalAgeFromDaysAndWeeks = (__, ga) => {
  let gestationalAge = '';
  if (ga.weeks > 0) {
    gestationalAge += __('medicalhistory.gestationalAge.duration.weeks', {
      '#weeks': ga.weeks,
      weeks: __(`medicalhistory.gestationalAge.week${ga.weeks === 1 ? '' : 's'}`),
    });
  }
  if (gestationalAge !== '') {
    gestationalAge += __('medicalhistory.gestationalAge.duration.separator');
  }

  gestationalAge += __('medicalhistory.gestationalAge.duration.days', {
    '#days': ga.days,
  });

  return gestationalAge;
};

export const computeEstimatedDeliveryDate = (gaInDays, dateObtained, localeOrFormat, toTz, pregnancyLengthInDays) => {
  if (!gaInDays || !dateObtained) return '';

  // Default pregnancy length is 280 days in US. 287 days in France, but should be in the config in that case
  const daysLeft = (pregnancyLengthInDays || 280) - gaInDays;
  const dateObtainedTZ = convertTimeZone(dateObtained, toTz);
  const date = new Date(dateObtainedTZ);
  date.setDate(date.getDate() + daysLeft);
  return formatYYYYMMDDDate(date, localeOrFormat);
};

/**
 * get Amenorrhea weeks (Semaines d’aménorrhée) from last period date and reference date
 */
export const computeWA = (lastMenstrualDate, atDate, localeOrFormat = 'fr') => {
  if (!atDate) atDate = Date.now();
  const lastMentrualTimestamp = fromStrToDate(lastMenstrualDate, localeOrFormat) / 1000;
  const atTimestamp = Number.isInteger(atDate) ? atDate / 1000 : Date.parse(atDate) / 1000;
  if (atTimestamp < lastMentrualTimestamp) return 0;
  return Math.floor((atTimestamp - lastMentrualTimestamp) / (60 * 60 * 24 * 7));
};

/**
 * compute BMI starting from weight in kgs and height in cms
 */
export const computeBMI = (weight, height) => {
  if (!weight || !height) return '';
  if (isNaN(weight) || isNaN(height)) return '';
  return weight / (height / 100) ** 2;
};

/**
 * compute weight starting from height in cms and BMI
 */
export const computeWeight = (height, bmi) => {
  if (!height || !bmi) return '';
  return bmi * (height / 100) ** 2;
};

/**
 * get a list of all the medical history items with readable labels and values in the current language
 * @returns array of objects {label, value, risk_factor_id}
 */
export const getMedicalHistory = (
  medicalHistory,
  medicalHistoryItems,
  medications = [],
  __ = () => {},
  preferedUnit = 'metric',
  convertValueToSelectedUnit
) => {
  if (!medicalHistory || !medicalHistoryItems) return [];
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  // excluding array values is intended to exclude teratogenic risks
  const niceMedicalHistory = Object.values(medicalHistory)
    .filter(
      (item) =>
        item.text_id?.indexOf('medicalexam.fetus') !== 0 && item.text_id?.indexOf('teratogenicrisks.medications') !== 0
    )
    .filter((item) => !!medicalHistoryItems[item.text_id])
    .map((item) => {
      const label = medicalHistoryItems[item.text_id].label[currentLanguage];
      const raw_value = item.raw_value;
      const tmp_value = item.tmp_value || false;
      const order = item.order;
      const [value, risk_factor_id, is_risky] = medicalHistoryItems[item.text_id]?.options?.reduce(
        (valueLabel, option) =>
          (valueLabel =
            option.value === item.value
              ? [option.label[currentLanguage], option.risk_factor_id, option.is_risky]
              : valueLabel),
        ['', 0]
      ) || [false, [], false];

      const weight =
        medicalHistoryItems[item.text_id].type === 'weight' && !isNaN(raw_value) && !isNullOrUndefined(raw_value)
          ? preferedUnit === 'imperial'
            ? convertValueToSelectedUnit(raw_value, 'kg', 'lbs.oz', 0)[0] +
              ' lbs ' +
              convertValueToSelectedUnit(raw_value, 'kg', 'lbs.oz', 0)[1] +
              ' oz'
            : Number(raw_value).toFixed(0) + ' ' + __('medicalhistory.units.kg')
          : false;

      const size =
        medicalHistoryItems[item.text_id].type === 'size' && !isNaN(raw_value) && !isNullOrUndefined(raw_value)
          ? preferedUnit === 'imperial'
            ? convertValueToSelectedUnit(raw_value, 'cm', 'ft.inch', 0)[0] +
              ' ft ' +
              convertValueToSelectedUnit(raw_value, 'cm', 'ft.inch', 0)[1] +
              ' inches'
            : Number(raw_value).toFixed(0) + ' ' + __('medicalhistory.units.cm')
          : false;

      return {
        label,
        text_id: item.text_id,
        value: size || weight || value,
        raw_value: !!size || !!weight ? '' : raw_value,
        tmp_value,
        risk_factor_id,
        is_risky,
        order,
      };
    });

  const niceMedications =
    medicalHistory['teratogenicrisks.medications']?.raw_value.map((item) => {
      const medication = medications.find((m) => m.id === item.id);
      const id = medication?.id || '';
      const label = __('examination.medication');
      const value = medication?.name[currentLanguage] || '';
      const raw_value = value;
      const tmp_value = value;
      const risk_factor_id = medication?.risk_factor_ids?.[0] || null;
      return { id, label, value, raw_value, tmp_value, risk_factor_id, is_risky: !!risk_factor_id };
    }) || [];

  return [...niceMedicalHistory, ...niceMedications];
};

/**
 * get a list of all the risk factors with readable labels and values in the current language
 * note: we are hiding all the fetus specific items (at now, just the sex)
 * @returns array of objects {label, value, risk_factor_id}
 */
export const getRiskFactors = (medicalHistory, medicalHistoryItems) => {
  if (!medicalHistory || !medicalHistoryItems) return [];

  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  // excluding array values is intended to exclude teratogenic risks
  const riskFactors = Object.values(medicalHistory)
    .filter(
      (item) =>
        !!item.risk_factors.length && !Array.isArray(item.raw_value) && item.text_id.indexOf('medicalexam.fetus') !== 0
    )
    .map((item) => {
      if (!medicalHistoryItems[item.text_id]) return false;
      const label = medicalHistoryItems[item.text_id].label[currentLanguage];
      const raw_value = item.raw_value;
      const tmp_value = item.tmp_value || false;
      const order = item.order;
      const [value, risk_factor_id, is_risky] = medicalHistoryItems[item.text_id].options.reduce(
        (valueLabel, option) =>
          (valueLabel =
            option.value === item.value
              ? [option.label[currentLanguage], option.risk_factor_id, option.is_risky]
              : valueLabel),
        ['', 0]
      );
      return { label, value, raw_value, tmp_value, risk_factor_id, is_risky, order };
    });

  return riskFactors.filter((risk) => risk.is_risky).sort((a, b) => a.order - b.order);
};

/**
 * get a list of all teratogenic risks with readable labels and values in the current language
 * @param {*} medicalHistory
 * @param {*} medicalHistoryItems
 * @returns array of objects {id, label, risk_factor_id}
 */
export const getTeratogenicRisks = (medicalHistory, medications = []) => {
  if (!medicalHistory || !medicalHistory['teratogenicrisks.medications']) return [];

  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const teratogenicRisks =
    medicalHistory['teratogenicrisks.medications']?.raw_value
      .filter((item) => !!item.risk_factor_ids.length)
      .map((item) => {
        const medication = medications?.find((m) => m.id === item.id);
        const id = medication?.id || '';
        const label = medication?.name[currentLanguage] || '';
        const risk_factor_id = medication?.risk_factor_ids[0] || null;
        return { id, label, risk_factor_id };
      }) || [];
  return teratogenicRisks;
};

/**
 * rebase prevalence to a given scale
 */
const formatPrevalence = (prevalence, scale = 10000) => {
  if (!prevalence) prevalence = 0;
  prevalence = Number(prevalence);
  let amount = Math.round(prevalence * scale * 100) / 100;
  if (amount === 0) amount = '< 0.01';
  return { amount, scale: scale.toLocaleString() };
};

/**
 * print the prevalence in a nice, readable format
 */
export const nicePrevalence = (prevalence, __) => {
  const prev = formatPrevalence(prevalence, 10000);
  return __('dx.summary.prevalence.stat.' + (prev.amount === 1 ? 'singular' : 'plural'))
    .replace('{amount}', prev.amount)
    .replace('{scale}', prev.scale);
};

/**
 * rebase probability on a scale from 0 to 10
 */
export const formatProbability = (probability) => {
  probability = Math.floor((probability * 9990 + 10) / 1000);
  return Number(probability) === 0 ? '< 1' : probability;
};

/**
 * return the name of the given syndrome in the current language
 * @param {int} syndrome
 * @param {[]} allMalformations
 * @param {[]} presentMalformations // syndromes marked as present (yes) in the current exam, used for isolated anomalies or fortuituos associations
 */
export const getSyndromeName = (syndrome, allMalformations, presentMalformations = [], __ = () => {}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  if (syndrome) return syndrome?.canonical_name?.[currentLanguage];
  if (!presentMalformations?.length) return '';
  if (presentMalformations.length === 1) {
    return __('dx.summary.isolatedAnomaly').replace(
      '{anomaly}',
      allMalformations[presentMalformations[0]]?.canonical_name?.[currentLanguage]
    );
  } else {
    return __('dx.summary.fortuitousAssociation').replace(
      '{anomalies}',
      presentMalformations
        .map((malformationId) => allMalformations[malformationId]?.canonical_name?.[currentLanguage])
        .join(' / ')
    );
  }
};
